import { Box, Divider, Tab, Tabs, alpha, useTheme } from "@mui/material";
import { ElementType, forwardRef } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { RouteType } from "@convin/config/routes.config";
import { isDefined } from "@convin/utils/helper/common.helper";

export const CustomNavLink: React.FC<
    NavLinkProps & { showActiveBg?: boolean }
> = ({ children, showActiveBg = false, ...rest }) => {
    const theme = useTheme();
    return (
        <NavLink
            {...rest}
            style={({ isActive }) => ({
                fontWeight: 500,
                ...(Boolean(isActive) && {
                    color: theme.palette.primary.main,
                    ...(showActiveBg && {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }),
                }),
                ...(!isActive && {
                    color:
                        theme.palette.mode === "light"
                            ? theme.palette.textColors["666"]
                            : "white",
                }),
            })}
        >
            {children}
        </NavLink>
    );
};

export const TabComponent: ElementType = forwardRef(function Component(
    props: RouteType,
    ref
) {
    const { name, to, Icon } = props;
    return (
        <Box
            className="flex items-center justify-center flex-1"
            sx={{
                px: 2,
            }}
            ref={ref}
        >
            <CustomNavLink
                to={to}
                className={
                    "flex gap-2 flex-1 text-center items-center justify-center"
                }
            >
                {isDefined(Icon) && <Icon />}
                <Box component="span">{name}</Box>
            </CustomNavLink>
        </Box>
    );
});

function TabNavigation({
    tabs,
    value,
}: {
    tabs: (
        | {
              component: React.FC;
              to: string;
          }
        | RouteType
    )[];
    value?: string;
}) {
    const { pathname } = useLocation();
    return (
        <Box className="flex-shrink-0">
            <Tabs
                value={isDefined(value) ? value : pathname}
                sx={(theme) => ({
                    "& .MuiTab-root": {
                        flex: 1,
                        maxWidth: "unset",
                        fontSize: theme.spacing(1.75),
                    },

                    "& .MuiTabs-flexContainer": {
                        height: "100%",
                        borderRadius: (theme) =>
                            `${theme.spacing(0.75)} ${theme.spacing(0.75)} 0 0`,
                    },

                    ".MuiTabs-indicator": {
                        height: "2px",
                        width: `calc(100% / ${tabs.length})`,
                    },
                })}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.to}
                        component={
                            "component" in tab
                                ? tab.component
                                : forwardRef(function RefComponent(_, ref) {
                                      return (
                                          <TabComponent {...tab} ref={ref} />
                                      );
                                  })
                        }
                        value={tab.to}
                    />
                ))}
            </Tabs>
            <Divider />
        </Box>
    );
}

export default TabNavigation;
