import { isDefined } from "@convin/utils/helper/common.helper";
import { useContext } from "react";
import {
    CommentInputSateContext,
    ICommentInputStateContext,
} from "../context/CommentInputStatePorvider";

export default function useCommentInputState(): ICommentInputStateContext {
    const context = useContext(CommentInputSateContext);
    if (!isDefined(context)) {
        throw new Error(
            "CommentsStateInputContext must be wrapped around CommentsSateInputProvider"
        );
    }
    return context;
}
