import { Stack, Typography, Button } from "@mui/material";

import { baseUrl } from "@apis/axiosInstance";

export default function InternalSignin() {
    const uri = `${baseUrl}/app/tpsignup/outlook/?is_internal_login=true`;
    return (
        <Stack>
            <Typography variant="medium" component="div" my={1.5}>
                Log in using your Microsoft account.
            </Typography>
            <Button
                variant="global"
                fullWidth
                component="a"
                href={uri}
                startIcon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_33805_20684)">
                            <path
                                d="M7.60413 8.10413H0V0.5H7.60413V8.10413Z"
                                fill="#F1511B"
                            />
                            <path
                                d="M15.9996 8.10413H8.39551V0.5H15.9996V8.10413Z"
                                fill="#80CC28"
                            />
                            <path
                                d="M7.60394 16.5006H0V8.89648H7.60394V16.5006Z"
                                fill="#00ADEF"
                            />
                            <path
                                d="M15.9996 16.5006H8.39551V8.89648H15.9996V16.5006Z"
                                fill="#FBBC09"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_33805_20684">
                                <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                }
            >
                Login with Microsoft Account
            </Button>
        </Stack>
    );
}
