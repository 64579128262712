import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function OverallSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_15045_41304"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_15045_41304)">
                <path
                    d="M2.5 9.16667V2.5H9.16667V9.16667H2.5ZM2.5 17.5V10.8333H9.16667V17.5H2.5ZM10.8333 9.16667V2.5H17.5V9.16667H10.8333ZM10.8333 17.5V10.8333H17.5V17.5H10.8333Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
