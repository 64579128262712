import {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";
import useConversationDetailsContext from "../hooks/useConversationDetailsContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import { CustomerSentimentType } from "@convin/type/Conversation";

export interface IConversationState {
    activeLeftTab: "overview" | "summary" | "wordcloud" | "transcript";
    handleActiveLeftTab: (tab: IConversationState["activeLeftTab"]) => void;
    searchQuery: string;
    handleSearchQuery: (e: string) => void;
    sentimentTranscriptFilter: CustomerSentimentType | null;
    handleSentimentTranscriptFilter: (
        payload: CustomerSentimentType | null
    ) => void;
}

export const ConversationStateContext = createContext<IConversationState>(
    {} as IConversationState
);

export default function ConversationStateProvider({
    children,
}: PropsWithChildren): ReactElement {
    const query = useSearchQuery();
    const { checkCanAccess } = useAuth();
    const { conversationDetails, isConversationDetailsLoading } =
        useConversationDetailsContext();
    const [activeLeftTab, setActiveLeftTab] =
        useState<IConversationState["activeLeftTab"]>("transcript");
    const [sentimentTranscriptFilter, setSentimentTranscriptFilter] =
        useState<CustomerSentimentType | null>(null);

    const [searchQuery, setSearchQuery] = useState<string>(
        query.get("keyword") ?? ""
    );

    const handleActiveLeftTab: IConversationState["handleActiveLeftTab"] =
        useCallback((tab) => {
            setActiveLeftTab(tab);
        }, []);

    const handleSearchQuery: IConversationState["handleSearchQuery"] =
        useCallback((query: string) => {
            setSearchQuery(query);
        }, []);

    const handleSentimentTranscriptFilter = useCallback(
        (payload: CustomerSentimentType | null) => {
            setSentimentTranscriptFilter(payload);
            if (activeLeftTab !== "transcript") {
                handleActiveLeftTab("transcript");
            }
        },
        [activeLeftTab]
    );

    useEffect(() => {
        if (isConversationDetailsLoading || !isDefined(conversationDetails))
            return;
        if (
            conversationDetails.meeting_type === "call" &&
            !checkCanAccess({
                heading: PermissionModules.Meeting,
                code_name: "meeting.restrict_transcript_access",
                permission_type: PermissionTypes.edit,
            })
        ) {
            handleActiveLeftTab("overview"); // If no access to transcript, change tab to overview
        }
    }, [conversationDetails, isConversationDetailsLoading]);

    return (
        <ConversationStateContext
            value={{
                activeLeftTab,
                handleActiveLeftTab,
                searchQuery,
                handleSearchQuery,
                sentimentTranscriptFilter,
                handleSentimentTranscriptFilter,
            }}
        >
            {children}
        </ConversationStateContext>
    );
}
