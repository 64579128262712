import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function CustomTrackingTabSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 25 25"
            sx={{ width: 25, height: 25, fill: "none", ...sx }}
        >
            <mask
                id="mask0_15074_23349"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
            >
                <rect
                    x="0.508301"
                    y="0.7771"
                    width="24"
                    height="24"
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_15074_23349)">
                <path
                    d="M9.24072 20.9211L8.14128 19.8216L13.56 14.3833L16.3086 17.1319L20.3726 13.0679L21.4917 14.187L16.3086 19.3504L13.56 16.6018L9.24072 20.9211ZM6.57064 20.1357C6.13871 20.1357 5.76896 19.982 5.46137 19.6744C5.15379 19.3668 5 18.997 5 18.5651V7.57064C5 7.13871 5.15379 6.76896 5.46137 6.46137C5.76896 6.15379 6.13871 6 6.57064 6H17.5651C17.997 6 18.3668 6.15379 18.6744 6.46137C18.982 6.76896 19.1357 7.13871 19.1357 7.57064V10.869H6.57064V20.1357Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
