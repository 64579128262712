import { SearchContext } from "@convin/type/customerIntelligence";
import { Close, PlayCircleFilled } from "@mui/icons-material";
import {
    Box,
    ClickAwayListener,
    IconButton,
    Popper,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { Label } from "../Typography/Label";
import { secondsToTime } from "@convin/utils/helper";
import { ConversationDetails } from "@convin/type/Conversation";
import { isDefined } from "@convin/utils/helper/common.helper";
import SnippetPlayer from "./SnippetPlayer";

export default function SnippetList({
    id,
    monologues,
    meeting_type,
    keyword,
}: {
    monologues: SearchContext[];
    id: number;
    meeting_type: ConversationDetails["meeting_type"];
    keyword?: string;
}) {
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const [monologue, setMonologue] = useState<SearchContext | null>(null);
    return (
        <>
            {monologues?.map((monologue, idx) => (
                <Box
                    sx={{ mb: 1.5 }}
                    key={idx}
                    className="flex items-center gap-3"
                >
                    {meeting_type === "call" && (
                        <PlayCircleFilled
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}
                            className="cursor-pointer"
                            onClick={(e) => {
                                setMonologue(monologue);
                                setAnchorEl(e.currentTarget);
                            }}
                        />
                    )}

                    <p
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {meeting_type === "call" && (
                            <Label
                                colorType="666"
                                variant="medium"
                                component="span"
                                className="pr-2"
                            >
                                {secondsToTime(monologue.start_time)} |
                            </Label>
                        )}

                        <Typography
                            variant="medium"
                            className="capitalize font-medium"
                            component="span"
                        >
                            {monologue.speaker_name || "Multiple Speakers"} -{" "}
                        </Typography>
                        <Typography
                            variant="medium"
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: monologue?.headline,
                            }}
                            className="cursor-pointer hover:underline"
                            onClick={() =>
                                window.open(
                                    `/${meeting_type}/${id}?tab=transcript&start_time=${monologue.start_time}&end_time=${monologue.end_time}` +
                                        (isDefined(keyword) && keyword?.length
                                            ? `&keyword=${keyword}`
                                            : "")
                                )
                            }
                        />
                    </p>
                </Box>
            ))}
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={() => setAnchorEl(null)}
            >
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement="left-end"
                    sx={{ zIndex: 1400 }}
                    className="relative"
                >
                    {monologue ? (
                        <>
                            <SnippetPlayer
                                id={id}
                                start_time={monologue.start_time}
                                end_time={monologue.end_time}
                            />
                            <IconButton
                                className="absolute top-3 right-3 cursor-pointer z-10"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.white,
                                }}
                                onClick={() => {
                                    setMonologue(null);
                                    setAnchorEl(null);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </>
                    ) : null}
                </Popper>
            </ClickAwayListener>
        </>
    );
}
