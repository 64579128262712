import { errorReload } from "@convin/utils/helper/common.helper";
import { useEffect } from "react";

export default function useVitePreloadError() {
    useEffect(() => {
        window.addEventListener("vite:preloadError", () => {
            errorReload("Error during page load", 1);
        });
        return () => {
            window.removeEventListener("vite:preloadError", () => {});
        };
    }, []);
}
