import { Navigate } from "react-router-dom";
// hooks
import { PropsWithChildren, ReactElement } from "react";
import useAuth from "@convin/hooks/useAuth";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";

const conversationRoute = DashboardRoutesConfig.Conversations;

const homeRoute = DashboardRoutesConfig.Home;

export default function GuestGuard({
    children,
}: PropsWithChildren): ReactElement {
    const { isAuthenticated } = useAuth();
    const { data: domainConfig } = useGetDomainConfigQuery();
    const isB2cDomain = domainConfig?.domain_type === "b2c";
    if (isAuthenticated) {
        return (
            <Navigate to={isB2cDomain ? homeRoute.to : conversationRoute.to} />
        );
    }

    return <>{children}</>;
}
