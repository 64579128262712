import DateFilter from "@convin/components/custom_components/Filters/DateFilter";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box } from "@mui/material";

const MeetingDateFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    return (
        <Box
            sx={{
                mb: 2.5,
            }}
        >
            <DateFilter
                label={"Created Date"}
                value={filtersState.dateKey}
                options={filtersState.dateOptions}
                handleUpdate={(e) => {
                    if (!Boolean(e.is_custom)) {
                        updateFiltersState({
                            dateKey: e.value ?? undefined,
                        });
                    } else {
                        updateFiltersState({
                            dateKey: e.value ?? undefined,
                            dateOptions: {
                                ...filtersState.dateOptions,
                                ...(isDefined(e.value) && {
                                    [e.value]: {
                                        name: e.value,
                                        is_rolling_date: false,
                                        label: "",
                                        dateRange: e.dateRange,
                                    },
                                }),
                            },
                        });
                    }
                }}
            />
        </Box>
    );
};

export default MeetingDateFilter;
