import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function DateSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 19 18"
            sx={{
                width: 19,
                height: 18,
                fill: "none",
                color: "#666666",
                ...sx,
            }}
        >
            <mask
                id="mask0_14847_35904"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="18"
            >
                <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_14847_35904)">
                <path
                    d="M6.5 10.5C6.2875 10.5 6.10938 10.4281 5.96562 10.2844C5.82187 10.1406 5.75 9.9625 5.75 9.75C5.75 9.5375 5.82187 9.35938 5.96562 9.21563C6.10938 9.07188 6.2875 9 6.5 9C6.7125 9 6.89062 9.07188 7.03438 9.21563C7.17812 9.35938 7.25 9.5375 7.25 9.75C7.25 9.9625 7.17812 10.1406 7.03438 10.2844C6.89062 10.4281 6.7125 10.5 6.5 10.5ZM9.5 10.5C9.2875 10.5 9.10938 10.4281 8.96563 10.2844C8.82188 10.1406 8.75 9.9625 8.75 9.75C8.75 9.5375 8.82188 9.35938 8.96563 9.21563C9.10938 9.07188 9.2875 9 9.5 9C9.7125 9 9.89063 9.07188 10.0344 9.21563C10.1781 9.35938 10.25 9.5375 10.25 9.75C10.25 9.9625 10.1781 10.1406 10.0344 10.2844C9.89063 10.4281 9.7125 10.5 9.5 10.5ZM12.5 10.5C12.2875 10.5 12.1094 10.4281 11.9656 10.2844C11.8219 10.1406 11.75 9.9625 11.75 9.75C11.75 9.5375 11.8219 9.35938 11.9656 9.21563C12.1094 9.07188 12.2875 9 12.5 9C12.7125 9 12.8906 9.07188 13.0344 9.21563C13.1781 9.35938 13.25 9.5375 13.25 9.75C13.25 9.9625 13.1781 10.1406 13.0344 10.2844C12.8906 10.4281 12.7125 10.5 12.5 10.5ZM4.25 16.5C3.8375 16.5 3.48438 16.3531 3.19063 16.0594C2.89688 15.7656 2.75 15.4125 2.75 15V4.5C2.75 4.0875 2.89688 3.73438 3.19063 3.44063C3.48438 3.14688 3.8375 3 4.25 3H5V1.5H6.5V3H12.5V1.5H14V3H14.75C15.1625 3 15.5156 3.14688 15.8094 3.44063C16.1031 3.73438 16.25 4.0875 16.25 4.5V15C16.25 15.4125 16.1031 15.7656 15.8094 16.0594C15.5156 16.3531 15.1625 16.5 14.75 16.5H4.25ZM4.25 15H14.75V7.5H4.25V15Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
