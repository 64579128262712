import { alpha, Box, Popover, Skeleton } from "@mui/material";
import {
    getDefaultConfigOptions,
    getParameterResponseOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import BlockSettingSvg from "../../svg/BlockSettingSvg";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import CreateSingleResponseBlockType from "../SingleResponse/CreateSingleResponseBlockType";
import BertCardWrapper from "../BertCardWrapper";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import AttributeBlock from "../Blocks/AttributeBlock";
import ParameterBlock from "../Blocks/ParameterBlock";
import MomentsBlock from "../Blocks/MomentsBlock";
import PredefinedFuncBlock from "../Blocks/PredefinedFuncBlock";
import {
    CombinationNode,
    MomentReferenceType,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../../types/bert";
import { Node } from "reactflow";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import InfoLegendSvg from "../../svg/InfoLegendSvg";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import ValidateInfoBlock from "../Blocks/ValidateInfoBlock";
import { MultiResponseNodeType } from "../../context/NodeStateContext";
import { pxToRem } from "@convin/utils/getFontValue";

import ConfigureSettingSvg from "../../svg/ConfigureSettingSvg";
import { Options } from "../SingleResponse/CombinationBlockNode";

export default function MultiResponseBlock({
    data,
    id,
    parent,
    isConditionBlock,
    sourcePosition,
    hideNot,
}: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0] & {
    isConditionBlock?: boolean;
    hideNot?: boolean;
}) {
    const { question } = useParameterAiConfigContext();
    const { data: momentsObj, isLoading } = useGetMomentsQuery();
    const { setNodes, setEdges } = useReactFlowStateContextProvider();
    const {
        dispatch,
        aiConfigState: { activeGroupBlock, activeDefaultBlock },
    } = useParameterAiConfigContext();
    const { open, anchorEl, handleClick, handleClose } =
        useAnchorEl<HTMLButtonElement | null>();

    const {
        open: toolBarOpen,
        handleClick: handleToolbarClick,
        handleClose: handleToolbarClose,
        anchorEl: toolbarAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const {
        open: editOpen,
        anchorEl: editAnchor,
        handleClick: handleEditClick,
        handleClose: handleEditClose,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: infoOpen,
        handleClick: handleInfoClick,
        handleClose: handleInfoClose,
        anchorEl: infoAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const {
        open: selectOpen,
        anchorEl: selectAnchor,
        handleClose: handleSelectClose,
        handleClick: handleSelectClick,
    } = useAnchorEl<HTMLButtonElement>();

    const selectedBlocks =
        data.type === "default"
            ? (data.metadata as CombinationNode["metadata"]).blocks.filter(
                  (e) => e.is_selected
              )
            : [];

    const parameterOptions = isConditionBlock
        ? []
        : getParameterResponseOptions(question);

    const isMommentBlock = data.type === "moment";
    const momentName = isMommentBlock
        ? momentsObj?.moments.find(
              (e) => e.id === (data.metadata as MomentReferenceType).id
          )?.name
        : "";

    return (
        <>
            <div
                className={[
                    "multiple--response-block",
                    "is_not" in data && data.is_not && "not-operator-block",
                    id === activeGroupBlock?.blockId && "active-group-block",
                    id === activeDefaultBlock?.blockId &&
                        "active-default-block",
                    !isDefined(data.type) && "no-type-block",
                ]
                    .filter(Boolean)
                    .join(" ")}
            >
                {isDefined(data.type) && (
                    <>
                        {data.type === "group_block" ? (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#F26EA6"
                                    stroke="#EA62B4"
                                />

                                <path
                                    d="M8.97201 12.5875L5.39844 9.80805L6.05359 9.31172L8.97201 11.575L11.8904 9.31172L12.5456 9.80805L8.97201 12.5875ZM8.97201 10.5823L5.39844 7.80288L8.97201 5.02344L12.5456 7.80288L8.97201 10.5823Z"
                                    fill="white"
                                />
                            </svg>
                        ) : data.type === "default" ? (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#77BE04"
                                    stroke="#77BE04"
                                />
                                <g clipPath="url(#clip0_7138_9903)">
                                    <g mask="url(#mask0_7138_9903)">
                                        <path
                                            d="M8.67795 14.0938L8.05573 13.4604L8.86684 12.6493H7.37795C7.28166 12.9086 7.12054 13.1215 6.89462 13.2882C6.66869 13.4549 6.41128 13.5382 6.1224 13.5382C5.75203 13.5382 5.43721 13.4086 5.17795 13.1493C4.91869 12.89 4.78906 12.5752 4.78906 12.2049C4.78906 11.916 4.8724 11.6586 5.03906 11.4326C5.20573 11.2067 5.41869 11.0456 5.67795 10.9493V7.23819C5.41869 7.1419 5.20573 6.98079 5.03906 6.75486C4.8724 6.52894 4.78906 6.27153 4.78906 5.98264C4.78906 5.61227 4.91869 5.29745 5.17795 5.03819C5.43721 4.77894 5.75203 4.64931 6.1224 4.64931C6.41128 4.64931 6.66869 4.73264 6.89462 4.89931C7.12054 5.06597 7.28166 5.27894 7.37795 5.53819H8.86684L8.05573 4.72708L8.67795 4.09375L10.5668 5.98264L8.67795 7.87153L8.05573 7.23819L8.86684 6.42708H7.37795C7.31128 6.61968 7.20758 6.78634 7.06684 6.92708C6.9261 7.06782 6.75943 7.17153 6.56684 7.23819V10.9493C6.75943 11.016 6.9261 11.1197 7.06684 11.2604C7.20758 11.4012 7.31128 11.5678 7.37795 11.7604H8.86684L8.05573 10.9493L8.67795 10.316L10.5668 12.2049L8.67795 14.0938ZM12.3446 13.5382C11.9742 13.5382 11.6594 13.4086 11.4002 13.1493C11.1409 12.89 11.0113 12.5752 11.0113 12.2049C11.0113 11.9086 11.0946 11.6475 11.2613 11.4215C11.428 11.1956 11.6409 11.0382 11.9002 10.9493V7.23819C11.6409 7.14931 11.428 6.9919 11.2613 6.76597C11.0946 6.54005 11.0113 6.27894 11.0113 5.98264C11.0113 5.61227 11.1409 5.29745 11.4002 5.03819C11.6594 4.77894 11.9742 4.64931 12.3446 4.64931C12.715 4.64931 13.0298 4.77894 13.2891 5.03819C13.5483 5.29745 13.678 5.61227 13.678 5.98264C13.678 6.27894 13.5946 6.54005 13.428 6.76597C13.2613 6.9919 13.0483 7.14931 12.7891 7.23819V10.9493C13.0483 11.0456 13.2613 11.2067 13.428 11.4326C13.5946 11.6586 13.678 11.916 13.678 12.2049C13.678 12.5752 13.5483 12.89 13.2891 13.1493C13.0298 13.4086 12.715 13.5382 12.3446 13.5382Z"
                                            fill="white"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_7138_9903">
                                        <rect
                                            width="10.6667"
                                            height="10.6667"
                                            fill="white"
                                            transform="translate(3.66406 3.66406)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        ) : data.type === "else" ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#F9A641"
                                    stroke="#F9A641"
                                />
                                <path
                                    d="M3 8.44444L5.75 8.44444C6.26333 8.44444 6.64375 8.36574 6.89125 8.20833C7.13875 8.05093 7.38167 7.84259 7.62 7.58333L8.40375 8.375C8.30292 8.48611 8.19521 8.59259 8.08062 8.69444C7.96604 8.7963 7.84458 8.89815 7.71625 9C7.89042 9.12963 8.04396 9.26157 8.17687 9.39583C8.30979 9.53009 8.44042 9.66667 8.56875 9.80556C8.88958 10.1574 9.26083 10.4769 9.6825 10.7639C10.1042 11.0509 10.8421 11.2037 11.8962 11.2222L11.03 10.3472L11.8 9.55556L14 11.7778L11.8 14L11.03 13.2222L11.8962 12.3333C10.5854 12.3148 9.65271 12.1111 9.09812 11.7222C8.54354 11.3333 8.09208 10.9444 7.74375 10.5556C7.47792 10.2593 7.21896 10.0185 6.96687 9.83333C6.71479 9.64815 6.30917 9.55556 5.75 9.55556L3 9.55556L3 8.44444ZM10.6037 5.77778C10.7871 5.74074 10.9887 5.71528 11.2087 5.70139C11.4287 5.6875 11.6579 5.67593 11.8962 5.66667L11.03 4.77778L11.8 4L14 6.22222L11.8 8.44444L11.03 7.65278L11.8825 6.77778C11.69 6.77778 11.509 6.78704 11.3394 6.80556C11.1698 6.82407 11.0117 6.84259 10.865 6.86111L10.6037 5.77778ZM8.18375 6.97222C8.37625 6.78704 8.60083 6.6088 8.8575 6.4375C9.11417 6.2662 9.43042 6.11574 9.80625 5.98611L10.0675 7.05556C9.82 7.14815 9.60917 7.25463 9.435 7.375C9.26083 7.49537 9.105 7.625 8.9675 7.76389L8.18375 6.97222Z"
                                    fill="white"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#F9A641"
                                    stroke="#F9A641"
                                />
                                <path
                                    d="M9.12648 12.8311L5.4375 9.1421L9.12648 5.45312L12.8155 9.1421L9.12648 12.8311Z"
                                    fill="white"
                                />
                            </svg>
                        )}
                        {"is_not" in data && data.is_not && (
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="16"
                                    height="16"
                                    rx="3.75"
                                    fill="#FD6C3E"
                                />
                                <path
                                    d="M8.00521 4.65625C6.16521 4.65625 4.67188 6.14958 4.67188 7.98958C4.67188 9.82958 6.16521 11.3229 8.00521 11.3229C9.84521 11.3229 11.3385 9.82958 11.3385 7.98958C11.3385 6.14958 9.84521 4.65625 8.00521 4.65625ZM8.00521 10.6562C6.53188 10.6562 5.33854 9.46292 5.33854 7.98958C5.33854 7.37292 5.54854 6.80625 5.90188 6.35625L9.63854 10.0929C9.18854 10.4462 8.62188 10.6562 8.00521 10.6562ZM10.1085 9.62292L6.37188 5.88625C6.82188 5.53292 7.38854 5.32292 8.00521 5.32292C9.47854 5.32292 10.6719 6.51625 10.6719 7.98958C10.6719 8.60625 10.4619 9.17292 10.1085 9.62292Z"
                                    fill="white"
                                />
                            </svg>
                        )}
                    </>
                )}
                <div
                    className="flex-1 font-semibold text-ellipsis overflow-hidden whitespace-nowrap"
                    style={{
                        fontSize: pxToRem(12),
                    }}
                >
                    {isConditionBlock ? (
                        isMommentBlock ? (
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                momentName
                            )
                        ) : "name" in data.metadata ? (
                            data.type === "default" ? (
                                `${data.metadata.name}(${
                                    isDefined(data.metadata.selected)
                                        ? data.metadata.selected
                                            ? `Any ${data.metadata.selected} Blocks`
                                            : "All Blocks"
                                        : "Not Configured"
                                })`
                            ) : (
                                data.metadata.name
                            )
                        ) : (
                            ""
                        )
                    ) : (
                        parameterOptions.find((e) => e.id == id)?.label
                    )}
                </div>
                <>
                    {isDefined(data.type) ? (
                        <>
                            {data.type === "default" &&
                                selectedBlocks.length > 0 && (
                                    <>
                                        <div className="relative overflow-visible nodrag nopan">
                                            <Box
                                                sx={{
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .textColors[999],
                                                            0.1
                                                        ),
                                                    borderRadius: "6px",
                                                }}
                                                className="flex-shrink-0 w-[20px] h-[20px] flex items-center justify-center nodrag nopan cursor-pointer"
                                                component="button"
                                                onClick={(e) =>
                                                    handleSelectClick(e)
                                                }
                                            >
                                                <ConfigureSettingSvg />
                                            </Box>
                                        </div>
                                    </>
                                )}
                            {data.type !== "else" && (
                                <button
                                    onClick={handleToolbarClick}
                                    className="flex-shrink-0 nodrag nopan cursor-pointer font-semibold scale-[1.2]"
                                >
                                    &#8942;
                                </button>
                            )}
                        </>
                    ) : (
                        <>
                            <button
                                className="flex-shrink-0 nodrag nopan cursor-pointer"
                                onClick={handleClick}
                            >
                                <BlockSettingSvg />
                            </button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                }}
                                classes={{
                                    paper: "overflow-visible bg-transparent",
                                }}
                            >
                                <Box
                                    className="relative"
                                    sx={{
                                        width: "420px",
                                    }}
                                >
                                    <BertCardWrapper
                                        sx={{
                                            bottom: "0",
                                            left: "0",
                                            transform: "translate(0%,50%)",
                                            position: "absolute",
                                        }}
                                    >
                                        <CreateSingleResponseBlockType
                                            onNodeSaveCallBack={() => {
                                                handleClose();
                                            }}
                                            sourceId={id}
                                            nodeDataToUpdate={
                                                "metadata" in data
                                                    ? (data.metadata as
                                                          | MultiResponseBlockData<MultiResponseNodeType>
                                                          | undefined)
                                                    : undefined
                                            }
                                            blockParentId={parent ?? undefined}
                                        />
                                    </BertCardWrapper>
                                </Box>
                            </Popover>
                        </>
                    )}
                </>
                {toolBarOpen && (
                    <Popover
                        anchorEl={toolbarAnchor}
                        open={toolBarOpen}
                        onClose={handleToolbarClose}
                        transformOrigin={{
                            horizontal: -40,
                            vertical: "center",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "center",
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    bgcolor: "transparent",
                                    border: "none",
                                },
                            },
                        }}
                    >
                        <div className="flex items-center flex-col gap-[8px]">
                            {data.type === "else" ? (
                                <></>
                            ) : (
                                <>
                                    <DeleteButton
                                        onDelete={() => {
                                            return new Promise(() => {
                                                setNodes((nodes) =>
                                                    nodes.map(
                                                        (
                                                            node: Node<
                                                                MultipleResponseNode<MultiResponseNodeType>
                                                            >
                                                        ) =>
                                                            node.id === parent
                                                                ? {
                                                                      ...node,
                                                                      data: {
                                                                          ...node.data,
                                                                          metadata:
                                                                              {
                                                                                  ...node
                                                                                      .data
                                                                                      .metadata,
                                                                                  blocks: node.data.metadata.blocks.map(
                                                                                      (
                                                                                          b
                                                                                      ) =>
                                                                                          b.id !==
                                                                                          id
                                                                                              ? b
                                                                                              : {
                                                                                                    ...b,
                                                                                                    data: {
                                                                                                        type: null,
                                                                                                        is_not: false,
                                                                                                        metadata:
                                                                                                            {
                                                                                                                name:
                                                                                                                    b
                                                                                                                        .data
                                                                                                                        .metadata
                                                                                                                        .name ??
                                                                                                                    "Untitled",
                                                                                                            },
                                                                                                    },
                                                                                                }
                                                                                  ),
                                                                              },
                                                                      },
                                                                  }
                                                                : node
                                                    )
                                                );
                                                setEdges((edges) =>
                                                    edges.filter(
                                                        (e) =>
                                                            e.sourceHandle !==
                                                            `${id}_${sourcePosition}`
                                                    )
                                                );

                                                handleToolbarClose();
                                            });
                                        }}
                                        message="Are you sure you want to remove the configuration for this block?"
                                        title="Remove Configuration"
                                        DeleteOptionComponent={
                                            <button>
                                                <DeleteLegendSvg />
                                            </button>
                                        }
                                    />
                                    {data.type !== "group_block" && (
                                        <button onClick={handleInfoClick}>
                                            <InfoLegendSvg />
                                        </button>
                                    )}

                                    <button
                                        onClick={(e) => {
                                            if (data.type === "group_block") {
                                                dispatch({
                                                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                                                    payload: {
                                                        blockId: id,
                                                        blockParentId: parent!,
                                                    },
                                                });
                                                handleToolbarClose();
                                            } else if (
                                                data.type === "default"
                                            ) {
                                                dispatch({
                                                    type: "SET_ACTIVE_DEFAULT_BLOCK_ID",
                                                    payload: {
                                                        blockId: id,
                                                        blockParentId: parent!,
                                                    },
                                                });
                                                handleToolbarClose();
                                            } else {
                                                handleEditClick(e);
                                            }
                                        }}
                                    >
                                        <EditLegendSvg />
                                    </button>

                                    {!hideNot && (
                                        <button
                                            onClick={() => {
                                                setNodes((nodes) =>
                                                    nodes.map(
                                                        (
                                                            node: Node<
                                                                MultipleResponseNode<MultiResponseNodeType>
                                                            >
                                                        ) =>
                                                            node.id === parent
                                                                ? {
                                                                      ...node,
                                                                      data: {
                                                                          ...node.data,
                                                                          metadata:
                                                                              {
                                                                                  ...node
                                                                                      .data
                                                                                      .metadata,
                                                                                  blocks: node.data.metadata.blocks.map(
                                                                                      (
                                                                                          b
                                                                                      ) =>
                                                                                          b.id ===
                                                                                          id
                                                                                              ? {
                                                                                                    ...b,
                                                                                                    data: {
                                                                                                        ...b.data,
                                                                                                        ...("is_not" in
                                                                                                        b.data
                                                                                                            ? {
                                                                                                                  is_not: !b
                                                                                                                      .data
                                                                                                                      .is_not,
                                                                                                              }
                                                                                                            : {
                                                                                                                  is_not: true,
                                                                                                              }),
                                                                                                    },
                                                                                                }
                                                                                              : b
                                                                                  ),
                                                                              },
                                                                      },
                                                                  }
                                                                : node
                                                    )
                                                );
                                                handleToolbarClose();
                                            }}
                                        >
                                            <NotOperatorLegendSvg />
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </Popover>
                )}
                {(editOpen || infoOpen) && (
                    <Popover
                        open={editOpen || infoOpen}
                        anchorEl={editAnchor || infoAnchor}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        sx={{
                            ".MuiPaper-root": {
                                mt: 0,
                            },
                        }}
                    >
                        <NodeStatePorvider
                            onNodeSaveCallBack={() => {
                                handleToolbarClose();
                                handleEditClose();
                            }}
                            nodeIdToUpdate={id}
                            nodeDataToUpdate={
                                "metadata" in data
                                    ? (data.metadata as
                                          | MultiResponseBlockData<MultiResponseNodeType>
                                          | undefined)
                                    : undefined
                            }
                            blockParentId={parent!}
                        >
                            <BertCardWrapper
                                className="w-[400px]"
                                sx={{
                                    mt: 2,
                                    ...((infoOpen || !editOpen) && {
                                        "& button": {
                                            display: "none",
                                        },

                                        "& *": {
                                            userSelect: "none",
                                            pointerEvents: "none",
                                        },
                                    }),
                                }}
                                onClick={(e) => {
                                    const target = e.target as HTMLElement;
                                    if (
                                        target &&
                                        target.classList.contains(
                                            "block_close_button"
                                        )
                                    ) {
                                        if (editOpen) {
                                            handleEditClose();
                                        } else {
                                            handleInfoClose();
                                        }
                                    }
                                }}
                            >
                                {data.type === "attribute" ? (
                                    <AttributeBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "parameter" ? (
                                    <ParameterBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "moment" ? (
                                    <MomentsBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "function" ? (
                                    <PredefinedFuncBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "validateInfo" ? (
                                    <ValidateInfoBlock />
                                ) : (
                                    <></>
                                )}
                            </BertCardWrapper>
                        </NodeStatePorvider>
                    </Popover>
                )}
                <Popover
                    open={selectOpen}
                    anchorEl={selectAnchor}
                    onClose={handleSelectClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    {getDefaultConfigOptions(selectedBlocks.length).map(
                        (option, idx) => (
                            <Options
                                {...option}
                                key={idx}
                                onClick={() => {
                                    setNodes((nodes) =>
                                        nodes.map(
                                            (
                                                node: Node<
                                                    MultipleResponseNode<MultiResponseNodeType>
                                                >
                                            ) =>
                                                node.id === parent
                                                    ? {
                                                          ...node,
                                                          data: {
                                                              ...node.data,
                                                              metadata: {
                                                                  ...node.data
                                                                      .metadata,
                                                                  blocks: node.data.metadata.blocks.map(
                                                                      (e) =>
                                                                          e.id ===
                                                                          id
                                                                              ? {
                                                                                    ...e,
                                                                                    data: {
                                                                                        ...e.data,
                                                                                        metadata:
                                                                                            {
                                                                                                ...e
                                                                                                    .data
                                                                                                    .metadata,
                                                                                                selected:
                                                                                                    option.index,
                                                                                            },
                                                                                    },
                                                                                }
                                                                              : e
                                                                  ),
                                                              },
                                                          },
                                                      }
                                                    : node
                                        )
                                    );
                                    handleSelectClose();
                                }}
                            />
                        )
                    )}
                </Popover>
            </div>
        </>
    );
}
