import { createContext, useContext } from "react";

export interface IPaginatedSelectContext {
    hasNext: boolean;
    fetchNext: () => void;
    isFetching: boolean;
    isLoading: boolean;
}

export const PaginatedSelectContext =
    createContext<IPaginatedSelectContext | null>(null);

export const usePaginatedSelectContext = () => {
    const context = useContext(PaginatedSelectContext);
    if (!context) {
        throw new Error(
            "usePaginatedSelectContext must be used within a PaginatedSelectProvider"
        );
    }
    return context;
};
