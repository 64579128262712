import { SvgIcon, SxProps, Theme } from "@mui/material";

interface RocketSvgProps {
    sx?: SxProps<Theme>;
}

export default function RocketSvg({ sx }: RocketSvgProps) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                d="M2.9783 15.5408C4.30941 14.2097 4.44252 13.8769 5.27447 14.7088C6.10642 15.5408 5.77364 15.6406 4.44252 17.005C3.44419 18.0033 2.17963 17.8037 2.17963 17.8037C2.17963 17.8037 1.97996 16.5391 2.9783 15.5408Z"
                fill="#FF9D27"
            />
            <path
                d="M5.50749 16.9052C6.03994 15.5075 6.20633 15.3411 5.44094 14.9085C4.67555 14.4759 4.67555 14.7754 4.17638 16.173C3.77704 17.2047 4.24293 18.1364 4.24293 18.1364C4.24293 18.1364 5.14144 17.97 5.50749 16.9052Z"
                fill="#FF9D27"
            />
            <path
                d="M4.742 15.6073C5.0415 14.8419 5.0415 14.6755 5.44083 14.9085C5.87345 15.1414 5.77361 15.2412 5.47411 16.0066C5.27444 16.5724 4.77528 16.7055 4.77528 16.7055C4.77528 16.7055 4.54233 16.2063 4.742 15.6073Z"
                fill="#FDF516"
            />
            <path
                d="M5.04143 14.5424C4.60882 13.777 4.44243 13.9434 3.04476 14.4759C2.01314 14.8752 1.81348 15.7404 1.81348 15.7404C1.81348 15.7404 2.74526 16.2063 3.77687 15.807C5.17454 15.2745 5.47404 15.2745 5.04143 14.5424Z"
                fill="#FF9D27"
            />
            <path
                d="M4.3428 15.2413C5.10819 14.9418 5.27458 14.9418 5.04163 14.5424C4.80869 14.1098 4.70886 14.2096 3.94346 14.5091C3.37774 14.7088 3.24463 15.208 3.24463 15.208C3.24463 15.208 3.77707 15.4409 4.3428 15.2413Z"
                fill="#FDF516"
            />
            <path
                d="M3.97685 15.1747C4.70897 14.4426 4.77552 14.2429 5.24141 14.7088C5.7073 15.1747 5.50763 15.2413 4.77552 15.9734C4.2098 16.5391 3.51096 16.406 3.51096 16.406C3.51096 16.406 3.44441 15.7404 3.97685 15.1747Z"
                fill="#FDF516"
            />
            <path
                d="M5.50745 11.98C3.44422 8.51913 0.0166016 9.95008 0.0166016 9.95008C0.0166016 9.95008 4.90845 5.05823 8.20296 8.35274L5.50745 11.98Z"
                fill="#67CDFE"
            />
            <path
                d="M7.10479 9.41763L8.16968 8.35274C4.90845 5.05823 0.0166016 9.95008 0.0166016 9.95008C0.0166016 9.95008 4.30945 6.88852 7.10479 9.41763Z"
                fill="#1B6C93"
            />
            <path
                d="M8.00317 14.4759C11.4641 16.5391 10.0331 19.9668 10.0331 19.9668C10.0331 19.9668 14.925 15.0749 11.6305 11.7804L8.00317 14.4759Z"
                fill="#67CDFE"
            />
            <path
                d="M10.5656 12.8786L11.6305 11.8137C14.9251 15.1082 10.0332 20 10.0332 20C10.0332 20 13.0948 15.6739 10.5656 12.8786Z"
                fill="#1B6C93"
            />
            <path
                d="M15.5908 9.61731C11.6972 13.4775 7.4044 15.574 5.9069 14.0765C4.40939 12.579 6.5059 8.28619 10.3661 4.39268C14.8919 -0.133108 19.9169 0.0665592 19.9169 0.0665592C19.9169 0.0665592 20.0833 5.09152 15.5908 9.61731Z"
                fill="#C5D0D8"
            />
            <path
                d="M14.5924 8.51914C10.7322 12.3794 6.87193 14.9085 5.97342 14.01C5.07492 13.1115 7.60404 9.25125 11.4643 5.39102C15.9901 0.865225 19.9834 0 19.9834 0C19.9834 0 19.1182 3.99334 14.5924 8.51914Z"
                fill="url(#paint0_radial_34906_5480)"
            />
            <path
                d="M7.43751 15.1414C7.27112 15.3078 7.00489 15.3078 6.83851 15.1414L4.84183 13.1447C4.67544 12.9783 4.67544 12.6788 4.84183 12.5125L5.44083 11.9135L8.03651 14.5091L7.43751 15.1414Z"
                fill="#C94747"
            />
            <path
                d="M6.87178 14.5424C6.70539 14.7088 6.50573 14.7754 6.40589 14.6755L5.27445 13.5441C5.17461 13.4442 5.24117 13.2446 5.40756 13.0782L6.00656 12.4792L7.47078 13.9434L6.87178 14.5424Z"
                fill="#F15744"
            />
            <path
                d="M6.30611 15.3744C6.20628 15.4742 5.97333 15.4742 5.8735 15.3744L4.57566 14.0766C4.47583 13.9767 4.5091 13.7771 4.60894 13.6772L5.00827 13.2779L6.70544 14.9751L6.30611 15.3744Z"
                fill="#3E4347"
            />
            <path
                d="M6.04002 15.1082C5.94019 15.208 5.74052 15.2413 5.67397 15.1747L4.77547 14.2762C4.70891 14.2097 4.74219 14.0433 4.8753 13.9435L5.27463 13.5441L6.43936 14.7089L6.04002 15.1082Z"
                fill="#62727A"
            />
            <path
                d="M19.9167 0.0665917C19.9167 0.0665917 18.1197 3.60012e-05 15.6904 0.931816L19.0515 4.29288C19.9833 1.8636 19.9167 0.0665917 19.9167 0.0665917Z"
                fill="#C94747"
            />
            <path
                d="M19.9168 0.0665283C19.9168 0.0665283 18.4858 0.366029 16.3228 1.59731L18.386 3.66054C19.6173 1.49748 19.9168 0.0665283 19.9168 0.0665283Z"
                fill="#E53935"
            />
            <path
                d="M13.827 7.82028C14.7459 7.82028 15.4909 7.07533 15.4909 6.15639C15.4909 5.23744 14.7459 4.49249 13.827 4.49249C12.908 4.49249 12.1631 5.23744 12.1631 6.15639C12.1631 7.07533 12.908 7.82028 13.827 7.82028Z"
                fill="#1B6C93"
            />
            <path
                d="M13.8269 7.25457C14.4334 7.25457 14.9251 6.7629 14.9251 6.1564C14.9251 5.54989 14.4334 5.05823 13.8269 5.05823C13.2204 5.05823 12.7288 5.54989 12.7288 6.1564C12.7288 6.7629 13.2204 7.25457 13.8269 7.25457Z"
                fill="#455A64"
            />
            <path
                d="M10.4991 11.1481C11.418 11.1481 12.163 10.4031 12.163 9.48421C12.163 8.56526 11.418 7.82031 10.4991 7.82031C9.58016 7.82031 8.83521 8.56526 8.83521 9.48421C8.83521 10.4031 9.58016 11.1481 10.4991 11.1481Z"
                fill="#1B6C93"
            />
            <path
                d="M10.4993 10.5824C11.1058 10.5824 11.5975 10.0907 11.5975 9.48422C11.5975 8.87771 11.1058 8.38605 10.4993 8.38605C9.89279 8.38605 9.40112 8.87771 9.40112 9.48422C9.40112 10.0907 9.89279 10.5824 10.4993 10.5824Z"
                fill="#455A64"
            />
            <path
                d="M15.6239 1.63064C15.524 1.73048 15.3244 1.73048 15.2245 1.63064C15.1247 1.53081 15.1247 1.33114 15.2245 1.23131C15.3244 1.13147 15.524 1.13147 15.6239 1.23131C15.7237 1.33114 15.7237 1.53081 15.6239 1.63064Z"
                fill="white"
            />
            <path
                d="M16.1898 2.46256C16.3368 2.46256 16.456 2.34337 16.456 2.19634C16.456 2.04931 16.3368 1.93011 16.1898 1.93011C16.0428 1.93011 15.9236 2.04931 15.9236 2.19634C15.9236 2.34337 16.0428 2.46256 16.1898 2.46256Z"
                fill="white"
            />
            <path
                d="M16.9884 3.26121C17.1354 3.26121 17.2546 3.14201 17.2546 2.99498C17.2546 2.84795 17.1354 2.72876 16.9884 2.72876C16.8414 2.72876 16.7222 2.84795 16.7222 2.99498C16.7222 3.14201 16.8414 3.26121 16.9884 3.26121Z"
                fill="white"
            />
            <path
                d="M17.7538 4.05991C17.9008 4.05991 18.02 3.94072 18.02 3.79369C18.02 3.64666 17.9008 3.52747 17.7538 3.52747C17.6067 3.52747 17.4875 3.64666 17.4875 3.79369C17.4875 3.94072 17.6067 4.05991 17.7538 4.05991Z"
                fill="white"
            />
            <path
                d="M18.5524 4.82529C18.6994 4.82529 18.8186 4.7061 18.8186 4.55907C18.8186 4.41204 18.6994 4.29285 18.5524 4.29285C18.4053 4.29285 18.2861 4.41204 18.2861 4.55907C18.2861 4.7061 18.4053 4.82529 18.5524 4.82529Z"
                fill="white"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_34906_5480"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12.5 8) rotate(135) scale(14.1421 14.1389)"
                >
                    <stop offset="0.0112" stopColor="white" />
                    <stop offset="0.9995" stopColor="#BDBDBD" />
                </radialGradient>
            </defs>
        </SvgIcon>
    );
}
