import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetTemplatesQuery } from "@convin/redux/services/settings/auditManager.service";

const TemplateDrawerFilter = () => {
    const { data: templateList, isLoading: isTemplateListLoading } =
        useGetTemplatesQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const activeTemplateList = templateList?.filter((e) => !e.is_disabled);
    return (
        <GenericSingleSelect
            label="Choose a Template"
            autocompleteProps={{
                options: activeTemplateList || [],
                disableClearable: false,
            }}
            value={filtersState.template}
            setValue={(e) => {
                updateFiltersState({
                    template: e,
                    templateCategories: {},
                    templateQuestions: {},
                });
            }}
            className="w-full"
            loading={isTemplateListLoading}
        />
    );
};

export default TemplateDrawerFilter;
