import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "@convin/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import useNodeContext from "../../hooks/useNodeContext";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { useGetCallTypesQuery } from "@convin/redux/services/settings/callTypeManager.service";
import { useGetCallTagsQuery } from "@convin/redux/services/settings/callTagsManager.service";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import {
    AttributeBlockFormType,
    SingleResponseNode,
} from "../../../types/bert";
import { isDefined } from "@convin/utils/helper/common.helper";
import BlockHeader from "./BlockHeader";
import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import { CallTag, CallType } from "@convin/type/CallManager";
import CustomSkeletonLoader from "@convin/components/custom_components/Skeleton";

const attribute_types = [
    { id: "conversation_type", label: "Conversation Type" },
    { id: "type", label: "Type" },
    { id: "tags", label: "tags" },
];

export default function AttributeBlock({
    showCreateButton,
}: {
    showCreateButton?: boolean;
}) {
    const { createSingleResponseNode, updateSingleResponseNode } =
        useReactFlowStateContextProvider();
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeDataToUpdate,
        nodeIdToUpdate,
        blockParentId,
    } = useNodeContext();
    const { data: versionData } = useGetDomainConfigQuery();
    const meetingTypeOptions = [
        { id: "call", label: "Call" },

        ...(versionData?.has_chat
            ? [
                  { id: "chat", label: "Chat" },
                  { id: "email", label: "Email" },
              ]
            : []),
    ];

    const schema = yup.object().shape({
        name: yup.string().required("Name is Required"),
        attribute_type: yup.string().required("Attribute type is Required"),
        attribute: yup
            .array()
            .of(yup.mixed())
            .min(1, "Select at least one attribute"),
    });

    const methods = useForm<AttributeBlockFormType>({
        resolver: yupResolver(schema),
        values: isDefined(nodeDataToUpdate)
            ? { ...(nodeDataToUpdate as AttributeBlockFormType) }
            : {
                  name: "",
                  attribute_type: "",
                  attribute: [],
              },
    });

    const { watch, setValue } = methods;

    const attribute_type = watch("attribute_type");
    const { data: callTypes, isLoading: isTypesLoading } = useGetCallTypesQuery(
        undefined,
        {
            skip: attribute_type !== "type",
        }
    );
    const { data: callTags, isLoading: isTagsLoading } = useGetCallTagsQuery(
        undefined,
        {
            skip: attribute_type !== "tags",
        }
    );

    const { handleSubmit, control } = methods;

    const onSubmit = async (values: AttributeBlockFormType) => {
        if (isDefined(nodeIdToUpdate)) {
            updateSingleResponseNode<AttributeBlockFormType>({
                data: values,
                sourceId: nodeIdToUpdate,
                blockParentId,
            });
        } else
            createSingleResponseNode<SingleResponseNode<"attribute">>({
                data: {
                    type: "attribute",
                    is_not: false,
                    metadata: values,
                },
                sourceId,
                direction,
                blockParentId,
            });
        onNodeSaveCallBack();
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <BlockHeader
                title={"Attribute"}
                showCreateButton={showCreateButton}
            />
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Stack gap={1.5}>
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Block Name"
                        label="Block Name"
                    />
                    <RHFSelect
                        name="attribute_type"
                        className="w-full nodrag nopan"
                        variant="outlined"
                        size="small"
                        label="Select Attribute Type"
                        options={attribute_types}
                        onChangeCallback={() => {
                            setValue("attribute", []);
                        }}
                    />
                    {isTagsLoading || isTypesLoading ? (
                        <CustomSkeletonLoader rows={2} />
                    ) : (
                        <Controller
                            name="attribute"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <GenericMultipleSelect<
                                    | CallTag
                                    | CallType
                                    | (typeof meetingTypeOptions)[0]
                                >
                                    label="Select Attribute"
                                    autocompleteProps={{
                                        size: "small",
                                        options: attribute_type
                                            ? attribute_type ===
                                              "conversation_type"
                                                ? meetingTypeOptions
                                                : attribute_type === "tags"
                                                ? callTags ?? []
                                                : callTypes ?? []
                                            : [],
                                        className: "nowheel nodrag nopan",
                                    }}
                                    values={value ?? []}
                                    setValues={(value) => onChange(value)}
                                    className="w-full nowheel nodrag nopan"
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    )}
                </Stack>
            </Box>
        </FormProvider>
    );
}
