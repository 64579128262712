import { ChevronDownSvg, CloseSvg } from "@convin/components/svg";
import { isDefined } from "@convin/utils/helper/common.helper";

import { Box, Typography, Button } from "@mui/material";
import useNodeContext from "../../hooks/useNodeContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";

export default function BlockHeader({
    showCreateButton,
    title,
}: {
    showCreateButton?: boolean;
    title: string;
}) {
    const {
        aiConfigState: { activeDefaultBlock },
    } = useParameterAiConfigContext();
    const { isSaving } = useReactFlowStateContextProvider();
    const { dispatch, nodeIdToUpdate, onNodeSaveCallBack } = useNodeContext();
    return (
        <Box
            className="flex items-center justify-between flex-shrink-0"
            sx={{ mb: 2 }}
        >
            <div className="flex items-center">
                <button
                    onClick={() => {
                        if (isDefined(nodeIdToUpdate)) return;
                        dispatch({
                            payload: { selectedBlockType: null },
                            type: "UPDATE",
                        });
                    }}
                    className="flex items-center cursor-pointer block--config--back__button"
                >
                    {!isDefined(nodeIdToUpdate) && (
                        <ChevronDownSvg
                            sx={{
                                transform: "rotate(270deg)",
                                width: 16,
                                height: 16,
                                cursor: "pointer",
                            }}
                        />
                    )}
                </button>
                <Typography variant="medium16" sx={{ fontWeight: 600, ml: 1 }}>
                    {title}
                </Typography>
            </div>
            <Box
                sx={{
                    ...(isDefined(activeDefaultBlock) && {
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                    }),
                }}
                className="flex"
                gap={2}
            >
                {isDefined(nodeIdToUpdate) ? (
                    <>
                        {isDefined(activeDefaultBlock) && (
                            <Button
                                variant="outlined"
                                onClick={onNodeSaveCallBack}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            type="submit"
                            className={
                                isDefined(activeDefaultBlock)
                                    ? "h-[40px]"
                                    : "h-[30px]"
                            }
                            variant="global"
                            loading={isSaving}
                        >
                            Save
                        </Button>
                    </>
                ) : showCreateButton ? (
                    <Button
                        type="submit"
                        className={
                            isDefined(activeDefaultBlock)
                                ? "h-[40px]"
                                : "h-[30px]"
                        }
                        variant="global"
                        loading={isSaving}
                    >
                        {isDefined(activeDefaultBlock) ? "Add Block" : "Create"}
                    </Button>
                ) : null}

                <div className="h-[30px] block_close_button nodrag nopan cursor-pointer">
                    <CloseSvg className="pointer-events-none" />
                </div>
            </Box>
        </Box>
    );
}
