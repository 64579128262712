import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import ThreeDotsWave from "../ThreeDotsWave";
import { Box, Stack, SxProps, Theme } from "@mui/material";
import { isDefined } from "@convin/utils/helper/common.helper";
import useWindowSize from "@convin/hooks/useWindowSize";
import useDebounce from "@convin/hooks/useDebounce";
import SkeletonLoader from "../Loader/SkeletonLoader";
import { useVirtualizer } from "@tanstack/react-virtual";

export default function VirtualGrid<
    T,
    U extends Record<string, unknown> = Record<string, unknown>,
    V = Record<string, T[]>
>({
    data = {} as V,
    Component,
    rowSize = 1000,
    columnCount,
    hasNext,
    fetchNext,
    isFetching,
    isLoading,
    sx,
    headerField,
    HeaderComponent,
    ...rest
}: {
    data?: V;
    Component: React.FC<
        {
            prevIndexedData: T | null;
            nextIndexedData: T | null;
            data: T;
            index: number;
        } & { [key in keyof U]: U[key] }
    >;
    rowSize?: number;
    columnCount: number;
    hasNext?: boolean;
    fetchNext?: () => unknown;
    isFetching?: boolean;
    sx?: SxProps<Theme>;
    isLoading?: boolean;
    headerField?: keyof T;
    HeaderComponent?: React.FC<
        {
            prevIndexedData: T | null;
            nextIndexedData: T | null;
            data: T;
            index: number;
        } & { [key in keyof U]: U[key] }
    >;
} & U) {
    const dims = useWindowSize();
    const debouncedDims = useDebounce(dims, 500);

    const parentRef = useRef<HTMLDivElement | null>(null);

    const gridData = useMemo(() => {
        return Object.entries<T[]>(data ?? {}).reduce(
            (prev, [_, curr]) => [
                ...prev,
                ...(isDefined(headerField) &&
                prev?.length &&
                prev?.length % columnCount
                    ? Array(columnCount - (prev?.length % columnCount)).fill(
                          null
                      )
                    : []),
                ...(curr ?? []),
            ],
            [] as T[]
        );
    }, [data, columnCount]);

    const columnWidth = useMemo(() => {
        return Math.floor(
            (parentRef.current?.clientWidth ?? dims[0]) / columnCount
        );
    }, [debouncedDims, columnCount]);

    const rowVirtualizer = useVirtualizer({
        count: Math.ceil(
            hasNext ? gridData.length + 1 : gridData.length / columnCount
        ),
        getScrollElement: () => parentRef.current,
        estimateSize: useCallback(() => rowSize, [rowSize]),
    });

    const columnVirtualizer = useVirtualizer({
        count: columnCount,
        getScrollElement: () => parentRef.current,
        horizontal: true,
        estimateSize: useCallback(() => columnWidth, [columnWidth]),
        overscan: 5,
    });

    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem) {
            return;
        }
        if (!fetchNext) {
            return;
        }

        if (lastItem.index === gridData.length && hasNext && !isFetching) {
            fetchNext();
        }
    }, [rowVirtualizer.getVirtualItems()]);

    return (
        <Box
            sx={{
                ...sx,
            }}
            ref={parentRef}
            className="w-full h-full overflow-x-hidden overflow-y-auto"
        >
            <Stack
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: "100%",
                    position: "relative",
                }}
                data-testid="virtual-list-container"
            >
                {columnWidth ? (
                    rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const { index: rowIndex, start, key } = virtualRow;
                        return (
                            <div
                                key={key}
                                data-index={rowIndex}
                                ref={rowVirtualizer.measureElement}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    transform: `translateY(${start}px)`,
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                {columnVirtualizer
                                    .getVirtualItems()
                                    .map(
                                        ({
                                            index: columnIndex,
                                            key: columnKey,
                                        }) => {
                                            const dataIndex =
                                                rowIndex * columnCount +
                                                columnIndex;
                                            const isLoaderRow =
                                                dataIndex > gridData.length - 1;
                                            const prevIndexedData =
                                                dataIndex !== 0
                                                    ? gridData.at(
                                                          dataIndex - 1
                                                      ) ?? null
                                                    : null;
                                            const currIndexedData =
                                                gridData.at(dataIndex) ?? null;
                                            const nextIndexedData =
                                                gridData.at(dataIndex + 1) ??
                                                null;
                                            const isHeaderRow =
                                                isDefined(headerField) &&
                                                isDefined(currIndexedData) &&
                                                prevIndexedData?.[
                                                    headerField
                                                ] !==
                                                    currIndexedData?.[
                                                        headerField
                                                    ];

                                            return (
                                                <Fragment key={columnKey}>
                                                    {isHeaderRow &&
                                                    isDefined(
                                                        HeaderComponent
                                                    ) ? (
                                                        <div
                                                            style={{
                                                                height: 50,
                                                                width:
                                                                    columnWidth *
                                                                    columnCount,
                                                            }}
                                                        >
                                                            <HeaderComponent
                                                                {...{
                                                                    prevIndexedData,
                                                                    nextIndexedData,
                                                                    data: currIndexedData,
                                                                    index: dataIndex,
                                                                    isLoading,
                                                                    ...(rest as unknown as U),
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        style={{
                                                            height: rowSize,
                                                            width: columnWidth,
                                                            paddingRight:
                                                                "12px",
                                                        }}
                                                    >
                                                        {isLoaderRow ? (
                                                            hasNext ? (
                                                                <ThreeDotsWave />
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : isDefined(
                                                              currIndexedData
                                                          ) ? (
                                                            <Component
                                                                {...{
                                                                    prevIndexedData,
                                                                    nextIndexedData,
                                                                    data: currIndexedData,
                                                                    index: dataIndex,
                                                                    isLoading,
                                                                    ...(rest as unknown as U),
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Fragment>
                                            );
                                        }
                                    )}
                            </div>
                        );
                    })
                ) : (
                    <SkeletonLoader />
                )}
            </Stack>
        </Box>
    );
}
