import { START_DATE_SORT_KEY } from "@constants/Account/index";
import callsConfig from "@constants/MyCalls/index";
import TopbarConfig from "@constants/Topbar/index";

export const USER_TYPES = {
    observer: 0,
    product: 1,
};

const initStae = {
    common: {
        gsText: "",
        showLoader: false,
        domain: "app",
        tags: [],
        call_types: [],
        clients: [],
        nextClientsUrl: null,
        topics: [],
        fields: {},
        users: [],
        teams: [],
        salesTasks: [],
        nextSalesTaskUrl: null,
        salesTaskNextLoading: false,
        activeReportType: null,
        activeCallTag: [],
        filterTeams: {
            teams: [{ id: 0, name: "All Teams" }],
            active: [],
        },
        filterReps: {
            reps: [{ id: 0, name: "All" }],
            active: [],
            loading: false,
        },
        filterCallTypes: {
            callTypes: [{ 0: "All" }],
            active: 0,
        },
        filterAuditTemplates: {
            templates: [],
            active: null,
        },
        filterCallDuration: {
            options: {
                0: {
                    value: [0, null],
                    text: "Any",
                },
                1: {
                    value: [0, 2],
                    text: ["Below 2 min"],
                },
                2: {
                    value: [2, null],
                    text: ["Above 2 min"],
                },
                3: {
                    value: [0, 5],
                    text: ["Below 5 min"],
                },
                4: {
                    value: [5, null],
                    text: ["Above 5 min"],
                },
                5: {
                    value: [10, null],
                    text: ["Above 10 min"],
                },
                6: {
                    value: [15, null],
                    text: ["Above 15 min"],
                },
            },
            active: TopbarConfig.defaultDuration,
        },
        filterDates: {
            dates: {
                [TopbarConfig.dateKeys.all]: {
                    name: TopbarConfig.ALLLABEL,
                    dateRange: TopbarConfig.ALLDATERANGE,
                },
                [TopbarConfig.dateKeys.today]: {
                    name: TopbarConfig.TODAYLABEL,
                    dateRange: TopbarConfig.TODAYDATERANGE,
                    label: "Today from midnight until the current time",
                },
                [TopbarConfig.dateKeys.yesterday]: {
                    name: TopbarConfig.YESTERDAYLABEL,
                    dateRange: TopbarConfig.YESTERDAYDATERANGE,
                    label: "The previous 24 hour day",
                },
                [TopbarConfig.dateKeys.week]: {
                    name: TopbarConfig.WEEKLABEL,
                    dateRange: TopbarConfig.WEEKDATERANGE,
                    label: "The current calendar week",
                },
                [TopbarConfig.dateKeys.last_week]: {
                    name: TopbarConfig.LASTWEEKLABEL,
                    dateRange: TopbarConfig.LASTWEEKDATERANGE,
                    label: "The previous calendar week",
                },
                [TopbarConfig.dateKeys.last30days]: {
                    name: TopbarConfig.LAST30DAYS,
                    dateRange: TopbarConfig.LAST30DAYSRANGE,
                    label: "The Previous 30 Days Including Today",
                },
                [TopbarConfig.dateKeys.month]: {
                    name: TopbarConfig.MONTHLABEL,
                    dateRange: TopbarConfig.MONTHDATERANGE,
                    label: "The current calendar month",
                },
                [TopbarConfig.dateKeys.last_month]: {
                    name: TopbarConfig.LASTMONTHLABEL,
                    dateRange: TopbarConfig.LASTMONTHDATERANGE,
                    label: "The previous month",
                },
                [TopbarConfig.dateKeys.current_quater]: {
                    name: TopbarConfig.CURRENTQUATERLABEL,
                    dateRange: TopbarConfig.CURRENTQUATER,
                    label: "The current quarter",
                },
                [TopbarConfig.dateKeys.prevs_quater]: {
                    name: TopbarConfig.PREVQUATERLABEL,
                    dateRange: TopbarConfig.PREVQUATER,
                    label: "The previous full quarter",
                },
                [TopbarConfig.dateKeys.custom]: {
                    name: TopbarConfig.CUSTOMLABEL,
                    dateRange: [],
                },
                [TopbarConfig.dateKeys.rd7]: {
                    name: TopbarConfig.RD7DAYS,
                    dateRange: TopbarConfig.RD7RANGE,
                    is_rolling_date: true,
                    label: "The Previous 07 Days Excluding Today",
                },
                [TopbarConfig.dateKeys.rd14]: {
                    name: TopbarConfig.RD14DAYS,
                    dateRange: TopbarConfig.RD14RANGE,
                    is_rolling_date: true,
                    label: "The Previous 14 Days Excluding Today",
                },
                [TopbarConfig.dateKeys.rd30]: {
                    name: TopbarConfig.RD30DAYS,
                    dateRange: TopbarConfig.RD30RANGE,
                    is_rolling_date: true,
                    label: "The Previous 30 Days Excluding Today",
                },
                [TopbarConfig.dateKeys.rd60]: {
                    name: TopbarConfig.RD60DAYS,
                    dateRange: TopbarConfig.RD60RANGE,
                    is_rolling_date: true,
                    label: "The Previous 60 Days Excluding Today",
                },
            },
            active: TopbarConfig.defaultDate,
        },
        didFiltersChange: false,
        versionData: {},
    },
    auth: {
        username: "",
        email: "",
        first_name: "",
        isAuthenticated: false,
        isChecking: true,
        formError: {
            status: false,
            message: "",
        },
        isSignUp: false,
        hasConflict: false,
    },

    search: {
        fetchingCalls: false,
        fields: {},
        calls: [],
        count: 0,
        next_url: "",
        trackers: {
            count: 0,
            next: null,
            prev: null,
            results: [],
        },
        searchUrls: {},
        confTools: [],
        searchFilters: {
            is_loading: false,
            client: null,
            keywords: [],
            keyword_present_in_call: true,
            keyword_said_by_rep: null,
            keyword_said_by_others: null,
            call_tags: [],
            call_types: [],
            // call_types: null,
            no_of_questions_by_rep: [0, 100],
            no_of_questions_by_others: [0, 100],
            topic: null,
            topic_in_call: true,
            interactivity: {
                clientLongestMonologue: [0, 100],
                clientTalkRatio: [0, 100],
                interactivity: [-10, 10],
                ownerLongestMonologue: [0, 100],
                ownerTalkRatio: [0, 100],
            },
            recording_medium: null,
            processing_status: "processed",
            template: null,
            activeTemplate: null,
            activeQuestions: {},
            activeCategoriesScore: {},
            conversationScore: {},
            auditQuestions: [],
            audit_filter: {
                audit_type: null,
                auditors: [],
                manualAuditDateRange: [null, null],
            },
            min_aiscore: null,
            max_aiscore: null,
            min_patience: 0,
            max_patience: 100,
            min_interruption_count: null,
            max_interruption_count: null,
            sortKey: null,
            stage: null,
            min_talktime: null,
            max_talktime: null,
            lead_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            csat_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            collection_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            audit_feedback_status: undefined,
            is_draft: false,
        },
        defaultSearchFilters: {
            client: null,
            keywords: [],
            keyword_present_in_call: true,
            keyword_said_by_rep: null,
            keyword_said_by_others: null,
            call_tags: [],
            call_types: [],
            no_of_questions_by_rep: [0, 100],
            no_of_questions_by_others: [0, 100],
            topic: null,
            topic_in_call: true,
            interactivity: {
                clientLongestMonologue: [0, 100],
                clientTalkRatio: [0, 100],
                interactivity: [-10, 10],
                ownerLongestMonologue: [0, 100],
                ownerTalkRatio: [0, 100],
            },
            recording_medium: null,
            processing_status: "processed",
            auditQuestions: [],
            activeQuestions: {},
            conversationScore: {},
            activeCategoriesScore: {},
            template: null,
            activeTemplate: null,
            audit_filter: {
                audit_type: null,
                auditors: [],
                manualAuditDateRange: [null, null],
            },
            min_aiscore: null,
            max_aiscore: null,
            min_patience: 0,
            max_patience: 100,
            min_interruption_count: null,
            max_interruption_count: null,
            sortKey: null,
            stage: null,
            min_talktime: null,
            max_talktime: null,
            lead_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            csat_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            collection_config: {
                is_hot: false,
                is_warm: false,
                is_cold: false,
            },
            audit_feedback_status: undefined,
            is_draft: false,
        },
        activeFilters: [],
        views: [
            {
                id: 0,
                name: "Completed Calls",
                is_default: false,
            },
        ],
        samplingRules: [],
        activeSamplingRule: null,
        samplingRulesLoading: false,
        defaultView: null,
    },
    assistant: {
        bot: {},
        recorder: {},
    },
    settings: {
        designations: [],
        invited: [],
        available_subscriptions: [],
        third_party_integrations: [],
        user_types: [
            {
                id: USER_TYPES.observer,
                name: "Observer",
            },
            {
                id: USER_TYPES.product,
                name: "Product User",
            },
        ],
        crm_sheets: [],
        default_crm_sheet: null,
    },
    stats: {
        idealRanges: [],
        topicDuration: {},
        loading: false,
    },

    callAudit: {
        //Data for settings section
        newCallObjId: null,
        templates: [],
        availableTeams: {},
        nonAvailableTeams: {},
        categories: [],
        questions: [],
        disableLoading: false,
        filter: null,
        deletedFilters: [],
        globalExpressions: {},
        //Data for call details section
        auditTemplate: null,
        allAuditTemplate: null,
        scoreDetails: [],
        saving: {
            savingNote: false,
            question_id: null,
            savingResponse: false,
            savingMedia: false,
        },
        aiAuditScore: null,
        auditDone: null,
        aiAuditLoading: true,
        questionList: {},
        showAuditIncomplete: false,
        callAuditOverallDetails: {},
        accountAuditOverallDetails: {},
        loading: false,
        searchAuditTemplate: {},
        //Data for Lead Score Creation
        leadScore: null,
        filtersSettings: {
            template: null,
            categories: [],
        },
        isAccountLevel: false,
        runCommandLoading: false,
        parametersLoading: false,
        word_cloud: [],
        audit_filter: {
            audit_type: null,
            auditors: [],
        },
        scoreDetailsLoading: false,
    },
};

export default initStae;
