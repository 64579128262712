import { pxToRem } from "@convin/utils/getFontValue";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const stringifyErrorObject = (obj) => {
    return Array.isArray(obj) && obj?.length
        ? typeof obj?.[0] === "string"
            ? obj?.[0]
            : JSON.stringify(obj)
        : JSON.stringify(obj);
};

export const getErrorMessage = (payload) => {
    const errRes =
        payload?.data instanceof Blob
            ? payload?.message ?? "Something Went Wrong"
            : payload?.data?.detail ||
              payload?.data?.message ||
              payload?.message ||
              payload?.data;

    const status = payload?.status;

    let message =
        status === 404
            ? "The resource doesn't exist or You don't have access to it"
            : Array.isArray(errRes) && errRes?.length
            ? typeof errRes?.[0] === "string"
                ? errRes?.[0]?.length === 1
                    ? errRes
                    : errRes?.[0]
                : errRes?.[0]?.detail ||
                  Object.values(
                      errRes.response.data?.[0] || {
                          detail: "Something went wrong",
                      }
                  )?.[0]
            : typeof errRes === "object"
            ? stringifyErrorObject(Object.values(errRes)?.[0])
            : JSON.stringify(errRes) ||
              payload?.data?.error ||
              payload?.data?.[Object.keys(payload?.data)]?.[0] ||
              "Something went wrong";
    if (
        typeof message === "string" &&
        message.toLowerCase().includes("<title>server error (500)</title>")
    ) {
        message = "Internal Server Error (500)";
    }
    return message;
};

export const rtkQueryErrorLogger = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        const status = action?.payload?.status;

        if (typeof status === "string") {
            return next(action);
        }

        if (
            action?.meta?.arg?.endpointName === "createBulkUser" ||
            action?.meta?.arg?.endpointName === "inviteBulkUser" ||
            action?.meta?.arg?.endpointName === "getConversationTranscript" ||
            action?.meta?.arg?.endpointName === "getAiTagedTranscripts" ||
            action?.meta?.arg?.endpointName === "getVoicePrint" ||
            action?.meta?.arg?.endpointName === "getConversationMediaUrl" ||
            action?.meta?.arg?.endpointName === "getConversationTranscript" ||
            action?.meta?.arg?.endpointName === "getAIAuditConfig" ||
            action?.meta?.arg?.endpointName === "batchUpdateQuestion" ||
            (action?.meta?.arg?.endpointName === "submitAudit" &&
                status === 400) ||
            status === 302 ||
            !status
        ) {
            return next(action);
        }

        let message = getErrorMessage(action?.payload);
        if (status !== 401)
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

                //If client error i.e : 400, 403 etc , hide progress bar and show at bottom left
                ...(status % 400 < 100
                    ? {
                          hideProgressBar: true,
                          position: "bottom-left",
                          theme: "dark",

                          style: {
                              width: "640px",
                              minHeight: "48px",
                              left: "80px",
                              backgroundColor: "#333333",
                              fontSize: pxToRem(16),
                          },
                      }
                    : {}),
            });
    }

    return next(action);
};
