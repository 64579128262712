import Dot from "@convin/components/custom_components/Dot";
import { Comment } from "@convin/type/Common";
import { getDisplayName } from "@convin/utils/helper";
import { isDefined, stringAvatar } from "@convin/utils/helper/common.helper";
import { getDateTime } from "@convin/utils/helper/date.helper";
import {
    Avatar,
    Divider,
    Stack,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import VideoPreview from "../../MediaRecorder/VideoPreview";
import MoreOptions from "@convin/components/custom_components/Popover/MoreOptions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import useCommentInputState from "../hooks/useCommentInputState";
import CommentInput from "./CommentInput";
import useAuth from "@convin/hooks/useAuth";
import useCommentsState from "../hooks/useCommentsState";
import { DeleteSvg } from "@convin/components/svg";
import { useDeleteCommentMutation } from "@convin/redux/services/comment/comment.service";
import AudioPreview from "../../MediaRecorder/AudioPreview";
import useMediaPlayerContext from "@convin/modules/conversationDetails/hooks/useMediaPlayerContext";
import useCommentsDrawerStateContext from "../hooks/useCommentsDrawerStateContext";
import CommentTranscriptPreview from "./CommentTranscriptPreview";

const CommentTimeStamp = ({ timestamp }: { timestamp: string }) => {
    const { handleSeek } = useMediaPlayerContext();
    const [ss = 0, mm = 0, hh = 0] = timestamp.split(":").map(Number).reverse();
    const time = hh * 3600 + mm * 60 + ss || 0;
    return (
        <Typography
            onClick={() => handleSeek(time)}
            component="pre"
            color="primary.main"
            className="font-medium cursor-pointer inline"
        >
            @{timestamp}
        </Typography>
    );
};

function FormatComment({ comment }: { comment: string }) {
    const { dashboardType } = useCommentsDrawerStateContext();
    const timestampRegex = /(@\d{1,2}:\d{2}(?::\d{2})?)/g;
    const nameTaggingRegex = /;\#([^()]+)\((\d+)\)#;/g;
    const { palette } = useTheme();
    const renderComment = () => {
        const parts = comment.split(timestampRegex);
        return parts.map((part: string | undefined, index) => {
            if (part && part.match(timestampRegex)) {
                return dashboardType === "account" ? (
                    <Typography key={index} component="pre" className="inline">
                        {part}
                    </Typography>
                ) : (
                    <CommentTimeStamp
                        timestamp={part
                            .split("")
                            .filter((e) => e !== "@")
                            .join("")}
                        key={index}
                    />
                );
            }
            const formattedText = part?.replace(
                nameTaggingRegex,
                `<span style="color: ${palette.primary.main};font-weight: 500">@$1</span>`
            );
            return (
                <span
                    key={index}
                    dangerouslySetInnerHTML={{ __html: formattedText ?? <></> }}
                />
            );
        });
    };

    return renderComment();
}

const CommentCard: React.FC<{
    data: Comment;
}> = ({ data }) => {
    const {
        id: conversationId,
        dashboard_type,
        setCommentToReply,
        commentToReply,
        type,
    } = useCommentsState();
    const [deleteComment, { isLoading: isDeletingComment }] =
        useDeleteCommentMutation();
    const { state, dispatch } = useCommentInputState();
    const { user } = useAuth();
    const { commentIdToEdit } = state;
    const {
        id,
        comment,
        owner,
        created,
        replies_count,
        media,
        parent,
        is_disabled,
        media_type,
        mentioned_users,
        transcript,
    } = data;

    const isEditActive = isDefined(commentIdToEdit) && commentIdToEdit === id;
    const isOwner = owner?.id === user?.id;
    const isReplyThread = isDefined(commentToReply);
    return (
        <Fragment key={id}>
            <Box
                sx={(theme) => ({
                    p: 2.5,
                    ...(isDefined(parent) && {
                        borderLeft: "1px solid #333",
                        borderLeftColor: alpha(
                            theme.palette.textColors["333"],
                            0.1
                        ),
                    }),
                })}
            >
                <Box className="flex items-center justify-between">
                    <Stack
                        direction="row"
                        className="flex items-center"
                        gap={1}
                        sx={{
                            mb: 1.5,
                        }}
                    >
                        {type === "comment" && (
                            <Avatar
                                {...stringAvatar(owner.first_name)}
                                sx={{
                                    height: "32px",
                                    width: "32px",
                                }}
                            />
                        )}

                        <Typography className="font-medium" variant="medium">
                            {getDisplayName(owner)}
                        </Typography>
                        <Dot bgcolor="#999" className="h-[4px] w-[4px]" />
                        <Typography variant="medium" color={"textColors.999"}>
                            {getDateTime({
                                isoDate: created,
                                format: "dd MM, YYYY | T",
                            })}
                        </Typography>
                    </Stack>

                    {isEditActive ||
                        (isOwner && !is_disabled && type === "comment" && (
                            <MoreOptions
                                classes={{
                                    paper: "add__menu--dropdown--paper",
                                }}
                            >
                                <Box
                                    onClick={() => {
                                        dispatch({
                                            type: "UPDATE",
                                            payload: {
                                                commentIdToEdit: id,
                                                comment,
                                                mentioned_users,
                                            },
                                        });
                                    }}
                                    sx={{
                                        p: 1.5,
                                    }}
                                    gap={1}
                                    className="flex items-center"
                                    component={"button"}
                                >
                                    <EditIcon />
                                    Edit Comment
                                </Box>
                                <Divider />
                                <DeleteButton
                                    onDelete={() =>
                                        deleteComment({
                                            commentId: id,
                                            id: conversationId,
                                            dashboard_type,
                                            parent,
                                            type,
                                        })
                                    }
                                    isLoading={isDeletingComment}
                                    message="Are you sure you want to delete this comment?"
                                    DeleteOptionComponent={
                                        <Box
                                            sx={{
                                                p: 1.5,
                                            }}
                                            className="flex items-center"
                                            gap={1}
                                        >
                                            <DeleteIcon />
                                            Delete Comment
                                        </Box>
                                    }
                                />
                            </MoreOptions>
                        ))}
                </Box>
                {isEditActive ? (
                    <CommentInput />
                ) : (
                    <>
                        {isDefined(transcript) && (
                            <CommentTranscriptPreview {...{ transcript }} />
                        )}

                        <Typography
                            sx={{
                                color: "textColors.666",
                                pre: {
                                    m: 0,
                                    p: 0,
                                },
                            }}
                            className="leading-[24px] "
                            component="div"
                        >
                            {is_disabled && <DeleteSvg />}
                            {comment === "type_media" ? (
                                ""
                            ) : (
                                <FormatComment {...{ comment }} />
                            )}
                        </Typography>
                        {isDefined(media) ? (
                            media_type === "mp3" ? (
                                <AudioPreview
                                    url={media}
                                    previewType="comment_media"
                                />
                            ) : (
                                <VideoPreview
                                    url={media}
                                    previewType="comment_media"
                                />
                            )
                        ) : (
                            <></>
                        )}
                        {!isDefined(parent) && !isReplyThread && (
                            <Box className="flex justify-end">
                                <Typography
                                    className="flex items-center"
                                    variant="medium"
                                    color="primary.main"
                                    component="button"
                                    onClick={() => setCommentToReply(data)}
                                >
                                    <ReplyIcon /> Reply
                                </Typography>
                            </Box>
                        )}

                        {!isReplyThread && !!replies_count && (
                            <Typography
                                className="flex items-center"
                                variant="medium"
                                color="textColors.666"
                                sx={(theme) => ({
                                    pl: 1,
                                    borderLeft: "1px solid",
                                    borderColor: alpha(
                                        theme.palette.textColors[999],
                                        0.2
                                    ),
                                })}
                                component={"button"}
                                onClick={() => setCommentToReply(data)}
                            >
                                View {replies_count} replies
                            </Typography>
                        )}
                    </>
                )}
            </Box>
            {isDefined(parent) || <Divider />}
        </Fragment>
    );
};

export default CommentCard;
