import {
    Box,
    Divider,
    ListItemButton,
    ListItemText,
    useTheme,
} from "@mui/material";
import React, { ReactElement, useCallback } from "react";

import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import { Label } from "@convin/components/custom_components/Typography/Label";
import { Option } from "@convin/components/select/GenericSelect";
import MeetingSelectorSvg from "@convin/components/svg/OverallFiters/MeetingSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { MeetingTypeConst } from "@convin/type/Common";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox, filterPopoverSx } from "../../../styles";
import { durationkeys } from "@convin/config/default.config";

type MeetingTypeOption = Option<Exclude<MeetingTypeConst, null>>;

export default function GenericFilter(): ReactElement {
    const theme = useTheme();
    const { data: versionData } = useGetDomainConfigQuery();

    const { state, updateState, handleTemplateSelection } =
        useGlobalFiltersStateContext();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const handleChange = (option: MeetingTypeOption): void => {
        updateState({
            meetingType: option.id,
            ...(option.id !== "call" && { durationKey: durationkeys.any }),
        });
        handleTemplateSelection(state.teams, option.id);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const options = [
        { id: "call", value: "Call" },
        ...(versionData?.has_chat
            ? [
                  { id: "chat", value: "Chat" },
                  { id: "email", value: "Email" },
              ]
            : []),
    ] as MeetingTypeOption[];

    return (
        <>
            <StyledFilterBox onClick={handleClick} aria-describedby={id}>
                <MeetingSelectorSvg />
                <Label
                    isEllipses
                    hasTooltip
                    colorType="white"
                    className="flex-1 font-medium"
                    variant="medium"
                >
                    {
                        options.find(
                            (option) => option.id === state.meetingType
                        )?.value
                    }
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={filterPopoverSx(theme)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Label colorType="666" variant="medium" sx={{ p: 1.5 }}>
                    Select Conversation Type
                </Label>
                <Divider />
                <Box className="flex-1">
                    {options.map((option) => (
                        <ListItemButton
                            onClick={() => handleChange(option)}
                            key={option.id}
                            component="button"
                            sx={{ width: "100%" }}
                        >
                            <ListItemText>{option.value}</ListItemText>
                        </ListItemButton>
                    ))}
                </Box>
            </CustomPopover>
        </>
    );
}
