import React, { useCallback, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
    Box,
    Typography,
    TextField,
    Button,
    ClickAwayListener,
} from "@mui/material";
import MomentPhraseCard from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/components/MomentPhraseCard";
import { nanoid } from "@reduxjs/toolkit";
import { MomentBlockFormType } from "@convin/modules/settings/modules/auditManager/components/types/bert";
import AIRecommandationModal from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/components/AiRecommendation/AIRecommendationModal";
import { CloseSvg } from "@convin/components/svg";
import { Label } from "@convin/components/custom_components/Typography/Label";

const PhraseConfirmDialog = ({
    open,
    handleClose,
    handleButtonClick,
}: {
    open: boolean;
    handleClose: () => void;
    handleButtonClick: ({ multiline }: { multiline: boolean }) => void;
}) => {
    return (
        open && (
            <ClickAwayListener onClickAway={handleClose}>
                <Box
                    sx={{
                        width: "max-context",
                        display: "inline",
                        zIndex: "0",
                        p: 2,
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            position: "absolute",
                            width: theme.spacing(40),
                            background: theme.palette.common.white,
                            zIndex: 99,
                            left: theme.spacing(4),
                            padding: 2,
                            boxShadow:
                                "0px 4px 20px 0px rgba(51, 51, 51, 0.10)",
                            top: "-232px",
                            borderRadius: `${theme.shape.borderRadius}px`,
                            bgcolor: theme.palette.background.paper,
                        })}
                    >
                        <div className="flex items-center">
                            <Label
                                colorType="333"
                                className="flex-1 font-semibold"
                            >
                                Add
                            </Label>
                            <div onClick={handleClose} className="w-5">
                                <CloseSvg />
                            </div>
                        </div>
                        <Label colorType="333" sx={{ py: 2 }}>
                            Create a phrase for each line or combine all into
                            one.
                        </Label>
                        <div className="flex flex-col gap-[8px]">
                            <Button
                                variant="global"
                                onClick={() => {
                                    handleButtonClick({ multiline: false });
                                    handleClose();
                                }}
                            >
                                Combine Phrases
                            </Button>
                            <Button
                                variant="global"
                                onClick={() => {
                                    handleButtonClick({ multiline: true });
                                    handleClose();
                                }}
                            >
                                Create Individual Phrase
                            </Button>
                        </div>
                    </Box>
                </Box>
            </ClickAwayListener>
        )
    );
};

/**
 * RHFPhraseListField - A React Hook Form controlled component for managing a list of phrases
 * Provides functionality to add, display, and remove phrases with an AI recommendation feature
 * @component
 *
 * @example
 * ```tsx
 * <FormProvider {...methods}>
 *   <RHFPhraseListField />
 * </FormProvider>
 * ```
 */
export default function RHFPhraseListField() {
    /** Access form context and methods from React Hook Form */
    const { control, watch } = useFormContext<MomentBlockFormType>();

    /** State for managing the new phrase input */
    const [open, setOpen] = useState(false);
    const [phraseToAdd, setPhraseToAdd] = useState("");

    /** Watch the phrases array from form state */
    const phrases = watch("phrase");

    /** Field array methods for managing the list of phrases */
    const { append, remove } = useFieldArray({
        control,
        name: "phrase",
    });

    /**
     * Handles adding a new phrase to the list
     * @param {string} phrase - The phrase to be added
     */
    const handleAddPhrase = (phrase: string) => {
        if (phrase) {
            const phrases = phrase.split("\n");
            if (phrases.length > 1) setOpen(true);
            else addPhrases([phrase]);
        }
    };

    const convertPhrases = useCallback(
        ({ multiline }: { multiline: boolean }) => {
            let phrasesToBeAdded = [];
            if (multiline) {
                const phrases = phraseToAdd.split("\n").filter((e) => e.length);
                phrasesToBeAdded = [...phrases];
            } else {
                phrasesToBeAdded = [phraseToAdd.split("\n").join(" ")];
            }
            addPhrases(phrasesToBeAdded);
        },
        [phraseToAdd]
    );

    const addPhrases = (phrase: string[]) => {
        if (phrase?.length > 0) {
            phrase?.forEach((e) => {
                append({ id: nanoid(), phrase: e });
            });
        }
        setPhraseToAdd("");
    };

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [open]);

    return (
        <>
            <Controller
                name="phrase"
                control={control}
                render={() => (
                    <>
                        <Box
                            className="flex items-center justify-between"
                            sx={{
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: 1,
                            }}
                        >
                            <TextField
                                value={phraseToAdd}
                                onChange={(e) => {
                                    setPhraseToAdd(e.target.value);
                                }}
                                multiline={true}
                                maxRows={3}
                                label="Add a Phrase"
                                className="w-full whitespace-pre"
                                placeholder="Add a Phrase"
                                size="small"
                                sx={(theme) => ({
                                    "& .MuiInputBase-root > fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiFormLabel-root": {
                                        background:
                                            theme.palette.background.paper,
                                    },
                                })}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleAddPhrase(phraseToAdd);
                                    }
                                }}
                            />
                            <Box className="flex items-center">
                                <AIRecommandationModal phrase={phraseToAdd} />
                                <Box
                                    component="button"
                                    sx={{
                                        background: "#000",
                                        px: 1.5,
                                        py: 0.5,
                                        color: "#fff",
                                        m: 0.5,
                                        borderRadius: 1,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleAddPhrase(phraseToAdd);
                                    }}
                                    className="nodrag nopan"
                                >
                                    +
                                </Box>
                            </Box>
                        </Box>
                        {phrases.length !== 0 ? (
                            <Box
                                sx={{
                                    height: "130px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    mb: 1,
                                    borderRadius: "6px",
                                }}
                                className="flex flex-col overflow-y-auto flex-auto"
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        p: 1,
                                        background: "rgba(153, 153, 153, 0.10)",
                                        borderBottom:
                                            "1px solid rgba(0, 0, 0, 0.23)",
                                        fontWeight: 500,
                                    }}
                                    component={"div"}
                                >
                                    Added Phrases-{`${phrases.length ?? 0}`}
                                </Typography>
                                <Box className="overflow-y-scroll nowheel">
                                    {phrases.map((item, idx) => (
                                        <MomentPhraseCard
                                            key={item.id}
                                            name={item.phrase}
                                            onClick={() => remove(idx)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ) : null}
                        <PhraseConfirmDialog
                            {...{
                                open,
                                handleClose,
                                handleButtonClick: convertPhrases,
                            }}
                        />
                    </>
                )}
            />
        </>
    );
}
