import { Node, NodeProps } from "reactflow";
import BlockNodeUI from "./BlockNodeUI";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import { Box } from "@mui/system";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import ConfigureSettingSvg from "../../svg/ConfigureSettingSvg";
import { BoxProps, Popover, Typography, alpha } from "@mui/material";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import {
    getDefaultConfigOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import CreateSingleResponseBlockType from "./CreateSingleResponseBlockType";
import HandlerWrapper from "../Handlers/HandlerWrapper";
import { CombinationNode } from "../../../types/bert";

export const Options: React.FC<
    ReturnType<typeof getDefaultConfigOptions>[0] & Pick<BoxProps, "onClick">
> = ({ text, ...rest }) => {
    return (
        <Box
            sx={(theme) => ({
                py: 1.5,
                px: 2,
                "&:hover": {
                    background: "rgba(26, 98, 242, 0.10)",
                    cursor: "pointer",
                    "*": {
                        color: theme.palette.primary.main,
                    },
                },
            })}
            className="flex items-center justify-between nopan nodrag"
            {...rest}
        >
            <Typography variant="medium">{text}</Typography>
        </Box>
    );
};

const CombinationBlockNode: React.FC<NodeProps<CombinationNode>> = (props) => {
    const { open, anchorEl, handleClose, handleClick } =
        useAnchorEl<HTMLButtonElement>();
    const { setNodes, deleteNode } = useReactFlowStateContextProvider();
    const {
        dispatch,
        aiConfigState: { activeDefaultBlock },
    } = useParameterAiConfigContext();
    const {
        id,
        data: {
            metadata: { blocks, selected, name },
        },
    } = props;
    const selectedBlocks = blocks.filter((e) => e.is_selected);
    const handleSave = (selectedIndex: number) => {
        setNodes((nodes) =>
            nodes.map((e: Node<CombinationNode>) =>
                e.id === id
                    ? {
                          ...e,
                          data: {
                              ...e.data,
                              metadata: {
                                  ...e.data.metadata,
                                  selected: selectedIndex,
                              },
                          },
                      }
                    : e
            )
        );
        handleClose();
    };
    return (
        <HandlerWrapper AddComponent={CreateSingleResponseBlockType} {...props}>
            <BlockNodeUI
                {...{
                    name: `${name}(${
                        isDefined(selected)
                            ? selected
                                ? `Any ${selected} Blocks`
                                : "All Blocks"
                            : "Not Configured"
                    })`,
                    heading: "Combination Block",
                    isDefaultRuleConfig: true,
                    is_active: activeDefaultBlock?.blockId === id,
                    Extra: selectedBlocks.length ? (
                        <div className="relative overflow-visible">
                            <Box
                                sx={{
                                    bgcolor: (theme) =>
                                        alpha(
                                            theme.palette.textColors[999],
                                            0.1
                                        ),
                                    borderRadius: "6px",
                                }}
                                className="flex-shrink-0 w-[20px] h-[20px] flex items-center justify-center nodrag nopan cursor-pointer"
                                component="button"
                                onClick={(e) => handleClick(e)}
                            >
                                <ConfigureSettingSvg />
                            </Box>
                        </div>
                    ) : (
                        <></>
                    ),
                }}
            >
                <Box className="flex items-center" gap={0.5}>
                    <button
                        onClick={() => {
                            dispatch({
                                type: "SET_ACTIVE_DEFAULT_BLOCK_ID",
                                payload: {
                                    blockId: id,
                                },
                            });
                        }}
                    >
                        <EditLegendSvg />
                    </button>
                    <DeleteButton
                        onDelete={() => {
                            return new Promise(() => {
                                deleteNode(id);
                            });
                        }}
                        title="Delete Node"
                        message="Are you sure you want to delete this Node?"
                        DeleteOptionComponent={
                            <button>
                                <DeleteLegendSvg />
                            </button>
                        }
                    />
                </Box>
            </BlockNodeUI>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {getDefaultConfigOptions(selectedBlocks.length).map(
                    (option, idx) => (
                        <Options
                            {...option}
                            key={idx}
                            onClick={() => {
                                handleSave(option.index);
                            }}
                        />
                    )
                )}
            </Popover>
        </HandlerWrapper>
    );
};

export default CombinationBlockNode;
