import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function ReportSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.28105 4.11442C2.5 4.89547 2.5 6.15255 2.5 8.6667V11.3334C2.5 13.8475 2.5 15.1046 3.28105 15.8857C4.0621 16.6667 5.31918 16.6667 7.83333 16.6667H11.9815C14.4956 16.6667 15.7527 16.6667 16.5338 15.8857C17.3148 15.1046 17.3148 13.8475 17.3148 11.3334V8.66671C17.3148 6.15255 17.3148 4.89547 16.5338 4.11442C15.7527 3.33337 14.4956 3.33337 11.9815 3.33337H7.83333C5.31918 3.33337 4.0621 3.33337 3.28105 4.11442ZM12.8696 6.88902C13.3606 6.88902 13.7585 7.28699 13.7585 7.77791V13.7038C13.7585 14.1948 13.3606 14.5927 12.8696 14.5927C12.3787 14.5927 11.9808 14.1948 11.9808 13.7038V7.77791C11.9808 7.28699 12.3787 6.88902 12.8696 6.88902ZM7.83261 9.25939C7.83261 8.76847 7.43464 8.3705 6.94372 8.3705C6.4528 8.3705 6.05483 8.76847 6.05483 9.25939L6.05483 13.7038C6.05483 14.1948 6.4528 14.5927 6.94372 14.5927C7.43464 14.5927 7.83261 14.1948 7.83261 13.7038L7.83261 9.25939ZM10.7956 10.7409C10.7956 10.25 10.3976 9.85198 9.90668 9.85198C9.41576 9.85198 9.0178 10.25 9.0178 10.7409V13.7038C9.0178 14.1948 9.41576 14.5927 9.90668 14.5927C10.3976 14.5927 10.7956 14.1948 10.7956 13.7038V10.7409Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
