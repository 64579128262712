import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function InsightsTabSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <mask
                id="mask0_15074_23343"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_15074_23343)">
                <path
                    d="M4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6834 2 13.3834 2 12C2 10.6167 2.2625 9.31672 2.7875 8.10005C3.3125 6.88338 4.025 5.82505 4.925 4.92505L6 6.00005C5.21667 6.78338 4.60417 7.68338 4.1625 8.70005C3.72083 9.71672 3.5 10.8167 3.5 12C3.5 13.1834 3.72083 14.2834 4.1625 15.3C4.60417 16.3167 5.21667 17.2167 6 18L4.925 19.075ZM7.05 16.95C6.41667 16.3167 5.91667 15.575 5.55 14.725C5.18333 13.875 5 12.9667 5 12C5 11.0334 5.18333 10.125 5.55 9.27505C5.91667 8.42505 6.41667 7.68338 7.05 7.05005L8.1 8.10005C7.6 8.60005 7.20833 9.18338 6.925 9.85005C6.64167 10.5167 6.5 11.2334 6.5 12C6.5 12.7667 6.64167 13.4834 6.925 14.15C7.20833 14.8167 7.6 15.4 8.1 15.9L7.05 16.95ZM11 16.5C11 16.1167 10.8708 15.7375 10.6125 15.3625C10.3542 14.9875 10.0667 14.5959 9.75 14.1875C9.43333 13.7792 9.14583 13.3542 8.8875 12.9125C8.62917 12.4709 8.5 12 8.5 11.5C8.5 10.5334 8.84167 9.70838 9.525 9.02505C10.2083 8.34172 11.0333 8.00005 12 8.00005C12.9667 8.00005 13.7917 8.34172 14.475 9.02505C15.1583 9.70838 15.5 10.5334 15.5 11.5C15.5 12 15.3708 12.4709 15.1125 12.9125C14.8542 13.3542 14.5667 13.7792 14.25 14.1875C13.9333 14.5959 13.6458 14.9875 13.3875 15.3625C13.1292 15.7375 13 16.1167 13 16.5H11ZM11 19V17.5H13V19H11ZM16.95 16.95L15.9 15.9C16.4 15.4 16.7917 14.8167 17.075 14.15C17.3583 13.4834 17.5 12.7667 17.5 12C17.5 11.2334 17.3583 10.5167 17.075 9.85005C16.7917 9.18338 16.4 8.60005 15.9 8.10005L16.95 7.05005C17.5833 7.68338 18.0833 8.42505 18.45 9.27505C18.8167 10.125 19 11.0334 19 12C19 12.9667 18.8167 13.875 18.45 14.725C18.0833 15.575 17.5833 16.3167 16.95 16.95ZM19.075 19.075L18 18C18.7833 17.2167 19.3958 16.3167 19.8375 15.3C20.2792 14.2834 20.5 13.1834 20.5 12C20.5 10.8167 20.2792 9.71672 19.8375 8.70005C19.3958 7.68338 18.7833 6.78338 18 6.00005L19.075 4.92505C19.975 5.82505 20.6875 6.88338 21.2125 8.10005C21.7375 9.31672 22 10.6167 22 12C22 13.3834 21.7375 14.6834 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
