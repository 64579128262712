import CustomModal from "@convin/components/custom_components/CustomModal";
import {
    Autocomplete,
    Box,
    Button,
    TextField,
    createFilterOptions,
} from "@mui/material";
import { useGetUsersQuery } from "@convin/redux/services/settings/users.service";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import useAuth from "@convin/hooks/useAuth";
import InfoSvg from "@convin/components/svg/InfoSvg";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "@convin/components/hook-form";
import { useShareMeetingsMutation } from "@convin/redux/services/meeting/meeting.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { ConversationView } from "@convin/type/Conversation";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";

const ShareAnalyticsModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const [handleShare, { isLoading }] = useShareMeetingsMutation();
    const { user } = useAuth();
    const {
        prepareSearchFilters,
        state: { analyticsTemplate },
    } = useGlobalFiltersStateContext();

    const { data: users } = useGetUsersQuery();

    const schema = Yup.object().shape({
        comments: Yup.string(),
        emails: Yup.array()
            .of(Yup.string())
            .min(1, "Select at least one email"),
    });
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: { comments: "", emails: [] as string[] },
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = async (payload: {
        comments: string;
        emails: Array<string>;
    }) => {
        if (isDefined(user)) {
            const json_filters: ConversationView["json_filters"] = [
                ...prepareSearchFilters({
                    template: analyticsTemplate,
                }),
            ];
            handleShare({
                ...payload,
                owner: user.id,
                json_filters,
                module: "home",
            })
                .unwrap()
                .then(() => {
                    showToast({
                        ...CreateUpdateToastSettings,
                        message: "Link has been shared!",
                    });
                    onClose();
                    reset();
                });
        }
    };

    return (
        <CustomModal
            open={open}
            handleClose={onClose}
            title="Share Analytics via Email"
            subTitle="Invite your team members to check the analytics on this dashboard"
            dialogContentSx={() => ({
                width: "666px",
            })}
            showFooter={false}
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "12px",
                },
            }}
        >
            <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className={"h-full flex flex-col"}
            >
                <Box className="flex flex-col" gap={1.5}>
                    <Controller
                        name="emails"
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                        }) => (
                            <Autocomplete
                                multiple
                                options={users?.map((e) => e.email) ?? []}
                                getOptionLabel={(option) => option}
                                freeSolo
                                filterOptions={createFilterOptions({
                                    ignoreCase: false,
                                    trim: true,
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Share to"
                                        placeholder="Enter email and press enter"
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                                value={value}
                                onChange={(_, value) => {
                                    const uniqueValues = Object.fromEntries(
                                        (value as string[])
                                            .map((s) => s.trim())
                                            .filter((s) => s.length)
                                            .map((s) => [s.toLowerCase(), s])
                                    );
                                    onChange(Object.values(uniqueValues));
                                }}
                            />
                        )}
                    />

                    <Box
                        className="flex items-center gap-[8px]"
                        sx={{
                            color: "primary.main",
                        }}
                    >
                        <InfoSvg />
                        <span className="mt-[2px]">
                            A public link will be shared with the above
                            mentioned email.
                        </span>
                    </Box>
                    <RHFTextField
                        name="comments"
                        label="Comment"
                        variant="filled"
                        className="w-full "
                    />
                </Box>
                <Box className="w-full flex justify-end" mt={1.5}>
                    <Button
                        variant="globalOutlined"
                        sx={{ mr: 2, width: "112px", height: "40px" }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="global"
                        type="submit"
                        sx={{
                            typography: "textSmbold",
                            width: "112px",
                            height: "40px",
                        }}
                        loading={isLoading}
                    >
                        Share
                    </Button>
                </Box>
            </FormProvider>
        </CustomModal>
    );
};

export default ShareAnalyticsModal;
