import { Empty } from "antd";
import React from "react";
import EmptyV1Svg from "../../../static/images/icons/emptyv1.svg";

export default function NoData({ description, className }) {
    return (
        <Empty
            image={EmptyV1Svg}
            imageStyle={{
                height: 60,
            }}
            description={<span className="font16 bold500">{description}</span>}
            className={className}
        />
    );
}

NoData.defaultProps = {
    description: "No Data Found",
    className: "",
};
