import { useTheme } from "@mui/material";

export default function AccessDeniedSvg() {
    const {
        palette: {
            primary: { main },
        },
    } = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
        >
            <path
                d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
                fill="url(#paint0_linear_22921_5672)"
                fillOpacity="0.5"
            />
            <mask
                id="mask0_22921_5672"
                maskUnits="userSpaceOnUse"
                x="42"
                y="59"
                width="117"
                height="117"
            >
                <rect
                    x="42.1875"
                    y="59"
                    width="116.547"
                    height="116.547"
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_22921_5672)">
                <path
                    d="M97.6801 64.932C95.0171 67.6393 93.375 68.7932 89.2475 70.746C83.1671 73.6309 76.1547 75.273 67.2339 75.8944C63.062 76.1607 61.9525 76.3382 61.4643 76.782C60.4879 77.714 58.4907 89.209 57.248 100.615C56.4935 107.095 56.2716 120.099 56.7154 125.114C57.3811 132.437 59.2895 138.917 61.9081 142.956C65.4143 148.415 73.4474 154.628 84.898 160.62C89.5581 163.016 99.4553 167.499 100.21 167.499C101.763 167.499 116.321 160.442 122.401 156.759C129.99 152.143 136.426 146.551 138.822 142.468C141.263 138.384 142.905 132.393 143.66 125.07C144.148 120.454 143.837 107.228 143.172 100.748C141.885 89.0759 139.843 77.714 138.822 76.6489C138.29 76.1163 137.491 76.0275 133.496 75.8056C124.576 75.3618 117.474 73.6309 110.906 70.391C107.533 68.7489 103.805 65.9528 102.873 64.355C102.34 63.423 101.231 62.7129 100.299 62.7129C100.032 62.7573 98.8784 63.7337 97.6801 64.932ZM106.423 89.7416C111.572 91.1174 114.812 92.9815 118.584 96.754C122.401 100.571 124.221 103.766 125.685 109.092C126.44 111.888 126.44 118.368 125.685 121.164C124.265 126.446 122.401 129.641 118.584 133.502C114.767 137.319 111.572 139.139 106.246 140.603C103.45 141.358 96.9699 141.358 94.1739 140.603C88.8924 139.183 85.6969 137.319 81.8357 133.502C78.0188 129.685 76.1991 126.49 74.7345 121.164C73.98 118.324 73.98 111.933 74.7789 108.915C76.998 100.305 83.4778 93.2478 91.7329 90.4073C96.0379 88.8983 102.251 88.632 106.423 89.7416Z"
                    fill="url(#paint1_linear_22921_5672)"
                />
                <path
                    d="M97.2805 94.0913C92.7979 94.7127 88.626 96.7542 85.253 100.172C79.4389 105.941 77.5749 114.108 80.1491 122.008C80.8592 124.094 82.9451 127.955 83.5221 128.132C83.6996 128.177 90.4901 121.564 98.6564 113.398L113.524 98.5295L112.726 97.9082C111.394 96.843 107.533 95.0677 105.536 94.5795C102.828 93.9138 99.8103 93.7363 97.2805 94.0913Z"
                    fill="url(#paint2_linear_22921_5672)"
                />
                <path
                    d="M101.807 116.903L86.9395 131.771L87.7383 132.393C89.1142 133.458 92.931 135.189 95.0614 135.766C99.4108 136.875 104.914 136.343 109.219 134.345C120.67 129.064 124.886 114.373 117.963 103.544C117.43 102.701 116.897 102.035 116.764 102.035C116.764 102.035 109.974 108.692 101.807 116.903Z"
                    fill="url(#paint3_linear_22921_5672)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_22921_5672"
                    x1="100"
                    y1="-2.93614e-08"
                    x2="99.5074"
                    y2="145.32"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={main} stopOpacity="0.5" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_22921_5672"
                    x1="100.193"
                    y1="62.7129"
                    x2="100.193"
                    y2="167.499"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={main} />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_22921_5672"
                    x1="96.2707"
                    y1="93.9121"
                    x2="96.2707"
                    y2="128.133"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={main} />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_22921_5672"
                    x1="104.129"
                    y1="102.035"
                    x2="104.129"
                    y2="136.335"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={main} />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}
