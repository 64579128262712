import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import Error500State from "../reuseable/Error500State";

export default function SalesAgentIframe() {
    const { data } = useGetDomainConfigQuery();
    if (!data?.sales_agent_url && !data?.sales_agent_url?.length) {
        return <Error500State />;
    }
    return (
        <iframe
            loading="lazy"
            title="AI Agent"
            src={data?.sales_agent_url || ""}
            sandbox="allow-scripts allow-modals allow-forms allow-popups allow-same-origin allow-top-navigation"
            allowFullScreen
            className="h-full w-full"
        ></iframe>
    );
}
