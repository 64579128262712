import TextField from "@mui/material/TextField";
import { useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import "react-calendar/dist/Calendar.css";
import {
    Paper,
    Box,
    Popper,
    Typography,
    alpha,
    ClickAwayListener,
    InputBase,
    useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { Divider } from "antd";
import { showNoPermissionToast } from "@convin/utils/toast";

const DurationInput = styled(InputBase)(({ theme }) => ({
    width: "100%",
    fontSize: "45px",
    outline: "none",
    border: "2px solid transparent",
    borderRadius: "8px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transition: "border-color 0.2s ease",

    "&:hover": {
        borderColor: theme.palette.primary.main,
    },

    "& .MuiInputBase-input": {
        textAlign: "center",
    },
}));

const DurationDropDownPopper = styled(Popper)(({ theme }) =>
    theme.unstable_sx({
        boxShadow: `0px 4px 16px 2px ${alpha(
            theme.palette.textColors[333],
            0.1
        )}`,
        top: "10px !important",

        maxHeight: "334px !important",
        borderRadius: 1,
        overflow: "hidden",

        ".MuiPaper-root": {
            maxHeight: "334px !important",
        },
    })
);

const DurationOption = styled(Box, {
    shouldForwardProp: (prop) => true,
})(({ theme }) => ({
    ":hover": {
        backgroundColor: alpha(theme?.palette?.primary?.main, 0.1),
        cursor: "pointer",
    },
}));

export const DurationDropDown = ({
    onClose,
    setShowDurationPicker,
    options,
    handleUpdate,
    active,
}) => {
    const handleChange = (value) => {
        if (+value === 7) {
            setShowDurationPicker(true); // Show DurationPicker.
        } else {
            handleUpdate({
                value,
                is_custom: false,
            });
            onClose();
        }
    };

    return (
        <Box className="w-full">
            {Object.keys(options).map((key, idx) => (
                <DurationOption
                    sx={{
                        px: 2,
                        py: 1,
                    }}
                    key={key}
                    onClick={() => handleChange(key)}
                >
                    <Typography
                        fontWeight={key === active + "" ? "bold" : "regular"}
                        variant="textSm"
                        component="button"
                    >
                        {options[key]?.name}
                    </Typography>
                </DurationOption>
            ))}
        </Box>
    );
};

export const CustomDurationPicker = ({
    setShowDurationPicker,
    handleRangeChange,
}) => {
    const [duration, setDuration] = useState({
        min: null,
        max: null,
    });
    const theme = useTheme();
    return (
        <Box
            sx={{
                bgcolor: theme.palette?.primary?.dimlight,
                // bgcolor: "#F2F7FF",
                borderRadius: "6px",
                p: 2,
                mt: 2,
            }}
            className="custom_duration_picker"
        >
            <Typography className="font-medium" sx={{ mb: 1 }}>
                Enter Duration (in min)
            </Typography>
            <Divider sx={{ color: "common.divider" }} />
            <Box className="flex items-center justify-center">
                <Box
                    sx={{
                        width: 147,
                        px: 2,
                        py: 1,
                    }}
                >
                    <DurationInput
                        value={duration.min}
                        onChange={(e) => {
                            setDuration({
                                ...duration,
                                min: +e.target.value || null,
                            });
                        }}
                        type="number"
                        inputProps={{
                            min: 0,
                            max: duration.max,
                        }}
                    />
                    <Typography variant="small">{"Min Duration"}</Typography>
                </Box>
                <Box
                    sx={{
                        fontSize: 45,
                        pb: 3,
                    }}
                >
                    :
                </Box>
                <Box
                    sx={{
                        width: 147,
                        px: 2,
                        py: 1,
                    }}
                >
                    <DurationInput
                        value={duration.max}
                        onChange={(e) => {
                            setDuration({
                                ...duration,
                                max: +e.target.value || null,
                            });
                        }}
                        type="number"
                        inputProps={{
                            min: duration.min ?? 0,
                        }}
                    />
                    <Typography variant="small">{"Max Duration"}</Typography>
                </Box>
            </Box>
            <Box className="flex justify-end gap-10">
                <Typography
                    onClick={() => {
                        setShowDurationPicker(false);
                    }}
                    className="font-medium cursor-pointer"
                    variant="medium"
                    sx={{ color: "primary.main" }}
                >
                    Cancel
                </Typography>
                <Typography
                    onClick={() => {
                        handleRangeChange(duration);
                    }}
                    className="font-medium cursor-pointer"
                    variant="medium"
                    sx={{ color: "primary.main" }}
                >
                    OK
                </Typography>
            </Box>
        </Box>
    );
};

export default function DurationFilter({
    value,
    handleUpdate,
    options,
    disabled = false,
    disableClearable = true,
    label,
}) {
    const [openPopper, setOpenPopper] = useState(false);
    const [showDurationPicker, setShowDurationPicker] = useState(false);

    const anchorRef = useRef(null);

    const handleRangeChange = ({ min, max }) => {
        const range = [min, max];

        if (!(min || max)) {
            showNoPermissionToast({
                message: "Duration values are required to apply filter.",
            });
            return;
        } else if ((min && min < 0) || (max && max < 0)) {
            showNoPermissionToast({
                message: "Duration values can't be negative.",
            });
            return;
        } else if (min && max && min >= max) {
            showNoPermissionToast({
                message: "Max duration must be greater than min duration.",
            });
            return;
        }

        const active =
            min && max
                ? `Between ${min} - ${max} min`
                : min
                ? `Above ${min} min`
                : max
                ? `Below ${max} min`
                : 0;
        handleUpdate({
            value: active,
            is_custom: active ? true : false,
            range,
        });
        setShowDurationPicker(false);
    };

    const onClose = () => {
        setOpenPopper(false);
    };
    return (
        <>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={() => {
                    setOpenPopper(false);
                }}
            >
                <Box ref={anchorRef} className="relative flex-1">
                    <Autocomplete
                        value={value}
                        onChange={(_, __, reason) => {
                            if (reason === "clear") {
                                handleUpdate({
                                    value: null,
                                    is_custom: false,
                                });
                            }
                        }}
                        disableClearable={disableClearable}
                        options={[]}
                        open={openPopper}
                        onOpen={() => setOpenPopper(true)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={!!label ? label : "Duration"}
                            />
                        )}
                        getOptionLabel={(option) => options?.[option]?.name}
                        PaperComponent={() => (
                            <Paper
                                className="flex flex-col"
                                sx={{
                                    maxHeight: 200,
                                    height: 200,
                                }}
                            >
                                <Box className="flex-1 overflow-y-auto">
                                    <DurationDropDown
                                        {...{
                                            options,
                                            onClose,
                                            showDurationPicker,
                                            setShowDurationPicker: (flag) => {
                                                setShowDurationPicker(flag);
                                                setOpenPopper(!flag);
                                            },
                                            handleUpdate,
                                            active: value,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        )}
                        PopperComponent={(props) => (
                            <DurationDropDownPopper
                                placement="bottom-end"
                                {...props}
                            >
                                {props.children}
                            </DurationDropDownPopper>
                        )}
                        disabled={!!disabled}
                    />
                    {showDurationPicker && (
                        <CustomDurationPicker
                            {...{ setShowDurationPicker, handleRangeChange }}
                        />
                    )}
                </Box>
            </ClickAwayListener>
        </>
    );
}
