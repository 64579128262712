import { CloseSvg } from "@convin/components/svg";
import { Box, Typography } from "@mui/material";
import React, { useState, useRef } from "react";

const Beep = () => {
    return (
        <Box
            className="h-[35px] w-[35px] rounded-full flex items-center justify-center"
            sx={{
                bgcolor: "white",
                mb: 1,
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
            >
                <path
                    d="M7.49848 10.9759C8.55113 10.9759 9.56066 10.5644 10.305 9.83183C11.0493 9.09932 11.4675 8.10581 11.4675 7.06987C11.4675 6.03394 11.0493 5.04043 10.305 4.30792C9.56066 3.5754 8.55113 3.16387 7.49848 3.16387C6.44584 3.16387 5.43631 3.5754 4.69198 4.30792C3.94765 5.04043 3.52948 6.03394 3.52948 7.06987C3.52948 8.10581 3.94765 9.09932 4.69198 9.83183C5.43631 10.5644 6.44584 10.9759 7.49848 10.9759ZM7.49848 0.234375C5.65636 0.234375 3.88968 0.954542 2.5871 2.23645C1.28452 3.51835 0.552734 5.25699 0.552734 7.06987C0.552734 8.88276 1.28452 10.6214 2.5871 11.9033C3.88968 13.1852 5.65636 13.9054 7.49848 13.9054C9.34061 13.9054 11.1073 13.1852 12.4099 11.9033C13.7125 10.6214 14.4442 8.88276 14.4442 7.06987C14.4442 5.25699 13.7125 3.51835 12.4099 2.23645C11.1073 0.954542 9.34061 0.234375 7.49848 0.234375ZM1.54498 7.06987C1.54498 6.30046 1.69898 5.53858 1.99817 4.82773C2.29736 4.11689 2.73589 3.47099 3.28872 2.92694C3.84156 2.38288 4.49787 1.95131 5.22018 1.65686C5.94249 1.36242 6.71666 1.21087 7.49848 1.21087C8.28031 1.21087 9.05448 1.36242 9.77679 1.65686C10.4991 1.95131 11.1554 2.38288 11.7082 2.92694C12.2611 3.47099 12.6996 4.11689 12.9988 4.82773C13.298 5.53858 13.452 6.30046 13.452 7.06987C13.452 8.62378 12.8247 10.114 11.7082 11.2128C10.5917 12.3116 9.07745 12.9289 7.49848 12.9289C5.91952 12.9289 4.40522 12.3116 3.28872 11.2128C2.17223 10.114 1.54498 8.62378 1.54498 7.06987Z"
                    fill="#FF6365"
                />
            </svg>
        </Box>
    );
};

const MediaRecorderComponent: React.FC<{
    handleClose: () => void;
    updateMediaState: (value: {
        media: Blob | null;
        media_url?: string;
    }) => void;
    type: "audio" | "video";
}> = ({ handleClose, updateMediaState, type }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);

    const [isRecording, setIsRecording] = useState(false);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                ...(type === "video" && { video: true }),
                audio: true,
            });

            if (type === "video") {
                videoRef.current!.srcObject = stream;
            }

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(chunksRef.current, {
                    type: type === "video" ? "video/mp4" : "audio/mp3",
                });
                const url = URL.createObjectURL(blob);
                handleClose();
                updateMediaState({
                    media: blob,
                    media_url: url,
                });
            };

            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
            mediaRecorder.start();
        } catch (error) {
            console.error("Error accessing camera:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);

            if (mediaRecorderRef.current.stream) {
                const tracks = mediaRecorderRef.current.stream.getTracks();
                for (let i = 0; i < tracks.length; i++) {
                    tracks[i].stop();
                }
            }
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "230px",
                borderRadius: "10px",
                bgcolor: "black",
                color: "white",
            }}
            className="relative"
        >
            <Box
                component="button"
                className="absolute top-[20px] right-[20px] z-10"
                onClick={handleClose}
            >
                <CloseSvg />
            </Box>

            {type === "video" && (
                <video
                    className="rounded-[inherit] top-0 left-0 absolute z-0 w-full h-full"
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                />
            )}

            <Box
                className="flex"
                sx={{
                    position: "absolute",
                    left: "50%",
                    top: "80%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                {isRecording ? (
                    <Box
                        component="button"
                        className="flex flex-col justify-center items-center"
                        onClick={stopRecording}
                    >
                        <Beep />
                        <Typography color={"white"} variant="small">
                            Stop Recording
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        component="button"
                        className="flex flex-col justify-center items-center"
                        onClick={startRecording}
                    >
                        <Beep />
                        <Typography color={"white"} variant="small">
                            Start Recording
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MediaRecorderComponent;
