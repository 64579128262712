import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { matchPath, useLocation } from "react-router-dom";

export const useAccountFilterCheck = () => {
    const location = useLocation();

    const route = matchPath<"dashboard_type", string>(
        "/:dashboard_type/*",
        location.pathname
    );
    const dashboard = Object.values(DashboardRoutesConfig).find(
        (e) =>
            e.path.toLowerCase() ===
            `${route?.params?.dashboard_type?.toLowerCase()}`
    );

    const pathIsAccounts =
        dashboard?.path === DashboardRoutesConfig.Account.path;

    return pathIsAccounts;
};
