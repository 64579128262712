import { PropsWithChildren, ReactElement, createContext, useRef } from "react";

//Audio
import notificationAudio from "@convin/assets/audio/notification.mp3";
import { isDefined } from "@convin/utils/helper/common.helper";

export interface IAudioContextValue {
    playAudio: (type: "notification") => Promise<void>;
}

const AudioContext = createContext<IAudioContextValue | null>(null);

function AudioProvider({ children }: PropsWithChildren): ReactElement {
    const audioRef = useRef<HTMLAudioElement>(null);
    const playAudio: IAudioContextValue["playAudio"] = async (type) => {
        if (isDefined(audioRef.current)) {
            const audio = audioRef.current;
            switch (type) {
                case "notification":
                    audio.src = notificationAudio;
                    break;
                default:
            }
            await audio.play();
        }
    };

    return (
        <AudioContext.Provider value={{ playAudio }}>
            {children} <audio ref={audioRef} src={undefined} />
        </AudioContext.Provider>
    );
}

export { AudioContext, AudioProvider };
