import DateFilter from "@convin/components/custom_components/Filters/DateFilter";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box } from "@mui/material";

type Props = {
    disabled?: boolean;
    showDatePickerOptions?: boolean;
};

export default function LastContactedFilter({
    disabled = false,
    showDatePickerOptions = true,
}: Props) {
    const { state, updateState } = useGlobalFiltersStateContext();
    return (
        <Box>
            <DateFilter
                label="Last Contacted Date"
                disabled={disabled}
                value={state.lastContactedDateKey}
                options={state.lastContactedDateOptions}
                handleUpdate={(e) => {
                    if (!Boolean(e.is_custom)) {
                        updateState({
                            lastContactedDateKey: e.value,
                        });
                    } else {
                        updateState({
                            lastContactedDateKey: e.value,
                            lastContactedDateOptions: {
                                ...state.lastContactedDateOptions,
                                ...(isDefined(e.value) && {
                                    [e.value]: {
                                        name: e.value,
                                        is_rolling_date: false,
                                        label: "",
                                        dateRange: e.dateRange,
                                    },
                                }),
                            },
                        });
                    }
                }}
                {...{ showDatePickerOptions }}
            />
        </Box>
    );
}
