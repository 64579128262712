import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function DocumentTypeSvgg({
    sx,
}: {
    sx?: SxProps<Theme>;
    noBorder?: boolean;
}) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <g clipPath="url(#clip0_13491_13556)">
                <g mask="url(#mask0_13491_13556)">
                    <path
                        d="M17.5968 9.45326L14.3546 6.2111C14.2181 6.08275 14.0371 6.01257 13.8498 6.01542C13.6624 6.01827 13.4836 6.09393 13.3511 6.22638C13.2186 6.35882 13.1429 6.53764 13.14 6.72495C13.1371 6.91227 13.2072 7.09336 13.3355 7.22986L11.9453 8.62003L15.1875 11.8622L16.5776 10.472C16.7127 10.6072 16.896 10.6831 17.0871 10.6831C17.1817 10.6832 17.2754 10.6645 17.3628 10.6283C17.4502 10.5921 17.5297 10.5391 17.5966 10.4722C17.6635 10.4053 17.7166 10.3259 17.7528 10.2385C17.789 10.1511 17.8077 10.0574 17.8077 9.96277C17.8077 9.86815 17.7891 9.77446 17.7529 9.68703C17.7167 9.59961 17.6636 9.52018 17.5968 9.45326Z"
                        fill="#E1E8ED"
                    />
                    <path
                        d="M13.8446 6H8.44096C8.05879 6 7.69228 6.15182 7.42205 6.42205C7.15182 6.69228 7 7.05879 7 7.44096V17.5277C7 17.9098 7.15182 18.2763 7.42205 18.5466C7.69228 18.8168 8.05879 18.9686 8.44096 18.9686H16.3662C16.7484 18.9686 17.1149 18.8168 17.3851 18.5466C17.6554 18.2763 17.8072 17.9098 17.8072 17.5277V9.96264H14.565C14.2048 9.96264 13.8446 9.6024 13.8446 9.24216V6Z"
                        fill="#CCD6DD"
                    />
                    <path
                        d="M13.8445 5.99999H13.124V9.24215C13.124 9.62431 13.2758 9.99083 13.5461 10.2611C13.8163 10.5313 14.1828 10.6831 14.565 10.6831H17.8071V9.96263H14.565C14.2047 9.96263 13.8445 9.60239 13.8445 9.24215V5.99999Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M12.0433 8.88195C12.0433 8.97749 12.0054 9.06912 11.9378 9.13668C11.8702 9.20423 11.7786 9.24219 11.6831 9.24219H8.80116C8.70562 9.24219 8.61399 9.20423 8.54643 9.13668C8.47887 9.06912 8.44092 8.97749 8.44092 8.88195C8.44092 8.78641 8.47887 8.69478 8.54643 8.62722C8.61399 8.55966 8.70562 8.52171 8.80116 8.52171H11.6831C11.7786 8.52171 11.8702 8.55966 11.9378 8.62722C12.0054 8.69478 12.0433 8.78641 12.0433 8.88195Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M12.0433 10.3229C12.0433 10.4184 12.0054 10.51 11.9378 10.5776C11.8702 10.6452 11.7786 10.6831 11.6831 10.6831H8.80116C8.70562 10.6831 8.61399 10.6452 8.54643 10.5776C8.47887 10.51 8.44092 10.4184 8.44092 10.3229C8.44092 10.2273 8.47887 10.1357 8.54643 10.0681C8.61399 10.0006 8.70562 9.96263 8.80116 9.96263H11.6831C11.7786 9.96263 11.8702 10.0006 11.9378 10.0681C12.0054 10.1357 12.0433 10.2273 12.0433 10.3229Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M16.3662 11.7638C16.3662 11.8593 16.3282 11.951 16.2607 12.0185C16.1931 12.0861 16.1015 12.124 16.006 12.124H8.80116C8.70562 12.124 8.61399 12.0861 8.54643 12.0185C8.47887 11.951 8.44092 11.8593 8.44092 11.7638C8.44092 11.6682 8.47887 11.5766 8.54643 11.5091C8.61399 11.4415 8.70562 11.4035 8.80116 11.4035H16.006C16.1015 11.4035 16.1931 11.4415 16.2607 11.5091C16.3282 11.5766 16.3662 11.6682 16.3662 11.7638Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M16.3662 13.2048C16.3662 13.3004 16.3282 13.392 16.2607 13.4596C16.1931 13.5271 16.1015 13.5651 16.006 13.5651H8.80116C8.70562 13.5651 8.61399 13.5271 8.54643 13.4596C8.47887 13.392 8.44092 13.3004 8.44092 13.2048C8.44092 13.1093 8.47887 13.0177 8.54643 12.9501C8.61399 12.8825 8.70562 12.8446 8.80116 12.8446H16.006C16.1015 12.8446 16.1931 12.8825 16.2607 12.9501C16.3282 13.0177 16.3662 13.1093 16.3662 13.2048Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M16.3662 14.6457C16.3662 14.7413 16.3282 14.8329 16.2607 14.9005C16.1931 14.968 16.1015 15.006 16.006 15.006H8.80116C8.70562 15.006 8.61399 14.968 8.54643 14.9005C8.47887 14.8329 8.44092 14.7413 8.44092 14.6457C8.44092 14.5502 8.47887 14.4586 8.54643 14.391C8.61399 14.3235 8.70562 14.2855 8.80116 14.2855H16.006C16.1015 14.2855 16.1931 14.3235 16.2607 14.391C16.3282 14.4586 16.3662 14.5502 16.3662 14.6457Z"
                        fill="#99AAB5"
                    />
                    <path
                        d="M16.3662 16.0868C16.3662 16.1823 16.3282 16.274 16.2607 16.3415C16.1931 16.4091 16.1015 16.447 16.006 16.447H8.80116C8.70562 16.447 8.61399 16.4091 8.54643 16.3415C8.47887 16.274 8.44092 16.1823 8.44092 16.0868C8.44092 15.9912 8.47887 15.8996 8.54643 15.8321C8.61399 15.7645 8.70562 15.7265 8.80116 15.7265H16.006C16.1015 15.7265 16.1931 15.7645 16.2607 15.8321C16.3282 15.8996 16.3662 15.9912 16.3662 16.0868Z"
                        fill="#99AAB5"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_13491_13556">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
