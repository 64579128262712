import { alpha } from "@mui/material";

export default function Chip(theme) {
    return {
        MuiChip: {
            variants: [
                {
                    props: { variant: "primary" },
                    style: {
                        border: "1px solid",
                        borderColor: alpha(theme?.palette?.primary?.main, 0.2),
                        background: alpha(theme?.palette?.primary?.main, 0.2),
                        color: theme?.palette?.primary?.main,
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: 12,
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        border: "1px solid",
                        borderColor: alpha(
                            theme?.palette?.textColors?.[999],
                            0.24
                        ),
                        background: alpha(
                            theme?.palette?.textColors?.[999],
                            0.1
                        ),
                        color: theme?.palette?.textColors?.[666],
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: 12,
                    },
                },
            ],
        },
    };
}
