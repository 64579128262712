import isEqual from "lodash.isequal";
import {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    DrawerFiltersType,
    StaticDashboardFiltersType,
} from "@convin/type/Filters.model";
import useGlobalFiltersStateContext from "../hooks/useGlobalFiltersStateContext";
import {
    GlobalFiltersInitialState,
    GlobalFiltersStateType,
} from "./GlobalFiltersStateContextProvider";
import useAppliedFilters, {
    UseAppliedFiltersReturnType,
} from "@convin/modules/components/globalDashboardFilters/components/DrawerFilters/hooks/useAppliedFilters";

export interface IFilterVisibility extends UseAppliedFiltersReturnType {
    staticFiltersVisibility?: (keyof StaticDashboardFiltersType)[];
    drawerFiltersVisibility?: (keyof DrawerFiltersType)[];
    appliedFilterKeys: Array<
        keyof DrawerFiltersType | keyof StaticDashboardFiltersType
    >;
    dashboardType?: "Account" | "Conversation" | "CI" | "Analytics ";
    getResetPayload: () => Partial<GlobalFiltersStateType>;
}

export const FiltersVisibilityContext = createContext<IFilterVisibility>(
    {} as IFilterVisibility
);

export default function FiltersVisibilityContextProvider({
    drawerFiltersVisibility,
    staticFiltersVisibility,
    dashboardType,
    children,
}: PropsWithChildren<
    Omit<
        IFilterVisibility,
        | "appliedFilterKeys"
        | "filtersList"
        | "appliedFilters"
        | "getResetPayload"
    >
>): ReactElement {
    const { timeStampWhenAppliedButtonWasClicked, state } =
        useGlobalFiltersStateContext();
    const [appliedFilterKeys, setAppliedFilterKeys] = useState<
        IFilterVisibility["appliedFilterKeys"]
    >([]);
    const { filtersList, appliedFilters } = useAppliedFilters(
        appliedFilterKeys,
        dashboardType
    );

    const handleFilterChange = () => {
        type Filtertype = DrawerFiltersType | StaticDashboardFiltersType;

        setAppliedFilterKeys(
            Object.keys(GlobalFiltersInitialState)
                .filter(
                    (e) =>
                        drawerFiltersVisibility?.includes(
                            e as keyof DrawerFiltersType
                        ) ||
                        staticFiltersVisibility?.includes(
                            e as keyof StaticDashboardFiltersType
                        )
                )
                .filter((key) => {
                    return !isEqual(
                        state[key as keyof Filtertype],
                        GlobalFiltersInitialState[key as keyof Filtertype]
                    );
                }) as IFilterVisibility["appliedFilterKeys"]
        );
    };

    const getResetPayload = useCallback<
        IFilterVisibility["getResetPayload"]
    >(() => {
        const fields = [
            ...(staticFiltersVisibility ?? []),
            ...(drawerFiltersVisibility ?? []),
        ];
        let obj = {} as Partial<GlobalFiltersStateType>;
        fields.forEach((field) => {
            if (field in state) {
                obj = {
                    ...obj,
                    [field]: state[field],
                };
            }
        });

        return {
            ...GlobalFiltersInitialState,
            ...obj,
            dateOptions: state.dateOptions,
            durationOptions: state.durationOptions,
            auditDateOptions: state.auditDateOptions,
            analyticsTemplate: state.analyticsTemplate,
        };
    }, [state, staticFiltersVisibility, drawerFiltersVisibility]);

    useEffect(() => {
        handleFilterChange();
    }, [
        timeStampWhenAppliedButtonWasClicked,
        state.teams,
        state.reps,
        state.dateKey,
        state.durationKey,
        state.meetingType,
        state.level,
        state.agentTenure,
        state.accountTeams,
        state.accountReps,
    ]);

    return (
        <FiltersVisibilityContext
            value={{
                drawerFiltersVisibility,
                staticFiltersVisibility,
                appliedFilterKeys,
                filtersList,
                appliedFilters,
                dashboardType,
                getResetPayload,
            }}
        >
            {children}
        </FiltersVisibilityContext>
    );
}
