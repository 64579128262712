import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import { useGetStagesQuery } from "@convin/redux/services/account/account.service";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";

const StageFilter = () => {
    const { data: stages, isFetching } = useGetStagesQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <GenericSingleSelect
            label="Status/Stage"
            autocompleteProps={{
                options: stages || [],
                disableClearable: false,
            }}
            value={filtersState.stage}
            setValue={(e) => {
                updateFiltersState({
                    stage: e,
                });
            }}
            className="flex-1"
            loading={isFetching}
        />
    );
};

export default StageFilter;
