import DateFilter from "@convin/components/custom_components/Filters/DateFilter";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box } from "@mui/material";

export default function CloseDateFilter() {
    const { state, updateState } = useGlobalFiltersStateContext();
    return (
        <Box>
            <DateFilter
                label="Close Date"
                value={state.closeDateKey}
                options={state.closeDateOptions}
                handleUpdate={(e) => {
                    if (!Boolean(e.is_custom)) {
                        updateState({
                            closeDateKey: e.value,
                        });
                    } else {
                        updateState({
                            closeDateKey: e.value,
                            closeDateOptions: {
                                ...state.closeDateOptions,
                                ...(isDefined(e.value) && {
                                    [e.value]: {
                                        name: e.value,
                                        is_rolling_date: false,
                                        label: "",
                                        dateRange: e.dateRange,
                                    },
                                }),
                            },
                        });
                    }
                }}
            />
        </Box>
    );
}
