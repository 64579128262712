import { useContext } from "react";
import {
    CommentsDrawerStateContext,
    ICommentDrawerStateContext,
} from "../context/CommentsDrawerStateProvider";
import { isDefined } from "@convin/utils/helper/common.helper";

export default function useCommentsDrawerStateContext(): ICommentDrawerStateContext {
    const context = useContext(CommentsDrawerStateContext);
    if (!isDefined(context)) {
        throw new Error(
            "CommentsDrawerStateContext must be wrapped around CommentsDrawerStateProvider"
        );
    }
    return context;
}
