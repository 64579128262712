import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function AudioTypeSvg({
    sx,
    noBorder,
}: {
    sx?: SxProps<Theme>;
    noBorder?: boolean;
}) {
    return (
        <SvgIcon
            {...(noBorder
                ? {
                      viewBox: "0 0 24 24",
                      sx: { width: 24, height: 24, fill: "none", ...sx },
                  }
                : {
                      viewBox: "0 0 30 30",
                      sx: { width: 30, height: 30, fill: "none", ...sx },
                  })}
        >
            {noBorder ? (
                <path
                    d="M12.1333 6V13.2677C11.7269 13.0335 11.2584 12.8888 10.7555 12.8888C9.2331 12.8888 8 14.1219 8 15.6444C8 17.1668 9.2331 18.3999 10.7555 18.3999C12.278 18.3999 13.5111 17.1668 13.5111 15.6444V8.75553H16.2666V6H12.1333Z"
                    fill="#5E83E1"
                />
            ) : (
                <>
                    <rect
                        width="30"
                        height="30"
                        rx="6"
                        fill="#5E82E3"
                        fillOpacity="0.2"
                    />
                    <path
                        d="M15 8.25V16.1625C14.5575 15.9075 14.0475 15.75 13.5 15.75C11.8425 15.75 10.5 17.0925 10.5 18.75C10.5 20.4075 11.8425 21.75 13.5 21.75C15.1575 21.75 16.5 20.4075 16.5 18.75V11.25H19.5V8.25H15Z"
                        fill="#5E83E1"
                    />
                </>
            )}
        </SvgIcon>
    );
}
