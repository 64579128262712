import { pxToRem } from "@convin/utils/getFontValue";
import { alpha, styled, Tab, Tabs, Theme } from "@mui/material";
import useAccuracyAnalysisTabContext from "../hooks/useAccuracyAnalysisTabContext";

export const StyledTab = styled(Tab)(({ theme }: { theme: Theme }) => ({
    textTransform: "none",
    fontWeight: 400,
    borderRadius: "6px",
    fontSize: pxToRem(14),
    color: theme.palette.textColors["333"],
    width: "180px",
    height: "34px",
    minHeight: "unset",
    "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: alpha(theme.palette.primary.main, 0.5),
        fontWeight: 500,
    },
}));

// Custom styled Tabs component
export const StyledTabs = styled(Tabs)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: "4px",
    "& .MuiTabs-indicator": {
        display: "none",
    },
    "& .MuiTabs-scroller": {
        display: "inline-flex",
        alightItems: "center",
    },
    background: alpha(theme.palette.textColors[999], 0.1),
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${alpha(theme.palette.textColors[999], 0.05)}`,
}));

export default function AccuracyAnalysisTabs() {
    const { tabValue, handleChange } = useAccuracyAnalysisTabContext();
    return (
        <StyledTabs
            value={tabValue}
            onChange={handleChange}
            aria-label="analysis tabs"
        >
            <StyledTab
                label="Accuracy Analysis"
                id="analysis-tab-0"
                aria-controls="analysis-tabpanel-0"
                value="accuracy"
            />
            <StyledTab
                label="Recall Analysis"
                id="analysis-tab-1"
                aria-controls="analysis-tabpanel-1"
                value="recall"
            />
        </StyledTabs>
    );
}
