import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function TemplateSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 19 18"
            sx={{
                width: 19,
                height: 18,
                fill: "none",
                color: "#666666",
                ...sx,
            }}
        >
            <mask
                id="mask0_14847_35892"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="18"
            >
                <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_14847_35892)">
                <path
                    d="M8.45 12.0375L13.7375 6.75L12.6875 5.7L8.45 9.9375L6.3125 7.8L5.2625 8.85L8.45 12.0375ZM4.25 15.75C3.8375 15.75 3.48438 15.6031 3.19063 15.3094C2.89688 15.0156 2.75 14.6625 2.75 14.25V3.75C2.75 3.3375 2.89688 2.98438 3.19063 2.69063C3.48438 2.39688 3.8375 2.25 4.25 2.25H7.4C7.5625 1.8 7.83437 1.4375 8.21562 1.1625C8.59688 0.8875 9.025 0.75 9.5 0.75C9.975 0.75 10.4031 0.8875 10.7844 1.1625C11.1656 1.4375 11.4375 1.8 11.6 2.25H14.75C15.1625 2.25 15.5156 2.39688 15.8094 2.69063C16.1031 2.98438 16.25 3.3375 16.25 3.75V14.25C16.25 14.6625 16.1031 15.0156 15.8094 15.3094C15.5156 15.6031 15.1625 15.75 14.75 15.75H4.25ZM9.5 3.1875C9.6625 3.1875 9.79688 3.13438 9.90313 3.02813C10.0094 2.92188 10.0625 2.7875 10.0625 2.625C10.0625 2.4625 10.0094 2.32812 9.90313 2.22187C9.79688 2.11563 9.6625 2.0625 9.5 2.0625C9.3375 2.0625 9.20313 2.11563 9.09688 2.22187C8.99063 2.32812 8.9375 2.4625 8.9375 2.625C8.9375 2.7875 8.99063 2.92188 9.09688 3.02813C9.20313 3.13438 9.3375 3.1875 9.5 3.1875Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
