import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import { useGetTemplateInfoByIdQuery } from "@convin/redux/services/settings/auditManager.service";
import { isDefined } from "@convin/utils/helper/common.helper";

import TemplateCategoryAccordion from "./TemplateCategoryAccordion";
import TemplateDrawerFilter from "./TemplateDrawerFilter";

const TemplateInfoFilter = () => {
    const { state: filtersState } = useGlobalFiltersStateContext();

    const {
        data: activeTemplateData,
        isFetching: isActiveTemplateDataFetching,
    } = useGetTemplateInfoByIdQuery(
        { template_id: Number(filtersState.template), questions: "all" },
        {
            skip: !isDefined(filtersState.template),
        }
    );

    return (
        <>
            <TemplateDrawerFilter />
            {isActiveTemplateDataFetching ? (
                <SkeletonLoader />
            ) : filtersState.template ? (
                activeTemplateData?.categories.map((category) => (
                    <TemplateCategoryAccordion
                        key={category.id}
                        data={category}
                    />
                ))
            ) : null}
        </>
    );
};

export default TemplateInfoFilter;
