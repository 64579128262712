export const SingleCallSvg = () => {
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g mask="url(#mask0_29600_1644)">
                    <path
                        d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21ZM6.025 9L7.675 7.35L7.25 5H5.025C5.10833 5.68333 5.225 6.35833 5.375 7.025C5.525 7.69167 5.74167 8.35 6.025 9ZM14.975 17.95C15.625 18.2333 16.2875 18.4583 16.9625 18.625C17.6375 18.7917 18.3167 18.9 19 18.95V16.75L16.65 16.275L14.975 17.95Z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        </>
    );
};

export const BulkCallSvg = () => {
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g mask="url(#mask0_29600_1709)">
                    <path
                        d="M12 4V2H22V4H12ZM12 8V6H22V8H12ZM12 12V10H22V12H12ZM18.95 22C16.8667 22 14.8083 21.5458 12.775 20.6375C10.7417 19.7292 8.89167 18.4417 7.225 16.775C5.55833 15.1083 4.27083 13.2583 3.3625 11.225C2.45417 9.19167 2 7.13333 2 5.05C2 4.75 2.1 4.5 2.3 4.3C2.5 4.1 2.75 4 3.05 4H7.1C7.33333 4 7.54167 4.07917 7.725 4.2375C7.90833 4.39583 8.01667 4.58333 8.05 4.8L8.7 8.3C8.73333 8.56667 8.725 8.79167 8.675 8.975C8.625 9.15833 8.53333 9.31667 8.4 9.45L5.975 11.9C6.30833 12.5167 6.70417 13.1125 7.1625 13.6875C7.62083 14.2625 8.125 14.8167 8.675 15.35C9.19167 15.8667 9.73333 16.3458 10.3 16.7875C10.8667 17.2292 11.4667 17.6333 12.1 18L14.45 15.65C14.6 15.5 14.7958 15.3875 15.0375 15.3125C15.2792 15.2375 15.5167 15.2167 15.75 15.25L19.2 15.95C19.4333 16.0167 19.625 16.1375 19.775 16.3125C19.925 16.4875 20 16.6833 20 16.9V20.95C20 21.25 19.9 21.5 19.7 21.7C19.5 21.9 19.25 22 18.95 22ZM5.025 10L6.675 8.35L6.25 6H4.025C4.10833 6.68333 4.225 7.35833 4.375 8.025C4.525 8.69167 4.74167 9.35 5.025 10ZM13.975 18.95C14.625 19.2333 15.2875 19.4583 15.9625 19.625C16.6375 19.7917 17.3167 19.9 18 19.95V17.75L15.65 17.275L13.975 18.95Z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        </>
    );
};

export const ConversationalPathwaySvg = () => {
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g mask="url(#mask0_30436_2124)">
                    <path
                        d="M18.95 21C16.8667 21 14.8083 20.5458 12.775 19.6375C10.7417 18.7292 8.89167 17.4417 7.225 15.775C5.55833 14.1083 4.27083 12.2583 3.3625 10.225C2.45417 8.19167 2 6.13333 2 4.05C2 3.75 2.1 3.5 2.3 3.3C2.5 3.1 2.75 3 3.05 3H7.1C7.33333 3 7.54167 3.07917 7.725 3.2375C7.90833 3.39583 8.01667 3.58333 8.05 3.8L8.7 7.3C8.73333 7.56667 8.725 7.79167 8.675 7.975C8.625 8.15833 8.53333 8.31667 8.4 8.45L5.975 10.9C6.30833 11.5167 6.70417 12.1125 7.1625 12.6875C7.62083 13.2625 8.125 13.8167 8.675 14.35C9.19167 14.8667 9.73333 15.3458 10.3 15.7875C10.8667 16.2292 11.4667 16.6333 12.1 17L14.45 14.65C14.6 14.5 14.7958 14.3875 15.0375 14.3125C15.2792 14.2375 15.5167 14.2167 15.75 14.25L19.2 14.95C19.4333 15.0167 19.625 15.1375 19.775 15.3125C19.925 15.4875 20 15.6833 20 15.9V19.95C20 20.25 19.9 20.5 19.7 20.7C19.5 20.9 19.25 21 18.95 21ZM5.025 9L6.675 7.35L6.25 5H4.025C4.10833 5.68333 4.225 6.35833 4.375 7.025C4.525 7.69167 4.74167 8.35 5.025 9ZM13.975 17.95C14.625 18.2333 15.2875 18.4583 15.9625 18.625C16.6375 18.7917 17.3167 18.9 18 18.95V16.75L15.65 16.275L13.975 17.95Z"
                        fill="currentColor"
                    />
                    <path
                        d="M11.5 11C11.5 10.675 11.5937 10.3833 11.781 10.125C11.9687 9.86667 12.2083 9.6875 12.5 9.5875L12.5 7C12.5 6.45 12.6958 5.97917 13.0875 5.5875C13.4792 5.19583 13.95 5 14.5 5C15.05 5 15.5208 5.19583 15.9125 5.5875C16.3042 5.97917 16.5 6.45 16.5 7L16.5 8C16.5 8.275 16.598 8.5105 16.794 8.7065C16.9897 8.90217 17.225 9 17.5 9C17.775 9 18.0105 8.90217 18.2065 8.7065C18.4022 8.5105 18.5 8.275 18.5 8L18.5 5.4125C18.2083 5.30417 17.9687 5.123 17.781 4.869C17.5937 4.61467 17.5 4.325 17.5 4C17.5 3.58333 17.6458 3.22917 17.9375 2.9375C18.2292 2.64583 18.5833 2.5 19 2.5C19.4167 2.5 19.7708 2.64583 20.0625 2.9375C20.3542 3.22917 20.5 3.58333 20.5 4C20.5 4.325 20.4063 4.61467 20.219 4.869C20.0313 5.123 19.7917 5.30417 19.5 5.4125L19.5 8C19.5 8.55 19.3042 9.02083 18.9125 9.4125C18.5208 9.80417 18.05 10 17.5 10C16.95 10 16.4792 9.80417 16.0875 9.4125C15.6958 9.02083 15.5 8.55 15.5 8L15.5 7C15.5 6.725 15.4022 6.48967 15.2065 6.294C15.0105 6.098 14.775 6 14.5 6C14.225 6 13.9895 6.098 13.7935 6.294C13.5978 6.48967 13.5 6.725 13.5 7L13.5 9.5875C13.7917 9.69583 14.0313 9.87717 14.219 10.1315C14.4063 10.3855 14.5 10.675 14.5 11C14.5 11.4167 14.3542 11.7708 14.0625 12.0625C13.7708 12.3542 13.4167 12.5 13 12.5C12.5833 12.5 12.2292 12.3542 11.9375 12.0625C11.6458 11.7708 11.5 11.4167 11.5 11ZM18.5 4C18.5 4.14167 18.5478 4.2605 18.6435 4.3565C18.7395 4.45217 18.8583 4.5 19 4.5C19.1417 4.5 19.2605 4.45217 19.3565 4.3565C19.4522 4.2605 19.5 4.14167 19.5 4C19.5 3.85833 19.4522 3.7395 19.3565 3.6435C19.2605 3.54783 19.1417 3.5 19 3.5C18.8583 3.5 18.7395 3.54783 18.6435 3.6435C18.5478 3.7395 18.5 3.85833 18.5 4Z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        </>
    );
};
