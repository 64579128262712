import { SvgIcon } from "@mui/material";
import { FC } from "react";

export const CyberArkSvg: FC = () => {
    return (
        <SvgIcon
            viewBox="0 0 50 50"
            sx={{ width: 24, height: 24, fill: "none" }}
        >
            <g clipPath="url(#clip0_16_3)">
                <path
                    d="M43.5454 13.0736L42.7073 12.5937L21.6265 0.369629L0.323242 12.7221V37.3155L0.618133 37.4815L21.6355 49.6123L42.0256 37.8421L43.2414 37.1405V23.4393L42.0256 24.1409L32.2956 29.7575V19.5578L43.5363 13.0723H43.5454V13.0736ZM22.2706 14.4404L31.0798 19.5591V29.7679L22.2706 24.6583V14.4404ZM20.9901 2.1528V13.0359L11.573 18.5048L2.1482 13.0736L20.9901 2.1528ZM20.9901 14.4404V24.6583L12.1809 29.7679V19.5591L20.9901 14.4404ZM1.53902 14.1266L10.9652 19.5591V30.4695L1.53902 35.9383V14.1266ZM2.17536 36.9836L11.573 31.5238L21.0082 36.9745L21.0264 47.8576L2.17536 36.9823V36.9836ZM21.6265 35.9124H21.6174L12.7811 30.8222L21.5903 25.7126L21.6265 25.7308L21.664 25.7126L30.4719 30.8222L29.4579 31.4045L21.6355 35.9227H21.6265V35.9137V35.9124ZM32.9047 30.8222L42.0269 25.5466V36.4298L41.0867 36.9745L22.2344 47.8486L22.2525 36.9654L32.9034 30.8119V30.8209L32.9047 30.8222ZM31.689 18.5074L22.2719 13.0385V2.1528L41.1138 13.0736L31.6877 18.5061L31.689 18.5074Z"
                    fill="#082233"
                />
                <path
                    d="M21.6253 49.9909L0 37.5088V12.5366L21.6253 0L44.1805 13.0722L32.6164 19.7523V29.2128L43.5623 22.8933V37.3428L21.624 50V49.9909H21.6253ZM0.645396 37.1301L0.774734 37.204L21.6356 49.2426L42.9298 36.955V24.0021L31.984 30.3216V19.3736L42.9027 13.0722L21.6253 0.748282L0.645396 12.9153V37.1301ZM21.3498 48.4191L1.53007 36.9822L11.5732 31.1529L21.3304 36.789L21.3485 48.4204L21.3498 48.4191ZM2.81956 36.9822L20.7044 47.3026L20.6863 37.1677L11.5732 31.9116L2.81956 36.9926V36.9835V36.9822ZM21.9111 48.4204L21.9292 36.789L42.3478 24.9916V36.623L41.242 37.2598L21.9111 48.4204ZM22.5746 37.1586L22.5565 47.2935L41.7037 36.2443V26.1004L22.5759 37.1495V37.1586H22.5746ZM1.21707 36.5024V13.5715L11.2873 19.3827V30.6627L1.21578 36.5115V36.5024H1.21707ZM1.86117 14.6894V35.3845L10.6419 30.2853V19.7536L1.86117 14.6894ZM21.6162 36.2897L12.1345 30.8209L21.5878 25.343L31.1148 30.8209L21.6434 36.2897H21.6162ZM13.4343 30.8209L21.6343 35.5518L29.8344 30.8209L21.6253 26.0718L13.4343 30.8209ZM31.4019 30.332L21.9486 24.8528V13.8854L31.4019 19.3736V30.3307V30.332ZM22.5927 24.4741L30.7565 29.2037V19.7432L22.5927 15.0032V24.4728V24.4741ZM11.859 30.332V19.3749L21.3123 13.8867V24.8528L11.859 30.332ZM12.5044 19.7445V29.205L20.6682 24.4754V15.0032L12.5044 19.7432V19.7445ZM31.6878 18.886L21.9486 13.2317V1.58864L41.7593 13.0722L31.6878 18.8834V18.886ZM22.594 12.8531L31.6878 18.1377L40.4685 13.0748L22.5927 2.7156V12.8505L22.594 12.8531ZM11.5744 18.886L1.50161 13.0722L21.3123 1.58864V13.2201L11.5732 18.8743V18.8834L11.5744 18.886ZM2.7924 13.0722L11.5732 18.1351L20.6669 12.8505V2.7156L2.7924 13.0722Z"
                    fill="#082233"
                />
            </g>
        </SvgIcon>
    );
};
