import { toast, ToastOptions, TypeOptions } from "react-toastify";

type ToastParams = {
    type: TypeOptions;
    message: string;
} & ToastOptions;

export function showToast({ type, message, ...rest }: ToastParams) {
    if (
        type === "success" ||
        type === "error" ||
        type === "info" ||
        type === "warning"
    ) {
        return toast[type](message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            ...rest,
        });
    }
    return toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...rest,
    } as ToastOptions);
}

export function showNoPermissionToast(payload: Partial<ToastParams> = {}) {
    return showToast({
        type: "error",
        message: "You do not have the permission to access this feature",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...payload,
    });
}
