import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function MailSentSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 232 232"
            sx={{ width: 232, height: 232, fill: "none", ...sx }}
        >
            <path
                d="M116 232C180.065 232 232 180.065 232 116C232 51.935 180.065 0 116 0C51.935 0 0 51.935 0 116C0 180.065 51.935 232 116 232Z"
                fill="url(#paint0_linear_33869_5683)"
                fillOpacity="0.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.714 114.951L167.364 136.736V176.845C167.364 179.24 165.41 181.194 163.016 181.194H60.3488C57.954 181.194 56 179.24 56 176.845V119.303C56 116.908 57.954 114.954 60.3488 114.954L115.714 114.951Z"
                fill="url(#paint1_linear_33869_5683)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.4134 180.05L94.1665 150.311L57.3252 116.179C56.5083 116.969 56 118.077 56 119.299V176.841C56 178.111 56.5465 179.254 57.4134 180.05Z"
                fill="url(#paint2_linear_33869_5683)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.195 150.309L165.948 180.048C166.818 179.252 167.362 178.109 167.362 176.842V136.739L139.25 140.994L129.195 150.309Z"
                fill="url(#paint3_linear_33869_5683)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.3485 181.194H163.012C164.141 181.194 165.172 180.759 165.948 180.048L129.195 150.309L114.638 163.793C112.966 165.341 110.398 165.341 108.726 163.793L94.1692 150.309L57.4131 180.048C58.1888 180.759 59.2202 181.194 60.3485 181.194Z"
                fill="url(#paint4_linear_33869_5683)"
            />
            <path
                d="M146.117 146.765C162.621 146.765 176 133.386 176 116.883C176 100.379 162.621 87 146.117 87C129.613 87 116.234 100.379 116.234 116.883C116.234 133.386 129.613 146.765 146.117 146.765Z"
                fill="white"
            />
            <path
                d="M143.1 127.176L158.476 111.8L155.423 108.747L143.1 121.07L136.884 114.854L133.831 117.907L143.1 127.176ZM146.154 138.954C143.137 138.954 140.301 138.381 137.648 137.236C134.994 136.091 132.686 134.537 130.723 132.574C128.76 130.612 127.206 128.303 126.061 125.65C124.916 122.996 124.344 120.161 124.344 117.144C124.344 114.127 124.916 111.292 126.061 108.638C127.206 105.984 128.76 103.676 130.723 101.713C132.686 99.7505 134.994 98.1965 137.648 97.0515C140.301 95.9065 143.137 95.334 146.154 95.334C149.171 95.334 152.006 95.9065 154.66 97.0515C157.313 98.1965 159.621 99.7505 161.584 101.713C163.547 103.676 165.101 105.984 166.246 108.638C167.391 111.292 167.964 114.127 167.964 117.144C167.964 120.161 167.391 122.996 166.246 125.65C165.101 128.303 163.547 130.612 161.584 132.574C159.621 134.537 157.313 136.091 154.66 137.236C152.006 138.381 149.171 138.954 146.154 138.954Z"
                fill="url(#paint5_linear_33869_5683)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_33869_5683"
                    x1="116"
                    y1="-3.4059e-08"
                    x2="116"
                    y2="187"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" stopOpacity="0.5" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_33869_5683"
                    x1="111.585"
                    y1="86.535"
                    x2="111.585"
                    y2="145.581"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_33869_5683"
                    x1="51.6095"
                    y1="131.633"
                    x2="93.9181"
                    y2="152.09"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_33869_5683"
                    x1="167.365"
                    y1="122.426"
                    x2="127.859"
                    y2="156.739"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_33869_5683"
                    x1="111.535"
                    y1="94.2181"
                    x2="111.68"
                    y2="181.194"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_33869_5683"
                    x1="146.154"
                    y1="95.334"
                    x2="146.154"
                    y2="138.954"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#C0D5FF" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
