import { useContext } from "react";
import {
    AccuracyAnalysisTabContext,
    IAccuracyAnalysisTabsContext,
} from "../context/context";

const useAccuracyAnalysisTabContext = (): IAccuracyAnalysisTabsContext => {
    const context = useContext(AccuracyAnalysisTabContext);

    if (!context)
        throw new Error(
            "AccuracyAnalysisTab context must be use inside AccuracyAnalysisTab provider"
        );

    return context;
};

export default useAccuracyAnalysisTabContext;
