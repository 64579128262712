import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function WarningIconSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 19 18"
            sx={{ width: 19, height: 18, fill: "none", ...sx }}
        >
            <g mask="url(#mask0_34690_1012)">
                <path
                    d="M9.5002 16.5187C9.3002 16.5187 9.10957 16.4812 8.92832 16.4062C8.74707 16.3312 8.58145 16.225 8.43145 16.0875L2.4127 10.0687C2.2752 9.9187 2.16895 9.75308 2.09395 9.57183C2.01895 9.39058 1.98145 9.19995 1.98145 8.99995C1.98145 8.79995 2.01895 8.6062 2.09395 8.4187C2.16895 8.2312 2.2752 8.0687 2.4127 7.9312L8.43145 1.91245C8.58145 1.76245 8.74707 1.65308 8.92832 1.58433C9.10957 1.51558 9.3002 1.4812 9.5002 1.4812C9.7002 1.4812 9.89395 1.51558 10.0814 1.58433C10.2689 1.65308 10.4314 1.76245 10.5689 1.91245L16.5877 7.9312C16.7377 8.0687 16.8471 8.2312 16.9158 8.4187C16.9846 8.6062 17.0189 8.79995 17.0189 8.99995C17.0189 9.19995 16.9846 9.39058 16.9158 9.57183C16.8471 9.75308 16.7377 9.9187 16.5877 10.0687L10.5689 16.0875C10.4314 16.225 10.2689 16.3312 10.0814 16.4062C9.89395 16.4812 9.7002 16.5187 9.5002 16.5187ZM8.7502 9.74995H10.2502V5.24995H8.7502V9.74995ZM9.5002 12C9.7127 12 9.89082 11.9281 10.0346 11.7843C10.1783 11.6406 10.2502 11.4625 10.2502 11.25C10.2502 11.0375 10.1783 10.8593 10.0346 10.7156C9.89082 10.5718 9.7127 10.5 9.5002 10.5C9.2877 10.5 9.10957 10.5718 8.96582 10.7156C8.82207 10.8593 8.7502 11.0375 8.7502 11.25C8.7502 11.4625 8.82207 11.6406 8.96582 11.7843C9.10957 11.9281 9.2877 12 9.5002 12Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
