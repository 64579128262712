import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";

export default function useBranding() {
    const { data: domainConfig } = useGetDomainConfigQuery();

    return {
        logo: domainConfig?.logo,
        branding: domainConfig?.brand || "Convin",
    };
}
