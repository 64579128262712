import { Box } from "@mui/material";

import DateFilter from "@convin/components/custom_components/Filters/DateFilter";
import { Label } from "@convin/components/custom_components/Typography/Label";
import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import { datekeys } from "@convin/config/default.config";
import { useGetAllAuditorsQuery } from "@convin/redux/services/settings/users.service";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import { AuditType } from "@convin/type/Filters.model";
import AuditorFilter from "./AuditorFilter";

const AuditTypeFilter = () => {
    const { data: auditorsList } = useGetAllAuditorsQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const isManual = filtersState.auditType === AuditType.manual;

    return (
        <>
            <Box>
                <GenericRadioGroup<string | null>
                    value={
                        isDefined(filtersState.auditType)
                            ? filtersState.auditType === AuditType.manual
                                ? "manual"
                                : "ai"
                            : null
                    }
                    handleChange={(val) => {
                        updateFiltersState({
                            auditType: val === "manual" ? 1 : 0,
                            ...(val !== "manual"
                                ? {}
                                : {
                                      auditDateKey: datekeys.last30days,
                                      auditors:
                                          auditorsList?.map((e) => e.id) ?? [],
                                  }),
                        });
                    }}
                    options={[
                        { id: "ai", label: "AI" },
                        { id: "manual", label: "Manual" },
                    ]}
                    className="flex"
                    sx={{ gap: 4, mb: 2.5 }}
                />
            </Box>
            {isManual ? (
                <>
                    <AuditDateFilter />
                    <AuditorFilter />
                </>
            ) : null}
        </>
    );
};

const AuditDateFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    return (
        <Box
            sx={{
                mb: 2.5,
            }}
        >
            <Label
                variant="medium"
                colorType="666"
                className="font-medium uppercase"
                sx={{ mb: 2.5 }}
            >
                Select Audited Date
            </Label>
            <Box>
                <DateFilter
                    label={"Audited Date"}
                    value={filtersState.auditDateKey}
                    options={filtersState.auditDateOptions}
                    showCustomTimePicker={true}
                    handleUpdate={(e) => {
                        if (!Boolean(e.is_custom)) {
                            updateFiltersState({
                                auditDateKey: e.value,
                            });
                        } else {
                            updateFiltersState({
                                auditDateKey: e.value,
                                auditDateOptions: {
                                    ...filtersState.auditDateOptions,
                                    ...(isDefined(e.value) && {
                                        [e.value]: {
                                            name: e.value,
                                            is_rolling_date: false,
                                            label: "",
                                            dateRange: e.dateRange,
                                        },
                                    }),
                                },
                            });
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default AuditTypeFilter;
