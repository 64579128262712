import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function TeamSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 19 18"
            sx={{
                width: 19,
                height: 18,
                fill: "none",

                ...sx,
            }}
        >
            <mask
                id="mask0_14847_38293"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="18"
            >
                <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_14847_38293)">
                <path
                    d="M0.5 13.5V12.3188C0.5 11.7812 0.775 11.3438 1.325 11.0063C1.875 10.6688 2.6 10.5 3.5 10.5C3.6625 10.5 3.81875 10.5031 3.96875 10.5094C4.11875 10.5156 4.2625 10.5312 4.4 10.5562C4.225 10.8187 4.09375 11.0938 4.00625 11.3813C3.91875 11.6688 3.875 11.9688 3.875 12.2812V13.5H0.5ZM5 13.5V12.2812C5 11.8812 5.10938 11.5156 5.32812 11.1844C5.54688 10.8531 5.85625 10.5625 6.25625 10.3125C6.65625 10.0625 7.13438 9.875 7.69063 9.75C8.24688 9.625 8.85 9.5625 9.5 9.5625C10.1625 9.5625 10.7719 9.625 11.3281 9.75C11.8844 9.875 12.3625 10.0625 12.7625 10.3125C13.1625 10.5625 13.4688 10.8531 13.6813 11.1844C13.8938 11.5156 14 11.8812 14 12.2812V13.5H5ZM15.125 13.5V12.2812C15.125 11.9563 15.0844 11.65 15.0031 11.3625C14.9219 11.075 14.8 10.8063 14.6375 10.5562C14.775 10.5312 14.9156 10.5156 15.0594 10.5094C15.2031 10.5031 15.35 10.5 15.5 10.5C16.4 10.5 17.125 10.6656 17.675 10.9969C18.225 11.3281 18.5 11.7688 18.5 12.3188V13.5H15.125ZM3.5 9.75C3.0875 9.75 2.73438 9.60313 2.44063 9.30937C2.14688 9.01562 2 8.6625 2 8.25C2 7.825 2.14688 7.46875 2.44063 7.18125C2.73438 6.89375 3.0875 6.75 3.5 6.75C3.925 6.75 4.28125 6.89375 4.56875 7.18125C4.85625 7.46875 5 7.825 5 8.25C5 8.6625 4.85625 9.01562 4.56875 9.30937C4.28125 9.60313 3.925 9.75 3.5 9.75ZM15.5 9.75C15.0875 9.75 14.7344 9.60313 14.4406 9.30937C14.1469 9.01562 14 8.6625 14 8.25C14 7.825 14.1469 7.46875 14.4406 7.18125C14.7344 6.89375 15.0875 6.75 15.5 6.75C15.925 6.75 16.2813 6.89375 16.5688 7.18125C16.8563 7.46875 17 7.825 17 8.25C17 8.6625 16.8563 9.01562 16.5688 9.30937C16.2813 9.60313 15.925 9.75 15.5 9.75ZM9.5 9C8.875 9 8.34375 8.78125 7.90625 8.34375C7.46875 7.90625 7.25 7.375 7.25 6.75C7.25 6.1125 7.46875 5.57812 7.90625 5.14688C8.34375 4.71563 8.875 4.5 9.5 4.5C10.1375 4.5 10.6719 4.71563 11.1031 5.14688C11.5344 5.57812 11.75 6.1125 11.75 6.75C11.75 7.375 11.5344 7.90625 11.1031 8.34375C10.6719 8.78125 10.1375 9 9.5 9Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
