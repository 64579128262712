import { CheckCircle, Close } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";

import {
    Box,
    Button,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import DownloadProgressSvg from "@convin/components/svg/Notifications/DownloadProgressSvg";
import { getDateTime } from "@convin/utils/helper/date.helper";
import { DownloadReport } from "@convin/type/Report";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    useDeleteDownloadReportMutation,
    useHandleDownloadReportMutation,
    useRetryDownloadReportMutation,
} from "@convin/redux/services/home/analyticsDashboard.service";

import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";

interface Props {
    data: DownloadReport;
    prevIndexedData: DownloadReport | null;
}

const StatusMap: Record<
    DownloadReport["task_status"],
    {
        Icon: typeof SvgIcon | typeof DownloadProgressSvg;
        prefix: string;
        content: string;
    }
> = {
    completed: {
        prefix: "Downloaded",
        Icon: CheckCircle,
        content: "Downloaded",
    },
    failed: { prefix: "Download Failed", Icon: ReportIcon, content: "Failed" },
    pending: {
        prefix: "Downloading",
        Icon: DownloadProgressSvg,
        content: "Processing",
    },
    processing: {
        prefix: "Downloading",
        Icon: DownloadProgressSvg,
        content: "Processing",
    },
};

const DownloadNotificationCard: React.FC<Props> = ({
    data,
    prevIndexedData,
}) => {
    const [deleteReport] = useDeleteDownloadReportMutation();
    const [retryDownload, { isLoading: isRetrying }] =
        useRetryDownloadReportMutation();
    const [downloadReport, { isLoading: isDownloading }] =
        useHandleDownloadReportMutation();
    const { created_at, task_status, id, report_name, report_type, message } =
        data;
    const theme = useTheme();

    const Icon = StatusMap[task_status].Icon;
    const color =
        theme.palette.notificationColors[task_status] ||
        theme.palette.primary.main;
    const prevDay = isDefined(prevIndexedData)
        ? getDateTime({
              isoDate: prevIndexedData.created_at,
              format: "dd MM 'YY",
          })
        : null;

    const currDay = getDateTime({
        isoDate: created_at,
        format: "dd MM 'YY",
    });

    const isToday =
        currDay ===
        getDateTime({
            isoDate: new Date(),
            format: "dd MM 'YY",
        });

    const isYesterday =
        currDay ===
        getDateTime({
            isoDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            format: "dd MM 'YY",
        });

    const displayTime = getDateTime({
        isoDate: created_at,
        format: "MM dd, T",
    });

    const handleDownload = () => {
        if (task_status === "completed") {
            downloadReport(id);
        } else if (task_status === "failed") {
            if (isDefined(message)) {
                showToast({
                    message,
                    ...CreateUpdateToastSettings,
                });
            } else retryDownload(id);
        }
    };

    return (
        <Stack>
            {currDay !== prevDay ? (
                <Label colorType="999" variant="medium" className="font-medium">
                    {isToday ? "Today" : isYesterday ? "Yesterday" : currDay}
                </Label>
            ) : null}

            <Box
                className="flex items-center justify-center"
                gap={1.5}
                sx={{
                    ...((task_status === "pending" ||
                        task_status === "processing") && {
                        mb: 2.5,
                    }),
                }}
            >
                <Box
                    className="rounded-full h-[40px] w-[40px] flex-shrink-0 flex items-center justify-center"
                    sx={{
                        color,
                        bgcolor: alpha(color, 0.1),
                    }}
                >
                    <Icon />
                </Box>
                <Stack
                    gap={0.5}
                    pt={1}
                    className="flex-1 flex-grow overflow-hidden"
                >
                    <Box className="flex items-center justify-between ">
                        <Label
                            colorType="333"
                            className="font-medium"
                            isEllipses
                            hasTooltip
                        >
                            {isDefined(report_name)
                                ? `${StatusMap[task_status].prefix} | ${report_name}`
                                : report_type}
                        </Label>
                        {task_status !== "pending" &&
                            task_status !== "processing" && (
                                <IconButton
                                    className="scale-90"
                                    onClick={() => {
                                        deleteReport(id);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            )}
                    </Box>
                    <Box
                        className="flex items-center justify-between"
                        sx={{
                            mb: 1.5,
                        }}
                    >
                        <Label colorType="666" variant="small">
                            {task_status === "pending" ||
                            task_status === "processing"
                                ? "...Downloading"
                                : displayTime}
                        </Label>
                        {task_status !== "pending" &&
                            task_status !== "processing" && (
                                <Box className="flex flex-row-reverse h-[20px]">
                                    <Typography
                                        variant="medium"
                                        color="primary.main"
                                        className="underline"
                                        component={Button}
                                        onClick={handleDownload}
                                        loading={isRetrying || isDownloading}
                                        p={0}
                                    >
                                        {task_status === "completed"
                                            ? "Download"
                                            : "Restart Download"}
                                    </Typography>
                                </Box>
                            )}
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
};

export default DownloadNotificationCard;
