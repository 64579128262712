import EmptyState from "./EmptyState";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import Error500Svg from "@convin/components/svg/Error500Svg";

const {
    subRoutes: { overall_dashboard },
} = DashboardRoutesConfig["Home"];

const Error500State = () => {
    return (
        <EmptyState
            Component={<Error500Svg />}
            text="Oh No! Internal Server Error"
            subtext="Uh-oh! Something's not right on our end. Try refreshing the page or check back later."
            ButtonComponent={
                <Link to={overall_dashboard.to}>
                    {" "}
                    <Button variant="global">Back Home</Button>
                </Link>
            }
        />
    );
};

export default Error500State;
