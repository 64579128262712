import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function ParameterSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.54163 2.0625C7.09188 2.0625 5.91663 3.23775 5.91663 4.6875L5.91664 4.6944C5.39674 4.7102 5.0708 4.76219 4.81475 4.93327C4.65552 5.03967 4.5188 5.17639 4.4124 5.33563C4.16663 5.70345 4.16663 6.2155 4.16663 7.23958V14.1667C4.16663 15.5416 4.16663 16.2291 4.59376 16.6562C5.0209 17.0833 5.70836 17.0833 7.08329 17.0833H12.9166C14.2916 17.0833 14.979 17.0833 15.4062 16.6562C15.8333 16.2291 15.8333 15.5416 15.8333 14.1667V7.23958C15.8333 6.2155 15.8333 5.70345 15.5875 5.33563C15.4811 5.17639 15.3444 5.03967 15.1852 4.93327C14.9291 4.76219 14.6032 4.7102 14.0833 4.6944L14.0833 4.6875C14.0833 3.23775 12.908 2.0625 11.4583 2.0625H8.54163ZM8.39579 4.6875C8.39579 4.20425 8.78754 3.8125 9.27079 3.8125H10.7291C11.2124 3.8125 11.6041 4.20425 11.6041 4.6875C11.6041 5.17075 11.2124 5.5625 10.7291 5.5625H9.27079C8.78754 5.5625 8.39579 5.17075 8.39579 4.6875ZM7.81246 8.91667C7.32921 8.91667 6.93746 9.30842 6.93746 9.79167C6.93746 10.2749 7.32921 10.6667 7.81246 10.6667H12.1875C12.6707 10.6667 13.0625 10.2749 13.0625 9.79167C13.0625 9.30842 12.6707 8.91667 12.1875 8.91667H7.81246ZM7.81246 11.8333C7.32921 11.8333 6.93746 12.2251 6.93746 12.7083C6.93746 13.1916 7.32921 13.5833 7.81246 13.5833H10.7291C11.2124 13.5833 11.6041 13.1916 11.6041 12.7083C11.6041 12.2251 11.2124 11.8333 10.7291 11.8333H7.81246Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
