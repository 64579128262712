import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import { RouteType } from "@convin/config/routes.config";
import { AuthUserType } from "@convin/type/User";
import { createContext } from "react";

export type CheckCanAccessParams = {
    heading: PermissionModules;
    code_name?: string;
    permission_type?: PermissionTypes;
};
export interface IAuthContextState {
    user: AuthUserType | null | undefined;
    isAuthenticated: boolean;
    isInitialized: boolean;
}

export interface IAuthContextInterface extends IAuthContextState {
    handleLogin: (
        credentials:
            | {
                  email: string;
                  password: string;
                  rawEmail: string;
              }
            | { params: { [k: string]: string } }
    ) => Promise<void>;
    handleLogout: () => Promise<void>;
    initialize: () => Promise<void>;
    isAuditor: boolean;
    checkIsOwner: (id: number | undefined | null) => boolean;
    checkCanAccess: ({
        heading,
        code_name,
        permission_type,
    }: CheckCanAccessParams) => boolean;
    isLoginRequestInProgress: boolean;
    isLogoutRequestInProgress: boolean;
    isAuthenticateRequestInProgress: boolean;
    checkDashboardVisibility: (e: RouteType) => boolean;
    handleVerifyOtp: (email: string, otp: string) => Promise<void>;
    isVerifyOtpRequestInProgress: boolean;
    updateUser: (user: AuthUserType) => void;
}

const AuthContext = createContext<IAuthContextInterface | null>(null);
// ----------------------------------------------------------------------

export default AuthContext;
