import React, { ReactElement } from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { Box, Fade, SxProps, Theme, alpha } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { pressedStateIconSx } from "@convin/utils/helper/MuiUtils";
import { isDefined } from "@convin/utils/helper/common.helper";

interface Props extends Omit<PopoverProps, "open"> {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    containerSx?: SxProps<Theme>;
}

type ChildrenProps = {
    // onClose: () => void;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    preventClose?: boolean;
};

export default function MoreOptions({
    children,
    className = "",
    disabled = false,
    containerSx = {},
    element,
    rightClickable,
    ...rest
}: Props & {
    element?: React.ReactNode;
    rightClickable?: boolean;
}): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement<ChildrenProps>(child)) {
            return React.cloneElement(child, {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    event?.stopPropagation();
                    child?.props?.onClick && child.props.onClick(event);
                    handleClose();
                },
            });
        }
        return child;
    });

    return (
        <Box className={className} sx={containerSx}>
            {isDefined(element) ? (
                <div
                    {...(rightClickable
                        ? {
                              onContextMenu: (e) => !disabled && handleClick(e),
                          }
                        : {
                              onClick: (e) => !disabled && handleClick(e),
                          })}
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                >
                    {element}
                </div>
            ) : (
                <div
                    aria-describedby={id}
                    {...(rightClickable
                        ? {
                              onContextMenu: (e) => !disabled && handleClick(e),
                          }
                        : {
                              onClick: (e) => !disabled && handleClick(e),
                          })}
                    className={`${
                        disabled ? "cursor-not-allowed" : "cursor-pointer"
                    } flex items-center justify-center`}
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                    data-testid="three-dots-icon"
                >
                    <MoreVert
                        sx={(theme) => ({
                            ...(open ? pressedStateIconSx(theme) : {}),
                        })}
                    />
                </div>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(event) => {
                    handleClose();
                    (event as React.MouseEvent).stopPropagation();
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    fontSize: "14px",
                    "& .MuiPopover-paper": {
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1) !important",
                    },
                }}
                data-testid="crud-popover"
                TransitionComponent={Fade}
                {...rest}
            >
                <Box
                    className="flex flex-col cursor-pointer"
                    sx={{
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: "divider",
                        overflow: "hidden",
                    }}
                >
                    {childrenWithProps}
                </Box>
            </Popover>
        </Box>
    );
}

export const EditDeleteOptionsSxProp = (theme: Theme) => {
    return {
        px: 2.5,
        py: 0.75,
        "&:hover": {
            color: "primary.main",
            bgcolor: alpha(theme.palette.primary.main, 0.2),
        },
    };
};
