import {
    auditAiConfig,
    useGetExistingBlocksDataQuery,
    useLazyGetExistingBlocksDataQuery,
} from "@convin/redux/services/settings/auditAiConfig.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { BertNodeKeys } from "../constants";

export function useGetExistingBlocks(
    payload?: {
        block_name?: string;
        type?: BertNodeKeys.singleBlockNode | BertNodeKeys.groupBlockNode;
    },
    fetchAll?: boolean,
    extras?: {
        skip?: boolean;
        refetchOnMountOrArgChange?: boolean;
    }
) {
    // baseResult is for example
    const baseResult = useGetExistingBlocksDataQuery(payload ?? {}, {
        ...extras,
    });
    // trigger may be fired many times
    const [trigger, nextResult] = useLazyGetExistingBlocksDataQuery();
    const dispatch = useDispatch();
    const isBaseReady = useRef(false);
    const isNextDone = useRef(true);
    // next: starts with a null, fetching ended with a null, It stores a URL
    const next = useRef<null | string>(null);
    const abortControllerRef = useRef<ReturnType<typeof trigger> | null>(null);

    // Function to reset state
    const resetState = () => {
        isBaseReady.current = false;
        isNextDone.current = true;
        next.current = null;
    };

    // Base result
    useEffect(() => {
        if (!baseResult.data) {
            return;
        }
        next.current = baseResult.data?.last_evaluated_key;
        if (baseResult.data) {
            isBaseReady.current = true;
            fetchAll && fetchNext();
        }
    }, [baseResult]);

    // When there comes a next fetched result
    useEffect(() => {
        if (nextResult.isFetching) return;
        abortControllerRef.current = null;
        if (!nextResult.isSuccess) {
            resetState();
            return;
        }
        if (
            isBaseReady.current &&
            nextResult.data &&
            nextResult.data.last_evaluated_key !== next.current
        ) {
            next.current = nextResult.data.last_evaluated_key; // undefined if no data further

            // Put next fetched snippets into the first queried collection (as a base collection)
            // This can help us do optimistic/pessimistic updates against the base collection
            const newItems = nextResult.data?.nodes || [];
            dispatch(
                auditAiConfig.util.updateQueryData(
                    "getExistingBlocksData",
                    payload ?? {},
                    (drafts) => {
                        drafts.last_evaluated_key =
                            nextResult.data.last_evaluated_key;
                        if (newItems && newItems.length > 0) {
                            drafts.nodes.push(...newItems);
                        }
                    }
                )
            );
        }
    }, [nextResult]);

    const fetchNext = async () => {
        if (
            !isBaseReady.current ||
            !isNextDone.current ||
            !isDefined(next.current)
        )
            return;

        try {
            isNextDone.current = false;
            const result = trigger({
                last_evaluated_key: next.current,
            });
            abortControllerRef.current = result;
        } catch (e) {
            console.error("Fetch next error:", e);
        } finally {
            isNextDone.current = true;
            fetchAll && fetchNext();
        }
    };

    const refetch = async () => {
        resetState();
        await baseResult.refetch(); // restart with a whole new refetching
    };

    // Reset everything when payload changes
    useEffect(() => {
        return () => {
            resetState();
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
                abortControllerRef.current = null;
            }
        };
    }, []);

    return {
        data: baseResult.data,
        error: baseResult.error,
        isError: baseResult.isError,
        isLoading: baseResult.isLoading,
        isFetching: baseResult.isFetching || nextResult.isFetching,
        errorNext: nextResult.error,
        isErrorNext: nextResult.isError,
        isFetchingNext: nextResult.isFetching,
        hasNext: baseResult.data?.last_evaluated_key !== "",
        fetchNext,
        refetch,
    };
}
