import { Backdrop, Badge, IconButton } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

import BellSvg from "@convin/components/svg/Notifications/BellSvg";
import useWebSocketContextProvider from "@convin/components/websocket/hooks/useWebSocketContextProvider";
import { isDefined } from "@convin/utils/helper/common.helper";

import { RootStyle } from "../components/Comments/CommentsComponent";
import NotificationsInterface from "./components/NotificationsInterface";

const Notifications = () => {
    const {
        open,
        setOpen,
        notificationIsUnread,
        setNotificationIsUnread,
        buttonRef,
    } = useWebSocketContextProvider();

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{
                    background: "transparent",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            />
            <IconButton
                onClick={() => {
                    !open && setNotificationIsUnread(false);
                    handleToggle();
                }}
                className={"h-8 w-8 notification__bounce"}
                ref={buttonRef}
            >
                <Badge
                    color="error"
                    variant="dot"
                    invisible={!notificationIsUnread}
                    overlap="circular"
                >
                    <BellSvg open={open} />
                </Badge>
            </IconButton>
            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle
                            initial={{ x: 0, y: -1000, opacity: 0 }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 0,
                                    ease: [0.43, 0.13, 0.23, 0.96],
                                },
                            }}
                            exit={{
                                opacity: 0,
                                y: -1000,
                                transition: {
                                    duration: 0,
                                    ease: [0.43, 0.13, 0.23, 0.96],
                                },
                            }}
                            sx={{
                                ...(isDefined(buttonRef.current) && {
                                    marginRight: `${
                                        window.innerWidth -
                                        buttonRef.current?.getBoundingClientRect()
                                            .x -
                                        buttonRef.current?.getBoundingClientRect()
                                            .width
                                    }px`,
                                    top:
                                        buttonRef?.current?.getBoundingClientRect()
                                            .top +
                                        buttonRef.current?.getBoundingClientRect()
                                            .height +
                                        5,
                                }),
                                borderRadius: "12px",
                                mb: 2,
                                border: "1px solid",
                                borderColor: "divider",
                                bgcolor: "background.paper",
                            }}
                        >
                            <NotificationsInterface />
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>
        </>
    );
};

export default Notifications;
