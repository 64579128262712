import { Box, Typography } from "@mui/material";
import {
    DateOrTimeView,
    DateTimePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateDropDownProps } from "./DateFilter";
import { showToast } from "@convin/utils/toast";

type Props = Pick<
    DateDropDownProps,
    "handleRangeChange" | "showCustomTimePicker"
>;

export const CustomDateSelector = React.memo(function CustomDateSelector({
    handleRangeChange,
    showCustomTimePicker,
}: Props) {
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
        null,
        null,
    ]);
    const buttonRef = useRef<HTMLDivElement>(null);

    const now = dayjs();
    const today = now.startOf("day");

    useEffect(() => {
        setTimeout(() => {
            buttonRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        });
    }, []);

    const triggerInvalidDateToast = useCallback((message?: string) => {
        showToast({
            type: "error",
            message: message ?? "Invalid Date",
        });
    }, []);

    const handleDateChange = useCallback(
        (index: number, newValue: Dayjs | null) => {
            setDateRange((prev) => {
                const newRange = [...prev] as [Dayjs | null, Dayjs | null];
                if (index === 1 && newValue) {
                    if (newValue.isAfter(now)) {
                        newRange[index] = now;
                    } else if (
                        newValue.isBefore(today, "day") &&
                        prev[index] === null
                    ) {
                        newRange[index] = newValue.endOf("day");
                    } else if (
                        newValue.isSame(today, "day") &&
                        prev[index] === null
                    ) {
                        newRange[index] = now;
                    } else {
                        newRange[index] = newValue;
                    }
                    //checks for if "to" date is less than "from" date
                    if (newValue && prev[0] && newValue.isBefore(prev[0])) {
                        newRange[index] = null;
                        triggerInvalidDateToast(
                            "To Date should be greater than From Date"
                        );
                    }
                } else {
                    if (newValue && newValue.isAfter(now)) {
                        newRange[index] = now;
                    } else newRange[index] = newValue;

                    //checks for if "from" date is less than "to" date
                    if (
                        newValue &&
                        newValue.toDate() &&
                        prev[1] &&
                        newValue.isAfter(prev[1])
                    ) {
                        newRange[index] = null;
                        triggerInvalidDateToast(
                            "From Date should be less than To Date"
                        );
                    }
                }
                return newRange;
            });
        },
        [now, today]
    );

    const handleOnApply = useCallback(() => {
        if (dateRange[0] !== null && !dateRange[0]?.isValid()) {
            handleDateChange(0, null);
            triggerInvalidDateToast(
                showCustomTimePicker ? "Invalid Date Time" : "Invalid Date"
            );
            return;
        } else if (dateRange[1] !== null && !dateRange[1]?.isValid()) {
            handleDateChange(1, null);
            triggerInvalidDateToast(
                showCustomTimePicker ? "Invalid Date Time" : "Invalid Date"
            );
            return;
        }
        handleRangeChange([
            dateRange[0]?.toDate() ?? null,
            dateRange[1]?.toDate() ?? null,
        ]);
    }, [dateRange]);

    return (
        <>
            <Box className="flex flex-col" sx={{ gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={
                            showCustomTimePicker
                                ? "From Date & Time"
                                : "From Date"
                        }
                        disableFuture
                        value={dateRange[0]}
                        onChange={(newValue) => handleDateChange(0, newValue)}
                        referenceDate={dayjs().startOf("day")}
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                            popper: {
                                placement: "left-end",
                            },
                        }}
                        views={[
                            "year",
                            "month",
                            "day",
                            ...(Boolean(showCustomTimePicker)
                                ? ([
                                      "hours",
                                      "minutes",
                                      "seconds",
                                  ] as DateOrTimeView[])
                                : []),
                        ]}
                        timeSteps={{ minutes: 1, seconds: 1 }}
                        ampm={false}
                        maxDateTime={
                            dateRange[1] ? dayjs(dateRange[1]) : undefined
                        }
                    />
                    <DateTimePicker
                        label={
                            showCustomTimePicker ? "To Date & Time" : "To Date"
                        }
                        disableFuture
                        value={dateRange[1]}
                        onChange={(newValue) => handleDateChange(1, newValue)}
                        referenceDate={now}
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                            popper: {
                                placement: "left-end",
                            },
                        }}
                        views={[
                            "year",
                            "month",
                            "day",
                            ...(Boolean(showCustomTimePicker)
                                ? ([
                                      "hours",
                                      "minutes",
                                      "seconds",
                                  ] as DateOrTimeView[])
                                : []),
                        ]}
                        timeSteps={{ minutes: 1, seconds: 1 }}
                        ampm={false}
                        minDateTime={
                            dateRange[0] ? dayjs(dateRange[0]) : undefined
                        }
                        maxDate={showCustomTimePicker ? now : undefined}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                className="flex justify-end"
                sx={{ gap: 2, py: 2, pb: 1 }}
                ref={buttonRef}
            >
                <Typography
                    className="transition-all cursor-pointer hover:font-semibold"
                    sx={{ color: (theme) => theme.palette.textColors[333] }}
                    onClick={() => {
                        setDateRange([null, null]);
                        handleRangeChange([null, null]);
                    }}
                >
                    Clear all
                </Typography>
                <Typography
                    className="transition-all cursor-pointer hover:font-semibold"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    onClick={handleOnApply}
                >
                    Apply
                </Typography>
            </Box>
        </>
    );
});
