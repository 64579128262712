import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { AuthUserType } from "@convin/type/User";

export const personApiSlice = createApi({
    reducerPath: "personApiSlice",
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getAuthUser: builder.query<AuthUserType, unknown>({
            query: () => "/person/person/me/",
        }),

        generateSalesAssistToken: builder.query<
            {
                token: string;
                user_id: number;
            },
            void
        >({
            query: () => "/live_video/saleassist_token/",
        }),
        changeCurrentPassword: builder.mutation<void, FormData>({
            query: (data) => ({
                url: "/person/changepassword/",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useLazyGetAuthUserQuery,
    useGenerateSalesAssistTokenQuery,
    useChangeCurrentPasswordMutation,
} = personApiSlice;
