import {
    Box,
    IconButton,
    Popover,
    Stack,
    Tooltip,
    Typography,
    alpha,
    capitalize,
    styled,
} from "@mui/material";
import { Label } from "@convin/components/custom_components/Typography/Label";
import { CloseSvg } from "@convin/components/svg";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import { IFilterVisibility } from "@convin/contexts/FiltersVisibilityContextProvider";
import { pxToRem } from "@convin/utils/getFontValue";
import { useCallback, useState } from "react";
import VirtualGrid from "@convin/components/custom_components/VirtualGrid";

export const StyledFilterBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    cursor: "pointer",
    height: 34,
    borderRadius: "100px",
    color: "white",
    padding: theme.spacing(1.6),
    background: theme.palette.primary.main,
    maxWidth: 231,
}));

const AppliedFilterChip: React.FC<{
    data: IFilterVisibility["appliedFilters"][0];
    index: number;
}> = ({ data, index }) => {
    const { label, value, onRemove } = data;
    return (
        <StyledFilterBox key={label ?? "" + index} my={0.5}>
            <Stack
                className="flex-1 overflow-hidden items-center"
                direction="row"
                sx={{
                    "*": {
                        color: "white",
                    },
                }}
                gap={0.5}
            >
                <Tooltip
                    title={`${label} : ${capitalize(
                        value?.toString().toLowerCase() ?? ""
                    )}`}
                >
                    <Box
                        className="flex items-center flex-1 overflow-hidden"
                        gap={0.5}
                    >
                        <Label
                            colorType="white"
                            variant="small"
                            className="capitalize mt-[1px]"
                            isEllipses
                        >
                            {label + " :"}
                        </Label>

                        <Label
                            variant="medium"
                            className="font-medium capitalize flex-1 min-w-[1rem]"
                            colorType="white"
                            isEllipses
                        >
                            {value?.toString().toLowerCase()}
                        </Label>
                    </Box>
                </Tooltip>

                <IconButton className="flex-shrink-0 p-1" onClick={onRemove}>
                    <CloseSvg
                        sx={{
                            color: "white",
                            width: "12px",
                            height: "12px",
                        }}
                    />
                </IconButton>
            </Stack>
        </StyledFilterBox>
    );
};

export default function AppliedFilters() {
    const { appliedFilters } = useFiltersVisibilityContext();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    if (!appliedFilters.length) {
        return <></>;
    }

    const open = Boolean(anchorEl);
    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                classes={{
                    paper: [
                        "add__menu--dropdown--paper mt-[8px] bg-transparent",
                        appliedFilters.length > 2 && "pl-[54px]",
                    ]
                        .filter(Boolean)
                        .join(" "),
                }}
                sx={{
                    ".MuiPaper-root": {
                        background: "transparent !important",
                    },
                }}
            >
                <Box
                    className="flex flex-col"
                    p={2}
                    sx={{
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: "inherit",
                        bgcolor: "background.paper",
                        ...(appliedFilters.length > 20
                            ? {
                                  height: "200px",
                                  width: "1350px",
                              }
                            : {
                                  maxHeight: "150px",
                              }),
                    }}
                >
                    <Box className="flex items-center justify-between">
                        <Typography className="font-medium flex-shrink-0">
                            Applied Filters
                        </Typography>
                        <IconButton
                            className="flex-shrink-0"
                            onClick={handleClose}
                        >
                            <CloseSvg
                                sx={{
                                    color: "textColors.333",
                                }}
                            />
                        </IconButton>
                    </Box>

                    <Box className="flex flex-wrap gap-[8px] flex-1 overflow-y-auto">
                        {appliedFilters.length <= 20 ? (
                            appliedFilters.map((e, idx) => (
                                <AppliedFilterChip
                                    key={idx}
                                    data={e}
                                    index={idx}
                                />
                            ))
                        ) : (
                            <VirtualGrid<(typeof appliedFilters)[0]>
                                data={{ all: appliedFilters }}
                                Component={AppliedFilterChip}
                                rowSize={50}
                                columnCount={6}
                            />
                        )}
                    </Box>
                </Box>
            </Popover>
            <Box
                className="flex justify-center items-center transition-all cursor-pointer"
                sx={(theme) => ({
                    borderRadius: "100px",
                    minWidth: "50px",
                    height: theme.spacing(4.5),
                    border: "1px solid",
                    borderColor: alpha(
                        open
                            ? theme.palette.primary.main
                            : theme.palette.textColors["333"],
                        0.2
                    ),
                    ...(open && {
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                    }),
                    fontSize: pxToRem(14),
                    transition: "all 0.3s ease-in",
                    "&:hover": {
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                        color: theme.palette.primary.main,
                        borderColor: alpha(theme.palette.primary.main, 0.2),
                    },
                })}
                component={"button"}
                onClick={handleClick}
            >
                <Typography variant="small" className="font-medium">
                    + {appliedFilters.length}
                </Typography>
            </Box>
        </>
    );
}
