import {
    initialConversationListFilterState,
    ratingQuestionValueReverseMap,
} from "@convin/config/conversation.config";
import { searchDataFields } from "@convin/config/searchData.config";
import { MeetingTypeConst, NumericRange } from "@convin/type/Common";
import {
    AccountListFilters,
    AuditType,
    ConversationListFilters,
    TemplateCategoryFilter,
    TemplateQuestionFilter,
} from "@convin/type/Filters.model";
import { EncodedFilters, SearchFilter } from "@convin/type/Search";

import { datekeys, defaultConfig } from "@convin/config/default.config";
import { GlobalFiltersStateType } from "@convin/contexts/GlobalFiltersStateContextProvider";
import {
    CSATAnalysis,
    CollectionAnalysis,
    ConversationSearchDecodedFilters,
    ConversationSkills,
    CustomDataFilterType,
    LeadAnalysis,
    SearchKeywords,
} from "@convin/type/Conversation";
import { isDefined, objectKeyArrayMap, objectKeyMap } from "./common.helper";
import { format } from "date-fns";
import { AccountSearchDecodeFilters } from "@convin/type/Account";
import { isValidDateKeyExpr, convertDaysToEpoch } from "./date.helper";

const decodeDateFilter = <
    T extends ConversationSearchDecodedFilters | AccountSearchDecodeFilters
>({
    fileldName,
    encodedFilters,
    dateStateKey,
    dateFields,
}: {
    fileldName: searchDataFields;
    encodedFilters: EncodedFilters;
    dateStateKey: Extract<
        keyof T,
        "dateKey" | "auditDateKey" | "joiningDateKey"
    >;
    dateFields: Array<
        Extract<
            keyof T,
            | "startDate"
            | "endDate"
            | "auditStartDate"
            | "auditEndDate"
            | "endJoinTime"
            | "startJoinTime"
        >
    >;
}): Partial<T> => {
    const decodedData: Partial<T> = {};

    if (encodedFilters[fileldName]) {
        const dateKey = encodedFilters[fileldName]?.search_attributes.expr;
        if (isDefined(dateKey)) {
            decodedData[dateStateKey] = dateKey;
        } else {
            decodedData[dateFields[0]] =
                (encodedFilters[fileldName]?.search_attributes.gte as string) ||
                null;
            decodedData[dateFields[1]] =
                (encodedFilters[fileldName]?.search_attributes.lte as string) ||
                null;
        }
    } else {
        decodedData[dateFields[0]] = null;
        decodedData[dateFields[1]] = null;
    }

    return decodedData;
};

export const decodeTopbarFilters = (
    encodedFilters: EncodedFilters
): Partial<ConversationSearchDecodedFilters> => {
    const decodedData = {} as Partial<ConversationSearchDecodedFilters>;
    //Meeting Type
    if (encodedFilters[searchDataFields.meeting_type]) {
        decodedData.meetingType = encodedFilters[searchDataFields.meeting_type]
            ?.search_attributes?.exact as NonNullable<MeetingTypeConst>;
    }

    //Reps
    if (encodedFilters[searchDataFields.owner__id]) {
        decodedData.reps = encodedFilters[searchDataFields.owner__id]
            .search_attributes.exact
            ? ([
                  encodedFilters[searchDataFields.owner__id].search_attributes
                      .exact,
              ] as number[])
            : encodedFilters[searchDataFields.owner__id].search_attributes.in
            ? (encodedFilters[searchDataFields.owner__id].search_attributes
                  .in as number[])
            : [];
    } else {
        decodedData.reps = [];
    }

    //Teams
    if (
        encodedFilters[searchDataFields.owner__team__id]?.search_attributes.in
    ) {
        decodedData.teams = encodedFilters[searchDataFields.owner__team__id]
            .search_attributes.in as number[];
    } else {
        decodedData.teams = [];
    }

    //Duration
    if (encodedFilters[searchDataFields.stats__duration]) {
        decodedData.minDuration = encodedFilters[
            searchDataFields.stats__duration
        ].search_attributes.gte
            ? (encodedFilters[searchDataFields.stats__duration]
                  .search_attributes.gte as number) / 60
            : null;
        decodedData.maxDuration = encodedFilters[
            searchDataFields.stats__duration
        ].search_attributes.lte
            ? (encodedFilters[searchDataFields.stats__duration]
                  .search_attributes.lte as number) / 60
            : null;
    }

    return {
        ...decodedData,
        ...decodeDateFilter({
            fileldName: searchDataFields.start_time,
            encodedFilters,
            dateStateKey: "dateKey",
            dateFields: ["startDate", "endDate"],
        }),
    };
};

export const decodeConversationScoreFilter = (
    encodedFilters: EncodedFilters
): NumericRange => {
    if (encodedFilters[searchDataFields.meeting_score__percent]) {
        return [
            encodedFilters[searchDataFields.meeting_score__percent]
                ?.search_attributes?.gte
                ? (encodedFilters[searchDataFields.meeting_score__percent]
                      ?.search_attributes?.gte as number) * 100
                : 0,
            encodedFilters[searchDataFields.meeting_score__percent]
                ?.search_attributes?.lte
                ? (encodedFilters[searchDataFields.meeting_score__percent]
                      ?.search_attributes?.lte as number) * 100
                : encodedFilters[searchDataFields.meeting_score__percent]
                      ?.search_attributes?.lt
                ? (encodedFilters[searchDataFields.meeting_score__percent]
                      ?.search_attributes?.lt as number) * 100
                : 100,
        ];
    }
    return [null, null];
};

export const decodeMeetingScoreTemplateFilter = (
    encodedFilters: EncodedFilters
) => {
    return encodedFilters[searchDataFields.meeting_score__template__id]
        ? (encodedFilters[searchDataFields.meeting_score__template__id]
              .search_attributes.exact as number)
        : null;
};

export const decodeTemplateScoreFilter = (filters: SearchFilter[]) => {
    const decodedData = {
        templateCategories: {} as TemplateCategoryFilter,
        templateQuestions: {} as TemplateQuestionFilter,
    };

    for (let i = 0; i < filters.length; i++) {
        if (
            filters[i].name === searchDataFields.category_score__category__id &&
            isDefined(filters[i].search_attributes.exact)
        ) {
            decodedData.templateCategories[
                filters[i].search_attributes.exact as number
            ] = [
                (filters[i + 1].search_attributes?.gte as number)
                    ? (filters[i + 1].search_attributes?.gte as number) * 100
                    : null,
                (filters[i + 1].search_attributes?.lte as number)
                    ? (filters[i + 1].search_attributes?.lte as number) * 100
                    : null,
            ];
        }

        if (
            filters[i].name === searchDataFields.score__question__id &&
            isDefined(filters[i].search_attributes.exact)
        ) {
            decodedData.templateQuestions[
                filters[i].search_attributes.exact as number
            ] =
                (filters[i + 1].search_attributes?.exact as number) ??
                ratingQuestionValueReverseMap(
                    filters[i + 1].search_attributes as Partial<{
                        lt: number;
                        gte: number;
                    }>
                );
        }
    }

    return decodedData;
};

export const decodeAuditTypeAndAuditorsFilter = (
    encodedFilters: EncodedFilters
) => {
    const decodedData = {} as {
        auditType: AuditType | null;
        auditors: number[];
        auditStartDate: null | string;
        auditEndDate: null | string;
    };

    if (
        encodedFilters[searchDataFields.meeting_score__auditor__id]
            ?.search_attributes?.isnull === false ||
        encodedFilters[searchDataFields.meeting_score__auditor__id]
            ?.search_attributes?.in
    ) {
        decodedData.auditType = AuditType.manual;
        decodedData.auditors =
            encodedFilters[searchDataFields.meeting_score__auditor__id]
                .search_attributes.isnull === false
                ? [0]
                : (encodedFilters[searchDataFields.meeting_score__auditor__id]
                      .search_attributes.in as number[]) || [];
    } else {
        if (
            encodedFilters?.[searchDataFields?.score__is_ai_rated]
                ?.search_attributes?.exact
        ) {
            decodedData.auditType = AuditType.ai;
            decodedData.auditors = [];
        } else if (
            encodedFilters?.[searchDataFields?.score__is_ai_rated]
                ?.search_attributes &&
            encodedFilters?.[searchDataFields?.score__is_ai_rated]
                ?.search_attributes?.exact === false
        ) {
            decodedData.auditType = AuditType.manual;
            decodedData.auditors = [];
        } else {
            decodedData.auditType = null;
            decodedData.auditors = [];
        }
    }

    // if (encodedFilters[searchDataFields?.meeting_score__last_audit_time]) {
    //     decodedData.auditStartDate =
    //         (encodedFilters[searchDataFields?.meeting_score__last_audit_time]
    //             ?.search_attributes.gte as string) || null;
    //     decodedData.auditEndDate =
    //         (encodedFilters[searchDataFields?.meeting_score__last_audit_time]
    //             ?.search_attributes.lte as string) || null;
    // }
    return {
        ...decodedData,
        ...decodeDateFilter({
            fileldName: searchDataFields.meeting_score__last_audit_time,
            encodedFilters,
            dateStateKey: "auditDateKey",
            dateFields: ["auditStartDate", "auditEndDate"],
        }),
    };
};

export const decodeClientFilter = (encodedFilters: EncodedFilters) => {
    return encodedFilters[searchDataFields.sales_task__id]
        ? (encodedFilters[searchDataFields.sales_task__id].search_attributes
              .exact as number)
        : null;
};

export const decodeKeywordsFilter = (encodedFilters: EncodedFilters) => {
    let decodedData = {} as Partial<SearchKeywords>;

    const speakerData = encodedFilters[searchDataFields?.speaker_type];
    decodedData = {
        saidByClient: speakerData
            ? speakerData.search_attributes.iregex === "client|participant"
            : false,
        saidByOwner: speakerData
            ? speakerData.search_attributes.iregex === "owner"
            : false,
    };

    //SubFields - Case 1: Unknown Case
    if (
        encodedFilters[searchDataFields.sentence_category_transcript_ts_vector]
    ) {
        const topicData =
            encodedFilters[
                searchDataFields.sentence_category_transcript_ts_vector
            ];
        decodedData.keywords = (
            topicData.search_attributes.exact as string
        ).split(" ");
        decodedData.isInCall = !topicData.search_attributes.negate;
    }
    //SubFields - Case 2: Both Rep and Others selected
    else if (encodedFilters[searchDataFields.combined_ts_vector]) {
        decodedData.keywords = (
            encodedFilters[searchDataFields.combined_ts_vector]
                .search_attributes.exact as string
        ).split(" ");
        decodedData.isInCall =
            !encodedFilters[searchDataFields.combined_ts_vector]
                .search_attributes.negate;
    }
    //SubFields - Case 3: Either Rep or Others selected
    else if (encodedFilters[searchDataFields.speaker_transcript_ts_vector]) {
        decodedData.keywords = (
            encodedFilters[searchDataFields.speaker_transcript_ts_vector]
                .search_attributes.exact as string
        ).split(" ");
        decodedData.isInCall =
            !encodedFilters[searchDataFields.speaker_transcript_ts_vector]
                .search_attributes.negate;
    } else {
        decodedData.isInCall = true;
        decodedData.keywords = [];
    }

    return decodedData;
};

export const decodeCallTagsFilter = (
    encodedFilters: EncodedFilters
): GlobalFiltersStateType["tags"] | undefined => {
    if (encodedFilters[searchDataFields.tags__id]) {
        return {
            tagIds: encodedFilters[searchDataFields.tags__id].search_attributes
                .in as number[],
            logic: encodedFilters[searchDataFields.tags__id].search_attributes
                .and
                ? "and"
                : "or",
            negate:
                encodedFilters[searchDataFields.tags__id].search_attributes
                    .negate ?? false,
        };
    }

    return;
};

export const decodeCallTypesFilter = (
    encodedFilters: EncodedFilters
): GlobalFiltersStateType["callTypes"] | undefined => {
    if (encodedFilters[searchDataFields.call_types__id]) {
        return {
            typeIds: encodedFilters[searchDataFields.call_types__id]
                .search_attributes.exact
                ? ([
                      encodedFilters[searchDataFields.call_types__id]
                          .search_attributes.exact,
                  ] as number[])
                : (encodedFilters[searchDataFields.call_types__id]
                      .search_attributes.in as number[]),
            logic: encodedFilters[searchDataFields.call_types__id]
                .search_attributes.and
                ? "and"
                : "or",
            negate:
                encodedFilters[searchDataFields.call_types__id]
                    .search_attributes.negate ?? false,
        };
    }

    return;
};

export const decodeQuestionCountFilter = (
    encodedFilters: EncodedFilters
): ConversationListFilters["questions"] => {
    return {
        byClient: [
            encodedFilters[searchDataFields.stats__client_question_count]
                ?.search_attributes?.gte
                ? (encodedFilters[searchDataFields.stats__client_question_count]
                      .search_attributes.gte as number)
                : 0,
            encodedFilters[searchDataFields.stats__client_question_count]
                ?.search_attributes?.lte
                ? (encodedFilters[searchDataFields.stats__client_question_count]
                      .search_attributes.lte as number)
                : 100,
        ],
        byOwner: [
            encodedFilters[searchDataFields.stats__owner_question_count]
                ?.search_attributes?.gte
                ? (encodedFilters[searchDataFields.stats__owner_question_count]
                      .search_attributes.gte as number)
                : 0,
            encodedFilters[searchDataFields.stats__owner_question_count]
                ?.search_attributes?.lte
                ? (encodedFilters[searchDataFields.stats__owner_question_count]
                      .search_attributes.lte as number)
                : 100,
        ],
    };
};

export const decodeTopicsFilter = (encodedFilters: EncodedFilters) => {
    if (encodedFilters[searchDataFields?.topics__id]) {
        return {
            id: encodedFilters[searchDataFields.topics__id].search_attributes
                .exact as number,
            inCall: !encodedFilters[searchDataFields.topics__id]
                .search_attributes.negate,
        };
    }
    return {
        id: null,
        inCall: true,
    };
};

export const decodeConversationSkillsFilter = (
    encodedFilters: EncodedFilters
): ConversationSkills => {
    return {
        ownerTalkRatio: [
            isDefined(
                encodedFilters[searchDataFields.stats__owner_talk_ratio]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__owner_talk_ratio]
                      .search_attributes.gte as number) * 100
                : 0,
            isDefined(
                encodedFilters[searchDataFields.stats__owner_talk_ratio]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__owner_talk_ratio]
                      .search_attributes.lte as number) * 100
                : 100,
        ],
        clientTalkRatio: [
            isDefined(
                encodedFilters[searchDataFields.stats__client_talk_ratio]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__client_talk_ratio]
                      .search_attributes.gte as number) * 100
                : 0,
            isDefined(
                encodedFilters[searchDataFields.stats__client_talk_ratio]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__client_talk_ratio]
                      .search_attributes.lte as number) * 100
                : 100,
        ],
        ownerLongestMonologue: [
            isDefined(
                encodedFilters[searchDataFields.stats__longest_monologue_owner]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[
                      searchDataFields.stats__longest_monologue_owner
                  ].search_attributes.gte as number) / 60
                : 0,
            isDefined(
                encodedFilters[searchDataFields.stats__longest_monologue_owner]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[
                      searchDataFields.stats__longest_monologue_owner
                  ].search_attributes.lte as number) / 60
                : 100,
        ],
        clientLongestMonologue: [
            isDefined(
                encodedFilters[searchDataFields.stats__longest_monologue_client]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[
                      searchDataFields.stats__longest_monologue_client
                  ].search_attributes.gte as number) / 60
                : 0,
            isDefined(
                encodedFilters[searchDataFields.stats__longest_monologue_client]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[
                      searchDataFields.stats__longest_monologue_client
                  ].search_attributes.lte as number) / 60
                : 100,
        ],
        interactivity: [
            isDefined(
                encodedFilters[searchDataFields.stats__interactivity]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__interactivity]
                      .search_attributes.gte as number)
                : -10,
            isDefined(
                encodedFilters[searchDataFields.stats__interactivity]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__interactivity]
                      .search_attributes.lte as number)
                : 10,
        ],
        patience: [
            isDefined(
                encodedFilters[searchDataFields.stats__patience]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__patience]
                      .search_attributes.gte as number)
                : 0,
            isDefined(
                encodedFilters[searchDataFields.stats__patience]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__patience]
                      .search_attributes.lte as number)
                : 100,
        ],
        talktime: [
            isDefined(
                encodedFilters[searchDataFields.stats__talk_time]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__talk_time]
                      .search_attributes.gte as number)
                : null,
            isDefined(
                encodedFilters[searchDataFields.stats__talk_time]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__talk_time]
                      .search_attributes.lte as number)
                : null,
        ],
        interruptionCount: [
            isDefined(
                encodedFilters[searchDataFields.stats__owner_overlap_rate]
                    ?.search_attributes?.gte
            )
                ? (encodedFilters[searchDataFields.stats__owner_overlap_rate]
                      .search_attributes.gte as number)
                : null,
            isDefined(
                encodedFilters[searchDataFields.stats__owner_overlap_rate]
                    ?.search_attributes?.lte
            )
                ? (encodedFilters[searchDataFields.stats__owner_overlap_rate]
                      .search_attributes.lte as number)
                : null,
        ],
    };
};

export const decodeRecordingMediumFilter = (encodedFilters: EncodedFilters) => {
    return encodedFilters[searchDataFields.conference_tool]
        ? (encodedFilters[searchDataFields.conference_tool].search_attributes
              .exact as string)
        : null;
};

export const decodeStageFilter = (encodedFilters: EncodedFilters) => {
    return encodedFilters[searchDataFields.sales_stage__id]
        ? (encodedFilters[searchDataFields.sales_stage__id].search_attributes
              .exact as number)
        : null;
};

export const decodeAttributeFilter = (
    encodedFilters: EncodedFilters
): ConversationSearchDecodedFilters["attribute"] | null => {
    if (
        isDefined(
            encodedFilters[searchDataFields.metadata__key__metadata_type__id]
        )
    )
        return {
            category:
                (encodedFilters[
                    searchDataFields.metadata__key__metadata_type__id
                ].search_attributes.exact as number | undefined) ?? null,
            type:
                (encodedFilters[searchDataFields.metadata__key__id]
                    ?.search_attributes?.exact as number | undefined) ?? null,
            value:
                (
                    encodedFilters[searchDataFields.metadata__value]
                        ?.search_attributes?.exact as
                        | Record<string, string>
                        | undefined
                )?.value ?? null,
        };
    return null;
};

export const decodeProcessingStatusFilter = (
    encodedFilters: EncodedFilters
) => {
    return encodedFilters[searchDataFields.processing_status]
        ? (encodedFilters[searchDataFields.processing_status].search_attributes
              .exact as string)
        : null;
};

export const decodeRepJoiningFilter = (encodedFilters: EncodedFilters) => {
    const decodedData = {} as {
        startJoinTime: string | null;
        endJoinTime: string | null;
    };

    if (encodedFilters[searchDataFields.owner__org_date_joined]) {
        decodedData.startJoinTime =
            (encodedFilters[searchDataFields?.owner__org_date_joined]
                ?.search_attributes.gte as string) || null;
        decodedData.endJoinTime =
            (encodedFilters[searchDataFields?.owner__org_date_joined]
                ?.search_attributes.lte as string) || null;
    } else {
        decodedData.startJoinTime = null;
        decodedData.endJoinTime = null;
    }

    return;
};

export const decodeScoreSenseFilter = (
    filters: SearchFilter[]
): ConversationSearchDecodedFilters["scoresense"] => {
    const filter: ConversationSearchDecodedFilters["scoresense"] = {
        lead: null,
        csat: null,
        collection: null,
    };

    const encodedFilters = objectKeyArrayMap(filters);
    if (
        isDefined(
            encodedFilters[
                searchDataFields.sales_task__account_stat__lead_classification
            ]?.[0]
        )
    ) {
        const value =
            encodedFilters[
                searchDataFields.sales_task__account_stat__lead_classification
            ][0].search_attributes.exact;
        if (["hot", "warm", "cold"].includes(String(value ?? "")))
            filter.lead = value as LeadAnalysis["classification"];
    }

    const scoreSenseType =
        encodedFilters[searchDataFields.gptanalysisresult__score_type] ?? [];
    const scoreSenseValue =
        encodedFilters[searchDataFields.gptanalysisresult__classification] ??
        [];

    const csatIndex = scoreSenseType.findIndex(
        (e) => e.search_attributes.exact === "CSAT"
    );
    const collectionIndex = scoreSenseType.findIndex(
        (e) => e.search_attributes.exact === "Collection"
    );
    if (csatIndex > -1)
        filter.csat = scoreSenseValue[csatIndex].search_attributes
            .exact as CSATAnalysis["classification"];
    if (collectionIndex > -1)
        filter.collection = scoreSenseValue[collectionIndex].search_attributes
            .exact as CollectionAnalysis["classification"];

    return filter;
};

export const decodeAuditStateFilter = (
    encodedFilters: EncodedFilters
): Partial<
    Pick<
        ConversationSearchDecodedFilters,
        "auditFeedbackStatus" | "auditType" | "auditors" | "isDraftAudit"
    >
> => {
    const auditKey = searchDataFields.meeting_score__auditor__id;
    const auditType =
        encodedFilters[auditKey]?.search_attributes?.isnull === false
            ? 0
            : encodedFilters[auditKey]?.search_attributes?.isnull ||
              encodedFilters[auditKey]?.search_attributes.in?.length
            ? 1
            : null;
    const isNotAcknowledgeStatus =
        encodedFilters[searchDataFields.meeting_score__status]
            ?.search_attributes?.isnull;
    const auditFeedbackStatus =
        encodedFilters[searchDataFields.meeting_score__status]
            ?.search_attributes?.exact;
    const auditors: number[] =
        (encodedFilters[auditKey]?.search_attributes.in as
            | number[]
            | undefined) ?? [];
    return {
        auditType,
        auditors,
        isDraftAudit: isDefined(
            encodedFilters[searchDataFields.score__is_in_draft]
                ?.search_attributes?.exact
        ),
        auditFeedbackStatus: isNotAcknowledgeStatus
            ? "not_acknowledge"
            : (auditFeedbackStatus as ConversationSearchDecodedFilters["auditFeedbackStatus"]) ??
              null,
    };
};

export const decodeAiDispositionFilter = (
    filters: SearchFilter[]
): Pick<ConversationSearchDecodedFilters, "dispositionTemplate"> => {
    const encodedFilters = objectKeyMap(filters);
    return {
        dispositionTemplate: {
            template:
                (encodedFilters[
                    "ai_disposition_results__disposition__ai_disposition_template__id"
                ]?.search_attributes.exact as number) ?? null,
            level1:
                (objectKeyMap(
                    filters.filter(
                        (e) =>
                            e.name === "ai_disposition_results__result" &&
                            isDefined(e.search_attributes.level_1)
                    )
                )["ai_disposition_results__result"]?.search_attributes
                    ?.level_1 as number) ?? null,
            level2:
                (objectKeyMap(
                    filters.filter(
                        (e) =>
                            e.name === "ai_disposition_results__result" &&
                            isDefined(e.search_attributes.level_2)
                    )
                )["ai_disposition_results__result"]?.search_attributes
                    ?.level_2 as number) ?? null,
            level3:
                (encodedFilters["ai_disposition_results__disposition__id"]
                    ?.search_attributes.exact as number) ?? null,
        },
    };
};

export const decodeConversationListFilters = (
    filters: SearchFilter[]
): Partial<ConversationSearchDecodedFilters> => {
    const encodedFilters = objectKeyMap(filters);
    const {
        questions,
        topics,
        conversationSkills,
        recordingMedium,
        stage,
        scoresense,
        attribute,
        callTypes,
        tags,
    } = initialConversationListFilterState;
    const decodedData = {
        ...decodeTopbarFilters(encodedFilters),
        template: decodeMeetingScoreTemplateFilter(encodedFilters),
        analyticsTemplate: decodeMeetingScoreTemplateFilter(encodedFilters),
        conversationScore: decodeConversationScoreFilter(encodedFilters),
        ...decodeTemplateScoreFilter(filters),
        ...decodeAuditTypeAndAuditorsFilter(encodedFilters),
        client: decodeClientFilter(encodedFilters),
        searchKeywords: decodeKeywordsFilter(encodedFilters),
        callTypes: decodeCallTypesFilter(encodedFilters) ?? callTypes,
        tags: decodeCallTagsFilter(encodedFilters) ?? tags,
        questions: decodeQuestionCountFilter(encodedFilters) ?? questions,
        topics: decodeTopicsFilter(encodedFilters) ?? topics,
        conversationSkills:
            decodeConversationSkillsFilter(encodedFilters) ??
            conversationSkills,
        recordingMedium:
            decodeRecordingMediumFilter(encodedFilters) ?? recordingMedium,
        stage: decodeStageFilter(encodedFilters) ?? stage,
        processingStatus: decodeProcessingStatusFilter(encodedFilters),
        scoresense: decodeScoreSenseFilter(filters) ?? scoresense,
        ...decodeAuditStateFilter(encodedFilters),
        attribute: decodeAttributeFilter(encodedFilters) ?? attribute,
        ...decodeAiDispositionFilter(filters),
    } as Partial<ConversationSearchDecodedFilters>;

    //TODO:  Draft Status, Audit Feedback Status

    return decodedData;
};

export const decodeAccountTopBarFilter = (encodedFilters: EncodedFilters) => {
    const decodedData = {} as Partial<ConversationSearchDecodedFilters>;

    //Account Reps
    if (encodedFilters[searchDataFields.sales_task__owner__id]) {
        decodedData.accountReps = encodedFilters[
            searchDataFields.sales_task__owner__id
        ].search_attributes.exact
            ? ([
                  encodedFilters[searchDataFields.sales_task__owner__id]
                      .search_attributes.exact,
              ] as number[])
            : encodedFilters[searchDataFields.sales_task__owner__id]
                  .search_attributes.in
            ? (encodedFilters[searchDataFields.sales_task__owner__id]
                  .search_attributes.in as number[])
            : [];
    } else {
        decodedData.accountReps = [];
    }

    //Account Teams
    if (encodedFilters[searchDataFields.sales_task__owner__team__id]) {
        decodedData.accountTeams = encodedFilters[
            searchDataFields.sales_task__owner__team__id
        ].search_attributes.in as number[];
    } else {
        decodedData.accountTeams = [];
    }

    //reps
    if (encodedFilters[searchDataFields.sales_task__account_score__owner__id]) {
        decodedData.reps = encodedFilters[
            searchDataFields.sales_task__account_score__owner__id
        ].search_attributes.exact
            ? ([
                  encodedFilters[
                      searchDataFields.sales_task__account_score__owner__id
                  ].search_attributes.exact,
              ] as number[])
            : encodedFilters[
                  searchDataFields.sales_task__account_score__owner__id
              ].search_attributes.in
            ? (encodedFilters[
                  searchDataFields.sales_task__account_score__owner__id
              ].search_attributes.in as number[])
            : [];
    } else {
        decodedData.reps = [];
    }

    //Teams
    if (
        encodedFilters[
            searchDataFields.sales_task__account_score__owner__team__id
        ]
    ) {
        decodedData.teams = encodedFilters[
            searchDataFields.sales_task__account_score__owner__team__id
        ].search_attributes.in as number[];
    } else {
        decodedData.teams = [];
    }

    //Duration
    if (encodedFilters[searchDataFields.duration]) {
        decodedData.minDuration = encodedFilters[searchDataFields.duration]
            .search_attributes.gte
            ? (encodedFilters[searchDataFields.duration].search_attributes
                  .gte as number) / 60
            : null;
        decodedData.maxDuration = encodedFilters[searchDataFields.duration]
            .search_attributes.lte
            ? (encodedFilters[searchDataFields.duration].search_attributes
                  .lte as number) / 60
            : null;
    }

    return {
        ...decodedData,
        ...decodeDateFilter({
            fileldName: searchDataFields.sales_task__created,
            encodedFilters,
            dateStateKey: "dateKey",
            dateFields: ["startDate", "endDate"],
        }),
    };
};

export const decodedAccountDealSizeFilter = (
    encodedFilters: EncodedFilters
): AccountListFilters["dealSize"] => {
    if (encodedFilters[searchDataFields.account_size])
        return {
            min: encodedFilters[searchDataFields.account_size].search_attributes
                .gte
                ? (encodedFilters[searchDataFields.account_size]
                      .search_attributes.gte as number)
                : null,
            max: encodedFilters[searchDataFields.account_size].search_attributes
                .lte
                ? (encodedFilters[searchDataFields.account_size]
                      .search_attributes.lte as number)
                : null,
        };

    return { min: null, max: null };
};

export const decodedMinMaxInteractionsFilter = (
    encodedFilters: EncodedFilters
): NumericRange => {
    if (encodedFilters[searchDataFields.interaction_count]) {
        return [
            encodedFilters[searchDataFields.interaction_count]
                ?.search_attributes?.gte
                ? (encodedFilters[searchDataFields.interaction_count]
                      ?.search_attributes?.gte as number)
                : null,
            encodedFilters[searchDataFields.interaction_count]
                ?.search_attributes?.lte
                ? (encodedFilters[searchDataFields.interaction_count]
                      ?.search_attributes?.lte as number)
                : null,
        ];
    }
    return [null, null];
};

export const decodedAccountTagsFilter = (encodedFilters: EncodedFilters) => {
    if (encodedFilters[searchDataFields.sales_task__tags__id]) {
        return {
            tagIds: encodedFilters[searchDataFields.sales_task__tags__id]
                .search_attributes.in as number[],
        };
    }

    return { tagIds: [] };
};

// export const decodedAccountLastContactedFilter = (
//     encodedFilters: EncodedFilters
// ): AccountSearchDecodeFilters["lastContactedDateKey"] => {
//     if (encodedFilters[searchDataFields.last_connected_date]) {
//         return [
//             (encodedFilters[searchDataFields?.last_connected_date]
//                 ?.search_attributes.gte as string) || null,
//             (encodedFilters[searchDataFields?.last_connected_date]
//                 ?.search_attributes.lte as string) || null,
//         ];
//     }
//     return [null, null];
// };

export const decodedAccountStageFilter = (encodedFilters: EncodedFilters) => {
    if (encodedFilters[searchDataFields.stage__id])
        return encodedFilters[searchDataFields.stage__id].search_attributes
            .in?.[0] as number;
    return null;
};

export const decodedAccountListFilters = (filters: SearchFilter[]) => {
    const encodedFilters = objectKeyMap(filters);
    const decodedData = {
        ...decodeAccountTopBarFilter(encodedFilters),
        dealSize: decodedAccountDealSizeFilter(encodedFilters),
        minMaxInteractions: decodedMinMaxInteractionsFilter(encodedFilters),
        accountTags: decodedAccountTagsFilter(encodedFilters),
        // lastContactedDateKey: decodedAccountLastContactedFilter(encodedFilters),
        stage: decodedAccountStageFilter(encodedFilters),
    };
    return decodedData;
};

export const decodeSamplingFilters = (filters: SearchFilter[]) => {
    const encodedFilters = objectKeyMap(filters);
    const decodedData = {
        ...decodeConversationListFilters(filters),
        ...decodeDateFilter({
            fileldName: searchDataFields.owner__org_date_joined,
            encodedFilters,
            dateStateKey: "joiningDateKey",
            dateFields: ["startJoinTime", "endJoinTime"],
        }),
    } as Partial<ConversationSearchDecodedFilters>;

    return decodedData;
};

export const decodeSamplingAccountFilters = (filters: SearchFilter[]) => {
    const decodedData = {
        ...decodedAccountListFilters(filters),
    } as Partial<AccountSearchDecodeFilters>;
    return decodedData;
};

export const getSearchFiltersCustomData = (
    state: GlobalFiltersStateType
): CustomDataFilterType => {
    return {
        name: "custom_data",
        ...(state.auditType === 1 && {
            auditDateKey: state.auditDateKey,
        }),
        durationKey: state.durationKey,
        dateKey: state.dateKey,
        minMaxInteractions: state.minMaxInteractions,
        agentTenure: state.agentTenure,
        convinInsights: state.convinInsights,
    };
};

export const getCustomDurationFilter = (
    min: number | null,
    max: number | null
): { name: string; value: [number | null, number | null] } => {
    const name =
        min && max
            ? `Between ${min} - ${max} min`
            : min
            ? `Above ${min} min`
            : max
            ? `Below ${max} min`
            : "Any";
    return { name, value: [min, max] };
};

export const getCustomDateFilter = (
    startDate: string | null,
    endDate: string | null
): {
    key: keyof typeof datekeys | string;
    value: [string | null, string | null];
    name: string;
} => {
    const formattedDate0 = startDate
        ? format(new Date(startDate), "MMM dd, yyyy")
        : null;
    const formattedDate1 = endDate
        ? format(new Date(endDate), "MMM dd, yyyy")
        : null;

    const name =
        formattedDate0 && formattedDate1
            ? `Between ${formattedDate0} - ${formattedDate1}`
            : formattedDate0
            ? `After ${formattedDate0}`
            : formattedDate1
            ? `Before ${formattedDate0}`
            : defaultConfig.dateConfig[datekeys.all].name;
    return {
        key: name || "all",
        value: [startDate, endDate],
        name,
    };
};

export const setDurationDateFiltersOnDecodedFilters = ({
    state,
    filters,
}: {
    state: GlobalFiltersStateType;
    filters: Partial<
        ConversationSearchDecodedFilters | AccountSearchDecodeFilters
    >;
}): GlobalFiltersStateType => {
    const {
        startDate,
        endDate,
        maxDuration,
        minDuration,
        auditEndDate,
        auditStartDate,
        startJoinTime,
        endJoinTime,
    } = filters;
    const { name: durationName, value: durationValue } =
        getCustomDurationFilter(minDuration ?? null, maxDuration ?? null);

    const {
        key,
        value: dateValue,
        name: dateName,
    } = getCustomDateFilter(startDate ?? null, endDate ?? null);

    const {
        key: joinKey,
        value: joinDateValue,
        name: joinDateName,
    } = getCustomDateFilter(startJoinTime ?? null, endJoinTime ?? null);

    const {
        key: auditKey,
        value: auditDateValue,
        name: auditDateName,
    } = getCustomDateFilter(auditStartDate ?? null, auditEndDate ?? null);

    //Check for duplicate key
    const durationKey =
        Object.keys(state.durationOptions).find(
            (e) => state.durationOptions[e].name === durationName
        ) ?? durationName;
    //Check for duplicate key
    const dateKey =
        filters.dateKey ??
        Object.keys(state.dateOptions).find(
            (e) =>
                state.dateOptions[e].name.toLowerCase() ===
                dateName.toLowerCase()
        ) ??
        key;
    //Check for duplicate key
    const joiningDateKey =
        filters.joiningDateKey ??
        Object.keys(state.joiningDateOptions).find(
            (e) =>
                state.joiningDateOptions[e].name.toLowerCase() ===
                joinDateName.toLowerCase()
        ) ??
        joinKey;
    const auditDateKey =
        filters.auditDateKey ??
        Object.keys(state.auditDateOptions).find(
            (e) =>
                state.auditDateOptions[e].name.toLowerCase() ===
                auditDateName.toLowerCase()
        ) ??
        auditKey;
    try {
        return {
            ...state,
            ...filters,
            durationKey,
            dateKey,
            dateOptions: {
                ...state.dateOptions,
                ...((!isDefined(filters.dateKey) ||
                    (isDefined(filters.dateKey) &&
                        !isValidDateKeyExpr(filters.dateKey))) && {
                    [dateKey]: {
                        name: dateName,
                        dateRange: dateValue,
                        is_rolling_date: false,
                        label: state.dateOptions[dateKey]?.label ?? "",
                    },
                }),
            },
            joiningDateKey,
            joiningDateOptions: {
                ...state.joiningDateOptions,
                ...(!isDefined(filters.joiningDateKey) && {
                    [joiningDateKey]: {
                        name: joinDateName,
                        dateRange: joinDateValue,
                        is_rolling_date: false,
                        label: state.dateOptions[joiningDateKey]?.label ?? "",
                    },
                }),
            },
            auditDateKey,
            auditDateOptions: {
                ...state.auditDateOptions,
                ...(isDefined(filters.auditDateKey) && {
                    [auditDateKey]: {
                        name: auditDateName,
                        dateRange: auditDateValue,
                        is_rolling_date: false,
                        label: state.dateOptions[auditDateKey]?.label ?? "",
                    },
                }),
            },
            durationOptions: {
                ...state.durationOptions,
                [durationKey]: { value: durationValue, name: durationName },
            },
        };
    } catch {
        return state;
    }
};

export const convertedTenureBuckets = (
    agentTenure: GlobalFiltersStateType["agentTenure"]
) => {
    if (agentTenure) {
        const fromDays = agentTenure.start;
        const toDays = agentTenure.end;

        const lte = convertDaysToEpoch(fromDays, 0).fromEpoch;

        const gte = convertDaysToEpoch(0, toDays).toEpoch;

        return {
            gte,
            lte,
        };
    }
};
