import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function CollectionSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <g clipPath="url(#clip0_19276_3622)">
                <mask
                    id="mask0_19276_3622"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                >
                    <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_19276_3622)">
                    <path
                        d="M6.66663 14.1667H8.33329V13.3334H9.16663C9.40274 13.3334 9.60065 13.2535 9.76038 13.0938C9.9201 12.9341 9.99996 12.7362 9.99996 12.5V10C9.99996 9.76393 9.9201 9.56601 9.76038 9.40629C9.60065 9.24657 9.40274 9.16671 9.16663 9.16671H6.66663V8.33337H9.99996V6.66671H8.33329V5.83337H6.66663V6.66671H5.83329C5.59718 6.66671 5.39926 6.74657 5.23954 6.90629C5.07982 7.06601 4.99996 7.26393 4.99996 7.50004V10C4.99996 10.2362 5.07982 10.4341 5.23954 10.5938C5.39926 10.7535 5.59718 10.8334 5.83329 10.8334H8.33329V11.6667H4.99996V13.3334H6.66663V14.1667ZM13.3333 13.5417L15 11.875H11.6666L13.3333 13.5417ZM11.6666 8.33337H15L13.3333 6.66671L11.6666 8.33337ZM3.33329 16.6667C2.87496 16.6667 2.4826 16.5035 2.15621 16.1771C1.82982 15.8507 1.66663 15.4584 1.66663 15V5.00004C1.66663 4.54171 1.82982 4.14935 2.15621 3.82296C2.4826 3.49657 2.87496 3.33337 3.33329 3.33337H16.6666C17.125 3.33337 17.5173 3.49657 17.8437 3.82296C18.1701 4.14935 18.3333 4.54171 18.3333 5.00004V15C18.3333 15.4584 18.1701 15.8507 17.8437 16.1771C17.5173 16.5035 17.125 16.6667 16.6666 16.6667H3.33329Z"
                        fill="currentColor"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_19276_3622">
                    <path
                        d="M0 0H20V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V0Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
