import { useContext } from "react";
import {
    IWebSocketStateContext,
    WebSocketContext,
} from "../context/WebSocketContextProvider";

const useWebSocketContextProvider = (): IWebSocketStateContext => {
    const context = useContext(WebSocketContext);

    if (!context)
        throw new Error(
            "WebSocket Context must be use inside WebSocketContextProvider"
        );

    return context;
};

export default useWebSocketContextProvider;
