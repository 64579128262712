import { OverallAccuracyAnalytics } from "@convin/type/Analytics";
import { createContext } from "react";

export interface IAccuracyAnalysisTabsContext {
    tabValue: "accuracy" | "recall";
    handleChange: (
        _: React.SyntheticEvent,
        newValue: IAccuracyAnalysisTabsContext["tabValue"]
    ) => void;
}

export const AccuracyAnalysisTabContext =
    createContext<IAccuracyAnalysisTabsContext | null>(null);

export interface IAccuracyAnalysisTabsContext {
    tabValue: "accuracy" | "recall";
    handleChange: (
        _: React.SyntheticEvent,
        newValue: IAccuracyAnalysisTabsContext["tabValue"]
    ) => void;
}

export interface IAccuracyAnalysisModalContext {
    open: boolean;
    handleClose: () => void;
    parameter: { id: number; name: string } | null;
    handleSetParameter: (e: IAccuracyAnalysisModalContext["parameter"]) => void;
}

export const AccuracyAnalysisModalContext =
    createContext<null | IAccuracyAnalysisModalContext>(null);

export interface IAccuracyStatsProvider {
    accuracyData: OverallAccuracyAnalytics;
    chartData:
        | Array<{ epoch: number; accuracy: number; cumulative_average: number }>
        | undefined;
    isChartLoading: boolean;
    color: string;
    dateLabel: string;
}

export const AccuracyStatsContext =
    createContext<null | IAccuracyStatsProvider>(null);
