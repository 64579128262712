import { Box, Divider, Stack, Typography } from "@mui/material";
import { useGetBulkUploadList } from "../hooks/useGetBulkUploadList";
import CustomSkeleton from "@convin/components/custom_components/Skeleton";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import BulkUploadNotificationCard from "./BulkUploadNotificationCard";
import NoDownloads from "./NoDownloads";
import { isDefined } from "@convin/utils/helper/common.helper";
import { BulkUploadData } from "@convin/type/Report";
import {
    useDeleteBulkUploadListMutation,
    useLazyGetBulkUploadListQuery,
} from "@convin/redux/services/home/analyticsDashboard.service";

export default function GeneralNotificationPanel() {
    const { data, isLoading, hasNext, fetchNext, isFetching } =
        useGetBulkUploadList();
    const [refetchQuery] = useLazyGetBulkUploadListQuery();

    const [deleteList] = useDeleteBulkUploadListMutation();

    if (isLoading) return <CustomSkeleton rows={5} px={1.5} />;

    return (
        <Stack className="w-full h-full overflow-auto">
            <Box className="flex-1 overflow-auto" px={2}>
                {data?.count ? (
                    <VirtualList<BulkUploadData<"MANUAL_QMS" | "USER_LIST">>
                        Component={BulkUploadNotificationCard}
                        data={data?.results ?? []}
                        hasNext={hasNext}
                        fetchNext={fetchNext}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        sx={{
                            p: 0,
                        }}
                    />
                ) : (
                    <NoDownloads copy="Notifications" />
                )}
            </Box>
            {isDefined(data?.results) && data?.results.length !== 0 && (
                <Box className="flex flex-col justify-center flex-shrink-0">
                    <Divider flexItem className="flex-1" />
                    <Typography
                        component="button"
                        variant="medium"
                        color="primary.main"
                        className="font-medium text-center"
                        p={2}
                        onClick={() => {
                            deleteList()
                                .unwrap()
                                .then(() => {
                                    refetchQuery({});
                                });
                        }}
                    >
                        Clear
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
