import { createContext, useContext } from "react";

/**
 * Interface for the AttachLmsDocuments context
 */
export interface IAttachLmsDocumentsContext {
    /**
     * Array of selected document IDs
     */
    value: number[];

    /**
     * Function to update the selected document IDs
     * @param newValue - New array of document IDs
     */
    handleUpdate: (newValue: number[]) => void;
}

/**
 * Default context values
 */
export const defaultContextValue: IAttachLmsDocumentsContext = {
    value: [],
    handleUpdate: () => {},
};

/**
 * Create the context with default values
 */
export const AttachLmsDocumentsContext = createContext<
    IAttachLmsDocumentsContext | undefined
>(undefined);

/**
 * Custom hook to use the AttachLmsDocuments context
 * @returns AttachLmsDocuments context
 */
export const useAttachLmsDocuments = (): IAttachLmsDocumentsContext => {
    const context = useContext(AttachLmsDocumentsContext);

    if (!context) {
        throw new Error(
            "useAttachLmsDocuments must be used within an AttachLmsDocumentsProvider"
        );
    }

    return context;
};
