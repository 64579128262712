import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import useGlobalFiltersStateContext from "../../../../../../../hooks/useGlobalFiltersStateContext";
import { useGetAccountTagsQuery } from "@convin/redux/services/account/account.service";
import { AccountTag } from "@convin/type/Account";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Label } from "@convin/components/custom_components/Typography/Label";
import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import { AccountListFilters } from "@convin/type/Filters.model";

const AccountTagsFilter = ({ label }: { label?: string }) => {
    const { data, isLoading } = useGetAccountTagsQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const { dashboardType } = useFiltersVisibilityContext();
    return (
        <>
            <GenericMultipleSelect<AccountTag>
                label={label ?? "Tags"}
                loading={isLoading}
                values={filtersState.accountTags.tagIds}
                setValues={(val: number[]) => {
                    updateFiltersState({
                        accountTags: {
                            ...filtersState.accountTags,
                            tagIds: val,
                        },
                    });
                }}
                autocompleteProps={{
                    options: data || [],
                }}
            />
            {dashboardType === "Account" && (
                <Stack
                    direction="row"
                    gap={5}
                    sx={{
                        mt: 2,
                        alignItems: "center",
                    }}
                >
                    <Label colorType="666">Logic between</Label>

                    <GenericRadioGroup<
                        AccountListFilters["accountTags"]["logic"]
                    >
                        options={[
                            { id: "and", label: "And" },
                            { id: "or", label: "Or" },
                        ]}
                        value={filtersState.accountTags.logic}
                        handleChange={(val) => {
                            updateFiltersState({
                                accountTags: {
                                    ...filtersState.accountTags,
                                    logic: val,
                                },
                            });
                        }}
                        className="gap-5 "
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filtersState?.accountTags?.negate}
                                onChange={(e) => {
                                    updateFiltersState({
                                        accountTags: {
                                            ...filtersState.accountTags,
                                            negate: e.target.checked,
                                        },
                                    });
                                }}
                                name="isExcluded"
                            />
                        }
                        label="Exclude"
                    />
                </Stack>
            )}
        </>
    );
};

export default AccountTagsFilter;
