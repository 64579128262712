import {
    AccordionDetails,
    AccordionSummary,
    SxProps,
    Theme,
    alpha,
} from "@mui/material";
import { useCallback, useMemo } from "react";

import ExpandAccordianSvg from "@convin/components/svg/ExpandAccordianSvg";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import MinMaxInputField from "@convin/modules/components/globalDashboardFilters/components/MinMaxInputField";
import { StyledCategoryAccordion } from "@convin/modules/components/globalDashboardFilters/styles";
import { CategoryWithQuestions } from "@convin/type/Audit";
import { NumericRange } from "@convin/type/Common";
import { TemplateCategoryFilter } from "@convin/type/Filters.model";

import TemplateQuestion from "./TemplateQuestionFilter";

type Props = {
    data: CategoryWithQuestions;
    sx?: SxProps<Theme>;
};

const TemplateCategoryAccordion = ({
    data: { name, questions, id },
    sx = {},
}: Props) => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const categoryData: TemplateCategoryFilter[number] = useMemo(() => {
        return filtersState.templateCategories?.[id] ?? [null, null];
    }, [filtersState.templateCategories?.[id]]);

    const updateCategoryScore = useCallback(
        (score: NumericRange) => {
            updateFiltersState({
                templateCategories: {
                    ...filtersState.templateCategories,
                    [id]: score,
                },
            });
        },
        [filtersState.templateCategories]
    );

    return (
        <StyledCategoryAccordion
            disableGutters
            elevation={0}
            sx={{
                background: (theme) =>
                    alpha(theme.palette.textColors[999], 0.1),
                ...sx,
            }}
            slotProps={{ transition: { unmountOnExit: true } }}
        >
            <AccordionSummary expandIcon={<ExpandAccordianSvg />}>
                {name}
            </AccordionSummary>
            <AccordionDetails>
                <MinMaxInputField
                    showLabel
                    name="Category Score"
                    values={categoryData}
                    minLabel="Min Score"
                    maxLabel="Max Score"
                    setValues={(val) => updateCategoryScore(val)}
                    minLimit={0}
                    maxLimit={100}
                />
                {questions.map((question, index) => (
                    <TemplateQuestion
                        key={question.id}
                        question={question}
                        index={index}
                    />
                ))}
            </AccordionDetails>
        </StyledCategoryAccordion>
    );
};

export default TemplateCategoryAccordion;
