import PageLoader from "@convin/components/custom_components/PageLoader";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import {
    useGetConversationDetailsQuery,
    useGetConversationMediaUrlQuery,
    useGetConversationTranscriptQuery,
    useGetPreviewMeetingQuery,
} from "@convin/redux/services/conversation/conversations.service";
import {
    AITagTranscript,
    ConversationDetails,
    Transcript,
} from "@convin/type/Conversation";
import {
    formatTranscriptBasedOnSpeakers,
    generateTopicsFromTranscript,
    isDefined,
    mergeContinuousSpeakers,
    getQuestionsAndActionItemsFromTranscript,
} from "@convin/utils/helper/common.helper";
import { AxiosError } from "axios";
import {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConversationAccessDenied from "../components/ConversationAccessDenied";
import Error500State from "@convin/components/custom_components/reuseable/Error500State";
import useAuth from "@convin/hooks/useAuth";

export interface IConversationDetails {
    conversationDetails?: ConversationDetails;
    isConversationDetailsLoading: boolean;
    mediaUrl?: string;
    isMedaiUrlLoading: boolean;
    transcripts?: Transcript[];
    speakerBasedTranscript: Record<string, Transcript[]> | null;
    isTranscriptsLoading: boolean;
    dashboardType: "account" | "conversation" | "qms" | "preview";
    aiAuditTagTranscript: {
        scoreId: number | null;
        transcripts: Array<AITagTranscript>;
    };
    handleAiAuditTagTranscript: (
        payload: Partial<IConversationDetails["aiAuditTagTranscript"]>
    ) => void;
    isPorcessing: boolean;
    topics: Record<
        string,
        {
            color: string;
            transcript: Array<
                Pick<
                    Transcript,
                    | "start_time"
                    | "end_time"
                    | "monologue_text"
                    | "speaker_type"
                    | "speaker_name"
                > & { color: string }
            >;
            phrases: Record<
                string,
                {
                    count: number;
                    transcript: Array<
                        Pick<
                            Transcript,
                            | "start_time"
                            | "end_time"
                            | "monologue_text"
                            | "speaker_type"
                            | "speaker_name"
                        >
                    >;
                }
            >;
        }
    >;
    actionItems: Record<
        string,
        {
            transcripts: Array<
                Transcript["sentence_categories"]["action"][number] &
                    Pick<
                        Transcript,
                        "speaker_id" | "speaker_name" | "speaker_type"
                    >
            >;
            speaker_name: string;
            speaker_id: number;
            speaker_type: Transcript["speaker_type"];
        }
    >;
    questions: Record<
        string,
        {
            transcripts: Array<
                Transcript["sentence_categories"]["action"][number] &
                    Pick<
                        Transcript,
                        "speaker_id" | "speaker_name" | "speaker_type"
                    >
            >;
            speaker_name: string;
            speaker_id: number;
            speaker_type: Transcript["speaker_type"];
        }
    >;
    callDuration: number | null;
    error?: AxiosError;
}

export const ConversationDetailsContext = createContext<IConversationDetails>(
    {} as IConversationDetails
);

export default function ConversationDetailsProvider({
    children,
    dashboardType,
    conversationId,
}: PropsWithChildren<{
    dashboardType: IConversationDetails["dashboardType"];
    conversationId?: string;
}>): ReactElement {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const { conversation_id } = useParams<{
        conversation_id: string;
    }>();
    const id = conversationId ?? conversation_id;
    const searchParams = useSearchQuery();
    const {
        data: conversationDetails,
        isFetching: isConversationDetailsLoading,
        error,
    } = useGetConversationDetailsQuery(id!, {
        skip: dashboardType === "preview" || !isDefined(id),
    });
    const {
        data: mediaUrl,
        isFetching: isMedaiUrlLoading,
        isError,
    } = useGetConversationMediaUrlQuery(id!, {
        skip:
            dashboardType === "qms" ||
            dashboardType === "preview" ||
            !(conversationDetails?.meeting_type === "call") ||
            conversationDetails?.conference_tool === "convin_qms" ||
            !isDefined(id),
    });
    const {
        data: transcripts,
        isLoading: isTranscriptsLoading,
        isError: isTranscriptError,
    } = useGetConversationTranscriptQuery(id!, {
        skip:
            dashboardType === "qms" ||
            dashboardType === "preview" ||
            !isDefined(id),
    });

    const {
        data: previewConversationDetails,
        isFetching: isPreviewConversationDetailsLoading,
        error: previewError,
    } = useGetPreviewMeetingQuery(searchParams.get("share_id")!, {
        skip: dashboardType !== "preview",
    });

    const [aiAuditTagTranscript, setAiAuditTagTranscript] = useState<
        IConversationDetails["aiAuditTagTranscript"]
    >({
        scoreId: null,
        transcripts: [],
    });
    const handleAiAuditTagTranscript: IConversationDetails["handleAiAuditTagTranscript"] =
        useCallback(
            (payload) => {
                setAiAuditTagTranscript((prev) => ({
                    ...prev,
                    ...payload,
                }));
            },
            [aiAuditTagTranscript]
        );

    const isCall = useMemo(
        () =>
            dashboardType === "preview"
                ? previewConversationDetails?.meeting_json?.meeting_type ===
                  "call"
                : conversationDetails?.meeting_type === "call",
        [
            conversationDetails?.meeting_type,
            previewConversationDetails?.meeting_json?.meeting_type,
        ]
    );

    const memoisedTranscripts = useMemo(() => {
        return isTranscriptError
            ? []
            : dashboardType === "preview"
            ? previewConversationDetails?.transcript_json
            : isCall && !isDefined(aiAuditTagTranscript.scoreId)
            ? mergeContinuousSpeakers(transcripts)
            : transcripts;
    }, [
        isCall,
        transcripts,
        aiAuditTagTranscript,
        previewConversationDetails?.transcript_json,
        isTranscriptError,
    ]);

    const { topics, questions, actionItems } = useMemo(() => {
        return {
            topics: generateTopicsFromTranscript(transcripts),
            ...getQuestionsAndActionItemsFromTranscript(transcripts),
        };
    }, [conversationDetails?.meeting_type, transcripts]);

    const speakerBasedTranscript = useMemo(() => {
        return isCall
            ? formatTranscriptBasedOnSpeakers(
                  mergeContinuousSpeakers(
                      dashboardType === "preview"
                          ? previewConversationDetails?.transcript_json
                          : transcripts
                  )
              )
            : null;
    }, [isCall, transcripts, previewConversationDetails?.transcript_json]);

    const callDuration = useMemo(() => {
        if (dashboardType === "preview") {
            if (!isDefined(previewConversationDetails)) return null;
            return (
                previewConversationDetails.end_time -
                previewConversationDetails.start_time
            );
        }
        if (!isDefined(conversationDetails)) return null;
        return (
            Math.abs(
                new Date(conversationDetails.end_time).getTime() -
                    new Date(conversationDetails.start_time).getTime()
            ) / 1000
        );
    }, [dashboardType, previewConversationDetails, conversationDetails]);

    useEffect(() => {
        if (
            dashboardType === "preview" &&
            isDefined(previewConversationDetails) &&
            isAuthenticated
        ) {
            navigate(`/call/${previewConversationDetails.meeting}`);
        }
    }, [previewConversationDetails]);

    if (isPreviewConversationDetailsLoading) {
        return <PageLoader />;
    }

    const isPorcessing =
        dashboardType === "preview"
            ? previewConversationDetails?.meeting_json?.processing_status ===
              "processing"
            : conversationDetails?.processing_status === "processing";

    return (
        <ConversationDetailsContext
            value={{
                isConversationDetailsLoading:
                    dashboardType === "preview"
                        ? isPreviewConversationDetailsLoading
                        : isConversationDetailsLoading,
                conversationDetails:
                    dashboardType === "preview"
                        ? previewConversationDetails?.meeting_json
                        : conversationDetails,
                mediaUrl: isError
                    ? undefined
                    : dashboardType === "preview"
                    ? previewConversationDetails?.location
                    : mediaUrl?.location,
                isMedaiUrlLoading:
                    dashboardType === "preview"
                        ? isPreviewConversationDetailsLoading
                        : isMedaiUrlLoading,
                transcripts: memoisedTranscripts,
                speakerBasedTranscript,
                isTranscriptsLoading:
                    dashboardType === "preview"
                        ? isPreviewConversationDetailsLoading
                        : isTranscriptsLoading,
                dashboardType,
                aiAuditTagTranscript,
                handleAiAuditTagTranscript,
                isPorcessing,
                topics,
                questions,
                actionItems,
                callDuration,
                error: (dashboardType === "preview"
                    ? previewError
                    : error) as AxiosError,
            }}
        >
            {(error as AxiosError)?.status === 404 ? (
                <ConversationAccessDenied />
            ) : (error as AxiosError)?.status === 500 ? (
                <Error500State />
            ) : (
                children
            )}
        </ConversationDetailsContext>
    );
}
