import { createApi } from "@reduxjs/toolkit/query/react";
import {
    ConversationTraits,
    CreateDemoLinkResponse,
    DemoLinkMetadata,
    IBulkCall,
    IBulkCallingAnalytics,
    ICallFlowConfig,
    IFolder,
    IGPTResponsePayload,
    IPathway,
    IPathwayConfig,
    ISingleCall,
    ISlotsByCompanyPayload,
    IVersion,
    IVoice,
} from "@convin/type/aiVoiceCall";
import { getDomain } from "@convin/utils/helper/common.helper";
import axiosBaseQuery from "../axiosBaseQuery";

const AI_AGENT_HOST_URL = `https://${
    localStorage.getItem("aiAgentHostUrl") || "vbprod.convin.ai"
}/api`;
// const AI_AGENT_HOST_URL = `${import.meta.env.VITE_APP_AI_AGENT_HOST_URL}/api/`;

export const aiVoiceCallSlice = createApi({
    reducerPath: "aiVoiceCall",
    baseQuery: axiosBaseQuery({
        baseUrl: AI_AGENT_HOST_URL,
    }),
    endpoints: (builder) => ({
        getBulkCallingStats: builder.query<IBulkCallingAnalytics, void>({
            query: () => ({
                url: `/analytics/${getDomain()}`,
                method: "GET",
            }),
        }),
        getSingleCallsList: builder.query<ISingleCall[], void>({
            query: () => ({
                url: `/list/single_call/${getDomain()}`,
                method: "GET",
            }),
        }),
        getBulkCallsList: builder.query<IBulkCall[], void>({
            query: () => ({
                url: `/list/batch_call/${getDomain()}`,
                method: "GET",
            }),
        }),
        createSingleCall: builder.mutation<ISingleCall, FormData>({
            query: (payload) => ({
                url: "/call",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getSingleCallsList",
                        undefined,
                        (draft) => [data, ...draft]
                    )
                );
            },
        }),
        createBatchCall: builder.mutation<IBulkCall, FormData>({
            query: (payload) => ({
                url: "/batch",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getBulkCallsList",
                        undefined,
                        (draft) => [data, ...draft]
                    )
                );
            },
        }),
        stopBatchCalling: builder.mutation<{ message: string }, number>({
            query: (id) => ({
                url: `/batch/${id}/stop`,
                method: "POST",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getBulkCallsList",
                        undefined,
                        (draft) =>
                            draft.map((item) => {
                                if (id === item.id) {
                                    return {
                                        ...item,
                                        status: "terminated",
                                    };
                                }
                                return item;
                            })
                    )
                );
            },
        }),
        getLanguageList: builder.query<string[], void>({
            query: () => ({
                url: "/languages",
                method: "GET",
            }),
        }),
        getVoicesList: builder.query<string[], void>({
            query: () => ({
                url: "/tts_speakers",
                method: "GET",
            }),
        }),
        getCallFlowList: builder.query<{ response: ICallFlowConfig[] }, void>({
            query: () => ({
                url: `/call_flow/${getDomain()}`,
                method: "GET",
            }),
        }),

        // Conversational Pathways

        getFoldersList: builder.query<
            { folders_list: IFolder[]; all_pathways: IPathway[] },
            void
        >({
            query: () => ({
                url: `/conversational_pathway/folders/${getDomain()}`,
                method: "GET",
            }),
        }),

        getPathwaysList: builder.query<IPathway[], { folder_id?: string }>({
            query: (payload) => ({
                url: "/conversational_pathway/pathways/list",
                method: "POST",
                body: payload,
            }),
        }),

        getVersionsList: builder.query<IVersion[], { pathway_id: string }>({
            query: (payload) => ({
                url: "/conversational_pathway/pathways/versions",
                method: "POST",
                body: payload,
            }),
        }),

        createFolder: builder.mutation<
            { folder: IFolder },
            { folder_name: string }
        >({
            query: (payload) => ({
                url: `/conversational_pathway/folders/${getDomain()}`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getFoldersList",
                        undefined,
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                folders_list: [
                                    data.folder,
                                    ...draft.folders_list,
                                ],
                            });
                        }
                    )
                );
            },
        }),
        createPathway: builder.mutation<
            IPathway,
            {
                folder_id?: string;
                pathway_name: string;
                subdomain: string;
                pathway_config: IPathwayConfig;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathway/create",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (!props.folder_id) {
                    dispatch(
                        aiVoiceCallSlice.util.updateQueryData(
                            "getFoldersList",
                            undefined,
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    all_pathways: [data, ...draft.all_pathways],
                                });
                            }
                        )
                    );
                } else {
                    dispatch(
                        aiVoiceCallSlice.util.updateQueryData(
                            "getPathwaysList",
                            { folder_id: props.folder_id },
                            (draft) => [data, ...draft]
                        )
                    );
                }
            },
        }),
        getPathwayInfo: builder.query<
            IPathway,
            {
                pathway_id: string;
                version_id?: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathway",
                method: "POST",
                body: payload,
            }),
        }),
        updatePathwayInfo: builder.mutation<
            IPathway,
            {
                pathway_id: string;
                version_id: string;
                pathway_config: IPathwayConfig;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathway",
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getPathwayInfo",
                        {
                            pathway_id: props.pathway_id,
                            version_id: props.version_id,
                        },
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                pathway_config: props.pathway_config,
                            });
                        }
                    )
                );
            },
        }),
        movePathway: builder.mutation<
            {
                pathway: IPathway;
            },
            {
                pathway_id: string;
                folder_id?: string;
                from_folder_id: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathways/move",
                method: "PATCH",
                body: payload,
            }),

            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getPathwaysList",
                        { folder_id: props.folder_id },
                        (draft) => [...draft, data.pathway]
                    )
                );

                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getPathwaysList",
                        { folder_id: props.from_folder_id },
                        (draft) =>
                            draft.filter(
                                (item) => item.pathway_id !== props.pathway_id
                            )
                    )
                );
            },
        }),
        deletePathway: builder.mutation<
            {
                pathway: {
                    pathway_id: string;
                    folder_id: string;
                    is_active: boolean;
                };
            },
            {
                pathway_id: string;
                folder_id: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathway",
                method: "DELETE",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getPathwaysList",
                        { folder_id: props.folder_id },
                        (draft) =>
                            draft.filter(
                                (item) => item.pathway_id !== props.pathway_id
                            )
                    )
                );
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getFoldersList",
                        undefined,
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                all_pathways: draft.all_pathways.filter(
                                    (item) =>
                                        item.pathway_id !== props.pathway_id
                                ),
                            });
                        }
                    )
                );
            },
        }),
        publishPathway: builder.mutation<
            {
                version: IVersion;
            },
            {
                pathway_id: string;
                new_version_name: string;
                version_id: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathways/publish",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getVersionsList",
                        { pathway_id: props.pathway_id },
                        (draft) => [data.version, ...draft]
                    )
                );
            },
        }),
        updatePathwayDefaultVersion: builder.mutation<
            {
                pathway: IPathway;
            },
            {
                pathway_id: string;
                version_id: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/pathways/versions",
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getVersionsList",
                        { pathway_id: props.pathway_id },
                        (draft) =>
                            draft.map((item) => {
                                if (item.version_id === props.version_id) {
                                    return {
                                        ...item,
                                        default_version: props.version_id,
                                    };
                                }
                                return item;
                            })
                    )
                );
            },
        }),
        editFolderName: builder.mutation<
            void,
            {
                folder_id: string;
                folder_name: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/folder/edit_name",
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getFoldersList",
                        undefined,
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                folders_list: draft.folders_list.map((item) => {
                                    if (item.folder_id === props.folder_id) {
                                        return {
                                            ...item,
                                            folder_name: props.folder_name,
                                        };
                                    }
                                    return item;
                                }),
                            });
                        }
                    )
                );
            },
        }),
        deleteFolder: builder.mutation<
            void,
            {
                folder_id: string;
            }
        >({
            query: (payload) => ({
                url: "/conversational_pathway/folder/delete",
                method: "DELETE",
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    aiVoiceCallSlice.util.updateQueryData(
                        "getFoldersList",
                        undefined,
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                folders_list: draft.folders_list.filter(
                                    (item) => item.folder_id !== props.folder_id
                                ),
                                all_pathways: draft.all_pathways.filter(
                                    (item) => item.folder_id !== props.folder_id
                                ),
                            });
                        }
                    )
                );
            },
        }),
        getGPTResponse: builder.query<
            {
                response: {
                    choices: {
                        message: {
                            content: string;
                        };
                    }[];
                };
            },
            IGPTResponsePayload
        >({
            query: (payload) => ({
                url: "/generate_gpt_response",
                method: "POST",
                body: payload,
            }),
        }),
        getVoicesBySubdomain: builder.query<IVoice[], void>({
            query: () => ({
                url: `/list/voices/${getDomain()}`,
                method: "GET",
            }),
        }),
        getConversationTraits: builder.query<ConversationTraits, void>({
            query: () => ({
                url: "/conversation_traits ",
                method: "GET",
            }),
        }),
        createSlotsByCompany: builder.query<
            {
                message: string;
                status: string;
            },
            ISlotsByCompanyPayload
        >({
            query: (payload) => ({
                url: "/subdomain_voicebot_config ",
                method: "POST",
                body: payload,
            }),
        }),
        createDemoLink: builder.mutation<
            CreateDemoLinkResponse,
            Partial<DemoLinkMetadata>
        >({
            query: (payload) => ({
                url: "/demo-link/create",
                method: "POST",
                body: payload,
            }),
        }),
        getDemoLink: builder.query<DemoLinkMetadata, string>({
            query: (company_name) => ({
                url: `/demo-link/${company_name}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetVoicesListQuery,
    useGetLanguageListQuery,
    useCreateSingleCallMutation,
    useCreateBatchCallMutation,
    useGetSingleCallsListQuery,
    useGetBulkCallsListQuery,
    useStopBatchCallingMutation,
    useGetBulkCallingStatsQuery,
    useLazyGetPathwayInfoQuery,
    useUpdatePathwayInfoMutation,
    useCreateFolderMutation,
    useGetFoldersListQuery,
    useGetPathwaysListQuery,
    useMovePathwayMutation,
    useGetVersionsListQuery,
    useUpdatePathwayDefaultVersionMutation,
    usePublishPathwayMutation,
    useCreatePathwayMutation,
    useDeletePathwayMutation,
    useEditFolderNameMutation,
    useDeleteFolderMutation,
    useLazyGetCallFlowListQuery,
    useGetGPTResponseQuery,
    useLazyGetGPTResponseQuery,
    useGetVoicesBySubdomainQuery,
    useGetConversationTraitsQuery,
    useLazyCreateSlotsByCompanyQuery,
    useCreateDemoLinkMutation,
    useGetDemoLinkQuery,
    useLazyGetDemoLinkQuery,
} = aiVoiceCallSlice;
