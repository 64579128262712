// @mui
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { GlobalStyles } from "@mui/material";
// utils

// ----------------------------------------------------------------------

export default function GistStyle() {
    const { data } = useGetDomainConfigQuery();
    const { user, checkCanAccess } = useAuth();

    return (
        <GlobalStyles
            styles={{
                //We used to use Intercom first for the chat widget. Now we use Gist
                ...(!isDefined(user) ||
                (isDefined(user) &&
                    !checkCanAccess({
                        permission_type: PermissionTypes.edit,
                        code_name: "app.can_access_intercom",
                        heading: PermissionModules.General,
                    })) ||
                data?.logo
                    ? {
                          "& #gist-app": {
                              display: "none !important",
                              position: "relative !important",
                              zIndex: -1,
                          },
                      }
                    : {
                          "& #gist-app": {
                              display: "block !important",
                              position: "unset !important",
                              zIndex: "unset !important",
                          },
                      }),
            }}
        />
    );
}
