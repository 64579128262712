import { Label } from "@convin/components/custom_components/Typography/Label";
import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetCallTypesQuery } from "@convin/redux/services/settings/callTypeManager.service";
import { CallType } from "@convin/type/CallManager";
import { ConversationListFilters } from "@convin/type/Filters.model";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";

const TypesFilter = () => {
    const { data: callTypeList, isLoading: isCallTypesListLoading } =
        useGetCallTypesQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const { dashboardType } = useFiltersVisibilityContext();

    const showFilter =
        dashboardType === "Conversation" || dashboardType === "CI";

    return (
        <>
            <GenericMultipleSelect<CallType>
                label="Types"
                loading={isCallTypesListLoading}
                values={filtersState.callTypes.typeIds}
                setValues={(val: number[]) => {
                    updateFiltersState({
                        callTypes: { ...filtersState.callTypes, typeIds: val },
                    });
                }}
                autocompleteProps={{
                    options: callTypeList || [],
                }}
            />
            {showFilter && (
                <Stack
                    direction="row"
                    gap={5}
                    sx={{
                        mt: 2,
                        alignItems: "center",
                    }}
                >
                    <Label colorType="666">Logic between</Label>

                    <GenericRadioGroup<
                        ConversationListFilters["callTypes"]["logic"]
                    >
                        options={[
                            { id: "and", label: "And" },
                            { id: "or", label: "Or" },
                        ]}
                        value={filtersState.callTypes.logic}
                        handleChange={(val) => {
                            updateFiltersState({
                                callTypes: {
                                    ...filtersState.callTypes,
                                    logic: val,
                                },
                            });
                        }}
                        className=" gap-5"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filtersState?.callTypes?.negate}
                                onChange={(e) => {
                                    updateFiltersState({
                                        callTypes: {
                                            ...filtersState.callTypes,
                                            negate: e.target.checked,
                                        },
                                    });
                                }}
                                name="isExcluded"
                            />
                        }
                        label="Exclude"
                    />
                </Stack>
            )}
        </>
    );
};

export default TypesFilter;
