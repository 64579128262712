import { Label } from "@convin/components/custom_components/Typography/Label";
import MoreFilterSvg from "@convin/components/svg/OverallFiters/MoreFilterSvg";
import { Box, Button, Divider, alpha, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import DrawerFilters from "./components/DrawerFilters";
import StaticDashboardFilters from "./components/StaticFilters";
import MoreOptions from "@convin/components/custom_components/Popover/MoreOptions";
import ShareAnalyticsModal from "@convin/modules/home/analatyicsDashboard/modules/OverallDashboard/components/ShareAnalyticsModal";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import AppliedFilters from "./components/AppliedFilters";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import FilledShareIcon from "@convin/components/svg/FilledShareIcon";
import { LearnMore } from "@convin/modules/GlobalDashboardLayout/components/LearnMore";

type GlobalDashboardFiltersProps = {
    moreOptionsVisibility?: boolean;
};

const GlobalDashboardFilters = React.memo(
    function GlobalDashboardFilters({
        moreOptionsVisibility = false,
    }: GlobalDashboardFiltersProps) {
        const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
        const [isShareAnalyticsModalOpen, setIsShareAnalyticsModalOpen] =
            useState(false);
        const { resetFilters } = useGlobalFiltersStateContext();
        const theme = useTheme();
        const {
            appliedFilterKeys,
            staticFiltersVisibility,
            drawerFiltersVisibility,
        } = useFiltersVisibilityContext();
        const toggleDrawer = useCallback(
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === "keydown" &&
                    ((event as React.KeyboardEvent).key === "Tab" ||
                        (event as React.KeyboardEvent).key === "Shift")
                ) {
                    return;
                }

                setIsDrawerOpen((prev) => !prev);
            },
            []
        );

        const handleDrawerClose = useCallback(() => {
            setIsDrawerOpen(false);
        }, [isDrawerOpen]);

        return (
            <>
                <Box className="relative flex justify-between">
                    <Box
                        className="flex items-center justify-center flex-1 flex-shrink-0"
                        sx={{
                            gap: (theme) => theme.spacing(1.25),
                        }}
                    >
                        <StaticDashboardFilters
                            visibleFilters={staticFiltersVisibility || []}
                        />

                        {appliedFilterKeys?.length ? <AppliedFilters /> : <></>}
                        {!!drawerFiltersVisibility?.length ? (
                            <Box
                                className="flex items-center justify-center transition-all cursor-pointer"
                                sx={(theme) => ({
                                    borderRadius: "100px",
                                    width: theme.spacing(6.25),
                                    height: theme.spacing(4.5),
                                    border: "1px solid",

                                    borderColor: alpha(
                                        theme.palette.textColors["333"],
                                        0.2
                                    ),
                                    "&:hover": {
                                        bgcolor: alpha(
                                            theme.palette.primary.main,
                                            0.2
                                        ),
                                        color: theme.palette.primary.main,
                                        fontWeight: 500,
                                        borderColor: alpha(
                                            theme.palette.primary.main,
                                            0.2
                                        ),
                                    },
                                })}
                                onClick={toggleDrawer}
                                aria-label="drawer-filters-button"
                                component={"button"}
                            >
                                <MoreFilterSvg
                                    sx={{
                                        color: "textColors.666",
                                    }}
                                />
                            </Box>
                        ) : (
                            <></>
                        )}

                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={(theme) => ({
                                bgcolor: alpha(
                                    theme.palette.textColors["999"],
                                    0.2
                                ),
                                mx: 0.5,
                            })}
                        />
                        <Button
                            variant="text"
                            onClick={() => resetFilters()}
                            className="px-0 hover:bg-transparent"
                        >
                            <Label
                                colorType="666"
                                variant="small"
                                className="leading-6 cursor-pointer"
                                sx={{
                                    fontWeight: 400,
                                    "&:hover": {
                                        color: "primary.main",
                                    },
                                }}
                            >
                                Clear Filters
                            </Label>
                        </Button>
                    </Box>
                    <LearnMore />
                    {!!moreOptionsVisibility && (
                        <MoreOptions className="rounded ">
                            <Box
                                sx={{
                                    py: 1.5,
                                    px: 2,
                                    gap: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    "&:hover": {
                                        color: "textColors.333",
                                        bgcolor: alpha(
                                            theme.palette.primary.main,
                                            0.2
                                        ),
                                    },
                                    color: "textColors.333",
                                }}
                                onClick={() => {
                                    setIsShareAnalyticsModalOpen(true);
                                }}
                            >
                                <FilledShareIcon />
                                <Label colorType="333">Share Analytics</Label>
                            </Box>
                        </MoreOptions>
                    )}
                </Box>
                {!!moreOptionsVisibility && isShareAnalyticsModalOpen && (
                    <ShareAnalyticsModal
                        open={isShareAnalyticsModalOpen}
                        onClose={() => {
                            setIsShareAnalyticsModalOpen(false);
                        }}
                    />
                )}
                <DrawerFilters
                    visibleFilters={drawerFiltersVisibility || []}
                    {...{
                        isDrawerOpen,
                        handleDrawerClose,
                    }}
                />
            </>
        );
    },
    (prev, curr) => prev?.moreOptionsVisibility === curr?.moreOptionsVisibility
);

export default GlobalDashboardFilters;
