import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function DraftFilter() {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={!!filtersState.isDraftAudit}
                    onChange={(_, checked) => {
                        updateFiltersState({
                            isDraftAudit: checked,
                        });
                    }}
                    name="isDraftAudit"
                />
            }
            label="Audit - Draft"
        />
    );
}
