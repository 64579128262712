import { FormProvider } from "@convin/components/hook-form";
import useAuth from "@convin/hooks/useAuth";
import useIsMountedRef from "@convin/hooks/useIsMountedRef";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, FormControl, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import { FormTextField } from "../components";
import { listTimeZones } from "timezone-support";
import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import {
    useGetUserFromInvitationIdQuery,
    useSignUpMutation,
} from "@convin/redux/services/auth/auth.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { SingUpPayload } from "@convin/type/User";
import PasswordField from "../components/PasswordField";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";
import { DashboardRoutesConfig } from "@convin/config/routes.config";

const timeZoneList = listTimeZones().map((e) => ({ id: e, label: e }));

export default function Signup() {
    useDocumentTitle(DashboardRoutesConfig["Signup"].name);
    const query = useSearchQuery();
    const invitation_id = query.get("invitation_id");
    const [signUp, { isLoading }] = useSignUpMutation();
    const { data: invitedUser } = useGetUserFromInvitationIdQuery(
        invitation_id!,
        {
            skip: !isDefined(invitation_id),
        }
    );

    const { initialize } = useAuth();

    const isMountedRef = useIsMountedRef();

    const schema = Yup.object().shape({
        first_name: Yup.string().required("Name is required"),
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
        primary_phone: Yup.string().required("Phone number is required"),
        timezone: Yup.string().required("Timezone is required"),
    });

    const defaultValues = {
        first_name: "",
        email: "",
        password: "",
        primary_phone: "",
        timezone: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        values: {
            ...defaultValues,
            email: invitedUser?.email ?? "",
        },
    });

    const {
        setError,
        handleSubmit,
        formState: { errors },
        control,
    } = methods;

    const onSubmit = async ({
        first_name,
        email,
        password,
        timezone,
        primary_phone,
    }: SingUpPayload): Promise<void> => {
        const payload = new FormData();
        payload.append("first_name", first_name);
        payload.append("password", password);
        payload.append("email", email);
        payload.append("primary_phone", primary_phone);
        payload.append("username", email.toLowerCase());
        payload.append("timezone", timezone);
        signUp({
            payload,
            query: {
                invitation_id: invitation_id ?? false,
            },
        })
            .unwrap()
            .then(({ token }) => {
                window.localStorage.setItem(
                    "authTokens",
                    JSON.stringify(token)
                );
                initialize();
            })
            .catch((error) => {
                if (isMountedRef.current) {
                    setError("afterSubmit", {
                        message:
                            error?.data?.detail || "Oops! something went wrong",
                    });
                }
            });
    };

    return (
        <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto"
        >
            <Stack
                gap={2.5}
                className="w-full"
                sx={{
                    ".MuiFormLabel-root": {
                        "&:after": {
                            content: '"*"',
                            color: "#fd6c6f",
                        },
                    },
                }}
            >
                {!!errors.afterSubmit && (
                    <Alert
                        severity="error"
                        sx={{ border: "1px solid", borderColor: "error.main" }}
                    >
                        {errors.afterSubmit.message}
                    </Alert>
                )}
                <FormTextField
                    className="w-full requiredMark"
                    name="first_name"
                    label="Name"
                />
                <FormTextField
                    name="email"
                    label="Email Id"
                    disabled={isDefined(invitedUser)}
                    className="requiredMark"
                    slotProps={{
                        input: {
                            readOnly: isDefined(invitedUser),
                        },
                    }}
                />
                <PasswordField name="password" label="Password" />
                <FormTextField name="primary_phone" label="Phone Number" />
                <Controller
                    name="timezone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl className="w-full">
                            <GenericSingleSelect
                                label="Timezone"
                                autocompleteProps={{
                                    options: timeZoneList,
                                }}
                                value={field.value}
                                setValue={(value) => field.onChange(value)}
                                className="flex-1"
                                error={!!error}
                                helperText={error?.message}
                            />
                        </FormControl>
                    )}
                />
                <Button
                    variant="global"
                    fullWidth
                    size="large"
                    type="submit"
                    className="h-[42px]"
                    loading={isLoading}
                >
                    Sign up
                </Button>
            </Stack>
        </FormProvider>
    );
}
