import { Divider, Stack, alpha } from "@mui/material";
import { useMemo } from "react";

import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { StaticDashboardFiltersType } from "@convin/type/Filters.model";

import {
    DurationSelectorFilter,
    DateSelectorFilter,
    MeetingTypeFilter,
    TeamFilter,
    RepFilter,
    TemplateFilter,
    ModuleFilter,
} from "./filters";
import AuditDateSelectorFilter from "./filters/AuditDateSelectorFilter";

function StaticDashboardFilters({
    visibleFilters,
}: {
    visibleFilters: (keyof StaticDashboardFiltersType)[];
}) {
    const { data: versionData } = useGetDomainConfigQuery();
    const filters = useMemo(() => new Set(visibleFilters), [visibleFilters]);
    return (
        <Stack direction="row" gap={1.25} data-testid="static-filters">
            {versionData?.has_chat && filters.has("meetingType") ? (
                <>
                    <MeetingTypeFilter />
                    <Divider
                        flexItem
                        sx={(theme) => ({
                            width: "1px",
                            bgcolor: alpha(
                                theme.palette.textColors["999"],
                                0.2
                            ),
                            mx: 0.5,
                        })}
                    />
                </>
            ) : null}
            {filters.has("module") ? <ModuleFilter /> : null}
            {filters.has("teams") || filters.has("accountTeams") ? (
                <TeamFilter />
            ) : null}
            {filters.has("reps") || filters.has("accountReps") ? (
                <RepFilter />
            ) : null}
            {filters.has("template") ? <TemplateFilter /> : null}
            {filters.has("durationKey") ? <DurationSelectorFilter /> : null}
            {filters.has("auditDateKey") ? <AuditDateSelectorFilter /> : null}
            {filters.has("dateKey") ? <DateSelectorFilter /> : null}
        </Stack>
    );
}

export default StaticDashboardFilters;
