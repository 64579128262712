import {
    Box,
    Divider,
    ListItemButton,
    ListItemText,
    useTheme,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import { Label } from "@convin/components/custom_components/Typography/Label";
import TemplateSelectorSvg from "@convin/components/svg/OverallFiters/TemplateSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { useGetTemplatesQuery } from "@convin/redux/services/settings/auditManager.service";
import { Template } from "@convin/type/Audit";
import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox, filterPopoverSx } from "../../../styles";
import SearchField from "../../SearchField";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { isDefined } from "@convin/utils/helper/common.helper";
interface TemplateFilterProps {
    filterStyle?: "custom";
}
export default function TemplateFilter({
    filterStyle,
}: TemplateFilterProps): ReactElement {
    const theme = useTheme();
    const { data: templates, isLoading } = useGetTemplatesQuery();
    const { data: domainConfig } = useGetDomainConfigQuery();
    const { state, updateState, handleTemplateSelection } =
        useGlobalFiltersStateContext();
    const [searchValue, setSearchValue] = useState("");
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const handleChange = (option: Template): void => {
        updateState({
            analyticsTemplate: option.id,
        });
        handleClose();
    };

    const { meetingType, teams: activeTeams } = state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const filteredData =
        templates
            ?.filter((e) => !e.is_disabled)
            ?.filter(({ parameters }) =>
                !!parameters?.meeting_type?.length
                    ? parameters?.meeting_type?.includes(meetingType!)
                    : true
            )
            ?.filter(({ teams, parameters }) =>
                activeTeams.length === 0
                    ? teams?.length === 0 &&
                      parameters?.call_tags?.length === 0 &&
                      parameters?.call_types.length === 0 &&
                      parameters?.meeting_type?.length === 0
                        ? false
                        : true
                    : teams?.find?.(({ id }) => activeTeams?.includes(id)) ||
                      parameters?.call_tags?.length ||
                      parameters?.call_types?.length
                    ? true
                    : false
            )
            .filter((template) =>
                template.name.toLowerCase().includes(searchValue.toLowerCase())
            ) || [];

    useEffect(() => {
        if (!isDefined(domainConfig)) return;
        if (templates?.length === 0) {
            return;
        }
        if (!isDefined(domainConfig?.filters.template)) {
            if (!isDefined(state.analyticsTemplate)) {
                handleTemplateSelection([], state.meetingType!);
            }
        }
        if (
            isDefined(domainConfig?.filters.template) &&
            !isDefined(state.analyticsTemplate)
        )
            updateState({
                analyticsTemplate: domainConfig.filters.template,
            });
    }, [domainConfig, templates]);

    const filterBoxStyles =
        filterStyle === "custom"
            ? {
                  background: "inherit",
                  color: theme.palette.textColors[666],
                  border: "1px solid #999",
              }
            : {};

    return (
        <>
            <StyledFilterBox
                onClick={handleClick}
                aria-describedby={id}
                sx={filterBoxStyles}
            >
                <TemplateSelectorSvg
                    sx={{
                        color: (theme) =>
                            filterStyle === "custom"
                                ? theme.palette.textColors[666]
                                : theme.palette.common.white,
                    }}
                />
                <Label
                    isEllipses
                    hasTooltip
                    colorType={filterStyle === "custom" ? "666" : "white"}
                    className="flex-1 font-medium"
                    variant="medium"
                >
                    {state.analyticsTemplate === null
                        ? "Template"
                        : isLoading
                        ? "...Loading"
                        : templates?.find(
                              (option) => option.id === state.analyticsTemplate
                          )?.name}
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={filterPopoverSx(theme, "250px")}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Label colorType="666" variant="medium" sx={{ p: 1.5 }}>
                    Select Template
                </Label>
                <Divider />
                {isLoading ? (
                    <SkeletonLoader />
                ) : (
                    <Box
                        className="flex-1"
                        sx={{ height: "300px", overflow: "auto" }}
                    >
                        <SearchField
                            value={searchValue}
                            handleChange={setSearchValue}
                            placeholder="Search Template"
                        />
                        {filteredData?.map((template) => (
                            <ListItemButton
                                onClick={() => handleChange(template)}
                                key={template.id}
                                component="button"
                                sx={{ width: "100%" }}
                            >
                                <ListItemText>{template.name}</ListItemText>
                            </ListItemButton>
                        ))}
                    </Box>
                )}
            </CustomPopover>
        </>
    );
}
