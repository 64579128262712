import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";
import { Comment } from "@convin/type/Common";
import { showNoPermissionToast } from "@convin/utils/toast";
import {
    ReactElement,
    ReactNode,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";

export interface ICommentDrawerStateContext {
    isCommentsDrawerOpen: boolean;
    timeStampToCommentOn: number | null;
    handleOpenCommentsDrawer: (flag: boolean) => void;
    setTimeStampToCommentOn: (value: number | null) => void;
    dashboardType: "account" | "conversation" | "qms" | "preview";
    transcript: Comment["transcript"];
    setTranscriptToCommentOn: (
        transcript: ICommentDrawerStateContext["transcript"]
    ) => void;
}

export const CommentsDrawerStateContext = createContext<
    ICommentDrawerStateContext | undefined
>(undefined);

export default function CommentsDrawerStateProvider({
    children,
    dashboardType,
}: {
    children: ReactNode;
    dashboardType: ICommentDrawerStateContext["dashboardType"];
}): ReactElement {
    const { checkCanAccess } = useAuth();

    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] =
        useState<boolean>(false);
    const [timeStampToCommentOn, setTimeStamp] = useState<number | null>(null);
    const [transcript, setTranscript] =
        useState<ICommentDrawerStateContext["transcript"]>(null);

    const handleOpenCommentsDrawer: ICommentDrawerStateContext["handleOpenCommentsDrawer"] =
        useCallback((value) => {
            if (
                !checkCanAccess({
                    heading: PermissionModules.Meeting,
                    code_name: "meeting.restrict_comment_access",
                    permission_type: PermissionTypes.edit,
                })
            ) {
                return showNoPermissionToast();
            }
            setIsCommentsDrawerOpen(value);
            if (value === false) {
                setTimeStampToCommentOn(null);
            }
        }, []);
    const setTimeStampToCommentOn: ICommentDrawerStateContext["setTimeStampToCommentOn"] =
        useCallback((value) => {
            setTimeStamp(value);
        }, []);
    const setTranscriptToCommentOn: ICommentDrawerStateContext["setTranscriptToCommentOn"] =
        useCallback((value) => {
            setTranscript(value);
        }, []);
    useEffect(() => {
        return () => {
            if (isCommentsDrawerOpen) setTranscriptToCommentOn(null);
        };
    }, [isCommentsDrawerOpen]);
    return (
        <CommentsDrawerStateContext
            value={{
                handleOpenCommentsDrawer,
                setTimeStampToCommentOn,
                isCommentsDrawerOpen,
                timeStampToCommentOn,
                dashboardType,
                transcript,
                setTranscriptToCommentOn,
            }}
        >
            {children}
        </CommentsDrawerStateContext>
    );
}
