import { AudioContext, IAudioContextValue } from "@convin/contexts/AudioContex";
import { useContext } from "react";

const useAudio = (): IAudioContextValue => {
    const context = useContext(AudioContext);
    if (!context)
        throw new Error("Audio context must be use inside AudioProvider");

    return context;
};

export default useAudio;
