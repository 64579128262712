import {
    alpha,
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    Typography,
    Grid2,
} from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { CloneSvg, CloseSvg } from "@convin/components/svg";

import { IconButton } from "@mui/material";

import { Stack } from "@mui/material";
import { isDarkMode, isDefined } from "@convin/utils/helper/common.helper";
import { Fragment, useState } from "react";
import {
    AIConfigNodeType,
    GroupNodeType,
    MomentType,
    SingleResponseNode,
} from "../../types/bert";
import { useGetExistingBlocks } from "../hooks/useGetExistingBlocks";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import DeleteDialog from "@convin/components/custom_components/Dialog/DeleteDialog";
import InfoSvg from "@convin/components/svg/InfoSvg";
import SearchSvg from "@convin/components/svg/SearchSvg";
import { useReactFlowStateContextProvider } from "../hooks/useReactFlowStateContextProvider";
import { BertNodeKeys, singleNodeTypesMap } from "../constants";
import useNodeContext from "../hooks/useNodeContext";
import AttributeBlock from "./Blocks/AttributeBlock";
import NodeStatePorvider from "../context/NodeStatePorvider";
import { SingleResponseBlockType } from "../context/NodeStateContext";
import ParameterBlock from "./Blocks/ParameterBlock";
import MomentsBlock from "./Blocks/MomentsBlock";
import PredefinedFuncBlock from "./Blocks/PredefinedFuncBlock";
import ValidateInfoBlock from "./Blocks/ValidateInfoBlock";
import { Edge } from "reactflow";
import {
    StyledTabs,
    StyledTab,
} from "@convin/modules/home/analatyicsDashboard/modules/AccuracyDashboard/components/AccuracyAnalysisTabs";
import {
    useCreateMomentMutation,
    useGetMomentsQuery,
} from "@convin/redux/services/settings/auditAiConfig.service";
import useDebounce from "@convin/hooks/useDebounce";
import Skeleton from "@convin/components/custom_components/Skeleton";
import EmptyDataSvg from "@convin/components/svg/EmptyDataSvg";
import EmptyState from "@convin/components/custom_components/reuseable/EmptyState";
import { useNodeInfoContext } from "../context/NodeInfoContext";
import { LoadingButton } from "@mui/lab";
import useParameterAiConfigContext from "../hooks/useParameterAiConfigContext";

const BlockCard = ({
    data: nodeData,
}: {
    data: Pick<
        AIConfigNodeType & { momentData?: MomentType },
        "data" | "type" | "momentData"
    > & { name?: string };
}) => {
    const {
        data: { type: blockType, metadata },
        type: nodeType,
        name,
        momentData,
    } = nodeData;
    const [open, setOpen] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const { createSingleResponseNode } = useReactFlowStateContextProvider();
    const { sourceId, direction, onNodeSaveCallBack, blockParentId } =
        useNodeContext();
    const displayName = name || ("name" in metadata ? metadata.name : "-");
    const [createMoment, { isLoading: isCreatingMoment }] =
        useCreateMomentMutation();
    const handleClone = () => {
        if (
            nodeType === BertNodeKeys.singleBlockNode ||
            nodeType === BertNodeKeys.groupBlockNode ||
            nodeType === BertNodeKeys.combinationBlockNode
        ) {
            if (blockType === "moment" && isDefined(momentData)) {
                createMoment(momentData)
                    .unwrap()
                    .then((res) => {
                        createSingleResponseNode({
                            data: {
                                type: "moment",
                                is_not: false,
                                metadata: {
                                    name: "",
                                    id: res.id,
                                },
                            },
                            sourceId,
                            direction,
                            blockParentId,
                        });
                        onNodeSaveCallBack();
                    });
            } else {
                createSingleResponseNode({
                    data: nodeData.data,
                    sourceId,
                    direction,
                    blockParentId,
                });

                onNodeSaveCallBack();
            }
        }
    };
    const getEdgeLabel = (edges: Edge[], source: string) => {
        return (
            edges.find((edge) => edge.source === source)?.data.operator ||
            undefined
        );
    };

    if (
        nodeData.type === BertNodeKeys.singleBlockNode ||
        nodeData.type === BertNodeKeys.groupBlockNode ||
        nodeData.type === BertNodeKeys.combinationBlockNode
    ) {
        return (
            <>
                <Grid2
                    container
                    sx={(theme) => ({
                        mb: 1,
                        p: 1.5,
                        borderRadius: "6px",
                        bgcolor: alpha(theme.palette.textColors[999], 0.1),
                        transition: "all 0.1s ease",
                        border: "1px solid",
                        borderColor: "transparent",
                        "&:hover": {
                            bgcolor: alpha(theme.palette.primary.main, 0.1),

                            borderColor: theme.palette.primary.main,
                        },
                    })}
                    className="cursor-pointer"
                    onClick={() => {
                        setOpenDrawer(true);
                    }}
                >
                    <Grid2
                        size={{ xs: blockType !== "moment" ? 6 : 10 }}
                        component={Label}
                        colorType="333"
                        isEllipses
                        hasTooltip
                        variant="medium"
                        fontWeight={600}
                    >
                        {displayName}
                    </Grid2>
                    {blockType !== "moment" && (
                        <Grid2
                            size={{ xs: 4 }}
                            component={Typography}
                            variant="medium"
                        >
                            {nodeType === BertNodeKeys.groupBlockNode
                                ? "Group Block"
                                : nodeType === BertNodeKeys.combinationBlockNode
                                ? "Combination Block"
                                : singleNodeTypesMap[
                                      blockType as keyof typeof singleNodeTypesMap
                                  ]}
                        </Grid2>
                    )}

                    <Grid2
                        size={{ xs: 2 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                        }}
                        className="flex justify-center cursor-pointer"
                    >
                        <CloneSvg />
                    </Grid2>
                </Grid2>
                <DeleteDialog
                    open={open}
                    setOpen={setOpen}
                    onDelete={handleClone}
                    title="Do you wish to clone this block?"
                    message={
                        <div className="flex flex-col items-center justify-center gap-2">
                            <Typography
                                fontWeight={600}
                                variant="large"
                                color="primary.main"
                            >
                                {name}
                            </Typography>
                            <Typography>
                                <Box
                                    component={"span"}
                                    sx={{
                                        color: "danger",
                                        transform: "scale(1.2)",
                                        mr: 1,
                                    }}
                                >
                                    <InfoSvg />
                                </Box>
                                A clone will get created and can be edited.
                            </Typography>
                        </div>
                    }
                    okText="Clone"
                    isDanger={false}
                    isLoading={isCreatingMoment}
                />
                <Drawer
                    anchor="right"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    sx={{
                        zIndex: (theme) => theme.zIndex.modal + 10,
                        "& .MuiBackdrop-root": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                    }}
                    PaperProps={{
                        sx: {
                            width: "536px",
                            "& .block--config--back__button": {
                                display: "none",
                            },
                        },
                    }}
                >
                    <Box className="flex-shrink-0">
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p={2.5}
                            className="flex-shrink-0"
                        >
                            <Label
                                colorType="333"
                                variant="extraLarge"
                                sx={{ fontWeight: 600 }}
                            >
                                {nodeType === BertNodeKeys.groupBlockNode
                                    ? (metadata as GroupNodeType["metadata"])
                                          .name
                                    : "Clone Block"}
                            </Label>
                            <IconButton onClick={() => setOpenDrawer(false)}>
                                <CloseSvg />
                            </IconButton>
                        </Stack>
                        <Divider className="flex-shrink-0" />
                    </Box>
                    <Box
                        className="h-full overflow-scroll"
                        sx={{
                            p: 2,
                            "& button": {
                                userSelect: "none",
                                pointerEvents: "none",
                            },
                            "& .block_close_button": {
                                display: "none",
                            },
                        }}
                    >
                        {nodeType === BertNodeKeys.singleBlockNode ? (
                            <NodeStatePorvider
                                onNodeSaveCallBack={() => {}}
                                nodeDataToUpdate={
                                    (
                                        nodeData.data as SingleResponseNode<
                                            NonNullable<SingleResponseBlockType>
                                        >
                                    ).metadata
                                }
                            >
                                {blockType === "attribute" ? (
                                    <AttributeBlock showCreateButton={false} />
                                ) : (
                                    <></>
                                )}
                                {blockType === "parameter" ? (
                                    <ParameterBlock showCreateButton={false} />
                                ) : (
                                    <></>
                                )}
                                {blockType === "moment" ? (
                                    <MomentsBlock showCreateButton={false} />
                                ) : (
                                    <></>
                                )}
                                {blockType === "function" ? (
                                    <PredefinedFuncBlock
                                        showCreateButton={false}
                                    />
                                ) : (
                                    <></>
                                )}
                                {blockType === "validateInfo" ? (
                                    <ValidateInfoBlock
                                        showCreateButton={false}
                                    />
                                ) : (
                                    <></>
                                )}
                            </NodeStatePorvider>
                        ) : nodeType === BertNodeKeys.groupBlockNode ? (
                            (metadata as GroupNodeType["metadata"]).nodes.map(
                                (block) => {
                                    const edgeLabel =
                                        "edges" in metadata
                                            ? getEdgeLabel(
                                                  (
                                                      metadata as GroupNodeType["metadata"]
                                                  )?.edges ?? [],
                                                  block.id
                                              )
                                            : null;
                                    return (
                                        <Fragment key={block.id}>
                                            <BlockCard data={block} />
                                            {edgeLabel && (
                                                <div className="ml-10 relative">
                                                    <div
                                                        className={
                                                            "self-center w-px h-[60px] border border-solid border-neutral-400 border-opacity-20  mt-[-8px]"
                                                        }
                                                    />

                                                    <Chip
                                                        sx={{
                                                            bgcolor:
                                                                "primary.main",
                                                            color: "white",
                                                            transform:
                                                                "translate(-50%, 50%)",
                                                            top: 0,
                                                        }}
                                                        className="uppercase absolute"
                                                        label={edgeLabel}
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    );
                                }
                            )
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Divider
                        className="flex-shrink-0"
                        orientation="horizontal"
                    />
                    <Box
                        sx={{
                            p: 2,
                        }}
                        className="flex-shrink-0 flex flex-row-reverse gap-2"
                    >
                        <LoadingButton
                            variant="global"
                            color="primary"
                            onClick={handleClone}
                            loading={isCreatingMoment}
                        >
                            Clone
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpenDrawer(false)}
                        >
                            Back
                        </Button>
                    </Box>
                </Drawer>
            </>
        );
    }
};

export default function CloneBlockDrawerUi({
    onClose,
}: {
    onClose: () => void;
}) {
    const [query, setQuery] = useState<string>("");
    const debouncedQuery = useDebounce(query, 500);
    const { nodeType } = useNodeInfoContext();
    const [tabValue, setTabValue] = useState<"blocks" | "moments">(() =>
        nodeType === "condition_gpt" ? "moments" : "blocks"
    );
    const {
        aiConfigState: { activeDefaultBlock },
    } = useParameterAiConfigContext();
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const {
        data: existingBlocksData,
        hasNext,
        fetchNext,
        isLoading,
        isFetching,
        isFetchingNext,
    } = useGetExistingBlocks(
        {
            block_name: debouncedQuery,
            type:
                isGroupBlockTree || isDefined(activeDefaultBlock?.blockId)
                    ? BertNodeKeys.singleBlockNode
                    : undefined,
        },
        false,
        {
            skip: tabValue !== "blocks",
        }
    );

    const handleChange = (
        event: React.SyntheticEvent,
        newValue: "blocks" | "moments"
    ) => {
        setTabValue(newValue);
    };
    const { data: momentsData, isLoading: isMomentsLoading } =
        useGetMomentsQuery(undefined, {
            skip: tabValue !== "moments",
        });
    const momentNodes: Pick<
        AIConfigNodeType & { momentData?: MomentType },
        "data" | "type" | "momentData"
    >[] =
        momentsData?.moments
            ?.filter((moment) =>
                debouncedQuery
                    ? moment.name
                          .toLowerCase()
                          .includes(debouncedQuery.toLowerCase())
                    : true
            )
            .filter((moment) =>
                nodeType === "condition_gpt" ? moment.is_prompt : true
            )
            .map((moment) => ({
                data: {
                    type: "moment",
                    is_not: false,
                    metadata: {
                        name: "",
                        id: moment.id,
                    },
                },
                type: BertNodeKeys.singleBlockNode,
                name: moment.name,
                momentData: moment,
            })) ?? [];

    return (
        <div className="flex flex-col h-full w-[536px]">
            <Box className="flex-shrink-0">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2.5}
                    className="flex-shrink-0"
                >
                    <Label
                        colorType="333"
                        variant="extraLarge"
                        sx={{ fontWeight: 600 }}
                    >
                        Clone Block
                    </Label>
                    <IconButton onClick={onClose}>
                        <CloseSvg />
                    </IconButton>
                </Stack>
                <Divider className="flex-shrink-0" />
            </Box>
            <Stack
                className="flex-1 flex flex-col overflow-hidden"
                px={2.5}
                gap={1.5}
            >
                <Box
                    sx={(theme) => ({
                        background: "rgba(153, 153, 153, 0.10)",
                        p: 1,
                        borderRadius: "6px",
                        ...(!isDarkMode(theme) && {
                            border: "1px solid rgba(153, 153, 153, 0.10)",
                        }),
                        mt: 1.5,
                    })}
                    className="flex flex-shrink-0"
                >
                    <SearchSvg sx={{ mr: 1, flex: "0 1 auto" }} />
                    <Box
                        component="input"
                        placeholder="Search blocks"
                        sx={{
                            outline: "none",
                            background: "transparent",
                            fontSize: 12,
                            ml: 1,
                        }}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        className="flex-auto"
                    ></Box>
                </Box>
                <StyledTabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="analysis tabs"
                    sx={{
                        "& .MuiTabs-flexContainer": {
                            width: "100%",
                        },
                    }}
                >
                    <StyledTab
                        label="All Blocks"
                        id="analysis-tab-0"
                        aria-controls="analysis-tabpanel-0"
                        value="blocks"
                        sx={{
                            flex: 1,
                        }}
                        disabled={nodeType === "condition_gpt"}
                    />
                    <StyledTab
                        label="Moment Blocks"
                        id="analysis-tab-1"
                        aria-controls="analysis-tabpanel-1"
                        value="moments"
                        sx={{
                            flex: 1,
                        }}
                    />
                </StyledTabs>

                <Box
                    className="flex-1 flex flex-col overflow-hidden"
                    sx={{
                        background: "rgba(153, 153, 153, 0.10)",
                        p: 2.5,
                        borderRadius: "6px",
                    }}
                >
                    <Grid2
                        container
                        sx={{ mb: 1, px: 1.5, borderRadius: "6px" }}
                    >
                        <Grid2
                            size={{ xs: tabValue === "blocks" ? 6 : 10 }}
                            component={Typography}
                            variant="small"
                        >
                            {tabValue === "blocks"
                                ? "Block Name"
                                : "Moment Name"}
                        </Grid2>

                        {tabValue === "blocks" && (
                            <Grid2
                                size={{ xs: 4 }}
                                component={Typography}
                                variant="small"
                            >
                                Block Type{" "}
                            </Grid2>
                        )}

                        <Grid2
                            size={{ xs: 2 }}
                            component={Typography}
                            variant="small"
                            className="text-center"
                        >
                            Action{" "}
                        </Grid2>
                    </Grid2>
                    {tabValue === "blocks" ? (
                        (!isFetchingNext && isFetching) || isLoading ? (
                            <Skeleton rows={5} />
                        ) : existingBlocksData?.nodes?.length ? (
                            <VirtualList<AIConfigNodeType>
                                Component={BlockCard}
                                data={existingBlocksData?.nodes}
                                hasNext={hasNext}
                                fetchNext={fetchNext}
                                isLoading={isLoading}
                                isFetching={isFetching}
                                sx={{
                                    p: 0,
                                }}
                                className="flex-1"
                            />
                        ) : (
                            <EmptyState
                                Component={<EmptyDataSvg />}
                                text="No Blocks Found!"
                                subtext="It looks like there are no blocks available right now.Please change the filters as no blocks found from the applied filters."
                            />
                        )
                    ) : isMomentsLoading ? (
                        <Skeleton rows={5} />
                    ) : momentNodes.length ? (
                        <VirtualList<
                            Pick<
                                AIConfigNodeType & { momentData?: MomentType },
                                "data" | "type" | "momentData"
                            >
                        >
                            Component={BlockCard}
                            data={momentNodes}
                            hasNext={false}
                            fetchNext={() => {}}
                            isLoading={false}
                            isFetching={false}
                            sx={{
                                p: 0,
                            }}
                            className="flex-1"
                        />
                    ) : (
                        <EmptyState
                            Component={<EmptyDataSvg />}
                            text="No Moment Blocks Found!"
                            subtext="It looks like there are no moment blocks available right now."
                        />
                    )}
                </Box>
            </Stack>
        </div>
    );
}
