import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import { DownloadReport } from "@convin/type/Report";
import DownloadNotificationCard from "./DownloadNotificationCard";
import NoDownloads from "./NoDownloads";
import { useGetDownloadReportsList } from "../hooks/useGetDownloadReportsList";
import CustomSkeleton from "@convin/components/custom_components/Skeleton";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    useDeleteAllReportNotificationMutation,
    useLazyGetReportsDownloadQueQuery,
} from "@convin/redux/services/home/analyticsDashboard.service";

export default function DownloadNotificationPanel() {
    const { data, hasNext, fetchNext, isFetching, isLoading } =
        useGetDownloadReportsList({});
    const [deleteNotifications] = useDeleteAllReportNotificationMutation();
    const [refetchQue] = useLazyGetReportsDownloadQueQuery();

    if (isLoading) return <CustomSkeleton rows={5} px={1.5} />;
    return (
        <Stack className="w-full h-full overflow-auto">
            <Box className="flex-1 overflow-auto" px={2}>
                {data?.count ? (
                    <VirtualList<DownloadReport>
                        Component={DownloadNotificationCard}
                        data={data?.results ?? []}
                        hasNext={hasNext}
                        fetchNext={fetchNext}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        sx={{
                            p: 0,
                        }}
                    />
                ) : (
                    <NoDownloads />
                )}
            </Box>
            {isDefined(data?.results) && data?.results.length !== 0 && (
                <Box className="flex flex-col justify-center flex-shrink-0">
                    <Divider flexItem className="flex-1" />
                    <Typography
                        component="button"
                        variant="medium"
                        color="primary.main"
                        className="font-medium text-center"
                        p={2}
                        onClick={() => {
                            deleteNotifications()
                                .unwrap()
                                .then(() => {
                                    refetchQue({});
                                });
                        }}
                    >
                        Clear Downloads
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
