import AIInsightsTabSvg from "@convin/components/svg/CI/AIInsightsTabSvg";
import CustomTrackingTabSvg from "@convin/components/svg/CI/CustomTrackingTabSvg";
import InsightsTabSvg from "@convin/components/svg/CI/InsightsTabSvg";
import AdvancedReportsSvg from "@convin/components/svg/Dashboard/AdvancedReportSvg";
import AuditSvg from "@convin/components/svg/Dashboard/AuditSvg";
import CollectionSvg from "@convin/components/svg/Dashboard/CollectionSvg";
import CsatSvg from "@convin/components/svg/Dashboard/CsatSvg";
import LeadSvg from "@convin/components/svg/Dashboard/LeadSvg";
import OverallSvg from "@convin/components/svg/Dashboard/OverallSvg";
import ParameterSvg from "@convin/components/svg/Dashboard/ParameterSvg";
import RepSvg from "@convin/components/svg/Dashboard/RepSvg";
import ReportSvg from "@convin/components/svg/Dashboard/ReportSvg";
import TeamSvg from "@convin/components/svg/Dashboard/TeamSvg";
import ViolationSvg from "@convin/components/svg/Dashboard/ViolationSvg";
import {
    AccountNavSvg,
    AIAgentNavSvg,
    AuditReportNavSvg,
    CoachingSvg,
    ConversationListNavSvg,
    CustomerIntelligenceNavSvg,
    HomeNavSvg,
    LMSNavSvg,
    SalesAssistNavSvg,
    SettingsNavSvg,
    StatisticsNavSvg,
    SupervisorAssistNavSvg,
} from "@convin/modules/GlobalDashboardLayout/components/NavLinkSvg";
import {
    DomainConfig,
    FeatureAccess,
    MeetingTypeConst,
} from "@convin/type/Common";
import { SxProps, Theme } from "@mui/material";
import React from "react";
import { PermissionModules, PermissionTypes } from "./permissions.config";
import { CheckCanAccessParams } from "@convin/contexts/AuthContext";
import AccuracySvg from "@convin/components/svg/Dashboard/AccuracySvg";

import {
    BulkCallSvg,
    ConversationalPathwaySvg,
    SingleCallSvg,
} from "@convin/modules/aiVoiceCall/components/NavSvgIcons";

export type RouteType = {
    name: string;
    to: string;
    path: string;
    canAccessName?: string;
    Icon?: React.FC<{ sx?: SxProps<Theme> }>;
    desc?: string;
    hideLink?: boolean;
} & (
    | {
          protectionType?: never;
      }
    | {
          protectionType: "default";
          featureAccessVisibility?: never;
          permission?: never;
          domainVisibility?: never;
      }
    | {
          protectionType: "featureAccess";
          featureAccessVisibility: keyof FeatureAccess;
      }
    | {
          protectionType: "permission";
          permission: CheckCanAccessParams;
      }
    | {
          protectionType: "domainVisibility";
          domainVisibility: DomainConfig["domain_type"];
      }
    | {
          protectionType: "permission & featureAccess";
          permission: CheckCanAccessParams;
          featureAccessVisibility: keyof FeatureAccess;
      }
    | {
          protectionType: "permission & domainVisibility";
          permission: CheckCanAccessParams;
          domainVisibility: DomainConfig["domain_type"];
      }
    | {
          protectionType: "default & domainVisibility";
          domainVisibility: DomainConfig["domain_type"];
      }
);

type RouteTypeWithSubRoutes = {
    name: string;
    hasSubRoutes: boolean;
    subRoutes: { [key in Paths]?: RouteType };
};

type Paths =
    | "USER_MANAGER"
    | "ROLE_MANAGER"
    | "TOPIC_MANAGER"
    | "INTEGRATIONS"
    | "TEAM_MANAGER"
    | "AUDIT_MANAGER"
    | "SAMPLING_MANAGER"
    | "VIOLATION_MANAGER"
    | "RECORDING_MANAGER"
    | "CALL_TYPE_MANAGER"
    | "CALL_TAGS_MANAGER"
    | "AGENT_ASSIST"
    | "ENTITY"
    | "AI_SUMMARY"
    | "MANUAL_QMS"
    | "SCHEDULED_REPORTS"
    | "BILLING"
    | "SCORE_SENSE"
    | "AI_INSIGHTS"
    | "SENTIMENT_ANALYSIS"
    | "AI_DISPOSITION";

export const SettingRoutes: Record<Paths, RouteType> = {
    USER_MANAGER: {
        name: "User Manager",
        to: "user_manager",
        path: "user_manager",
        protectionType: "default",
    },
    ROLE_MANAGER: {
        name: "Role Manager",
        to: "role_manager",
        path: "role_manager",
        protectionType: "default",
    },
    TOPIC_MANAGER: {
        name: "Topic Manager",
        to: "topic_manager",
        path: "topic_manager",
        protectionType: "default",
    },
    INTEGRATIONS: {
        name: "Integrations",
        to: "integrations",
        path: "integrations",
        protectionType: "default",
    },
    TEAM_MANAGER: {
        name: "Team Manager",
        to: "team_manager",
        path: "team_manager",
        protectionType: "default",
    },
    AUDIT_MANAGER: {
        name: "Audit Manager",
        to: "audit_manager",
        path: "audit_manager",
        protectionType: "default",
    },
    SAMPLING_MANAGER: {
        name: "Sampling Manager",
        to: "sampling_manager",
        path: "sampling_manager",
        protectionType: "default",
    },
    VIOLATION_MANAGER: {
        name: "Violation Manager",
        to: "violation_manager",
        path: "violation_manager",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Audit_Manager,
            code_name: "audit.can_manage_violation",
            permission_type: PermissionTypes.edit,
        },
    },
    RECORDING_MANAGER: {
        name: "Recording Manager",
        to: "recording_manager",
        path: "recording_manager",
        protectionType: "default",
    },
    CALL_TYPE_MANAGER: {
        name: "Type Manager",
        canAccessName: "Call Type Manager",
        to: "type_manager",
        path: "type_manager",
        protectionType: "default",
    },
    CALL_TAGS_MANAGER: {
        name: "Tags Manager",
        canAccessName: "Call Tags Manager",
        to: "tags_manager",
        path: "tags_manager",
        protectionType: "default",
    },
    BILLING: {
        name: "Billing",
        to: "billing",
        path: "billing",
        protectionType: "default",
    },
    SCHEDULED_REPORTS: {
        name: "Scheduled Reports",
        to: "scheduled_reports",
        path: "scheduled_reports",
        protectionType: "default",
    },
    ENTITY: {
        name: "Entity",
        to: "entity",
        path: "entity",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.view_entity",
            permission_type: PermissionTypes.edit,
        },
    },
    AI_SUMMARY: {
        name: "AI Summary",
        to: "ai_summary",
        path: "ai_summary",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.view_custom_summary",
            permission_type: PermissionTypes.edit,
        },
    },
    MANUAL_QMS: {
        name: "Manual QMS",
        to: "qms",
        path: "qms",
        protectionType: "default",
        canAccessName: "QMS",
    },
    AGENT_ASSIST: {
        name: "Agent Assist",
        to: "agent_assist",
        path: "agent_assist",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Agent_Assist,
            code_name: "live_assist.can_access_agent_assist_settings",
            permission_type: PermissionTypes.edit,
        },
    },
    SCORE_SENSE: {
        name: "ScoreSense",
        to: "score_sense",
        path: "score_sense",
        canAccessName: "Score Sense",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.view_score_sense",
            permission_type: PermissionTypes.edit,
        },
    },
    AI_INSIGHTS: {
        name: "AI Insights",
        to: "ai_insights",
        path: "ai_insights",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.ai_feedback",
            permission_type: PermissionTypes.edit,
        },
    },
    SENTIMENT_ANALYSIS: {
        name: "Sentiment Analysis",
        to: "sentiment_analysis",
        path: "sentiment_analysis",
        protectionType: "permission & featureAccess",
        featureAccessVisibility: "gpt_sentiment_analysis",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.view_sentiment_analysis",
            permission_type: PermissionTypes.edit,
        },
    },
    AI_DISPOSITION: {
        name: "AI Disposition",
        to: "ai_disposition",
        path: "ai_disposition",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Gpt_Features,
            code_name: "gpt.view_ai_disposition",
            permission_type: PermissionTypes.edit,
        },
    },
};

export const SettingsTypeConfig: Array<RouteTypeWithSubRoutes> = [
    {
        name: "General",
        hasSubRoutes: true,
        subRoutes: {
            TEAM_MANAGER: SettingRoutes.TEAM_MANAGER,
            ROLE_MANAGER: SettingRoutes.ROLE_MANAGER,
            USER_MANAGER: SettingRoutes.USER_MANAGER,
            RECORDING_MANAGER: SettingRoutes.RECORDING_MANAGER,
        },
    },
    {
        name: "Integrations & Apps",
        hasSubRoutes: true,
        subRoutes: {
            INTEGRATIONS: SettingRoutes.INTEGRATIONS,
            AGENT_ASSIST: SettingRoutes.AGENT_ASSIST,
        },
    },
    {
        name: "Product Settings",
        hasSubRoutes: true,
        subRoutes: {
            AUDIT_MANAGER: SettingRoutes.AUDIT_MANAGER,
            SAMPLING_MANAGER: SettingRoutes.SAMPLING_MANAGER,
            VIOLATION_MANAGER: SettingRoutes.VIOLATION_MANAGER,
            CALL_TYPE_MANAGER: SettingRoutes.CALL_TYPE_MANAGER,
            CALL_TAGS_MANAGER: SettingRoutes.CALL_TAGS_MANAGER,
            TOPIC_MANAGER: SettingRoutes.TOPIC_MANAGER,
            MANUAL_QMS: SettingRoutes.MANUAL_QMS,
            SCHEDULED_REPORTS: SettingRoutes.SCHEDULED_REPORTS,
        },
    },
    {
        name: "ConvinGPT Settings",
        hasSubRoutes: true,
        subRoutes: {
            AI_DISPOSITION: SettingRoutes.AI_DISPOSITION,
            AI_SUMMARY: SettingRoutes.AI_SUMMARY,
            ENTITY: SettingRoutes.ENTITY,
            SCORE_SENSE: SettingRoutes.SCORE_SENSE,
            AI_INSIGHTS: SettingRoutes.AI_INSIGHTS,
            SENTIMENT_ANALYSIS: SettingRoutes.SENTIMENT_ANALYSIS,
        },
    },
    {
        name: "Billing",
        hasSubRoutes: false,
        subRoutes: {
            BILLING: SettingRoutes.BILLING,
        },
    },
];

export const DashboardRoutesConfig: {
    LMS: RouteType & {
        subRoutes: {
            knowledgeBase: RouteType;
            assesments: RouteType;
        };
    };
    CI: RouteType & {
        subRoutes: {
            custom_tracking: RouteType;
            insights: RouteType;
            ai_insights: RouteType;
        };
    };
    Home: RouteType & {
        subRoutes: {
            overall_dashboard: RouteType;
            team_level_dashboard: RouteType;
            multi_team_level_dashboard: RouteType;
            rep_level_dashboard: RouteType;
            parameter_dashboard: RouteType;
            violation_dashboard: RouteType;
            audit_dashboard: RouteType;
            accuracy_dashboard: RouteType;
            all_reports: RouteType;
            custom_dashboard: RouteType;
            lead_dashboard: RouteType;
            csat_dashboard: RouteType;
            collection_dashboard: RouteType;
            advanced_reports: RouteType;
        };
    };
    Coaching: RouteType;
    Account: RouteType;
    Settings: RouteType;
    Conversations: RouteType;
    SupervisorAssist: RouteType;
    SalesAssist: RouteType;
    Statistics: RouteType;
    AuditReport: RouteType;
    AIAgent: RouteType & {
        subRoutes: {
            SINGLE_CALL: RouteType;
            BULK_CALL: RouteType;
            CONVERSATIONAL_PATHWAYS: RouteType;
        };
    };
    SalesAgent: RouteType;
    ActivityLog: RouteType;
    UserProfile: RouteType;

    /* Pre Auth Routes */
    Signin: RouteType;
    Signup: RouteType;
    GenerateResetPasswordLink: RouteType;
    ResetPassword: RouteType;
    VerifyOtp: RouteType;
    InternalSignin: RouteType;
    CreateDomain: RouteType;
    FindDomain: RouteType;
    Configuration: RouteType;
    LiveConversation: RouteType;
    Share: RouteType;
} = {
    Home: {
        name: "Home",
        to: "/home",
        path: "home",
        Icon: HomeNavSvg,
        subRoutes: {
            overall_dashboard: {
                name: "Overall Dashboard",
                to: "/home/analytics",
                path: "analytics",
                desc: "Get an overview of the performance of your various teams and reps in your organisation",
                Icon: OverallSvg,
            },
            multi_team_level_dashboard: {
                name: "Multi Team Dashboard",
                to: "/home/multi_team_dashboard",
                path: "multi_team_dashboard",
                desc: "",
                hideLink: true,
                protectionType: "default",
            },
            team_level_dashboard: {
                name: "Team Level Dashboard",
                to: "/home/team_level_dashboard",
                path: "team_level_dashboard",
                desc: "Dive deep in the team level performance and get insights on top and bottom performers",
                Icon: TeamSvg,
            },
            rep_level_dashboard: {
                name: "Rep Level Dashboard",
                to: "/home/rep_level_dashboard",
                path: "rep_level_dashboard",
                desc: "Understand the performance metrics for a particular agent and take improvement steps",
                Icon: RepSvg,
            },

            parameter_dashboard: {
                name: "Parameter Dashboard",
                to: "/home/parameter_level_dashboard",
                path: "parameter_level_dashboard",
                desc: "Unlock data-driven parameter level insights and improve agent performance effortlessly",
                Icon: ParameterSvg,
            },
            violation_dashboard: {
                name: "Violation Dashboard",
                to: "/home/violation_dashboard",
                path: "violation_dashboard",
                desc: "Efficiently monitor and address compliance issues and effectively take remedial measures",
                Icon: ViolationSvg,
            },
            audit_dashboard: {
                name: "Audit Dashboard",
                to: "/home/audit_report",
                path: "audit_report",
                desc: "Analyze the Audit teams performance and get an overview of all the manual audits",
                Icon: AuditSvg,
            },
            accuracy_dashboard: {
                name: "Accuracy Dashboard",
                to: "/home/accuracy_dashboard",
                path: "accuracy_dashboard",
                desc: "Evaluate accuracy of AI scoring prediction by effectively comparing with manual audits.",
                Icon: AccuracySvg,
                protectionType: "permission & featureAccess",
                featureAccessVisibility: "accuracy_dashboard",
                permission: {
                    heading: PermissionModules.Accuracy_Dashboard,
                    code_name: "analytics.manage_accuracy_dashboard",
                    permission_type: PermissionTypes.view,
                },
            },
            all_reports: {
                name: "All Reports",
                to: "/home/reports",
                path: "reports",
                desc: "Track and analyze your data with our intuitive reporting section and take informed decisions",
                Icon: ReportSvg,
            },
            lead_dashboard: {
                name: "Lead Interest Dashboard",
                to: "/home/lead_interest_dashboard",
                path: "lead_interest_dashboard",
                desc: "Identify high-potential leads easily using our dashboard and take right business decisions",
                Icon: LeadSvg,
                protectionType: "featureAccess",
                featureAccessVisibility: "lead_dashboard",
            },
            csat_dashboard: {
                name: "CSAT Dashboard",
                to: "/home/csat_dashboard",
                path: "csat_dashboard",
                desc: "Gain rich insights into customer satisfaction helping you enhance customer experience",
                Icon: CsatSvg,
                protectionType: "featureAccess",
                featureAccessVisibility: "csat_dashboard",
            },
            collection_dashboard: {
                name: "Collection Dashboard",
                to: "/home/collection_dashboard",
                path: "collection_dashboard",
                desc: "Effortlessly manage collections and enhance financial strategies with our insightful reports",
                Icon: CollectionSvg,
                protectionType: "featureAccess",
                featureAccessVisibility: "collection_dashboard",
            },
            custom_dashboard: {
                name: "Custom Dashboard",
                to: "/home/custom",
                path: "custom",
                desc: "Create Custom Dashboard and charts as per your business requirements",
                Icon: ReportSvg,
                protectionType: "featureAccess",
                featureAccessVisibility: "custom_dashboard",
            },
            advanced_reports: {
                name: "Advanced Reports",
                to: "",
                path: "",
                desc: "Use our advanced reporting dashboard to create customized reporting for your teams",
                Icon: AdvancedReportsSvg,
                protectionType: "permission & featureAccess",
                featureAccessVisibility: "superset_accessibility",
                permission: {
                    heading: PermissionModules.Reports,
                    code_name: "analytics.can_access_advance_report",
                    permission_type: PermissionTypes.edit,
                },
            },
        },
        protectionType: "domainVisibility",
        domainVisibility: "b2c",
    },
    Coaching: {
        name: "Coaching Dashboard",
        canAccessName: "Coaching",
        to: "/coaching",
        path: "coaching",
        Icon: CoachingSvg,
        protectionType: "default & domainVisibility",
        domainVisibility: "b2c",
    },
    LMS: {
        name: "LMS",
        to: "/lms",
        path: "lms",
        canAccessName: "Library",
        Icon: LMSNavSvg,
        subRoutes: {
            knowledgeBase: {
                name: "Knowledge Base",
                to: "/lms/knowledge_base",
                path: "knowledge_base",
            },
            assesments: {
                name: "Assessments",
                to: "/lms/assessments",
                path: "assessments",
            },
        },
        protectionType: "default",
    },
    CI: {
        name: "Convin Insights",
        to: "/ci_dashboard",
        path: "ci_dashboard",
        Icon: CustomerIntelligenceNavSvg,
        canAccessName: "Convin Insights",
        protectionType: "default",
        subRoutes: {
            insights: {
                name: "Customer Intelligence",
                to: "/ci_dashboard/customer_intelligence",
                path: "customer_intelligence",
                Icon: InsightsTabSvg,
                featureAccessVisibility: "ci_dashboard",
            },
            custom_tracking: {
                name: "Custom Tracking",
                to: "/ci_dashboard/custom_tracking",
                path: "custom_tracking",
                Icon: CustomTrackingTabSvg,
                featureAccessVisibility: "customer_tracking_dashboard",
            },
            ai_insights: {
                name: "AI Insights",
                to: "/ci_dashboard/ai_insights",
                path: "ai_insights",
                Icon: AIInsightsTabSvg,
            },
        },
    },
    Account: {
        name: "Accounts",
        to: "/accounts",
        path: "accounts",
        Icon: AccountNavSvg,
        protectionType: "default",
    },
    Settings: {
        name: "Settings",
        to: "/settings",
        path: "settings",
        Icon: SettingsNavSvg,
    },
    Conversations: {
        name: "Conversations",
        to: "/calls",
        path: "calls",
        Icon: ConversationListNavSvg,
    },
    SupervisorAssist: {
        name: "Supervisor Assist",
        to: "/supervisor_assist",
        path: "supervisor_assist",
        Icon: SupervisorAssistNavSvg,
        protectionType: "permission & featureAccess",
        featureAccessVisibility: "live_assist",
        permission: {
            heading: PermissionModules.Agent_Assist,
            code_name: "live_assist.can_access_supervisor_assist",
            permission_type: PermissionTypes.edit,
        },
    },
    SalesAssist: {
        name: "Live Call",
        to: "/live_call",
        path: "live_call",
        Icon: SalesAssistNavSvg,
        featureAccessVisibility: "saleassist",
        protectionType: "featureAccess",
    },
    Statistics: {
        name: "Statistics",
        to: "/statistics",
        path: "statistics",
        Icon: StatisticsNavSvg,
        domainVisibility: "b2b",
        protectionType: "domainVisibility",
    },
    AuditReport: {
        name: "Audit Report",
        to: "/audit_report",
        path: "audit_report",
        Icon: AuditReportNavSvg,
        protectionType: "permission & domainVisibility",
        domainVisibility: "b2b",
        permission: {
            heading: PermissionModules.Audit_Manager,
            code_name: "audit.can_audit",
            permission_type: PermissionTypes.edit,
        },
    },
    SalesAgent: {
        name: "AI Agent",
        to: "/ai_agent",
        path: "ai_agent",
        Icon: AIAgentNavSvg,
        featureAccessVisibility: "sales_agent",
        protectionType: "featureAccess",
    },
    AIAgent: {
        name: "AI Agent",
        to: "/ai_voice_call",
        path: "ai_voice_call",
        Icon: AIAgentNavSvg,
        featureAccessVisibility: "ai_agent",
        canAccessName: PermissionModules.AI_Agent,
        permission: {
            heading: PermissionModules.AI_Agent,
            code_name: "voice_bot.single_call_ai_agent",
            permission_type: PermissionTypes.edit,
        },
        protectionType: "permission & featureAccess",
        subRoutes: {
            SINGLE_CALL: {
                name: "Single Call",
                to: "/ai_voice_call/single_call/",
                path: "single_call",
                Icon: SingleCallSvg,
            },
            BULK_CALL: {
                name: "Bulk Call",
                to: "/ai_voice_call/bulk_call/",
                path: "bulk_call",
                Icon: BulkCallSvg,
            },
            CONVERSATIONAL_PATHWAYS: {
                name: "Conversational Pathways",
                to: "/ai_voice_call/conversational_pathways",
                path: "conversational_pathways",
                Icon: ConversationalPathwaySvg,
            },
        },
    },
    LiveConversation: {
        name: "Live Conversation",
        to: "/live_conversation",
        path: "live_conversation",
        protectionType: "permission",
        permission: {
            heading: PermissionModules.Agent_Assist,
            code_name: "live_assist.can_access_supervisor_assist",
            permission_type: PermissionTypes.edit,
        },
    },

    /*Pre Auth Routes */
    Signin: {
        name: "Signin",
        to: "/signin",
        path: "signin",
    },
    Signup: {
        name: "Signup",
        to: "/signup",
        path: "signup",
    },
    GenerateResetPasswordLink: {
        name: "Generate Reset Password Link",
        to: "/generate_reset_password_link",
        path: "generate_reset_password_link",
    },
    ResetPassword: {
        name: "Reset Password",
        to: "/resetpassword",
        path: "resetpassword",
    },
    VerifyOtp: {
        name: "Verify OTP",
        to: "/verify_otp",
        path: "verify_otp",
    },
    CreateDomain: {
        name: "Create Domain",
        to: "/",
        path: "",
    },
    FindDomain: {
        name: "Find Domain",
        to: "/find_my_domain",
        path: "find_my_domain",
    },
    InternalSignin: {
        name: "Internal Signin",
        to: "/internal_signin",
        path: "internal_signin",
    },
    ActivityLog: {
        name: "Activity Log",
        to: "/activity_log",
        path: "activity_log",
    },
    UserProfile: {
        name: "User Profile",
        to: "/user_profile",
        path: "user_profile",
    },
    Configuration: {
        name: "Configuration",
        to: "/cofu",
        path: "cofu",
    },
    Share: {
        name: "Share",
        to: "/share",
        path: "share",
    },
};

export const conversationPathList: Array<`${NonNullable<MeetingTypeConst>}`> = [
    "call",
    "chat",
    "email",
];

export const internalRoutes: string[] = [
    `${DashboardRoutesConfig.Settings.path}/${SettingRoutes.AUDIT_MANAGER.path}/coaching`,
    `${DashboardRoutesConfig.Settings.path}/${SettingRoutes.AUDIT_MANAGER.path}/filters`,
];
