import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType } from "@convin/type/Common";
import { CrmSettings, IntegrationWebhook } from "@convin/type/integrations";

export const integrationManagerApiSlice = createApi({
    reducerPath: "integrationManagerApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getCrmSettings: builder.query<CrmSettings, void>({
            query: () => "crm/settings/",
        }),
        updateCrmSettings: builder.mutation<
            CrmSettings,
            Pick<CrmSettings, "push_new_task">
        >({
            query: ({ push_new_task }) => ({
                url: "crm/settings/",
                method: "PATCH",
                body: { push_new_task },
            }),
            async onQueryStarted(
                { push_new_task },
                { dispatch, queryFulfilled }
            ) {
                const updateResult = dispatch(
                    integrationManagerApiSlice.util.updateQueryData(
                        "getCrmSettings",
                        undefined,
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                push_new_task,
                            });
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    updateResult.undo();
                }
            },
        }),
        getWebHooks: builder.query<PaginationType<IntegrationWebhook>, void>({
            query: () => "/calendar/webhook/",
        }),
        getListOfConnectedParties: builder.query<
            Array<{
                provider: string;
                spreadsheet_id: null | string;
                spreadsheet_name: null | string;
            }>,
            void
        >({
            query: () => "/tpauth/list_connected_parties/",
        }),
        connectProvider: builder.mutation<
            | {
                  location: string;
                  method: string;
                  target: string;
              }
            | IntegrationWebhook,
            {
                provider: string;
                type: "webhook" | "tpauth";
                params?: Record<string, unknown>;
            }
        >({
            query: ({ provider, type, params }) =>
                type === "webhook"
                    ? `calendar/webhook/${provider}/init/`
                    : {
                          url: `tpauth/init_auth/${provider}/`,
                          method: "GET",
                          params,
                      },
            async onQueryStarted({ type }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    if ("resourceUri" in data && type === "webhook") {
                        dispatch(
                            integrationManagerApiSlice.util.updateQueryData(
                                "getWebHooks",
                                undefined,
                                (draft) => {
                                    return {
                                        ...draft,
                                        count: draft.count + 1,
                                        results: [...draft.results, data],
                                    };
                                }
                            )
                        );
                    } else if ("location" in data) {
                        window.open(data.location, "_blank");
                    }
                } catch {}
            },
        }),
        connectProviderViaForm: builder.mutation<
            "Done",
            { provider: string; data: Record<string, unknown> }
        >({
            query: ({ provider, data }) => ({
                url: `tpauth/get_credentials/${provider}/`,
                method: "POST",
                body: data,
            }),
            async onQueryStarted({ provider }, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        integrationManagerApiSlice.util.updateQueryData(
                            "getListOfConnectedParties",
                            undefined,
                            (draft) => {
                                return [
                                    ...draft,
                                    {
                                        provider,
                                        spreadsheet_id: null,
                                        spreadsheet_name: null,
                                    },
                                ];
                            }
                        )
                    );
                } catch {}
            },
        }),
        disconnectProvider: builder.mutation<
            void,
            { provider: string; id?: number; type: "webhook" | "tpauth" }
        >({
            query: ({ provider, id, type }) => ({
                url:
                    type === "webhook"
                        ? `/calendar/webhook/${provider}/${id}/?delete=1`
                        : `tpauth/manage/${provider}/`,
                method: "DELETE",
            }),
        }),
        getListOfCrmSheets: builder.query<
            Array<{
                spreadsheet_name: string;
                spreadsheet_id: string;
            }>,
            void
        >({
            query: () => "tpauth/list_crm_sheets/",
        }),
        updateCrmSheet: builder.mutation<
            void,
            { spreadsheet_id: string | null }
        >({
            query: (data) => ({
                url: "tpauth/update/crm_sheet/",
                method: "PATCH",
                body: data,
            }),
        }),
    }),
});

export const {
    useGetWebHooksQuery,
    useLazyGetWebHooksQuery,
    useDisconnectProviderMutation,
    useConnectProviderMutation,
    useGetListOfConnectedPartiesQuery,
    useLazyGetListOfConnectedPartiesQuery,
    useGetCrmSettingsQuery,
    useGetListOfCrmSheetsQuery,
    useUpdateCrmSheetMutation,
    useUpdateCrmSettingsMutation,
    useConnectProviderViaFormMutation,
} = integrationManagerApiSlice;
