import { SxProps, Theme, UseAutocompleteProps } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Fragment, useCallback, useMemo } from "react";

import { isDefined } from "@convin/utils/helper/common.helper";
import VirtualListOptionsForSelect from "./VirtualListOptionsForSelect";

export interface CustomAutoCompleteProps<
    T extends { id: number | string | null; label: string }
> {
    autocompleteProps: Omit<
        UseAutocompleteProps<
            T["id"],
            false,
            boolean | undefined,
            boolean | undefined
        >,
        "options"
    > &
        Partial<
            Omit<
                AutocompleteProps<
                    T["id"],
                    false,
                    boolean | undefined,
                    boolean | undefined
                >,
                "options"
            >
        > & {
            options: T[];
        };
    label: string;
    loading?: boolean;
    value?: T["id"] | null;
    setValue?: (val: T["id"] | null) => void;
    className?: string;
    limitTags?: number;
    sx?: SxProps<Theme>;
    error?: boolean;
    helperText?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
}

const GenericSingleSelect = <
    T extends { id: number | string | null; label: string }
>(
    props: CustomAutoCompleteProps<T>
) => {
    const {
        label,
        value = null,
        setValue = () => {
            return;
        },
        error = false,
        helperText = "",
        placeholder = "Search",
        autocompleteProps: { options, ...rest },
        required,
        disabled = false,
        ...other
    } = props;

    const handleOptionChange: CustomAutoCompleteProps<T>["autocompleteProps"]["onChange"] =
        (e, value) => {
            if (isDefined(value)) {
                return setValue(value);
            }
            setValue(null);
        };

    const optionsObj = useMemo(() => {
        return options.reduce((acc, item) => {
            acc[item.id ?? ""] = item;
            return acc;
        }, {} as Record<string | number, T>);
    }, [options]);

    const optionsIds = useMemo(() => {
        return options.map((e) => e.id);
    }, [options]);

    const getLabel = useCallback(
        (id: T["id"]) => optionsObj[id ?? ""]?.label || "",
        [options]
    );

    return (
        <Autocomplete
            disableClearable
            multiple={false}
            options={[...optionsIds, ...(options.length ? [""] : [])]}
            getOptionLabel={(option) => getLabel(option) || ""}
            renderOption={(props, option) => {
                return isDefined(option) && option !== "" ? (
                    <li
                        {...props}
                        key={option}
                        id={option?.toString()}
                        className={`${props.className} !py-3`}
                        title={getLabel(option)}
                    >
                        {getLabel(option)}
                    </li>
                ) : (
                    <Fragment key={-1} />
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    helperText={<>{helperText}</>}
                    required={required}
                />
            )}
            value={value}
            onChange={handleOptionChange}
            isOptionEqualToValue={(option, value) => {
                return option === value;
            }}
            disabled={disabled}
            slotProps={{
                listbox: {
                    component: VirtualListOptionsForSelect,
                },
                popper: {
                    sx: {
                        zIndex: (theme) => theme.zIndex.modal + 10,
                    },
                },
            }}
            {...rest}
            {...other}
        />
    );
};

export default GenericSingleSelect;
