import { Box, Typography } from "@mui/material";
import { useLottie } from "lottie-react";
import JSON from "@convin/assets/lottieJson/configuration.json";
import { pxToRem } from "@convin/utils/getFontValue";
import { useConfigureDomainMutation } from "@convin/redux/services/auth/auth.service";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import { useEffect } from "react";

export default function Configuration() {
    const [configureDomain, { isError }] = useConfigureDomainMutation();
    const query = useSearchQuery();
    const { View } = useLottie({
        animationData: JSON,
        loop: true,
    });

    const provider = query.get("provider");
    const code = query.get("code");

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (provider && code) {
            configureDomain({ provider, code })
                .unwrap()
                .then((res) => {
                    window.location.href = res.redirect;
                })
                .catch(() => {
                    timer = setTimeout(() => {
                        window.location.href = window.location.origin;
                    }, 1500);
                });
        } else {
            timer = setTimeout(() => {
                window.location.href = window.location.origin;
            }, 1500);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);

    return (
        <Box
            className="flex flex-col items-center justify-center w-[700px]"
            sx={{
                py: 10,
            }}
        >
            <Box
                className="relative"
                sx={{
                    "& rect": {
                        fill: "transparent",
                    },
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="232"
                    height="232"
                    viewBox="0 0 232 232"
                    fill="none"
                    className="absolute top-[-15px]"
                >
                    <path
                        d="M116 232C180.065 232 232 180.065 232 116C232 51.935 180.065 0 116 0C51.935 0 0 51.935 0 116C0 180.065 51.935 232 116 232Z"
                        fill="url(#paint0_linear_34677_4367)"
                        fillOpacity="0.5"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_34677_4367"
                            x1="116"
                            y1="-3.4059e-08"
                            x2="116"
                            y2="187"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#1A62F2" stopOpacity="0.5" />
                            <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                            />
                        </linearGradient>
                    </defs>
                </svg>
                <Box>{View}</Box>
            </Box>

            <Box className="text-center">
                <Typography
                    component="p"
                    className="font-semibold"
                    sx={{
                        color: "textColors.666",
                        fontSize: pxToRem(28),
                    }}
                >
                    {isError || (!provider && !code)
                        ? "Error while creating domain"
                        : "Creating Domain..."}
                </Typography>
                <Typography component="p">
                    {isError || (!provider && !code)
                        ? "Please do not refresh the page while we setup your domain"
                        : "Error occurred rolling back changes"}
                </Typography>
            </Box>
        </Box>
    );
}
