import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";

import {
    GlobalSearchContext,
    IGloablSearchContextState,
} from "../context/GlobalSearchContextProvider";

const useGlobalSearchContext = (): IGloablSearchContextState => {
    const context = useContext(GlobalSearchContext);
    if (!isDefined(context))
        throw new Error(
            "GlobalSearchContext must be use inside GlobalSearchContextProvider"
        );
    return context;
};

export default useGlobalSearchContext;
