import CustomSkeleton from "@convin/components/custom_components/Skeleton";
import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { useGetInsightsTypesQuery } from "@convin/redux/services/meeting/filters.service";
import {
    insightsFilterType,
    moduleOptionsType,
} from "@convin/type/Filters.model";
import {
    ciInsightsOptions,
    isDefined,
    modulesOptions,
} from "@convin/utils/helper/common.helper";
import { Typography } from "@mui/material";

const InsightFilter = () => {
    const {
        state: {
            convinInsights: { module, option_type, insights },
        },
    } = useGlobalFiltersStateContext();
    return (
        <>
            <LevelSelector module={module} label="Insights" level={0} />
            {option_type && (
                <LevelSelector
                    module={module}
                    option_type={option_type}
                    label="Level 1"
                    level={1}
                />
            )}
            {option_type && insights.level1 && (
                <LevelSelector
                    module={module}
                    option_type={option_type}
                    label="Level 2"
                    level={2}
                    parent_id={insights.level1}
                />
            )}
            {insights.level1 && insights.level2 && (
                <LevelSelector
                    module={module}
                    option_type={option_type}
                    label="Level 3"
                    level={3}
                    parent_id={insights.level2}
                />
            )}
        </>
    );
};

const CustomTrackingFilter = () => {
    const {
        state: {
            convinInsights: { module, option_type },
        },
    } = useGlobalFiltersStateContext();

    return (
        <>
            <LevelSelector module={module} label="Group" level={0} />
            {option_type && (
                <LevelSelector
                    module={module}
                    option_type={option_type}
                    label="Keywords"
                    level={1}
                />
            )}
        </>
    );
};

const LevelSelector: React.FC<{
    module: insightsFilterType["module"];
    option_type?: insightsFilterType["option_type"];
    parent_id?: number | string;
    label: string;
    level: 0 | 1 | 2 | 3;
}> = ({ module, option_type, parent_id, label, level }) => {
    const {
        updateState,
        state: { convinInsights },
    } = useGlobalFiltersStateContext();

    const { data: options, isLoading } = useGetInsightsTypesQuery(
        {
            module: module!,
            option_type: option_type ?? undefined,
            parent_id: parent_id ?? undefined,
        },
        { skip: !module, refetchOnMountOrArgChange: true }
    );

    return (
        <>
            <Typography className="font-semibold" mt={2} mb={1.5}>
                {label}
            </Typography>

            {isLoading ? (
                <CustomSkeleton rows={1} />
            ) : (
                <GenericSingleSelect<{
                    id: string | number;
                    label: string;
                }>
                    label={`Select ${label}`}
                    autocompleteProps={{
                        options:
                            module === "customer_intelligence" && level === 0
                                ? ciInsightsOptions
                                : options ?? [],
                        loading: isLoading,
                        disableClearable: false,
                    }}
                    value={
                        level === 0
                            ? convinInsights.option_type
                            : level === 1
                            ? convinInsights.insights.level1
                            : level === 2
                            ? convinInsights.insights.level2
                            : level === 3
                            ? convinInsights.insights.level3
                            : null
                    }
                    error={!!convinInsights.error}
                    helperText={convinInsights.error}
                    setValue={(e) => {
                        if (level === 0) {
                            updateState({
                                convinInsights: {
                                    ...convinInsights,
                                    option_type: e,
                                    insights: {
                                        level1: null,
                                        level2: null,
                                        level3: null,
                                    },
                                    error: undefined,
                                },
                            });
                        }
                        if (level === 1) {
                            updateState({
                                convinInsights: {
                                    ...convinInsights,
                                    insights: {
                                        level1: e,
                                        level2: null,
                                        level3: null,
                                    },
                                },
                            });
                        }
                        if (level === 2) {
                            updateState({
                                convinInsights: {
                                    ...convinInsights,
                                    insights: {
                                        ...convinInsights.insights,
                                        level2: e,
                                        level3: null,
                                    },
                                },
                            });
                        }
                        if (level === 3) {
                            updateState({
                                convinInsights: {
                                    ...convinInsights,
                                    insights: {
                                        ...convinInsights.insights,
                                        level3: e,
                                    },
                                },
                            });
                        }
                    }}
                    className="flex-1"
                />
            )}
        </>
    );
};

const ConvinInsightsFilter = () => {
    const { data: domainConfig } = useGetDomainConfigQuery();
    const isAiInsightsDashboard =
        domainConfig?.feature_access?.ai_insights_dashboard ?? true;
    const isCustomTrackingDashboard =
        domainConfig?.feature_access?.customer_tracking_dashboard ?? true;
    const isCustomerIntelligenceDashboard =
        domainConfig?.feature_access?.ci_dashboard ?? true;
    const filteredModuleOptions = modulesOptions.filter((option) => {
        switch (option.id) {
            case "customer_intelligence":
                return isCustomerIntelligenceDashboard;
            case "ai_insights":
                return isAiInsightsDashboard;
            case "custom_tracking":
                return isCustomTrackingDashboard;
            default:
                return false;
        }
    });
    const {
        state: {
            convinInsights: { module },
        },
        updateState,
    } = useGlobalFiltersStateContext();

    return (
        <>
            <GenericSingleSelect<moduleOptionsType>
                label="Select Module"
                autocompleteProps={{
                    options: filteredModuleOptions,
                    disableClearable: false,
                }}
                value={module}
                setValue={(e) => {
                    updateState({
                        convinInsights: {
                            module: e,
                            option_type: null,
                            insights: {
                                level1: null,
                                level2: null,
                                level3: null,
                            },
                        },
                    });
                }}
                className="flex-1"
            />

            {isDefined(module) ? (
                module === "custom_tracking" ? (
                    <CustomTrackingFilter />
                ) : (
                    <InsightFilter />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default ConvinInsightsFilter;
