import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, {
    AutocompleteProps,
    createFilterOptions,
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
    Chip,
    ChipOwnProps,
    CircularProgress,
    SxProps,
    Theme,
    Tooltip,
    UseAutocompleteProps,
} from "@mui/material";
import VirtualListOptionsForSelect, {
    VirtualListOptionsForSelectPaginated,
} from "./VirtualListOptionsForSelect";
import { useMemo } from "react";
import { Box } from "@mui/system";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type CustomAutoCompleteProps<
    T extends { id: number | string; label: string }
> = {
    autocompleteProps: UseAutocompleteProps<
        T,
        true,
        boolean | undefined,
        boolean | undefined
    > &
        Partial<
            Omit<
                AutocompleteProps<
                    T,
                    true,
                    boolean | undefined,
                    boolean | undefined
                >,
                "options"
            >
        > & {
            options: T[];
            renderOption?: (
                props: React.HTMLProps<HTMLLIElement>,
                option: T
            ) => React.ReactNode;
        };
    label: string;
    loading?: boolean;
    values: T["id"][];
    setValues?: (val: T["id"][]) => void;
    className?: string;
    limitTags?: number;
    sx?: SxProps<Theme>;
    error?: boolean;
    helperText?: string;

    placeholder?: string;
    chipSize?: ChipOwnProps["size"];
    hideSelectAll?: boolean;
} & (
    | { isPaginated?: false; setQuery?: never }
    | { isPaginated: true; setQuery: (val: string) => void }
);

const GenericMultipleSelect = <
    T extends { id: number | string; label: string }
>(
    props: CustomAutoCompleteProps<T>
) => {
    const {
        label,
        values = [],
        setValues = () => {
            return;
        },
        error = false,
        helperText = "",
        placeholder = "Search",
        autocompleteProps: { options, renderOption, limitTags = 20, ...rest },
        chipSize,
        loading,
        isPaginated,
        setQuery,
        hideSelectAll,
        ...other
    } = props;

    const valueSet = new Set(values);
    const selectedValues = useMemo(() => {
        return options.filter((option) => valueSet.has(option.id));
    }, [options, values]);

    return (
        <Autocomplete
            multiple
            freeSolo
            disableListWrap
            id="checkboxes-tags-demo"
            disableCloseOnSelect
            options={options}
            getOptionLabel={(option) => (option as T).label}
            filterOptions={(options, params) => {
                if (isPaginated) {
                    setQuery?.(params.inputValue);
                }
                // <<<--- inject the Select All option
                const filter = createFilterOptions<T>();
                const filtered = filter(options, params) as T[];
                return [
                    !!options.length &&
                        !hideSelectAll && {
                            label: "Select All",
                            id: -1,
                            all: true,
                        },
                    ...filtered,
                ].filter(Boolean) as T[];
            }}
            renderOption={
                renderOption ||
                ((props, option) => {
                    return (
                        <li
                            {...props}
                            {...((valueSet.has(option.id) ||
                                values.length === options.length) && {
                                "aria-selected": true,
                            })}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (option.id === -1) {
                                    if (values.length === options.length) {
                                        setValues([]);
                                    } else {
                                        setValues(options.map((e) => e.id));
                                    }
                                    return;
                                }
                                const valueSet = new Set(values);
                                if (valueSet.has(option.id)) {
                                    valueSet.delete(option.id);
                                    setValues(Array.from(valueSet));
                                } else {
                                    valueSet.add(option.id);
                                    setValues(Array.from(valueSet));
                                }
                            }}
                            key={option?.id}
                            id={option?.id?.toString()}
                            data-value={option.label}
                            title={option.label}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                    option?.id === -1
                                        ? values.length === options.length
                                        : valueSet.has(option.id)
                                }
                            />
                            {option.label}
                        </li>
                    );
                })
            }
            renderInput={(params) => {
                const { InputProps, ...rest } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                    <TextField
                        {...rest}
                        label={label}
                        placeholder={placeholder}
                        slotProps={{
                            input: {
                                ...restInputProps,
                                startAdornment: (
                                    <div className="overflow-auto max-h-20">
                                        {startAdornment}
                                    </div>
                                ),
                                ...(loading && {
                                    endAdornment: (
                                        <div className="max-h-20">
                                            <CircularProgress size={18} />
                                        </div>
                                    ),
                                }),
                            },
                        }}
                        error={error}
                        helperText={<>{helperText}</>}
                    />
                );
            }}
            value={selectedValues.slice(0, limitTags)}
            renderTags={(_, getTagProps) => {
                const numTags = selectedValues.length;
                return (
                    <>
                        {selectedValues
                            .slice(0, limitTags)
                            .map((option, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    onDelete={() => {
                                        const valueSet = new Set(values);
                                        valueSet.delete(option.id);
                                        setValues(Array.from(valueSet));
                                    }}
                                    key={index}
                                    label={
                                        <Tooltip title={option.label}>
                                            <Box component="span">
                                                {option.label}
                                            </Box>
                                        </Tooltip>
                                    }
                                    size={chipSize ?? "medium"}
                                />
                            ))}
                        {numTags > limitTags &&
                            Array.isArray(selectedValues) && (
                                <Tooltip
                                    title={
                                        <Box className="max-h-[300px] overflow-scroll">
                                            {selectedValues
                                                ?.slice(limitTags)
                                                ?.map((e) => e?.label ?? "")
                                                ?.join(",")}
                                        </Box>
                                    }
                                >
                                    <Box component="span">
                                        {`+${numTags - limitTags}`}
                                    </Box>
                                </Tooltip>
                            )}
                    </>
                );
            }}
            onChange={(_e, _options, reason) => {
                if (reason === "clear") {
                    setValues([]);
                }
            }}
            slotProps={{
                listbox: {
                    component: isPaginated
                        ? VirtualListOptionsForSelectPaginated
                        : VirtualListOptionsForSelect,
                },
                popper: {
                    sx: {
                        zIndex: (theme) => theme.zIndex.modal + 10,
                    },
                },
            }}
            {...rest}
            {...other}
        />
    );
};

export default GenericMultipleSelect;
