import PageLoader from "@convin/components/custom_components/PageLoader";
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import {
    DashboardRoutesConfig,
    SettingsTypeConfig,
} from "@convin/config/routes.config";
import {
    useGetAccessRefreshTokenMutation,
    useLoginMutation,
    useVerifyOtpMutation,
    useLogoutMutation,
} from "@convin/redux/services/auth/auth.service";
import { useLazyGetAuthUserQuery } from "@convin/redux/services/auth/person.service";
import { AuthUserType } from "@convin/type/User";
import { getDomain, isDefined } from "@convin/utils/helper/common.helper";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { usePostHog } from "posthog-js/react";
import {
    PropsWithChildren,
    ReactElement,
    useCallback,
    useEffect,
    useReducer,
} from "react";
import { matchPath, useNavigate } from "react-router-dom";
import AuthContext, {
    IAuthContextInterface,
    IAuthContextState,
} from "./AuthContext";

export type CheckCanAccessParams = {
    heading: PermissionModules;
    code_name?: string;
    permission_type?: PermissionTypes;
};

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    isAuditor: false,
    isLoginRequestInProgress: false,
    isLogoutRequestInProgress: false,
    isAuthenticateRequestInProgress: false,
};

enum ActionKind {
    "LOGIN",
    "LOGOUT",
    "INITIALIZE",
    "UPDATE_USER",
}

const reducer = (
    state: IAuthContextState,
    action: {
        type: keyof typeof ActionKind;
        payload: Partial<IAuthContextState>;
    }
): IAuthContextState => {
    const { type, payload } = action;

    switch (type) {
        case "INITIALIZE": {
            const { isAuthenticated, user } = payload;
            return {
                ...state,
                isAuthenticated: isAuthenticated ?? false,
                isInitialized: true,
                user,
            };
        }
        case "LOGIN": {
            const { user } = payload;
            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        case "LOGOUT":
            return {
                ...state,
                ...payload,
            };
        case "UPDATE_USER":
            return {
                ...state,
                user: { ...state.user, ...payload.user } as AuthUserType,
            };
        default:
            return { ...state };
    }
};

export default function AuthProvider({
    children,
}: PropsWithChildren): ReactElement {
    const { data: versionData } = useGetDomainConfigQuery();

    const [state, setState] = useReducer(reducer, initialState);
    const [login, { isLoading: isLoginRequestInProgress }] = useLoginMutation();
    const [verifyOtp, { isLoading: isVerifyOtpRequestInProgress }] =
        useVerifyOtpMutation();
    const [getAccessRefreshToken, { isLoading: isLoadingTokens }] =
        useGetAccessRefreshTokenMutation();
    const [getAuthUser, { isLoading: isAuthenticateRequestInProgress }] =
        useLazyGetAuthUserQuery();
    const [logout, { isLoading: isLogoutRequestInProgress }] =
        useLogoutMutation();
    const posthog = usePostHog();
    const navigate = useNavigate();

    const match = matchPath<"dashboard", string>(
        ":dashboard/",
        location.pathname
    );

    const dashboard = match?.params.dashboard;
    const isSignInRoute = dashboard === DashboardRoutesConfig["Signin"].path;

    const initialize = useCallback(async (): Promise<void> => {
        try {
            const at = window.localStorage.getItem("at") as string | undefined;
            if (isDefined(at) && !import.meta.env.PROD) {
                getAccessRefreshToken({
                    jwt_token: at,
                })
                    .unwrap()
                    .then((res) => {
                        if (isDefined(res)) {
                            handleAuthentication(res.access);
                            window.localStorage.removeItem("at");
                            window.localStorage.setItem(
                                "authTokens",
                                JSON.stringify(res)
                            );
                        }
                    })
                    .catch(() => {
                        window.localStorage.removeItem("at");
                        navigate(DashboardRoutesConfig["Signin"].to);
                    });
                return;
            }

            const tokensString = window.localStorage.getItem("authTokens") as
                | string
                | undefined;

            const tokens = isDefined(tokensString)
                ? (JSON.parse(tokensString) as { access: string })
                : undefined;

            if (tokens?.access) {
                handleAuthentication(tokens.access);
            } else {
                setState({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            setState({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, []);

    const handleAuthentication = async (access: string) => {
        try {
            if (access) {
                const user = await getAuthUser(
                    undefined as void,
                    true
                ).unwrap();
                if (!user?.id) {
                    throw new Error("Invalid User");
                }
                setState({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                });
                if (isDefined(user) && user.extra_details?.is_internal) {
                    window.localStorage.setItem("is_internal", "true");
                }
            } else {
                setState({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            navigate(DashboardRoutesConfig["Signin"].to);
            setState({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    };

    const handleLogin: IAuthContextInterface["handleLogin"] = async (
        credentials
    ) => {
        try {
            const res = await login(credentials).unwrap();
            window.localStorage.setItem("domain", JSON.stringify(getDomain()));
            if (isDefined(res)) {
                window.localStorage.setItem("authTokens", JSON.stringify(res));
                initialize();
            } else {
                if ("rawEmail" in credentials)
                    navigate({
                        pathname: DashboardRoutesConfig["VerifyOtp"].path,
                        search: `?email=${credentials.rawEmail}&set_expiry=true`,
                    });
            }
        } catch (err) {
            console.error(err);
            if (err.status === 302) {
                navigate(err.data.redirect_url);
                return;
            }
            setState({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
            navigate(DashboardRoutesConfig["Signin"].to);
            return new Promise((_, rej) => rej(err));
        }
    };

    const handleVerifyOtp: IAuthContextInterface["handleVerifyOtp"] = async (
        email: string,
        otp: string
    ) => {
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("otp", otp);
            const res = await verifyOtp(formData).unwrap();
            window.localStorage.setItem("authTokens", JSON.stringify(res));
            initialize();
        } catch (err) {
            console.error(err);
            return new Promise((_, rej) => rej(err));
        }
    };

    const handleLogout = async (): Promise<void> => {
        setState({
            type: "LOGOUT",
            payload: {
                isAuthenticated: false,
                user: null,
                isInitialized: false,
            },
        });
        const authTokens = window.localStorage.getItem("authTokens");
        if (isDefined(authTokens)) {
            window.localStorage.removeItem("authTokens");
            window.localStorage.removeItem("is_internal");
            const parsedTokens = JSON.parse(authTokens) as { access: string };
            const token = parsedTokens.access;
            try {
                await logout(token).unwrap();
                posthog.reset();
                navigate(DashboardRoutesConfig.Signin.to);
            } catch (err) {
                console.error(err);
                navigate("/");
            }
        }
    };

    const updateUser: IAuthContextInterface["updateUser"] = (
        user: AuthUserType
    ) => {
        setState({
            type: "UPDATE_USER",
            payload: { user },
        });
    };

    const checkIsOwner: IAuthContextInterface["checkIsOwner"] = useCallback(
        (id) => {
            return isDefined(state.user?.id) && state.user?.id === id;
        },
        [state.user]
    );

    /**
     * @param {*} heading
     * @param {*} code_name optional
     * @param {*} permission_type optional
     * @return {*} Boolean value
     * @description Based on the heading, code name and permission
     * finds weather the permission exists in role field of the loged in user object
     * @author Mahen
     */
    const checkCanAccess: IAuthContextInterface["checkCanAccess"] = ({
        heading,
        code_name,
        permission_type,
    }) => {
        const auth = state.user;
        const permissionsObj =
            auth?.role?.code_names?.find((e) => e.heading === heading)
                ?.permissions || {};
        if (permission_type === "view") {
            const viewable_permissions = Object.keys(permissionsObj || {}).map(
                (key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.view;
                }
            );
            return !!viewable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "edit") {
            const editable_permissions = Object.keys(permissionsObj || {})
                .map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.edit;
                })
                .flat();

            return !!editable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "delete") {
            const deleteable_permissions = Object.keys(permissionsObj || {})
                .map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.delete;
                })
                .flat();

            return !!deleteable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }

        return !!auth?.role?.code_names?.find((e) => e.heading === heading)
            ?.is_visible;
    };

    const isAuditor = checkCanAccess({
        heading: PermissionModules.Audit_Manager,
        code_name: "audit.can_audit",
        permission_type: PermissionTypes.edit,
    });

    //SSO LOGIN HANDLER
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        //provider,code,url,...etc params present in url make the request to login endpoint
        if ("access" in params && "refresh" in params) {
            window.localStorage.setItem(
                "authTokens",
                JSON.stringify({
                    access: params.access,
                    refresh: params.refresh,
                })
            );
            initialize();
        } else if (Object.keys(params).length > 0 && isSignInRoute) {
            handleLogin({
                params,
            });
        } else initialize();
    }, []);

    if (
        (isAuthenticateRequestInProgress ||
            isLoadingTokens ||
            isLogoutRequestInProgress) &&
        state.isInitialized === false
    ) {
        return <PageLoader />;
    }

    const checkDashboardVisibility: IAuthContextInterface["checkDashboardVisibility"] =
        (dashboard) => {
            const { canAccessName, name, protectionType } = dashboard;
            if (name === DashboardRoutesConfig.Settings.name) {
                const hasAtLeastOneSubRoute = (
                    settingType: (typeof SettingsTypeConfig)[0]
                ): boolean => {
                    return (
                        Object.values(settingType.subRoutes).filter((e) =>
                            checkDashboardVisibility(e)
                        ).length > 0
                    );
                };

                const canAccessSettings = SettingsTypeConfig.map(
                    (settingType) => {
                        if (!settingType.hasSubRoutes) {
                            const route = Object.values(
                                settingType.subRoutes
                            )[0];
                            const canAccess = checkDashboardVisibility(route);
                            return canAccess;
                        }
                        return hasAtLeastOneSubRoute(settingType);
                    }
                ).filter((e) => e);

                return canAccessSettings.length > 0;
            }
            if (!isDefined(protectionType)) {
                return true;
            }

            let featureFlag = true;
            let permissionFlag = true;
            let domainVisibilityFlag = true;

            if (
                protectionType === "domainVisibility" ||
                protectionType === "permission & domainVisibility"
            ) {
                domainVisibilityFlag =
                    dashboard.domainVisibility === versionData?.domain_type;
            }

            if (
                protectionType === "permission & featureAccess" ||
                protectionType === "featureAccess"
            ) {
                const { featureAccessVisibility } = dashboard;
                const featureAccess =
                    versionData?.feature_access[featureAccessVisibility];
                if (isDefined(featureAccess)) {
                    if (typeof featureAccess === "boolean")
                        featureFlag = featureAccess;
                    else featureFlag = !!featureAccess?.visibility;
                } else {
                    featureFlag = false;
                }
            }

            if (
                protectionType === "permission & domainVisibility" ||
                protectionType === "permission & featureAccess" ||
                protectionType === "permission"
            ) {
                const { permission } = dashboard;
                if (isDefined(permission)) {
                    permissionFlag = checkCanAccess(permission);
                }
            }

            if (protectionType === "permission & featureAccess") {
                return featureFlag && permissionFlag;
            }

            if (protectionType === "permission & domainVisibility") {
                return permissionFlag && domainVisibilityFlag;
            }

            if (protectionType === "default & domainVisibility") {
                return (
                    domainVisibilityFlag &&
                    checkCanAccess({
                        heading: (canAccessName ?? name) as PermissionModules,
                    })
                );
            }

            if (protectionType === "permission") {
                return permissionFlag;
            }

            if (protectionType === "featureAccess") {
                return featureFlag;
            }

            if (protectionType === "domainVisibility") {
                return domainVisibilityFlag;
            }

            return checkCanAccess({
                heading: (canAccessName ?? name) as PermissionModules,
            });
        };

    return (
        <AuthContext
            value={{
                ...state,
                checkIsOwner,
                isAuditor,
                checkCanAccess,
                handleLogin,
                handleLogout,
                isLoginRequestInProgress,
                isLogoutRequestInProgress,
                isAuthenticateRequestInProgress,
                initialize,
                checkDashboardVisibility,
                handleVerifyOtp,
                isVerifyOtpRequestInProgress,
                updateUser,
            }}
        >
            {children}
        </AuthContext>
    );
}
