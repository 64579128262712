import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function RecordVideoSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 14 10"
            sx={{ width: 14, height: 10, fill: "none", ...sx }}
        >
            <path
                d="M10.8889 3.5V0.777778C10.8889 0.35 10.5389 0 10.1111 0H0.777778C0.35 0 0 0.35 0 0.777778V8.55556C0 8.98333 0.35 9.33333 0.777778 9.33333H10.1111C10.5389 9.33333 10.8889 8.98333 10.8889 8.55556V5.83333L14 8.94445V0.388889L10.8889 3.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
