import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function VideoTypeSvg({
    sx,
    noBorder,
}: {
    sx?: SxProps<Theme>;
    noBorder?: boolean;
}) {
    return (
        <SvgIcon
            {...(noBorder
                ? {
                      viewBox: "0 0 24 24",
                      sx: { width: 24, height: 24, fill: "none", ...sx },
                  }
                : {
                      viewBox: "0 0 30 30",
                      sx: { width: 30, height: 30, fill: "none", ...sx },
                  })}
        >
            {noBorder ? (
                <path
                    d="M6 9.41667C6 8.77573 6.25461 8.16104 6.70783 7.70783C7.16104 7.25461 7.77573 7 8.41667 7H15.1833C15.8243 7 16.439 7.25461 16.8922 7.70783C17.3454 8.16104 17.6 8.77573 17.6 9.41667V14.25C17.6 14.8909 17.3454 15.5056 16.8922 15.9588C16.439 16.4121 15.8243 16.6667 15.1833 16.6667H8.41667C7.77573 16.6667 7.16104 16.4121 6.70783 15.9588C6.25461 15.5056 6 14.8909 6 14.25V9.41667ZM10.35 9.726V13.9407C10.35 14.1823 10.6149 14.3302 10.8208 14.2046L13.9711 12.279C14.0475 12.2324 14.1107 12.1669 14.1545 12.0889C14.1983 12.0108 14.2213 11.9228 14.2213 11.8333C14.2213 11.7438 14.1983 11.6558 14.1545 11.5778C14.1107 11.4998 14.0475 11.4343 13.9711 11.3877L10.8198 9.46307C10.7729 9.43463 10.7193 9.41915 10.6645 9.41823C10.6097 9.41731 10.5556 9.43098 10.5078 9.45783C10.46 9.48468 10.4202 9.52375 10.3925 9.57104C10.3648 9.61833 10.3501 9.67214 10.35 9.72697V9.726Z"
                    fill="#E35D5C"
                />
            ) : (
                <>
                    <rect width="30" height="30" rx="6" fill="#F7CECE" />
                    <path
                        d="M8.25 12.1875C8.25 11.4416 8.54632 10.7262 9.07376 10.1988C9.60121 9.67132 10.3166 9.375 11.0625 9.375H18.9375C19.6834 9.375 20.3988 9.67132 20.9262 10.1988C21.4537 10.7262 21.75 11.4416 21.75 12.1875V17.8125C21.75 18.5584 21.4537 19.2738 20.9262 19.8012C20.3988 20.3287 19.6834 20.625 18.9375 20.625H11.0625C10.3166 20.625 9.60121 20.3287 9.07376 19.8012C8.54632 19.2738 8.25 18.5584 8.25 17.8125V12.1875ZM13.3125 12.5475V17.4525C13.3125 17.7338 13.6207 17.9059 13.8604 17.7596L17.5268 15.5186C17.6157 15.4644 17.6891 15.3882 17.7401 15.2974C17.7911 15.2066 17.8179 15.1042 17.8179 15C17.8179 14.8958 17.7911 14.7934 17.7401 14.7026C17.6891 14.6118 17.6157 14.5356 17.5268 14.4814L13.8593 12.2415C13.8047 12.2084 13.7423 12.1904 13.6785 12.1893C13.6148 12.1883 13.5518 12.2042 13.4962 12.2354C13.4406 12.2667 13.3942 12.3121 13.362 12.3672C13.3297 12.4222 13.3126 12.4848 13.3125 12.5486V12.5475Z"
                        fill="#E35D5C"
                    />
                </>
            )}
        </SvgIcon>
    );
}
