import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const UploadProgressSvg = ({ sx, ...rest }: SvgIconProps) => {
    const {
        palette: {
            primary: { main },
        },
    } = useTheme();
    return (
        <SvgIcon
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: 14, height: 14, fill: "none", ...sx }}
            {...rest}
        >
            <path
                d="M6.16666 10.3333V3.54166L3.99999 5.70833L2.83333 4.49999L6.99999 0.333328L11.1667 4.49999L10 5.70833L7.83333 3.54166V10.3333H6.16666ZM1.99999 13.6667C1.54166 13.6667 1.1493 13.5035 0.822912 13.1771C0.496523 12.8507 0.333328 12.4583 0.333328 12V9.49999H1.99999V12H12V9.49999H13.6667V12C13.6667 12.4583 13.5035 12.8507 13.1771 13.1771C12.8507 13.5035 12.4583 13.6667 12 13.6667H1.99999Z"
                fill={main}
            />
        </SvgIcon>
    );
};

export default UploadProgressSvg;
