import { useEffect } from "react";
import useAuth from "./useAuth";
import { getDomain, getUserName } from "@convin/utils/helper/common.helper";

/**
 * This hook is used to get the Gist widget with the logged in user
 */
export default function useGetGist() {
    const { user } = useAuth();

    useEffect(() => {
        window.gist?.identify(`user_${user!.id}_${getDomain()}`, {
            email: user!.email,
            name: getUserName(user),
            subdomain: getDomain(),
            role: user!.role?.name,
            role_id: user!.role?.id,
            team_id: user!.team,
            team_name: user!.team_name,
        });
    }, [user]);
}
