import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function CombinationNodeSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            sx={{ width: 32, height: 32, fill: "none", ...sx }}
        >
            <rect
                x="1"
                y="1"
                width="30"
                height="30"
                rx="6"
                fill="#77BE04"
                stroke="#77BE04"
            />
            <g clipPath="url(#clip0_1174_31750)">
                <g mask="url(#mask0_1174_31750)">
                    <path
                        d="M15.401 25.5547L14.2344 24.3672L15.7552 22.8464H12.9635C12.783 23.3325 12.4809 23.7318 12.0573 24.0443C11.6337 24.3568 11.151 24.513 10.6094 24.513C9.91493 24.513 9.32465 24.27 8.83854 23.7839C8.35243 23.2977 8.10938 22.7075 8.10938 22.013C8.10938 21.4714 8.26562 20.9887 8.57812 20.5651C8.89062 20.1415 9.28993 19.8394 9.77604 19.6589V12.7005C9.28993 12.52 8.89062 12.2179 8.57812 11.7943C8.26562 11.3707 8.10938 10.888 8.10938 10.3464C8.10938 9.65191 8.35243 9.06163 8.83854 8.57552C9.32465 8.08941 9.91493 7.84635 10.6094 7.84635C11.151 7.84635 11.6337 8.0026 12.0573 8.3151C12.4809 8.6276 12.783 9.02691 12.9635 9.51302H15.7552L14.2344 7.99219L15.401 6.80469L18.9427 10.3464L15.401 13.888L14.2344 12.7005L15.7552 11.1797H12.9635C12.8385 11.5408 12.6441 11.8533 12.3802 12.1172C12.1163 12.3811 11.8038 12.5755 11.4427 12.7005V19.6589C11.8038 19.7839 12.1163 19.9783 12.3802 20.2422C12.6441 20.5061 12.8385 20.8186 12.9635 21.1797H15.7552L14.2344 19.6589L15.401 18.4714L18.9427 22.013L15.401 25.5547ZM22.276 24.513C21.5816 24.513 20.9913 24.27 20.5052 23.7839C20.0191 23.2977 19.776 22.7075 19.776 22.013C19.776 21.4575 19.9323 20.9679 20.2448 20.5443C20.5573 20.1207 20.9566 19.8255 21.4427 19.6589V12.7005C20.9566 12.5339 20.5573 12.2387 20.2448 11.8151C19.9323 11.3915 19.776 10.9019 19.776 10.3464C19.776 9.65191 20.0191 9.06163 20.5052 8.57552C20.9913 8.08941 21.5816 7.84635 22.276 7.84635C22.9705 7.84635 23.5608 8.08941 24.0469 8.57552C24.533 9.06163 24.776 9.65191 24.776 10.3464C24.776 10.9019 24.6198 11.3915 24.3073 11.8151C23.9948 12.2387 23.5955 12.5339 23.1094 12.7005V19.6589C23.5955 19.8394 23.9948 20.1415 24.3073 20.5651C24.6198 20.9887 24.776 21.4714 24.776 22.013C24.776 22.7075 24.533 23.2977 24.0469 23.7839C23.5608 24.27 22.9705 24.513 22.276 24.513Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1174_31750">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(6 6)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
