import { useState } from "react";
import {
    Button,
    DialogContent,
    Dialog,
    Divider,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { CloseSvg } from "@convin/components/svg";
import { matchPath, useLocation } from "react-router-dom";
import LearnMoreSvg from "@convin/components/svg/LearnMoreSvg";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    DashboardRoutesConfig,
    SettingRoutes,
} from "@convin/config/routes.config";

const { CI } = DashboardRoutesConfig;

type moduleKeys =
    | "create_template"
    | "create_category_parameter"
    | "account_level_configuration";
const record: Record<
    string | moduleKeys,
    {
        link: string;
        label: string;
    }
> = {
    [`${CI.subRoutes.ai_insights.path}`]: {
        link: "https://drive.google.com/file/d/1Y3LmqUOmnsQoTTfxlvTIo16jQObtxRSS/preview",
        label: "AI Insights",
    },
    [`${CI.subRoutes.insights.path}`]: {
        link: "https://drive.google.com/file/d/1mGttrowD_zS0_DuktrL2-6l4rMR8fBkR/preview",
        label: "Customer Intelligence",
    },
    [`${CI.subRoutes.custom_tracking.path}`]: {
        link: "https://drive.google.com/file/d/1obIlPnw9O2PfSOHIq13NzfGUuU0sVCYr/preview",
        label: "Custom Tracking",
    },
    [`${SettingRoutes["AUDIT_MANAGER"].path}`]: {
        link: "https://drive.google.com/file/d/1Yog3L5J11jSTG1jJyXdHxN5ba78Cab5n/preview",
        label: "Audit Manager",
    },
    [`${SettingRoutes["VIOLATION_MANAGER"].path}`]: {
        link: "https://drive.google.com/file/d/1pr5G1a7LhExuagrN7nIE-iTwNY1i4Cgi/preview",
        label: "Violation Manager",
    },
    create_template: {
        link: "https://drive.google.com/file/d/1MnozeT11JnqB8jPYmqi867QmTF2gHWo2/preview",
        label: "Create Template",
    },
    create_category_parameter: {
        link: "https://drive.google.com/file/d/1GkNZuMBNGJk3x6MLLOWFIaLAdvc94PsY/preview",
        label: "Create Category and Parameter",
    },
    account_level_configuration: {
        link: "https://drive.google.com/file/d/1QjZqdM5OFKNNoGDYW953RL1I5-cu5fO2/preview",
        label: "Account Level Configuration",
    },
    // [`${Account.path}`]: {
    //     link: "https://drive.google.com/file/d/17Ghb8olCe3zGlBcf-G7mYsD9Y6rirjGv/preview",
    //     label: "Accounts Dashboard",
    // },
    // [`${Home.subRoutes.lead_dashboard.path}`]: {
    //     link: "https://drive.google.com/file/d/1HAXQ_hBKDY7cbppI-FvPMUX2oSXYAN3n/preview",
    //     label: "Lead Interest Dashboard",
    // },
    // [`${Conversations.path}`]: {
    //     link: "https://drive.google.com/file/d/1MgAa8_VsqcM6Yl-qSdtwrQopw3g_iWrl/preview",
    //     label: "Conversations Dashboard",
    // },
};

export const LearnMore: React.FC<{
    module?: moduleKeys;
}> = ({ module }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { pathname } = useLocation();
    const route = matchPath<"dashboard_type", string>(
        "/:dashboard_type/*",
        pathname
    );
    const subRoute = matchPath<"subroute", string>(
        "/:dashboard_type/:subroute/*",
        pathname
    );
    if (
        !isDefined(route?.params?.dashboard_type) &&
        !isDefined(subRoute?.params?.subroute) &&
        !isDefined(module)
    )
        return null;

    const object = isDefined(module)
        ? record[module as keyof typeof record]
        : isDefined(subRoute?.params?.subroute)
        ? record[subRoute!.params.subroute as keyof typeof record]
        : isDefined(route?.params?.dashboard_type)
        ? record[route!.params.dashboard_type as keyof typeof record]
        : null;
    if (!object) return null;
    return (
        <>
            <Button
                type="button"
                variant="text"
                startIcon={<LearnMoreSvg />}
                onClick={() => setOpen(true)}
                color="primary"
            >
                Learn More
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "12px",
                        width: "700px",
                        maxWidth: "700px",
                    },
                    "& .MuiDialogContent-root": {
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    },
                    "& .react-player-container": {
                        width: "100%",
                        height: "100%",
                    },
                    "& .expand-player-button": {
                        display: "none",
                    },
                    "& .react-player--download__button": {
                        display: "none",
                    },
                }}
            >
                <DialogTitle className="flex items-center justify-between flex-shrink-0">
                    <Typography
                        fontWeight={600}
                        variant="medium"
                        className="capitalize font-semibold"
                        color={"textColors.333"}
                    >
                        {object.label}
                    </Typography>

                    <IconButton onClick={() => setOpen(false)}>
                        <CloseSvg />
                    </IconButton>
                </DialogTitle>
                <Divider className="flex-shrink-0" flexItem />
                <DialogContent className="flex flex-col flex-1 items-center justify-center">
                    <iframe
                        src={object.link}
                        width="640"
                        height="480"
                        allow="autoplay"
                    ></iframe>
                </DialogContent>
            </Dialog>{" "}
        </>
    );
};
