import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function AIInsightsTabSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 14 18"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: 14, height: 18, fill: "none", ...sx }}
        >
            <svg>
                <path
                    d="M6.75 18C6.255 18 5.83125 17.8237 5.47875 17.4713C5.12625 17.1187 4.95 16.695 4.95 16.2H8.55C8.55 16.695 8.37375 17.1187 8.02125 17.4713C7.66875 17.8237 7.245 18 6.75 18ZM3.15 15.3V13.5H10.35V15.3H3.15ZM3.375 12.6C2.34 11.985 1.51875 11.16 0.91125 10.125C0.30375 9.09 0 7.965 0 6.75C0 4.875 0.65625 3.28125 1.96875 1.96875C3.28125 0.65625 4.875 0 6.75 0C8.625 0 10.2188 0.65625 11.5312 1.96875C12.8438 3.28125 13.5 4.875 13.5 6.75C13.5 7.965 13.1962 9.09 12.5887 10.125C11.9812 11.16 11.16 11.985 10.125 12.6H3.375ZM3.915 10.8H9.585C10.26 10.32 10.7812 9.7275 11.1487 9.0225C11.5162 8.3175 11.7 7.56 11.7 6.75C11.7 5.37 11.22 4.2 10.26 3.24C9.3 2.28 8.13 1.8 6.75 1.8C5.37 1.8 4.2 2.28 3.24 3.24C2.28 4.2 1.8 5.37 1.8 6.75C1.8 7.56 1.98375 8.3175 2.35125 9.0225C2.71875 9.7275 3.24 10.32 3.915 10.8Z"
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
}
