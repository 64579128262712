import { Comment, CommentPayload } from "@convin/type/Common";
import { Transcript } from "@convin/type/Conversation";
import {
    Dispatch,
    PropsWithChildren,
    ReactElement,
    createContext,
    useReducer,
} from "react";

type CommentInputState = Pick<CommentPayload, "comment" | "mentioned_users"> & {
    media: Blob | null;
    media_url?: string;
    media_type?: "video" | "audio";
    commentIdToEdit?: number;
    transcript: Transcript | null;
};

export interface ICommentInputStateContext {
    state: CommentInputState;
    dispatch: Dispatch<Action>;
    prepareCommentStateForUpdate: (e: Comment) => void;
}

export const CommentInputSateContext = createContext<
    ICommentInputStateContext | undefined
>(undefined);

const initialState: CommentInputState = {
    comment: "",
    mentioned_users: [],
    media: null,
    transcript: null,
};

export enum ActionKind {
    updateState = "UPDATE",
    resetState = "RESET",
}

type Action =
    | {
          type: "UPDATE";
          payload: Partial<CommentInputState>;
      }
    | {
          type: "RESET";
          payload?: undefined;
      };

function Reducer(state: CommentInputState, action: Action): CommentInputState {
    const { type, payload } = action;
    switch (type) {
        case ActionKind.updateState:
            return {
                ...state,
                ...payload,
            };
        case ActionKind.resetState:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export default function CommentInputStatePorvider({
    children,
    initialCommentValue,
    transcript,
}: PropsWithChildren<{
    initialCommentValue?: string;
    transcript?: Transcript | null;
}>): ReactElement {
    const [state, dispatch] = useReducer(Reducer, {
        ...initialState,
        comment: initialCommentValue || "",
        transcript: transcript ?? null,
    });
    const prepareCommentStateForUpdate: ICommentInputStateContext["prepareCommentStateForUpdate"] =
        ({ comment, mentioned_users }) => {
            dispatch({
                type: "UPDATE",
                payload: {
                    ...state,
                    comment,
                    mentioned_users,
                },
            });
        };
    return (
        <CommentInputSateContext
            value={{
                state,
                dispatch,
                prepareCommentStateForUpdate,
            }}
        >
            {children}
        </CommentInputSateContext>
    );
}
