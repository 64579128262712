import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    ConversationDetailsContext,
    IConversationDetails,
} from "../context/ConversationDetailsProvider";

const useConversationDetailsContext = (): IConversationDetails => {
    const context = useContext(ConversationDetailsContext);
    if (!isDefined(context))
        throw new Error(
            "ConversationDetailsContext must be use inside ConversationDetailsProvider"
        );
    return context;
};

export default useConversationDetailsContext;
