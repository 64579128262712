import { combineReducers } from "redux";
import common from "./common/reducer";
import search from "./search/reducer";
import stats from "./stats/reducer";
import callAudit from "./call_audit/reducer";
import billing from "./billing/billing";
import coupon from "./coupon/coupon";
import dashboard from "./dashboard/dashboard";
import coaching from "./coaching/reduser";
import createCoachingSlice from "./coaching/createCoachingSlice";
import coaching_dashboard from "./coaching/coaching.store";
import callListSlice from "./callListSlice/callListSlice";

//Typescript with rtk query
import { violationManagerApiSlice } from "../convin/redux/services/settings/violationManager.service";
import { auditManagerApiSlice } from "../convin/redux/services/settings/auditManager.service";
import { roleManagerApiSlice } from "../convin/redux/services/settings/roleManager.service";
import { callTypeManagerApiSlice } from "../convin/redux/services/settings/callTypeManager.service";
import { callTagsManagerApiSlice } from "../convin/redux/services/settings/callTagsManager.service";
import { usersApiSlice } from "../convin/redux/services/settings/users.service";
import { scoreSenseApiSlice } from "@convin/redux/services/settings/scoreSense.service";
import { qmsApiSlice } from "@convin/redux/services/settings/qms.service";
import { customDashboardApiSlice } from "@convin/redux/services/home/customDashboard.service";
import { accountsApiSlice } from "@convin/redux/services/account/account.service";
import { entityApiSlice } from "@convin/redux/services/settings/entity.service";
import { activityApiSlice } from "@convin/redux/services/settings/activity.service";
import { userManagerApiSlice } from "../convin/redux/services/settings/userManager.service";
import { teamManagerSeviceSlice } from "@convin/redux/services/settings/teamManager.service";
import { domainApiSlice } from "@convin/redux/services/domain/domain.service";
import { billingApiSlice } from "@convin/redux/services/settings/billing.service";
import { lmsKnowledgeBaseApiSlice } from "@convin/redux/services/lms/lmsKnowledgeBase.service";
import { lmsAssessmentApiSlice } from "@convin/redux/services/lms/lmsAssesments.service";
import { analyticsDashboardApiSlice } from "@convin/redux/services/home/analyticsDashboard.service";
import { scheduledReportsApiSlice } from "@convin/redux/services/settings/scheduledReport.service";
import { customerIntelligenceApiSlice } from "@convin/redux/services/customerIntelligence/customerIntelligence.service";
import { liveAssistApiSlice } from "@convin/redux/services/settings/liveAssist.service";
import { aiFeedbackSlice } from "@convin/redux/services/settings/aiFeedback.service";
import { notificationApiSlice } from "@convin/redux/services/notifications/notification.service";
import { supervisorAssistApiSlice } from "@convin/redux/services/supervisorAssist/supervisorAssist.service";
import { aiVoiceCallSlice } from "@convin/redux/services/aiVoiceCall/aiVoiceCall.service";
import { samplingManagerApiSlice } from "@convin/redux/services/settings/samplingManager.service";
import { filtersApiSlice } from "@convin/redux/services/meeting/filters.service";
import { conversationsApiSlice } from "@convin/redux/services/conversation/conversations.service";
import { commentApiSlice } from "@convin/redux/services/comment/comment.service";
import { auditSheetApiSlice } from "@convin/redux/services/audit/auditSheet.service";
import { meetingsApiSlice } from "@convin/redux/services/meeting/meeting.service";
import { authApiSlice } from "@convin/redux/services/auth/auth.service";
import { coachingDashboardApiSlice } from "@convin/redux/services/home/coachingDashboard.service";
import { recommendationApiSlice } from "@convin/redux/services/settings/recommendation.service";
import { auditAiConfig } from "@convin/redux/services/settings/auditAiConfig.service";
import { coachingApiSlice } from "@convin/redux/services/coaching/coaching.service";
import { aiDispositionApiSlice } from "@convin/redux/services/settings/aiDisposition.service";
import { aiSummaryApiSlice } from "@convin/redux/services/settings/aiSummary.service";
import { personApiSlice } from "@convin/redux/services/auth/person.service";
import { internalCoachingApiSlice } from "@convin/redux/services/settings/internalCoaching.service";
import { recordingManagerApiSlice } from "@convin/redux/services/settings/recordingManager.service";
import { sentimentAnalysisApiSlice } from "@convin/redux/services/settings/sentimentAnalysis.service";
import { topicManagerApiSlice } from "@convin/redux/services/settings/topicManager.service";
import { integrationManagerApiSlice } from "@convin/redux/services/settings/integrationManager.service";
import { liveConversationApiSlice } from "@convin/redux/services/settings/liveConversation.service";

// Combine all reducers.
const appReducer = combineReducers({
    common,
    search,
    stats,
    callAudit,
    coaching,
    billing,
    coupon,
    dashboard,
    coaching_dashboard,
    callListSlice,
    createCoachingSlice,
    [auditManagerApiSlice.reducerPath]: auditManagerApiSlice.reducer,
    [roleManagerApiSlice.reducerPath]: roleManagerApiSlice.reducer,
    [violationManagerApiSlice.reducerPath]: violationManagerApiSlice.reducer,
    [callTypeManagerApiSlice.reducerPath]: callTypeManagerApiSlice.reducer,
    [callTagsManagerApiSlice.reducerPath]: callTagsManagerApiSlice.reducer,
    [usersApiSlice.reducerPath]: usersApiSlice.reducer,
    [internalCoachingApiSlice.reducerPath]: internalCoachingApiSlice.reducer,
    [scoreSenseApiSlice.reducerPath]: scoreSenseApiSlice.reducer,
    [qmsApiSlice.reducerPath]: qmsApiSlice.reducer,
    [aiFeedbackSlice.reducerPath]: aiFeedbackSlice.reducer,
    [customDashboardApiSlice.reducerPath]: customDashboardApiSlice.reducer,
    [activityApiSlice.reducerPath]: activityApiSlice.reducer,
    [userManagerApiSlice.reducerPath]: userManagerApiSlice.reducer,
    [teamManagerSeviceSlice.reducerPath]: teamManagerSeviceSlice.reducer,
    [accountsApiSlice.reducerPath]: accountsApiSlice.reducer,
    [entityApiSlice.reducerPath]: entityApiSlice.reducer,
    [domainApiSlice.reducerPath]: domainApiSlice.reducer,
    [billingApiSlice.reducerPath]: billingApiSlice.reducer,
    [lmsKnowledgeBaseApiSlice.reducerPath]: lmsKnowledgeBaseApiSlice.reducer,
    [lmsAssessmentApiSlice.reducerPath]: lmsAssessmentApiSlice.reducer,
    [analyticsDashboardApiSlice.reducerPath]:
        analyticsDashboardApiSlice.reducer,
    [scheduledReportsApiSlice.reducerPath]: scheduledReportsApiSlice.reducer,
    [customerIntelligenceApiSlice.reducerPath]:
        customerIntelligenceApiSlice.reducer,
    [liveAssistApiSlice.reducerPath]: liveAssistApiSlice.reducer,
    [aiSummaryApiSlice.reducerPath]: aiSummaryApiSlice.reducer,
    [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
    [supervisorAssistApiSlice.reducerPath]: supervisorAssistApiSlice.reducer,
    [aiVoiceCallSlice.reducerPath]: aiVoiceCallSlice.reducer,
    [samplingManagerApiSlice.reducerPath]: samplingManagerApiSlice.reducer,
    [filtersApiSlice.reducerPath]: filtersApiSlice.reducer,
    [conversationsApiSlice.reducerPath]: conversationsApiSlice.reducer,
    [commentApiSlice.reducerPath]: commentApiSlice.reducer,
    [auditSheetApiSlice.reducerPath]: auditSheetApiSlice.reducer,
    [meetingsApiSlice.reducerPath]: meetingsApiSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [coachingDashboardApiSlice.reducerPath]: coachingDashboardApiSlice.reducer,
    [recommendationApiSlice.reducerPath]: recommendationApiSlice.reducer,
    [auditAiConfig.reducerPath]: auditAiConfig.reducer,
    [coachingApiSlice.reducerPath]: coachingApiSlice.reducer,
    [aiDispositionApiSlice.reducerPath]: aiDispositionApiSlice.reducer,
    [personApiSlice.reducerPath]: personApiSlice.reducer,
    [recordingManagerApiSlice.reducerPath]: recordingManagerApiSlice.reducer,
    [sentimentAnalysisApiSlice.reducerPath]: sentimentAnalysisApiSlice.reducer,
    [topicManagerApiSlice.reducerPath]: topicManagerApiSlice.reducer,
    [integrationManagerApiSlice.reducerPath]:
        integrationManagerApiSlice.reducer,
    [liveConversationApiSlice.reducerPath]: liveConversationApiSlice.reducer,
});

export const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    // if (action.type === 'LOGOUT') state = undefined;
    return appReducer(state, action);
};
