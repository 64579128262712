import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AvailableSubscriptionType,
    BillingInfoFormType,
    BillingPlan,
    InvoiceType,
    RazorpayResponse,
    SubscriptionType,
    UpdateBillingPlanPayload,
} from "@convin/type/Billing";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";

export const billingApiSlice = createApi({
    reducerPath: "billingSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getSubscription: builder.query<SubscriptionType, void>({
            query: () => "subscription/get-subscription/",
        }),
        getAvailableSubscriptions: builder.query<
            AvailableSubscriptionType[],
            void
        >({
            query: () => "subscription/available-subscriptions/",
        }),
        getBillingCycle: builder.query<BillingPlan[], void>({
            query: () => "subscription/get-billingcycle/",
        }),
        getAmountPayable: builder.query<
            {
                currency: string;
                days: number;
                licenses: number;
                total_price: number;
                platform_fee: number;
            },
            {
                licenses: number;
                create_subscription: boolean;
                plan_id: number;
                subscription_id: number;
            }
        >({
            query: (payload) => ({
                url: "subscription/calculate-price/",
                method: "POST",
                body: payload,
            }),
        }),

        updateSubscription: builder.mutation<
            {
                key: string;
                subscription_id: number;
                invoice_id: string;
            },
            UpdateBillingPlanPayload
        >({
            query: ({ id, ...payload }) => ({
                url: `subscription/update/${id}/`,
                method: "PUT",
                body: payload,
            }),
        }),
        createBillingInfo: builder.mutation<SubscriptionType, RazorpayResponse>(
            {
                query: (payload) => ({
                    url: "subscription/billing_information/",
                    method: "POST",
                    body: payload,
                }),
            }
        ),
        getBillingInfo: builder.query<BillingInfoFormType, void>({
            query: () => "subscription/billing_information/",
        }),
        updateBillingInfo: builder.mutation<
            BillingInfoFormType,
            BillingInfoFormType
        >({
            query: (payload) => ({
                url: "subscription/billing_information/",
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        billingApiSlice.util.updateQueryData(
                            "getBillingInfo",
                            undefined,
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                    showToast({
                        ...CreateUpdateToastSettings,
                        message: "Billing info updated successfully",
                    });
                } catch {}
            },
        }),
        getInvoices: builder.query<InvoiceType[], void>({
            query: () => "/subscription/get-invoices/",
        }),
    }),
});

export const {
    useGetSubscriptionQuery,
    useGetAvailableSubscriptionsQuery,
    useGetBillingCycleQuery,
    useGetAmountPayableQuery,
    useUpdateSubscriptionMutation,
    useCreateBillingInfoMutation,
    useGetBillingInfoQuery,
    useUpdateBillingInfoMutation,
    useGetInvoicesQuery,
} = billingApiSlice;
