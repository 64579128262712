import { RHFTextField } from "@convin/components/hook-form";
import { TextFieldProps, styled } from "@mui/material";

export const FormTextField = styled(
    (props: TextFieldProps & { name: string; label?: string }) => (
        <RHFTextField {...props} />
    )
)(({ theme }) => ({
    "&.MuiTextField-root": {
        marginTop: "0px",
        "& .MuiInputBase-input,& .MuiInputLabel-root:not(.Mui-error,.Mui-focused)":
            {
                color: theme.palette.textColors["333"],
            },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.error.main} !important`,
            },
        },

        "not.(Mui-error, Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.textColors["999"]} !important`,
            },
        },
    },
}));
