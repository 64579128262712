import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DomainConfig } from "@convin/type/Common";
import { baseUrl } from "@apis/axiosInstance";

export const domainApiSlice = createApi({
    reducerPath: "domainApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    endpoints: (builder) => ({
        getDomainConfig: builder.query<DomainConfig, void>({
            query: () => ({
                url: "app/api/version/",
                method: "GET",
            }),
            async onQueryStarted(props, { queryFulfilled }) {
                const { data } = await queryFulfilled;
                const baseUrl = data?.ai_agent_host || "vbprod.convin.ai";
                localStorage.setItem("aiAgentHostUrl", baseUrl);
            },
        }),
        checkDomainExist: builder.query<
            {
                status: boolean;
            },
            string
        >({
            query: (name) => ({
                url: "https://app.api.convin.ai/app/domains/availability?name=teams",
                method: "GET",
                params: {
                    name,
                },
            }),
        }),
    }),
});

export const { useGetDomainConfigQuery, useCheckDomainExistQuery } =
    domainApiSlice;
