import React from "react";

export const CustomEditor = React.lazy(
    () => import("@convin/modules/lms/knowledgeBase/components/CustomEditor")
);
export const CustomerIntelligence = React.lazy(
    () => import("@convin/modules/customerInteligence")
);
export const SupervisorAssist = React.lazy(
    () => import("@convin/modules/SupervisorAssist")
);

export const B2BAuditDashboard = React.lazy(
    () =>
        import(
            "@convin/modules/home/analatyicsDashboard/modules/B2BAuditDashboard"
        )
);

export const ConversationRoutes = React.lazy(
    () => import("@convin/modules/routes/ConversationRoutes")
);
export const CreateQmsConversation = React.lazy(
    () => import("@convin/modules/qms/components/CreateQmsConversation")
);
export const MeetingsDashboard = React.lazy(
    () => import("@convin/modules/MeetingsDashboard")
);

export const AiVoiceCall = React.lazy(
    () => import("@convin/modules/aiVoiceCall")
);

export const Statistics = React.lazy(() => import("../Statistics/Statistics"));
export const Coaching = React.lazy(() => import("../Coaching/Coaching"));

export const Settings = React.lazy(() => import("../Settings/Settings"));

export const AccountsDashboard = React.lazy(
    () => import("@convin/modules/accounts")
);

export const HomeDashboard = React.lazy(() => import("@convin/modules/home"));

export const LMS = React.lazy(() => import("@convin/modules/lms"));

export const AssessmentAgentView = React.lazy(
    () => import("@convin/modules/coaching/AssessmentAgentView")
);

export const LockedState = React.lazy(
    () => import("@convin/components/custom_components/reuseable/LockedState")
);

export const BertRuleEngine = React.lazy(
    () =>
        import(
            "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/RuleEngineEntry"
        )
);

export const CoachingDashboard = React.lazy(
    () => import("@convin/modules/home/CoachingDashboard")
);

export const SalesAssistIframe = React.lazy(
    () => import("@convin/components/custom_components/SalesAssistIframe")
);

export const UserProfile = React.lazy(
    () => import("@convin/modules/settings/modules/userProfile")
);

export const ActivityLog = React.lazy(
    () => import("@convin/modules/ActivityLog")
);

export const LiveCallDetailsPage = React.lazy(
    () => import("@convin/modules/liveCallDetails")
);
