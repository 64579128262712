/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetUsersQuery } from "@convin/redux/services/settings/users.service";
import {
    Box,
    Button,
    Divider,
    Fade,
    Menu,
    MenuItem,
    Typography,
    alpha,
    styled,
} from "@mui/material";
import {
    Mention as MentionType,
    MentionsInput,
    OnChangeHandlerFunc,
} from "react-mentions";
import MediaRecorderComponent from "../../MediaRecorder/MediaRecorderComponent";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import RecordVideoSvg from "@convin/components/svg/RecordVideoSvg";
import { AnimatePresence, motion } from "framer-motion";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import VideoPreview from "../../MediaRecorder/VideoPreview";
import { useCallback, useState } from "react";
import useCommentInputState from "../hooks/useCommentInputState";
import {
    useAddCommentMutation,
    useEditCommentMutation,
} from "@convin/redux/services/comment/comment.service";
import { showToast } from "@convin/utils/toast";
import { getUserName, isDefined } from "@convin/utils/helper/common.helper";
import { nanoid } from "@reduxjs/toolkit";
import useCommentsState from "../hooks/useCommentsState";
import AudioPreview from "../../MediaRecorder/AudioPreview";
import CommentTranscriptPreview from "./CommentTranscriptPreview";

const StyledCommentInput = styled(MentionsInput as React.ComponentType<any>)(
    ({ theme }) => ({
        "& .mentions-input__suggestions": {
            maxHeight: "240px",
            overflow: "scroll",
            borderRadius: "6px",
            border: `1px solid ${alpha(theme.palette.textColors[999], 0.1)}`,
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        "& .mentions-input__suggestions__item": {
            padding: "8px",
            paddingLeft: "12px",
            fontSize: "1rem",
            "&:hover": {
                background: alpha(theme.palette.primary.main, 0.1),
            },
        },
        "&.mentions-input": {
            flex: 1,
        },
        "& .mention": {
            bgcolor: alpha(theme.palette.primary.main, 0.1),
        },
        textarea: {
            border: "unset",
            outline: "unset",
        },
    })
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MentionComponent = MentionType as React.ComponentType<any>;

export default function CommentInput() {
    const {
        id,
        dashboard_type,
        commentToReply,
        setCommentToReply,
        type,
        auditVariables,
    } = useCommentsState();
    const { state, dispatch } = useCommentInputState();
    const { data: users } = useGetUsersQuery();
    const [addComment, { isLoading: isCreatingComment }] =
        useAddCommentMutation();
    const [updateComment, { isLoading: isUpdateingComment }] =
        useEditCommentMutation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openRecording, setOpenRecording] = useState<boolean>(false);

    const { comment, media_type, media_url, commentIdToEdit } = state;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const displayTransform = (id: number | string, display: string) => {
        return `@${display}`;
    };

    const handleChange: OnChangeHandlerFunc = (
        event,
        newValue,
        newPlainTextValue,
        mentions
    ) => {
        dispatch({
            type: "UPDATE",
            payload: {
                comment: newValue,
                mentioned_users: mentions,
            },
        });
    };

    const initializeState = (type: typeof media_type) => {
        setOpenRecording(true);
        dispatch({
            type: "UPDATE",
            payload: {
                media_type: type,
            },
        });
        handleClose();
    };

    const updateMediaState = useCallback(
        (value: Pick<typeof state, "media" | "media_url">): void => {
            dispatch({
                type: "UPDATE",
                payload: {
                    ...value,
                },
            });
        },
        [state]
    );

    const { transcript } = state;

    const handleEditComment = () => {
        const { comment, mentioned_users, media, media_type } = state;
        if (!isDefined(media) && comment === "") {
            showToast({
                type: "error",
                message: "Comment is required",
            });
        } else {
            const formdata = new FormData();
            formdata.append(
                "comment",
                isDefined(media) && !comment ? "type_media" : comment
            );
            formdata.append(
                "mentioned_users",
                JSON.stringify((mentioned_users ?? []).map((e) => e.id))
            );
            if (type === "auditDisputeComment") {
                formdata.append(
                    "is_meeting",
                    JSON.stringify(dashboard_type !== "account")
                );
                if (isDefined(auditVariables)) {
                    formdata.append(
                        "template_id",
                        JSON.stringify(auditVariables.template)
                    );
                    formdata.append(
                        "owner_id",
                        JSON.stringify(auditVariables.agent)
                    );
                    if (auditVariables.is_ai_rated) {
                        formdata.append("is_ai_rated", JSON.stringify(true));
                    }
                }
            }

            if (isDefined(transcript)) {
                formdata.append("transcript", JSON.stringify(transcript));
            }
            if (isDefined(media) && !!media_type) {
                formdata.append(
                    "media",
                    media,
                    `${nanoid(10)}.${media_type === "video" ? "mp4" : "mp3"}`
                );
            }

            if (isDefined(commentIdToEdit))
                updateComment({
                    commentId: commentIdToEdit,
                    payload: formdata,
                    id,
                    dashboard_type,
                    type,
                })
                    .unwrap()
                    .then((res) => {
                        dispatch({
                            type: "RESET",
                        });
                        if (commentIdToEdit === commentToReply?.id) {
                            setCommentToReply(res);
                        }
                    });
            else {
                if (isDefined(commentToReply))
                    formdata.append("parent", commentToReply!.id.toString());
                addComment({
                    id,
                    dashboard_type,
                    payload: formdata,
                    type,
                })
                    .unwrap()
                    .then(() => {
                        dispatch({
                            type: "RESET",
                        });
                    });
            }
        }
    };

    return (
        <>
            {isDefined(transcript) && (
                <CommentTranscriptPreview {...{ transcript }} />
            )}
            <Box className="relative flex items-center" gap={1.5}>
                <Box
                    sx={{
                        px: 2,
                        py: 1.5,
                        border: "1px solid",
                        borderColor: "primary.main",
                        borderRadius: "8px",
                    }}
                    className="flex-1"
                    gap={1.5}
                >
                    <Box
                        className="flex items-center w-full"
                        sx={{
                            "& .mentions-input__suggestions": {
                                border: "1px solid black",
                            },
                        }}
                    >
                        <StyledCommentInput
                            placeholder={"Add comment"}
                            autoFocus
                            value={comment}
                            onChange={handleChange}
                            itemType="user"
                            className="mentions-input"
                            forceSuggestionsAboveCursor={true}
                        >
                            <MentionComponent
                                markup=";#__display__(__id__)#;"
                                trigger="@"
                                appendSpaceOnAdd={true}
                                displayTransform={displayTransform}
                                data={
                                    users?.map((e) => ({
                                        ...e,
                                        display: getUserName(e),
                                    })) || []
                                }
                                renderSuggestion={(
                                    suggestion: any,
                                    search: any,
                                    highlightedDisplay: any,
                                    index: number,
                                    focused: boolean
                                ) => (
                                    <Typography
                                        className={`${
                                            focused ? "focused" : ""
                                        }`}
                                        color="textColors.333"
                                    >
                                        {highlightedDisplay as React.ReactNode}
                                    </Typography>
                                )}
                                className="mention"
                            />
                        </StyledCommentInput>
                        {
                            //Don't show the Add more options if the user is editing a comment
                            isDefined(commentIdToEdit) ||
                                type === "auditDisputeComment" || (
                                    <>
                                        <Box
                                            component="button"
                                            sx={{
                                                color: "textColors.999",
                                            }}
                                            id="fade-button"
                                            aria-controls={
                                                open ? "fade-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                open ? "true" : undefined
                                            }
                                            onClick={handleClick}
                                        >
                                            <AddIcon />
                                        </Box>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                                "aria-labelledby":
                                                    "fade-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            TransitionComponent={Fade}
                                            classes={{
                                                paper: "add__menu--dropdown--paper",
                                            }}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: 120,
                                                horizontal: "right",
                                            }}
                                        >
                                            <MenuItem
                                                sx={{
                                                    p: 1.5,
                                                    px: 2.5,
                                                }}
                                                onClick={() =>
                                                    initializeState("audio")
                                                }
                                                className="flex items-center gap-[6px]"
                                            >
                                                <KeyboardVoiceIcon />{" "}
                                                <Typography variant="medium">
                                                    Audio
                                                </Typography>
                                            </MenuItem>
                                            <Divider
                                                sx={{ my: "0px !important" }}
                                            />
                                            <MenuItem
                                                sx={{
                                                    p: 1.5,
                                                    px: 2.5,
                                                }}
                                                onClick={() =>
                                                    initializeState("video")
                                                }
                                                className="flex items-center gap-[12px]"
                                            >
                                                <RecordVideoSvg />
                                                <Typography variant="medium">
                                                    Video
                                                </Typography>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )
                        }
                    </Box>

                    {isDefined(media_url) && media_type === "audio" ? (
                        <AudioPreview
                            url={media_url}
                            onClose={() =>
                                dispatch({
                                    type: "UPDATE",
                                    payload: {
                                        media_url: undefined,
                                        media: null,
                                        media_type: undefined,
                                    },
                                })
                            }
                            previewType="preview_media"
                        />
                    ) : (
                        <VideoPreview
                            url={media_url}
                            onClose={() =>
                                dispatch({
                                    type: "UPDATE",
                                    payload: {
                                        media_url: undefined,
                                        media: null,
                                        media_type: undefined,
                                    },
                                })
                            }
                            previewType="preview_media"
                        />
                    )}
                </Box>

                {
                    //Don't show create icon if the user is editing a comment
                    isDefined(commentIdToEdit) || (
                        <Button
                            variant="global"
                            loading={isCreatingComment}
                            sx={{
                                borderRadius: "12px",
                            }}
                            className="h-[48px] w-[48px]"
                            onClick={handleEditComment}
                        >
                            <SendIcon />
                        </Button>
                    )
                }

                <AnimatePresence>
                    {openRecording && (
                        <>
                            <Box
                                component={motion.div}
                                initial={{ x: 0, y: 1000, opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 0.64,
                                        ease: [0.43, 0.13, 0.23, 0.96],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    y: 1000,
                                    transition: {
                                        duration: 0.48,
                                        ease: [0.43, 0.13, 0.23, 0.96],
                                    },
                                }}
                                className="flex flex-col absolute w-[100%]"
                                sx={{
                                    bottom: "calc(100% + 12px)",
                                }}
                            >
                                <MediaRecorderComponent
                                    {...{
                                        handleClose: () =>
                                            setOpenRecording(false),
                                        updateMediaState,
                                        type: media_type || "video",
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </AnimatePresence>
            </Box>
            {isDefined(commentIdToEdit) && (
                <Box
                    className="flex items-center justify-end"
                    gap={2}
                    pt={2}
                    mr={1}
                >
                    <Box
                        color="textColors.333"
                        component="button"
                        onClick={() => {
                            dispatch({
                                type: "UPDATE",
                                payload: {
                                    commentIdToEdit: undefined,
                                },
                            });
                        }}
                    >
                        Cancel
                    </Box>
                    <Box
                        disabled={isUpdateingComment}
                        color="primary.main"
                        component="button"
                        onClick={handleEditComment}
                    >
                        {isUpdateingComment ? "...Saving" : "Save"}
                    </Box>
                </Box>
            )}
        </>
    );
}
