import useAppUpdate from "@convin/hooks/useAppUpdate";
import { useLocalStorage } from "@convin/hooks/useLocalStorage";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { pxToRem } from "@convin/utils/getFontValue";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";

export function useAppUpdateNotification() {
    const { data } = useGetDomainConfigQuery();
    const [version, setVersion] = useLocalStorage("av", data?.version);
    const { needRefresh, updateServiceWorker } = useAppUpdate();

    useEffect(() => {
        if (
            isDefined(data) &&
            isDefined(version) &&
            Number(version) !== Number(data?.version)
        ) {
            setVersion(data?.version);
            updateServiceWorker(true);
            window.location.reload();
        }
    }, [version, data?.version]);

    useEffect(() => {
        if (needRefresh) {
            toast(
                <div className="w-full flex items-center justify-between">
                    <Typography
                        fontWeight={600}
                        sx={{
                            fontFamily: "Figtree",
                        }}
                    >
                        ⚡️ New Update Available!
                    </Typography>
                    <Box
                        component="button"
                        onClick={() => {
                            updateServiceWorker(true);
                            toast.dismiss();
                        }}
                        bgcolor="background.paper"
                        color="primary.main"
                        fontWeight={500}
                        fontSize={pxToRem(14)}
                        className="ml-auto transition-all text-xs  border rounded-md px-4 py-2"
                    >
                        Update
                    </Box>
                </div>,
                {
                    autoClose: false,
                    closeOnClick: false,
                    draggable: true,
                    theme: "dark",
                    style: {
                        width: "400px",
                        height: "48px",
                    },
                    position: "bottom-center",
                    closeButton: false,
                }
            );
        }
    }, [needRefresh]);
}
