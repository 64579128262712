import {
    MutableRefObject,
    PropsWithChildren,
    createContext,
    useCallback,
    useRef,
    useState,
} from "react";

export interface IGloablSearchContextState {
    searchKeyword: string;
    handleSearchKeyword: (e: string) => void;
    searchIsDirty: MutableRefObject<boolean>;
}

export const GlobalSearchContext = createContext<IGloablSearchContextState>(
    {} as IGloablSearchContextState
);

export default function GlobalSearchContextProvider({
    children,
}: PropsWithChildren) {
    const searchIsDirty = useRef<boolean>(false);
    const [searchKeyword, setSearchKeyword] = useState<string>("");

    const handleSearchKeyword = useCallback(
        (keyword: string) => {
            setSearchKeyword(keyword);
        },
        [searchKeyword]
    );

    return (
        <GlobalSearchContext
            value={{ searchKeyword, handleSearchKeyword, searchIsDirty }}
        >
            {children}
        </GlobalSearchContext>
    );
}
