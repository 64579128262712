import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import MinMaxInputField from "../../../MinMaxInputField";
import { NumericRange } from "@convin/type/Common";

export default function DealSize({ ...props }) {
    const { state, updateState } = useGlobalFiltersStateContext();

    return (
        // <Stack gap={2} direction="row">
        //     <TextField
        //         value={state.dealSize.min}
        //         onChange={(e) => {
        //             updateState({
        //                 dealSize: {
        //                     ...state.dealSize,
        //                     min: Number(e.target.value),
        //                 },
        //             });
        //         }}
        //         type="number"
        //         className="w-full"
        //         placeholder="Enter minimum deal size"
        //     />
        //     <TextField
        //         value={state.dealSize.max}
        //         onChange={(e) => {
        //             updateState({
        //                 dealSize: {
        //                     ...state.dealSize,
        //                     max: Number(e.target.value),
        //                 },
        //             });
        //         }}
        //         type="number"
        //         className="w-full"
        //         placeholder="Enter maximum deal size"
        //     />
        // </Stack>
        <MinMaxInputField
            values={Object.values(state.dealSize) as NumericRange}
            minLabel="Min Size"
            maxLabel="Max Size"
            setValues={([min, max]) => {
                updateState({
                    dealSize: {
                        min,
                        max,
                    },
                });
            }}
            minLimit={0}
            variant="outlined"
            {...props}
        />
    );
}
