import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function CommentSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 21 20"
            sx={{ width: 21, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_20798_9591"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
            >
                <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_20798_9591)">
                <path
                    d="M5.5013 11.668H15.5013V10.0013H5.5013V11.668ZM5.5013 9.16797H15.5013V7.5013H5.5013V9.16797ZM5.5013 6.66797H15.5013V5.0013H5.5013V6.66797ZM18.8346 18.3346L15.5013 15.0013H3.83464C3.3763 15.0013 2.98394 14.8381 2.65755 14.5117C2.33116 14.1853 2.16797 13.793 2.16797 13.3346V3.33464C2.16797 2.8763 2.33116 2.48394 2.65755 2.15755C2.98394 1.83116 3.3763 1.66797 3.83464 1.66797H17.168C17.6263 1.66797 18.0187 1.83116 18.3451 2.15755C18.6714 2.48394 18.8346 2.8763 18.8346 3.33464V18.3346ZM16.2096 13.3346L17.168 14.2721V3.33464H3.83464V13.3346H16.2096Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
