import AudioTypeSvg from "@convin/components/svg/FileFormats/AudioTypeSvg";
import DocumentTypeSvg from "@convin/components/svg/FileFormats/DocumentTypeSvg";
import VideoTypeSvg from "@convin/components/svg/FileFormats/VideoTypeSvg";
import SnippetTypeSvg from "@convin/components/svg/FileFormats/SnippetTypeSvg";

import { getFileType } from "@convin/utils/helper/common.helper";

export default function MediaType({
    url,
    isSnippet,
    noBorder,
}: {
    url: string;
    isSnippet?: boolean;
    noBorder?: boolean;
}) {
    const mediaType = getFileType(url);
    return (
        <>
            {isSnippet ? (
                <SnippetTypeSvg />
            ) : mediaType === "audio" ? (
                <AudioTypeSvg {...{ noBorder }} />
            ) : mediaType === "video" ? (
                <VideoTypeSvg {...{ noBorder }} />
            ) : (
                <DocumentTypeSvg
                    {...{ noBorder }}
                    sx={{
                        ...(mediaType === "transcription" && {
                            color: "red",
                        }),
                    }}
                />
            )}
        </>
    );
}
