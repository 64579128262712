import TextField from "@mui/material/TextField";
import { useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import "react-calendar/dist/Calendar.css";
import {
    Paper,
    Box,
    Popper,
    Typography,
    alpha,
    AccordionSummary,
    AccordionDetails,
    FormGroup,
    FormControlLabel,
    Checkbox,
    ClickAwayListener,
    Theme,
} from "@mui/material";
import { SxProps, styled } from "@mui/system";
import { format } from "date-fns";
import { CustomDateSelector } from "./CustomDateSelector";
import { ChevronDownSvg } from "@convin/components/svg";
import { pxToRem } from "@convin/utils/getFontValue";
import { datekeys } from "@convin/config/default.config";
import { borderlessVariantProps } from "@convin/components/select/GenericSelect";
import { isDefined } from "@convin/utils/helper/common.helper";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme: _ }) => ({
    "&.MuiAccordion-root": {
        minHeight: "36px ",
        display: "flex",
        flexDirection: "column",
    },
    ".MuiAccordionSummary-root": {
        minHeight: "36px ",
    },
    ".MuiAccordionSummary-content": {
        margin: 0,
        overflow: "hidden",
        ".MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: 1.25,
            fontSize: pxToRem(14),
        },
    },
    ".MuiAccordionDetails-root": {
        padding: " 8px 10px",
    },
    ".MuiFormControlLabel-root": {
        margin: 0,
    },
}));

const DateDropDownPopper = styled(Popper)(({ theme }) =>
    theme.unstable_sx({
        boxShadow: `0px 4px 16px 2px ${alpha(
            theme.palette.textColors[333],
            0.1
        )}`,
        maxHeight: "334px !important",
        borderRadius: 1,
        overflow: "hidden",
        ".MuiPaper-root": {
            maxHeight: "334px !important",
        },
    })
);

const DateOption = styled(Box, {
    shouldForwardProp: (_prop) => true,
})(({ theme }) => ({
    ":hover": {
        backgroundColor: alpha(theme?.palette?.primary?.main, 0.1),
        cursor: "pointer",
    },
}));

export interface DateDropDownProps {
    onClose: () => void;
    options: { [key: string]: { name: string; is_rolling_date?: boolean } };
    handleUpdate: DateFilterProps["handleUpdate"];
    active: string | null;
    showDatePickerOptions?: boolean;
    handleRangeChange: (newRange: [Date | null, Date | null]) => void;
    showCustomTimePicker?: boolean;
}

export const DateDropDown = ({
    onClose,
    options,
    handleUpdate,
    handleRangeChange,
    active,
    showDatePickerOptions = true,
    showCustomTimePicker,
}: DateDropDownProps) => {
    const excludeLabels = [datekeys.before, datekeys.after];
    const parentRef = useRef<HTMLDivElement | null>(null);
    const customAccordionRef = useRef<HTMLDivElement | null>(null);

    const handleChange = (value: string) => {
        handleUpdate({
            value,
            is_custom: false,
            dateRange: [null, null],
        });
        onClose();
    };

    const showCustomDatePicker = (key: string) => {
        if (key === datekeys.custom && !showDatePickerOptions) return false;
        return true;
    };

    return (
        <>
            <Box className="flex-1 overflow-y-auto" ref={parentRef}>
                {Object.keys(options).map((key) =>
                    !options[key]?.is_rolling_date &&
                    !excludeLabels.includes(key) &&
                    showCustomDatePicker(key) ? (
                        <DateOption
                            sx={{
                                px: 2,
                                py: 1,
                            }}
                            key={key}
                            onClick={() => handleChange(key)}
                        >
                            <Typography
                                fontWeight={
                                    key === active
                                        ? "fontWeightMedium"
                                        : "fontFamily"
                                }
                                variant="textSm"
                                component="button"
                            >
                                {options[key]?.name}
                            </Typography>
                        </DateOption>
                    ) : null
                )}

                {showDatePickerOptions && (
                    <>
                        <Box ref={customAccordionRef}>
                            <Accordion
                                slotProps={{
                                    transition: {
                                        unmountOnExit: true,
                                        timeout: 0,
                                    },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ChevronDownSvg />}
                                >
                                    <Typography>Custom</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CustomDateSelector
                                        {...{
                                            handleRangeChange,
                                            showCustomTimePicker,
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </>
                )}

                <Accordion>
                    <AccordionSummary expandIcon={<ChevronDownSvg />}>
                        <Typography>Rolling Date Range</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {Object.keys(datekeys).map((key) =>
                                options[key]?.is_rolling_date ? (
                                    <FormControlLabel
                                        key={key}
                                        control={
                                            <Checkbox
                                                checked={active === key}
                                                onClick={() =>
                                                    handleUpdate({
                                                        value: key,
                                                        is_custom: false,
                                                        dateRange: [null, null],
                                                    })
                                                }
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                disableRipple
                                            />
                                        }
                                        label={
                                            <Typography variant="textXs">
                                                {options[key]?.name}
                                            </Typography>
                                        }
                                    />
                                ) : null
                            )}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

// Define types for DateFilter props
export interface DateFilterProps {
    value: string | null;
    handleUpdate: (e: {
        is_custom: boolean;
        value: string | null;
        dateRange: [string | null, string | null];
    }) => void;
    options: { [key: string]: { name: string; is_rolling_date?: boolean } };
    label: string | null;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    disableClearable?: boolean;
    customVariant?: string;
    showDatePickerOptions?: boolean;
    showCustomTimePicker?: boolean;
}

export default function DateFilter({
    value,
    handleUpdate,
    options,
    label,
    sx = {},
    disabled = false,
    disableClearable = true,
    customVariant = "",
    showDatePickerOptions,
    showCustomTimePicker,
}: DateFilterProps) {
    const [openPopper, setOpenPopper] = useState(false);

    const anchorRef = useRef<HTMLDivElement | null>(null);

    const onClose = () => {
        setOpenPopper(false);
    };

    const handleRangeChange = (newRange: [Date | null, Date | null]) => {
        if (!isDefined(newRange[0]) && !isDefined(newRange[1])) return;

        const formattedDate0 = isDefined(newRange[0])
            ? format(newRange[0], "MMM dd, yyyy HH:mm")
            : "";
        const formattedDate1 = isDefined(newRange[1])
            ? format(newRange[1], "MMM dd, yyyy HH:mm")
            : "";

        const fromDate = isDefined(newRange[0]) ? `${newRange[0]}` : null;
        const toDate = isDefined(newRange[1]) ? `${newRange[1]}` : null;

        const dateRange: [string | null, string | null] = [fromDate, toDate];

        const active_date = !isDefined(newRange[0])
            ? `Before ${formattedDate1}`
            : !isDefined(newRange[1])
            ? `After ${formattedDate0}`
            : `${formattedDate0} - ${formattedDate1}`;

        handleUpdate({
            value: active_date,
            is_custom: true,
            dateRange,
        });

        onClose();
    };
    return (
        <>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={() => {
                    setOpenPopper(false);
                }}
            >
                <Box
                    ref={anchorRef}
                    className="relative"
                    sx={{
                        ...(customVariant !== "borderless"
                            ? { flex: 1 }
                            : {
                                  ...(value === "all"
                                      ? { width: "80px" }
                                      : { width: "120px" }),
                              }),
                        ...sx,
                    }}
                >
                    <Autocomplete
                        value={value}
                        onChange={(_, newValue, reason) => {
                            if (reason === "clear") {
                                handleUpdate({
                                    value: null,
                                    is_custom: false,
                                    dateRange: [null, null],
                                });
                            }
                        }}
                        disableClearable={disableClearable}
                        options={[]}
                        open={openPopper}
                        onOpen={() => setOpenPopper(true)}
                        renderInput={(params) => (
                            <TextField {...params} label={label ?? "Date"} />
                        )}
                        getOptionLabel={(option) =>
                            options?.[option]?.name || ""
                        }
                        slotProps={{
                            paper: {
                                component: () => (
                                    <Paper
                                        className="flex flex-col"
                                        sx={{
                                            maxHeight: 200,
                                            height: 200,
                                        }}
                                    >
                                        <DateDropDown
                                            {...{
                                                options,
                                                onClose,
                                                handleUpdate,
                                                active: value,
                                                handleRangeChange,
                                                showDatePickerOptions,
                                                showCustomTimePicker,
                                            }}
                                        />
                                    </Paper>
                                ),
                            },
                            popper: {
                                component: ({ children, sx: _, ...rest }) => {
                                    return (
                                        <DateDropDownPopper
                                            placement="bottom-end"
                                            modifiers={[
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, 10],
                                                    },
                                                },
                                            ]}
                                            {...rest}
                                        >
                                            {children}
                                        </DateDropDownPopper>
                                    );
                                },
                            },
                        }}
                        disabled={!!disabled}
                        {...(customVariant === "borderless" &&
                            borderlessVariantProps())}
                        onClose={(e, reason) => {
                            if (reason === "selectOption") return;
                            if (reason === "toggleInput")
                                setOpenPopper((prev) => !prev);
                        }}
                    />
                </Box>
            </ClickAwayListener>
        </>
    );
}
