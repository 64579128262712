import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function AdvancedReportsSvg({
    sx = {},
}: {
    sx?: SxProps<Theme>;
}) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                width: 20,
                height: 20,
                fill: "none",
                ...sx,
            }}
        >
            <mask
                id="mask0_28564_923"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_28564_923)">
                <path
                    d="M7.08407 9.79268H12.9182V8.33415H7.08407V9.79268ZM7.08407 6.87561H12.9182V5.41707H7.08407V6.87561ZM15.7988 15.9732L12.8453 12.1263C12.6387 11.8468 12.3834 11.631 12.0796 11.4791C11.7757 11.3272 11.4475 11.2512 11.095 11.2512H4.16699V3.95854C4.16699 3.55744 4.30981 3.21408 4.59544 2.92845C4.88107 2.64282 5.22443 2.5 5.62553 2.5H14.3767C14.7778 2.5 15.1212 2.64282 15.4068 2.92845C15.6925 3.21408 15.8353 3.55744 15.8353 3.95854V15.6268C15.8353 15.6876 15.8322 15.7453 15.8262 15.8C15.8201 15.8547 15.811 15.9125 15.7988 15.9732ZM5.62553 17.0854C5.22443 17.0854 4.88107 16.9426 4.59544 16.6569C4.30981 16.3713 4.16699 16.0279 4.16699 15.6268V12.7098H11.095C11.2166 12.7098 11.329 12.7371 11.4323 12.7918C11.5356 12.8465 11.6238 12.9225 11.6967 13.0197L14.7596 17.0307C14.6988 17.055 14.635 17.0702 14.5682 17.0763C14.5013 17.0823 14.4375 17.0854 14.3767 17.0854H5.62553Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
