import { Box, Divider, Popover } from "@mui/material";
import React, { useCallback, useState } from "react";

import {
    CustomDurationPicker,
    DurationDropDown,
} from "@convin/components/custom_components/Filters/DurationFilter";
import { Label } from "@convin/components/custom_components/Typography/Label";
import DurationSelectorSvg from "@convin/components/svg/OverallFiters/DurationSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { isDefined } from "@convin/utils/helper/common.helper";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox } from "../../../styles";

import { showNoPermissionToast } from "@convin/utils/toast";

const DurationSelectorFilter = () => {
    const { state, updateState } = useGlobalFiltersStateContext();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [showDurationPicker, setShowDurationPicker] = useState(false);

    const isDisabled = state.meetingType !== "call";

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        if (isDisabled) return;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
        setShowDurationPicker(false);
    }, []);

    const handleUpdate = (e: {
        is_custom: boolean;
        value: string;
        range: [number | null, number | null];
    }) => {
        if (!Boolean(e.is_custom)) {
            updateState({
                durationKey: e.value,
            });
        } else {
            updateState({
                durationKey: e.value,
                durationOptions: {
                    ...state.durationOptions,
                    [e.value]: {
                        name: e.value,
                        value: e.range,
                    },
                },
            });
        }
    };

    const handleRangeChange = ({
        min,
        max,
    }: {
        min: number | null;
        max: number | null;
    }) => {
        const range: [number | null, number | null] = [min, max];
        if (!(min || max)) {
            showNoPermissionToast({
                message: "Duration values are required to apply filter.",
            });
            return;
        } else if ((min && min < 0) || (max && max < 0)) {
            showNoPermissionToast({
                message: "Duration values can't be negative.",
            });
            return;
        } else if (min && max && min >= max) {
            showNoPermissionToast({
                message: "Max duration must be greater than min duration.",
            });
            return;
        }

        const active =
            min && max
                ? `Between ${min} - ${max} min`
                : min
                ? `Above ${min} min`
                : max
                ? `Below ${max} min`
                : 0;
        handleUpdate({
            value: String(active),
            is_custom: active ? true : false,
            range,
        });
        setShowDurationPicker(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <StyledFilterBox
                onClick={handleClick}
                aria-describedby={id}
                sx={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                }}
            >
                <DurationSelectorSvg
                    sx={{ color: (theme) => theme.palette.common.white }}
                />
                <Label
                    isEllipses
                    hasTooltip
                    colorType="white"
                    className="font-medium flex-1"
                    variant="medium"
                >
                    {isDefined(state.durationKey)
                        ? state?.durationOptions?.[state.durationKey]?.name
                        : "Duration"}
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                classes={{ paper: "filters_popover" }}
                sx={{
                    "& .MuiPaper-root.filters_popover": {
                        borderRadius: "6px",
                        border: "1px solid rgba(153, 153, 153, 0.20) !important",
                        minWidth: "250px",
                        maxHeight: "400px",
                        boxShadow: "0px 4px 16px 2px rgba(51, 51, 51, 0.10)",
                        marginTop: "8px",

                        "& .custom_duration_picker": {
                            width: "348px",
                            m: 0,
                        },
                    },
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Label colorType="666" variant="medium" sx={{ p: 1.5 }}>
                    Select Duration
                </Label>
                <Divider />
                {!showDurationPicker && (
                    <DurationDropDown
                        options={state.durationOptions}
                        active={state.durationKey}
                        handleUpdate={handleUpdate}
                        setShowDurationPicker={setShowDurationPicker}
                        onClose={handleClose}
                    />
                )}
                {showDurationPicker && (
                    <CustomDurationPicker
                        handleRangeChange={handleRangeChange}
                        setShowDurationPicker={setShowDurationPicker}
                    />
                )}
            </Popover>
        </>
    );
};

export default DurationSelectorFilter;
