import {
    Box,
    Divider,
    ListItemButton,
    ListItemText,
    useTheme,
} from "@mui/material";
import React, { ReactElement, useCallback } from "react";

import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import { Label } from "@convin/components/custom_components/Typography/Label";
import { Option } from "@convin/components/select/GenericSelect";
import TeamSelectorSvg from "@convin/components/svg/OverallFiters/TeamSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox, filterPopoverSx } from "../../../styles";

type ModuleOption = Option<string>;

export default function ModuleFilter(): ReactElement {
    const theme = useTheme();
    const { state, updateState } = useGlobalFiltersStateContext();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const handleChange = (option: ModuleOption): void => {
        updateState({
            module: option.id,
        });
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const options: ModuleOption[] = [
        { id: "all", value: "All" },
        { id: "coaching", value: "Coaching" },
        { id: "meetings", value: "Meetings" },
        { id: "ci", value: "CI" },
        { id: "lms", value: "LMS" },
        { id: "assessment", value: "Assessment" },
        { id: "general_settings", value: "General Settings" },
        { id: "integrations", value: "Integrations" },
        { id: "product_settings", value: "Product Settings" },
        { id: "convin_gpt_settings", value: "Convin GPT Settings" },
    ];

    return (
        <>
            <StyledFilterBox onClick={handleClick} aria-describedby={id}>
                <TeamSelectorSvg />
                <Label
                    isEllipses
                    hasTooltip
                    colorType="white"
                    className="flex-1 font-semiBold"
                    variant="small"
                >
                    {options.find((option) => option.id === state.module)
                        ?.value ?? "All"}
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={filterPopoverSx(theme)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Label colorType="666" variant="medium" sx={{ p: 1.5 }}>
                    Select Module
                </Label>
                <Divider />
                <Box className="flex-1">
                    {options.map((option) => (
                        <ListItemButton
                            onClick={() => handleChange(option)}
                            key={option.id}
                            component="button"
                            sx={{ width: "100%" }}
                        >
                            <ListItemText>{option.value}</ListItemText>
                        </ListItemButton>
                    ))}
                </Box>
            </CustomPopover>
        </>
    );
}
