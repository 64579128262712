import { DeleteSvg } from "@convin/components/svg";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Pause, PlayArrow } from "@mui/icons-material";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";

type WaveSurferProps = {
    url?: string;
} & (
    | {
          onClose: () => void;
          previewType: "preview_media" | "audit_media";
      }
    | {
          previewType: "comment_media";
          onClose?: () => void;
      }
);

const AudioPreview: React.FC<WaveSurferProps> = ({
    url,
    previewType,
    onClose,
}) => {
    const {
        palette: { primary, textColors },
    } = useTheme();
    const waveformRef = useRef<HTMLDivElement>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);

    useEffect(() => {
        if (!waveformRef.current) return;

        wavesurfer.current = WaveSurfer.create({
            normalize: true,
            barWidth: 3,
            barRadius: 5,
            barGap: 2,
            barMinHeight: 30,
            hideCursor: false,
            container: waveformRef?.current,
            backend: "WebAudio",
            height: 30,
            progressColor: primary.main,
            responsive: true,
            waveColor: alpha(textColors["999"], 0.2),
            cursorColor: "transparent",
            scrollParent: true,
        });

        if (isDefined(url)) wavesurfer.current.load(url);

        wavesurfer.current.on("audioprocess", () => {
            setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
        });

        return () => {
            if (wavesurfer.current) wavesurfer.current.destroy();
        };
    }, [url]);

    const handlePlayPause = () => {
        if (wavesurfer.current) {
            setPlaying(!playing);
            wavesurfer.current.playPause();
        }
    };

    const formatTime = (time: number): string => {
        return new Date(time * 1000).toISOString().substr(14, 5);
    };

    return !isDefined(url) ? (
        <></>
    ) : (
        <Box
            sx={() => ({
                gap: 1,
                ...(["comment_media", "audit_media"].includes(previewType) && {
                    border: "1px solid",
                    color: "divider",
                    borderRadius: "6px",
                    p: 1,
                    my: 1,
                }),
            })}
            className="flex items-center"
        >
            {["preview_media", "audit_media"].includes(previewType) && (
                <Box
                    onClick={onClose}
                    component={"button"}
                    color="textColors.666"
                >
                    <DeleteSvg />
                </Box>
            )}

            <Box
                component={"button"}
                color={"primary.main"}
                onClick={handlePlayPause}
            >
                {playing ? <Pause /> : <PlayArrow />}
            </Box>
            <div id="waveform" className="flex-1" ref={waveformRef}></div>

            <Typography variant="small">{formatTime(currentTime)}</Typography>
        </Box>
    );
};

export default AudioPreview;
