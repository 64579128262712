import { Label } from "@convin/components/custom_components/Typography/Label";
import { DeleteSvg } from "@convin/components/svg";
import PlusSvg from "@convin/components/svg/PlusSvg";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import {
    useCreateTenureBucketMutation,
    useDeleteTenureBucketMutation,
    useGetTenureBucketsQuery,
} from "@convin/redux/services/meeting/filters.service";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    useTheme,
} from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import CustomSkeletonLoader from "@convin/components/custom_components/Skeleton";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tenure_bucket } from "@convin/type/Filter";
import { FormProvider } from "@convin/components/hook-form";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import DeleteDialog from "@convin/components/custom_components/Dialog/DeleteDialog";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import useAuth from "@convin/hooks/useAuth";

export const AgentTenureFilter = () => {
    const theme = useTheme();
    const { state, updateState, handleAppliedButtonClick } =
        useGlobalFiltersStateContext();
    const { user } = useAuth();
    const isAdmin = user?.role?.id === 1;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
        open: boolean;
        bucket: { start: number; end: number } | null;
    }>({ open: false, bucket: null });

    const [createTenureBucket, { isLoading: isCreating }] =
        useCreateTenureBucketMutation();
    const { data: tenureBucketList, isLoading } = useGetTenureBucketsQuery();
    const [deleteTenureBucket, { isLoading: isDeleting }] =
        useDeleteTenureBucketMutation();

    const schema = Yup.object().shape({
        start: Yup.number()
            .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
            })
            .required("From is required")
            .min(0, "From must be a positive")
            .integer("From cannot be a decimal")
            .nullable()
            .test(
                "not-in-existing-range",
                "From must not fall within any existing range",
                (value) => {
                    if (
                        value === null ||
                        value === undefined ||
                        !tenureBucketList?.tenure_buckets
                    )
                        return true;
                    return !tenureBucketList.tenure_buckets.some(
                        (bucket) => value >= bucket.start && value <= bucket.end
                    );
                }
            ),
        end: Yup.number()
            .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
            })
            .required("To is required")
            .min(0, "To must be a positive")
            .integer("To cannot be a decimal")
            .nullable()
            .moreThan(Yup.ref("start"), "To must be greater than From")
            .test(
                "not-in-existing-range",
                "To must not fall within any existing range",
                (value) => {
                    if (
                        value === null ||
                        value === undefined ||
                        !tenureBucketList?.tenure_buckets
                    )
                        return true;
                    return !tenureBucketList.tenure_buckets.some(
                        (bucket) => value >= bucket.start && value <= bucket.end
                    );
                }
            ),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            start: null,
            end: null,
        },
        mode: "onChange",
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = methods;

    const onSubmit = (data: FieldValues) => {
        const tenureData: tenure_bucket = {
            start: data.start,
            end: data.end,
        };

        // New validation check for overlapping ranges
        const isOverlapping = tenureBucketList?.tenure_buckets?.some(
            (bucket) => {
                return (
                    (tenureData.start >= bucket.start &&
                        tenureData.start <= bucket.end) ||
                    (tenureData.end >= bucket.start &&
                        tenureData.end <= bucket.end) ||
                    (tenureData.start <= bucket.start &&
                        tenureData.end >= bucket.end)
                );
            }
        );

        if (isOverlapping) {
            showToast({
                type: "error",
                message:
                    "Agent Tenure buckets cannot overlap. Please redefine the range to continue.",
            });
            return; // Prevent creation if overlapping
        }

        createTenureBucket(tenureData)
            .unwrap()
            .then(() => {
                showToast({
                    message: "Bucket created successfully",
                    ...CreateUpdateToastSettings,
                });
                setIsOpen(false);
                reset();
            });
    };

    const handleDeleteBucket = (start: number, end: number) => {
        setIsDeleteDialogOpen({ open: true, bucket: { start, end } });
    };
    return (
        <>
            {isLoading ? (
                <CustomSkeletonLoader rows={3} />
            ) : (
                tenureBucketList?.tenure_buckets?.map(
                    (item: { start: number; end: number }, index: number) => {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                                key={index}
                            >
                                <ListItemButton
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        p: 0,
                                    }}
                                    onClick={() => {
                                        if (
                                            state.agentTenure?.start ===
                                                item.start &&
                                            state.agentTenure?.end === item.end
                                        ) {
                                            updateState({ agentTenure: null });
                                        } else {
                                            updateState({
                                                agentTenure: {
                                                    start: item.start,
                                                    end: item.end,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            state.agentTenure?.start ===
                                                item.start &&
                                            state.agentTenure?.end === item.end
                                        }
                                        icon={<RadioButtonUnchecked />}
                                        checkedIcon={<RadioButtonChecked />}
                                    />
                                    <ListItemText
                                        primary={`${item.start} - ${item.end} days`}
                                    />
                                </ListItemButton>
                                {isAdmin &&
                                    !(
                                        state.agentTenure?.start ===
                                            item.start &&
                                        state.agentTenure?.end === item.end
                                    ) && (
                                        <IconButton
                                            onClick={() =>
                                                handleDeleteBucket(
                                                    item.start,
                                                    item.end
                                                )
                                            }
                                        >
                                            <DeleteSvg />
                                        </IconButton>
                                    )}
                            </Box>
                        );
                    }
                )
            )}

            {isOpen && (
                <Box sx={{ pl: 1, mb: 1 }}>
                    <Label colorType="666" sx={{ mb: 2 }}>
                        Choose from and to in Days
                    </Label>
                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack direction="row" className="w-full" gap={2}>
                                <Controller
                                    name="start"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="filled"
                                            label="From"
                                            type="number"
                                            className="flex-1"
                                            error={!!errors.start}
                                            helperText={
                                                <>{errors.start?.message}</>
                                            }
                                            {...field}
                                            value={field.value || ""}
                                        />
                                    )}
                                />
                                <Controller
                                    name="end"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="filled"
                                            label="To"
                                            type="number"
                                            className="flex-1"
                                            error={!!errors.end}
                                            helperText={
                                                <>{errors.end?.message}</>
                                            }
                                            {...field}
                                            value={field.value || ""}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </FormProvider>

                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: 1,
                        }}
                    >
                        <Button
                            variant="text"
                            sx={{
                                color: theme.palette.textColors[666],
                            }}
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleSubmit(onSubmit)}
                            loading={isCreating}
                            loadingIndicator={
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            }
                            disabled={!!errors.start || !!errors.end}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
            {isAdmin && (
                <Button
                    variant="text"
                    sx={{ justifyContent: "flex-start", width: "auto", mt: 2 }}
                    onClick={() => setIsOpen(true)}
                    disabled={isOpen}
                >
                    <PlusSvg sx={{ width: 20, height: 20, mr: 1 }} />
                    Create Tenure Bucket
                </Button>
            )}

            <DeleteDialog
                open={isDeleteDialogOpen.open}
                setOpen={() =>
                    setIsDeleteDialogOpen((prev) => ({
                        ...prev,
                        open: false,
                    }))
                }
                isLoading={isDeleting}
                title="Delete Tenure Bucket"
                message={
                    state.agentTenure?.start ===
                        isDeleteDialogOpen?.bucket?.start &&
                    state.agentTenure?.end === isDeleteDialogOpen?.bucket?.end
                        ? " Are you sure? Deleting it will change filter results."
                        : "Are you sure? The bucket will be removed."
                }
                onDelete={async () => {
                    if (isDeleteDialogOpen.bucket) {
                        const { start, end } = isDeleteDialogOpen.bucket;
                        try {
                            await deleteTenureBucket({ start, end }).unwrap();

                            showToast({
                                message: "Bucket deleted successfully",
                                ...CreateUpdateToastSettings,
                            });
                            handleAppliedButtonClick();
                            reset();
                            setIsDeleteDialogOpen({
                                open: false,
                                bucket: null,
                            });
                        } catch (error) {
                            showToast({
                                type: "error",
                                message:
                                    "Failed to delete the bucket. Please try again.",
                            });
                        }
                    }
                }}
            />
        </>
    );
};
