import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function SearchSvg({ sx = {} }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            sx={{ width: 16, height: 16, fill: "none", ...sx }}
        >
            <path
                d="M14.7917 13.575L11.952 10.75C12.8682 9.58538 13.3654 8.14721 13.3635 6.66667C13.3635 5.34813 12.9716 4.0592 12.2374 2.96287C11.5032 1.86654 10.4597 1.01206 9.23876 0.507473C8.01783 0.00288854 6.67435 -0.129134 5.37822 0.128101C4.08208 0.385336 2.89151 1.02027 1.95704 1.95262C1.02258 2.88497 0.386208 4.07286 0.128391 5.36607C-0.129426 6.65927 0.00289508 7.99972 0.508622 9.21789C1.01435 10.4361 1.87077 11.4773 2.96958 12.2098C4.06839 12.9423 5.36024 13.3333 6.68176 13.3333C8.16566 13.3352 9.60708 12.8392 10.7743 11.925L13.6057 14.7583C13.6834 14.8364 13.7758 14.8984 13.8775 14.9407C13.9793 14.9831 14.0885 15.0048 14.1987 15.0048C14.309 15.0048 14.4182 14.9831 14.5199 14.9407C14.6217 14.8984 14.7141 14.8364 14.7917 14.7583C14.87 14.6809 14.9322 14.5887 14.9746 14.4871C15.017 14.3856 15.0388 14.2767 15.0388 14.1667C15.0388 14.0567 15.017 13.9477 14.9746 13.8462C14.9322 13.7446 14.87 13.6525 14.7917 13.575ZM1.67044 6.66667C1.67044 5.67776 1.96435 4.71106 2.515 3.88882C3.06565 3.06657 3.84831 2.42571 4.76401 2.04727C5.67971 1.66883 6.68732 1.56982 7.65942 1.76274C8.63152 1.95567 9.52445 2.43187 10.2253 3.13114C10.9261 3.8304 11.4034 4.72131 11.5968 5.69122C11.7902 6.66112 11.6909 7.66646 11.3116 8.58009C10.9323 9.49372 10.29 10.2746 9.4659 10.824C8.6418 11.3734 7.67291 11.6667 6.68176 11.6667C5.35268 11.6667 4.07803 11.1399 3.13822 10.2022C2.19842 9.26452 1.67044 7.99275 1.67044 6.66667Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
