import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import { Typography } from "@mui/material";

export function OfflineNotificationStatus() {
    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <SignalWifiConnectedNoInternet4Icon color="error" />
                    <Typography fontWeight={600}>
                        Internet connection lost!
                    </Typography>
                </div>
            </div>
        </div>
    );
}
