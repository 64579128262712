import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function ViolationSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                d="M8.5337 3.99816L2.68507 13.762C2.56448 13.9708 2.50068 14.2076 2.50001 14.4487C2.49933 14.6898 2.56181 14.927 2.68122 15.1365C2.80064 15.346 2.97282 15.5205 3.18066 15.6428C3.38849 15.7651 3.62472 15.8309 3.86584 15.8335H15.5631C15.8042 15.8309 16.0405 15.7651 16.2483 15.6428C16.4561 15.5205 16.6283 15.346 16.7477 15.1365C16.8671 14.927 16.9296 14.6898 16.9289 14.4487C16.9283 14.2076 16.8645 13.9708 16.7439 13.762L10.8952 3.99816C10.7721 3.79522 10.5988 3.62744 10.392 3.51099C10.1852 3.39455 9.95182 3.33337 9.71447 3.33337C9.47712 3.33337 9.24377 3.39455 9.03694 3.51099C8.83012 3.62744 8.6568 3.79522 8.5337 3.99816Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1.65723"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.7135 7.54724V10.3093"
                stroke="white"
                strokeWidth="1.65723"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.7135 13.0713H9.72087"
                stroke="white"
                strokeWidth="1.65723"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
