import { isDarkMode } from "@convin/utils/helper/common.helper";
import { Grid2, alpha, styled } from "@mui/material";

export const StyledGridRow = styled(Grid2, {
    shouldForwardProp: (prop) => prop !== "index",
})<{ index?: number }>(({ theme, index }) => ({
    alignItems: "center",
    maxHeight: "80px",
    padding: theme.spacing(2.5),
    background:
        index === undefined
            ? alpha(
                  theme.palette.miscColors.headerGray,
                  isDarkMode(theme) ? 0.2 : 0.5
              )
            : index % 2 === 0
            ? theme.palette.background.paper
            : alpha(theme.palette.textColors[999], 0.1),
    color:
        index === undefined
            ? theme.palette.textColors[333]
            : theme.palette.textColors[666],
    fontWeight: index === undefined ? 500 : 400,
    ":last-of-type": {
        boxShadow: `0px 4px 12px 0px ${alpha(
            theme.palette.textColors[999],
            0.1
        )}`,
    },
}));
