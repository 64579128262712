import { MeetingTypeConst, NumericRange } from "./Common";
import {
    CSATAnalysis,
    CollectionAnalysis,
    Conversation,
    ConversationSkills,
    LeadAnalysis,
    SearchKeywords,
} from "./Conversation";
import { tenure_bucket } from "./Filter";

export enum AuditType {
    "ai" = 0,
    "manual" = 1,
}

export enum Level {
    "account" = 0,
    "conversation" = 1,
}

export enum ConversationScoreType {
    "good" = 1,
    "average" = 0,
    "bad" = -1,
}

export type ConversationScoreLabel = "Need Attention" | "Average" | "Good";

export type StaticDashboardFiltersType = {
    meetingType: MeetingTypeConst;
    teams: number[];
    reps: number[];
    template: null | number;
    dateKey: string;
    auditDateKey: string | null;
    durationKey: string | number;
    module: null | string;
    accountTeams: number[];
    accountReps: number[];
};

export type accountSortBy =
    | "sales_task__created"
    | "last_connected_date"
    | "ai_score";

export interface CIFilters {
    ciFilter: {
        saidBy: {
            client: boolean;
            owner: boolean;
        };
        exclude: boolean;
        agentTenure: tenure_bucket | null;
    };
}
export type modulesTypes =
    | "customer_intelligence"
    | "ai_insights"
    | "custom_tracking";

export type moduleOptions = {
    id: modulesTypes;
    label: string;
};

export type insightsFilterType = {
    module: modulesTypes | null;
    option_type: string | number | null;
    insights: {
        level1: string | number | null;
        level2: string | number | null;
        level3: string | number | null;
    };
    error?: string;
};

export type moduleOptionsType = { id: modulesTypes; label: string };

export type AccountListFilters = {
    accountStage: number | null;
    accountTags: {
        tagIds: number[];
        logic: "and" | "or";
        negate: boolean;
    };
    lastContactedDateKey: string | null;
    closeDateKey: string | null;
    dealSize: {
        min: number | null;
        max: number | null;
    };
    minMaxInteractions: NumericRange; //[0,1]
    leadType: LeadAnalysis["classification"] | null;
    minLeadScore: number | null;
    accountSortBy: `-${accountSortBy}` | accountSortBy;
    accountQueryBy: string | null;
    accountTeams: number[];
    accountReps: number[];
    teams?: number[];
    reps?: number[];
};

export type ConversationListFilters = {
    auditors: number[];
    auditDateKey: string | null;
    auditType: AuditType | null; // 0 for AI, 1 for Manual
    conversationScore: NumericRange;
    conversationScoreName: ConversationScoreType | null;
    analyticsTemplate: number | null;
    templateCategories: TemplateCategoryFilter;
    templateQuestions: TemplateQuestionFilter;
    isDraftAudit: boolean;
    auditFeedbackStatus: null | "acknowledge" | "dispute" | "not_acknowledge";
    client: null | number;
    searchKeywords: SearchKeywords;
    tags: {
        tagIds: number[];
        logic?: "and" | "or";
        negate?: boolean;
    };
    callTypes: {
        typeIds: number[];
        logic?: "and" | "or";
        negate?: boolean;
    };
    questions: {
        byClient: NumericRange;
        byOwner: NumericRange;
    };
    topics: {
        id: null | number;
        inCall: boolean;
    };
    conversationSkills: ConversationSkills;
    recordingMedium: null | string;
    stage: number | null;
    processingStatus: null | Conversation["processing_status"];
    scoresense: {
        lead: LeadAnalysis["classification"] | null;
        csat: CSATAnalysis["classification"] | null;
        collection: CollectionAnalysis["classification"] | null;
    };
    joiningDateKey: string | null;
    attribute: {
        category: number | null;
        type: number | null;
        value: string | null;
    };
    dispositionTemplate: {
        template: number | null;
        level1: number | null;
        level2: number | null;
        level3: number | null;
    };
    agentTenure: tenure_bucket | null;
};

export type CIInsightsTypesResponse = {
    insights: {
        id: number;
        name: string;
    }[];
};
export type InsightsTypeResponse = {
    options: {
        id: number;
        question_text?: string;
        title?: string;
    }[];
};
export type InsightsLevelResponse = {
    tags: {
        id: number;
        name: string;
    }[];
};
export type KeywordsResponse = {
    phrases: {
        id: number;
        representation: string;
    }[];
};
export type InsightsTypePayload = {
    module: modulesTypes;
    option_type?: string | number;
    parent_id?: number | string;
};

export type RatingQuestionOptions = "5<" | "7<" | "7>" | -1;

export type TemplateCategoryFilter = Record<number, NumericRange>;

export type TemplateQuestionFilter = Record<
    number, //Question
    number | RatingQuestionOptions
>;

export type DrawerFiltersType = Pick<
    StaticDashboardFiltersType,
    "template" | "dateKey"
> &
    CIFilters &
    ConversationListFilters &
    AccountListFilters & {
        level: Level; // 0 for Account, 1 for Call
        convinInsights: insightsFilterType;
    };
