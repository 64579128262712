import {
    Dispatch,
    MutableRefObject,
    useReducer,
    useRef,
    useCallback,
    createContext,
    PropsWithChildren,
} from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import poster from "@convin/assets/images/jpeg/media_player_poster.jpg";
import { OnProgressProps } from "react-player/base";

type PlayerProps = ReactPlayerProps & {
    duration: number;
    playedSeconds: number;
    captionsEnabled: boolean;
    loaded: number;
    isFullscreen: boolean;
};

const playerInitialState: PlayerProps = {
    playing: false,
    controls: false,
    volume: 0.8,
    light: true,
    duration: 0,
    playedSeconds: 0,
    played: 0,
    pip: false,
    muted: false,
    playbackRate: 1.0,
    captionsEnabled: true,
    loaded: 0,
    loadedSeconds: 0,
    isFullscreen: false,
};

type Action =
    | {
          type:
              | "LIGHT"
              | "DURATION"
              | "SEEK"
              | "VOLUME"
              | "PIP"
              | "FULLSCREEN"
              | "PLAYBACK_RATE";
          payload: Partial<PlayerProps>;
      }
    | {
          type:
              | "PLAY"
              | "TOGGLE_PLAY"
              | "PAUSE"
              | "TOGGLE_PIP"
              | "TOGGLE_CAPTIONS"
              | "TOGGLE_MUTE";
          payload?: undefined;
      };

function Reducer(state: PlayerProps, action: Action) {
    const { type } = action;
    const payload = action.payload ?? {};
    switch (type) {
        case "PLAY":
            return {
                ...state,
                playing: true,
            };
        case "LIGHT":
            return { ...state, light: payload.light };
        case "TOGGLE_PLAY":
            return { ...state, playing: !state.playing };
        case "PAUSE":
            return { ...state, playing: false };
        case "DURATION":
            return { ...state, duration: payload?.duration ?? 0 };
        case "SEEK":
            return {
                ...state,
                playedSeconds: payload?.playedSeconds ?? 0,
                loaded: payload?.loaded ?? 0,
            };
        case "VOLUME":
            return {
                ...state,
                volume: payload?.volume ?? 0,
                muted: (payload?.volume ?? 0) === 0 ? true : false,
            };
        case "TOGGLE_PIP":
            return { ...state, pip: !state.pip };
        case "TOGGLE_CAPTIONS": {
            return { ...state, captionsEnabled: !state.captionsEnabled };
        }
        case "TOGGLE_MUTE": {
            return {
                ...state,
                muted: !state.muted,
                ...(state.muted ? { volume: 0.3 } : { volume: 0 }),
            };
        }
        case "FULLSCREEN": {
            return { ...state, isFullscreen: payload.isFullscreen || false };
        }
        case "PLAYBACK_RATE": {
            return { ...state, playbackRate: payload.playbackRate };
        }
        default:
            return state;
    }
}

export interface IMediaPlayerContextValues
    extends Record<
        | "handlePreview"
        | "handlePlayPause"
        | "handlePause"
        | "handlePlay"
        | "toggleCaptions"
        | "handleEnablePip"
        | "togglePip"
        | "handleEnded"
        | "toggleMute",
        () => void
    > {
    playerRef: MutableRefObject<ReactPlayer | null>;
    playerState: PlayerProps;
    updatePlayerState: Dispatch<Action>;
    updateFullscreenState: (isFullscreen: boolean) => void;
    handleSeek: (value: number) => void;
    seekBy10seconds: (type: "back" | "forward") => void;
    handleVolumeChange: (event: Event, newValue: number | number[]) => void;
    handlePlayerControlSliderSeek: (
        event: Event,
        newValue: number | number[]
    ) => void;
    handleProgress: (e: OnProgressProps) => void;
    handleDuration: (duration: number) => void;
    handlePlaybackRateChange: (playbackRate: number) => void;
    hideController?: boolean;
}

export const MediaPlayerContext = createContext<
    IMediaPlayerContextValues | undefined
>(undefined);

/**
 * @param children : PropsWithChilden["children"]
 * @returns RectNode
 * @description A context provider which maintains the entire state of your media player and expose functions to update the state of the media player
 */
export default function MediaPlayerContextProvider({
    children,
    hideController = false,
}: PropsWithChildren<Pick<IMediaPlayerContextValues, "hideController">>) {
    const playerRef = useRef<ReactPlayer | null>(null);
    const [playerState, updatePlayerState] = useReducer(Reducer, {
        ...playerInitialState,
    });

    const handleSeek = useCallback((value: number) => {
        const playedSeconds = Math.floor(value);
        updatePlayerState({
            type: "SEEK",
            payload: { playedSeconds },
        });
        updatePlayerState({
            type: "PLAY",
        });
        playerRef?.current?.seekTo(playedSeconds);
    }, []);

    const handlePreview = useCallback(() => {
        updatePlayerState({ type: "PLAY" });
        updatePlayerState({ type: "LIGHT", payload: { light: false } });
    }, []);

    const handlePlayPause = useCallback(() => {
        updatePlayerState({ type: "TOGGLE_PLAY" });
    }, []);

    const handlePause = useCallback(() => {
        updatePlayerState({ type: "PAUSE" });
    }, []);

    const handleEnded = () => {
        updatePlayerState({ type: "LIGHT", payload: { light: poster } });
    };

    const handlePlay = useCallback(() => {
        updatePlayerState({ type: "PLAY" });
    }, []);

    const handleProgress: IMediaPlayerContextValues["handleProgress"] =
        useCallback((e) => {
            updatePlayerState({ type: "SEEK", payload: { ...e } });
        }, []);

    const handleDuration: IMediaPlayerContextValues["handleDuration"] =
        useCallback((duration: number) => {
            updatePlayerState({ type: "DURATION", payload: { duration } });
        }, []);

    const handlePlayerControlSliderSeek: IMediaPlayerContextValues["handlePlayerControlSliderSeek"] =
        useCallback((event: Event, newValue: number | number[]) => {
            updatePlayerState({
                type: "SEEK",
                payload: { playedSeconds: newValue as number },
            });
            playerRef?.current?.seekTo(newValue as number);
        }, []);

    const seekBy10seconds: IMediaPlayerContextValues["seekBy10seconds"] =
        useCallback(
            (type: "back" | "forward") => {
                const newPlayedSeconds =
                    type === "back"
                        ? Math.max(0, playerState.playedSeconds - 10)
                        : Math.min(
                              playerState.playedSeconds + 10,
                              playerState.duration
                          );

                updatePlayerState({
                    type: "SEEK",
                    payload: { playedSeconds: newPlayedSeconds },
                });
                playerRef?.current?.seekTo(newPlayedSeconds);
            },
            [playerState.playedSeconds]
        );

    const toggleCaptions = useCallback(() => {
        updatePlayerState({
            type: "TOGGLE_CAPTIONS",
        });
    }, []);

    const handleVolumeChange: IMediaPlayerContextValues["handleVolumeChange"] =
        useCallback((event: Event, newValue: number | number[]) => {
            updatePlayerState({
                type: "VOLUME",
                payload: {
                    volume: parseFloat((newValue as number).toString()),
                },
            });
        }, []);

    const togglePip = useCallback(() => {
        updatePlayerState({
            type: "TOGGLE_PIP",
        });
    }, []);

    const toggleMute = useCallback(() => {
        updatePlayerState({
            type: "TOGGLE_MUTE",
        });
    }, []);

    const updateFullscreenState: IMediaPlayerContextValues["updateFullscreenState"] =
        useCallback((isFullscreen) => {
            updatePlayerState({
                type: "FULLSCREEN",
                payload: { isFullscreen },
            });
        }, []);

    const handleEnablePip = useCallback(() => {
        updatePlayerState({
            type: "PIP",
            payload: { pip: true },
        });
    }, []);

    const handlePlaybackRateChange: IMediaPlayerContextValues["handlePlaybackRateChange"] =
        useCallback((playbackRate) => {
            updatePlayerState({
                type: "PLAYBACK_RATE",
                payload: { playbackRate },
            });
        }, []);

    return (
        <MediaPlayerContext
            value={{
                playerRef,
                playerState,
                updatePlayerState,
                handleSeek,
                handlePreview,
                handlePause,
                handlePlay,
                handlePlayPause,
                toggleCaptions,
                togglePip,
                toggleMute,
                updateFullscreenState,
                handleEnablePip,
                seekBy10seconds,
                handleVolumeChange,
                handlePlayerControlSliderSeek,
                handleProgress,
                handleDuration,
                handleEnded,
                handlePlaybackRateChange,
                hideController,
            }}
        >
            {children}
        </MediaPlayerContext>
    );
}
