import { SettingRoutes } from "@convin/config/routes.config";
import FiltersVisibilityContextProvider, {
    IFilterVisibility,
} from "@convin/contexts/FiltersVisibilityContextProvider";
import useAuth from "@convin/hooks/useAuth";
import { PropsWithChildren } from "react";

const staticFiltersVisibility: IFilterVisibility["staticFiltersVisibility"] = [
    "meetingType",
    "teams",
    "reps",
    "durationKey",
    "dateKey",
];

export const meetingsDrawerFiltersVisibility: IFilterVisibility["drawerFiltersVisibility"] =
    [
        "conversationScore",
        "template",
        "auditType",
        "isDraftAudit",
        "auditFeedbackStatus",
        "client",
        "scoresense",
        "searchKeywords",
        "tags",
        "callTypes",
        "questions",
        "conversationSkills",
        "topics",
        "recordingMedium",
        "stage",
        "minMaxInteractions",
        "processingStatus",
        "attribute",
        "dispositionTemplate",
        "agentTenure",
        "convinInsights",
    ];

export default function MeetingsFiltersWrapper({
    children,
}: PropsWithChildren) {
    const { checkDashboardVisibility } = useAuth();
    return (
        <FiltersVisibilityContextProvider
            {...{
                drawerFiltersVisibility: [
                    ...meetingsDrawerFiltersVisibility!.filter((e) =>
                        e === "dispositionTemplate"
                            ? checkDashboardVisibility(
                                  SettingRoutes.AI_DISPOSITION
                              )
                            : true
                    ),
                ],
                staticFiltersVisibility,
                dashboardType: "Conversation",
            }}
        >
            {children}
        </FiltersVisibilityContextProvider>
    );
}
