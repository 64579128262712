import { isDefined } from "@convin/utils/helper/common.helper";
import { useContext } from "react";
import { CommentState, CommentsStateContext } from "../CommentsComponent";

export default function useCommentsState(): CommentState {
    const context = useContext(CommentsStateContext);
    if (!isDefined(context)) {
        throw new Error(
            "CommentsStateContext must be wrapped around CommentsSateProvider"
        );
    }
    return context;
}
