import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useCallback } from "react";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { ratingQuestionOptions } from "@convin/config/conversation.config";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { RatingQuestionButton } from "@convin/modules/components/globalDashboardFilters/styles";
import { Question, QuestionType } from "@convin/type/Audit";
import { RatingQuestionOptions } from "@convin/type/Filters.model";

interface Props {
    question: Question;
    index: number;
}

const TemplateQuestion = ({
    question: { id, question_text, question_type, settings },
    index,
}: Props) => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const updateQuestionScore = useCallback(
        (
            question_id: number,
            question_type: QuestionType,
            value: number | RatingQuestionOptions
        ) => {
            if (filtersState.templateQuestions?.[question_id] === value) {
                const updatedQuestionFilters = {
                    ...filtersState.templateQuestions,
                };
                delete updatedQuestionFilters?.[question_id];
                return updateFiltersState({
                    templateQuestions: updatedQuestionFilters,
                });
            }
            updateFiltersState({
                templateQuestions: {
                    ...filtersState.templateQuestions,
                    [question_id]: value,
                },
            });
        },
        [filtersState.templateQuestions]
    );
    return (
        <Stack spacing={1.5}>
            <Label colorType="333" variant="medium" sx={{ fontWeight: 500 }}>
                {index < 8 ? `0${index + 1}` : index + 1}. {question_text}
            </Label>
            <Stack
                direction="row"
                rowGap={1}
                columnGap={3}
                pl={2}
                flexWrap="wrap"
            >
                {question_type === "yes_no" ? (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        filtersState.templateQuestions?.[id] ===
                                        1
                                    }
                                    onChange={() =>
                                        updateQuestionScore(
                                            id,
                                            question_type,
                                            1
                                        )
                                    }
                                    name="yes"
                                />
                            }
                            label="Yes"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        filtersState.templateQuestions?.[id] ===
                                        0
                                    }
                                    onChange={() =>
                                        updateQuestionScore(
                                            id,
                                            question_type,
                                            0
                                        )
                                    }
                                    name="no"
                                />
                            }
                            label="No"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        filtersState.templateQuestions?.[id] ===
                                        -1
                                    }
                                    onChange={() =>
                                        updateQuestionScore(
                                            id,
                                            question_type,
                                            -1
                                        )
                                    }
                                    name="na"
                                />
                            }
                            label="NA"
                        />
                    </>
                ) : question_type === "rating" ? (
                    ratingQuestionOptions.map(({ id: opt_id, label }) => (
                        <RatingQuestionButton
                            key={opt_id}
                            variant="outlined"
                            size="small"
                            active={
                                filtersState.templateQuestions?.[id] === opt_id
                            }
                            onClick={() =>
                                updateQuestionScore(id, question_type, opt_id)
                            }
                        >
                            {label}
                        </RatingQuestionButton>
                    ))
                ) : question_type === "custom" ? (
                    settings.custom.map(({ id: opt_id, name }) => (
                        <FormControlLabel
                            key={opt_id}
                            control={
                                <Checkbox
                                    checked={
                                        filtersState.templateQuestions?.[id] ===
                                        opt_id
                                    }
                                    onChange={() =>
                                        updateQuestionScore(
                                            id,
                                            question_type,
                                            opt_id
                                        )
                                    }
                                    name={name}
                                />
                            }
                            label={name}
                        />
                    ))
                ) : (
                    <></>
                )}
            </Stack>
        </Stack>
    );
};

export default TemplateQuestion;
