import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "@apis/axiosInstance";
import { InviteUserType } from "@convin/type/UserManager";

import { violationManagerApiSlice } from "@convin/redux/services/settings/violationManager.service";
import { auditManagerApiSlice } from "@convin/redux/services/settings/auditManager.service";
import { roleManagerApiSlice } from "@convin/redux/services/settings/roleManager.service";
import { callTypeManagerApiSlice } from "@convin/redux/services/settings/callTypeManager.service";
import { callTagsManagerApiSlice } from "@convin/redux/services/settings/callTagsManager.service";
import { usersApiSlice } from "@convin/redux/services/settings/users.service";
import { scoreSenseApiSlice } from "@convin/redux/services/settings/scoreSense.service";
import { qmsApiSlice } from "@convin/redux/services/settings/qms.service";
import { customDashboardApiSlice } from "@convin/redux/services/home/customDashboard.service";
import { accountsApiSlice } from "@convin/redux/services/account/account.service";
import { entityApiSlice } from "@convin/redux/services/settings/entity.service";
import { activityApiSlice } from "@convin/redux/services/settings/activity.service";
import { userManagerApiSlice } from "@convin/redux/services/settings/userManager.service";
import { teamManagerSeviceSlice } from "@convin/redux/services/settings/teamManager.service";
import { billingApiSlice } from "@convin/redux/services/settings/billing.service";
import { lmsKnowledgeBaseApiSlice } from "@convin/redux/services/lms/lmsKnowledgeBase.service";
import { lmsAssessmentApiSlice } from "@convin/redux/services/lms/lmsAssesments.service";
import { analyticsDashboardApiSlice } from "@convin/redux/services/home/analyticsDashboard.service";
import { scheduledReportsApiSlice } from "@convin/redux/services/settings/scheduledReport.service";
import { customerIntelligenceApiSlice } from "@convin/redux/services/customerIntelligence/customerIntelligence.service";
import { liveAssistApiSlice } from "@convin/redux/services/settings/liveAssist.service";
import { aiFeedbackSlice } from "@convin/redux/services/settings/aiFeedback.service";
import { notificationApiSlice } from "@convin/redux/services/notifications/notification.service";
import { supervisorAssistApiSlice } from "@convin/redux/services/supervisorAssist/supervisorAssist.service";
import { aiVoiceCallSlice } from "@convin/redux/services/aiVoiceCall/aiVoiceCall.service";
import { samplingManagerApiSlice } from "@convin/redux/services/settings/samplingManager.service";
import { filtersApiSlice } from "@convin/redux/services/meeting/filters.service";
import { conversationsApiSlice } from "@convin/redux/services/conversation/conversations.service";
import { commentApiSlice } from "@convin/redux/services/comment/comment.service";
import { auditSheetApiSlice } from "@convin/redux/services/audit/auditSheet.service";
import { meetingsApiSlice } from "@convin/redux/services/meeting/meeting.service";
import { coachingDashboardApiSlice } from "@convin/redux/services/home/coachingDashboard.service";
import { recommendationApiSlice } from "@convin/redux/services/settings/recommendation.service";
import { auditAiConfig } from "@convin/redux/services/settings/auditAiConfig.service";
import { coachingApiSlice } from "@convin/redux/services/coaching/coaching.service";
import { aiDispositionApiSlice } from "@convin/redux/services/settings/aiDisposition.service";
import { aiSummaryApiSlice } from "@convin/redux/services/settings/aiSummary.service";
import { personApiSlice } from "@convin/redux/services/auth/person.service";
import { internalCoachingApiSlice } from "@convin/redux/services/settings/internalCoaching.service";
import { recordingManagerApiSlice } from "@convin/redux/services/settings/recordingManager.service";
import { sentimentAnalysisApiSlice } from "@convin/redux/services/settings/sentimentAnalysis.service";
import { topicManagerApiSlice } from "@convin/redux/services/settings/topicManager.service";
import { integrationManagerApiSlice } from "@convin/redux/services/settings/integrationManager.service";
import { liveConversationApiSlice } from "@convin/redux/services/settings/liveConversation.service";

export const authApiSlice = createApi({
    reducerPath: "authApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    endpoints: (builder) => ({
        getAccessRefreshToken: builder.mutation<
            { access: string; refresh: string },
            { jwt_token: string }
        >({
            query: (payload) => ({
                url: "/person/new_token/",
                method: "POST",
                body: payload,
            }),
        }),
        login: builder.mutation<
            { access: string; refresh: string } | null,
            | { email: string; password: string }
            | { params: { [k: string]: string } }
        >({
            query: (credentials) => ({
                url: "/person/token/",
                method: "POST",
                body: "params" in credentials ? {} : credentials,
                ...("params" in credentials && {
                    params: credentials.params,
                }),
            }),
        }),
        getUserFromInvitationId: builder.query<
            {
                email: string;
                extra_info: Record<never, never>;
                id: number;
                invitation_id: string;
                role: number;
                subscription: number;
                team: number;
                user_type: number;
            },
            string
        >({
            query: (invitation_id) => `person/person/invite/${invitation_id}`,
        }),
        signUp: builder.mutation<
            {
                token: { access: string; refresh: string };
            },
            { payload: FormData; query?: { invitation_id?: string | boolean } }
        >({
            query: ({ payload, query }) => ({
                url: !query?.invitation_id
                    ? "person/person/create_admin/"
                    : "person/person/create/",
                method: "POST",
                body: payload,
                params: query,
            }),
        }),
        generateResetPasswordLink: builder.mutation<string, { email: string }>({
            query: (payload) => ({
                url: "/person/forgotpassword/",
                method: "POST",
                body: payload,
            }),
        }),
        resetPassword: builder.mutation<
            string,
            { payload: FormData; query?: { uid?: string } }
        >({
            query: ({ payload, query }) => ({
                url: "/person/resetpassword/",
                method: "POST",
                body: payload,
                params: query,
            }),
        }),
        verifyOtp: builder.mutation<
            { access: string; refresh: string },
            FormData
        >({
            query: (payload) => ({
                url: "/person/verify_otp/",
                method: "POST",
                body: payload,
            }),
        }),
        resendOtp: builder.mutation<void, FormData>({
            query: (payload) => ({
                url: "/person/verify_otp/resend/",
                method: "POST",
                body: payload,
            }),
        }),
        getInvitationDetails: builder.query<InviteUserType, string>({
            query: (invitation_id) => `person/person/invite/${invitation_id}`,
        }),
        getUserDomain: builder.mutation<
            {
                domain: string;
            },
            {
                email: string;
            }
        >({
            query: (params) => ({
                url: "app/domains/",
                params,
                method: "GET",
            }),
        }),
        createDomain: builder.mutation<
            unknown,
            { email: string; name: string }
        >({
            query: (payload) => ({
                url: "app/domains/",
                method: "POST",
                body: payload,
            }),
        }),
        configureDomain: builder.mutation<
            {
                redirect: string;
            },
            { provider: string; code: string }
        >({
            query: (payload) => ({
                url: `app/tpsignup/${payload.provider}/`,
                params: payload,
                method: "GET",
            }),
        }),
        logout: builder.mutation<void, string>({
            query: (token) => ({
                url: "/person/logout/",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    // Reset all API slices
                    dispatch(violationManagerApiSlice.util.resetApiState());
                    dispatch(auditManagerApiSlice.util.resetApiState());
                    dispatch(roleManagerApiSlice.util.resetApiState());
                    dispatch(callTypeManagerApiSlice.util.resetApiState());
                    dispatch(callTagsManagerApiSlice.util.resetApiState());
                    dispatch(usersApiSlice.util.resetApiState());
                    dispatch(scoreSenseApiSlice.util.resetApiState());
                    dispatch(qmsApiSlice.util.resetApiState());
                    dispatch(customDashboardApiSlice.util.resetApiState());
                    dispatch(accountsApiSlice.util.resetApiState());
                    dispatch(entityApiSlice.util.resetApiState());
                    dispatch(activityApiSlice.util.resetApiState());
                    dispatch(userManagerApiSlice.util.resetApiState());
                    dispatch(teamManagerSeviceSlice.util.resetApiState());
                    dispatch(billingApiSlice.util.resetApiState());
                    dispatch(lmsKnowledgeBaseApiSlice.util.resetApiState());
                    dispatch(lmsAssessmentApiSlice.util.resetApiState());
                    dispatch(analyticsDashboardApiSlice.util.resetApiState());
                    dispatch(scheduledReportsApiSlice.util.resetApiState());
                    dispatch(customerIntelligenceApiSlice.util.resetApiState());
                    dispatch(liveAssistApiSlice.util.resetApiState());
                    dispatch(aiFeedbackSlice.util.resetApiState());
                    dispatch(notificationApiSlice.util.resetApiState());
                    dispatch(supervisorAssistApiSlice.util.resetApiState());
                    dispatch(aiVoiceCallSlice.util.resetApiState());
                    dispatch(samplingManagerApiSlice.util.resetApiState());
                    dispatch(filtersApiSlice.util.resetApiState());
                    dispatch(conversationsApiSlice.util.resetApiState());
                    dispatch(commentApiSlice.util.resetApiState());
                    dispatch(auditSheetApiSlice.util.resetApiState());
                    dispatch(meetingsApiSlice.util.resetApiState());
                    dispatch(coachingDashboardApiSlice.util.resetApiState());
                    dispatch(recommendationApiSlice.util.resetApiState());
                    dispatch(auditAiConfig.util.resetApiState());
                    dispatch(coachingApiSlice.util.resetApiState());
                    dispatch(aiDispositionApiSlice.util.resetApiState());
                    dispatch(aiSummaryApiSlice.util.resetApiState());
                    dispatch(personApiSlice.util.resetApiState());
                    dispatch(internalCoachingApiSlice.util.resetApiState());
                    dispatch(recordingManagerApiSlice.util.resetApiState());
                    dispatch(sentimentAnalysisApiSlice.util.resetApiState());
                    dispatch(topicManagerApiSlice.util.resetApiState());
                    dispatch(integrationManagerApiSlice.util.resetApiState());
                    dispatch(liveConversationApiSlice.util.resetApiState());

                    window.localStorage.removeItem("authTokens");
                    window.localStorage.removeItem("is_internal");
                } catch {
                    console.error("Unable to logout due to error");
                    window.localStorage.clear();
                }
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useGetAccessRefreshTokenMutation,
    useGetUserFromInvitationIdQuery,
    useSignUpMutation,
    useGenerateResetPasswordLinkMutation,
    useResetPasswordMutation,
    useVerifyOtpMutation,
    useResendOtpMutation,
    useGetUserDomainMutation,
    useCreateDomainMutation,
    useLogoutMutation,
    useConfigureDomainMutation,
} = authApiSlice;
