import {
    Box,
    InputAdornment,
    SxProps,
    TextField,
    Theme,
    alpha,
} from "@mui/material";

import SearchSvg from "@convin/components/svg/SearchSvg";

interface Props {
    value: string;
    handleChange: (val: string) => void;
    placeholder?: string;
    sx?: SxProps<Theme>;
}

const SearchField = ({
    value,
    handleChange,
    placeholder = "Search",
    sx = {},
}: Props) => {
    return (
        <Box sx={{ p: 1.5 }}>
            <TextField
                size="small"
                variant="standard"
                sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                        bgcolor: (theme) =>
                            alpha(theme.palette.textColors["999"], 0.1),
                        borderRadius: "6px",
                        pl: 1,
                    },
                    ".MuiInputBase-input": {
                        py: 1,
                        height: "16px",
                    },
                    ".MuiInputAdornment-root": {
                        mt: "0px !important",
                    },
                    ...sx,
                }}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchSvg sx={{ color: "grey.999" }} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default SearchField;
