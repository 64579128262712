import GenericSelect from "@convin/components/select/GenericSelect";
import { draftStatusOptions } from "@convin/config/default.config";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { ConversationListFilters } from "@convin/type/Filters.model";

export default function AuditFeedbackStatus() {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <GenericSelect<ConversationListFilters["auditFeedbackStatus"]>
            options={draftStatusOptions}
            label="Audit Feedback Status"
            value={filtersState.auditFeedbackStatus}
            handleChange={(e) => {
                updateFiltersState({
                    auditFeedbackStatus: e,
                    auditType: 1,
                });
            }}
            hasClear
            className="flex-1"
        />
    );
}
