import { useCallback, useLayoutEffect, useState } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const initialize = (key: string): T => {
        try {
            const item = localStorage.getItem(key);
            if (item && item !== "undefined") {
                return JSON.parse(item) as T;
            }

            localStorage.setItem(key, JSON.stringify(initialValue));
            return initialValue;
        } catch {
            return initialValue;
        }
    };

    const [state, setState] = useState<T | null>(null);

    useLayoutEffect(() => {
        setState(initialize(key));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const setValue = useCallback(
        (value: T | ((prev: T | null) => T)) => {
            try {
                const valueToStore =
                    value instanceof Function ? value(state) : value;
                setState(valueToStore);
                localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error(error);
            }
        },
        [key, state]
    );

    const remove = useCallback(() => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(error);
        }
    }, [key]);

    return [state, setValue, remove] as const;
};
