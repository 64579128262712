import RocketSvg from "@convin/components/svg/RocketSvg";
import WarningIconSvg from "@convin/components/svg/WarningIconSvg";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";

export const Banner: React.FC = () => {
    const { data } = useGetDomainConfigQuery();
    const theme = useTheme();
    const [isVisible, setIsVisible] = React.useState(true);
    const errorBanner = data?.banners?.find(
        (banner) => banner.level === "error"
    );
    const warmBanner = data?.banners?.find((banner) => banner.level === "warm");
    const permanentBanner = data?.banners?.find(
        (banner) => banner.level === "permanent"
    );
    const warnBanner = data?.banners?.find((banner) => banner.level === "warn");

    const text =
        permanentBanner?.text ||
        errorBanner?.text ||
        warnBanner?.text ||
        warmBanner?.text;

    const level =
        permanentBanner?.level ||
        errorBanner?.level ||
        warnBanner?.level ||
        warmBanner?.level;

    const isSession = level === "warm" && warmBanner?.session;
    const getColor = () => {
        if (level === "error") return theme.palette.auditColors.bad;
        if (level === "warn") return theme.palette.auditColors.average;

        return "linear-gradient(270deg, #4AC7FD 13.54%, #6A4FF7 109.23%)";
    };

    useEffect(() => {
        if (!isSession) return;
        const sessionBannerExpiry = localStorage.getItem("sessionBannerExpiry");
        const currentTimeStamp = Math.floor(Date.now() / 1000);
        const timePeriod = 60 * 60 * 24; // seconds in a day
        if (!sessionBannerExpiry) {
            return;
        } else if (
            currentTimeStamp - Number(sessionBannerExpiry) <=
            timePeriod
        ) {
            setIsVisible(false);
        } else {
            localStorage.removeItem("sessionBannerExpiry");
            setIsVisible(true);
        }
    }, []);

    if (
        !isVisible ||
        (!errorBanner && !warmBanner && !permanentBanner && !warnBanner) ||
        isDefined(data?.brand)
    )
        return null;

    return (
        <Box
            className="flex-shrink-0 flex items-center justify-center"
            sx={{
                py: 0.875,
                color: "white",
                background: getColor(),
                position: "relative",
                zIndex: 1,
                textAlign: "center",
                opacity: isVisible ? 1 : 0,
                height: isVisible ? "auto" : 0,
                transition: "opacity 0.3s ease-out, height 0.3s ease-out",
                overflow: "hidden",
            }}
        >
            <Box className="flex items-center justify-center" gap={1}>
                {level === "error" || level === "warn" ? (
                    <WarningIconSvg sx={{ color: "white" }} />
                ) : (
                    <RocketSvg />
                )}
                <Typography
                    variant="medium"
                    dangerouslySetInnerHTML={{
                        __html: text ?? "",
                    }}
                    color="white"
                    component={"span"}
                />
                {level !== "permanent" && (
                    <Box
                        component="button"
                        onClick={() => {
                            if (isSession) {
                                const currentTimeStamp = Math.floor(
                                    Date.now() / 1000
                                );
                                localStorage.setItem(
                                    "sessionBannerExpiry",
                                    JSON.stringify(currentTimeStamp)
                                );
                            }
                            setIsVisible(false);
                        }}
                        sx={{
                            cursor: "pointer",
                            color: "white",
                            background: "none",
                            border: "none",
                            fontSize: "30px",
                            lineHeight: 1,
                            "&:hover": {
                                opacity: 0.8,
                            },
                        }}
                        className="absolute  right-[10px]"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <g mask="url(#mask0_36989_542)">
                                <path
                                    d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                    fill="white"
                                />
                            </g>
                        </svg>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
