/* eslint-disable @typescript-eslint/no-unused-vars */
import PageLoader from "@convin/components/custom_components/PageLoader";
import { durationkeys } from "@convin/config/default.config";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import {
    GlobalFiltersInitialState,
    GlobalFiltersStateType,
} from "@convin/contexts/GlobalFiltersStateContextProvider";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import {
    useGetDefaultViewQuery,
    useGetShareMeetingsQuery,
    useGetTrackerMeetingsQuery,
} from "@convin/redux/services/meeting/meeting.service";
import {
    ConversationView,
    CustomDataFilterType,
    ConversationSearchDecodedFilters,
} from "@convin/type/Conversation";
import { AuditType, Level } from "@convin/type/Filters.model";
import { SearchFilter } from "@convin/type/Search";
import { decrypt, isDefined } from "@convin/utils/helper/common.helper";
import { absoluteDateField } from "@convin/utils/helper/date.helper";
import {
    decodeConversationListFilters,
    getCustomDurationFilter,
    setDurationDateFiltersOnDecodedFilters,
} from "@convin/utils/helper/search.helper";
import { PropsWithChildren, useEffect, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";

export default function SetDefaultFiltersWrapper({
    children,
}: PropsWithChildren) {
    const query = useSearchQuery();
    const shareId = query.get("share_id");
    const trackerId = query.get("tracker");
    const hasSetDefaultFilters = useRef<boolean>(false);
    const location = useLocation();
    const route = matchPath<"dashboard_type", string>(
        "/:dashboard_type",
        location.pathname
    );

    const dashboard = Object.values(DashboardRoutesConfig).find(
        (e) => e.path === route?.params?.dashboard_type
    );

    const isPathConversationList =
        dashboard?.path === DashboardRoutesConfig.Conversations.path;
    const isPathAnalytics = dashboard?.path === DashboardRoutesConfig.Home.path;

    const { data: domainConfig, isLoading: isDomainConfigLoading } =
        useGetDomainConfigQuery();

    const { data: defaultView, isLoading: defaultViewLoading } =
        useGetDefaultViewQuery(undefined, {
            skip: !isPathConversationList,
        });

    const { data: shareData, isLoading: shareDataIsLoading } =
        useGetShareMeetingsQuery(shareId!, {
            skip: !(isDefined(shareId) && isPathConversationList),
        });
    const { data: alertData, isLoading: alertDataIsLoading } =
        useGetTrackerMeetingsQuery(trackerId!, {
            skip: !(isDefined(trackerId) && isPathConversationList),
        });

    const { handleActiveView, updateState, handleAppliedButtonClick, state } =
        useGlobalFiltersStateContext();

    const setFilters = (
        json_filters: Array<SearchFilter | CustomDataFilterType>
    ) => {
        const filters = decodeConversationListFilters(
            json_filters.filter(
                (filter) => filter.name !== "custom_data"
            ) as SearchFilter[]
        );
        updateState(setDurationDateFiltersOnDecodedFilters({ state, filters }));
        handleAppliedButtonClick();
    };
    const queryParams = useSearchQuery();
    const filters = queryParams.get("filters");

    useEffect(() => {
        if (defaultViewLoading) return;
        if (filters) {
            const decryptedFilters =
                decrypt<Partial<GlobalFiltersStateType>>(filters);
            updateState({
                ...decryptedFilters,
            });
            handleAppliedButtonClick();
            hasSetDefaultFilters.current = true;
            //TOD0: CLEAR THE FILTERS FROM THE URL
            return;
        }
        if (isDefined(shareId)) {
            return;
        }
        if (isDefined(trackerId)) {
            return;
        }
        if (hasSetDefaultFilters.current) return;
        if (isDefined(defaultView?.id) && isPathConversationList) {
            const { json_filters, name } = defaultView as ConversationView;
            const filters = decodeConversationListFilters(
                json_filters.filter(
                    (filter) => filter.name !== "custom_data"
                ) as SearchFilter[]
            );
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { name: _, ...uiFilters } =
                json_filters.find((filter) => filter.name === "custom_data") ??
                {};
            updateState(
                setDurationDateFiltersOnDecodedFilters({
                    state,
                    filters: { ...uiFilters, ...filters },
                })
            );
            handleAppliedButtonClick();
            handleActiveView(name);
        } else {
            if (isDefined(domainConfig)) {
                const initFilters: Partial<
                    ConversationSearchDecodedFilters & typeof state
                > = {};
                const { filters } = domainConfig;

                if (filters.isAccountLevelDashboard)
                    initFilters.level = Level.account;
                else initFilters.level = Level.conversation;

                if (filters.defaultAuditManual)
                    initFilters.auditType = AuditType.manual;

                if (isDefined(filters.processingStatus))
                    initFilters.processingStatus = filters.processingStatus;
                else initFilters.processingStatus = null;

                const { durationOptions } = state;
                const durationKey = Object.keys(state.durationOptions).find(
                    (key) => {
                        const [min, max] = durationOptions[key].value;
                        return (
                            min === filters.defaultDurationRange?.[0] &&
                            max === filters.defaultDurationRange?.[1]
                        );
                    }
                );

                if (isDefined(filters.defaultDurationRange) && !durationKey) {
                    initFilters.minDuration =
                        filters.defaultDurationRange?.[0] ?? null;
                    initFilters.maxDuration =
                        filters.defaultDurationRange?.[1] ?? null;
                    const { name: durationName, value: durationValue } =
                        getCustomDurationFilter(
                            initFilters.minDuration,
                            initFilters.maxDuration
                        );

                    initFilters.durationKey = durationName;
                    initFilters.durationOptions = {
                        ...state.durationOptions,
                        [durationName]: {
                            value: durationValue,
                            name: durationName,
                        },
                    };
                }

                initFilters.meetingType = filters?.meeting_type ?? "call";

                updateState({
                    ...initFilters,
                    durationKey:
                        initFilters.meetingType !== "call"
                            ? durationkeys.any
                            : initFilters.durationKey ??
                              durationKey ??
                              state.durationKey,
                    dateKey:
                        filters?.defaultDate ??
                        GlobalFiltersInitialState.dateKey,
                });
                handleAppliedButtonClick();
                hasSetDefaultFilters.current = true;
            }
        }
    }, [domainConfig, defaultView, filters]);

    useEffect(() => {
        if (hasSetDefaultFilters.current) return;
        if (isDefined(shareId) && isDefined(shareData)) {
            setFilters(shareData.json_filters);
            hasSetDefaultFilters.current = true;
        }
    }, [shareData]);

    useEffect(() => {
        if (hasSetDefaultFilters.current) return;
        if (isDefined(trackerId) && isDefined(alertData)) {
            try {
                const json_filters: ConversationView["json_filters"] =
                    JSON.parse(
                        alertData.search_json
                    ) as ConversationView["json_filters"];
                const filters = decodeConversationListFilters(
                    json_filters.filter(
                        (filter) => filter.name !== "custom_data"
                    ) as SearchFilter[]
                );

                const startDate = query.get("min_time")?.split(" ")?.[0];
                const endDate = query.get("max_time")?.split(" ")?.[0];
                updateState(
                    setDurationDateFiltersOnDecodedFilters({
                        state,
                        filters: {
                            ...filters,
                            ...(isDefined(startDate) && {
                                startDate: absoluteDateField(
                                    new Date(startDate).toISOString()
                                ),
                            }),
                            ...(isDefined(endDate) && {
                                endDate: absoluteDateField(
                                    new Date(endDate).toISOString(),
                                    false
                                ),
                            }),
                        },
                    })
                );
                handleAppliedButtonClick();
                hasSetDefaultFilters.current = true;
            } catch {}
        }
    }, [alertData]);
    if (isDomainConfigLoading) {
        return <PageLoader />;
    }
    if (
        isPathConversationList &&
        (defaultViewLoading || shareDataIsLoading || alertDataIsLoading) &&
        !hasSetDefaultFilters.current
    ) {
        return <PageLoader />;
    }

    return <>{children}</>;
}
