import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    Entity,
    EntityField,
    EntitySequencePayload,
} from "@convin/type/Entity";
import { isDefined } from "@convin/utils/helper/common.helper";

export const entityApiSlice = createApi({
    reducerPath: "entityApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["Entity"],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getCustomEntities: builder.query<Entity[], void>({
            query: () => "gpt/nerconfigs/",
            providesTags: ["Entity"],
        }),
        getCustomEntityById: builder.query<Entity, number>({
            query: (id) => `gpt/nerconfigs/${id}/`,
        }),
        createCustomEntity: builder.mutation<Entity, EntityField>({
            query: ({ ...payload }) => ({
                url: "gpt/nerconfigs/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        entityApiSlice.util.updateQueryData(
                            "getCustomEntities",
                            undefined,
                            (draft) => {
                                Object.assign(draft, [...draft, data]);
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        updateCustomEntity: builder.mutation<
            Entity,
            Pick<Entity, "id"> & Partial<EntityField>
        >({
            query: ({ id, ...payload }) => ({
                url: `gpt/nerconfigs/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(
                { id, is_disabled },
                { dispatch, queryFulfilled }
            ) {
                const patchResult = dispatch(
                    entityApiSlice.util.updateQueryData(
                        "getCustomEntities",
                        undefined,
                        (draft) => {
                            Object.assign(
                                draft,
                                draft.map((e) =>
                                    e.id === id ? { ...e, is_disabled } : e
                                )
                            );
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        entityApiSlice.util.updateQueryData(
                            "getCustomEntityById",
                            id,
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateEntitySequence: builder.mutation<
            { status: boolean },
            EntitySequencePayload
        >({
            query: (payload) => ({
                url: "gpt/entity/seq_no/bulk_update/",
                method: "PATCH",
                body: payload,
            }),
        }),
        deleteCustomEntity: builder.mutation<void, number>({
            query: (id) => ({
                url: `gpt/nerconfigs/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                // const { data: deletedPhrase } = await queryFulfilled;
                const deleteResult = dispatch(
                    entityApiSlice.util.updateQueryData(
                        "getCustomEntities",
                        undefined,
                        (draft) => draft.filter((e) => e.id !== id)
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetCustomEntitiesQuery,
    useGetCustomEntityByIdQuery,
    useCreateCustomEntityMutation,
    useUpdateCustomEntityMutation,
    useUpdateEntitySequenceMutation,
    useDeleteCustomEntityMutation,
} = entityApiSlice;
