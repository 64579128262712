import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";

import MinMaxInputField from "../../../MinMaxInputField";

const QuestionsFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <>
            <MinMaxInputField
                showLabel
                name="Number of Questions by Rep"
                values={filtersState.questions.byOwner}
                setValues={(val) =>
                    updateFiltersState({
                        questions: {
                            ...filtersState.questions,
                            byOwner: val,
                        },
                    })
                }
                minLimit={0}
            />
            <MinMaxInputField
                showLabel
                name="Number of Questions by Other Parties"
                values={filtersState.questions.byClient}
                setValues={(val) =>
                    updateFiltersState({
                        questions: {
                            ...filtersState.questions,
                            byClient: val,
                        },
                    })
                }
                minLimit={0}
            />
        </>
    );
};

export default QuestionsFilter;
