import { Label } from "@convin/components/custom_components/Typography/Label";
import {
    findImageUrl,
    scrollElementIntoView,
} from "@convin/utils/helper/common.helper";
import {
    formatTimeHHMMSS,
    getDateTime,
} from "@convin/utils/helper/date.helper";
import { Box, Typography, alpha } from "@mui/material";
import useCommentsDrawerStateContext from "../hooks/useCommentsDrawerStateContext";
import { PropsWithChildren } from "react";
import useConversationStateContext from "@convin/modules/conversationDetails/hooks/useConversationStateContext";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { Comment } from "@convin/type/Common";
import useMediaPlayerContext from "@convin/modules/conversationDetails/hooks/useMediaPlayerContext";
import { ConversationDetails } from "@convin/type/Conversation";
import { useNavigate, useParams } from "react-router-dom";

const account = DashboardRoutesConfig["Account"];

type Props = PropsWithChildren<{
    timestamp: number;
    meeting_type?: ConversationDetails["meeting_type"];
    conversation_id?: number;
}>;

export default function CommentTranscriptPreview({
    transcript,
}: {
    transcript: NonNullable<Comment["transcript"]>;
}) {
    const { monologue_text, timestamp, start_time, conversation } = transcript;
    const { dashboardType } = useCommentsDrawerStateContext();

    if (dashboardType !== "conversation" && dashboardType != "account") {
        return null;
    }

    const imageUrl = findImageUrl(monologue_text ?? "");

    return (
        <ClickEventListener
            timestamp={
                conversation?.meeting_type === "chat" ? timestamp : start_time
            }
            meeting_type={conversation?.meeting_type}
            conversation_id={conversation?.id}
        >
            <Box
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.textColors["666"], 0.1),
                    px: 2,
                    py: 1.5,
                    borderRadius: "12px",
                    mb: 1,
                })}
                className="flex flex-col"
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Image in Chat"
                        className="max-w-[230px] rounded-md h-auto "
                    />
                ) : (
                    <Label
                        variant="medium"
                        colorType={"666"}
                        isEllipses
                        className="w-[90%]"
                        dangerouslySetInnerHTML={{
                            __html: monologue_text,
                        }}
                        sx={{
                            maxHeight: "50px",
                        }}
                    />
                )}

                <Typography
                    variant="extraSmall"
                    className="flex items-center justify-end"
                    color="textColors.999"
                >
                    {conversation?.meeting_type === "call"
                        ? formatTimeHHMMSS(start_time)
                        : getDateTime({
                              isoDate: timestamp * 1000,
                              format: "T",
                          }).toLowerCase()}
                </Typography>
            </Box>
        </ClickEventListener>
    );
}

const ClickEventListener: React.FC<Props> = ({ children, ...rest }) => {
    const { dashboardType } = useCommentsDrawerStateContext();

    return dashboardType === "conversation" ? (
        <ConversationCommentPreview {...rest}>
            {children}
        </ConversationCommentPreview>
    ) : dashboardType === "account" ? (
        <AccountCommentPreview {...rest}>{children}</AccountCommentPreview>
    ) : (
        <></>
    );
};

const ConversationCommentPreview: React.FC<Props> = ({
    timestamp,
    meeting_type,
    children,
}) => {
    const { handleSeek } = useMediaPlayerContext();
    const { handleActiveLeftTab } = useConversationStateContext();
    return (
        <div
            onClick={() => {
                if (meeting_type === "call") handleSeek(timestamp);
                else scrollElementIntoView(`[data-index="${timestamp}"]`);

                handleActiveLeftTab("transcript");
            }}
            className="cursor-pointer"
        >
            {children}
        </div>
    );
};

const AccountCommentPreview: React.FC<Props> = ({
    children,
    conversation_id,
    timestamp,
    meeting_type,
}) => {
    const navigate = useNavigate();
    const { handleOpenCommentsDrawer } = useCommentsDrawerStateContext();

    const { account_id } = useParams<{
        account_id: string;
    }>();

    const basePath =
        meeting_type === "call"
            ? `${
                  account.path
              }/${account_id}/conversation/${conversation_id}?start_time=${timestamp
                  .toString()
                  .trim()}`
            : `${
                  account.path
              }/${account_id}/conversation/${conversation_id}?timestamp=${timestamp
                  .toString()
                  .trim()}`;

    return (
        <div
            onClick={() => {
                navigate(basePath);
                handleOpenCommentsDrawer(false);
            }}
            className="cursor-pointer"
        >
            {children}
        </div>
    );
};
