import CommentSvg from "@convin/components/svg/CommentSvg";
import { Backdrop, Box, alpha, styled } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import {
    Dispatch,
    SetStateAction,
    memo,
    useEffect,
    useRef,
    useState,
} from "react";
import { motion } from "framer-motion";
import { isDefined } from "@convin/utils/helper/common.helper";
import CommentsInterface from "./components/CommentsInterface";

import { createContext } from "react";
import { Comment } from "@convin/type/Common";
import useCommentsDrawerStateContext from "./hooks/useCommentsDrawerStateContext";
import { AuditSheetProviderValue } from "../AuditSheet/context/AuditSheetContextProvider";

export type CommentState = {
    id: number;
    dashboard_type: "account" | "conversation" | "qms";
    commentToReply?: Comment;
    setCommentToReply: Dispatch<SetStateAction<Comment | undefined>>;
    type: "comment" | "auditDisputeComment";
    auditVariables?: AuditSheetProviderValue["auditVariables"] & {
        is_ai_rated?: boolean;
    };
};

export const CommentsStateContext = createContext<CommentState | undefined>(
    undefined
);

export const RootStyle = styled(motion.div)(({ theme }) => ({
    bottom: 0,
    right: 0,
    display: "flex",
    position: "fixed",
    overflow: "hidden",
    width: "480px",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === "light"
            ? theme.palette.grey[500]
            : theme.palette.common.black,
        0.16
    )}`,
    background: theme.palette.background.paper,
}));

export default memo(
    function CommentsComponent(
        props: Required<Pick<CommentState, "id" | "dashboard_type" | "type">> &
            Partial<Pick<CommentState, "auditVariables">>
    ) {
        const [commentToReply, setCommentToReply] = useState<
            Comment | undefined
        >();
        const {
            isCommentsDrawerOpen: open,
            handleOpenCommentsDrawer: setOpen,
        } = useCommentsDrawerStateContext();
        const buttonRef = useRef<HTMLButtonElement | null>(null);
        useEffect(() => {
            if (open) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "unset";
            }
        }, [open]);

        const handleClose = () => {
            setOpen(false);
        };

        const handleToggle = () => {
            setOpen(!open);
        };

        return (
            <CommentsStateContext
                value={{ ...props, commentToReply, setCommentToReply }}
            >
                <Backdrop
                    open={open}
                    onClick={handleClose}
                    sx={{
                        background: "transparent",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                />
                <Box
                    component={"button"}
                    sx={{
                        color: open ? "primary.main" : "textColors.666",
                    }}
                    onClick={handleToggle}
                    ref={buttonRef}
                    aria-label="open-comments-btn"
                >
                    <CommentSvg />
                </Box>
                <AnimatePresence>
                    {open && (
                        <>
                            <RootStyle
                                initial={{ x: 0, y: -1000, opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 0,
                                        ease: [0.43, 0.13, 0.23, 0.96],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    y: -1000,
                                    transition: {
                                        duration: 0,
                                        ease: [0.43, 0.13, 0.23, 0.96],
                                    },
                                }}
                                className="flex flex-col"
                                sx={(theme) => ({
                                    ...(isDefined(buttonRef.current) && {
                                        marginRight: theme.spacing(1.5),
                                        top:
                                            buttonRef?.current?.getBoundingClientRect()
                                                .top +
                                            buttonRef.current?.getBoundingClientRect()
                                                .height +
                                            16,
                                    }),
                                    mb: 0,
                                })}
                            >
                                <CommentsInterface />
                            </RootStyle>
                        </>
                    )}
                </AnimatePresence>
            </CommentsStateContext>
        );
    },
    () => false
);
