import {
    Stack,
    SxProps,
    TextField,
    TextFieldVariants,
    Theme,
} from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { NumericRange } from "@convin/type/Common";
import { PropsWithChildren, useMemo } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";

type Props = {
    values: NumericRange;
    setValues: (val: NumericRange) => void;
    minLabel?: string;
    minLimit?: number;
    maxLabel?: string;
    maxLimit?: number;
    sx?: SxProps<Theme>;
    variant?: TextFieldVariants;
    isSizeFull?: boolean;
    showOnly?: "MIN" | "MAX";
} & (
    | {
          showLabel: true;
          name: string;
      }
    | {
          showLabel?: boolean;
          name?: string;
      }
);

const LabelWrapper: React.FC<
    PropsWithChildren<{ name?: string; sx: SxProps<Theme> }>
> = ({ name, sx, children }) => {
    return name ? (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={sx}
            gap={1.5}
        >
            <Label colorType="666" variant="medium" sx={{ fontWeight: 500 }}>
                {name}
            </Label>
            {children}
        </Stack>
    ) : (
        children
    );
};

const MinMaxInputField = ({
    name,
    values,
    setValues,
    minLabel = "Min",
    minLimit,
    maxLabel = "Max",
    maxLimit,
    sx = {},
    showLabel,
    variant,
    isSizeFull,
    showOnly,
}: Props) => {
    const [minValue, maxValue] = useMemo(() => values, [values]);

    return (
        <LabelWrapper {...{ name, sx }}>
            <Stack
                direction="row"
                className={`${showLabel ? "w-64" : "w-full"}`}
                gap={2}
            >
                {isDefined(showOnly) && showOnly === "MAX" ? null : (
                    <TextField
                        type="number"
                        variant={variant || "filled"}
                        label={minLabel}
                        className={`"flex-1 ${
                            isSizeFull ? "w-full" : "w-[120px] max-w-[120px]"
                        }`}
                        value={minValue ?? ""}
                        onChange={(e) => {
                            setValues([
                                e.target.value === ""
                                    ? null
                                    : Number(e.target.value),
                                maxValue,
                            ]);
                        }}
                        onBlur={() => {
                            if (minValue === null) return;
                            const val = Number(minValue);
                            const max = maxValue ?? maxLimit;
                            if (
                                minLimit !== undefined &&
                                Number(val) < minLimit
                            )
                                return setValues([minLimit, maxValue]);
                            if (max !== undefined && Number(val) > max)
                                return setValues([max, maxValue]);
                            setValues([Number(val), maxValue]);
                        }}
                        inputProps={{
                            min: minLimit,
                            max: maxValue ?? maxLimit,
                        }}
                    />
                )}
                {isDefined(showOnly) && showOnly === "MIN" ? null : (
                    <TextField
                        type="number"
                        variant={variant || "filled"}
                        label={maxLabel}
                        className={`"flex-1 ${
                            isSizeFull ? "w-full" : "w-[120px] max-w-[120px]"
                        }`}
                        value={maxValue ?? ""}
                        onChange={(e) =>
                            setValues([
                                minValue,
                                e.target.value === ""
                                    ? null
                                    : Number(e.target.value),
                            ])
                        }
                        onBlur={() => {
                            if (maxValue === null) return;
                            const val = Number(maxValue);
                            const min = minValue ?? minLimit;
                            if (min !== undefined && Number(val) < min)
                                return setValues([minValue, min]);
                            if (
                                maxLimit !== undefined &&
                                Number(val) > maxLimit
                            )
                                return setValues([minValue, maxLimit]);
                            setValues([minValue, Number(val)]);
                        }}
                        inputProps={{
                            min: minValue ?? minLimit,
                            max: maxLimit,
                        }}
                    />
                )}
            </Stack>
        </LabelWrapper>
    );
};

export default MinMaxInputField;
