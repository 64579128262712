import React, { lazy, Suspense, useEffect } from "react";
import "./app/static/styles/main.scss";
import { Typography } from "@mui/material";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import GuestGuard from "@convin/components/guards/GuestGuard";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import Home from "@container/Home/Home";
import PreAuthLayout from "@convin/modules/PreAuth/Layout/PreAuthLayout";
import Signup from "@convin/modules/PreAuth/Signup";
import useVitePreloadError from "@convin/hooks/useVitePreloadError";
import useSetAuthTokens from "@convin/hooks/useSetAuthTokens";
import GenerateResetPasswordLink from "@convin/modules/PreAuth/GenerateResetPasswordLink";
import ResetPassword from "@convin/modules/PreAuth/ResetPassword";
import VerifyOtp from "@convin/modules/PreAuth/VerifyOtp";
import CreateDomain from "@convin/modules/PreAuth/CreateDomain";
import { getDomain } from "@convin/utils/helper/common.helper";
import FindDomain from "@convin/modules/PreAuth/FindDomain";
import InternalSignin from "@convin/modules/PreAuth/Signin/InternalSignin";
import Configuration from "@convin/modules/PreAuth/Configuration";
import useOnlineStatus from "@convin/hooks/useOnlineStatus";
import { useAppUpdateNotification } from "@convin/hooks/useAppUpdateNotification";
import { FallbackUI } from "@presentational/reusables";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import SuccessMail from "@convin/modules/PreAuth/SuccessMail";
import useAuth from "@convin/hooks/useAuth";
import Signin from "@convin/modules/PreAuth/Signin";
import AuthGuard from "@convin/components/guards/AuthGuard";

const PreviewCall = lazy(() => import("@convin/modules/previewCall"));

export default function App() {
    const { data: domainConfig } = useGetDomainConfigQuery();
    const { isAuthenticated } = useAuth();
    useOnlineStatus();
    useAppUpdateNotification();
    useVitePreloadError();
    useSetAuthTokens();
    const isApplicationDomain = import.meta.env.VITE_APP_APP_DOMAIN?.split(
        " "
    )?.includes(getDomain());

    useEffect(() => {
        window.addEventListener("storage", (event) => {
            if (event.storageArea !== localStorage) return;
            if (event.key === "authTokens" || event.key === "isTokenExpired") {
                window.location.reload();
            }
        });
        return () => {
            window.removeEventListener("storage", () => {});
        };
    }, []);

    return (
        <Suspense fallback={<FallbackUI />}>
            <div className="w-full h-full flex flex-col">
                <Routes>
                    {isApplicationDomain ? (
                        <>
                            <Route
                                index
                                element={
                                    <GuestGuard>
                                        <PreAuthLayout
                                            Footer={
                                                <Typography
                                                    component={Link}
                                                    to={
                                                        DashboardRoutesConfig[
                                                            "FindDomain"
                                                        ].to
                                                    }
                                                >
                                                    Already have an
                                                    organization?{" "}
                                                    <Typography
                                                        component="span"
                                                        color="primary.main"
                                                    >
                                                        Sign in now
                                                    </Typography>
                                                </Typography>
                                            }
                                            title="Let's Get Started!"
                                            subtitle={`Sign up and start transforming your work with ${
                                                domainConfig?.brand || "Convin"
                                            }`}
                                        >
                                            <CreateDomain />
                                        </PreAuthLayout>
                                    </GuestGuard>
                                }
                            />
                            <Route
                                element={
                                    <GuestGuard>
                                        <PreAuthLayout
                                            Footer={
                                                <Typography
                                                    component={Link}
                                                    to={
                                                        DashboardRoutesConfig[
                                                            "CreateDomain"
                                                        ].to
                                                    }
                                                >
                                                    Don&apos;t have an
                                                    organization?{" "}
                                                    <Typography
                                                        component="span"
                                                        color="primary.main"
                                                    >
                                                        Create one now
                                                    </Typography>
                                                </Typography>
                                            }
                                        >
                                            <FindDomain />
                                        </PreAuthLayout>
                                    </GuestGuard>
                                }
                                path={DashboardRoutesConfig["FindDomain"].path}
                            />
                        </>
                    ) : (
                        <Route
                            element={
                                <Navigate
                                    to={DashboardRoutesConfig["Signin"].to}
                                />
                            }
                            index
                        />
                    )}
                    <Route
                        path={DashboardRoutesConfig["Signin"].path}
                        element={<Signin />}
                    />
                    <Route
                        path={DashboardRoutesConfig["Signup"].path}
                        element={
                            <GuestGuard>
                                <PreAuthLayout
                                    Footer={
                                        <Typography
                                            component={Link}
                                            to={
                                                DashboardRoutesConfig["Signin"]
                                                    .to
                                            }
                                        >
                                            Already have an account?{" "}
                                            <Typography
                                                component="span"
                                                color="primary.main"
                                            >
                                                Sign in now
                                            </Typography>
                                        </Typography>
                                    }
                                    showTitle={false}
                                >
                                    <Signup />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={
                            DashboardRoutesConfig["GenerateResetPasswordLink"]
                                .path
                        }
                        element={
                            <GuestGuard>
                                <PreAuthLayout>
                                    <GenerateResetPasswordLink />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={DashboardRoutesConfig["VerifyOtp"].path}
                        element={
                            <GuestGuard>
                                <PreAuthLayout>
                                    <VerifyOtp />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={DashboardRoutesConfig["ResetPassword"].path}
                        element={
                            <GuestGuard>
                                <PreAuthLayout>
                                    <ResetPassword />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={DashboardRoutesConfig["InternalSignin"].path}
                        element={
                            <GuestGuard>
                                <PreAuthLayout
                                    title="Convin Internal Login"
                                    subtitle="Log in to access your dashboard and insights"
                                    Footer={
                                        <Typography
                                            component={Link}
                                            to={
                                                DashboardRoutesConfig["Signin"]
                                                    .to
                                            }
                                            sx={{
                                                color: "#1A62F2",
                                                textDecoration:
                                                    "underline !important",
                                                textDecorationColor: "#1A62F2",

                                                fontWeight: 500,
                                            }}
                                        >
                                            Go Back
                                        </Typography>
                                    }
                                >
                                    <InternalSignin />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={DashboardRoutesConfig["Configuration"].path}
                        element={
                            <GuestGuard>
                                <PreAuthLayout
                                    showTitle={false}
                                    sx={{
                                        "& .domain__name": {
                                            display: "none",
                                        },
                                        "& .domain__name + .MuiDivider-root": {
                                            display: "none",
                                        },
                                    }}
                                >
                                    <Configuration />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />
                    <Route
                        path={"/success_sent"}
                        element={
                            <GuestGuard>
                                <PreAuthLayout
                                    sx={{
                                        "& .domain__name": {
                                            display: "none",
                                        },
                                        "& .domain__name + .MuiDivider-root": {
                                            display: "none",
                                        },
                                    }}
                                >
                                    <SuccessMail />
                                </PreAuthLayout>
                            </GuestGuard>
                        }
                    />

                    <Route
                        path={DashboardRoutesConfig["Share"].path}
                        element={
                            isAuthenticated ? (
                                <AuthGuard>
                                    <PreviewCall />
                                </AuthGuard>
                            ) : (
                                <GuestGuard>
                                    <PreviewCall />
                                </GuestGuard>
                            )
                        }
                    />

                    {isAuthenticated ? (
                        <Route
                            path={"/*"}
                            element={
                                <AuthGuard>
                                    <Home />
                                </AuthGuard>
                            }
                        />
                    ) : (
                        <Route path={"/*"} element={<Signin />} />
                    )}
                </Routes>
            </div>
        </Suspense>
    );
}
