import {
    Box,
    Button,
    Checkbox,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import { Label } from "@convin/components/custom_components/Typography/Label";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import RepSelectorSvg from "@convin/components/svg/OverallFiters/RepSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { UserType } from "@convin/type/User";
import { getUserName, isDefined } from "@convin/utils/helper/common.helper";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox, filterPopoverSx } from "../../../styles";
import SearchField from "../../SearchField";
import { useLocation } from "react-router-dom";
import { NonUndefined } from "react-hook-form";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import { useGetRestrictedUsersQuery } from "@convin/redux/services/settings/users.service";
import { convertedTenureBuckets } from "@convin/utils/helper/search.helper";

interface RepFilterProps {
    filterStyle?: "custom";
    repsList?: Array<
        Pick<UserType, "first_name" | "last_name" | "email" | "id">
    >;
}

const LabelWithCheckbox: React.FC<{
    data: NonUndefined<RepFilterProps["repsList"]>[0];
    onClick: (id: number) => void;
    selected: number[];
}> = React.memo(
    function LabelWithCheckbox({ data, onClick, selected }) {
        return (
            <ListItemButton
                sx={{
                    p: 0,
                    width: "100%",
                }}
                onClick={() => {
                    onClick(data.id);
                }}
                component="button"
            >
                <Checkbox checked={selected.includes(data.id)} />
                <ListItemText primary={getUserName(data)} />
            </ListItemButton>
        );
    },
    (prev, next) =>
        prev.data.id === next.data.id && prev.selected === next.selected
);

const RepFilter = React.memo(
    function RepFilter({
        filterStyle,
        repsList,
    }: RepFilterProps): ReactElement {
        const theme = useTheme();
        const location = useLocation();

        const { state, updateState, timeStampWhenAppliedButtonWasClicked } =
            useGlobalFiltersStateContext();
        const { appliedFilterKeys, dashboardType } =
            useFiltersVisibilityContext();
        const isAccountDashboard = dashboardType === "Account";

        const tenure_range = useMemo(() => {
            return appliedFilterKeys?.includes("agentTenure")
                ? convertedTenureBuckets(state.agentTenure)
                : undefined;
        }, [
            timeStampWhenAppliedButtonWasClicked,
            state.reps,
            appliedFilterKeys,
        ]);

        const { data, isLoading } = useGetRestrictedUsersQuery(
            tenure_range ? { tenure_range } : undefined,
            {
                skip: isDefined(repsList),
            }
        );

        const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

        const selectedRepsKey = isAccountDashboard ? "accountReps" : "reps";
        const selectedTeamsKey = isAccountDashboard ? "accountTeams" : "teams";

        const [selectedReps, setSelectedReps] = useState<number[]>(
            state[selectedRepsKey] || []
        );

        const [searchValue, setSearchValue] = useState("");

        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        const repsListSet = new Set(repsList?.map((e) => e.id) ?? []);

        useEffect(() => {
            setSelectedReps(state[selectedRepsKey]);
        }, [state.reps, state.accountReps, isAccountDashboard]);

        const handleClick = useCallback(
            (event: React.MouseEvent<HTMLDivElement>): void => {
                setAnchorEl(event.currentTarget);
            },
            []
        );

        const handleClose = useCallback((): void => {
            setSearchValue("");
            setAnchorEl(null);
        }, []);

        const filteredData = isDefined(repsList)
            ? repsList?.filter((user) =>
                  getUserName(user)
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
              )
            : data
                  ?.filter((user) =>
                      getUserName(user)
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                  )
                  ?.filter((e) =>
                      state[selectedTeamsKey].length
                          ? state[selectedTeamsKey].includes(e.team)
                          : true
                  ) || [];

        const handleChange = useCallback((id: number): void => {
            setSelectedReps((prev) =>
                prev.includes(id)
                    ? prev.filter((item) => item !== id)
                    : [...prev, id]
            );
        }, []);
        const filterBoxStyles =
            filterStyle === "custom"
                ? {
                      background: "inherit",
                      color: theme.palette.textColors[666],
                      border: "1px solid #999",
                  }
                : {};
        const handleApply = () => {
            updateState({ [selectedRepsKey]: selectedReps });
            if (
                selectedReps.length === 1 &&
                location.pathname?.includes("home")
            ) {
                const teamId = data?.find(
                    (r) => r.id === selectedReps[0]
                )?.team;
                if (teamId) {
                    updateState({
                        [selectedTeamsKey]: [teamId],
                    });
                }
            }
            handleClose();
            if (state.agentTenure) {
                updateState({
                    agentTenure: null,
                });
            }
        };

        return (
            <>
                <StyledFilterBox
                    onClick={handleClick}
                    aria-describedby={id}
                    sx={filterBoxStyles}
                >
                    <RepSelectorSvg />
                    <Label
                        isEllipses
                        hasTooltip
                        colorType={filterStyle === "custom" ? "666" : "white"}
                        className="flex-1 font-medium"
                        variant="medium"
                        tooltipNode={
                            <Box>
                                {state[selectedRepsKey].length === 0
                                    ? isAccountDashboard
                                        ? "All Owners"
                                        : "All Reps"
                                    : (repsList ?? data)
                                          ?.filter(({ id }) => {
                                              return state[
                                                  selectedRepsKey
                                              ].includes(id);
                                          })
                                          .map((user, idx, arr) => (
                                              <span
                                                  key={user.id}
                                              >{`${getUserName(user)}${
                                                  idx !== arr.length - 1
                                                      ? ", "
                                                      : ""
                                              }`}</span>
                                          ))}
                                {state[selectedRepsKey].length > 0 &&
                                    !(repsList ?? data)?.some(({ id }) =>
                                        state[selectedRepsKey].includes(id)
                                    ) && (
                                        <span>
                                            {isAccountDashboard
                                                ? "All Owners"
                                                : "All Reps"}
                                        </span>
                                    )}
                            </Box>
                        }
                    >
                        {state[selectedRepsKey].length === 0
                            ? isAccountDashboard
                                ? "All Owners"
                                : "All Reps"
                            : state[selectedRepsKey].length === 1
                            ? getUserName(
                                  (repsList ?? data)?.find(
                                      ({ id }) =>
                                          id === state[selectedRepsKey][0]
                                  )
                              ) ||
                              (isAccountDashboard ? "All Owners" : "All Reps")
                            : `Reps(${
                                  isDefined(repsList)
                                      ? state[selectedRepsKey]?.filter((e) =>
                                            repsListSet.has(e)
                                        ).length
                                      : state[selectedRepsKey].length
                              })`}
                    </Label>

                    <Box {...(open && { className: "rotate-180" })}>
                        <TrailingSvg />
                    </Box>
                </StyledFilterBox>
                <CustomPopover
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    sx={{ ...filterPopoverSx(theme, "250px") }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {isLoading ? (
                        <SkeletonLoader />
                    ) : repsList && !repsList.length ? (
                        <Box sx={{ p: 2, textAlign: "center" }}>
                            <Typography variant="medium" className="font-bold">
                                No Reps Found!
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <SearchField
                                value={searchValue}
                                handleChange={setSearchValue}
                                placeholder={`Search ${
                                    isAccountDashboard
                                        ? "Account Owner"
                                        : "Reps"
                                }
                                `}
                            />
                            <ListItemButton
                                sx={{
                                    p: 0,
                                    pt: 1,
                                }}
                                onClick={() => {
                                    setSelectedReps([]);
                                }}
                            >
                                <Checkbox checked={selectedReps.length === 0} />
                                <ListItemText
                                    primary={
                                        isAccountDashboard
                                            ? "All Owners"
                                            : "All Reps"
                                    }
                                />
                            </ListItemButton>
                            <Box
                                className="flex-1"
                                sx={{
                                    height: "300px",
                                    overflow: "auto",
                                }}
                            >
                                <VirtualList<
                                    Pick<
                                        UserType,
                                        | "id"
                                        | "first_name"
                                        | "last_name"
                                        | "email"
                                    >,
                                    {
                                        onClick: (id: number) => void;
                                        selected: number[];
                                    }
                                >
                                    Component={LabelWithCheckbox}
                                    data={filteredData || []}
                                    hasNext={false}
                                    fetchNext={() => {
                                        return;
                                    }}
                                    isLoading={false}
                                    isFetching={false}
                                    sx={{
                                        p: 0,
                                    }}
                                    onClick={handleChange}
                                    selected={selectedReps}
                                />
                            </Box>

                            <Stack
                                direction="row-reverse"
                                sx={{
                                    p: 1.5,
                                }}
                            >
                                <Button
                                    variant="global"
                                    sx={{ px: 1 }}
                                    className="capitalize"
                                    onClick={handleApply}
                                >
                                    Apply
                                </Button>
                                <Button
                                    variant="text"
                                    className="capitalize"
                                    onClick={() => {
                                        setSelectedReps([]);
                                    }}
                                >
                                    Clear all
                                </Button>
                            </Stack>
                        </>
                    )}
                </CustomPopover>
            </>
        );
    },
    (prevProps, nextProps) => {
        // Only re-render if analysedReps or filterStyle changes
        return (
            prevProps.filterStyle === nextProps.filterStyle &&
            JSON.stringify(prevProps.repsList) ===
                JSON.stringify(nextProps.repsList)
        );
    }
);

export default RepFilter;
