import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilitySvg from "@mui/icons-material/Visibility";
import VisibilityOffSvg from "@mui/icons-material/VisibilityOff";
import { FormTextField } from "../components";

interface PasswordFieldProps {
    name: string;
    label: string;
    size?: "small" | "medium";
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function PasswordField({
    name,
    label,
    size,
    onChange,
}: PasswordFieldProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = (): void => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        event.preventDefault();
    };

    return (
        <FormTextField
            name={name}
            type={showPassword ? "text" : "password"}
            label={label}
            size={size}
            onChange={onChange}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className="cursor-pointer"
                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{
                                    color: "textColors.666",
                                }}
                            >
                                {showPassword ? (
                                    <VisibilitySvg />
                                ) : (
                                    <VisibilityOffSvg />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
            className="requiredMark"
        />
    );
}
