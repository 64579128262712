import useWebSocketContextProvider from "@convin/components/websocket/hooks/useWebSocketContextProvider";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import {
    customerIntelligenceApiSlice,
    useLazyGetAiInsightDataQuery,
} from "@convin/redux/services/customerIntelligence/customerIntelligence.service";
import {
    CIFiltersPayload,
    CustomTrackingPayload,
} from "@convin/type/customerIntelligence";
import {
    AIInsightNotification,
    WebSocketMessage,
} from "@convin/type/WebSocketNotification";
import { isDefined } from "@convin/utils/helper/common.helper";
import { convertedTenureBuckets } from "@convin/utils/helper/search.helper";
import { prepareCISearchData } from "@convin/utils/searchData/searchData.utils";
import { showToast } from "@convin/utils/toast";
import { PropsWithChildren, createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const {
    subRoutes: { insights, ai_insights, custom_tracking },
} = DashboardRoutesConfig["CI"];
export interface ICIContext {
    id?: number;
    payload: CustomTrackingPayload;
    cIInsightsPayload: CIFiltersPayload;
    analysisId?: number;
}

const CIContext = createContext<ICIContext>({} as ICIContext);

// ----------------------------------------------------------------------

function CIProvider({
    children,
    analysisId,
}: PropsWithChildren<{
    analysisId?: number;
}>) {
    const location = useLocation();
    useDocumentTitle(
        DashboardRoutesConfig["CI"].subRoutes[
            location.pathname.includes(insights.path)
                ? "insights"
                : location.pathname.includes(ai_insights.path)
                ? "ai_insights"
                : "custom_tracking"
        ].name
    );

    const [fetchAiInsights] = useLazyGetAiInsightDataQuery();
    const { socketRef } = useWebSocketContextProvider();
    const dispatch = useDispatch();

    const { state, timeStampWhenAppliedButtonWasClicked } =
        useGlobalFiltersStateContext();

    const payload = useMemo(() => {
        const {
            meetingType,
            tags,
            stage,
            ciFilter,
            teams,
            reps,
            durationKey,
            dateKey,
            durationOptions,
            dateOptions,
            scoresense,
            agentTenure,
        } = state;

        return {
            ...prepareCISearchData({
                ...(isDefined(meetingType) && { meeting_type: meetingType }),
                tags: tags,
                stage,
                ...(isDefined(teams) && teams.length
                    ? { teams_id: teams }
                    : {}),
                ...(isDefined(reps) && reps.length ? { reps_id: reps } : {}),
                ...(isDefined(durationKey) && {
                    min_duration:
                        durationOptions[durationKey].value[0] === null
                            ? null
                            : (durationOptions[durationKey]
                                  .value[0] as number) * 60,
                    max_duration:
                        durationOptions[durationKey].value[1] === null
                            ? null
                            : (durationOptions[durationKey]
                                  .value[1] as number) * 60,
                }),
                ...(isDefined(dateKey) && {
                    start_date:
                        dateOptions[dateKey].dateRange[0] !== null
                            ? new Date(
                                  dateOptions[dateKey].dateRange[0] as string
                              ).getTime() / 1000
                            : null,
                    end_date:
                        dateOptions[dateKey].dateRange[1] !== null
                            ? new Date(
                                  dateOptions[dateKey].dateRange[1] as string
                              ).getTime() / 1000
                            : null,
                }),
                scoresense,
                ...(agentTenure && {
                    tenure_range: convertedTenureBuckets(agentTenure),
                }),

                ...ciFilter,
            }),
        };
    }, [
        state.teams,
        state.reps,
        state.durationKey,
        state.dateKey,
        state.template,
        state.meetingType,
        timeStampWhenAppliedButtonWasClicked,
    ]);

    const cIInsightsPayload: CIFiltersPayload = useMemo(() => {
        const {
            meetingType,
            teams,
            reps,
            tags,
            durationKey,
            durationOptions,
            dateKey,
            dateOptions,
            stage,
            scoresense,
            agentTenure,
        } = state;
        return {
            meeting_type: meetingType,
            tags_id: tags?.tagIds?.length ? tags?.tagIds : undefined,
            teams_id: teams?.length ? teams : undefined,
            reps_id: reps?.length ? reps : undefined,
            min_duration:
                durationOptions[durationKey].value[0] === null
                    ? null
                    : (durationOptions[durationKey].value[0] as number) * 60,
            max_duration:
                durationOptions[durationKey].value[1] === null
                    ? null
                    : (durationOptions[durationKey].value[1] as number) * 60,
            start_date:
                dateOptions[dateKey].dateRange[0] !== null
                    ? new Date(
                          dateOptions[dateKey].dateRange[0] as string
                      ).getTime() / 1000
                    : null,
            end_date:
                dateOptions[dateKey].dateRange[1] !== null
                    ? new Date(
                          dateOptions[dateKey].dateRange[1] as string
                      ).getTime() / 1000
                    : null,
            stages_id: stage ? [stage] : undefined,
            classification: scoresense.lead ?? undefined,
            gpt_classifications:
                isDefined(scoresense.collection) || isDefined(scoresense.csat)
                    ? [
                          ...(scoresense.collection
                              ? [scoresense.collection]
                              : []),
                          ...(scoresense.csat ? [scoresense.csat] : []),
                      ]
                    : undefined,
            tag_operators: {
                and: tags.logic === "and" ? true : false,
                negate: tags.negate ?? false,
            },
            ...(agentTenure && {
                tenure_range: convertedTenureBuckets(agentTenure),
            }),
        };
    }, [
        state.teams,
        state.reps,
        state.durationKey,
        state.dateKey,
        state.template,
        state.meetingType,
        timeStampWhenAppliedButtonWasClicked,
    ]);

    useEffect(() => {
        socketRef.current?.addEventListener("message", (event) => {
            const data = JSON.parse(event.data) as WebSocketMessage;
            if (
                isDefined(data?.push) &&
                "notification_type" in data.push.message.data
            ) {
                if (data.push.message.data.notification_type === "ai_insight") {
                    try {
                        const response = data.push?.message.data
                            .response as AIInsightNotification["response"];
                        if (
                            response.total_meeting ===
                                response.completed_meeting &&
                            !response.is_foundation
                        ) {
                            showToast({
                                ...CreateUpdateToastSettings,
                                message:
                                    "Your custom insight is ready! You can now view the results.",
                            });
                            fetchAiInsights(cIInsightsPayload);
                        }

                        dispatch(
                            customerIntelligenceApiSlice.util.updateQueryData(
                                "getAiInsightData",
                                cIInsightsPayload,
                                (draft) => {
                                    Object.assign(
                                        draft,
                                        draft.map((e) =>
                                            e.id === response.question_id
                                                ? {
                                                      ...e,
                                                      processed:
                                                          response.is_foundation
                                                              ? ((response.completed_meeting /
                                                                    response.total_meeting) *
                                                                    100) /
                                                                2
                                                              : 50 +
                                                                ((response.completed_meeting /
                                                                    response.total_meeting) *
                                                                    100) /
                                                                    2,
                                                  }
                                                : e
                                        )
                                    );
                                }
                            )
                        );
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        });
        return () => {
            socketRef.current?.removeEventListener("message", () => {});
        };
    }, [socketRef.current, cIInsightsPayload]);

    return (
        <CIContext
            value={{
                payload,
                cIInsightsPayload,
                analysisId,
            }}
        >
            {children}
        </CIContext>
    );
}

export { CIContext, CIProvider };
