import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function OutlookSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 19"
            sx={{ width: 20, height: 19, fill: "none", ...sx }}
        >
            <g clipPath="url(#clip0_4159_29796)">
                <path
                    d="M20 9.76743C20.001 9.61105 19.9202 9.46549 19.7869 9.38372H19.7846L19.7762 9.37906L12.846 5.27674C12.8161 5.25652 12.785 5.23804 12.753 5.2214C12.4854 5.08335 12.1675 5.08335 11.8999 5.2214C11.8679 5.23805 11.8368 5.25652 11.8069 5.27674L4.87671 9.37906L4.86835 9.38372C4.65654 9.51543 4.5916 9.79392 4.72332 10.0057C4.76212 10.0681 4.81558 10.1201 4.87904 10.1572L11.8093 14.2595C11.8393 14.2796 11.8703 14.298 11.9023 14.3149C12.1699 14.4529 12.4877 14.4529 12.7553 14.3149C12.7873 14.298 12.8183 14.2796 12.8483 14.2595L19.7786 10.1572C19.917 10.0765 20.0015 9.9277 20 9.76743Z"
                    fill="#0A2767"
                />
                <path
                    d="M5.68466 7.02837H10.2326V11.1972H5.68466V7.02837ZM19.0698 2.7907V0.883717C19.0807 0.406928 18.7033 0.0114249 18.2265 0H6.42467C5.94788 0.0114249 5.57049 0.406928 5.58141 0.883717V2.7907L12.5581 4.65116L19.0698 2.7907Z"
                    fill="#0364B8"
                />
                <path
                    d="M5.58142 2.79071H10.2326V6.97675H5.58142V2.79071Z"
                    fill="#0078D4"
                />
                <path
                    d="M14.8837 2.79071H10.2325V6.97675L14.8837 11.1628H19.0698V6.97675L14.8837 2.79071Z"
                    fill="#28A8EA"
                />
                <path
                    d="M10.2325 6.97675H14.8837V11.1628H10.2325V6.97675Z"
                    fill="#0078D4"
                />
                <path
                    d="M10.2325 11.1628H14.8837V15.3488H10.2325V11.1628Z"
                    fill="#0364B8"
                />
                <path
                    d="M5.68469 11.1972H10.2326V14.987H5.68469V11.1972Z"
                    fill="#14447D"
                />
                <path
                    d="M14.8837 11.1628H19.0697V15.3488H14.8837V11.1628Z"
                    fill="#0078D4"
                />
                <path
                    d="M19.7869 10.1316L19.7781 10.1363L12.8479 14.034C12.8176 14.0526 12.7869 14.0702 12.7548 14.0861C12.6371 14.1421 12.5093 14.1738 12.379 14.1791L12.0004 13.9577C11.9684 13.9416 11.9374 13.9238 11.9074 13.9042L4.88414 9.89583H4.88089L4.65112 9.76746V17.6577C4.65471 18.1841 5.08423 18.608 5.61065 18.6047H19.0553C19.0632 18.6047 19.0702 18.6009 19.0785 18.6009C19.1897 18.5938 19.2994 18.571 19.4041 18.533C19.4494 18.5139 19.4931 18.4911 19.5348 18.4651C19.566 18.4475 19.6195 18.4089 19.6195 18.4089C19.8577 18.2325 19.9988 17.9541 19.9999 17.6577V9.76746C19.9998 9.91837 19.9184 10.0575 19.7869 10.1316Z"
                    fill="url(#paint0_linear_4159_29796)"
                />
                <path
                    opacity="0.5"
                    d="M19.6279 9.73626V10.22L12.3814 15.2093L4.87906 9.89905C4.87906 9.89649 4.87697 9.8944 4.8744 9.8944L4.18604 9.48045V9.13161L4.46976 9.12695L5.06975 9.47114L5.0837 9.4758L5.13486 9.50836C5.13486 9.50836 12.186 13.5316 12.2046 13.5409L12.4744 13.699C12.4976 13.6897 12.5209 13.6804 12.5488 13.6711C12.5628 13.6618 19.5488 9.7316 19.5488 9.7316L19.6279 9.73626Z"
                    fill="#0A2767"
                />
                <path
                    d="M19.7869 10.1316L19.7781 10.1368L12.8478 14.0344C12.8176 14.053 12.7869 14.0707 12.7548 14.0865C12.4857 14.218 12.1709 14.218 11.9018 14.0865C11.8699 14.0707 11.8389 14.0533 11.8088 14.0344L4.87856 10.1368L4.8702 10.1316C4.73639 10.0591 4.65251 9.91965 4.65112 9.76746V17.6577C4.65445 18.184 5.08383 18.608 5.61015 18.6047C5.61015 18.6047 5.61017 18.6047 5.61019 18.6047H19.0409C19.5672 18.608 19.9966 18.1841 19.9999 17.6577C19.9999 17.6577 19.9999 17.6577 19.9999 17.6577V9.76746C19.9998 9.91837 19.9184 10.0575 19.7869 10.1316Z"
                    fill="#1490DF"
                />
                <path
                    opacity="0.1"
                    d="M12.9488 13.9767L12.8451 14.0349C12.815 14.054 12.784 14.0716 12.752 14.0874C12.6378 14.1435 12.5135 14.1764 12.3865 14.1842L15.0232 17.3023L19.6228 18.4107C19.7488 18.3155 19.849 18.1903 19.9144 18.0465L12.9488 13.9767Z"
                    fill="black"
                />
                <path
                    opacity="0.05"
                    d="M13.4186 13.7125L12.8451 14.0348C12.815 14.054 12.784 14.0715 12.752 14.0874C12.6378 14.1435 12.5135 14.1764 12.3865 14.1841L13.6218 17.5902L19.6241 18.4093C19.8606 18.2317 19.9998 17.9533 20 17.6576V17.5558L13.4186 13.7125Z"
                    fill="black"
                />
                <path
                    d="M5.62321 18.6047H19.0395C19.2459 18.6057 19.4473 18.5405 19.6139 18.4186L11.9999 13.9586C11.968 13.9426 11.9369 13.9247 11.9069 13.9051L4.88367 9.89676H4.88042L4.65112 9.76746V17.6307C4.6506 18.1681 5.08582 18.6041 5.62321 18.6047Z"
                    fill="#28A8EA"
                />
                <path
                    opacity="0.1"
                    d="M11.1627 4.5735V14.4944C11.1619 14.8423 10.9504 15.1549 10.6279 15.2851C10.5279 15.3281 10.4203 15.3502 10.3116 15.3502H4.65112V4.18606H5.58136V3.72095H10.3116C10.7815 3.72273 11.1617 4.10362 11.1627 4.5735Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M10.6976 5.03857V14.9595C10.6988 15.0718 10.675 15.1831 10.6279 15.2851C10.4987 15.6034 10.1901 15.8123 9.84647 15.8139H4.65112V4.18601H9.84647C9.98143 4.18466 10.1144 4.21832 10.2325 4.28369C10.5176 4.42733 10.6975 4.71931 10.6976 5.03857Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M10.6976 5.03857V14.0293C10.6954 14.4989 10.3161 14.8796 9.84648 14.8837H4.65112V4.18601H9.84647C9.98143 4.18466 10.1144 4.21832 10.2325 4.28369C10.5176 4.42733 10.6975 4.71931 10.6976 5.03857Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M10.2325 5.03859V14.0293C10.232 14.4997 9.85174 14.8814 9.38136 14.8837H4.65112V4.18604H9.38135C9.85169 4.18629 10.2328 4.56779 10.2325 5.03813C10.2325 5.03828 10.2325 5.03844 10.2325 5.03859Z"
                    fill="black"
                />
                <path
                    d="M0.852555 4.18604H9.37999C9.85085 4.18604 10.2325 4.56774 10.2325 5.03859V13.566C10.2325 14.0369 9.85085 14.4186 9.37999 14.4186H0.852555C0.381697 14.4186 0 14.0369 0 13.566V5.03859C0 4.56774 0.381708 4.18604 0.852555 4.18604Z"
                    fill="url(#paint1_linear_4159_29796)"
                />
                <path
                    d="M2.66462 7.76187C2.87475 7.31418 3.21392 6.93953 3.63858 6.68606C4.10887 6.41681 4.64434 6.28259 5.18602 6.29815C5.68808 6.28726 6.18348 6.41453 6.61812 6.66605C7.02677 6.90976 7.3559 7.26683 7.56555 7.69396C7.79388 8.16461 7.90767 8.68257 7.89765 9.20559C7.90872 9.75218 7.79164 10.2938 7.55579 10.787C7.34114 11.2294 7.00157 11.5992 6.57905 11.8507C6.12765 12.1099 5.61386 12.2405 5.09347 12.2284C4.58069 12.2407 4.07437 12.112 3.62975 11.8563C3.21755 11.6123 2.8843 11.2548 2.66975 10.8265C2.44007 10.3627 2.32492 9.85053 2.33393 9.33302C2.32437 8.79108 2.43741 8.25399 2.66462 7.76187ZM3.70276 10.2874C3.81479 10.5705 4.00478 10.8159 4.25067 10.9954C4.50112 11.1704 4.80102 11.2607 5.10648 11.253C5.43178 11.2659 5.75243 11.1725 6.01997 10.987C6.26274 10.8081 6.44774 10.562 6.55206 10.2791C6.66868 9.9631 6.72623 9.6284 6.72182 9.29163C6.72543 8.95165 6.67133 8.61352 6.56182 8.29164C6.4651 8.00102 6.28607 7.74476 6.04648 7.55396C5.78565 7.35965 5.46586 7.2611 5.14089 7.2749C4.82882 7.26681 4.5222 7.35783 4.26508 7.53489C4.01504 7.71507 3.82145 7.96271 3.70695 8.24884C3.45294 8.90473 3.45162 9.63157 3.70322 10.2884L3.70276 10.2874Z"
                    fill="white"
                />
                <path
                    d="M14.8837 2.79071H19.0697V6.97675H14.8837V2.79071Z"
                    fill="#50D9FF"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4159_29796"
                    x1="12.3255"
                    y1="9.76746"
                    x2="12.3255"
                    y2="18.6047"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#35B8F1" />
                    <stop offset="1" stopColor="#28A8EA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_4159_29796"
                    x1="1.7776"
                    y1="3.51987"
                    x2="8.45495"
                    y2="15.0848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1784D9" />
                    <stop offset="0.5" stopColor="#107AD5" />
                    <stop offset="1" stopColor="#0A63C9" />
                </linearGradient>
                <clipPath id="clip0_4159_29796">
                    <rect width="20" height="18.6047" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
