import { SxProps, Theme, alpha } from "@mui/material";

export const sxPropOption = (theme: Theme): SxProps => ({
    px: 1.5,
    py: 1.2,
    display: "flex",
    alignItems: "center",
    gap: 1,
    "&:hover": {
        color: theme.palette.textColors[333],
        bgcolor: alpha(theme.palette.primary.main, 0.2),
    },
});

export const pressedStateIconSx = (theme: Theme): SxProps => ({
    color: "textColors.999",
    bgcolor: alpha(theme.palette.textColors[999], 0.1),
    borderRadius: 1,
});

export const sharedGenericSelectStyles = {
    select: (theme: Theme) => ({
        mb: 3,
        background: theme.palette.common.white,
        fontSize: 18,
        fontWeight: 500,
        fieldset: {
            border: "none",
            boxShadow: `0px 4px 20px 0px ${alpha(
                theme.palette.textColors[999],
                0.1
            )}`,
        },
        ".MuiSelect-select": {
            py: 1.6,
        },
        "& .MuiFormHelperText-root": {
            display: "none",
        },
    }),
    menu: (theme: Theme) => ({
        ".MuiMenu-paper": {
            mt: 0.75,
            borderColor: alpha(theme.palette.textColors[999], 0.2),
            boxShadow: `0px 4px 4px 0px ${alpha(
                theme.palette.textColors[999],
                0.1
            )}`,
            ul: {
                color: theme.palette.textColors[666],
            },
            li: {
                p: 1.5,
                fontSize: 18,
            },
        },
        ".Mui-selected": {
            color: theme.palette.primary.main,
        },
    }),
    icon: {
        p: 1,
        width: 32,
        height: 32,
        top: "calc(50% - 0.65em) !important",
        borderRadius: 0.75,
        stroke: (theme: Theme) => theme.palette.common.black,
        transform: "rotate(180deg)",
    },
};
