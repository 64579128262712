import { Checkbox, FormControlLabel, Grid2 } from "@mui/material";
import MediaType from "@convin/modules/lms/knowledgeBase/components/MediaType";
import { Label } from "@convin/components/custom_components/Typography/Label";
import React from "react";
import { getUserName } from "@convin/utils/helper/common.helper";
import { getDateTime } from "@convin/utils/helper/date.helper";
import { alpha } from "@mui/system";
import { LMSMedia } from "@convin/type/lms";
import { useAttachLmsDocuments } from "./context/AttachLmsDocumentsContext";

const FileCard: React.FC<{
    data: LMSMedia;
}> = ({ data }) => {
    const { value, handleUpdate } = useAttachLmsDocuments();
    const { id, file_type, file_name, owner, created, media_type } = data;

    const isSnippet = media_type === "SN";

    const isSelected = value.includes(id);

    const handleCardClick = () => {
        if (!isSelected) {
            handleUpdate([...value, id]);
        } else {
            handleUpdate(value.filter((currId) => currId !== id));
        }
    };

    return (
        <>
            <Grid2
                sx={(theme) => ({
                    p: 2,
                    ml: 0,
                    transition: "0.3s",
                    ...(isSelected && {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                    }),
                    border: `1px solid ${alpha(
                        theme.palette.textColors[999],
                        0.2
                    )}`,
                    borderTop: 0,
                    "& .MuiGrid2-item": {
                        pt: 0,
                    },
                    "&:hover": {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                    },
                })}
                container
                className="cursor-pointer w-full"
                columnSpacing={1}
                onClick={handleCardClick}
            >
                <Grid2 size={{ xs: 5 }} className="flex items-center gap-1">
                    <FormControlLabel
                        control={
                            <Checkbox sx={{ p: 0 }} checked={isSelected} />
                        }
                        label=""
                    />
                    <MediaType
                        url={file_name}
                        {...{ isSnippet, noBorder: true }}
                    />
                    <Label
                        colorType="333"
                        isEllipses
                        hasTooltip
                        className="flex-1 font-medium"
                        variant="body1"
                    >
                        {file_name}
                    </Label>
                </Grid2>
                <Grid2 size={{ xs: 2 }} className="flex items-center ">
                    <Label
                        variant="medium"
                        colorType="666"
                        isEllipses
                        hasTooltip
                    >
                        {file_type}
                    </Label>
                </Grid2>
                <Grid2 size={{ xs: 3 }} className="flex items-center">
                    <Label
                        variant="medium"
                        isEllipses
                        colorType="666"
                        hasTooltip
                    >
                        {getDateTime({
                            isoDate: created,
                            format: "MM dd, YYYY",
                        })}
                    </Label>
                </Grid2>
                <Grid2 size={{ xs: 2 }} className="flex items-center ">
                    <Label
                        variant="medium"
                        isEllipses
                        colorType="666"
                        hasTooltip
                    >
                        {getUserName(owner)}
                    </Label>
                </Grid2>
            </Grid2>
        </>
    );
};

export default FileCard;
