import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Alert,
    Button,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";

import * as Yup from "yup";
import { FormProvider } from "@convin/components/hook-form";
import { FormTextField } from "../components";
import { SsoOptions } from "../components/SsoOptions";
import { useCreateDomainMutation } from "@convin/redux/services/auth/auth.service";
import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useNavigate } from "react-router-dom";

const CreateDomain = () => {
    const navigate = useNavigate();
    const [createDomain, { isLoading, error }] = useCreateDomainMutation();
    const schema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
        name: Yup.string().required("Domain is required"),
    });

    const defaultValues = {
        email: "",
        name: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const {
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = methods;

    // Watch email field for changes
    const email = watch("email");
    // Update domain whenever email changes
    useEffect(() => {
        if (email) {
            const match = email.match(/@([^.]+)/);
            if (match && match[1]) {
                setValue("name", match[1]);
                clearErrors("name");
            } else {
                setValue("name", "");
            }
        } else {
            setValue("name", "");
        }
    }, [email]);

    const onSubmit = async ({
        email,
        name,
    }: {
        email: string;
        name: string;
    }) => {
        createDomain({
            email,
            name,
        })
            .unwrap()
            .then(() => {
                navigate("/success_sent");
            })
            .catch((err) => {
                setError("afterSubmit", {
                    message:
                        err.status === 409
                            ? "Domain already exists! Choose another name."
                            : getErrorMessage(err),
                });
            });
    };

    const disabled =
        !isDefined(error) ||
        (error && "status" in error && error.status !== 409);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="medium" component="div" my={1.5}>
                Enter email address to create a Domain
            </Typography>
            <Stack gap={2}>
                <FormTextField
                    name="email"
                    label="Email Id"
                    autoComplete="true"
                    className="requiredMark"
                />

                <FormTextField
                    name="name"
                    label="Domain"
                    slotProps={{
                        input: {
                            readOnly: disabled,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography>.convin.ai</Typography>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>https://</Typography>
                                </InputAdornment>
                            ),
                        },
                    }}
                    disabled={disabled}
                />
                {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}

                <Button
                    variant="global"
                    fullWidth
                    size="large"
                    type="submit"
                    className="h-[42px]"
                    loading={isLoading}
                >
                    Continue
                </Button>
                <SsoOptions />
            </Stack>
        </FormProvider>
    );
};

export default CreateDomain;
