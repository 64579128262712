import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType } from "@convin/type/Common";
import {
    CreateUpdateDocumentPayload,
    KnowledgeBaseResource,
    LMSGetDocumentsPayload,
    LMSMedia,
} from "@convin/type/lms";
import { AxiosResponse } from "axios";
import {
    getFileNameAndExtension,
    isDefined,
} from "@convin/utils/helper/common.helper";

export const lmsKnowledgeBaseApiSlice = createApi({
    reducerPath: "lmsKnowledgeBaseApiSlice",

    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getLmsResources: builder.query<
            PaginationType<KnowledgeBaseResource>,
            void | { next?: string; isPaginated?: boolean }
        >({
            query: (payload) =>
                payload?.next || isDefined(payload?.isPaginated)
                    ? `/library/category/list/?all_library=true&paginate_results=${payload?.isPaginated}`
                    : "/library/category/list/?all_library=true",
            keepUnusedDataFor: 30,
        }),
        getLmsResourceById: builder.query<KnowledgeBaseResource, string>({
            query: (id) => `/library/category/retrieve/${id}/`,
            keepUnusedDataFor: 0,
        }),
        createLmsFolder: builder.mutation<
            KnowledgeBaseResource,
            Pick<KnowledgeBaseResource, "name">
        >({
            query: (payload) => ({
                url: "/library/category/create/",
                body: payload,
                method: "POST",
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        lmsKnowledgeBaseApiSlice.util.updateQueryData(
                            "getLmsResources",
                            undefined,
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    results: [data, ...draft.results],
                                    count: draft.count + 1,
                                });
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        updateLmsFolder: builder.mutation<
            KnowledgeBaseResource,
            Pick<KnowledgeBaseResource, "name" | "id">
        >({
            query: ({ name, id }) => ({
                url: `/library/category/update/${id}/`,
                body: { name },
                method: "PATCH",
            }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        lmsKnowledgeBaseApiSlice.util.updateQueryData(
                            "getLmsResources",
                            undefined,
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    results: [...draft.results].map((e) =>
                                        e.id === id ? data : e
                                    ),
                                    count: draft.count + 1,
                                });
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        deleteLmsFolder: builder.mutation<void, number>({
            query: (id) => ({
                url: `/library/category/destroy/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const deleteResult = dispatch(
                    lmsKnowledgeBaseApiSlice.util.updateQueryData(
                        "getLmsResources",
                        undefined,
                        (draft) => {
                            return {
                                ...draft,
                                results: draft.results.filter(
                                    (e) => e.id !== id
                                ),
                            };
                        }
                    )
                );

                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
        getResourses: builder.query<
            PaginationType<LMSMedia>,
            LMSGetDocumentsPayload & {
                next?: string;
            }
        >({
            query: ({ next, ...payload }) => ({
                url: next || "/library/documents/",
                method: "POST",
                body: payload,
            }),
            keepUnusedDataFor: 0,
        }),
        getAllResources: builder.query<LMSMedia[], LMSGetDocumentsPayload>({
            query: (payload) => ({
                url: `/library/documents/`,
                method: "POST",
                body: payload,
            }),
        }),
        updateResource: builder.mutation<LMSMedia, Partial<LMSMedia>>({
            query: ({ id, ...payload }) => ({
                url: `/library/file_upload/${id}/`,
                method: "PATCH",
                body: payload,
            }),
        }),
        tagResource: builder.mutation<LMSMedia, Partial<LMSMedia>>({
            query: ({ id, ...payload }) => ({
                url: `/library/library_files/${id}/`,
                method: "PATCH",
                body: payload,
            }),
        }),
        getResource: builder.query<LMSMedia, number>({
            query: (id) => ({
                url: `/library/file_upload/${id}/`,
                method: "GET",
            }),
        }),
        deleteResource: builder.mutation<
            void,
            { id: number } & Pick<
                LMSGetDocumentsPayload,
                | "category_id"
                | "filename"
                | "owners"
                | "ai_agent"
                | "agent_assist"
            >
        >({
            query: ({ id }) => ({
                url: `/library/file_upload_delete/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { category_id, filename, owners, id, ai_agent, agent_assist },
                { dispatch, queryFulfilled }
            ) {
                const deleteResult = dispatch(
                    lmsKnowledgeBaseApiSlice.util.updateQueryData(
                        "getResourses",
                        {
                            category_id,
                            filename,
                            ...(owners?.length && {
                                owners,
                            }),
                            ...(ai_agent && {
                                ai_agent,
                            }),
                            ...(agent_assist && {
                                agent_assist,
                            }),
                        },
                        (draft) => {
                            return {
                                ...draft,
                                results: draft.results.filter(
                                    (e) => +e.id !== +id
                                ),
                            };
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),

        createDocument: builder.mutation<LMSMedia, CreateUpdateDocumentPayload>(
            {
                query: (payload) => ({
                    url: "/library/editor/",
                    method: "POST",
                    body: payload,
                }),
            }
        ),
        getDocument: builder.query<LMSMedia, number>({
            query: (id) => `/library/editor/${id}/`,
            keepUnusedDataFor: 0,
        }),
        updateDocument: builder.mutation<
            LMSMedia,
            Partial<CreateUpdateDocumentPayload> & { id: number }
        >({
            query: ({ id, ...payload }) => ({
                url: `/library/editor/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        lmsKnowledgeBaseApiSlice.util.updateQueryData(
                            "getDocument",
                            id,
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    ...data,
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        publishDocument: builder.mutation<
            LMSMedia,
            { id: number; raw: string }
        >({
            query: ({ id, raw }) => ({
                url: `/library/editor/publish/${id}/`,
                method: "POST",
                body: { raw },
            }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        lmsKnowledgeBaseApiSlice.util.updateQueryData(
                            "getDocument",
                            id,
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    ...data,
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        downloadDocumentPdf: builder.mutation<Blob, { html_content: string }>({
            query: (payload) => ({
                url: "/library/editor/pdf/",
                method: "POST",
                body: payload,
                responseType: "blob",
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data, meta } = await queryFulfilled;
                    const { headers } = meta as Omit<AxiosResponse, "data">;
                    const href = URL.createObjectURL(data);
                    const link = document.createElement("a");

                    const disposition = headers?.["content-disposition"];
                    const fileNameMatch = disposition?.match(
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    );
                    const { fileName } = getFileNameAndExtension(
                        fileNameMatch ? fileNameMatch[1] : "download"
                    );

                    link.href = href;
                    link.setAttribute("download", fileName + ".pdf");
                    link.click();
                    URL.revokeObjectURL(href);
                } catch (err) {}
            },
        }),
        getMedia: builder.query<{ media: string }, number>({
            query: (id) => `/library/meeting/media/${id}/`,
        }),
    }),
});

export const {
    useGetLmsResourcesQuery,
    useGetLmsResourceByIdQuery,
    useCreateLmsFolderMutation,
    useUpdateLmsFolderMutation,
    useGetResoursesQuery,
    useDeleteResourceMutation,
    useDeleteLmsFolderMutation,
    useLazyGetResoursesQuery,
    useUpdateResourceMutation,
    useLazyGetLmsResourcesQuery,
    useCreateDocumentMutation,
    useGetDocumentQuery,
    useUpdateDocumentMutation,
    useTagResourceMutation,
    useDownloadDocumentPdfMutation,
    usePublishDocumentMutation,
    useLazyGetResourceQuery,
    useLazyGetMediaQuery,
    useGetAllResourcesQuery,
} = lmsKnowledgeBaseApiSlice;
