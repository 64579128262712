import { CheckCircle, Close } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { getDateTime } from "@convin/utils/helper/date.helper";
import { BulkUploadData } from "@convin/type/Report";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useDeleteBulkUploadMutation } from "@convin/redux/services/home/analyticsDashboard.service";
import UploadProgressSvg from "@convin/components/svg/Notifications/UploadProgressSvg";
import { BULK_UPLOAD_TYPE } from "@convin/type/WebSocketNotification";

interface Props {
    data: BulkUploadData<BULK_UPLOAD_TYPE>;
    prevIndexedData: BulkUploadData<BULK_UPLOAD_TYPE> | null;
}

const StatusMap: Record<
    Lowercase<BulkUploadData<BULK_UPLOAD_TYPE>["status"]> | "failed",
    {
        Icon: typeof SvgIcon | typeof UploadProgressSvg;
        prefix?: string;
        suffix?: string;
    }
> = {
    completed: {
        suffix: "Updated",
        Icon: CheckCircle,
    },
    failed: {
        suffix: "Updated",
        Icon: ReportIcon,
    },
    pending: {
        prefix: "Updating",
        Icon: UploadProgressSvg,
    },
    processing: {
        prefix: "Updating",
        Icon: UploadProgressSvg,
    },
};

const BulkUploadNotificationCard: React.FC<Props> = ({
    data,
    prevIndexedData,
}) => {
    const [handleDelete] = useDeleteBulkUploadMutation();
    const theme = useTheme();
    const { created, status, id, upload_type, metadata, status_file } = data;
    const task_status = status.toLowerCase() as Lowercase<
        BulkUploadData<BULK_UPLOAD_TYPE>["status"]
    >;
    const statusCheck =
        task_status === "completed" &&
        metadata["failed" as keyof typeof metadata] === metadata.total
            ? "failed"
            : status;
    const Icon =
        StatusMap[statusCheck.toLowerCase() as typeof statusCheck].Icon;
    const color =
        theme.palette.notificationColors[statusCheck] ||
        theme.palette.primary.main;
    const prevDay = isDefined(prevIndexedData)
        ? getDateTime({
              isoDate: prevIndexedData.created,
              format: "dd MM 'YY",
          })
        : null;

    const currDay = getDateTime({
        isoDate: created,
        format: "dd MM 'YY",
    });

    const isToday =
        currDay ===
        getDateTime({
            isoDate: new Date(),
            format: "dd MM 'YY",
        });

    const isYesterday =
        currDay ===
        getDateTime({
            isoDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            format: "dd MM 'YY",
        });

    const displayTime = getDateTime({
        isoDate: created,
        format: "MM dd, T",
    });

    const handleDownload = () => {
        if (isDefined(status_file)) {
            const link = document.createElement("a");
            link.href = status_file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Stack gap={1.5} mb={2}>
            {currDay !== prevDay ? (
                <Label colorType="999" variant="medium" className="font-medium">
                    {isToday ? "Today" : isYesterday ? "Yesterday" : currDay}
                </Label>
            ) : null}

            <Box
                className="flex flex-start justify-center h-[80px]"
                gap={1.5}
                sx={{}}
            >
                <Box
                    sx={{ position: "relative", display: "inline-flex" }}
                    className="h-[40px] w-[40px]"
                >
                    {statusCheck === "pending" && "processed" in metadata && (
                        <CircularProgress
                            variant="determinate"
                            value={
                                (metadata.processed! / metadata.total!) * 100
                            }
                        />
                    )}
                    <Box
                        className="rounded-full h-[40px] w-[40px] flex items-center justify-center"
                        sx={{
                            color,
                            bgcolor: alpha(color, 0.1),
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                        }}
                    >
                        <Icon />
                    </Box>
                </Box>

                <Stack className="flex-grow flex-1 overflow-hidden" gap={0.5}>
                    <Box className="flex items-center justify-between ">
                        <Label
                            colorType="333"
                            className="font-medium"
                            isEllipses
                            hasTooltip
                        >
                            {StatusMap[task_status].prefix}
                            {upload_type === "MANUAL_QMS"
                                ? " Qms Entries "
                                : " User List "}
                            {StatusMap[task_status].suffix}
                        </Label>
                        {task_status === "completed" && (
                            <IconButton
                                className="scale-90"
                                onClick={() => {
                                    handleDelete(id);
                                }}
                                sx={{ p: 0 }}
                            >
                                <Close />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        className="font-medium inline-flex items-center"
                        gap={0.5}
                    >
                        {statusCheck === "completed" ||
                        statusCheck === "failed" ? (
                            <>
                                {statusCheck !== "failed" && (
                                    <Typography
                                        variant="small"
                                        component="span"
                                        color="textColors.666"
                                    >
                                        {"success" in metadata
                                            ? metadata.success
                                            : 0}{" "}
                                        Completed
                                    </Typography>
                                )}

                                {"failed" in metadata && metadata.failed > 0 ? (
                                    <>
                                        {statusCheck !== "failed" && "|"}
                                        <Typography
                                            variant="small"
                                            component="span"
                                            color="danger"
                                        >
                                            {metadata.failed} Failed
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : "processed" in metadata ? (
                            <Typography
                                variant="small"
                                component="span"
                                color="textColors.666"
                            >
                                {`${metadata.processed} out of ${metadata.total} entries updated`}
                            </Typography>
                        ) : (
                            <Label colorType="666" variant="small">
                                ...Processing
                            </Label>
                        )}
                    </Box>
                    <Box
                        className="flex items-center justify-between"
                        sx={{
                            mb: 1.5,
                        }}
                    >
                        {(task_status === "completed" ||
                            statusCheck === "failed") && (
                            <Label colorType="666" variant="small">
                                {displayTime}
                            </Label>
                        )}
                        {isDefined(status_file) &&
                            (statusCheck === "failed" ||
                                (task_status === "completed" &&
                                    "failed" in metadata &&
                                    metadata.failed > 0)) && (
                                <Box className="flex flex-row-reverse h-[20px]">
                                    <Typography
                                        variant="medium"
                                        color="primary.main"
                                        className="underline"
                                        component={Button}
                                        onClick={handleDownload}
                                        p={0}
                                    >
                                        Error Report
                                    </Typography>
                                </Box>
                            )}
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
};

export default BulkUploadNotificationCard;
