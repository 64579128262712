import { pxToRem } from "@convin/utils/getFontValue";
import { ToastOptions, TypeOptions } from "react-toastify";

const CreateUpdateToastSettings: { type: TypeOptions } & ToastOptions = {
    type: "success",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
    hideProgressBar: true,
    position: "bottom-left",
    style: {
        width: "640px",
        height: "48px",
        left: "80px",
        backgroundColor: "#333333",
        fontSize: pxToRem(16),
    },
};

export { CreateUpdateToastSettings };
