import { Box, Divider, Typography } from "@mui/material";
import useCommentsState from "../hooks/useCommentsState";
import { useGetInfiniteRepliesList } from "../hooks/useGetInfiniteRepliesList";
import LeftArrowSvg from "@convin/components/svg/LeftArrowSvg";
import CommentInputStatePorvider from "../context/CommentInputStatePorvider";
import CommentCard from "./CommentCard";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import InfiniteList from "@convin/components/custom_components/InfiniteList/InfiniteList";
import { Comment } from "@convin/type/Common";
import CommentInput from "./CommentInput";

export function RepliesUI() {
    const { commentToReply, setCommentToReply, dashboard_type } =
        useCommentsState();

    const {
        data: comments,
        hasNext,
        fetchNext,
        isLoading,
        isFetching,
        isFetchingNext,
    } = useGetInfiniteRepliesList({
        commentId: commentToReply!.id,
        dashboard_type,
    });

    return (
        <>
            <Box
                sx={{
                    py: 2,
                    px: 2,
                }}
                className="flex items-center flex-shrink-0"
                gap={1}
            >
                <Box
                    onClick={() => setCommentToReply(undefined)}
                    component="button"
                >
                    <LeftArrowSvg />
                </Box>
                <Typography className="font-medium">Reply Thread</Typography>
            </Box>
            <Divider />
            <Box className="flex-1 overflow-y-auto">
                <CommentInputStatePorvider>
                    <Box className="flex-shrink-0">
                        <CommentCard data={commentToReply!} />
                    </Box>
                </CommentInputStatePorvider>

                {isFetching && !isFetchingNext ? (
                    <SkeletonLoader />
                ) : (
                    <>
                        <Box
                            className="flex-1 flex flex-col"
                            sx={() => ({
                                mx: 2,
                            })}
                        >
                            <Typography
                                variant="medium"
                                color="textColors.666"
                                my={2}
                                component="div"
                            >
                                {`Replies (${comments?.count})`}
                            </Typography>
                            <CommentInputStatePorvider>
                                <InfiniteList<Comment>
                                    data={comments?.results}
                                    Component={CommentCard}
                                    hasNext={hasNext}
                                    fetchNext={fetchNext}
                                    isLoading={isLoading}
                                    isFetching={isFetching}
                                    sx={{
                                        p: 0,
                                    }}
                                />
                            </CommentInputStatePorvider>
                        </Box>
                    </>
                )}
            </Box>

            <Box
                className="flex-shrink-0"
                sx={{
                    p: 2.5,
                }}
            >
                <CommentInputStatePorvider>
                    <CommentInput />
                </CommentInputStatePorvider>
            </Box>
        </>
    );
}
