import SearchSvg from "@convin/components/svg/SearchSvg";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import {
    isDefined,
    removeConvinFromString,
} from "@convin/utils/helper/common.helper";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import useGlobalSearchContext from "../hooks/useGlobalSearchContext";
import { useEffect, useState } from "react";
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";
import Notifications from "@convin/modules/notifications";
import { matchPath, useLocation } from "react-router-dom";
import { ProfileMenu } from "./ProfileMenu";
import LogoSvg from "@convin/components/svg/LogoSvg";
import useBranding from "@convin/hooks/useBranding";

export default function GlobalDashboardHeader() {
    const { handleSearchKeyword, searchIsDirty, searchKeyword } =
        useGlobalSearchContext();
    const [keyWord, setKeyWordToSearch] = useState<string>(searchKeyword);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setKeyWordToSearch(event.target.value);
    };
    const location = useLocation();
    const route = matchPath<"dashboard_type", string>(
        "/:dashboard_type/*",
        location.pathname
    );

    const { logo, branding } = useBranding();

    const { checkCanAccess } = useAuth();

    const dashboard = Object.values(DashboardRoutesConfig).find(
        (e) =>
            e.path.toLowerCase() ===
            `${route?.params?.dashboard_type?.toLowerCase()}`
    );
    const pathIsSettings =
        dashboard?.path === DashboardRoutesConfig.Settings.path;
    const pathIsAccounts =
        dashboard?.path === DashboardRoutesConfig.Account.path;

    const canSearch = checkCanAccess({
        heading: PermissionModules.Meeting,
        code_name: "meeting.restrict_search_meeting",
        permission_type: PermissionTypes.edit,
    });

    useEffect(() => {
        setKeyWordToSearch(searchKeyword);
    }, [searchKeyword]);

    return (
        <Box
            className="flex h-[60px] w-full flex-shrink-0 items-center justify-between relative"
            component="header"
            sx={{
                borderBottom: "2px solid",
                borderColor: "divider",
            }}
        >
            <div className="flex items-center">
                <div className="w-[56px] flex-shrink-0 flex items-center justify-center">
                    {logo ? (
                        <img
                            src={logo}
                            alt="logo"
                            className="w-full h-full object-contain"
                        />
                    ) : (
                        <LogoSvg />
                    )}
                </div>
                {isDefined(dashboard?.name) && (
                    <Typography
                        variant="large"
                        className="font-semibold capitalize"
                        component="div"
                        mt={0.5}
                        ml={1.5}
                    >
                        {branding
                            ? removeConvinFromString(dashboard?.name)
                            : dashboard?.name}
                    </Typography>
                )}
            </div>

            {pathIsSettings || pathIsAccounts ? null : (
                <Tooltip
                    title={
                        !canSearch
                            ? "You do not have the permission to access this function"
                            : null
                    }
                >
                    <Box
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                            },
                            top: "50%",
                            left: "50%",
                            transform: "translate(calc(-50% - 60px),-50%)",
                        }}
                        className="flex items-center absolute"
                        gap={1}
                    >
                        <TextField
                            variant="standard"
                            size="small"
                            placeholder="Search here"
                            value={keyWord}
                            onChange={handleChange}
                            onKeyUp={(e): void => {
                                if (e.key === "Enter") {
                                    searchIsDirty.current = true;
                                    handleSearchKeyword(keyWord);
                                }
                            }}
                            slotProps={{
                                input: {
                                    sx: { height: 28, fontSize: 16 },
                                    endAdornment: (
                                        <Box
                                            className="cursor-pointer"
                                            onClick={() => {
                                                searchIsDirty.current = true;
                                                handleSearchKeyword(keyWord);
                                            }}
                                        >
                                            <SearchSvg
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette
                                                            .textColors[333],
                                                    pr: 0.5,
                                                    transform: "scale(1.2)",
                                                }}
                                            />
                                        </Box>
                                    ),
                                },
                            }}
                            sx={{
                                background: "rgba(153, 153, 153, 0.10)",
                                borderRadius: "100px",
                                border: "none",
                                width: "400px",
                                "& .MuiInputBase-root::before,.MuiInputBase-root::after":
                                    {
                                        display: "none",
                                    },
                                px: 1.5,
                                py: 0.5,
                                "& .MuiInputBase-input": {
                                    p: 0,
                                    pr: 2,
                                },
                            }}
                            disabled={!canSearch}
                        />
                    </Box>
                </Tooltip>
            )}
            <Box component="span" className="flex items-center gap-3" mr={3.5}>
                <Notifications />
                <ProfileMenu />
            </Box>
        </Box>
    );
}
