import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import { useCallback } from "react";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const LeadTypeFilter = () => {
    const {
        state: { leadType },
        updateState,
    } = useGlobalFiltersStateContext();

    const handleFilterChange: ({
        type,
        checked,
    }: {
        type: "hot" | "warm" | "cold";
        checked: boolean;
    }) => void = useCallback(
        ({ type, checked }) => {
            if (!checked) {
                updateState({
                    leadType: null,
                });
            } else {
                updateState({
                    leadType: type,
                });
            }
        },
        [leadType]
    );

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={leadType === "hot"}
                        onChange={(e) =>
                            handleFilterChange({
                                type: "hot",
                                checked: e.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                    />
                }
                label={<Typography variant="textSm">Hot</Typography>}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={leadType === "warm"}
                        onChange={(e) =>
                            handleFilterChange({
                                type: "warm",
                                checked: e.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                    />
                }
                label={<Typography variant="textSm">Warm</Typography>}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={leadType === "cold"}
                        onChange={(e) =>
                            handleFilterChange({
                                type: "cold",
                                checked: e.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                    />
                }
                label={<Typography variant="textSm">Cold</Typography>}
            />
        </FormGroup>
    );
};

export default LeadTypeFilter;
