import React from "react";

import CreatorNode from "../CreatorNode";
import CreateSingleResponseBlockType from "./CreateSingleResponseBlockType";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { NodeInfoProvider } from "../../context/NodeInfoContext";

const UI = () => {
    return (
        <CreatorNode>
            <CreateSingleResponseBlockType onNodeSaveCallBack={() => {}} />
        </CreatorNode>
    );
};

const CreateSingleResponseNode: React.FC = () => {
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const { isMultiResponseTree } = useParameterAiConfigContext();
    return isGroupBlockTree || isMultiResponseTree ? (
        <UI />
    ) : (
        <NodeInfoProvider>
            <UI />
        </NodeInfoProvider>
    );
};

export default CreateSingleResponseNode;
