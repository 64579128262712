import * as React from "react";
import { ProfileMenuAvatar } from "./ProfileMenuAvatar";
import "@mui/material/styles";
import useAuth from "@convin/hooks/useAuth";
import { getUserName } from "@convin/utils/helper/common.helper";
import { Paper, Typography, Divider, Box } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Logout from "./Logout";
import { useNavigate } from "react-router-dom";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { pxToRem } from "@convin/utils/getFontValue";
import { Label } from "@convin/components/custom_components/Typography/Label";

interface MenuItemProps {
    icon: React.ComponentType;
    label: string;
    className?: string;
    onClick?: () => void;
}

const menuItems = [
    {
        icon: AccountCircleOutlinedIcon,
        label: "My Profile",
        to: DashboardRoutesConfig.UserProfile.to,
    },
    // {
    //     icon: ActivitySvg,
    //     label: "Activity Log",
    //     to: DashboardRoutesConfig.ActivityLog.to,
    // },
];

export const MenuItem: React.FC<MenuItemProps> = ({
    icon: Icon,
    label,
    className = "",
    onClick,
}) => (
    <div
        className={`flex gap-2 items-center w-full ${className}`}
        onClick={onClick}
        role="button"
        tabIndex={0}
    >
        <Box sx={{ scale: 0.8 }}>
            <Icon />
        </Box>
        <Typography
            variant="body2"
            sx={{
                alignSelf: "stretch",
                my: "auto",
                width: "101px",
            }}
        >
            {label}
        </Typography>
    </div>
);

export const ProfileMenu: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const { user } = useAuth();
    const navigate = useNavigate();
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const initials = getUserName(user)[0];

    return (
        <div className="relative" ref={menuRef}>
            <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
                <ProfileMenuAvatar
                    sx={{
                        "& .MuiAvatar-root": {
                            fontSize: pxToRem(12),
                            textTransform: "capitalize",
                        },
                    }}
                    initials={initials}
                    isOnline={true}
                />
            </div>
            {isOpen && (
                <Paper
                    className="absolute right-0 top-12"
                    sx={{ zIndex: 1000 }}
                >
                    <div className="flex overflow-hidden flex-col rounded-xl min-w-[280px] shadow-[-4px_4px_20px_rgba(51,51,51,0.12)]">
                        <Box
                            className="flex w-full  min-h-[70px] relative"
                            sx={{
                                px: 3,
                                background:
                                    "linear-gradient(129deg, rgba(62, 75, 255, 0.40) -0.01%, rgba(45, 181, 235, 0.40) 70.9%, rgba(16, 222, 253, 0.40) 100.03%)",
                            }}
                        >
                            <ProfileMenuAvatar
                                initials={getUserName(user)[0]}
                                isOnline={true}
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    transform: "translateY(50%)",
                                    "& .MuiAvatar-root": {
                                        textTransform: "capitalize",
                                    },
                                }}
                                size={48}
                            />
                        </Box>
                        <div className="flex z-10 flex-col items-start w-full">
                            <Box sx={{ px: 2, mt: 2 }} className="w-full">
                                <div className="flex flex-col max-w-full font-semibold ">
                                    <Typography
                                        className="font-semibold capitalize"
                                        sx={{
                                            mt: 2,
                                            color: "text.primary",
                                        }}
                                    >
                                        {getUserName(user)}
                                    </Typography>
                                    <Box
                                        sx={{ mt: 1 }}
                                        className="flex gap-2 items-center w-full text-xs leading-none whitespace-nowrap"
                                    >
                                        <Label
                                            variant="caption"
                                            sx={{
                                                maxWidth: "60px",
                                            }}
                                            colorType="666"
                                            hasTooltip
                                            isEllipses
                                        >
                                            {user?.role.name}
                                        </Label>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                        />
                                        <Label
                                            variant="caption"
                                            sx={{
                                                alignSelf: "stretch",
                                                my: "auto",
                                                maxWidth: "88px",
                                            }}
                                            colorType="666"
                                        >
                                            {user?.email}
                                        </Label>
                                    </Box>
                                </div>
                                <Divider sx={{ my: 2, width: "100%" }} />
                                <Box
                                    className="flex flex-col self-stretch w-full"
                                    mb={2}
                                >
                                    {menuItems.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{ mt: index > 0 ? 2 : 0 }}
                                        >
                                            <MenuItem
                                                icon={item.icon}
                                                label={item.label}
                                                onClick={() => {
                                                    navigate(item.to);
                                                    setIsOpen(false);
                                                }}
                                            />
                                        </Box>
                                    ))}
                                    <Divider sx={{ my: 1.5, width: "100%" }} />
                                    <Logout />
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Paper>
            )}
        </div>
    );
};
