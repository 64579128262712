import React, {
    Fragment,
    PropsWithChildren,
    cloneElement,
    forwardRef,
} from "react";
import VirtualList from "../custom_components/VirtualList/VirtualList";
import { usePaginatedSelectContext } from "./PaginatedSelectContext";

export const VirtualListOptionsForSelectPaginated = React.memo(
    forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function Test(
        {
            children,
            ...rest
        }: PropsWithChildren<React.HTMLAttributes<HTMLElement>>,
        ref
    ) {
        const { hasNext, fetchNext, isLoading, isFetching } =
            usePaginatedSelectContext();
        return (
            <div {...rest} ref={ref}>
                <VirtualList
                    data={children as React.ReactNode[]}
                    Component={({ index }) => (
                        <Fragment key={index}>
                            {cloneElement(
                                (children as React.ReactElement[])[index],
                                {
                                    key: index,
                                }
                            )}
                        </Fragment>
                    )}
                    hasNext={hasNext}
                    fetchNext={fetchNext}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    rowSize={50}
                    sx={{ p: 0, maxHeight: "min(300px, 40vh)" }}
                />
            </div>
        );
    }),
    () => false
);

export const VirtualListOptionsForSelect = React.memo(
    forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function Test(
        {
            children,
            ...rest
        }: PropsWithChildren<React.HTMLAttributes<HTMLElement>>,
        ref
    ) {
        return (
            <div {...rest} ref={ref}>
                <VirtualList
                    data={children as React.ReactNode[]}
                    Component={({ index }) => (
                        <Fragment key={index}>
                            {cloneElement(
                                (children as React.ReactElement[])[index],
                                {
                                    key: index,
                                }
                            )}
                        </Fragment>
                    )}
                    rowSize={50}
                    sx={{ p: 0, maxHeight: "min(300px, 40vh)" }}
                />
            </div>
        );
    }),
    () => false
);

export default VirtualListOptionsForSelect;
