import { FormProvider } from "@convin/components/hook-form";
import useIsMountedRef from "@convin/hooks/useIsMountedRef";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { FormTextField } from "../components";
import { useGenerateResetPasswordLinkMutation } from "@convin/redux/services/auth/auth.service";
import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";

export default function GenerateResetPasswordLink() {
    useDocumentTitle(DashboardRoutesConfig["GenerateResetPasswordLink"].name);
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const [generateResetPasswordLink, { isLoading }] =
        useGenerateResetPasswordLinkMutation();
    const ResetSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
    });

    const defaultValues = {
        email: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(ResetSchema),
        defaultValues,
    });

    const {
        setError,
        handleSubmit,
        formState: { errors },
    } = methods;

    const onSubmit = async ({ email }: { email: string }): Promise<void> => {
        generateResetPasswordLink({ email })
            .unwrap()
            .then(() => {
                navigate({
                    pathname: "/success_sent",
                    search: "?signin=true",
                });
            })
            .catch((err) => {
                if (isMountedRef.current) {
                    setError("afterSubmit", { message: getErrorMessage(err) });
                }
            });
    };

    return (
        <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            className="w-[full] mx-auto"
        >
            <Stack gap={2}>
                <>
                    <Typography variant="medium" component="div" my={1.5}>
                        Enter your email id to receive the password reset link.
                    </Typography>
                    <FormTextField
                        name="email"
                        label="Email Id"
                        autoFocus
                        className="requiredMark"
                    />

                    {!!errors.afterSubmit && (
                        <Alert
                            severity="error"
                            sx={{
                                border: "1px solid",
                                borderColor: "error.main",
                            }}
                        >
                            {errors.afterSubmit.message}
                        </Alert>
                    )}

                    <Button
                        variant="global"
                        fullWidth
                        size="large"
                        type="submit"
                        className="h-[42px]"
                        loading={isLoading}
                        sx={{
                            mt: 1.5,
                            mb: 1.5,
                        }}
                    >
                        Get Reset Link
                    </Button>

                    <Box className="flex items-center justify-center">
                        <Link to={DashboardRoutesConfig["Signin"].to}>
                            <Typography
                                variant="medium"
                                color="primary.main"
                                className="font-semibold"
                            >
                                Back to Sign in
                            </Typography>
                        </Link>
                    </Box>
                </>
            </Stack>
        </FormProvider>
    );
}
