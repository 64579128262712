import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    ActivityLog,
    ActivityPayload,
    ScoreSenseChange,
} from "@convin/type/Activity";
import { PaginationType } from "@convin/type/Common";
import { getDomain } from "@convin/utils/helper/common.helper";
import apiConfigs from "@convin/config/api.config";

const protocol = import.meta.env.VITE_APP_API_PROTOCOL ?? apiConfigs.HTTPS;
const api = import.meta.env.VITE_APP_ACTIVITY_LOG_SERVICE_URL;
const baseApi = api
    ? `${protocol}${api}/`
    : "https://activitydev.convin.ai/v1/";

export const activityApiSlice = createApi({
    reducerPath: "activityApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseApi}${getDomain()}`,
    }),
    endpoints: (builder) => ({
        getScoreSenseActivityLogs: builder.query<
            PaginationType<ActivityLog<ScoreSenseChange>>,
            ActivityPayload & { next?: string }
        >({
            query: (payload) => ({
                url: payload?.next || "analytics/activity_log/",
                method: "POST",
                body: payload,
            }),
            keepUnusedDataFor: 0,
        }),
        getActivityLogs: builder.query<unknown, void>({
            query: () => ({
                url: "activity_logs/",
                method: "POST",
                body: {
                    filters: "created_at=gte:1",
                },
            }),
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useGetScoreSenseActivityLogsQuery,
    useLazyGetScoreSenseActivityLogsQuery,
    useGetActivityLogsQuery,
} = activityApiSlice;
