import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import { useGetCallTagsQuery } from "@convin/redux/services/settings/callTagsManager.service";
import { CallTag } from "@convin/type/CallManager";
import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Label } from "@convin/components/custom_components/Typography/Label";
import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import { ConversationListFilters } from "@convin/type/Filters.model";

const TagsFilter = () => {
    const { data: callTagList, isLoading: isCallTagsLoading } =
        useGetCallTagsQuery();
    const { dashboardType } = useFiltersVisibilityContext();
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const showFilter =
        dashboardType === "Conversation" || dashboardType === "CI";
    return (
        <>
            <GenericMultipleSelect<CallTag>
                label="Tags"
                loading={isCallTagsLoading}
                values={filtersState.tags.tagIds}
                setValues={(val: number[]) => {
                    updateFiltersState({
                        tags: { ...filtersState.tags, tagIds: val },
                    });
                }}
                autocompleteProps={{
                    options: callTagList || [],
                }}
            />
            {showFilter && (
                <Stack
                    direction="row"
                    gap={5}
                    sx={{
                        mt: 2,
                        alignItems: "center",
                    }}
                >
                    <Label colorType="666">Logic between</Label>

                    <GenericRadioGroup<ConversationListFilters["tags"]["logic"]>
                        options={[
                            { id: "and", label: "And" },
                            { id: "or", label: "Or" },
                        ]}
                        value={filtersState.tags.logic}
                        handleChange={(val) => {
                            updateFiltersState({
                                tags: {
                                    ...filtersState.tags,
                                    logic: val,
                                },
                            });
                        }}
                        className=" gap-5"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filtersState?.tags?.negate}
                                onChange={(e) => {
                                    updateFiltersState({
                                        tags: {
                                            ...filtersState.tags,
                                            negate: e.target.checked,
                                        },
                                    });
                                }}
                                name="isExcluded"
                            />
                        }
                        label="Exclude"
                    />
                </Stack>
            )}
        </>
    );
};

export default TagsFilter;
