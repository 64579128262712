import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    ConversationStateContext,
    IConversationState,
} from "../context/ConversationStateProvider";

const useConversationStateContext = (): IConversationState => {
    const context = useContext(ConversationStateContext);
    if (!isDefined(context))
        throw new Error(
            "ConversationStateContext must be use inside ConversationStateProvider"
        );
    return context;
};

export default useConversationStateContext;
