import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetRestrictedUsersQuery } from "@convin/redux/services/settings/users.service";
import { getUserName } from "@convin/utils/helper/common.helper";

interface Reps {
    id: number;
    label: string;
}
const AccountRepsFilter = () => {
    const { state, updateState } = useGlobalFiltersStateContext();
    const { data: users, isLoading } = useGetRestrictedUsersQuery(undefined);

    const repsList =
        users
            ?.filter((user) => getUserName(user).toLowerCase())
            ?.filter((e) =>
                state.teams.length ? state.teams.includes(e.team) : true
            ) || [];

    return (
        <GenericMultipleSelect<Reps>
            label="Select Reps"
            loading={isLoading}
            values={state.reps}
            setValues={(val: number[]) => {
                updateState({
                    reps: val,
                });
            }}
            autocompleteProps={{
                options: repsList,
            }}
        />
    );
};

export default AccountRepsFilter;
