<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
></svg>;
import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function AccuracySvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <g clipPath="url(#clip0_5439_8236)">
                <path
                    d="M14.5 2.5C14.5 4 13 8.5 13 8.5H11C11 8.5 9.5 4 9.5 2.5C9.5 1.12 10.62 0 12 0C13.38 0 14.5 1.12 14.5 2.5ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM16.08 5.11C16.26 4.36 16.41 3.64 16.47 3.05C19.75 4.69 22 8.08 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 8.08 4.25 4.69 7.53 3.05C7.6 3.64 7.74 4.37 7.92 5.11C5.58 6.51 4 9.07 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 9.07 18.42 6.51 16.08 5.11ZM18 12C18 15.31 15.31 18 12 18C8.69 18 6 15.31 6 12C6 10 6.98 8.23 8.48 7.14C8.71 7.95 9.13 9.21 9.13 9.21C8.43 9.93 8 10.92 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 10.92 15.57 9.93 14.87 9.21C14.87 9.21 15.28 7.99 15.52 7.14C17.02 8.23 18 10 18 12Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_5439_8236">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
