import GenericSelect from "@convin/components/select/GenericSelect";
import { processingStatusOptions } from "@convin/config/default.config";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { Conversation } from "@convin/type/Conversation";

const ProcessingStatusFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <GenericSelect<Conversation["processing_status"]>
            options={processingStatusOptions || []}
            label="Processing Status"
            value={filtersState.processingStatus}
            handleChange={(e) => {
                updateFiltersState({
                    processingStatus: e || null,
                });
            }}
            hasClear
            className="flex-1"
        />
    );
};

export default ProcessingStatusFilter;
