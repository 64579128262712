import { useEffect } from "react";
import useAuth from "./useAuth";
import { usePostHog } from "posthog-js/react";
import { getDomain, getUserName } from "@convin/utils/helper/common.helper";

export default function useSetupPostHog() {
    const { user } = useAuth();
    const posthog = usePostHog();
    const domain = import.meta.env.VITE_APP_BARK_DOMAIN ?? getDomain();

    useEffect(() => {
        if (user) {
            if (import.meta.env.PROD) {
                posthog?.identify(user.email, {
                    email: user.email,
                    name: getUserName(user),
                    tenant_id: domain,
                    role_id: user.role.id,
                    team_id: user.team,
                });
            }
        }
    }, [user]);
}
