import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { alpha } from "@mui/system";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { CloseSvg } from "@convin/components/svg";
import NoDataSvg from "@convin/components/svg/NoDataSvg";
import { DrawerFiltersType } from "@convin/type/Filters.model";

import useGlobalFiltersStateContext from "../../../../../hooks/useGlobalFiltersStateContext";
import ExpandAccordianSvg from "@convin/components/svg/ExpandAccordianSvg";
import { pxToRem } from "@convin/utils/getFontValue";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";
import { CheckCircle, Search } from "@mui/icons-material";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    "&.MuiAccordion-root": {
        "&:before": {
            display: "none",
        },
    },
    ".MuiAccordionSummary-root": {
        height: 54,
        color: theme.palette.textColors[333],
        fontSize: pxToRem(14),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        fontWeight: 500,
        "&.Mui-expanded": {
            color: theme.palette.textColors[333],
            fontWeight: 500,
        },
    },
    ".MuiAccordionDetails-root": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));

export const DrawerFilterHeadings: Record<
    | keyof Omit<
          DrawerFiltersType,
          | "auditDateKey"
          | "accountStage"
          | "accountSortBy"
          | "accountQueryBy"
          | "analyticsTemplate"
          | "insight_id"
          | "conversationScoreName"
      >
    | "accountScore",
    string
> = {
    auditType: "Audit Type",
    ciFilter: "Said By",
    tags: "Conversation Tags",
    stage: "Status/Stage",
    level: "Choose Level",
    accountTags: "Account Tags",
    minLeadScore: "Minimum Lead Score",
    minMaxInteractions: "Number of Interactions",
    dealSize: "Deal Value",
    leadType: "Lead Interest",
    closeDateKey: "Close Date",
    lastContactedDateKey: "Last Contacted Date",
    conversationScore: "Conversation Score",
    accountScore: "Account Score",
    templateCategories: "Audit Template",
    templateQuestions: "Audit Template",
    isDraftAudit: "Draft Status",
    auditFeedbackStatus: "Audit Feedback Status",
    client: "Client",
    searchKeywords: "Advanced Keyword Search",
    callTypes: "Conversation Types",
    questions: "Questions",
    topics: "Topics",
    conversationSkills: "Conversation Skills",
    recordingMedium: "Conversation Recording Medium",
    processingStatus: "Processing Status",
    scoresense: "ScoreSense",
    joiningDateKey: "Rep Kpining Date",
    template: "Audit Template",
    attribute: "Attribute",
    dispositionTemplate: "AI Disposition Filters",
    dateKey: "Meeting Date",
    auditors: "Auditors",
    agentTenure: "Agent Tenure",
    convinInsights: "Convin Insights",
    accountTeams: "Rep Teams",
    accountReps: "Reps",
    teams: "Teams",
    reps: "Reps",
};

const DrawerFilters = ({
    isDrawerOpen,
    handleDrawerClose,
    visibleFilters,
}: {
    isDrawerOpen: boolean;
    handleDrawerClose: () => void;
    visibleFilters: (keyof DrawerFiltersType)[];
}) => {
    const { filtersList } = useFiltersVisibilityContext();
    const [searchText, setSearchText] = useState("");
    const {
        handleAppliedButtonClick,
        resetFilters,
        timeStampWhenAppliedButtonWasClicked,
    } = useGlobalFiltersStateContext();
    const { appliedFilterKeys } = useFiltersVisibilityContext();

    useEffect(() => {
        handleDrawerClose();
    }, [timeStampWhenAppliedButtonWasClicked]);

    const matchesSearch = useCallback(
        (sectionHeading: string): boolean => {
            const match = sectionHeading
                .toLowerCase()
                .includes(searchText.toLowerCase());
            return match;
        },
        [searchText]
    );

    const searchIsEmpty =
        searchText &&
        !Object.values(DrawerFilterHeadings).some((e) => matchesSearch(e));

    const filters = useMemo(() => new Set(visibleFilters), [visibleFilters]);

    const appliedFilters = useMemo(
        () => new Set(appliedFilterKeys),
        [appliedFilterKeys]
    );

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            sx={{
                "& .MuiDrawer-paper": {
                    width: "100%",
                    maxWidth: (theme) => theme.spacing(65),
                    height: "100%",
                    maxHeight: "100%",
                    borderRadius: "0px",
                },
            }}
        >
            <Box
                className="flex items-center justify-between"
                sx={{
                    gap: 2,
                    px: 2,
                    py: 3,
                }}
            >
                <Typography variant="h5">Advanced Filters</Typography>
                <IconButton onClick={handleDrawerClose}>
                    <CloseSvg />
                </IconButton>
            </Box>
            <Divider />
            <Box className="flex flex-col h-full overflow-hidden">
                <Box className="flex-shrink-0" sx={{ px: 2, py: 1.5 }}>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Search filters"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search sx={{ color: "textColors.999" }} />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                        sx={(theme) => ({
                            backgroundColor: alpha(
                                theme.palette.textColors[999],
                                0.1
                            ),
                            px: 2,
                            py: 0.5,
                            borderRadius: theme.spacing(0.5),
                        })}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Box>
                <Divider />
                <Box
                    className="flex-1 overflow-y-auto"
                    data-testid="drawer-filters-list"
                >
                    {filtersList?.map(({ heading, Component, filterKey }) => {
                        return (
                            <Fragment key={filterKey}>
                                {matchesSearch(heading) &&
                                filters.has(filterKey) ? (
                                    <div>
                                        <StyledAccordion
                                            disableGutters
                                            elevation={0}
                                            slotProps={{
                                                transition: {
                                                    unmountOnExit: true,
                                                },
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <ExpandAccordianSvg />
                                                }
                                            >
                                                <Box
                                                    className="flex items-center justify-between w-full"
                                                    pr={2}
                                                >
                                                    {heading}
                                                    {appliedFilters.has(
                                                        filterKey
                                                    ) ? (
                                                        <CheckCircle
                                                            sx={{
                                                                color: "primary.main",
                                                                scale: "0.95",
                                                            }}
                                                        />
                                                    ) : null}
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 0.75,
                                                        gap: 1,
                                                    }}
                                                    className="flex flex-col"
                                                >
                                                    <Component />
                                                </Box>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                        <Divider />
                                    </div>
                                ) : null}
                            </Fragment>
                        );
                    })}

                    {searchIsEmpty && (
                        <>
                            <Box className="flex flex-col items-center justify-center h-full">
                                <NoDataSvg />
                                <Label colorType="666" sx={{ my: 2 }}>
                                    No matching filters found.
                                </Label>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Divider />
            <Box className="flex justify-end" sx={{ p: 2.5, gap: 1.5 }}>
                <Button
                    onClick={() => {
                        resetFilters();
                        handleDrawerClose();
                    }}
                    variant="globalOutlined"
                    className="h-10 !normal-case"
                    sx={{ width: "150px" }}
                >
                    Clear all
                </Button>
                <Button
                    variant="global"
                    onClick={() => {
                        handleAppliedButtonClick();
                    }}
                    sx={{ width: "150px" }}
                >
                    Apply Filters
                </Button>
            </Box>
        </Drawer>
    );
};
[];
export default DrawerFilters;
