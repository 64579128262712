import { useState } from "react";
import { Logout as LogoutIcon } from "@mui/icons-material";
import DeleteDialog from "@convin/components/custom_components/Dialog/DeleteDialog";
import useAuth from "@convin/hooks/useAuth";
import { MenuItem } from "./ProfileMenu";

export default function Logout() {
    const [isLogoutDialogVisible, setIsLogoutDialogVisible] = useState(false);
    const { handleLogout, isLogoutRequestInProgress } = useAuth();
    return (
        <>
            <MenuItem
                icon={LogoutIcon}
                label="Logout"
                className="text-red-500"
                onClick={handleLogout}
            />
            <DeleteDialog
                open={isLogoutDialogVisible}
                setOpen={setIsLogoutDialogVisible}
                onDelete={() =>
                    handleLogout().then(() => {
                        setIsLogoutDialogVisible(false);
                    })
                }
                title="Logout User"
                message="Are you sure you want to logout?"
                okText="Logout"
                isLoading={isLogoutRequestInProgress}
            />
        </>
    );
}
