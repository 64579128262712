import { Suspense } from "react";
import { FallbackUI } from "@reusables";
import {
    DashboardRoutesConfig,
    SettingRoutes,
    conversationPathList,
} from "@convin/config/routes.config";
import { GlobalFiltersStateContextProvider } from "@convin/modules/components";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import GlobalDashboardLayout from "@convin/modules/GlobalDashboardLayout";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import useAuth from "@convin/hooks/useAuth";
import {
    AccountsDashboard,
    AiVoiceCall,
    AssessmentAgentView,
    B2BAuditDashboard,
    BertRuleEngine,
    Coaching,
    ConversationRoutes,
    CreateQmsConversation,
    CustomEditor,
    CustomerIntelligence,
    HomeDashboard,
    LMS,
    MeetingsDashboard,
    Settings,
    Statistics,
    SupervisorAssist,
    UserProfile,
    SalesAssistIframe,
    ActivityLog,
    CoachingDashboard,
    LiveCallDetailsPage,
} from "./LazyImports";
import WebSocketContextProvider from "@convin/components/websocket/context/WebSocketContextProvider";
import GlobalSearchContextProvider from "@convin/modules/GlobalDashboardLayout/context/GlobalSearchContextProvider";
import GlobalSearchHandleWrapper from "@convin/modules/GlobalDashboardLayout/components/GlobalSearchHandleWrapper";
import SetDefaultFiltersWrapper from "@convin/modules/GlobalDashboardLayout/components/SetDefaultFiltersWrapper";
import MeetingsFiltersWrapper from "@convin/modules/MeetingsDashboard/components/MeetingsFiltersWrapper";
import Error404State from "@convin/components/custom_components/reuseable/Error404State";
import useSetupPostHog from "@convin/hooks/useSetupPostHog";
import SalesAgentIframe from "@convin/components/custom_components/SalesAgentIframe";
import { AudioProvider } from "@convin/contexts/AudioContex";
import useGetGist from "@convin/hooks/useGetGist";

const conversationRoute = DashboardRoutesConfig.Conversations;

const homeRoute = DashboardRoutesConfig.Home;

const DashboardContextWrapper = () => {
    return (
        <GlobalFiltersStateContextProvider>
            <GlobalSearchHandleWrapper>
                <SetDefaultFiltersWrapper>
                    <Outlet />
                </SetDefaultFiltersWrapper>
            </GlobalSearchHandleWrapper>
        </GlobalFiltersStateContextProvider>
    );
};

const Dashboards = () => {
    const { data: domainConfig } = useGetDomainConfigQuery();
    const { checkDashboardVisibility } = useAuth();
    const isB2cDomain = domainConfig?.domain_type === "b2c";

    return (
        <AudioProvider>
            <WebSocketContextProvider>
                <GlobalSearchContextProvider>
                    <GlobalDashboardLayout>
                        <Suspense fallback={<FallbackUI />}>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        <Navigate
                                            to={
                                                isB2cDomain
                                                    ? homeRoute.to
                                                    : conversationRoute.to
                                            }
                                        />
                                    }
                                />
                                {checkDashboardVisibility(
                                    SettingRoutes.AUDIT_MANAGER
                                ) && (
                                    <Route
                                        path={`/settings/${SettingRoutes.AUDIT_MANAGER.path}/:template_id/category/:category_id/parameter/:parameter_id/configure`}
                                        element={
                                            domainConfig?.ai_config
                                                ?.advanced ? (
                                                <BertRuleEngine />
                                            ) : (
                                                <Error404State />
                                            )
                                        }
                                    />
                                )}
                                {checkDashboardVisibility(
                                    DashboardRoutesConfig.Settings
                                ) && (
                                    <Route
                                        path={`${DashboardRoutesConfig.Settings.path}/*`}
                                        element={<Settings />}
                                    />
                                )}
                                <Route path="qms">
                                    <Route
                                        path="create"
                                        element={<CreateQmsConversation />}
                                    />
                                </Route>
                                <Route
                                    path={`${DashboardRoutesConfig.LMS.subRoutes.knowledgeBase.path}/:category_id/document/:editor_id/view`}
                                    element={<CustomEditor />}
                                />

                                <Route element={<DashboardContextWrapper />}>
                                    <Route
                                        path={
                                            DashboardRoutesConfig.Conversations
                                                .path
                                        }
                                        element={
                                            <MeetingsFiltersWrapper>
                                                <MeetingsDashboard />
                                            </MeetingsFiltersWrapper>
                                        }
                                    />
                                    {conversationPathList.map((path) => (
                                        <Route
                                            path={`${path}/*`}
                                            key={path}
                                            element={<ConversationRoutes />}
                                        />
                                    ))}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.Home
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.Home.path}/*`}
                                            element={<HomeDashboard />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.AuditReport
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.AuditReport.path}`}
                                            element={<B2BAuditDashboard />}
                                        />
                                    )}

                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.SalesAssist
                                    ) && (
                                        <Route
                                            path={
                                                DashboardRoutesConfig
                                                    .SalesAssist.path
                                            }
                                            element={<SalesAssistIframe />}
                                        />
                                    )}

                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.SupervisorAssist
                                    ) && (
                                        <Route
                                            path={
                                                DashboardRoutesConfig
                                                    .SupervisorAssist.path
                                            }
                                            element={<SupervisorAssist />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.CI
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.CI.path}/*`}
                                            element={<CustomerIntelligence />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.Account
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.Account.path}/*`}
                                            element={<AccountsDashboard />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.LMS
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.LMS.path}/*`}
                                            element={<LMS />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.Coaching
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.Coaching.path}/:sessionId`}
                                        >
                                            <Route
                                                index
                                                element={<Coaching />}
                                            />
                                            <Route
                                                path="assessment"
                                                element={
                                                    <AssessmentAgentView />
                                                }
                                            />
                                        </Route>
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.AIAgent
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.AIAgent.path}/*`}
                                            element={<AiVoiceCall />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.Statistics
                                    ) && (
                                        <Route
                                            path={
                                                DashboardRoutesConfig.Statistics
                                                    .to
                                            }
                                            element={<Statistics />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.Coaching
                                    ) && (
                                        <Route
                                            path={
                                                DashboardRoutesConfig.Coaching
                                                    .to
                                            }
                                            element={<CoachingDashboard />}
                                        />
                                    )}
                                    {checkDashboardVisibility(
                                        DashboardRoutesConfig.LiveConversation
                                    ) && (
                                        <Route
                                            path={`${DashboardRoutesConfig.LiveConversation.to}/:id`}
                                            element={<LiveCallDetailsPage />}
                                        />
                                    )}
                                    <Route
                                        path={
                                            DashboardRoutesConfig.UserProfile.to
                                        }
                                        element={<UserProfile />}
                                    />
                                    <Route
                                        path={
                                            DashboardRoutesConfig.ActivityLog.to
                                        }
                                        element={<ActivityLog />}
                                    />
                                    <Route
                                        path={
                                            DashboardRoutesConfig.SalesAgent
                                                .path
                                        }
                                        element={<SalesAgentIframe />}
                                    />
                                </Route>
                                <Route path="*" element={<Error404State />} />
                            </Routes>
                        </Suspense>
                    </GlobalDashboardLayout>
                </GlobalSearchContextProvider>
            </WebSocketContextProvider>
        </AudioProvider>
    );
};

function Home() {
    useSetupPostHog();
    useGetGist();

    return <Dashboards />;
}

export default Home;
