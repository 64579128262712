import { Slider, Stack, SxProps, Theme } from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { NumericRange } from "@convin/type/Common";

interface Props {
    name: string;
    values: NumericRange;
    setValues: (val: NumericRange) => void;
    minLimit?: number;
    maxLimit?: number;
    unit?: string;
    sx?: SxProps<Theme>;
}

const MinMaxSlider = ({
    name,
    values,
    setValues,
    minLimit = 0,
    maxLimit = 100,
    unit = "%",
    sx = {},
}: Props) => {
    return (
        <Stack spacing={1} sx={sx}>
            <Label colorType="666" variant="medium" sx={{ fontWeight: 500 }}>
                {name}
            </Label>
            <Label colorType="666">
                {values[0] ?? minLimit} to {values[1] ?? maxLimit} {unit}
            </Label>
            <Slider
                value={[values[0] ?? minLimit, values[1] ?? maxLimit]}
                getAriaLabel={() => "Other Party Talk Ratio"}
                onChange={(e, value) => {
                    setValues([(value as number[])[0], (value as number[])[1]]);
                }}
                valueLabelDisplay="auto"
                sx={{
                    transform: "translateX(0.5rem)",
                    width: "calc(100% - 2.5rem)",
                }}
                min={minLimit}
                max={maxLimit}
            />
        </Stack>
    );
};

export default MinMaxSlider;
