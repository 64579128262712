import { SvgIcon, SxProps, Theme, useTheme } from "@mui/material";

export default function SortingSvg({
    sx,
    type,
    colorType = "333",
}: {
    sx?: SxProps<Theme>;
    type: "asc" | "desc" | "none";
    colorType?: "333" | "999" | "primary";
}) {
    const theme = useTheme();

    return (
        <SvgIcon
            viewBox="0 0 16 16"
            sx={{ width: 16, height: 16, fill: "none", ...sx }}
        >
            <mask
                id="mask0_10477_48415"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10477_48415)">
                <path
                    d="M8 1L12.3301 7H3.66987L8 1Z"
                    fill={`${
                        type === "asc"
                            ? colorType === "primary"
                                ? theme.palette.primary.main
                                : theme.palette.textColors[colorType]
                            : "#999999"
                    }`}
                />
                <path
                    d="M8 15L3.66987 9L12.3301 9L8 15Z"
                    fill={`${
                        type === "desc"
                            ? colorType === "primary"
                                ? theme.palette.primary.main
                                : theme.palette.textColors[colorType]
                            : "#999999"
                    }`}
                />
            </g>
        </SvgIcon>
    );
}
