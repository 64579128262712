import { Box } from "@mui/material";
import InternalNotificationCard from "./InternalNotificationCard";
import { useGetDownloadReportsList } from "../hooks/useGetDownloadReportsList";
import { Stack } from "@mui/system";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import { DownloadReport } from "@convin/type/Report";
import NoDownloads from "./NoDownloads";
import DownloadNotificationCard from "./DownloadNotificationCard";

interface Props {
    data: DownloadReport;
    prevIndexedData: DownloadReport | null;
}

const NotificationCard = ({ data, prevIndexedData }: Props) => {
    if (
        ["calibration_report", "comparison_report", "recall_report"].includes(
            data.report_type
        )
    ) {
        return (
            <DownloadNotificationCard
                data={data}
                prevIndexedData={prevIndexedData}
            />
        );
    }
    return (
        <InternalNotificationCard
            data={data}
            prevIndexedData={prevIndexedData}
        />
    );
};

export default function InternalNotificationPanel() {
    const { data, hasNext, fetchNext, isFetching, isLoading } =
        useGetDownloadReportsList({
            type: "internal",
        });
    return (
        <Stack className="w-full h-full overflow-auto">
            <Box className="flex-1 overflow-auto" px={2}>
                {data?.count ? (
                    <VirtualList<DownloadReport>
                        Component={NotificationCard}
                        data={data?.results ?? []}
                        hasNext={hasNext}
                        fetchNext={fetchNext}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        sx={{
                            p: 0,
                        }}
                    />
                ) : (
                    <NoDownloads copy="No Notifications" />
                )}
            </Box>
        </Stack>
    );
}
