import {
    filtersApiSlice,
    useGetClientsQuery,
    useLazyGetClientsQuery,
} from "@convin/redux/services/meeting/filters.service";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const useGetClientsPaginateQuery = (
    params: { next?: string; query?: string },
    fetchAll: boolean = false,
    extras?: {
        skip?: boolean;
        refetchOnMountOrArgChange?: boolean;
    }
) => {
    const baseResult = useGetClientsQuery(params, {
        ...extras,
    });
    const [trigger, nextResult] = useLazyGetClientsQuery();

    const dispatch = useDispatch();

    const isBaseReady = useRef(false);
    const isNextDone = useRef(true);
    const next = useRef<null | string>(null); // URL for next request

    // Base result
    useEffect(() => {
        if (!baseResult.data) {
            return;
        }
        next.current = baseResult.data?.next;
        if (baseResult.data) {
            isBaseReady.current = true;
            fetchAll && fetchNext();
        }
    }, [baseResult, fetchAll]);

    useEffect(() => {
        if (nextResult.isFetching) return;
        if (!nextResult.isSuccess) return;

        if (
            isBaseReady.current &&
            nextResult.data &&
            nextResult.data.next !== next.current
        ) {
            next.current = nextResult.data.next; // undefined if no data further

            // Put next fetched data into the first queried collection (as a base collection)

            const newItems = nextResult.data?.results || [];
            dispatch(
                filtersApiSlice.util.updateQueryData(
                    "getClients",
                    params,
                    (drafts) => {
                        drafts.next = nextResult.data.next;
                        drafts.previous = nextResult.data.previous;
                        if (newItems && newItems.length > 0) {
                            drafts.results.push(...newItems);
                        }
                    }
                )
            );
        }
    }, [nextResult, dispatch, params]);

    const fetchNext = async () => {
        if (
            !isBaseReady.current ||
            !isNextDone.current ||
            next.current === undefined ||
            next.current === null
        )
            return;

        try {
            isNextDone.current = false;
            await trigger({
                ...params,
                next: next.current,
            });
        } catch (e) {
        } finally {
            isNextDone.current = true;
            fetchAll && fetchNext();
        }
    };

    const refetch = async () => {
        isBaseReady.current = false;
        next.current = null;
        await baseResult.refetch(); // restart with a whole new refetching
    };

    return {
        data: baseResult.data,
        error: baseResult.error,
        isError: baseResult.isError,
        isLoading: baseResult.isLoading,
        isFetching: baseResult.isFetching || nextResult.isFetching,
        errorNext: nextResult.error,
        isErrorNext: nextResult.isError,
        isFetchingNext: nextResult.isFetching,
        hasNext: baseResult.data?.next !== null,
        fetchNext,
        refetch,
    };
};

export default useGetClientsPaginateQuery;
