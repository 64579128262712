import { useGlobalFiltersStateContext } from "@convin/modules/components";
import { ConversationSkills } from "@convin/type/Conversation";
import { NumericRange } from "@convin/type/Common";
import MinMaxInputField from "@convin/modules/components/globalDashboardFilters/components/MinMaxInputField";
import MinMaxSlider from "@convin/modules/components/globalDashboardFilters/components/MinMaxSlider";
import { conversationSkillsLabelMap } from "@convin/config/conversation.config";

const ConversationSkillsFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const updateConversationSkillState = (
        key: keyof ConversationSkills,
        value: NumericRange
    ) => {
        updateFiltersState({
            conversationSkills: {
                ...filtersState.conversationSkills,
                [key]: value,
            },
        });
    };

    return (
        <>
            <MinMaxInputField
                showLabel
                name={conversationSkillsLabelMap.clientLongestMonologue.label}
                values={filtersState.conversationSkills.clientLongestMonologue}
                setValues={(val) =>
                    updateConversationSkillState("clientLongestMonologue", val)
                }
                minLimit={0}
            />
            <MinMaxSlider
                name={conversationSkillsLabelMap.clientTalkRatio.label}
                values={filtersState.conversationSkills.clientTalkRatio}
                setValues={(val) =>
                    updateConversationSkillState("clientTalkRatio", val)
                }
            />
            <MinMaxSlider
                name={conversationSkillsLabelMap.interactivity.label}
                values={filtersState.conversationSkills.interactivity}
                setValues={(val) =>
                    updateConversationSkillState("interactivity", val)
                }
                minLimit={-10}
                maxLimit={10}
                unit=""
            />
            <MinMaxInputField
                showLabel
                name={conversationSkillsLabelMap.ownerLongestMonologue.label}
                values={filtersState.conversationSkills.ownerLongestMonologue}
                setValues={(val) =>
                    updateConversationSkillState("ownerLongestMonologue", val)
                }
                minLimit={0}
            />
            <MinMaxSlider
                name={conversationSkillsLabelMap.ownerTalkRatio.label}
                values={filtersState.conversationSkills.ownerTalkRatio}
                setValues={(val) =>
                    updateConversationSkillState("ownerTalkRatio", val)
                }
            />
            <MinMaxInputField
                showLabel
                name={conversationSkillsLabelMap.interruptionCount.label}
                values={filtersState.conversationSkills.interruptionCount}
                setValues={(val) =>
                    updateConversationSkillState("interruptionCount", val)
                }
                minLimit={0}
            />
            <MinMaxInputField
                showLabel
                name={conversationSkillsLabelMap.talktime.label}
                values={filtersState.conversationSkills.talktime}
                setValues={(val) =>
                    updateConversationSkillState("talktime", val)
                }
                minLimit={0}
            />
            <MinMaxInputField
                showLabel
                name={conversationSkillsLabelMap.patience.label}
                values={filtersState.conversationSkills.patience}
                setValues={(val) =>
                    updateConversationSkillState("patience", val)
                }
                minLimit={0}
            />
        </>
    );
};

export default ConversationSkillsFilter;
