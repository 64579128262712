import { FormProvider } from "@convin/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Stack, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useIsMountedRef from "@convin/hooks/useIsMountedRef";

import useSearchQuery from "@convin/hooks/useSearchQuery";
import PasswordField from "../components/PasswordField";
import useAuth from "@convin/hooks/useAuth";
import { useResendOtpMutation } from "@convin/redux/services/auth/auth.service";
import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { encryptString, isDefined } from "@convin/utils/helper/common.helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";
import { DashboardRoutesConfig } from "@convin/config/routes.config";

export default function VerifyOtp() {
    useDocumentTitle(DashboardRoutesConfig["VerifyOtp"].name);
    const [handleResendOtp, { isLoading: isResendOtpLoading }] =
        useResendOtpMutation();
    const { handleVerifyOtp, isVerifyOtpRequestInProgress } = useAuth();

    const query = useSearchQuery();
    const email = query.get("email") ?? "";
    const setExpiry = isDefined(query.get("set_expiry")) ?? false;

    const [countdown, setCountdown] = useState(() => {
        const storedExpiry = localStorage.getItem("otpExpiry");
        if (storedExpiry) {
            const remainingTime = Math.max(
                0,
                Math.floor((parseInt(storedExpiry) - Date.now()) / 1000)
            );
            if (remainingTime === 0) {
                localStorage.removeItem("otpExpiry");
            }
            return remainingTime;
        }
        return 0;
    });
    const [code, setCode] = useState("");
    const isMountedRef = useIsMountedRef();

    const VerifyOtpSchema = Yup.object().shape({
        code: Yup.string()
            .required("Verification code is required")
            .min(6, "Code must be 6 digits")
            .max(6, "Code must be 6 digits"),
    });

    const navigate = useNavigate();

    const defaultValues = {
        code: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(VerifyOtpSchema),
        defaultValues,
    });

    const {
        setError,
        handleSubmit,
        formState: { errors },
    } = methods;

    const onSubmit = async ({ code }: { code: string }): Promise<void> => {
        try {
            await handleVerifyOtp(
                shouldEncryptPassword ? encryptString(email) : email,
                shouldEncryptPassword ? encryptString(code) : code
            );
            localStorage.removeItem("otpExpiry");
        } catch (err) {
            if (isMountedRef.current) {
                setError("afterSubmit", { message: "Invalid code" });
            }
        }
    };
    const shouldEncryptPassword = isDefined(
        import.meta.env.VITE_ENCRYPT_SECRET_KEY
    );
    const handleResend = async () => {
        const formData = new FormData();
        formData.append(
            "email",
            shouldEncryptPassword ? encryptString(email) : email
        );
        try {
            await handleResendOtp(formData);
            startCountdown(true);
            showToast({
                message: "Code resent successfully",
                ...CreateUpdateToastSettings,
            });
        } catch (err) {
            if (isMountedRef.current) {
                setError("afterSubmit", { message: getErrorMessage(err) });
            }
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCount: number) => {
                    if (prevCount <= 1) {
                        localStorage.removeItem("otpExpiry");
                        return 0;
                    }
                    return prevCount - 1;
                });
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [countdown]);

    const startCountdown = (setExpiry: boolean) => {
        if (!localStorage.getItem("otpExpiry") && setExpiry) {
            const expiryTime = Date.now() + 60000; // 60 seconds from now
            localStorage.setItem("otpExpiry", expiryTime.toString());
            setCountdown(60);
            navigate(
                {
                    pathname: "/verify_otp",
                    search: `?email=${email}`,
                },
                {
                    replace: true,
                }
            );
        }
    };

    useEffect(() => {
        startCountdown(setExpiry);
    }, []);

    return (
        <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            className="w-[full] mx-auto"
        >
            <Typography variant="medium" component="div" my={1.5}>
                Enter OTP sent to {email}
            </Typography>
            <Stack gap={2}>
                <PasswordField
                    name="code"
                    label="Enter Verification Code"
                    onChange={(e) => setCode(e.target.value)}
                />

                {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}

                <Button
                    variant="global"
                    fullWidth
                    size="large"
                    type="submit"
                    className="h-[42px]"
                    loading={isVerifyOtpRequestInProgress}
                    disabled={countdown === 0 || code.length !== 6}
                >
                    Verify Code
                </Button>

                <Button
                    variant="global"
                    onClick={handleResend}
                    fullWidth
                    size="large"
                    className="h-[42px]"
                    loading={isResendOtpLoading}
                    disabled={countdown > 0}
                >
                    Resend Code {countdown > 0 && `after ${countdown} seconds`}
                </Button>
            </Stack>
        </FormProvider>
    );
}
