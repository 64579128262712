import { FormProvider } from "@convin/components/hook-form";
import useAuth from "@convin/hooks/useAuth";
import useIsMountedRef from "@convin/hooks/useIsMountedRef";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { Link } from "react-router-dom";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { FormTextField } from "../components";
import { encryptString, isDefined } from "@convin/utils/helper/common.helper";
import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import PasswordField from "../components/PasswordField";
import { SsoOptions } from "../components/SsoOptions";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";
import GuestGuard from "@convin/components/guards/GuestGuard";
import PreAuthLayout from "../Layout/PreAuthLayout";

export default function Signin() {
    useDocumentTitle(DashboardRoutesConfig["Signin"].name);
    const query = useSearchQuery();
    const email = query.get("email");
    const passwordResetSuccess = query.get("reset_password") === "true";
    const { error } = useGetDomainConfigQuery();
    const { handleLogin, isLoginRequestInProgress } = useAuth();
    const isMountedRef = useIsMountedRef();

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    const defaultValues = {
        email: email ?? "",
        password: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        setError,
        handleSubmit,
        formState: { errors },
    } = methods;

    const onSubmit = async ({
        email,
        password,
    }: {
        email: string;
        password: string;
    }): Promise<void> => {
        const shouldEncryptPassword = isDefined(
            import.meta.env.VITE_ENCRYPT_SECRET_KEY
        );
        handleLogin({
            email: shouldEncryptPassword ? encryptString(email) : email,
            password: shouldEncryptPassword
                ? encryptString(password)
                : password,
            rawEmail: email,
        })
            .then(() => {})
            .catch((error) => {
                if (isMountedRef.current) {
                    setError("afterSubmit", {
                        message: getErrorMessage(error),
                    });
                }
            });
    };

    return (
        <GuestGuard>
            <PreAuthLayout
                Footer={
                    <Typography
                        component={Link}
                        to={DashboardRoutesConfig["Signup"].to}
                    >
                        Don&apos;t have an account?{" "}
                        <Typography component="span" color="primary.main">
                            Sign up now
                        </Typography>
                    </Typography>
                }
            >
                <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-[full] mx-auto"
                >
                    {passwordResetSuccess && (
                        <Alert
                            sx={{
                                my: 2,
                                border: "1px solid",
                                borderColor: "success.main",
                            }}
                            severity="success"
                        >
                            Password has been reset! Login Now
                        </Alert>
                    )}

                    <Stack gap={2.5} my={2}>
                        <FormTextField
                            name="email"
                            label="Email Id"
                            autoComplete="true"
                            className="requiredMark"
                        />
                        <PasswordField name="password" label="Password" />
                        {!!errors.afterSubmit && (
                            <Alert
                                severity="error"
                                sx={{
                                    border: "1px solid",
                                    borderColor: "error.main",
                                }}
                            >
                                {errors.afterSubmit.message}
                            </Alert>
                        )}
                        {isDefined(error) && (
                            <Alert
                                severity="error"
                                sx={{
                                    border: "1px solid",
                                    borderColor: "error.main",
                                }}
                            >
                                {getErrorMessage(error) ??
                                    "Something went wrong!!"}
                            </Alert>
                        )}
                        <Button
                            variant="global"
                            fullWidth
                            size="large"
                            type="submit"
                            className="h-[42px]"
                            loading={isLoginRequestInProgress}
                        >
                            Sign in
                        </Button>
                        <Box className="flex items-center justify-between flex-row-reverse">
                            <Link
                                to={
                                    DashboardRoutesConfig[
                                        "GenerateResetPasswordLink"
                                    ].to
                                }
                            >
                                <Typography
                                    variant="medium"
                                    color="textColors.666"
                                    className="text-left"
                                >
                                    Forgot Password?
                                </Typography>
                            </Link>
                        </Box>
                        <SsoOptions />
                    </Stack>
                </FormProvider>
            </PreAuthLayout>
        </GuestGuard>
    );
}
