import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import InfiniteScrollSelect from "@convin/components/select/InfiniteScrollSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetAttributeCategoryKeysList } from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/hooks/useGetAttributeCategoryKeysList";
import {
    useGetAttributeCategoriesQuery,
    useGetAttributeCategoryKeysQuery,
} from "@convin/redux/services/settings/auditAiConfig.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import useDebounce from "@hooks/useDebounce";
import { Stack, TextField } from "@mui/material";
import { useState } from "react";

export default function AttributeFilter() {
    const { state, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const {
        attribute: { category, type, value },
    } = state;
    const { data: categories, isFetching: isLoadingCategories } =
        useGetAttributeCategoriesQuery();
    const { data: selectedKey } = useGetAttributeCategoryKeysQuery(
        {
            id: Number(type),
        },
        {
            skip: !isDefined(type),
        }
    );
    const [query, setQuery] = useState("");

    const debouncedQuery = useDebounce(query, 1000);
    const {
        data: keys,
        isFetching,
        isLoading,
        hasNext,
        fetchNext,
    } = useGetAttributeCategoryKeysList(
        {
            metadata_type: category!,
            query: debouncedQuery,
        },
        undefined,
        {
            skip: !isDefined(category),
            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <Stack gap={2}>
            <GenericSingleSelect<{ id: number; label: string }>
                label="Select Attribute Category"
                autocompleteProps={{
                    options:
                        categories?.map((e) => ({
                            id: e.id,
                            label: e.name,
                        })) ?? [],
                    loading: isLoadingCategories,
                    value: category,
                    size: "small",
                }}
                setValue={(e) => {
                    updateFiltersState({
                        attribute: {
                            category: e,
                            type: null,
                            value: null,
                        },
                    });
                }}
            />
            <InfiniteScrollSelect
                label="Select Attribute Type"
                options={
                    [
                        ...(keys?.results?.filter(
                            (e) => e.id !== selectedKey?.results[0]?.id
                        ) ?? []),
                        ...(selectedKey?.results ?? []),
                    ]?.map((e) => ({
                        ...e,
                        label: e.name,
                    })) ?? []
                }
                onQueryChange={(value, reason) => {
                    if (reason !== "select") setQuery(value ?? "");
                }}
                value={type}
                setValue={(id) => {
                    updateFiltersState({
                        attribute: {
                            category,
                            type: id,
                            value: null,
                        },
                    });
                }}
                className="flex-1"
                virtualizeProps={{
                    fetchNext,
                    hasNext,
                    isFetching,
                    isLoading,
                }}
                disabled={!isDefined(category)}
                size="small"
            />
            <TextField
                value={value ?? ""}
                onChange={(e) => {
                    updateFiltersState({
                        attribute: {
                            category,
                            type,
                            value: e.target.value,
                        },
                    });
                }}
                label="Enter Value"
                className="w-full"
                size="small"
                placeholder="Enter Value"
                disabled={!isDefined(type)}
            />
        </Stack>
    );
}
