import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { ConversationListFilters } from "@convin/type/Filters.model";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid2, Typography } from "@mui/material";

const CheckBoxComponent: React.FC<
    {
        field: keyof ConversationListFilters["scoresense"];
    } & Record<
        "type" | "value",
        | ConversationListFilters["scoresense"]["lead"]
        | ConversationListFilters["scoresense"]["csat"]
        | ConversationListFilters["scoresense"]["collection"]
    >
> = ({ type, value, field }) => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={type === value}
                    onChange={(_, checked) => {
                        updateFiltersState({
                            scoresense: {
                                ...filtersState.scoresense,
                                [field]: checked ? value : null,
                            },
                        });
                    }}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                />
            }
            label={
                <Typography
                    className="capitalize"
                    variant="medium"
                    sx={() => ({
                        color:
                            type === value ? "primary.main" : "textColors.333",
                        fontWeight: type === value ? 500 : 400,
                    })}
                >
                    {value}
                </Typography>
            }
        />
    );
};

export default function ScoreSenseFilter() {
    const { state: filtersState } = useGlobalFiltersStateContext();
    const {
        scoresense: { lead, csat, collection },
    } = filtersState;
    return (
        <>
            <Grid2 container alignItems="center" className="w-full">
                <Grid2 size={{ xs: 3 }}>
                    <Typography
                        className="font-medium"
                        variant="medium"
                        color="textColors.666"
                    >
                        Lead Interest
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 9 }} display="flex" gap={2}>
                    <CheckBoxComponent field="lead" type={lead} value={"hot"} />
                    <CheckBoxComponent
                        field="lead"
                        type={lead}
                        value={"warm"}
                    />
                    <CheckBoxComponent
                        field="lead"
                        type={lead}
                        value={"cold"}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container alignItems="center" className="w-full">
                <Grid2 size={{ xs: 3 }}>
                    <Typography
                        className="font-medium"
                        variant="medium"
                        color="textColors.666"
                    >
                        CSAT Analysis
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 9 }} display="flex" gap={2}>
                    <CheckBoxComponent
                        field="csat"
                        type={csat}
                        value={"satisfied"}
                    />
                    <CheckBoxComponent
                        field="csat"
                        type={csat}
                        value={"neutral"}
                    />
                    <CheckBoxComponent
                        field="csat"
                        type={csat}
                        value={"dissatisfied"}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container alignItems="center" className="w-full">
                <Grid2 size={{ xs: 3 }}>
                    <Typography
                        className="font-medium"
                        variant="medium"
                        color="textColors.666"
                    >
                        Collection Analysis
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 9 }} display="flex" gap={2}>
                    <CheckBoxComponent
                        field="collection"
                        type={collection}
                        value={"good"}
                    />
                    <CheckBoxComponent
                        field="collection"
                        type={collection}
                        value={"average"}
                    />
                    <CheckBoxComponent
                        field="collection"
                        type={collection}
                        value={"poor"}
                    />
                </Grid2>
            </Grid2>
        </>
    );
}
