import { ErrorInfo, PropsWithChildren } from "react";
import Navigation from "./components/Navigation";
import GlobalDashboardHeader from "./components/GlobalDashboardHeader";
import { isDefined } from "@convin/utils/helper/common.helper";
import { matchPath, useLocation } from "react-router-dom";
import {
    DashboardRoutesConfig,
    SettingRoutes,
    conversationPathList,
} from "@convin/config/routes.config";
import { ErrorBoundary } from "react-error-boundary";
import PageCrash from "@convin/components/error/PageCrash";
import { Banner } from "@convin/components/custom_components/Banner";

const { Account } = DashboardRoutesConfig;

export default function GlobalDashboardLayout({ children }: PropsWithChildren) {
    const location = useLocation();
    const isAccountDetailsDashboard = isDefined(
        matchPath<"id", string>(`${Account.path}/:id/*`, location.pathname)
            ?.params.id
    );

    const isConversationDetailsDashboard = [
        ...conversationPathList.map((path) => path),
        "qms",
    ].includes(
        matchPath<"meeting_type" | "conversation_id", string>(
            "/:meeting_type/:conversation_id/*",
            location.pathname
        )?.params?.meeting_type || ""
    );

    const isSalesAssistPath =
        location.pathname === DashboardRoutesConfig.SalesAssist.path;

    const isParameterAiConfigPath = isDefined(
        matchPath<"parameter_id", string>(
            `/settings/${SettingRoutes.AUDIT_MANAGER.path}/:template_id/category/:category_id/parameter/:parameter_id/configure`,
            location.pathname
        )?.params.parameter_id
    );

    const isLiveConversationDetailDashBoard = isDefined(
        matchPath<"id", string>(
            `${DashboardRoutesConfig["LiveConversation"].path}/:id`,
            location.pathname
        )?.params.id
    );

    const showHeader = !(
        isAccountDetailsDashboard ||
        isConversationDetailsDashboard ||
        isParameterAiConfigPath ||
        isSalesAssistPath ||
        isLiveConversationDetailDashBoard
    );

    const logError = (error: Error, info: ErrorInfo) => {
        // Do something with the error, e.g. log to an external API
        console.error(error.message);
        console.error(info);
    };

    return (
        <>
            <Banner />
            {showHeader && <GlobalDashboardHeader />}
            <div className="flex flex-1 overflow-hidden">
                <Navigation hideLogo={showHeader} />
                <ErrorBoundary FallbackComponent={PageCrash} onError={logError}>
                    <main className="relative flex-1 overflow-hidden">
                        {children}
                    </main>
                </ErrorBoundary>
            </div>
        </>
    );
}
