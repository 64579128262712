// ----------------------------------------------------------------------
import useAuth from "@convin/hooks/useAuth";
import FigtreeRegularOTF from "../../assets/fonts/figtree/Figtree-Regular.ttf";
import zIndex from "@mui/material/styles/zIndex";

export default function CssBaseline() {
    const scrollRadius = !import.meta.env.PROD ? "6px" : "10px";
    return {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "Figtree",
                    src: `local('Figtree-Regular'), url(${FigtreeRegularOTF}) format("opentype")`,
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontDisplay: "swap",
                    fontFeatureSettings: '"liga" 1, "calt" 1', // Enable standard ligatures and contextual alternates if needed
                },
                html: {
                    width: "100%",
                    height: "100%",
                    "& #gist-app": {
                        display: "none",
                        position: "relative",
                        zIndex: -1,
                    },
                    "*": {
                        "&::-webkit-scrollbar": {
                            width: scrollRadius,
                            height: scrollRadius,
                        },
                        ".scrollbar-thin": {
                            "&::-webkit-scrollbar": {
                                width: "5px",
                            },
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent !important",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "transparent",
                            borderRadius: scrollRadius,
                        },

                        "&:hover::-webkit-scrollbar-thumb": {
                            backgroundColor: "#99999966 !important",
                        },
                        ".scroll-thumb-visible": {
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#99999966 !important",
                            },
                        },
                    },
                },
                body: {
                    width: "100%",
                    height: "100%",

                    "& .Toastify__toast": {
                        fontFamily: "Figtree",
                    },

                    "& .Toastify__toast-container--top-center": {
                        width: "500px",
                    },

                    "& .Toastify__close-button--dark": {
                        top: "50%",
                        transform: "translateY(-50%)",
                        "& svg": {
                            width: "24px",
                            height: "24px",
                        },
                    },

                    "& .react-flow__handle": {
                        height: "10px",
                        width: "10px",
                    },
                },
                "#root": {
                    width: "100%",
                    height: "100%",
                },
            },
        },
    };
}
