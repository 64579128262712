import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { TextField } from "@mui/material";

export default function MinimumLeadScoreFilter() {
    const { state, updateState } = useGlobalFiltersStateContext();

    return (
        <TextField
            value={state.minLeadScore ?? ""}
            onChange={(e) => {
                updateState({
                    minLeadScore:
                        e.target.value === "" ? null : Number(e.target.value),
                });
            }}
            type="number"
            InputProps={{
                inputProps: {
                    max: 100,
                    min: 0,
                },
            }}
            placeholder="Enter a number between 0 to 100 (in %)"
            className="w-full"
        />
    );
}
