import useAuth from "@convin/hooks/useAuth";
import { PropsWithChildren, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PageLoader from "../custom_components/PageLoader";

export default function AuthGuard({
    children,
}: PropsWithChildren): ReactElement {
    const { isAuthenticated, isInitialized } = useAuth();
    const location = useLocation();

    if (!isInitialized) return <PageLoader />;
    if (isInitialized) {
        if (!isAuthenticated) return <Navigate to={location.pathname} />;
    }
    return <>{children}</>;
}
