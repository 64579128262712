import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { ReactElement } from "react";

export default function ConvinTextLogo({
    sx,
    ...rest
}: SvgIconProps): ReactElement {
    const theme = useTheme();
    const textColor = theme.palette.textColors[333];
    return (
        <SvgIcon
            viewBox="0 0 89 28"
            sx={{ width: 89, height: 28, fill: "none", ...sx }}
            {...rest}
        >
            <g clipPath="url(#clip0_30521_1698)">
                <path
                    d="M33.0573 12.5066L34.711 10.5497C33.7326 9.50231 32.1753 8.84082 30.7145 8.84082C27.724 8.84082 25.519 10.9218 25.519 13.7331C25.519 16.5858 27.6827 18.7081 30.618 18.7081C32.0788 18.7081 33.6637 17.9777 34.711 16.8476L33.0573 15.0837C32.3958 15.7589 31.5 16.1999 30.7559 16.1999C29.4467 16.1999 28.4544 15.125 28.4544 13.7055C28.4544 12.2861 29.4467 11.2249 30.7559 11.2249C31.5552 11.2249 32.4509 11.7348 33.0573 12.5066Z"
                    fill={textColor}
                />
                <path
                    d="M40.7015 8.84082C37.6697 8.84082 35.4371 10.908 35.4371 13.7469C35.4371 16.5996 37.6697 18.7081 40.7015 18.7081C43.7333 18.7081 45.9659 16.5858 45.9659 13.7469C45.9659 10.9218 43.7333 8.84082 40.7015 8.84082ZM40.7428 11.2387C42.0107 11.2387 43.0305 12.2999 43.0305 13.7607C43.0305 15.2215 42.0107 16.3102 40.7428 16.3102C39.475 16.3102 38.3725 15.2215 38.3725 13.7607C38.3725 12.2999 39.4612 11.2387 40.7428 11.2387Z"
                    fill={textColor}
                />
                <path
                    d="M47.5887 8.92351V18.584H50.2209V13.2094L54.3139 18.584H56.7531V8.92351H54.1347V14.3257L50.0417 8.92351H47.5887Z"
                    fill={textColor}
                />
                <path
                    d="M58.0789 8.92351L61.7722 18.584H64.7489L68.4836 8.92351H65.5482L63.3433 15.6073L61.1383 8.92351H58.0789Z"
                    fill={textColor}
                />
                <path
                    d="M69.7935 8.92351V18.584H72.6599V8.92351H69.7935Z"
                    fill={textColor}
                />
                <path
                    d="M74.8397 8.92351V18.584H77.4719V13.2094L81.5649 18.584H84.0041V8.92351H81.3857V14.3257L77.2927 8.92351H74.8397Z"
                    fill={textColor}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8668 10.4777C14.2686 9.70758 13.4799 9.112 12.5831 8.74679C12.3999 8.67216 12.2121 8.60715 12.0206 8.55224C10.8916 8.22859 9.68892 8.27239 8.58647 8.67731C7.48402 9.08223 6.53892 9.82728 5.88781 10.8047C5.23671 11.7822 4.91335 12.9414 4.96448 14.1147C5.01561 15.2881 5.43859 16.4148 6.17226 17.3319C6.90593 18.249 7.91227 18.909 9.04576 19.2165C10.1793 19.5239 11.3812 19.463 12.4777 19.0423C12.6638 18.9709 12.8452 18.8898 13.0212 18.7996C13.8821 18.3581 14.6156 17.6968 15.1445 16.8784C15.1449 16.8778 15.1453 16.8772 15.1457 16.8765L12.5878 15.225C12.5877 15.2252 12.5875 15.2255 12.5874 15.2257C12.3836 15.5411 12.1123 15.8047 11.7952 15.9986C11.6663 16.0775 11.5298 16.1449 11.3873 16.1996C10.8938 16.3889 10.3529 16.4163 9.84288 16.278C9.3328 16.1396 8.87995 15.8426 8.5498 15.4299C8.21965 15.0172 8.02931 14.5102 8.0063 13.9822C7.98329 13.4542 8.1288 12.9325 8.4218 12.4927C8.71479 12.0528 9.14009 11.7175 9.63619 11.5353C10.1323 11.3531 10.6735 11.3334 11.1815 11.4791C11.3283 11.5211 11.4701 11.5764 11.6054 11.6437C11.9383 11.8095 12.2316 12.0485 12.4621 12.3452C12.4622 12.3453 12.4623 12.3454 12.4624 12.3455L14.8668 10.4777Z"
                    fill="#6699FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0307 16.6416C17.5594 16.6416 18.7986 15.4024 18.7986 13.8737C18.7986 12.345 17.5594 11.1058 16.0307 11.1058C14.502 11.1058 13.2628 12.345 13.2628 13.8737C13.2628 15.4024 14.502 16.6416 16.0307 16.6416ZM16.0307 19.4095C19.0881 19.4095 21.5665 16.9311 21.5665 13.8737C21.5665 10.8164 19.0881 8.33789 16.0307 8.33789C12.9733 8.33789 10.4949 10.8164 10.4949 13.8737C10.4949 16.9311 12.9733 19.4095 16.0307 19.4095Z"
                    fill="#5FE6EB"
                />
                <rect
                    x="88.3259"
                    y="8.58691"
                    width="1.22675"
                    height="9.81398"
                    fill={textColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_30521_1698">
                    <rect width="88.9392" height="27.7935" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
