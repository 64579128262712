import Error404Svg from "@convin/components/svg/Error404Svg";
import EmptyState from "./EmptyState";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DashboardRoutesConfig } from "@convin/config/routes.config";

const {
    subRoutes: { overall_dashboard },
} = DashboardRoutesConfig["Home"];

const Error404State = () => {
    return (
        <EmptyState
            Component={<Error404Svg />}
            text="Oops! Page Not Found"
            subtext="Seems we've hit a snag in our journey. Redirect to the homepage"
            ButtonComponent={
                <Link to={overall_dashboard.to}>
                    {" "}
                    <Button variant="global">Back Home</Button>
                </Link>
            }
        />
    );
};

export default Error404State;
