/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react";

import {
    datekeys,
    defaultConfig,
    durationkeys,
} from "@convin/config/default.config";
import { FiltersPayload } from "@convin/type/CustomDashboard";
import {
    AuditType,
    DrawerFiltersType,
    Level,
    StaticDashboardFiltersType,
} from "@convin/type/Filters.model";
import {
    getInsightValue,
    getTimeZone,
    isDefined,
} from "@convin/utils/helper/common.helper";
import {
    convertDateToEpoch,
    isValidDateKeyExpr,
    convertDaysToEpoch,
} from "@convin/utils/helper/date.helper";
import { initialCIFilterState } from "@convin/config/ci.config";
import { initialConversationListFilterState } from "@convin/config/conversation.config";
import { initialAccountListFilterState } from "@convin/config/accounts.config";
import { prepareConversationListSearchData } from "@convin/utils/searchData/searchData.utils";
import { SearchFilter } from "@convin/type/Search";
import { SamplingRule } from "@convin/type/Sampling.model";
import { useGetTemplatesQuery } from "@convin/redux/services/settings/auditManager.service";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { ConversationSearchDecodedFilters } from "@convin/type/Conversation";
import { getAuditDashboardPayload } from "@convin/utils/helper/dashboardGraphs.helper";
import { AuditDashboardFiltersPayload } from "@convin/type/Analytics";
import useGlobalSearchContext from "@convin/modules/GlobalDashboardLayout/hooks/useGlobalSearchContext";
import { ConversationTypeConst, MeetingTypeConst } from "@convin/type/Common";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { convertedTenureBuckets } from "@convin/utils/helper/search.helper";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import { showToast } from "@convin/utils/toast";
import { useGetRestrictedUsersQuery } from "@convin/redux/services/settings/users.service";

const {
    Home: {
        subRoutes: {
            accuracy_dashboard,
            audit_dashboard,
            rep_level_dashboard,
            team_level_dashboard,
            overall_dashboard,
        },
    },
    Account,
} = DashboardRoutesConfig;

export type GlobalFiltersStateType = {
    is_primary?: boolean;
    dateOptions: typeof defaultConfig.dateConfig;
    auditDateOptions: typeof defaultConfig.dateConfig;
    joiningDateOptions: typeof defaultConfig.dateConfig;
    closeDateOptions: typeof defaultConfig.dateConfig;
    lastContactedDateOptions: typeof defaultConfig.dateConfig;
    durationOptions: typeof defaultConfig.durationConfig;
} & StaticDashboardFiltersType &
    DrawerFiltersType;

export interface IGlobalFiltersStateContext {
    state: GlobalFiltersStateType;
    updateState: (e: Partial<GlobalFiltersStateType>) => void;
    prepareFilters: (trackApliedBtnClick?: boolean) => Partial<FiltersPayload>;
    payload: Partial<FiltersPayload>;
    auditDashboardPayload: AuditDashboardFiltersPayload;
    statisticsPayload: SearchFilter[];
    searchPayload: SearchFilter[];
    handleAppliedButtonClick: () => void;
    activeView: string | null;
    handleActiveView: (e: IGlobalFiltersStateContext["activeView"]) => void;
    activeSamplingRule: SamplingRule | null;
    handleActiveSamplingRule: (
        e: IGlobalFiltersStateContext["activeSamplingRule"]
    ) => void;
    resetFilters: (e?: Partial<GlobalFiltersStateType>) => void;
    timeStampWhenAppliedButtonWasClicked: number | undefined;
    handleTemplateSelection: (
        team_ids: number[],
        meetingType: NonNullable<MeetingTypeConst>
    ) => void;
    prepareSearchFilters: (
        e?: Partial<GlobalFiltersStateType>,
        includeDateExpression?: boolean
    ) => SearchFilter[];
    levelLabel: string;
}

export const GlobalFiltersStateContext = createContext<
    IGlobalFiltersStateContext | undefined
>(undefined);

export const initialDrawerState: DrawerFiltersType = {
    ...initialCIFilterState,
    ...initialConversationListFilterState,
    ...initialAccountListFilterState,
    level: Level.conversation,
    template: null,
    dateKey: datekeys.last30days,
    convinInsights: {
        module: null,
        option_type: null,
        insights: { level1: null, level2: null, level3: null },
        error: undefined,
    },
};

export const GlobalFiltersInitialState: GlobalFiltersStateType = {
    meetingType: "call",
    teams: [],
    reps: [],
    durationKey: 2,
    dateOptions: defaultConfig.dateConfig,
    module: null,
    durationOptions: defaultConfig.durationConfig,
    auditDateOptions: defaultConfig.dateConfig,
    joiningDateOptions: defaultConfig.dateConfig,
    lastContactedDateOptions: defaultConfig.dateConfig,
    closeDateOptions: defaultConfig.dateConfig,
    ...initialDrawerState,
};

enum ActionKind {
    updateState = "UPDATE",
    resetState = "RESET",
}

type Action = {
    type: "UPDATE" | "RESET";
    payload: Partial<GlobalFiltersStateType>;
};

function Reducer(state: GlobalFiltersStateType, action: Action) {
    const { type, payload } = action;
    switch (type) {
        case ActionKind.updateState:
            return {
                ...state,
                ...payload,
            };
        case ActionKind.resetState:
            return {
                ...GlobalFiltersInitialState,
                ...payload,
                //Don't clear the template filter for analytics page
                analyticsTemplate: state.analyticsTemplate,
                //Don't clear the meeting type filter
                meetingType: state.meetingType,
            };
        default:
            return state;
    }
}

export default function GlobalFiltersStateContextProvider({
    children,
    initState,
}: PropsWithChildren<{
    initState?: Partial<GlobalFiltersStateType>;
}>): ReactElement {
    const location = useLocation();
    const isAuditDashboard = location.pathname === audit_dashboard.to;
    const isAccuracyDashboard = location.pathname === accuracy_dashboard.to;
    const isAccountDashboard = location.pathname === Account.to;
    const { data: domainConfig } = useGetDomainConfigQuery();
    const [state, dispatch] = useReducer(Reducer, {
        ...GlobalFiltersInitialState,
        ...initState,
    });
    const { searchIsDirty, handleSearchKeyword } = useGlobalSearchContext();
    const [activeView, setActiveView] =
        useState<IGlobalFiltersStateContext["activeView"]>(null);
    const [activeSamplingRule, setActiveSamplingRule] =
        useState<IGlobalFiltersStateContext["activeSamplingRule"]>(null);
    const [
        timeStampWhenAppliedButtonWasClicked,
        setTimeStampWhenAppliedButtonWasClicked,
    ] = useState<undefined | number>();

    const { data } = useGetTemplatesQuery();
    const templates = data?.filter((e) => !e.is_disabled);

    const handleAppliedButtonClick = useCallback(() => {
        setTimeStampWhenAppliedButtonWasClicked(new Date().getTime());
    }, []);

    const handleActiveView = useCallback(
        (val: string | null) => {
            isDefined(activeSamplingRule) && setActiveSamplingRule(null);
            setActiveView(val);
        },
        [activeSamplingRule]
    );

    const handleActiveSamplingRule = useCallback(
        (val: IGlobalFiltersStateContext["activeSamplingRule"]) => {
            isDefined(activeView) && setActiveSamplingRule(null);
            setActiveSamplingRule(val);
        },
        [activeView]
    );

    const updateState: IGlobalFiltersStateContext["updateState"] = useCallback(
        (state) => {
            dispatch({
                type: ActionKind.updateState,
                payload: {
                    ...state,
                },
            });
            isDefined(activeSamplingRule) && setActiveSamplingRule(null); // If a sampling rule is set and filters change, then revert back to 'All Conversations'
        },
        [activeSamplingRule]
    );
    const resetFilters: IGlobalFiltersStateContext["resetFilters"] =
        useCallback((resetState) => {
            const initFilters: Partial<ConversationSearchDecodedFilters> = {};
            searchIsDirty.current = false;
            if (isDefined(domainConfig)) {
                const { filters } = domainConfig;

                if (isDefined(filters.processingStatus)) {
                    initFilters.processingStatus = filters.processingStatus;
                }
                const { durationOptions } = state;
                const durationKey = Object.keys(state.durationOptions).find(
                    (key) => {
                        const [min, max] = durationOptions[key].value;
                        return (
                            min === filters.defaultDurationRange?.[0] &&
                            max === filters.defaultDurationRange?.[1]
                        );
                    }
                );

                if (isDefined(filters.defaultDurationRange)) {
                    initFilters.minDuration =
                        filters.defaultDurationRange?.[0] ?? null;
                    initFilters.maxDuration =
                        filters.defaultDurationRange?.[1] ?? null;
                }

                initFilters.meetingType = filters.meeting_type || "call";
                dispatch({
                    type: ActionKind.resetState,
                    payload: {
                        ...resetState,
                        ...initFilters,
                        durationKey:
                            initFilters.meetingType !== "call"
                                ? durationkeys.any
                                : durationKey ?? state.durationKey,
                        dateKey:
                            filters?.defaultDate ??
                            GlobalFiltersInitialState.dateKey,
                    },
                });
                handleSearchKeyword("");
                handleAppliedButtonClick();
                handleActiveView(null);
                handleActiveSamplingRule(null);
            }
        }, []);

    const prepareFilters: IGlobalFiltersStateContext["prepareFilters"] =
        useCallback(() => {
            const min_duration =
                state.durationOptions?.[state.durationKey]?.value?.[0];
            const max_duration =
                state.durationOptions?.[state.durationKey]?.value?.[1];
            const insightValue = getInsightValue(state.convinInsights.insights);

            return {
                ...(domainConfig?.has_chat && {
                    meeting_type: state.meetingType,
                }),
                ...(state.teams?.length && {
                    teams_id: state.teams,
                }),

                ...(state.accountTags.tagIds?.length && {
                    account_tags_id: state.accountTags.tagIds,
                }),
                ...(state.reps?.length && {
                    reps_id: state.reps,
                }),

                template_id: state.analyticsTemplate,
                ...(isDefined(min_duration) && {
                    min_duration: min_duration * 60,
                }),
                ...(isDefined(max_duration) && {
                    max_duration: max_duration * 60,
                }),
                ...(isDefined(state.dateKey) && !isAccuracyDashboard
                    ? {
                          start_date: convertDateToEpoch(
                              state.dateOptions?.[state.dateKey]?.dateRange[0]
                          ),
                          end_date: convertDateToEpoch(
                              state?.dateOptions?.[state.dateKey]?.dateRange[1]
                          ),
                      }
                    : isAccuracyDashboard && isDefined(state.auditDateKey)
                    ? {
                          start_date: convertDateToEpoch(
                              state.auditDateOptions?.[state.auditDateKey]
                                  ?.dateRange?.[0]
                          ),
                          end_date: convertDateToEpoch(
                              state.auditDateOptions?.[state.auditDateKey]
                                  ?.dateRange?.[1]
                          ),
                      }
                    : {}),
                ...(isDefined(state.auditDateKey) &&
                (Boolean(state.auditType) || isAuditDashboard)
                    ? {
                          audit_start_date: convertDateToEpoch(
                              state.auditDateOptions?.[state.auditDateKey]
                                  ?.dateRange?.[0]
                          ),
                          audit_end_date: convertDateToEpoch(
                              state.auditDateOptions?.[state.auditDateKey]
                                  ?.dateRange?.[1]
                          ),
                      }
                    : {}),
                is_call_level: state.level === Level.conversation,
                ...(!isAccuracyDashboard && {
                    is_manual: state.auditType === AuditType.manual,
                }),
                auditors_id:
                    state.auditType === AuditType.manual ||
                    isAuditDashboard ||
                    isAccuracyDashboard
                        ? state?.auditors?.length
                            ? state.auditors
                            : undefined
                        : undefined,
                timezone: getTimeZone(),
                stages_id: state.stage ? [state.stage] : undefined,
                tags_id: state.tags?.tagIds?.length
                    ? state.tags.tagIds
                    : undefined,
                accountReps: state.accountReps.length
                    ? state.accountReps
                    : undefined,
                accountTeams: state.accountTeams.length
                    ? state.accountTeams
                    : undefined,
                ...(isDefined(state.dispositionTemplate.template) && {
                    ai_disposition_template: state.dispositionTemplate.template,
                    ...(isDefined(state.dispositionTemplate.level1) && {
                        level_1: state.dispositionTemplate.level1,
                        ...(isDefined(state.dispositionTemplate.level2) && {
                            level_2: state.dispositionTemplate.level2,
                            ...(isDefined(state.dispositionTemplate.level3) && {
                                level_3: state.dispositionTemplate.level3,
                            }),
                        }),
                    }),
                }),
                ...(state.agentTenure && {
                    tenure_range: convertedTenureBuckets(state.agentTenure),
                }),
                ...(isDefined(state.convinInsights.option_type)
                    ? {
                          ...(state.convinInsights.module ===
                          "customer_intelligence"
                              ? {
                                    is_primary: state.is_primary ?? true,
                                    ...(isDefined(insightValue)
                                        ? {
                                              insight_id: insightValue,
                                          }
                                        : {
                                              insight_type:
                                                  state.convinInsights
                                                      .option_type,
                                          }),
                                }
                              : state.convinInsights.module === "ai_insights"
                              ? isDefined(insightValue)
                                  ? {
                                        tag_id: insightValue,
                                    }
                                  : {
                                        gpt_question_id: state.convinInsights
                                            .option_type as number,
                                    }
                              : isDefined(insightValue)
                              ? {
                                    search_phrase_id: insightValue,
                                }
                              : {
                                    tab_id: state.convinInsights.option_type,
                                }),
                          ...(state.convinInsights.module !==
                              "custom_tracking" && !isDefined(insightValue)
                              ? {
                                    parent__insight_id: null,
                                }
                              : {}),
                      }
                    : {}),
                ...(state.scoresense.csat && {
                    score_sense_type: "CSAT",
                    score_sense_value: state.scoresense.csat,
                }),
                ...(state.scoresense.collection && {
                    score_sense_type: "Collection",
                    score_sense_value: state.scoresense.collection,
                }),
                ...(state.scoresense.lead && {
                    score_sense_type: "Lead",
                    score_sense_value: state.scoresense.lead,
                }),
            };
        }, [state]);

    const payload = useMemo(() => {
        return prepareFilters();
    }, [
        isAccountDashboard ? state.accountTeams : state.teams,
        isAccountDashboard ? state.accountReps : state.reps,
        state.durationKey,
        state.analyticsTemplate,
        state.meetingType,
        isAccuracyDashboard ? state.auditDateKey : state.dateKey,
        timeStampWhenAppliedButtonWasClicked,
    ]);

    const auditDashboardPayload = useMemo(() => {
        return getAuditDashboardPayload(prepareFilters());
    }, [
        state.auditDateKey,
        state.teams,
        state.reps,
        state.meetingType,
        timeStampWhenAppliedButtonWasClicked,
    ]);

    const staticDateDurationFilters = () => {
        const { durationOptions, durationKey, ..._ } = state; // Exclude audit-type, audit date and auditors as they are set at rule level and not as part of filters
        const minDuration = durationOptions?.[durationKey]?.value?.[0];
        const maxDuration = durationOptions?.[durationKey]?.value?.[1];

        return {
            meetingType: domainConfig?.has_chat ? state.meetingType : undefined,
            minDuration: isDefined(minDuration) ? minDuration * 60 : null,
            maxDuration: isDefined(maxDuration) ? maxDuration * 60 : null,
            ...(isDefined(state.dateKey)
                ? {
                      startDate:
                          (state.dateOptions?.[state.dateKey]
                              .dateRange?.[0] as string) ?? null,
                      endDate:
                          (state.dateOptions[state.dateKey]
                              .dateRange[1] as string) ?? null,
                  }
                : {}),
            ...(isDefined(state.auditDateKey)
                ? {
                      auditStartDate:
                          (state.auditDateOptions[state.auditDateKey]
                              .dateRange[0] as string) ?? null,
                      auditEndDate:
                          (state.auditDateOptions[state.auditDateKey]
                              .dateRange[1] as string) ?? null,
                  }
                : {}),
            ...(isDefined(state.joiningDateKey)
                ? {
                      startJoinTime:
                          (state.joiningDateOptions[state.joiningDateKey]
                              .dateRange[0] as string) ?? null,
                      endJoinTime:
                          (state.joiningDateOptions[state.joiningDateKey]
                              .dateRange[1] as string) ?? null,
                  }
                : {}),
        };
    };

    const statisticsPayload = useMemo(() => {
        const { callTypes, teams, reps, meetingType } = state;
        return prepareConversationListSearchData({
            ...staticDateDurationFilters(),
            teams,
            reps,
            callTypes,
            meetingType,
        });
    }, [
        state.teams,
        state.reps,
        state.durationKey,
        state.dateKey,
        state.meetingType,
        timeStampWhenAppliedButtonWasClicked,
    ]);

    const prepareSearchFilters: IGlobalFiltersStateContext["prepareSearchFilters"] =
        useCallback(
            (e, includeDateExpression) => {
                const { dateKey, joiningDateKey, auditDateKey, ...rest } =
                    state;

                return prepareConversationListSearchData({
                    ...rest,
                    ...(includeDateExpression && {
                        ...(isValidDateKeyExpr(dateKey) && {
                            dateKey,
                        }),
                        ...(isValidDateKeyExpr(auditDateKey) && {
                            auditDateKey,
                        }),
                        ...(isValidDateKeyExpr(joiningDateKey) && {
                            joiningDateKey,
                        }),
                    }),
                    ...staticDateDurationFilters(),
                    ...(isDefined(state.auditDateKey)
                        ? {
                              auditStartDate:
                                  (state.auditDateOptions[state.auditDateKey]
                                      .dateRange[0] as string) ?? null,
                              auditEndDate:
                                  (state.auditDateOptions[state.auditDateKey]
                                      .dateRange[1] as string) ?? null,
                          }
                        : {}),
                    ...(isDefined(state.joiningDateKey)
                        ? {
                              startJoinTime:
                                  (state.joiningDateOptions?.[
                                      state.joiningDateKey
                                  ]?.dateRange[0] as string) ?? null,
                              endJoinTime:
                                  (state.joiningDateOptions?.[
                                      state.joiningDateKey
                                  ]?.dateRange[1] as string) ?? null,
                          }
                        : {}),
                    ...(state?.is_primary && {
                        isPrimary: state?.is_primary,
                    }),
                    ...e,
                });
            },
            [state]
        );

    const searchPayload = useMemo(
        () => prepareSearchFilters(),
        [
            state.teams,
            state.reps,
            state.durationKey,
            state.dateKey,
            state.meetingType,
            timeStampWhenAppliedButtonWasClicked,
        ]
    );

    const handleTemplateSelection: IGlobalFiltersStateContext["handleTemplateSelection"] =
        useCallback(
            (team_ids: number[], meetingType: ConversationTypeConst["id"]) => {
                const { analyticsTemplate } = state;
                if (!team_ids.length) {
                    const filterTemplates = templates?.filter(
                        ({ parameters, teams }) =>
                            !!parameters?.meeting_type?.length
                                ? parameters?.meeting_type.includes(
                                      meetingType as ConversationTypeConst["id"]
                                  )
                                : parameters?.call_tags?.length !== 0 ||
                                  parameters?.call_types.length !== 0 ||
                                  teams.length
                    );
                    return updateState({
                        analyticsTemplate: filterTemplates?.find(
                            (e) => e.id === analyticsTemplate
                        )
                            ? analyticsTemplate
                            : filterTemplates?.[0]?.id ?? undefined,
                    });
                }
                const getTemplatesOfTeam = templates
                    ?.filter(({ parameters }) =>
                        !!parameters?.meeting_type?.length
                            ? parameters?.meeting_type.includes(
                                  meetingType as ConversationTypeConst["id"]
                              )
                            : true
                    )
                    ?.filter(({ teams, parameters }) =>
                        team_ids.length
                            ? teams.find(({ id }) => team_ids?.includes(id))
                                ? true
                                : (parameters?.call_tags?.length ||
                                      parameters?.call_types?.length) &&
                                  !teams.length
                            : true
                    );
                if (getTemplatesOfTeam?.length) {
                    updateState({
                        analyticsTemplate: getTemplatesOfTeam[0]?.id,
                    });
                    return;
                }

                updateState({
                    analyticsTemplate: null,
                });
            },
            [templates, state.meetingType]
        );

    useEffect(() => {
        prepareFilters();
        prepareSearchFilters();
    }, []);

    useEffect(() => {
        if (state.agentTenure && state.reps.length) {
            updateState({ reps: [] });
        }
    }, [timeStampWhenAppliedButtonWasClicked, state.agentTenure]);

    useEffect(() => {
        if (state.convinInsights.module && !state.convinInsights.option_type) {
            const errorMessage = `Please select the  ${
                state.convinInsights.module === "custom_tracking"
                    ? "Group"
                    : "Insight"
            } to use the Convin Insights filter`;
            updateState({
                convinInsights: {
                    ...state.convinInsights,
                    error: errorMessage,
                },
            });
            showToast({
                type: "error",
                message: errorMessage,
            });
        }
    }, [timeStampWhenAppliedButtonWasClicked]);
    const navigate = useNavigate();

    const { data: repList } = useGetRestrictedUsersQuery(undefined, {
        skip: location.pathname !== rep_level_dashboard.path,
    });

    const route = matchPath<"dashboard_type" | "sub_route", string>(
        "/:dashboard_type/:sub_route",
        location.pathname
    );

    return (
        <GlobalFiltersStateContext
            value={{
                state: state ?? GlobalFiltersInitialState,
                updateState,
                prepareFilters,
                payload,
                // accountPayload,
                auditDashboardPayload,
                handleAppliedButtonClick,
                resetFilters,
                timeStampWhenAppliedButtonWasClicked,
                searchPayload,
                activeView,
                handleActiveView,
                activeSamplingRule,
                handleActiveSamplingRule,
                statisticsPayload,
                handleTemplateSelection,
                prepareSearchFilters,
                levelLabel:
                    state.level === Level.account
                        ? "Accounts"
                        : "Conversations",
            }}
        >
            {children}
        </GlobalFiltersStateContext>
    );
}
