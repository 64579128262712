import { userType } from "@convin/config/userType.config";
import { useGetRolesQuery } from "@convin/redux/services/settings/roleManager.service";
import { useGetTeamsQuery } from "@convin/redux/services/settings/teamManager.service";
import { useGetUsersQuery } from "@convin/redux/services/settings/users.service";
import {
    flattenTeams,
    getUserName,
    isDefined,
} from "@convin/utils/helper/common.helper";
import React, { useEffect, useState } from "react";

export type PopoverFilterType = {
    label: string;
    value: string | number | boolean | null;
};

export type useHandleFiltersPopoverStateReturnType<
    T extends PosibleFilterKeys
> = {
    handleFilterToShow: (e: T, data?: Array<string | number>) => void;
    setAnchorPosition: (
        event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
    ) => void;
    removeAnchorPosition: () => void;

    activeFilterKey: T | null;
    filters: PopoverFilterType[];
    anchorEl: HTMLDivElement | HTMLButtonElement | null;

    activeFilters: Record<T, Array<string | number | boolean | null>>;
    setActiveFilters: React.Dispatch<
        React.SetStateAction<Record<T, Array<string | number | boolean | null>>>
    >;
    resetPopoverFilters: (filterKey: PosibleFilterKeys) => void;
    filterLoaders: Record<"owners", boolean>;
};

export type PosibleFilterKeys =
    | "owners"
    | "fileType"
    | "role"
    | "team"
    | "user_type"
    | "status"
    | string;

export default function useHandleFiltersPopoverState<
    T extends PosibleFilterKeys
>({
    columnKeys,
}: {
    columnKeys: T[];
}): useHandleFiltersPopoverStateReturnType<T> {
    const { data: users, isLoading } = useGetUsersQuery();
    const { data: roles } = useGetRolesQuery();
    const { data: teams } = useGetTeamsQuery();

    const [anchorEl, setAnchorEl] = useState<
        HTMLDivElement | HTMLButtonElement | null
    >(null);
    const [filters, setFilters] = useState<
        useHandleFiltersPopoverStateReturnType<T>["filters"]
    >([]);
    const [activeFilterKey, setActiveFilterKey] = useState<T | null>(null);

    const [activeFilters, setActiveFilters] = useState<
        useHandleFiltersPopoverStateReturnType<T>["activeFilters"]
    >(() => {
        const obj = columnKeys.reduce((accumulator, value) => {
            return { ...accumulator, [value]: [] };
        }, {}) as useHandleFiltersPopoverStateReturnType<T>["activeFilters"];
        return obj;
    });

    const setAnchorPosition: useHandleFiltersPopoverStateReturnType<T>["setAnchorPosition"] =
        (event) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
        };

    const removeAnchorPosition: useHandleFiltersPopoverStateReturnType<T>["removeAnchorPosition"] =
        () => {
            setAnchorEl(null);
        };

    const resetPopoverFilters = (filterKey: PosibleFilterKeys) => {
        setActiveFilters((prevFilters) => ({
            ...prevFilters,
            [filterKey]: [],
        }));
        removeAnchorPosition();
    };

    useEffect(() => {
        if (isDefined(activeFilterKey)) handleFilterToShow(activeFilterKey);
    }, [users, roles, teams]);

    const handleFilterToShow: useHandleFiltersPopoverStateReturnType<T>["handleFilterToShow"] =
        (type, data) => {
            switch (type) {
                case "owners":
                    {
                        setFilters(
                            Array?.from(
                                new Set(
                                    users?.map((e) => ({
                                        name: getUserName(e),
                                        id: e.id,
                                    }))
                                )
                            ).map((e) => {
                                return {
                                    label: e.name,
                                    value: e.id,
                                };
                            })
                        );
                    }
                    break;
                case "fileType":
                    {
                        setFilters(
                            ["Audio", "Video", "Document", "Snippets"].map(
                                (e) => ({
                                    label: e,
                                    value: e,
                                })
                            )
                        );
                    }
                    break;
                case "role":
                    {
                        setFilters([
                            // { label: "", value: "" },
                            ...(roles || [])?.map((e) => {
                                return {
                                    label: e?.name?.replace(/\_/g, " "),
                                    value: e?.id,
                                };
                            }),
                        ]);
                    }
                    break;
                case "team":
                    {
                        setFilters([
                            // {
                            //     label: "",
                            //     value: "",
                            // },
                            ...flattenTeams(teams || [])?.map((e) => {
                                return {
                                    label: e?.name,
                                    value: e?.id,
                                };
                            }),
                        ]);
                    }
                    break;
                case "user_type":
                    {
                        setFilters(
                            Object.keys(userType).map((e) => {
                                return {
                                    label: userType[+e],
                                    value: Number(e),
                                };
                            })
                        );
                    }
                    break;
                case "status":
                    setFilters([
                        { label: "Active", value: true },
                        { label: "Inactive", value: false },
                    ]);
                    break;
                default: {
                    setFilters(
                        Array.from(new Set(data)).map((e) => {
                            return {
                                label: String(e),
                                value: e,
                            };
                        })
                    );
                }
            }
            setActiveFilterKey(type);
        };

    return {
        handleFilterToShow,
        setAnchorPosition,
        removeAnchorPosition,
        activeFilters,
        activeFilterKey,
        filters,
        anchorEl,
        setActiveFilters,
        resetPopoverFilters,
        filterLoaders: {
            owners: isLoading,
        },
    };
}
