import {
    Autocomplete,
    TextField,
    Checkbox,
    createFilterOptions,
    AutocompleteProps,
    TextFieldProps,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { flattenTeams } from "@convin/utils/helper/common.helper";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { useGetTeamsQuery } from "@convin/redux/services/settings/teamManager.service";
import { TeamTreeSelectOption } from "@convin/type/Common";

interface Team {
    id: number;
    name: string;
    subteams?: Team[];
}

export const toOptions = (
    category: Team,
    depth = 0,
    parentId: number | null = null
): TeamTreeSelectOption[] => {
    const { id, name, subteams = [] } = category;
    const children = subteams.flatMap((child) =>
        toOptions(child, depth + 1, id)
    );
    const option: TeamTreeSelectOption = {
        id,
        name,
        depth,
        parentId,
        matchTerms: [name].concat(children.map((obj) => obj.name)),
        hasSubteams: subteams.length !== 0,
        subteamsId: children.map((obj) => obj.id),
    };
    return [option].concat(children);
};

function concatIfNotPresent<T>(array: T[], elementsToConcat: T[]): T[] {
    const uniqueElements = new Set(array);

    elementsToConcat.forEach((element) => {
        uniqueElements.add(element);
    });

    return Array.from(uniqueElements);
}

interface MuiTeamSelectorProps {
    value: number[];
    updateValue: (val: number[]) => void;
    size?: AutocompleteProps<
        TeamTreeSelectOption,
        boolean | undefined,
        boolean | undefined,
        boolean | undefined
    >["size"];
    disabled?: boolean;
    autocompleteProps?: Partial<
        AutocompleteProps<TeamTreeSelectOption, true, false, false>
    >;
    limitTags?: number;
}

export default function MuiTeamSelector({
    value = [],
    updateValue,
    size = "medium",
    disabled,
    autocompleteProps = {},
    limitTags,
}: MuiTeamSelectorProps) {
    const { data: teams = [], isLoading } = useGetTeamsQuery();
    const optionsList: TeamTreeSelectOption[] = teams.flatMap((category) =>
        toOptions(category)
    );
    optionsList.unshift({
        id: -1,
        name: "Select All",
        depth: 0,
        parentId: null,
        matchTerms: [],
        hasSubteams: false,
        subteamsId: [],
    });
    const theme = useTheme();

    const handleChange = (
        event: React.SyntheticEvent,
        newValue: TeamTreeSelectOption[],
        reason: string
    ) => {
        let valueToUpdate = newValue.map((e) => e.id);

        if (reason === "removeOption") {
            const removedOptionId = value.filter(
                (e) => !newValue.map((v) => v.id).includes(e)
            );
            const removedOption = optionsList.find(
                (e) => e.id === removedOptionId[0]
            );
            if (removedOption?.hasSubteams) {
                valueToUpdate = valueToUpdate.filter(
                    (e) => !removedOption.subteamsId.includes(e)
                );
            }
            if (removedOption?.parentId) {
                valueToUpdate = valueToUpdate.filter(
                    (e) => e !== removedOption.parentId
                );
            }
        } else {
            if (newValue.length > 0) {
                const lastValue = newValue[newValue.length - 1];
                if (lastValue.id === -1) {
                    updateValue(flattenTeams(teams).map((e) => e.id));
                    return;
                }
                if (lastValue?.hasSubteams) {
                    const everySubteamSelected = lastValue.subteamsId.every(
                        (val) => valueToUpdate.includes(val)
                    );
                    if (everySubteamSelected)
                        valueToUpdate = valueToUpdate
                            .filter((e) => e !== lastValue.id)
                            .filter((e) => !lastValue.subteamsId.includes(e));
                    else
                        valueToUpdate = concatIfNotPresent(
                            valueToUpdate.filter((e) => e !== lastValue.id),
                            lastValue.subteamsId
                        );
                }
            }
        }
        updateValue(valueToUpdate);
    };

    return (
        <Autocomplete<TeamTreeSelectOption, true, false, false>
            multiple
            disableCloseOnSelect
            options={optionsList}
            size={size}
            loading={isLoading}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);
                const valueSet = new Set(value);

                const allSubteamSelected = option.hasSubteams
                    ? option.subteamsId.every((id: number) => valueSet.has(id))
                    : false;

                return (
                    <li {...props} key={option.id} data-value={option.name}>
                        <Checkbox
                            checked={
                                value.includes(option.id) || allSubteamSelected
                            }
                            sx={{ ml: 2 * option.depth }}
                        />
                        <div>
                            {parts.map(
                                (
                                    part: { text: string; highlight: boolean },
                                    index: number
                                ) => (
                                    <span
                                        key={index + part.text}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 600
                                                : 400,
                                            color: theme.palette.textColors[
                                                "333"
                                            ],
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                )
                            )}
                        </div>
                    </li>
                );
            }}
            renderInput={(params: TextFieldProps) => {
                const { InputProps, ...rest } = params;
                const { startAdornment, ...restInputProps } = InputProps || {};
                return (
                    <TextField
                        {...rest}
                        label="Teams and Sub-Teams"
                        placeholder="Search"
                        InputProps={{
                            ...restInputProps,
                            startAdornment: (
                                <div className="overflow-auto max-h-10">
                                    {startAdornment}
                                </div>
                            ),
                        }}
                    />
                );
            }}
            getOptionLabel={(option) =>
                optionsList.find(({ id }) => id === option.id)?.name || ""
            }
            filterOptions={createFilterOptions({
                stringify: (option) => option.matchTerms.join("//"),
            })}
            className="w-full"
            value={optionsList.filter((option) => value.includes(option.id))}
            onChange={handleChange}
            disabled={disabled}
            {...autocompleteProps}
            limitTags={limitTags}
        />
    );
}

interface MuiTeamSelectorControlledProps {
    value?: number[];
    updateValue: (val: number[]) => void;
    name?: string;
    [key: string]: unknown;
}

export function MuiTeamSelectorControlled({
    value = [],
    updateValue,
    name = "teams",
    ...rest
}: MuiTeamSelectorControlledProps) {
    const { data: teams } = useGetTeamsQuery();
    const optionsList: TeamTreeSelectOption[] = (teams ?? [])?.flatMap(
        (category: Team) => toOptions(category)
    );
    optionsList.unshift({
        id: -1,
        name: "Select All",
        depth: 0,
        parentId: null,
        matchTerms: [],
        hasSubteams: false,
        subteamsId: [],
    });
    const theme = useTheme();
    const { control } = useFormContext();

    const handleChange = (
        event: React.SyntheticEvent,
        newValue: TeamTreeSelectOption[],
        reason: string
    ) => {
        let valueToUpdate = newValue.map((e) => e.id);

        if (reason === "removeOption") {
            const removedOptionId = value.filter(
                (e) => !newValue.map((v) => v.id).includes(e)
            );
            const removedOption = optionsList.find(
                (e) => e.id === removedOptionId[0]
            );
            if (removedOption?.hasSubteams) {
                valueToUpdate = valueToUpdate.filter(
                    (e) => !removedOption.subteamsId.includes(e)
                );
            }
            if (removedOption?.parentId) {
                valueToUpdate = valueToUpdate.filter(
                    (e) => e !== removedOption.parentId
                );
            }
        } else {
            if (newValue.length > 0) {
                const lastValue = newValue[newValue.length - 1];
                if (lastValue.id === -1) {
                    updateValue(
                        flattenTeams(teams ?? []).map((e: Team) => e.id)
                    );
                    return;
                }
                if (lastValue?.hasSubteams) {
                    const everySubteamSelected = lastValue.subteamsId.every(
                        (val) => valueToUpdate.includes(val)
                    );
                    if (everySubteamSelected)
                        valueToUpdate = valueToUpdate
                            .filter((e) => e !== lastValue.id)
                            .filter((e) => !lastValue.subteamsId.includes(e));
                    else
                        valueToUpdate = concatIfNotPresent(
                            valueToUpdate.filter((e) => e !== lastValue.id),
                            lastValue.subteamsId
                        );
                }
            }
        }
        updateValue(valueToUpdate);
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ fieldState: { error } }) => (
                <Autocomplete<TeamTreeSelectOption, true, false, false>
                    multiple
                    disableCloseOnSelect
                    options={optionsList}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name, inputValue);
                        const parts = parse(option.name, matches);
                        const isDisabled =
                            option.id === -1 || option.hasSubteams;

                        return (
                            <li {...props} key={option.id}>
                                {!isDisabled && (
                                    <Checkbox
                                        checked={value.includes(option.id)}
                                        sx={{ ml: 2 * option.depth }}
                                    />
                                )}
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index + part.text}
                                            style={{
                                                fontWeight: part.highlight
                                                    ? 600
                                                    : 400,
                                                color: `${
                                                    isDisabled
                                                        ? theme.palette.primary
                                                              .main
                                                        : "black"
                                                }`,
                                                textDecoration: `${
                                                    isDisabled
                                                        ? "underline"
                                                        : "none"
                                                }`,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Teams and Sub-Teams"
                            error={!!error}
                            helperText={error?.message}
                        />
                    )}
                    getOptionLabel={(option) =>
                        optionsList.find(({ id }) => id === option.id)?.name ||
                        ""
                    }
                    filterOptions={createFilterOptions({
                        stringify: (option) => option.matchTerms.join("//"),
                    })}
                    className="w-full"
                    value={optionsList.filter((option) =>
                        value.includes(option.id)
                    )}
                    onChange={handleChange}
                    {...rest}
                />
            )}
        />
    );
}
