import { Box, Divider, Typography } from "@mui/material";
import type {
    BuildingBlockType,
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../../context/NodeStateContext";
import useNodeContext from "../../hooks/useNodeContext";
import SingleBlockSvg from "../../svg/SingleBlockSvg";
import GroupBlockSvg from "../../svg/GroupBlockSvg";
import BlockCard from "../BlockCard";
import { BlockType, SingleBlockTypes } from "../../constants";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { useEffect } from "react";
import {
    MultiResponseBlockData,
    GroupNodeType,
    MultipleResponseNode,
    CombinationNode,
} from "../../../types/bert";
import { isDefined } from "@convin/utils/helper/common.helper";
import type { Node } from "reactflow";
import { useNodeInfoContext } from "../../context/NodeInfoContext";
import ExistingBlockSvg from "../../svg/ExistingBlockSvg";
import CombinationBlockSvg from "../../svg/CombinationBlockSvg";
import { CloseSvg } from "@convin/components/svg";

type BlockTypes<T extends SingleResponseBlockType | BuildingBlockType> = Array<{
    name: T;
    description: string;
    displayName: string;
    svgIcon: React.ReactElement;
}>;

const buildingBlock: BlockTypes<BuildingBlockType> = [
    {
        name: "single",
        displayName: "Single Block",
        description: "Build an elemental rule",
        svgIcon: <SingleBlockSvg />,
    },
    {
        name: "group",
        displayName: "Group Block",
        description: "Create group of blocks",
        svgIcon: <GroupBlockSvg />,
    },
    // {
    //     name: "existingBlock",
    //     displayName: "Existing Block",
    //     description: "Add an existing block",
    //     svgIcon: <ExistingBlockSvg />,
    // },
    {
        name: "default",
        displayName: "Combination Block",
        description: "Create a combination of blocks",
        svgIcon: <CombinationBlockSvg />,
    },
];

const ChooseSingleResponseBlock = () => {
    const { nodeType } = useNodeInfoContext();
    const {
        dispatch: updateAiConfigState,
        isMultiResponseTree,
        aiConfigState: { activeDefaultBlock },
    } = useParameterAiConfigContext();
    const { isGroupBlockTree, createSingleResponseNode, setNodes } =
        useReactFlowStateContextProvider();

    const {
        state,
        dispatch,
        sourceId,
        direction,
        onNodeSaveCallBack,
        blockParentId,
    } = useNodeContext();

    const setActiveBlockType = (
        name: BlockType<SingleResponseBlockType>["name"]
    ) => {
        dispatch({ payload: { selectedBlockType: name }, type: "UPDATE" });
    };

    const setBuildingBlockType = (
        name: BlockType<BuildingBlockType>["name"]
    ) => {
        dispatch({ payload: { buildingBlockType: name }, type: "UPDATE" });
        if (name === "group" && !isGroupBlockTree) {
            if (
                isDefined(sourceId) &&
                isMultiResponseTree &&
                isDefined(blockParentId)
            ) {
                setNodes((nodes) =>
                    nodes.map((n: Node<MultipleResponseNode<"condition">>) =>
                        n.id === blockParentId
                            ? {
                                  ...n,
                                  data: {
                                      ...n.data,
                                      metadata: {
                                          ...n.data.metadata,
                                          blocks: n.data.metadata.blocks.map(
                                              (
                                                  e: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0]
                                              ) =>
                                                  e.id === sourceId
                                                      ? {
                                                            ...e,

                                                            data: {
                                                                ...e.data,
                                                                type: "group_block",
                                                                is_not: false,
                                                                metadata: {
                                                                    ...e.data
                                                                        .metadata,
                                                                    nodes: [],
                                                                    edges: [],
                                                                },
                                                            },
                                                        }
                                                      : e
                                          ),
                                      },
                                  },
                              }
                            : n
                    )
                );
                updateAiConfigState({
                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                    payload: {
                        blockId: sourceId,
                        blockParentId,
                    },
                });
                onNodeSaveCallBack();
            } else {
                const id = createSingleResponseNode<GroupNodeType>({
                    direction,
                    sourceId,
                    data: {
                        type: "group_block",
                        is_not: false,
                        metadata: {
                            name: "Untitled",
                            nodes: [],
                            edges: [],
                        },
                    },
                });
                updateAiConfigState({
                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                    payload: {
                        blockId: id,
                    },
                });
            }
            onNodeSaveCallBack();
        }

        if (name === "default") {
            if (
                isDefined(sourceId) &&
                isMultiResponseTree &&
                isDefined(blockParentId)
            ) {
                setNodes((nodes) =>
                    nodes.map((n: Node<MultipleResponseNode<"condition">>) =>
                        n.id === blockParentId
                            ? {
                                  ...n,
                                  data: {
                                      ...n.data,
                                      metadata: {
                                          ...n.data.metadata,
                                          blocks: n.data.metadata.blocks.map(
                                              (
                                                  e: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0]
                                              ) =>
                                                  e.id === sourceId
                                                      ? {
                                                            ...e,

                                                            data: {
                                                                ...e.data,
                                                                type: "default",
                                                                metadata: {
                                                                    ...e.data
                                                                        .metadata,
                                                                    blocks: [],
                                                                    selected:
                                                                        null,
                                                                },
                                                            },
                                                        }
                                                      : e
                                          ),
                                      },
                                  },
                              }
                            : n
                    )
                );
                updateAiConfigState({
                    type: "SET_ACTIVE_DEFAULT_BLOCK_ID",
                    payload: {
                        blockId: sourceId,
                        blockParentId,
                    },
                });
            } else {
                const id = createSingleResponseNode<CombinationNode>({
                    data: {
                        type: "default",
                        metadata: {
                            blocks: [],
                            selected: null,
                            name: "Untitled",
                        },
                    },
                    direction,
                    sourceId,
                });
                updateAiConfigState({
                    type: "SET_ACTIVE_DEFAULT_BLOCK_ID",
                    payload: {
                        blockId: id,
                    },
                });
            }
        }
    };
    //When the group block drawer or default rule drawer is open, we need to set the building block type to single
    useEffect(() => {
        if (isGroupBlockTree && state.buildingBlockType !== "single") {
            setBuildingBlockType("single");
        }
    }, [isGroupBlockTree]);

    const flag = !isGroupBlockTree;
    return (
        <>
            {flag && (
                <>
                    <Box
                        className="flex items-center justify-between"
                        sx={{
                            mb: 2.5,
                        }}
                    >
                        <Typography
                            className="font-medium"
                            variant="extraLarge"
                        >
                            Choose an Action
                        </Typography>
                        <div className="h-[30px] block_close_button nodrag nopan cursor-pointer">
                            <CloseSvg className="pointer-events-none" />
                        </div>
                    </Box>
                    <Typography
                        className="font-medium uppercase"
                        variant="medium"
                        color="textColors.666"
                        component="p"
                        sx={{
                            mb: 2.5,
                        }}
                    >
                        Building Blocks
                    </Typography>
                    <Box className="grid grid-cols-2" gap={1.5}>
                        {buildingBlock
                            .filter((e) =>
                                isDefined(activeDefaultBlock)
                                    ? e.name !== "default" && e.name !== "group"
                                    : true
                            )
                            .map((block, idx) => (
                                <BlockCard<BuildingBlockType>
                                    {...block}
                                    key={idx}
                                    onClick={setBuildingBlockType}
                                    isActive={
                                        block.name === state.buildingBlockType
                                    }
                                />
                            ))}
                    </Box>
                </>
            )}

            {state.buildingBlockType === "single" || !flag ? (
                <>
                    {!flag ? (
                        <></>
                    ) : (
                        <Divider
                            sx={{
                                my: 1.5,
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            my: 1.5,
                        }}
                    >
                        <div className="flex items-center justify-between">
                            <Typography
                                className="font-semibold uppercase"
                                variant="medium"
                                color="textColors.666"
                                sx={{
                                    mb: 1.5,
                                }}
                                component="p"
                            >
                                Block Type
                            </Typography>

                            {!flag && (
                                <div className="h-[30px] block_close_button nodrag nopan cursor-pointer">
                                    <CloseSvg className="pointer-events-none" />
                                </div>
                            )}
                        </div>
                        <Box className="grid grid-cols-2" gap={1.5}>
                            {SingleBlockTypes.filter((e) =>
                                nodeType === "condition_gpt"
                                    ? e.name === "moment"
                                    : true
                            ).map((block, idx) => (
                                <BlockCard<SingleResponseBlockType>
                                    {...block}
                                    key={idx}
                                    onClick={setActiveBlockType}
                                    isActive={
                                        block.name === state.selectedBlockType
                                    }
                                />
                            ))}
                            {isGroupBlockTree &&
                                buildingBlock
                                    .filter(
                                        (e) =>
                                            e.name === "existingBlock" ||
                                            (!isDefined(activeDefaultBlock) &&
                                                e.name === "default")
                                    )
                                    .map((block, idx) => (
                                        <BlockCard<BuildingBlockType>
                                            {...block}
                                            key={idx}
                                            onClick={setBuildingBlockType}
                                            isActive={
                                                block.name ===
                                                state.buildingBlockType
                                            }
                                        />
                                    ))}
                        </Box>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ChooseSingleResponseBlock;
