import GenericSelect from "@convin/components/select/GenericSelect";
import { conversationScoreOptions } from "@convin/config/conversation.config";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import MinMaxInputField from "@convin/modules/components/globalDashboardFilters/components/MinMaxInputField";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { conversationScoreMapping } from "@convin/utils/helper/common.helper";
import { defaultScoreThresholds } from "@convin/config/default.config";
import { useAccountFilterCheck } from "@convin/hooks/useAccountFilterCheck";

const ConversationScoreFilter = () => {
    const { data: domainConfig } = useGetDomainConfigQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    const accountCheck = useAccountFilterCheck();

    return (
        <>
            <GenericSelect
                options={conversationScoreOptions}
                label={`${
                    accountCheck
                        ? "Choose a Account Score"
                        : "Choose a Conversation Score"
                }`}
                value={filtersState.conversationScoreName}
                handleChange={(value) => {
                    updateFiltersState({
                        conversationScoreName: value,
                        conversationScore: conversationScoreMapping(
                            value,
                            domainConfig?.stats_threshold ??
                                defaultScoreThresholds
                        ),
                    });
                }}
                className="w-full"
                hasClear
            />
            <MinMaxInputField
                showLabel
                name="Custom Score"
                values={filtersState.conversationScore}
                minLabel="Min Score"
                maxLabel="Max Score"
                setValues={(val) => {
                    updateFiltersState({
                        conversationScoreName: null,
                        conversationScore: val,
                    });
                }}
                minLimit={0}
                maxLimit={100}
            />
        </>
    );
};

export default ConversationScoreFilter;
