import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { Box, Popover, PopoverProps, alpha } from "@mui/material";
import { styled } from "@mui/system";
import React, { PropsWithChildren, ReactElement, useCallback } from "react";
import { Label } from "@convin/components/custom_components/Typography/Label";
import { EdgeData } from "../../../types/bert";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";

const SelectDropdown = styled((props: PopoverProps) => <Popover {...props} />)(
    ({ theme }) => ({
        marginTop: "12px !important",
        "& .MuiPaper-root": {
            borderRadius: "6px",
            border: "1px solid",
            borderColor: alpha(theme.palette.textColors["999"], 0.24),
            width: "114px",
            maxHeight: "300px",
        },
    })
);

const values: Array<EdgeData["operator"]> = ["and", "or"];

export default function LogicOperatorSelector({
    operator,
    id,
}: PropsWithChildren<EdgeData & { id: string }>): ReactElement {
    const { updateEdge } = useReactFlowStateContextProvider();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>): void => {
            setAnchorEl(event.currentTarget);
        },
        []
    );

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleChange = (operator: "and" | "or"): void => {
        updateEdge({
            id,
            data: {
                operator,
            },
        });
        handleClose();
    };

    return (
        <>
            <Box
                onClick={handleClick}
                className="flex items-center gap-2 cursor-pointer h-[32px]"
                aria-describedby={id}
                sx={() => ({
                    width: "68px",
                    borderRadius: "6px",
                    p: 0.8,
                    bgcolor: "#121212",
                    "& *": {
                        color: "white",
                    },
                })}
                data-testid="frequency-selector"
            >
                <Label
                    isEllipses
                    colorType="white"
                    className="flex-1 font-medium uppercase"
                    variant="small"
                >
                    {operator}
                </Label>

                <Box
                    sx={{ color: "textColors.666" }}
                    {...(open && { className: "rotate-180" })}
                >
                    <TrailingSvg />{" "}
                </Box>
            </Box>
            <SelectDropdown
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {values.map((e, idx) => (
                    <Box onClick={(): void => handleChange(e)} key={idx}>
                        <Label
                            isEllipses
                            colorType="333"
                            sx={() => ({
                                py: 1,
                                px: 2,
                                transition: "all 0.3s",
                                ...(operator === e && {
                                    bgcolor: "#121212",
                                    color: "white !important",
                                }),
                            })}
                            variant="medium"
                            className="cursor-pointer uppercase"
                        >
                            {e}
                        </Label>
                    </Box>
                ))}
            </SelectDropdown>
        </>
    );
}
