import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import MinMaxInputField from "@convin/modules/components/globalDashboardFilters/components/MinMaxInputField";
import { isDefined } from "@convin/utils/helper/common.helper";

type Props = {
    isSizeFull?: boolean;
    name?: string;
    showLabel?: boolean;
    showOnly?: "MIN" | "MAX";
    minLabel?: string;
    maxLabel?: string;
    minLimit?: number;
    maxLimit?: number;
};

export default function MinMaxInteractions({
    showLabel = true,
    name = "Interactions",
    isSizeFull = false,
    showOnly,
    minLabel,
    maxLabel,
    minLimit,
    maxLimit,
}: Props) {
    const { state, updateState } = useGlobalFiltersStateContext();

    return (
        <MinMaxInputField
            values={state.minMaxInteractions}
            minLabel={minLabel ?? "Minimum Count"}
            maxLabel={maxLabel ?? "Maximum Count"}
            setValues={(val) => {
                updateState({
                    minMaxInteractions: val,
                });
            }}
            {...{
                name,
                showLabel,
                showOnly,
                isSizeFull,
                ...(isDefined(minLimit)
                    ? {
                          minLimit,
                      }
                    : { minLimit: 0 }),
                ...(isDefined(maxLimit) && {
                    maxLimit,
                }),
            }}
        />
    );
}
