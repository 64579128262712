import { useEffect, useState } from "react";
import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import SearchSvg from "@convin/components/svg/SearchSvg";
import { Label } from "@convin/components/custom_components/Typography/Label";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import {
    PopoverFilterType,
    PosibleFilterKeys,
    useHandleFiltersPopoverStateReturnType,
} from "@convin/hooks/useHandleFiltersPopoverState";
import { isDefined } from "@convin/utils/helper/common.helper";
import SkeletonLoader from "../Loader/SkeletonLoader";

function FiltersPopover<T extends PosibleFilterKeys>({
    anchorEl,
    removeAnchorPosition,
    filters,
    activeFilterKey,
    activeFilters,
    hideSearch = false,
    resetPopoverFilters,
    setActiveFilters,
    filterLoaders,
}: useHandleFiltersPopoverStateReturnType<T> & { hideSearch?: boolean }) {
    const [filterSearch, setFilterSearch] = useState<string>("");
    const [data, setData] = useState(filters);
    const [localActiveFilters, setLocalActiveFilters] = useState(activeFilters);
    useEffect(() => {
        setLocalActiveFilters(activeFilters);
    }, [activeFilters]);

    useEffect(() => {
        if (!isDefined(filters)) return;
        if (filterSearch !== "") {
            const filteredData = filters?.filter(({ label }) => {
                return label.toLowerCase().includes(filterSearch.toLowerCase());
            });
            setData(filteredData);
        } else setData(filters);
    }, [filterSearch, filters]);

    const handleApplyFilters = () => {
        setActiveFilters(localActiveFilters);
        removeAnchorPosition();
    };

    const FilterItem: React.FC<{
        data: PopoverFilterType;
    }> = ({ data }) => {
        const { label, value } = data;

        const isSelected = activeFilterKey
            ? localActiveFilters[activeFilterKey]?.some(
                  (curr) => curr === value
              )
            : false;
        return (
            <Typography
                component="li"
                className="flex items-center list-none select-none gridLayoutCancelSelect"
                sx={{
                    color: (theme) => theme.palette.textColors[333],
                }}
                onClick={() => {
                    if (!activeFilterKey) return;
                    setLocalActiveFilters({
                        ...localActiveFilters,
                        [activeFilterKey]: !isSelected
                            ? [...localActiveFilters[activeFilterKey], value]
                            : localActiveFilters[activeFilterKey].filter(
                                  (e: typeof value) => e !== value
                              ),
                    });
                }}
            >
                <Checkbox size="small" sx={{ mr: 1 }} checked={isSelected} />
                <Label hasTooltip={true} isEllipses={true} colorType="333">
                    {label}
                </Label>
            </Typography>
        );
    };

    return (
        <CustomPopover anchorEl={anchorEl} onClose={removeAnchorPosition}>
            {hideSearch || (
                <TextField
                    size="small"
                    sx={{
                        width: "100%",
                        ".MuiInputBase-input": {
                            py: 1,
                            height: "30px",
                        },
                        ".MuiInputAdornment-root": {
                            mt: "0px !important",
                        },
                    }}
                    placeholder="Search in filters"
                    variant="filled"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchSvg sx={{ color: "grey.999" }} />
                            </InputAdornment>
                        ),
                    }}
                    value={filterSearch}
                    onChange={(e) => setFilterSearch(e.target.value)}
                />
            )}

            <Box
                sx={{
                    ...(!hideSearch && {
                        height: "300px",
                    }),
                }}
            >
                {activeFilterKey === "owners" && filterLoaders["owners"] ? (
                    <SkeletonLoader />
                ) : data?.length ? (
                    <VirtualList<PopoverFilterType>
                        Component={FilterItem}
                        data={data}
                        hasNext={false}
                        fetchNext={() => {
                            return;
                        }}
                        isLoading={false}
                        isFetching={false}
                        sx={{
                            p: 0,
                        }}
                    />
                ) : (
                    <Label
                        className="mx-auto font-medium text-center"
                        variant="body1"
                        colorType="999"
                        sx={(theme) => ({
                            fontSize: theme.spacing(1.5),
                            m: 2,
                        })}
                    >
                        Not Found
                    </Label>
                )}
            </Box>
            <Divider />
            <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
                <Button
                    onClick={() =>
                        resetPopoverFilters(
                            activeFilterKey as PosibleFilterKeys
                        )
                    }
                    className="gridLayoutCancelSelect"
                >
                    RESET
                </Button>
                <Button
                    variant="global"
                    sx={{ px: 1 }}
                    onClick={() => {
                        handleApplyFilters();
                        setFilterSearch("");
                    }}
                    className="gridLayoutCancelSelect"
                >
                    OK
                </Button>
            </Stack>
        </CustomPopover>
    );
}

export default FiltersPopover;
