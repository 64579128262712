import { pxToRem } from "@convin/utils/getFontValue";
import { Box, Typography, useTheme } from "@mui/material";

export default function PageCrash() {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    return (
        <Box className="w-full h-full flex flex-col justify-center items-center">
            <Box className="w-[556px] flex flex-col" gap={1}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="164"
                    height="97"
                    viewBox="0 0 164 97"
                    fill="none"
                >
                    <path
                        d="M124.404 84.7673H21.7894L0.20025 14.2036C-0.68836 11.3156 1.49277 8.38721 4.52213 8.38721H97.7252C99.7044 8.38721 101.461 9.67973 102.027 11.5781L124.404 84.7673Z"
                        fill="#E1ECFF"
                    />
                    <path
                        d="M107.601 76.3658H33.9872C31.9879 76.3658 30.2308 75.0531 29.6452 73.1547L13.2866 19.7169C12.8423 18.2629 13.9329 16.7886 15.4476 16.7886H89.0609C91.0602 16.7886 92.8173 18.1013 93.4029 19.9997L109.741 73.4375C110.206 74.8916 109.115 76.3658 107.601 76.3658Z"
                        fill="url(#paint0_linear_25105_1816)"
                    />
                    <path
                        d="M159.626 96.4609H25.3041C23.3653 96.4609 21.79 94.8856 21.79 92.9468V84.7676H159.626C161.564 84.7676 163.14 86.3428 163.14 88.2816V92.967C163.14 94.8856 161.564 96.4609 159.626 96.4609Z"
                        fill="#C5DFFF"
                    />
                    <path
                        d="M41.0322 49.1426L49.4134 39.792"
                        stroke="white"
                        strokeWidth="3.63522"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M51.3928 49.1426L38.7705 39.792"
                        stroke="white"
                        strokeWidth="3.63522"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M78.2754 49.1421L86.6566 39.7915"
                        stroke="white"
                        strokeWidth="3.63522"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M88.636 49.1421L76.0137 39.7915"
                        stroke="white"
                        strokeWidth="3.63522"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M50.1641 62.2771C50.1641 62.2771 65.5754 53.405 81.2712 62.2771"
                        stroke="white"
                        strokeWidth="3.63522"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M81.1242 25.9122H108.119C109.478 25.9122 110.234 24.3151 109.403 23.2503L91.7712 0.624611C91.0161 -0.364077 89.4681 -0.135918 88.9773 1.00488L79.6518 23.6306C79.1987 24.7334 79.9538 25.9122 81.1242 25.9122Z"
                        fill="#1C3177"
                    />
                    <path
                        d="M90.6609 10.825L92.8512 17.4732C92.9542 17.8082 93.2892 18.0401 93.65 18.0401H93.9849C94.423 18.0401 94.7322 17.7051 94.7065 17.267L94.1138 10.6704C94.088 10.2581 93.7273 9.92312 93.315 9.92312L91.3824 9.87158C90.8412 9.87158 90.5063 10.3354 90.6609 10.825Z"
                        fill="white"
                    />
                    <path
                        d="M95.5316 20.1529C95.5831 20.8229 95.0678 21.364 94.3978 21.364C93.7278 21.364 93.1352 20.8229 93.0836 20.1529C93.0321 19.4829 93.5475 18.9418 94.2174 18.9418C94.8874 18.916 95.4801 19.4572 95.5316 20.1529Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_25105_1816"
                            x1="131.294"
                            y1="3.24542"
                            x2="11.5535"
                            y2="69.514"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor={color} />
                            <stop offset="1" stopColor="#9BBCFF" />
                        </linearGradient>
                    </defs>
                </svg>

                <Typography
                    className="font-medium"
                    sx={{
                        fontSize: pxToRem(24),
                    }}
                >
                    Aw, Snap! App Crashed...
                </Typography>
                <Typography
                    color="textColors.666"
                    className=" leading-6 text-left"
                    px={0}
                >
                    Looks like the app crashed unexpectedly. No worries,
                    we&apos;re here to help! Try these steps to get back on
                    track:
                </Typography>
                <ol className="list-decimal ml-1">
                    {[
                        "Close the tab completely and reopen it.",
                        "If the issue persists, try restarting your browser.",
                        "Clear cache and try reloading the page.",
                        "If none of the above steps work, please contact our support team for further assistance.",
                    ].map((e) => {
                        return (
                            <Typography
                                component="li"
                                color="textColors.666"
                                variant="medium"
                                key={e}
                            >
                                {e}
                            </Typography>
                        );
                    })}
                </ol>
            </Box>
        </Box>
    );
}
