import { IFilterVisibility } from "@convin/contexts/FiltersVisibilityContextProvider";
import { AccountListFilters } from "@convin/type/Filters.model";

export const initialAccountListFilterState: AccountListFilters = {
    accountStage: null,
    accountTags: {
        tagIds: [],
        logic: "or",
        negate: false,
    },
    lastContactedDateKey: null,
    closeDateKey: null,
    dealSize: {
        min: null,
        max: null,
    },
    minMaxInteractions: [null, null],
    leadType: null,
    minLeadScore: null,
    accountSortBy: "-sales_task__created",
    accountQueryBy: null,
    accountReps: [],
    accountTeams: [],
};

export const accountDrawerFiltersVisibility: IFilterVisibility["drawerFiltersVisibility"] =
    [
        "stage",
        "accountTags",
        "minMaxInteractions",
        "dealSize",
        "leadType",
        "closeDateKey",
        "lastContactedDateKey",
        "auditType",
        "teams",
        "reps",
        "conversationScore",
        "template",
        "dispositionTemplate",
        "convinInsights",
    ];
