import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function RepSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 18 18"
            sx={{
                width: 18,
                height: 18,
                fill: "none",
                ...sx,
            }}
        >
            <mask
                id="mask0_14847_35886"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="18"
                height="18"
            >
                <rect width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_14847_35886)">
                <path
                    d="M4.3875 12.825C5.025 12.3375 5.7375 11.9531 6.525 11.6719C7.3125 11.3906 8.1375 11.25 9 11.25C9.8625 11.25 10.6875 11.3906 11.475 11.6719C12.2625 11.9531 12.975 12.3375 13.6125 12.825C14.05 12.3125 14.3906 11.7313 14.6344 11.0813C14.8781 10.4313 15 9.7375 15 9C15 7.3375 14.4156 5.92188 13.2469 4.75313C12.0781 3.58438 10.6625 3 9 3C7.3375 3 5.92188 3.58438 4.75313 4.75313C3.58438 5.92188 3 7.3375 3 9C3 9.7375 3.12187 10.4313 3.36562 11.0813C3.60938 11.7313 3.95 12.3125 4.3875 12.825ZM9 9.75C8.2625 9.75 7.64062 9.49688 7.13438 8.99063C6.62813 8.48438 6.375 7.8625 6.375 7.125C6.375 6.3875 6.62813 5.76562 7.13438 5.25938C7.64062 4.75313 8.2625 4.5 9 4.5C9.7375 4.5 10.3594 4.75313 10.8656 5.25938C11.3719 5.76562 11.625 6.3875 11.625 7.125C11.625 7.8625 11.3719 8.48438 10.8656 8.99063C10.3594 9.49688 9.7375 9.75 9 9.75ZM9 16.5C7.9625 16.5 6.9875 16.3031 6.075 15.9094C5.1625 15.5156 4.36875 14.9813 3.69375 14.3063C3.01875 13.6313 2.48438 12.8375 2.09063 11.925C1.69687 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.69687 6.9875 2.09063 6.075C2.48438 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48438 6.075 2.09063C6.9875 1.69687 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69687 11.925 2.09063C12.8375 2.48438 13.6313 3.01875 14.3063 3.69375C14.9813 4.36875 15.5156 5.1625 15.9094 6.075C16.3031 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.3031 11.0125 15.9094 11.925C15.5156 12.8375 14.9813 13.6313 14.3063 14.3063C13.6313 14.9813 12.8375 15.5156 11.925 15.9094C11.0125 16.3031 10.0375 16.5 9 16.5Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
