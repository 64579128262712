import {
    Radio,
    RadioGroup,
    FormControlLabel,
    RadioGroupProps,
    FormHelperText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import InfoSvg from "@convin/components/svg/InfoSvg";
import { pxToRem } from "@convin/utils/getFontValue";

// ----------------------------------------------------------------------

export default function GenericRadioGroup<
    T extends string | number | undefined | null
>({
    options,
    handleChange,
    value,
    name,
    error,
    helperText,
    ...other
}: {
    name?: string;
    options: {
        id: T;
        label: string;
        subLabelText?: string;
        info?: string;
        disabled?: boolean;
    }[];
    value: T;
    error?: string;
    helperText?: string;
    handleChange: (event: T) => void;
} & RadioGroupProps) {
    const onChange = (e: React.SyntheticEvent<Element, Event>) => {
        handleChange((e.target as HTMLInputElement).value as T);
    };
    return (
        <Stack gap={1.5}>
            <Stack>
                <RadioGroup value={value} row className="gap-6" {...other}>
                    {options.map(
                        (
                            { id, label, subLabelText, disabled, info },
                            index
                        ) => (
                            <Fragment key={id?.toString() ?? index}>
                                <FormControlLabel
                                    label={
                                        <>
                                            <Typography className="radio--label">
                                                {label}
                                            </Typography>
                                            {subLabelText && (
                                                <Typography
                                                    sx={{
                                                        fontSize: pxToRem(14),
                                                        color: (theme) =>
                                                            theme.palette
                                                                .textColors[999],
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {subLabelText}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                    value={id}
                                    onChange={onChange}
                                    control={<Radio />}
                                    disabled={!!disabled}
                                />
                                {info && (
                                    <Tooltip title={info} placement="top">
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "-10px",
                                            }}
                                        >
                                            <InfoSvg
                                                sx={{
                                                    cursor: "pointer",
                                                    color: (theme) =>
                                                        theme.palette
                                                            .textColors[999],
                                                }}
                                            />
                                        </Typography>
                                    </Tooltip>
                                )}
                            </Fragment>
                        )
                    )}
                </RadioGroup>
                {!!helperText && (
                    <FormHelperText error={!!error} sx={{ px: 2 }}>
                        {helperText}
                    </FormHelperText>
                )}
            </Stack>
        </Stack>
    );
}
