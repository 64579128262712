import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function GroupBlockNodeSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 26 26"
            sx={{ width: 26, height: 26, fill: "none", ...sx }}
        >
            <rect
                x="1"
                y="1"
                width="24"
                height="24"
                rx="6"
                fill="#F26EA6"
                stroke="#EA62B4"
            />

            <g mask="url(#mask0_1256_8168)">
                <path
                    d="M12.9736 18.393L7.61328 14.2238L8.59601 13.4793L12.9736 16.8742L17.3513 13.4793L18.334 14.2238L12.9736 18.393ZM12.9736 15.3852L7.61328 11.216L12.9736 7.04688L18.334 11.216L12.9736 15.3852Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
