import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { useCallback } from "react";

const CISaidByFilter = () => {
    const {
        state: { ciFilter },
        updateState: updateFiltersState,
    } = useGlobalFiltersStateContext();

    const handleSaidByFilterChange: ({
        saidBy,
        checked,
    }: {
        saidBy: string;
        checked: boolean;
    }) => void = useCallback(
        ({ saidBy, checked }) => {
            if (saidBy === "client") {
                if (ciFilter.saidBy.client === false && !checked) {
                    updateFiltersState({
                        ciFilter: {
                            ...ciFilter,
                            saidBy: {
                                client: true,
                                owner: checked,
                            },
                        },
                    });
                } else {
                    updateFiltersState({
                        ciFilter: {
                            ...ciFilter,
                            saidBy: {
                                ...ciFilter.saidBy,
                                owner: checked,
                            },
                        },
                    });
                }
            } else if (ciFilter.saidBy.owner === false && !checked) {
                updateFiltersState({
                    ciFilter: {
                        ...ciFilter,
                        saidBy: {
                            ...ciFilter.saidBy,
                            owner: true,
                            client: checked,
                        },
                    },
                });
            } else {
                updateFiltersState({
                    ciFilter: {
                        ...ciFilter,
                        saidBy: {
                            ...ciFilter.saidBy,
                            client: checked,
                        },
                    },
                });
            }
        },
        [ciFilter]
    );

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ciFilter?.saidBy?.client}
                        onChange={(e) =>
                            handleSaidByFilterChange({
                                saidBy: "owner",
                                checked: e.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={<Typography variant="textSm">Rep</Typography>}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ciFilter?.saidBy?.owner}
                        onChange={(e) =>
                            handleSaidByFilterChange({
                                saidBy: "client",
                                checked: e.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={<Typography variant="textSm">Client</Typography>}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ciFilter?.exclude}
                        onChange={(e) =>
                            updateFiltersState({
                                ciFilter: {
                                    ...ciFilter,
                                    exclude: e.target.checked,
                                },
                            })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={<Typography variant="textSm">Exclude</Typography>}
            />
        </FormGroup>
    );
};

export default CISaidByFilter;
