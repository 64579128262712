import { PieDataItem } from "@convin/components/chart/DonutChart";
import {
    OverallLineAnalytics,
    OverallPieAnalytics,
    StatisticsGraphData,
    StatesticsStateKeys,
    LineGraphMetric,
    PerformanceType,
    AuditorAnalyticsGraphData,
    AuditorAnalyticsKeys,
    SingleBarMetric,
    CompositionLineMetric,
    LeadLineGraph,
    AuditLineGraph,
    AnalyticsParameterDetailGraphResult,
    AuditorAnalyticsData,
    FormattedAuditorAnalyticsData,
    AuditDashboardFiltersPayload,
    LeadStageType,
    CsatCategoryType,
    CollectionCategoryType,
    LeadPieAnalyticsResult,
    CsatPieAnalyticsResult,
    CollectionPieAnalyticsResult,
    CsatLineGraph,
    CollectionLineGraph,
    OverallLeadPieAnalytics,
    OverallCsatPieAnalytics,
    OverallCollectionPieAnalytics,
    ScoreSenseKeys,
} from "@convin/type/Analytics";
import { ApexOptions } from "apexcharts";
import {
    capitalizeFirstLetter,
    formatFloatNumber,
    getColorFromString,
    isDefined,
} from "./common.helper";
import { auditColors } from "@convin/theme/palette";
import { FiltersPayload } from "@convin/type/CustomDashboard";
import { ReportType } from "@convin/type/AllReports";
import { MeetingTypeConst } from "@convin/type/Common";

export function formatOverallPieData({
    good,
    average,
    bad,
    total,
    meetingType,
    isAccountLevel,
}: OverallPieAnalytics & {
    meetingType: MeetingTypeConst;
    isAccountLevel: boolean;
}): PieDataItem[] {
    return [
        {
            label: `Good ${isAccountLevel ? "Account" : meetingType}s`,
            percentage: (good.count / total.count) * 100,
            color: auditColors.good,
            ...good,
        },
        {
            label: `Average ${isAccountLevel ? "Account" : meetingType}s`,
            ...average,
            color: auditColors.average,
            percentage: (average.count / total.count) * 100,
        },
        {
            label: `Need Attention ${
                isAccountLevel ? "Account" : meetingType
            }s`,
            ...bad,
            color: auditColors.bad,
            percentage: (bad.count / total.count) * 100,
        },
    ];
}

export function formatLeadOverallPieData({
    data,
    keys,
    colors,
}: {
    data:
        | OverallLeadPieAnalytics
        | OverallCsatPieAnalytics
        | OverallCollectionPieAnalytics;
    keys: [ScoreSenseKeys, ScoreSenseKeys, ScoreSenseKeys];
    colors: [string, string, string];
}): PieDataItem[] {
    return [
        {
            label: capitalizeFirstLetter(keys[0]),
            percentage:
                (data[keys[0] as keyof typeof data].count / data.total.count) *
                100,
            color: colors[0],
            ...data[keys[0] as keyof typeof data],
        },
        {
            label: capitalizeFirstLetter(keys[1]),
            percentage:
                (data[keys[1] as keyof typeof data].count / data.total.count) *
                100,
            color: colors[1],
            ...data[keys[1] as keyof typeof data],
        },
        {
            label: capitalizeFirstLetter(keys[2]),
            color: colors[2],
            percentage:
                (data[keys[2] as keyof typeof data].count / data.total.count) *
                100,
            ...data[keys[2] as keyof typeof data],
        },
    ];
}

export function formatOverallAnalyticsLineData<T>({
    data,
    keys,
    colors,
}: {
    data: OverallLineAnalytics<T>[];
    keys: [string, string, string];
    colors: [string, string, string];
}): ApexOptions["series"] {
    if (!isDefined(data) || Object.values(data).length === 0) return [];
    // Extracting data for chart
    const dataPoints = data?.map((dataPoint) => ({
        x: dataPoint.epoch,
        y: dataPoint[keys[0] as keyof T] as number,
        y2: dataPoint[keys[1] as keyof T] as number,
        y3: dataPoint[keys[2] as keyof T] as number,
        ...(isDefined(dataPoint.overall_average) && {
            y4: dataPoint.overall_average,
        }),
    }));

    const series: ApexAxisChartSeries = [
        "overall_average" in data?.[0] && {
            name: "Overall Average",
            color: auditColors.overall_average,
            data: dataPoints.map(({ x, y4 }, index) => {
                return {
                    x,
                    y: y4,
                    meta: {
                        name: "Overall Average",
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y4! - dataPoints[index - 1].y4!) /
                                          dataPoints[index - 1].y4!,
                                      2
                                  ),
                    },
                };
            }),
        },
        {
            name: capitalizeFirstLetter(keys[0]),
            color: colors[0],
            data: dataPoints.map(({ x, y }, index) => {
                return {
                    x,
                    y,
                    meta: {
                        name: capitalizeFirstLetter(keys[0]),
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y - dataPoints[index - 1].y) /
                                          dataPoints[index - 1].y,
                                      2
                                  ),
                    },
                };
            }),
        },
        {
            name: capitalizeFirstLetter(keys[1]),
            color: colors[1],
            data: dataPoints.map(({ x, y2 }, index) => {
                return {
                    x,
                    y: y2,
                    meta: {
                        name: capitalizeFirstLetter(keys[1]),
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y2 - dataPoints[index - 1].y2) /
                                          dataPoints[index - 1].y2,
                                      2
                                  ),
                    },
                };
            }),
        },
        {
            name:
                keys[2] === "bad"
                    ? "Need Attention"
                    : capitalizeFirstLetter(keys[2]),
            color: colors[2],
            data: dataPoints.map(({ x, y3 }, index) => {
                return {
                    x,
                    y: y3,
                    meta: {
                        name:
                            keys[2] === "bad"
                                ? "Need Attention"
                                : capitalizeFirstLetter(keys[2]),
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y3 - dataPoints[index - 1].y3) /
                                          dataPoints[index - 1].y3,
                                      2
                                  ),
                    },
                };
            }),
        },
    ].filter(Boolean);
    return series;
}

export function formatOverallStatesticsLineData({
    data,
    key,
    color,
    tooltipText,
}: {
    data: StatisticsGraphData;
    key: keyof typeof StatesticsStateKeys;
    color: string;
    tooltipText: string;
}): ApexOptions["series"] {
    // Extracting data for chart
    const dataPoints = data.map((dataPoint) => ({
        x: dataPoint.epoch,
        y: dataPoint[key],
    }));

    const series: ApexAxisChartSeries = [
        {
            name: tooltipText,
            color,
            type: "area",
            data: dataPoints.map((e) => {
                return {
                    ...e,
                    meta: {
                        name: "Average Call Duration (in Min)",
                    },
                };
            }),
        },
    ];
    return series;
}

export function formatSingleParameterPerformanceLineData({
    parameterDetail,
    color,
}: {
    parameterDetail: AnalyticsParameterDetailGraphResult;
    color: string;
}): ApexOptions["series"] {
    if (!isDefined(parameterDetail)) return [];
    // Extracting data for chart
    const dataPoints = parameterDetail.data.map((dataPoint) => ({
        x: dataPoint.epoch,
        y: dataPoint.count,
    }));

    const series: ApexAxisChartSeries = [
        {
            name: "Occurrence",
            color,
            type: "area",
            data: dataPoints.map((e, index) => {
                return {
                    ...e,
                    meta: {
                        name: "Occurrence",
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (e.y - dataPoints[index - 1].y) /
                                          dataPoints[index - 1].y,
                                      2
                                  ),
                    },
                };
            }),
        },
    ];
    return series;
}

export function formatMultiLineData(
    data: LineGraphMetric[]
): ApexOptions["series"] {
    // Extracting data for chart
    const series: ApexAxisChartSeries = [];
    for (let i = 0; i < data.length; i++) {
        series.push({
            ...data[i],
            color: getColorFromString(data[i].name),
            data:
                data[i]?.data?.map?.(({ epoch, average }, idx) => ({
                    x: epoch,
                    y: average,
                    name: data[i].name,
                    meta: {
                        name: data[i].name,
                        trend:
                            idx === 0
                                ? null
                                : formatFloatNumber(
                                      (average -
                                          data[i]?.data[idx - 1]?.average) /
                                          data[i]?.data[idx - 1]?.average,
                                      2
                                  ),
                    },
                })) || [],
        });
    }
    return series;
}

export function formatCompositionLineData<
    T = AuditLineGraph | LeadLineGraph | CsatLineGraph | CollectionLineGraph
>(
    data: CompositionLineMetric<T>["data"] = [],
    config: {
        keys: [string, string, string];
        labels: [string, string, string];
        colors: [string, string, string];
    }
): ApexOptions["series"] {
    // Extracting data for chart
    const { keys, labels, colors } = config;
    const dataPoints: Array<Record<"x" | "y" | "y2" | "y3", number>> = data.map(
        (dataPoint) => ({
            x: dataPoint.epoch,
            y:
                keys[0] in dataPoint
                    ? (dataPoint[keys[0] as keyof typeof dataPoint] as number)
                    : 0,
            y2:
                keys[1] in dataPoint
                    ? (dataPoint[keys[1] as keyof typeof dataPoint] as number)
                    : 0,
            y3:
                keys[2] in dataPoint
                    ? (dataPoint[keys[2] as keyof typeof dataPoint] as number)
                    : 0,
        })
    );

    const series: ApexAxisChartSeries = [
        {
            name: labels[0],
            color: colors[0],
            data: dataPoints.map(({ x, y }, index) => {
                return {
                    x,
                    y,
                    meta: {
                        name: labels[1],
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y - dataPoints[index - 1].y) /
                                          dataPoints[index - 1].y,
                                      2
                                  ),
                    },
                };
            }),
        },
        {
            name: labels[1],
            color: colors[1],
            data: dataPoints.map(({ x, y2 }, index) => {
                return {
                    x,
                    y: y2,
                    meta: {
                        name: labels[1],
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y2 - dataPoints[index - 1].y2) /
                                          dataPoints[index - 1].y2,
                                      2
                                  ),
                    },
                };
            }),
        },
        {
            name: labels[2],
            color: colors[2],
            data: dataPoints.map(({ x, y3 }, index) => {
                return {
                    x,
                    y: y3,
                    meta: {
                        name: labels[2],
                        trend:
                            index === 0
                                ? null
                                : formatFloatNumber(
                                      (y3 - dataPoints[index - 1].y3) /
                                          dataPoints[index - 1].y3,
                                      2
                                  ),
                    },
                };
            }),
        },
    ];
    return series;
}

export function getDataBasedOnPerformanceType<T>({
    data,
    type,
    RATIO = 0.2,
    shouldSlice = true,
}: {
    data: T[];
    type: PerformanceType;
    shouldSlice?: boolean;
    RATIO?: number;
}): T[] {
    let returnValue: T[] = [];
    switch (type) {
        case "Top Performing":
            returnValue = data?.slice(0, Math.ceil(data.length * RATIO));
            return shouldSlice ? returnValue.slice(0, 5) : returnValue;
        case "Need Attention":
            returnValue =
                data.length === 1
                    ? []
                    : data?.slice(data.length - Math.ceil(data.length * RATIO));
            return shouldSlice
                ? returnValue.slice(-5).reverse()
                : returnValue.reverse();
        case "Average Performing":
            returnValue =
                data.length === 1
                    ? []
                    : data?.slice(
                          Math.ceil(data.length * 0.2),
                          data.length - 1 - Math.floor(data.length * 0.2)
                      );
            return shouldSlice ? returnValue.slice(0, 5) : returnValue;
        default:
            return data;
    }
}

export function getDetailedAanalysisBasedOnPerformance(
    data: SingleBarMetric[]
): SingleBarMetric[] {
    const { good, average, bad } = auditColors;
    const top = getDataBasedOnPerformanceType({
        data,
        type: "Top Performing",
        shouldSlice: false,
    })?.map((e) => ({ ...e, color: good }));
    const middle = getDataBasedOnPerformanceType({
        data,
        type: "Average Performing",
        shouldSlice: false,
    })?.map((e) => ({ ...e, color: average }));
    const bottom = getDataBasedOnPerformanceType({
        data,
        type: "Need Attention",
        shouldSlice: false,
    })?.map((e) => ({ ...e, color: bad }));
    return [...top, ...middle, ...bottom];
}

export function getAuditDashboardPayload(
    payload: Partial<FiltersPayload>
): AuditDashboardFiltersPayload {
    return {
        meeting_type: payload.meeting_type!,
        meeting_start_time: payload.start_date
            ? payload.start_date * 1000
            : null,
        meeting_end_time: payload.end_date ? payload.end_date * 1000 : null,
        start_time: payload.audit_start_date
            ? payload.audit_start_date * 1000
            : null,
        end_time: payload.audit_end_date ? payload.audit_end_date * 1000 : null,
        tag_id: isDefined(payload.tags_id) ? payload.tags_id : undefined,
        auditors_id: isDefined(payload.auditors_id)
            ? payload.auditors_id
            : undefined,
        teams_id: isDefined(payload.teams_id) ? payload.teams_id : undefined,
        reps_id: isDefined(payload.reps_id) ? payload.reps_id : undefined,
        is_call_level: isDefined(payload.is_call_level)
            ? payload.is_call_level
            : undefined,
        account_tags_id: isDefined(payload.account_tags_id)
            ? payload.account_tags_id
            : undefined,
    };
}

export function formatAuditorAnalyticsData(
    data: AuditorAnalyticsData
): Partial<FormattedAuditorAnalyticsData> {
    const object = {} as Partial<FormattedAuditorAnalyticsData>;
    data.forEach(({ name, ...rest }) => {
        object[name] = { ...rest };
    });
    return object;
}

export function formatAuditorAnalyticsGraph({
    data,
    key,
    color,
}: {
    data: AuditorAnalyticsGraphData | undefined;
    key: AuditorAnalyticsKeys;
    color: string;
}): ApexOptions["series"] {
    // Extracting data for chart

    const dataPoints =
        data?.[key]?.map((dataPoint) => ({
            x: dataPoint.epoch,
            y: dataPoint.count,
        })) || [];

    const label =
        key === "auditor"
            ? "No. of Auditors"
            : `${capitalizeFirstLetter(key)}s Audited`;

    const series: ApexAxisChartSeries = [
        {
            name: label,
            color: color,
            type: "area",
            data: dataPoints.map((e) => {
                return {
                    ...e,
                    meta: {
                        name: label,
                    },
                };
            }),
        },
    ];
    return series[0].data.length ? series : [];
}

export const getGraphSuffix = (
    suffix: string | null | undefined,
    def: string | undefined = "%"
) => (suffix === undefined ? def : suffix ? suffix : "");

export function getAreaSingleLineData(
    data?: LineGraphMetric,
    color?: string
): ApexOptions["series"] {
    // Extracting data for chart
    const dataPoints =
        data?.data.map(({ epoch, average }) => ({
            x: epoch,
            y: average,
        })) || [];

    const series: ApexAxisChartSeries = [
        {
            name: "",
            color,
            type: "area",
            data: dataPoints.map((e) => {
                return {
                    ...e,
                    meta: {
                        name: data?.name,
                        id: data?.id,
                    },
                };
            }),
        },
    ];
    return series;
}

export function formatOverallDistributionPieData({
    keys,
    data,
    colors,
}: {
    keys: Array<LeadStageType | CsatCategoryType | CollectionCategoryType>;
    data:
        | LeadPieAnalyticsResult
        | CsatPieAnalyticsResult
        | CollectionPieAnalyticsResult;
    colors: [string, string, string];
}): PieDataItem[] {
    return keys.map((e, idx) => ({
        label: capitalizeFirstLetter(e),
        color: colors[idx],
        percentage:
            (data[e as keyof typeof data].count / data.total.count) * 100,
        ...data[e as keyof typeof data],
    }));
}

export const formatAuditDashboardGraphData = ({
    data,
    color,
}: {
    data: Array<{ epoch: number; count: number }>;
    color: string;
}) => {
    const series: ApexAxisChartSeries = [
        {
            name: "",
            color,
            type: "area",
            data: data.map((e) => [e.epoch * 1000, e.count]),
        },
    ];
    return series;
};

export const formatCoachingProgressGraphData = ({
    data,
    color,
}: {
    data: Array<{ epoch: number; percentage: number; trends: number }>;
    color: string;
}) => {
    const series: ApexAxisChartSeries = [
        {
            name: "",
            color,
            type: "area",
            data: data.map((e) => [e.epoch, e.percentage, e.trends]),
        },
    ];
    return series;
};

export const getParetoGraphJson = ({
    data,
    columns,
}: Pick<ReportType, "data" | "columns">): Array<{
    [key: string]: string | number;
}> => {
    const json: Array<{ [key: string]: string | number }> = [];
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            if (!json[i]) json[i] = { id: i };
            json[i][columns[j]] = data[i][j];
        }
    }
    return json;
};
