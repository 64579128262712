import {
    Box,
    Divider,
    Paper,
    Tab,
    Theme,
    Typography,
    alpha,
} from "@mui/material";

import { useGetDownloadReportsList } from "../hooks/useGetDownloadReportsList";
import { useLocation } from "react-router-dom";
import { internalRoutes } from "@convin/config/routes.config";
import useWebSocketContextProvider from "@convin/components/websocket/hooks/useWebSocketContextProvider";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GeneralNotificationPanel from "./GeneralNotificationPanel";
import DownloadNotificationPanel from "./DownloadNotificationPanel";
import InternalNotificationPanel from "./InternalNotificationPanel";
import { useGetBulkUploadList } from "../hooks/useGetBulkUploadList";

const tabCountSx = (theme: Theme, active: boolean) => {
    const color = active
        ? theme.palette.primary.main
        : theme.palette.textColors[999];
    return {
        px: 0.5,
        background: alpha(color, 0.1),
        color,
    };
};

const NotificationsInterface = () => {
    const { activeTab, setActiveTab } = useWebSocketContextProvider();
    const { data: downloads } = useGetDownloadReportsList({});

    const { data: bulkUploads } = useGetBulkUploadList();

    const location = useLocation();
    const isInternalRoute = internalRoutes.some((route) =>
        location.pathname.includes(route)
    );

    return (
        <Box
            className="h-full w-full flex flex-col"
            component={Paper}
            sx={{
                "& .MuiTabPanel-root": {
                    overflow: "scroll",
                    p: 0,
                    pt: 2,
                    flex: 1,
                },
            }}
        >
            <Box className="flex items-center justify-between p-5">
                <Typography
                    sx={{
                        color: (theme) => theme.palette.textColors["333"],
                        fontWeight: 600,
                    }}
                    variant="large"
                >
                    Notifications
                </Typography>
            </Box>
            <Divider />
            <TabContext value={activeTab}>
                <TabList
                    onChange={(_, tab) => {
                        setActiveTab(tab);
                    }}
                    sx={{
                        ".MuiTabs-indicator": {
                            height: "2px",
                            width: "50%",
                        },
                    }}
                >
                    <Tab
                        label={
                            <Box className="flex gap-2">
                                General{" "}
                                <Box
                                    className="rounded-[4px] font-medium"
                                    sx={(theme) =>
                                        tabCountSx(
                                            theme,
                                            activeTab === "general"
                                        )
                                    }
                                >
                                    {bulkUploads?.count ?? 0}
                                </Box>
                            </Box>
                        }
                        value="general"
                        className="px-8"
                    />
                    <Tab
                        label={
                            <Box className="flex gap-2">
                                Downloads
                                <Box
                                    className="rounded-[4px] font-medium"
                                    sx={(theme) =>
                                        tabCountSx(
                                            theme,
                                            activeTab === "downloads"
                                        )
                                    }
                                >
                                    {downloads?.count ?? 0}
                                </Box>
                            </Box>
                        }
                        value="downloads"
                        sx={{
                            px: 4,
                        }}
                    />
                    {isInternalRoute && (
                        <Tab
                            label="Internal"
                            value="internal"
                            className="px-8"
                        />
                    )}
                </TabList>
                <TabPanel value="general">
                    <GeneralNotificationPanel />
                </TabPanel>
                <TabPanel value="downloads">
                    <DownloadNotificationPanel />
                </TabPanel>
                <TabPanel value="internal">
                    <InternalNotificationPanel />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default NotificationsInterface;
