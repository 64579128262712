import { OfflineNotificationStatus } from "@convin/modules/notifications/components/OfflineNotificationStatus";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useOnlineStatus = () => {
    useEffect(() => {
        let offlineToastId: string | number | null = null;

        const handleOnline = () => {
            if (offlineToastId) {
                toast.dismiss(offlineToastId);
            }

            toast.success("Back Online!", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                type: "success",
                theme: "dark",
                closeButton: false,
                style: {
                    width: "400px",
                    maxWidth: "90vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            });
        };

        const handleOffline = () => {
            offlineToastId = toast(OfflineNotificationStatus, {
                hideProgressBar: true,
                ariaLabel: "Internet connection lost!",
                autoClose: false,
                position: "bottom-center",
                theme: "dark",
                closeButton: false,
                style: { width: "400px", maxWidth: "90vw" },
            });
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);
};

export default useOnlineStatus;
