import { Box, Stack, Tooltip, alpha } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import {
    DashboardRoutesConfig,
    SettingRoutes,
} from "@convin/config/routes.config";
import useAuth from "@convin/hooks/useAuth";
import {
    isDefined,
    removeConvinFromString,
} from "@convin/utils/helper/common.helper";
import React, { ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import LogoSvg from "@convin/components/svg/LogoSvg";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";

const {
    CI,
    LMS,
    Account,
    Home,
    Settings,
    Conversations,
    SupervisorAssist,
    Statistics,
    AuditReport,
    SalesAgent,
    SalesAssist,
    AIAgent,
    Coaching,
} = DashboardRoutesConfig;

const sideBarRoutes = [
    Home,
    Conversations,
    Statistics,
    SupervisorAssist,
    CI,
    Account,
    LMS,
    Coaching,
    AuditReport,
    SalesAgent,
    AIAgent,
    Settings,
    SalesAssist,
];

export const StyledNavLink: React.FC<
    NavLinkProps & { to: string; name: string }
> = ({ children, to, name, ...rest }) => {
    const { data: domainConfig } = useGetDomainConfigQuery();
    const location = useLocation();
    const isActive =
        matchPath(to, location.pathname) !== null ||
        location.pathname.startsWith(to);
    return (
        <Tooltip
            title={domainConfig?.logo ? removeConvinFromString(name) : name}
            placement="right"
        >
            <Box
                {...rest}
                to={to}
                sx={({
                    palette: {
                        primary: { main },
                    },
                }) => ({
                    borderRadius: "8px",
                    p: 0.85,
                    height: "32px",
                    ...(isActive
                        ? {
                              bgcolor: alpha(main, 0.2),
                              color: main,
                          }
                        : {
                              color: "textColors.666",
                          }),
                })}
                component={NavLink}
                className={"flex items-center justify-center -my-[3px]"}
            >
                {children as ReactNode}
            </Box>
        </Tooltip>
    );
};

export default function Navigation({ hideLogo }: { hideLogo?: boolean }) {
    const { checkDashboardVisibility } = useAuth();
    const { data: domainConfig } = useGetDomainConfigQuery();
    const filteredRoutes = sideBarRoutes.filter((route) => {
        return route.name.toLowerCase() === "settings"
            ? Object.values(SettingRoutes).filter((route) =>
                  checkDashboardVisibility(route)
              ).length > 0
            : checkDashboardVisibility(route);
    });

    return (
        <Box
            sx={{
                width: "54px",
                borderRight: "2px solid",
                borderColor: "divider",
            }}
            component="nav"
        >
            {!hideLogo &&
                (domainConfig?.logo ? (
                    <img
                        src={domainConfig?.logo}
                        alt="logo"
                        className="w-[50px] h-[50px] object-contain"
                    />
                ) : (
                    <LogoSvg />
                ))}
            <Stack
                className="items-center flex-shrink-0"
                sx={{
                    pt: (theme) =>
                        hideLogo ? theme.spacing(4) : theme.spacing(2),
                }}
                gap={3}
            >
                {filteredRoutes.map(({ Icon, ...component }) => (
                    <StyledNavLink
                        to={component.to}
                        key={component.name}
                        name={component.name}
                    >
                        {isDefined(Icon) ? <Icon /> : null}
                    </StyledNavLink>
                ))}
            </Stack>
        </Box>
    );
}
