import { createApi } from "@reduxjs/toolkit/query/react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    LiveAssistTemplate,
    LiveConversationViolations,
} from "@convin/type/LiveConversation";
import axiosBaseQuery from "../axiosBaseQuery";
import { Template } from "@convin/type/Audit";
import { LiveCallStart } from "@convin/type/WebSocketNotification";

export enum CallEvent {
    CallStart = "start",
    IsCallActive = "active_calls",
    Transcript = "transcript",
    LiveAudit = "call_flow_template",
    CallEnd = "end",
}

export const liveConversationApiSlice = createApi({
    reducerPath: "templateApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getLiveAssistTemplates: builder.query<Template[], void>({
            query: () => "live_assist/templates/",
        }),
        getActiveTemplateById: builder.query<LiveAssistTemplate, number | null>(
            {
                query: (id) => `live_assist/template/${id}/`,
                transformResponse(res: LiveAssistTemplate) {
                    if (isDefined(res.categories))
                        for (const category of res.categories) {
                            // Loop through the questions in each category
                            for (const question of category.questions) {
                                // Add the "is_covered_in_call" field and set it to false
                                question.is_covered_in_call = false;
                            }
                        }
                    return res;
                },
            }
        ),
        getActiveCallsByAgentId: builder.mutation<
            LiveCallStart["body"][],
            { agent_id: number }
        >({
            query: (payload) => ({
                url: "/live_assist/active_calls/",
                method: "POST",
                body: payload,
            }),
        }),
        getLiveViolations: builder.query<
            LiveConversationViolations | null,
            void
        >({
            queryFn: () => {
                return {
                    data: null,
                };
            },
        }),
    }),
});

export const {
    useGetLiveAssistTemplatesQuery,
    useGetActiveTemplateByIdQuery,
    useGetActiveCallsByAgentIdMutation,
    useGetLiveViolationsQuery,
} = liveConversationApiSlice;
