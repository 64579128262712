import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    Assessment,
    AssessmentsPayload,
    AssessmentsResponse,
    UserResponses,
} from "@convin/type/lms";
import {
    GetPayloadType,
    GoogleFormAssessment,
    UpdateFormResponseObject,
} from "@convin/modules/lms/assesments/context/types";
import { isDefined } from "@convin/utils/helper/common.helper";
import { AxiosResponse } from "axios";

const config = {
    createForm: "coachings/forms/create/",
    updateForm: "coachings/forms/update/",
    assessementList: "coachings/assessments/",
};

export const lmsAssessmentApiSlice = createApi({
    reducerPath: "lmsAssessmentApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getAssessments: builder.query<AssessmentsResponse, AssessmentsPayload>({
            query: ({ page, ...payload }) => ({
                url: `${config.assessementList}`,
                method: "POST",
                keepUnusedDataFor: 30,
                body: { ...payload },
                params: { page },
            }),
        }),
        cloneAssessment: builder.mutation<
            Assessment,
            { id: number; title: string }
        >({
            query: ({ id, title }) => ({
                url: `${config.assessementList}clone/${id}/`,
                method: "POST",
                body: {
                    title: title,
                },
            }),
        }),
        deleteAssessment: builder.mutation<Assessment, number>({
            query: (payload) => ({
                url: `${config.assessementList}${payload}/`,
                method: "DELETE",
            }),
        }),
        getAssessmentGraphData: builder.query<UserResponses, number>({
            query: (id) => ({
                url: `/coachings/assessment/${id}/graph/`,
                method: "GET",
            }),
        }),
        createAssessment: builder.mutation<GoogleFormAssessment, void>({
            query: () => ({
                url: config.createForm,
                method: "POST",
            }),
        }),
        getAssessmentById: builder.query<GoogleFormAssessment, number>({
            query: (id) => ({
                url: `/${config.assessementList}${id}/`,
                method: "GET",
            }),
        }),
        getRepAssessmentById: builder.query<GoogleFormAssessment, number>({
            query: (id) => ({
                url: `/coachings/assessments/rep/${id}/`,
                method: "GET",
            }),
        }),
        batchUpdateQuestion: builder.mutation<
            UpdateFormResponseObject,
            GetPayloadType
        >({
            query: (payload) => ({
                url: `/coachings/forms/${payload.assessment_id}/update/`,
                method: "POST",
                body: { ...payload.data },
            }),
            async onQueryStarted(
                { assessment_id },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const {
                        data: { form },
                    } = await queryFulfilled;
                    if (isDefined(assessment_id)) {
                        dispatch(
                            lmsAssessmentApiSlice.util.updateQueryData(
                                "getAssessmentById",
                                assessment_id,
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        title: form.info.title,
                                        form_data: form,
                                    });
                                }
                            )
                        );
                    }
                } catch {}
            },
        }),
        downloadAssessmentResponses: builder.mutation<Blob, number>({
            query: (id) => ({
                url: `/coachings/assessment/download/${id}/`,
                method: "GET",
                responseType: "blob",
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data, meta } = await queryFulfilled;
                    const { headers } = meta as Omit<AxiosResponse, "data">;
                    const href = URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute(
                        "download",
                        headers?.["content-disposition"]
                            ?.split("filename=")[1]
                            .split('"')[1]
                    );
                    link.click();
                    URL.revokeObjectURL(href);
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetAssessmentsQuery,
    useLazyGetAssessmentsQuery,
    useCreateAssessmentMutation,
    useGetAssessmentByIdQuery,
    useLazyGetAssessmentByIdQuery,
    useBatchUpdateQuestionMutation,
    useGetAssessmentGraphDataQuery,
    useDeleteAssessmentMutation,
    useDownloadAssessmentResponsesMutation,
    useGetRepAssessmentByIdQuery,
    useLazyGetRepAssessmentByIdQuery,
    useCloneAssessmentMutation,
} = lmsAssessmentApiSlice;
