import React, { ReactElement } from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { Box, SxProps, Theme } from "@mui/material";

type Props = {
    children?: React.ReactNode;
    anchorEl: HTMLDivElement | HTMLButtonElement | SVGCircleElement | null;
    onClose: () => void;
    sx?: SxProps<Theme>;
};

type ChildrenProps = {
    onClose: () => void;
};

export default function CustomPopover({
    children,
    anchorEl,
    onClose,
    sx,
    ...rest
}: Omit<PopoverProps, "open"> & Props): ReactElement {
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement<ChildrenProps>(child)) {
            return React.cloneElement(child, { onClose });
        }
        return child;
    });

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            sx={{
                fontSize: "14px",
                "& .MuiPopover-paper": {
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1) !important",
                    maxWidth: "200px",
                    width: "200px",
                },
                ...sx,
            }}
            {...rest}
        >
            <Box
                className="min-w-[100px] cursor-pointer h-full"
                sx={{
                    borderRadius: 1,
                    overflow: "hidden",
                }}
            >
                {childrenWithProps}
            </Box>
        </Popover>
    );
}
