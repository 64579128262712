import {
    CheckCircle,
    Close,
    FactCheck,
    ImportContacts,
    InsertComment,
    Payment,
} from "@mui/icons-material";
import {
    Box,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import { ReactNode, useMemo } from "react";

import { GeneralNotificationType } from "@convin/type/Notification.model";

import { Label } from "@convin/components/custom_components/Typography/Label";
import { formatDistance } from "date-fns";
import { DownloadReport } from "@convin/type/Report";

interface Props {
    data: DownloadReport;
    prevIndexedData: DownloadReport | null;
}

const IconMap: Record<GeneralNotificationType, { Icon: typeof SvgIcon }> = {
    actionable: { Icon: Payment },
    audit: { Icon: FactCheck },
    coaching: { Icon: ImportContacts },
    comment: { Icon: InsertComment },
    completed: {
        Icon: CheckCircle,
    },
};

const InternalNotificationCard: React.FC<Props> = ({
    data: { task_status, report_name, message, created_at },
}) => {
    const type =
        task_status === "completed"
            ? "completed"
            : report_name.toLowerCase().includes("clips") ||
              report_name.toLowerCase().includes("session")
            ? "coaching"
            : "audit";

    const createdAt = created_at
        ? new Date(created_at).toString()
        : new Date().toString();

    const theme = useTheme();

    const Icon = IconMap[type].Icon;
    const color = theme.palette.primary.main;
    const durationDifference = useMemo(() => {
        return formatDistance(
            new Date().getTime(),
            new Date(createdAt).getTime()
        );
    }, [createdAt]);
    return (
        <Stack gap={1}>
            {report_name === "actionable" ? (
                <NotificationTag
                    color={theme.palette.notificationColors.failed}
                    content="Actionable"
                />
            ) : null}
            <Stack
                gap={1.5}
                direction="row"
                alignItems="start"
                className="w-full"
            >
                <Box
                    className="p-2 rounded-full"
                    sx={{
                        color,
                        bgcolor: alpha(color, 0.1),
                    }}
                >
                    <Icon />
                </Box>
                <Stack gap={0.5} pt={1} className="flex-grow">
                    {typeof report_name === "string" ? (
                        <Label colorType="333" className="font-medium">
                            {report_name}
                        </Label>
                    ) : (
                        report_name
                    )}
                    {typeof message === "string" ? (
                        <Label colorType="666" variant="medium">
                            {message}
                        </Label>
                    ) : (
                        message
                    )}
                    <Label colorType="999" variant="small">
                        {durationDifference} ago
                    </Label>
                </Stack>
            </Stack>
        </Stack>
    );
};

interface TagProps {
    color: string;
    content: string | ReactNode;
}

const NotificationTag = ({ color, content }: TagProps) => {
    return (
        <Typography
            sx={{
                bgcolor: alpha(color, 0.1),
                color,
            }}
            variant="medium"
            className="font-medium w-fit px-2 py-0.5 rounded-[100px]"
        >
            {content}
        </Typography>
    );
};

export default InternalNotificationCard;
