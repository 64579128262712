import { Box, Drawer, Typography, alpha } from "@mui/material";
import { BlockType } from "../constants";
import {
    BuildingBlockType,
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../context/NodeStateContext";
import { useState } from "react";
import CloneBlockDrawerUi from "./CloneBlockDrawerUi";

export default function BlockCard<
    T extends
        | SingleResponseBlockType
        | BuildingBlockType
        | MultiResponseNodeType
>({
    displayName,
    description,
    svgIcon,
    isActive,
    name,
    onClick,
}: BlockType<T> & { isActive: boolean; onClick: (e: T) => void }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box
                sx={(theme) => ({
                    bgcolor: isActive
                        ? alpha(theme.palette.primary.main, 0.2)
                        : "",
                    p: 1,
                    borderRadius: "6px",
                    border: isActive
                        ? `1px solid ${theme.palette.primary.main}`
                        : `1px solid ${alpha(
                              theme.palette.textColors[999],
                              0.2
                          )}`,
                })}
                className="flex items-center cursor-pointer"
                gap={1}
                onClick={() => {
                    if (name === "existingBlock") {
                        setOpen((prev) => !prev);
                    } else {
                        onClick(name);
                    }
                }}
            >
                <Box color="primary.main" component="span">
                    {svgIcon}
                </Box>
                <Box className="flex-1 flex flex-col">
                    <Typography variant="small" className="font-semibold">
                        {displayName}
                    </Typography>
                    <Typography variant="small" color="textColors.999">
                        {description}
                    </Typography>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                open={open}
                sx={(theme) => ({
                    "&.MuiDrawer-root": {
                        zIndex: `${theme.zIndex.modal + 1}`,
                    },
                    width: 536,
                })}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <CloneBlockDrawerUi
                    onClose={() => {
                        setOpen((prev) => !prev);
                    }}
                />
            </Drawer>
        </>
    );
}
