import React, { useCallback, useEffect, useRef } from "react";
import ThreeDotsWave from "../ThreeDotsWave";
import { Stack, SxProps, Theme } from "@mui/material";
import { isDefined } from "@convin/utils/helper/common.helper";

export default function InfiniteList<
    T extends { id?: string | number },
    U extends Record<string, unknown> = Record<string, unknown>
>({
    data = [],
    Component,
    hasNext,
    fetchNext,
    isFetching,
    isLoading,
    sx,
    ...rest
}: {
    data?: Array<T>;
    Component: React.FC<
        {
            prevIndexedData: T | null;
            data: T;
            index: number;
        } & { [key in keyof U]: U[key] }
    >;
    rowSize?: number;
    hasNext?: boolean;
    fetchNext?: () => unknown;
    isFetching?: boolean;
    sx?: SxProps<Theme>;
    isLoading?: boolean;
} & U) {
    const loaderRef = useRef<HTMLDivElement | null>(null);
    const handleObserver = useCallback(
        (entities: IntersectionObserverEntry[]) => {
            const target = entities?.[0];
            if (target?.isIntersecting) {
                if (
                    isDefined(fetchNext) &&
                    typeof fetchNext === "function" &&
                    !isFetching
                )
                    fetchNext();
            }
        },
        [hasNext]
    );

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (isDefined(loaderRef.current)) {
            observer.observe(loaderRef.current);
        }
    }, []);

    return (
        <Stack
            sx={{
                p: 3,
                ...sx,
            }}
            role="listbox"
            data-testid="infinite-list-container"
            className="h-full w-full overflow-y-auto overflow-x-hidden flex-1"
        >
            {data.map((e, index) => {
                return (
                    <Component
                        key={"id" in e ? e.id : index}
                        {...{
                            prevIndexedData: index ? data[index - 1] : null,
                            data: e,
                            index: index,
                            isLoading,
                            ...(rest as unknown as U),
                        }}
                    />
                );
            })}

            {hasNext && (
                <div
                    ref={loaderRef}
                    className="flex items-center justify-center"
                >
                    <ThreeDotsWave />
                </div>
            )}
        </Stack>
    );
}
