import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function LeadSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.77324 3.77324C3 4.54648 3 5.79099 3 8.28001V12.3867C3 14.8757 3 16.1202 3.77324 16.8935C4.54648 17.6667 5.79099 17.6667 8.28001 17.6667H12.3867C14.8757 17.6667 16.1202 17.6667 16.8935 16.8935C17.6667 16.1202 17.6667 14.8757 17.6667 12.3867V8.28001C17.6667 5.79099 17.6667 4.54648 16.8935 3.77324C16.1202 3 14.8757 3 12.3867 3H8.28001C5.79099 3 4.54648 3 3.77324 3.77324ZM14.7322 8.62117C15.0018 8.21679 14.8926 7.67042 14.4882 7.40083C14.0838 7.13124 13.5374 7.24051 13.2678 7.6449L11.1756 10.7832L11.0653 10.5625C10.4609 9.35376 8.77627 9.24888 8.02666 10.3733L5.93447 13.5116C5.66488 13.916 5.77415 14.4623 6.17854 14.7319C6.58293 15.0015 7.12929 14.8922 7.39888 14.4879L9.49108 11.3496L9.60143 11.5703C10.2058 12.779 11.8904 12.8839 12.64 11.7595L14.7322 8.62117Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
