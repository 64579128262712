import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function FilledShareIcon({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 16 18"
            sx={{
                width: 16,
                height: 18,
                fill: "none",
                ...sx,
            }}
        >
            <path
                d="M13 17.3333C12.3056 17.3333 11.7153 17.0902 11.2292 16.6041C10.7431 16.118 10.5 15.5277 10.5 14.8333C10.5 14.7361 10.5069 14.6354 10.5208 14.5312C10.5347 14.427 10.5556 14.3333 10.5833 14.25L4.70833 10.8333C4.47222 11.0416 4.20833 11.2048 3.91667 11.3229C3.625 11.4409 3.31944 11.5 3 11.5C2.30556 11.5 1.71528 11.2569 1.22917 10.7708C0.743056 10.2847 0.5 9.6944 0.5 8.99996C0.5 8.30551 0.743056 7.71524 1.22917 7.22913C1.71528 6.74301 2.30556 6.49996 3 6.49996C3.31944 6.49996 3.625 6.55899 3.91667 6.67704C4.20833 6.7951 4.47222 6.95829 4.70833 7.16663L10.5833 3.74996C10.5556 3.66663 10.5347 3.57288 10.5208 3.46871C10.5069 3.36454 10.5 3.26385 10.5 3.16663C10.5 2.47218 10.7431 1.8819 11.2292 1.39579C11.7153 0.909681 12.3056 0.666626 13 0.666626C13.6944 0.666626 14.2847 0.909681 14.7708 1.39579C15.2569 1.8819 15.5 2.47218 15.5 3.16663C15.5 3.86107 15.2569 4.45135 14.7708 4.93746C14.2847 5.42357 13.6944 5.66663 13 5.66663C12.6806 5.66663 12.375 5.6076 12.0833 5.48954C11.7917 5.37149 11.5278 5.20829 11.2917 4.99996L5.41667 8.41663C5.44444 8.49996 5.46528 8.59371 5.47917 8.69788C5.49306 8.80204 5.5 8.90274 5.5 8.99996C5.5 9.09718 5.49306 9.19788 5.47917 9.30204C5.46528 9.40621 5.44444 9.49996 5.41667 9.58329L11.2917 13C11.5278 12.7916 11.7917 12.6284 12.0833 12.5104C12.375 12.3923 12.6806 12.3333 13 12.3333C13.6944 12.3333 14.2847 12.5763 14.7708 13.0625C15.2569 13.5486 15.5 14.1388 15.5 14.8333C15.5 15.5277 15.2569 16.118 14.7708 16.6041C14.2847 17.0902 13.6944 17.3333 13 17.3333Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
