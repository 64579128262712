import { Box, Divider, Typography } from "@mui/material";

import useCommentsState from "../hooks/useCommentsState";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import { Comment } from "@convin/type/Common";
import InfiniteList from "@convin/components/custom_components/InfiniteList/InfiniteList";
import CommentCard from "./CommentCard";
import { useGetInfiniteCommentsList } from "../hooks/useGetInfiniteCommentsList";
import CommentInput from "./CommentInput";
import CommentInputStatePorvider from "../context/CommentInputStatePorvider";
import { isDefined } from "@convin/utils/helper/common.helper";
import useCommentsDrawerStateContext from "../hooks/useCommentsDrawerStateContext";
import { formatTimeHHMMSS } from "@convin/utils/helper/date.helper";
import NoCommentsSvg from "@convin/components/svg/NoCommentsSvg";
import EmptyState from "@convin/components/custom_components/reuseable/EmptyState";
import { RepliesUI } from "./RepliesUI";

function CommentsUI() {
    const { id, dashboard_type, type } = useCommentsState();
    const { timeStampToCommentOn, transcript } =
        useCommentsDrawerStateContext();
    const {
        data: comments,
        hasNext,
        fetchNext,
        isLoading,
        isFetching,
        isFetchingNext,
    } = useGetInfiniteCommentsList({ id, dashboard_type, type });

    return (
        <>
            <Box
                sx={{
                    py: 2.75,
                    px: 2.25,
                }}
                className="flex-shrink-0"
            >
                <Typography className="font-medium">
                    Comments {`(${comments?.count ?? 0})`}
                </Typography>
            </Box>
            <Divider />

            {isFetching && !isFetchingNext ? (
                <SkeletonLoader />
            ) : comments?.results.length ? (
                <CommentInputStatePorvider>
                    <InfiniteList<Comment>
                        data={comments?.results}
                        Component={CommentCard}
                        hasNext={hasNext}
                        fetchNext={fetchNext}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        sx={{
                            p: 0,
                        }}
                    />
                </CommentInputStatePorvider>
            ) : (
                <Box className="flex items-center h-full">
                    <EmptyState
                        Component={<NoCommentsSvg />}
                        text="No Comments yet"
                        subtext="Be the first one to comment"
                    />
                </Box>
            )}

            <Box
                className="flex-shrink-0"
                sx={{
                    p: 2.5,
                }}
            >
                <CommentInputStatePorvider
                    initialCommentValue={
                        isDefined(timeStampToCommentOn)
                            ? `@${formatTimeHHMMSS(timeStampToCommentOn)}`
                            : ""
                    }
                    transcript={transcript}
                >
                    <CommentInput />
                </CommentInputStatePorvider>
            </Box>
        </>
    );
}

export default function CommentsInterface() {
    const { commentToReply } = useCommentsState();

    return isDefined(commentToReply) ? <RepliesUI /> : <CommentsUI />;
}
