import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
    createFilterOptions,
} from "@mui/material";

import { Label } from "@convin/components/custom_components/Typography/Label";
import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import { presentInCallOptions } from "@convin/config/conversation.config";
import { useGlobalFiltersStateContext } from "@convin/modules/components";
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";

const KeywordSearchFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const { checkCanAccess } = useAuth();

    const canSearch = checkCanAccess({
        heading: PermissionModules.Meeting,
        code_name: "meeting.restrict_search_meeting",
        permission_type: PermissionTypes.edit,
    });

    return (
        <>
            <Autocomplete
                multiple
                open={false}
                options={[]}
                freeSolo
                filterOptions={createFilterOptions({
                    ignoreCase: false,
                    trim: true,
                })}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            className: `${params.inputProps.className ?? ""} ${
                                !canSearch ? "cursor-not-allowed" : ""
                            }`,
                        }}
                        variant="outlined"
                        label="Add keyword and press Enter"
                    />
                )}
                value={filtersState.searchKeywords.keywords}
                onChange={(_, value) => {
                    const uniqueValues = Object.fromEntries(
                        (value as string[])
                            .map((s) => s.trim())
                            .filter((s) => s.length)
                            .map((s) => [s.toLowerCase(), s])
                    );
                    updateFiltersState({
                        searchKeywords: {
                            ...filtersState.searchKeywords,
                            keywords: Object.values(uniqueValues),
                        },
                    });
                }}
                disabled={!canSearch}
            />
            <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                gap={4}
            >
                <GenericRadioGroup
                    name="Present in Conversation"
                    options={presentInCallOptions}
                    value={String(filtersState.searchKeywords.isInCall)}
                    handleChange={(val) => {
                        updateFiltersState({
                            searchKeywords: {
                                ...filtersState.searchKeywords,
                                isInCall: val === "true",
                            },
                        });
                    }}
                />
                <Stack gap={1.5}>
                    <Label colorType="333" sx={{ fontWeight: 500 }}>
                        Said by
                    </Label>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        !!filtersState.searchKeywords
                                            .saidByOwner
                                    }
                                    onChange={(_, checked) => {
                                        updateFiltersState({
                                            searchKeywords: {
                                                ...filtersState.searchKeywords,
                                                saidByOwner: checked,
                                            },
                                        });
                                    }}
                                    name="rep"
                                />
                            }
                            label="Rep"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        !!filtersState.searchKeywords
                                            .saidByClient
                                    }
                                    onChange={(_, checked) =>
                                        updateFiltersState({
                                            searchKeywords: {
                                                ...filtersState.searchKeywords,
                                                saidByClient: checked,
                                            },
                                        })
                                    }
                                    name="others"
                                />
                            }
                            label="Others"
                        />
                    </Box>
                </Stack>
            </Stack>
        </>
    );
};

export default KeywordSearchFilter;
