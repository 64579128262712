import EmptyState from "@convin/components/custom_components/reuseable/EmptyState";
import AccessDeniedSvg from "@convin/components/svg/AccessDeniedSvg";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import useConversationDetailsContext from "../hooks/useConversationDetailsContext";
import { ChevronRight } from "@mui/icons-material";

export default function ConversationAccessDenied() {
    const { dashboardType } = useConversationDetailsContext();
    return (
        <div className="h-full w-full flex items-center justify-center">
            <EmptyState
                Component={<AccessDeniedSvg />}
                text="Conversation Access Denied"
                subtext="Sorry, you do not have access to this conversation. "
                ButtonComponent={
                    dashboardType === "account" ? (
                        <></>
                    ) : (
                        <Link to={DashboardRoutesConfig.Conversations.path}>
                            <Button variant="text" endIcon={<ChevronRight />}>
                                Back to All Conversations
                            </Button>
                        </Link>
                    )
                }
            />
        </div>
    );
}
