import { alpha, Divider } from "@mui/material";

import { Box, Popover, Typography } from "@mui/material";

import StarFeatureSvg from "@convin/components/svg/StarFeatureSvg";
import { IconButton, InputAdornment } from "@mui/material";
import { CloseSvg } from "@convin/components/svg";
import { LoadingButton } from "@mui/lab";
import SavingSvg from "@convin/components/svg/SavingSvg";
import { useEffect, useState } from "react";
import { useGenerateAiDescriptionMutation } from "@convin/redux/services/notifications/notification.service";
import { IGenerateDescriptionPayload } from "@convin/type/WebSocketNotification";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
export default function GenerateAiDescription({
    payload,
    onApply,
}: {
    payload: IGenerateDescriptionPayload;
    onApply: (value: string) => void;
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const [generateAiDescription, { isLoading, data }] =
        useGenerateAiDescriptionMutation();
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            generateAiDescription(payload);
        }
    }, [open]);
    return (
        <InputAdornment position="end">
            <IconButton
                onClick={handlePopoverOpen}
                disabled={!payload.feature_parameter_name}
                className="disabled:opacity-50 disabled:cursor-not-allowed"
            >
                <StarFeatureSvg />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div className="flex flex-col w-[265px]">
                    <Box p={2} className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                            <StarFeatureSvg />
                            <Typography
                                className="font-semibold"
                                color="textColors.666"
                                sx={{
                                    background:
                                        "linear-gradient(115deg, #FF1B79 -16.74%, #FF4E60 23.75%, #2D66E8 76.44%)",
                                    backgroundClip: "text",
                                    "-webkit-background-clip": "text",
                                    "-webkit-text-fill-color": "transparent",
                                }}
                            >
                                Rewrite AI
                            </Typography>
                        </div>
                        <button onClick={handlePopoverClose}>
                            <CloseSvg />
                        </button>
                    </Box>
                    {isLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <Typography
                            variant="small"
                            color="textColors.666"
                            className="flex-1 overflow-scroll"
                            p={2}
                        >
                            {data?.description}
                        </Typography>
                    )}
                    <div className="flex-shrink-0">
                        <Divider />
                        <Box className="flex justify-between" p={2}>
                            <LoadingButton
                                variant="text"
                                sx={{
                                    color: "textColors.666",
                                    backgroundColor: (theme) =>
                                        alpha(
                                            theme.palette.textColors["999"],
                                            0.1
                                        ),
                                }}
                                disabled={isLoading}
                                onClick={() => {
                                    generateAiDescription(payload);
                                }}
                            >
                                <SavingSvg disabledAnimation />
                            </LoadingButton>
                            <Typography
                                variant="medium"
                                component="button"
                                color="primary.main"
                                onClick={() => {
                                    onApply(
                                        data?.description ||
                                            payload.feature_description ||
                                            ""
                                    );
                                    handlePopoverClose();
                                }}
                            >
                                Apply
                            </Typography>
                        </Box>
                    </div>
                </div>
            </Popover>
        </InputAdornment>
    );
}
