import { useContext } from "react";
import { CIContext, ICIContext } from "../context/CIContext";

const useCIContext = (): ICIContext => {
    const context = useContext(CIContext);

    if (!context) throw new Error("CI context must be use inside CIprovider");

    return context;
};

export default useCIContext;
