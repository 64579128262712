import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { AntSwitch } from "../custom_components/Switch/AntSwitch";

// ----------------------------------------------------------------------

export default function RHFSwitch({ name }: { name: string }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <AntSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                        field.onChange(e.target.checked);
                    }}
                />
            )}
        />
    );
}
