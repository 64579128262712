import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetAllAuditorsQuery } from "@convin/redux/services/settings/users.service";
import { UserType } from "@convin/type/User";

export default function AuditorFilter() {
    const { data: auditorsList, isLoading: isAuditorsLoading } =
        useGetAllAuditorsQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <>
            <GenericMultipleSelect<UserType>
                label="Auditors"
                loading={isAuditorsLoading}
                values={filtersState.auditors}
                setValues={(val: number[]) => {
                    updateFiltersState({
                        auditors: val,
                    });
                }}
                autocompleteProps={{
                    options: auditorsList || [],
                }}
            />
        </>
    );
}
