import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function CsatSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 20 21"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                d="M8.70829 12.9167C9.05551 13.2639 9.49301 13.4273 10.0208 13.4067C10.5486 13.3862 10.9305 13.195 11.1666 12.8334L14.6875 7.56254C14.8125 7.3681 14.7952 7.19087 14.6358 7.03087C14.4763 6.87087 14.2991 6.85365 14.1041 6.97921L8.83329 10.5C8.47218 10.75 8.2744 11.1287 8.23996 11.6359C8.20551 12.1431 8.36163 12.57 8.70829 12.9167ZM4.24996 16.6667C3.9444 16.6667 3.66329 16.6006 3.40663 16.4684C3.14996 16.3362 2.94496 16.1384 2.79163 15.875C2.43051 15.2223 2.15274 14.5453 1.95829 13.8442C1.76385 13.1431 1.66663 12.4173 1.66663 11.6667C1.66663 10.5139 1.88551 9.4306 2.32329 8.41671C2.76107 7.40282 3.35468 6.52087 4.10413 5.77087C4.85413 5.02087 5.73607 4.42726 6.74996 3.99004C7.76385 3.55282 8.84718 3.33393 9.99996 3.33337C11.1388 3.33337 12.2083 3.54865 13.2083 3.97921C14.2083 4.40976 15.0833 4.99671 15.8333 5.74004C16.5833 6.48282 17.1805 7.35087 17.625 8.34421C18.0694 9.33754 18.2986 10.4034 18.3125 11.5417C18.3263 12.3056 18.2397 13.0523 18.0525 13.7817C17.8652 14.5112 17.5769 15.2089 17.1875 15.875C17.0347 16.1389 16.83 16.337 16.5733 16.4692C16.3166 16.6014 16.035 16.6673 15.7283 16.6667H4.24996Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
