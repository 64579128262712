import { Box, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { ReactElement } from "react";

type DotProps = SystemStyleObject<Theme> & {
    className?: string;
    bgcolor?: string;
};

function Dot({ className, bgcolor, ...props }: DotProps): ReactElement {
    return (
        <Box
            className={className}
            sx={(theme: Theme) => ({
                width: 6,
                height: 6,
                borderRadius: "50%",
                display: "inline-block",
                bgcolor: bgcolor || theme.palette.textColors["999"],
                ...props,
            })}
        />
    );
}

export default Dot;
