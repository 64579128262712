import { FormProvider } from "@convin/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, Stack, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "@convin/redux/services/auth/auth.service";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import useSearchQuery from "@convin/hooks/useSearchQuery";

import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import PasswordField from "../components/PasswordField";
import useDocumentTitle from "@convin/hooks/useDocumentTitle";

export default function NewPasswordForm() {
    useDocumentTitle(DashboardRoutesConfig["ResetPassword"].name);

    const query = useSearchQuery();
    const uid = query.get("uid");
    const navigate = useNavigate();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    const PasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .matches(
                /[a-z]/,
                "Password must contain at least one lowercase letter"
            )
            .matches(
                /[A-Z]/,
                "Password must contain at least one uppercase letter"
            )
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[@$!%*?&#]/,
                "Password must contain at least one special character"
            )
            .required("New Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
            .required("Confirm Password is required"),
    });

    const methods = useForm({
        resolver: yupResolver(PasswordSchema),
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
            afterSubmit: "",
        },
    });

    const {
        handleSubmit,
        formState: { errors },
        setError,
    } = methods;

    const onSubmit = async (data: {
        newPassword: string;
        confirmPassword: string;
    }) => {
        // Handle password reset logic here
        if (!uid) {
            return;
        }
        const formData = new FormData();
        formData.append("new_password", data.newPassword);
        resetPassword({
            payload: formData,
            query: { uid },
        })
            .unwrap()
            .then(() => {
                navigate({
                    pathname: DashboardRoutesConfig["Signin"].to,
                    search: "?reset_password=true",
                });
            })
            .catch((err) => {
                setError("afterSubmit", { message: getErrorMessage(err) });
            });
    };

    if (!uid) {
        return <Navigate to={DashboardRoutesConfig["Signin"].to} />;
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2} mt={2}>
                <PasswordField name="newPassword" label="Password" />
                <PasswordField
                    name="confirmPassword"
                    label="Confirm Password"
                />

                {!!errors.afterSubmit && (
                    <Alert
                        severity="error"
                        sx={{ border: "1px solid", borderColor: "error.main" }}
                    >
                        {errors.afterSubmit.message}
                    </Alert>
                )}
                <Button
                    loading={isLoading}
                    variant="global"
                    fullWidth
                    size="large"
                    type="submit"
                >
                    Reset Password
                </Button>
            </Stack>
        </FormProvider>
    );
}
