import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider } from "@convin/components/hook-form";
import { FormTextField } from "../components";
import { Alert, Button, Stack, Typography } from "@mui/material";

import { useGetUserDomainMutation } from "@convin/redux/services/auth/auth.service";
import { getErrorMessage } from "@convin/redux/middleware/errorhandler";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { getBaseUrl } from "@convin/utils/helper/common.helper";

const FindDomain = () => {
    const [findDomain, { isLoading }] = useGetUserDomainMutation();
    const schema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
    });

    const defaultValues = {
        email: "",
        afterSubmit: "",
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { errors },
        setError,
    } = methods;

    const onSubmit = async (data: { email: string }) => {
        findDomain(data)
            .unwrap()
            .then((res) => {
                if (res.domain) {
                    window.location.href = `${
                        import.meta.env.PROD ? "https" : "http"
                    }://${res.domain}.${getBaseUrl()}/${
                        DashboardRoutesConfig["Signin"].path
                    }?email=${data.email}`;
                } else {
                    setError("afterSubmit", {
                        message:
                            "Hmm.. Looks like you are not registered with us. Try to signup from below link",
                    });
                }
            })
            .catch((err) => {
                setError("afterSubmit", {
                    message: getErrorMessage(err),
                });
            });
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="medium" component="div" my={1.5}>
                Enter email address to find your organization
            </Typography>
            <Stack gap={2}>
                <FormTextField
                    name="email"
                    label="Email Id"
                    autoComplete="true"
                    className="requiredMark"
                />
                {!!errors.afterSubmit && (
                    <Alert
                        severity="error"
                        sx={{ border: "1px solid", borderColor: "error.main" }}
                    >
                        {errors.afterSubmit.message}
                    </Alert>
                )}

                <Button
                    variant="global"
                    fullWidth
                    size="large"
                    type="submit"
                    className="h-[42px]"
                    loading={isLoading}
                >
                    Find My Organization
                </Button>
            </Stack>
        </FormProvider>
    );
};

export default FindDomain;
