import axios from "axios";
import apiConfigs from "./common/commonApiConfig";
import applyAppTokenRefreshInterceptor from "./axios.refresh_token";
import apiConfig from "@convin/config/api.config";
import { domainMappingConfig } from "@constants/Domain/domain.config";

const getDomain = () => {
    const { hostname } = new URL(window.location.href);
    return hostname.split(".")?.[0] ?? "";
};

export const getDomainMappingName = (name) => {
    return domainMappingConfig?.[name?.toLowerCase()] || name;
};

const protocol = import.meta.env.VITE_APP_API_PROTOCOL ?? apiConfigs.HTTPS;
const apiBaseurl =
    import.meta.env.VITE_APP_BACKEND_BASE_URL ?? apiConfigs.BASEURL;

const baseUrl = import.meta.env.VITE_APP_BACKEND_DOMAIN
    ? `${protocol}${import.meta.env.VITE_APP_BACKEND_DOMAIN}`
    : `${protocol}${getDomainMappingName(getDomain())}.${apiBaseurl}`;

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITEST ? apiConfig.TESTURL : baseUrl,
});

const addHeader = async (config) => {
    let authTokens = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;
    let authHeader = {};
    if (authTokens?.access) {
        authHeader = { Authorization: `Bearer ${authTokens?.access}` };
    }

    config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...config.headers,
        ...authHeader,
    };

    return config;
};

axiosInstance.interceptors.request.use(addHeader, (error) => {
    Promise.reject(error);
});

applyAppTokenRefreshInterceptor(axiosInstance);

const baseQuery = async (args, apiOptions, extraOptions) => {
    if (
        args.method === "POST" ||
        args.method === "PUT" ||
        args.method === "PATCH"
    ) {
        args.data = JSON.stringify(args.body);
    }
    const axiosResponse = await axiosInstance.request(args);

    const { headers, status, statusText } = axiosResponse;
    const data = axiosResponse.data;

    const response = new Response(JSON.stringify(data), {
        headers,
        status,
        statusText,
    });

    return response;
};

export { axiosInstance, baseQuery, baseUrl };
