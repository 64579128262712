import React, { ReactNode, useState } from "react";
import {
    AttachLmsDocumentsContext,
    IAttachLmsDocumentsContext,
} from "./AttachLmsDocumentsContext";

interface AttachLmsDocumentsProviderProps {
    children: ReactNode;
    /**
     * Optional initial value for selected documents
     */
    initialValue?: number[];
}

/**
 * Provider component for AttachLmsDocuments context
 */
export const AttachLmsDocumentsProvider: React.FC<
    AttachLmsDocumentsProviderProps
> = ({ children, initialValue = [] }) => {
    const [selectedDocuments, setSelectedDocuments] =
        useState<number[]>(initialValue);

    /**
     * Update the selected documents
     */
    const handleUpdate = (newValue: number[]): void => {
        setSelectedDocuments(newValue);
    };

    // Create the context value
    const contextValue: IAttachLmsDocumentsContext = {
        value: selectedDocuments,
        handleUpdate,
    };

    return (
        <AttachLmsDocumentsContext value={contextValue}>
            {children}
        </AttachLmsDocumentsContext>
    );
};

export default AttachLmsDocumentsProvider;
