import { Box, Divider, Popover } from "@mui/material";
import React, { useCallback, useState } from "react";

import {
    DateDropDown,
    DateFilterProps,
} from "@convin/components/custom_components/Filters/DateFilter";
import { Label } from "@convin/components/custom_components/Typography/Label";
import DateSelectorSvg from "@convin/components/svg/OverallFiters/DateSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { isDefined } from "@convin/utils/helper/common.helper";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox } from "../../../styles";
import { format } from "date-fns";

const AuditDateSelectorFilter = () => {
    const { state, updateState } = useGlobalFiltersStateContext();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const handleUpdate: DateFilterProps["handleUpdate"] = (e) => {
        if (!Boolean(e.is_custom)) {
            updateState({
                auditDateKey: e.value,
            });
        } else {
            updateState({
                auditDateKey: e.value,
                auditDateOptions: {
                    ...state.auditDateOptions,
                    ...(isDefined(e.value) && {
                        [e.value]: {
                            name: e.value,
                            is_rolling_date: false,
                            label: "",
                            dateRange: e.dateRange,
                        },
                    }),
                },
            });
        }
    };

    const handleRangeChange = (newRange: [Date | null, Date | null]) => {
        if (!isDefined(newRange[0]) && !isDefined(newRange[1])) return;

        const formattedDate0 = isDefined(newRange[0])
            ? format(newRange[0], "MMM dd, yyyy HH:mm")
            : "";
        const formattedDate1 = isDefined(newRange[1])
            ? format(newRange[1], "MMM dd, yyyy HH:mm")
            : "";

        const fromDate = isDefined(newRange[0]) ? `${newRange[0]}` : null;
        const toDate = isDefined(newRange[1]) ? `${newRange[1]}` : null;

        const dateRange: [string | null, string | null] = [fromDate, toDate];

        const active_date = !isDefined(newRange[0])
            ? `Before ${formattedDate1}`
            : !isDefined(newRange[1])
            ? `After ${formattedDate0}`
            : `${formattedDate0} - ${formattedDate1}`;

        handleUpdate({
            value: active_date,
            is_custom: true,
            dateRange,
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <StyledFilterBox onClick={handleClick} aria-describedby={id}>
                <DateSelectorSvg
                    sx={{ color: (theme) => theme.palette.common.white }}
                />
                <Label
                    isEllipses
                    hasTooltip
                    colorType="white"
                    className="font-medium flex-1"
                    variant="medium"
                >
                    {isDefined(state.auditDateKey)
                        ? state.auditDateOptions[state.auditDateKey].name
                        : "Date"}
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                classes={{ paper: "filters_popover flex column" }}
                sx={{
                    "& .MuiPaper-root.filters_popover": {
                        borderRadius: "6px",
                        border: "1px solid rgba(153, 153, 153, 0.20) !important",
                        minWidth: "250px",
                        maxHeight: "400px",
                        boxShadow: "0px 4px 16px 2px rgba(51, 51, 51, 0.10)",
                        marginTop: "8px",

                        "& .custom_date_picker": {
                            width: "348px",
                            m: 0,
                        },
                    },
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Label colorType="666" variant="medium" sx={{ p: 1.5 }}>
                    Select Audit Date
                </Label>
                <Divider />
                {
                    // <Box className="flex-1 overflow-y-auto">
                    <DateDropDown
                        options={state.auditDateOptions}
                        active={state.auditDateKey}
                        handleUpdate={handleUpdate}
                        handleRangeChange={handleRangeChange}
                        onClose={handleClose}
                        showCustomTimePicker={true}
                    />
                    // </Box>
                }
            </Popover>
        </>
    );
};

export default AuditDateSelectorFilter;
