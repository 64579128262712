import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    GlobalFiltersStateContext,
    IGlobalFiltersStateContext,
} from "../contexts/GlobalFiltersStateContextProvider";

const useGlobalFiltersStateContext = (): IGlobalFiltersStateContext => {
    const context = useContext(GlobalFiltersStateContext);
    if (!isDefined(context))
        throw new Error(
            "GlobalFiltersStateContext must be use inside GlobalFiltersStateProvider"
        );
    return context;
};

export default useGlobalFiltersStateContext;
