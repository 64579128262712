import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";
import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import { presentInCallOptions } from "@convin/config/conversation.config";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetTopicsQuery } from "@convin/redux/services/settings/topicManager.service";

const TopicsFilter = () => {
    const { data: topicList } = useGetTopicsQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    return (
        <>
            <GenericSingleSelect
                autocompleteProps={{
                    options:
                        topicList?.map((topic) => ({
                            id: topic.id,
                            label: topic.name,
                        })) || [],
                    disableClearable: false,
                }}
                label="Choose a Topic"
                value={filtersState.topics.id}
                setValue={(e) => {
                    updateFiltersState({
                        topics: {
                            ...filtersState.topics,
                            id: e,
                        },
                    });
                }}
                className="flex-1"
            />
            <GenericRadioGroup
                name="Present in Conversation"
                options={presentInCallOptions}
                value={String(filtersState.topics.inCall)}
                handleChange={(val) =>
                    updateFiltersState({
                        topics: {
                            ...filtersState.topics,
                            inCall: val === "true",
                        },
                    })
                }
            />
        </>
    );
};

export default TopicsFilter;
