import { Accordion, Box, Button, Theme, alpha, styled } from "@mui/material";

export const StyledFilterBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    cursor: "pointer",
    height: 32,
    maxWidth: 192,
    borderRadius: "100px",
    color: "white",
    padding: theme.spacing(1.6),
    background: theme.palette.primary.main,
    lineHeight: "normal",
}));

export const filterPopoverSx = (
    theme: Theme,
    width: string | number | undefined = "200px"
) => ({
    "& .MuiPaper-root": {
        borderRadius: "6px",
        border: `1px solid ${alpha(
            theme.palette.textColors[999],
            0.2
        )} !important`,
        boxShadow: `0px 4px 16px 2px ${alpha(
            theme.palette.textColors[333],
            0.1
        )}`,
        maxWidth: "250px",
        width,
        marginTop: "8px",
    },
});

export const StyledCategoryAccordion = styled(Accordion)(({ theme }) => ({
    "&.MuiAccordion-root": {
        border: "1px solid",
        borderColor: alpha(theme.palette.textColors[999], 0.1),
        borderRadius: 5,
        "&:before": {
            display: "none",
        },
    },
    ".MuiAccordionSummary-root": {
        height: 56,
        color: theme.palette.textColors[333],
        fontWeight: 500,
    },
    ".MuiAccordionDetails-root": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));

export const RatingQuestionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    color: active ? theme.palette.common.white : theme.palette.textColors[666],
    borderColor: active
        ? theme.palette.common.white
        : theme.palette.textColors[999],
    background: `${
        active ? theme.palette.primary.main : "transparent"
    } !important`,
}));
