import { Avatar, Badge, Theme, SxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            content: '""',
        },
    },
}));

export const ProfileMenuAvatar: React.FC<{
    initials: string;
    isOnline: boolean;
    sx?: SxProps<Theme>;
    size?: number;
}> = ({ initials, isOnline, sx, size = 30 }) => {
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            color={isOnline ? "success" : "error"}
            sx={{
                ...sx,
            }}
        >
            <Avatar
                alt={initials}
                sx={{ bgcolor: "primary.main", color: "white" }}
                component={"span"}
                className={`w-[${size}px] h-[${size}px]`}
            >
                {initials}
            </Avatar>
        </StyledBadge>
    );
};
