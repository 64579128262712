import { CSATColors } from "@convin/theme/palette";
import { CIFilters } from "@convin/type/Filters.model";
import {
    InsightsDbNameType,
    InsightType,
} from "@convin/type/customerIntelligence";
import { getColorFromString } from "@convin/utils/helper/common.helper";
import { Palette, Theme } from "@mui/material";

export const initialCIFilterState: CIFilters = {
    ciFilter: {
        saidBy: {
            client: true,
            owner: true,
        },
        exclude: false,
        agentTenure: null,
    },
};

export const ciPhraseStatsScoreLabels = {
    good: "Good",
    avg: "Average",
    bad: "Need Attention",
};

export const ciPhraseStatsSentimentLabels = {
    positive: "Positive",
    neutral: "Neutral",
    negative: "Negative",
};

export const alpha = [
    "",
    "dd",
    "cc",
    "bb",
    "aa",
    "99",
    "88",
    "77",
    "66",
    "55",
    "44",
    "33",
    "22",
    "11",
];
export const teamColors = [
    "#613DC1",
    "#F5B700",
    "#00BD9D",
    "#E34A6F",
    "#009EDD",
];
export const agentColors = [
    "#BD56FF",
    "#FFE54C",
    "#41C97E",
    "#FF826F",
    "#4C82FF",
];
export const getInsightLabels = <
    T extends InsightType | InsightsDbNameType | string | number
>({
    type,
    primaryColor,
    palette,
    viewLevel,
}: {
    type: T;
    primaryColor?: string;
    palette?: T extends InsightType ? never : Theme["palette"];
    viewLevel?: "default" | "team_view" | "agent_view";
}): {
    title: string;
    columnName: string;
    color: (key: string, index?: number) => string;
} => {
    try {
        switch (type) {
            case "reason":
                return {
                    title: "Conversation Reasons",
                    columnName: "Reason",
                    color: (_, index = 0) =>
                        `${primaryColor || "#1a62f2"}${alpha[index]}`,
                };
            case "feature":
                return {
                    title: "Product Features",
                    columnName: "Feature",
                    color: (_, index = 0) => `#FE654F${alpha[index]}`,
                };
            case "objection":
                return {
                    title: "Objections",
                    columnName: "Objection",
                    color: (_, index = 0) => `#7030FE${alpha[index]}`,
                };
            case "question":
                return {
                    title: "Questions",
                    columnName: "Question",
                    color: (_, index = 0) => `#F564A9${alpha[index]}`,
                };
            case "competition":
                return {
                    title: "Competition",
                    columnName: "Competition",
                    color: (_, index = 0) => `#E4C744${alpha[index]}`,
                };
            case "sentiment":
                return {
                    title: "Sentiment",
                    columnName: "Sentiment",
                    color: (key) => {
                        const name = key.toLowerCase();
                        return name.includes("positive")
                            ? "#1AC468"
                            : name.includes("negative")
                            ? "#F93739"
                            : "#A6A6A6";
                    },
                };
            case "team_and_agent_analysis":
                return {
                    title: "Team Analysis",
                    columnName: "Team Analysis",
                    color: (key, index = 0) => {
                        if (viewLevel === "team_view") {
                            return teamColors[index % teamColors.length];
                        } else {
                            return agentColors[index % agentColors.length];
                        }
                    },
                };
            case "sentiment_analysis":
                return {
                    title: "Sentiment Analysis",
                    columnName: "Sentiment Analysis",
                    color: (key, index = 0) => {
                        const name =
                            key.toLowerCase() as keyof Palette["sentimentColors"];
                        return name in palette!.sentimentColors
                            ? `${palette?.sentimentColors[name]}${alpha[index]}`
                            : viewLevel === "team_view"
                            ? teamColors[index % teamColors.length]
                            : agentColors[index % agentColors.length];
                    },
                };
            case "csat_analysis":
                return {
                    title: "CSAT Analysis",
                    columnName: "CSAT Analysis",
                    color: (key, index = 0) => {
                        let colorKey;

                        switch (key.toLowerCase()) {
                            case "satisfied":
                                colorKey = "good";
                                break;
                            case "neutral":
                                colorKey = "average";
                                break;
                            case "dissatisfied":
                                colorKey = "bad";
                                break;
                            default:
                                colorKey = key;
                        }

                        return colorKey in CSATColors!
                            ? `${
                                  CSATColors[
                                      colorKey as keyof typeof CSATColors
                                  ]
                              }${alpha[index]}`
                            : viewLevel === "team_view"
                            ? teamColors[index % teamColors.length]
                            : agentColors[index % agentColors.length];
                    },
                };
            case "lead_interest_analysis":
                return {
                    title: "Lead Interest Analysis",
                    columnName: "Lead Interest",
                    color: (key, index = 0) => {
                        const name =
                            key.toLowerCase() as keyof Palette["leadColors"];
                        return name in palette!.leadColors
                            ? `${palette?.leadColors[name]}${alpha[index]}`
                            : viewLevel === "team_view"
                            ? teamColors[index % teamColors.length]
                            : agentColors[index % agentColors.length];
                    },
                };
            case "collection_score_analysis":
                return {
                    title: "Collection Score Analysis",
                    columnName: "Collection Score",
                    color: (key, index = 0) => {
                        const name =
                            key.toLowerCase() as keyof Palette["CSATColors"];
                        return name in palette!.CSATColors
                            ? `${palette?.CSATColors[name]}${alpha[index]}`
                            : viewLevel === "team_view"
                            ? teamColors[index % teamColors.length]
                            : agentColors[index % agentColors.length];
                    },
                };
            case "deal_status_analysis":
                return {
                    title: "Deal Status",
                    columnName: "Deal Status",
                    color: (key, index = 0) => {
                        return viewLevel === "agent_view"
                            ? agentColors[index % agentColors.length]
                            : teamColors[index % teamColors.length];
                    },
                };
            case "ai_score_analysis":
                return {
                    title: "Ai Score",
                    columnName: "Ai Score",
                    color: (key, index = 0) => {
                        const name =
                            key.toLowerCase() as keyof Palette["auditColors"];

                        return name in palette!.auditColors
                            ? `${palette?.auditColors[name]}${alpha[index]}`
                            : viewLevel === "team_view"
                            ? teamColors[index % teamColors.length]
                            : agentColors[index % agentColors.length];
                    },
                };
            default:
                return {
                    title: String(type),
                    columnName: "Atribute",
                    color: (key, index = 0) =>
                        `${
                            key
                                ? getColorFromString(key, { isAiInsight: true })
                                : primaryColor
                        }${alpha[index]}`,
                };
        }
    } catch (err) {
        console.error(err);
        return {
            title: "Invalid Titel",
            columnName: "Invalid Column",
            color: (_, index = 0) =>
                `${primaryColor || "#1a62f2"}${alpha[index]}`,
        };
    }
};

export const othersSectionPieColor = "#d7d7d7";

export const insightSections: InsightType[] = [
    "reason",
    "objection",
    "question",
    "feature",
    "competition",
    "sentiment",
];

export const ciDonutChartAggregateLimit = 5;

export const insightAnalysisDashboards: Array<{
    name: string;
    value: InsightsDbNameType;
}> = [
    {
        name: "Team & Agent Analysis",
        value: "team_and_agent_analysis",
    },
    {
        name: "Sentiment Analysis",
        value: "sentiment_analysis",
    },
    {
        name: "CSAT Analysis",
        value: "csat_analysis",
    },
    {
        name: "Lead Interest Analysis",
        value: "lead_interest_analysis",
    },
    {
        name: "Collection Score Analysis",
        value: "collection_score_analysis",
    },
    {
        name: "Deal Status",
        value: "deal_status_analysis",
    },
    {
        name: "AI Score",
        value: "ai_score_analysis",
    },
];

export const getInsightAnalysisTabInfo = (type: InsightsDbNameType) => {
    switch (type) {
        case "team_and_agent_analysis":
            return {
                title: "Teams Analysis",
            };
        case "sentiment_analysis":
            return {
                title: "Sentiment Analysis",
            };
        case "csat_analysis":
            return {
                title: "CSAT",
            };
        case "lead_interest_analysis":
            return {
                title: "Lead Interest",
            };
        case "collection_score_analysis":
            return {
                title: "Collection Score",
            };
        case "deal_status_analysis":
            return {
                title: "Deal Status",
            };
        case "ai_score_analysis":
            return {
                title: "Ai Score",
            };
    }
};

export const zoomScale = {
    min: 0.5,
    max: 5,
};
