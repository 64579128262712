import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { Typography } from "@mui/material";
import { storeVersionData } from "@store/common/actions";
import { useDispatch } from "react-redux";
import { PropsWithChildren, useEffect } from "react";
import { handleVersionChange } from "@convin/utils/helper/common.helper";

export default function VersionApiLoader({ children }: PropsWithChildren) {
    const { data, isLoading, error } = useGetDomainConfigQuery();
    const dispatch = useDispatch();
    useEffect(() => {
        //Support for legacy code
        if (data) {
            dispatch(
                storeVersionData(
                    data.stats_threshold
                        ? { ...data }
                        : {
                              ...data,
                              stats_threshold: {
                                  good: 75,
                                  average: 50,
                                  bad: 50,
                              },
                          }
                )
            );
            const av = localStorage.getItem("av");
            if (parseInt(av || "0") !== data.version) {
                handleVersionChange();
            }
            localStorage.setItem("av", String(data.version));
        }
    }, [data]);

    if (isLoading && !error)
        return (
            <div className="w-full h-full flex flex-col justify-center items-center gap-5">
                <SystemUpdateAltIcon
                    sx={{
                        transform: "scale(3)",
                        color: "primary.main",
                    }}
                />
                <Typography variant="h6">Checking for updates...</Typography>
            </div>
        );

    return <>{children}</>;
}
