import {
    Box,
    Button,
    Checkbox,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import CustomPopover from "@convin/components/custom_components/Popover/CustomPopover";
import { toOptions } from "@convin/components/custom_components/TreeSelect/MuiTeamSelector";
import { Label } from "@convin/components/custom_components/Typography/Label";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import TeamSelectorSvg from "@convin/components/svg/OverallFiters/TeamSelectorSvg";
import TrailingSvg from "@convin/components/svg/TrailingSvg";
import { useGetTeamsQuery } from "@convin/redux/services/settings/teamManager.service";
import { TeamTreeSelectOption } from "@convin/type/Common";
import { isDefined } from "@convin/utils/helper/common.helper";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { StyledFilterBox, filterPopoverSx } from "../../../styles";
import SearchField from "../../SearchField";
import useFiltersVisibilityContext from "@convin/hooks/useFiltersVisibilityContext";

const LabelWithChekbox: React.FC<{
    data: TeamTreeSelectOption;
    onClick: (id: number, reason: "add" | "remove") => void;
    selected: number[];
}> = React.memo(
    function LabelWithChekbox({ data, onClick, selected }) {
        const selectedSet = new Set(selected);
        const allSubteamsSelected = data.hasSubteams
            ? data.subteamsId.every((id) => selectedSet.has(id))
            : false;
        const checked = selected.includes(data.id) || allSubteamsSelected;
        return (
            <ListItemButton
                sx={{
                    p: 0,
                    pl: 2 * data.depth,
                    width: "100%",
                }}
                onClick={() => {
                    onClick(data.id, checked ? "remove" : "add");
                }}
                component="button"
            >
                <Checkbox checked={checked} />
                <ListItemText primary={data.name} />
            </ListItemButton>
        );
    },
    (prev, next) =>
        prev.data.id === next.data.id && prev.selected === next.selected
);
interface TeamFilterProps {
    filterStyle?: "custom";
    analysedTeamsIds?: undefined | number[];
}
export default function TeamFilter({
    filterStyle,
    analysedTeamsIds,
}: TeamFilterProps): ReactElement {
    const theme = useTheme();
    const { data: teams, isLoading } = useGetTeamsQuery();

    const { state, updateState, handleTemplateSelection } =
        useGlobalFiltersStateContext();
    const { dashboardType } = useFiltersVisibilityContext();

    const isAccountDashboard = dashboardType === "Account";

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const selectedTeamsKey = isAccountDashboard ? "accountTeams" : "teams";
    const selectedRepsKey = isAccountDashboard ? "accountReps" : "reps";
    const [selectedTeams, setSelectedTeams] = useState<number[]>(
        state[selectedTeamsKey] || []
    );

    const [searchValue, setSearchValue] = useState("");

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        setSelectedTeams(state[selectedTeamsKey]);
    }, [state.accountTeams, state.teams, isAccountDashboard]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>): void => {
            setAnchorEl(event.currentTarget);
        },
        []
    );
    const handleClose = useCallback((): void => {
        setSearchValue("");
        setAnchorEl(null);
    }, []);
    const filterBoxStyles =
        filterStyle === "custom"
            ? {
                  background: "inherit",
                  color: theme.palette.textColors[666],
                  border: "1px solid #999",
              }
            : {};

    const filteredTeamsList = analysedTeamsIds
        ? teams
              ?.map((category) => {
                  const matchingSubteams =
                      category.subteams?.filter((subteam) =>
                          analysedTeamsIds?.includes(subteam.id)
                      ) || [];

                  const isCategoryIncluded = analysedTeamsIds?.includes(
                      category.id
                  );

                  return {
                      ...category,
                      subteams: isCategoryIncluded
                          ? category.subteams
                          : matchingSubteams,
                  };
              })
              .filter(
                  (category) =>
                      category.subteams.length > 0 ||
                      analysedTeamsIds?.includes(category.id)
              )
        : teams;
    const teamsList = filteredTeamsList?.flatMap((category) =>
        toOptions(category)
    );

    const filteredTeams =
        teamsList?.filter((team) =>
            team.matchTerms
                .join()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
        ) || [];

    const handleChange = (newId: number, reason: "add" | "remove"): void => {
        let selectedIds = [...selectedTeams];
        const option = teamsList?.find((team) => team.id === newId);
        if (!isDefined(option)) return;
        if (reason === "remove") {
            if (option.hasSubteams) {
                const subTeams = new Set(option.subteamsId);
                selectedIds = selectedIds.filter(
                    (id) => !subTeams.has(id) && newId !== id
                );
            } else {
                selectedIds = selectedIds.filter((id) => id !== newId);
            }
        } else {
            if (option.hasSubteams) {
                selectedIds = selectedIds.concat(option.subteamsId);
            } else {
                selectedIds.push(newId);
            }
        }
        setSelectedTeams(Array.from(new Set(selectedIds)));
    };
    return (
        <>
            <StyledFilterBox
                onClick={handleClick}
                aria-describedby={id}
                sx={filterBoxStyles}
            >
                <TeamSelectorSvg />
                <Label
                    isEllipses
                    hasTooltip
                    colorType={filterStyle === "custom" ? "666" : "white"}
                    className="flex-1 font-medium"
                    variant="medium"
                    tooltipNode={
                        <Box>
                            {state[selectedTeamsKey].length === 0
                                ? isAccountDashboard
                                    ? "All Owner Teams"
                                    : "All Teams"
                                : teamsList
                                      ?.filter(({ id }) => {
                                          return state[
                                              selectedTeamsKey
                                          ].includes(id);
                                      })
                                      .map(({ name, id }, idx, arr) => (
                                          <span key={id}>{`${name}${
                                              idx !== arr.length - 1 ? ", " : ""
                                          }`}</span>
                                      ))}
                        </Box>
                    }
                >
                    {state[selectedTeamsKey].length === 0
                        ? isAccountDashboard
                            ? "All Owner Teams"
                            : "All Teams"
                        : analysedTeamsIds
                        ? (() => {
                              const selectedAnalysedTeams = state[
                                  selectedTeamsKey
                              ].filter((id) => analysedTeamsIds.includes(id));
                              if (selectedAnalysedTeams.length === 1) {
                                  return teamsList?.find(
                                      ({ id }) =>
                                          id === selectedAnalysedTeams[0]
                                  )?.name;
                              }
                              return `Teams(${selectedAnalysedTeams.length})`;
                          })()
                        : state[selectedTeamsKey].length === 1
                        ? teamsList?.find(
                              ({ id }) => id === state[selectedTeamsKey][0]
                          )?.name
                        : `Teams(${state[selectedTeamsKey].length})`}
                </Label>

                <Box {...(open && { className: "rotate-180" })}>
                    <TrailingSvg />
                </Box>
            </StyledFilterBox>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ ...filterPopoverSx(theme, "250px") }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {isLoading ? (
                    <SkeletonLoader />
                ) : analysedTeamsIds && !analysedTeamsIds.length ? (
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        <Typography variant="medium" className="font-bold">
                            No Teams Found!
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <SearchField
                            value={searchValue}
                            handleChange={setSearchValue}
                            placeholder={`Search ${
                                isAccountDashboard ? "Owner Teams" : "Teams"
                            }`}
                        />
                        <ListItemButton
                            sx={{
                                p: 0,
                                pt: 1,
                            }}
                            onClick={() => {
                                setSelectedTeams([]);
                            }}
                        >
                            <Checkbox checked={selectedTeams.length === 0} />
                            <ListItemText
                                primary={
                                    isAccountDashboard
                                        ? "All Owner Teams"
                                        : "All Teams"
                                }
                            />
                        </ListItemButton>
                        <Box
                            className="flex-1"
                            sx={{
                                height: "300px",
                                overflow: "auto",
                            }}
                        >
                            <VirtualList<
                                TeamTreeSelectOption,
                                {
                                    onClick: (
                                        id: number,
                                        reason: "add" | "remove"
                                    ) => void;
                                    selected: number[];
                                }
                            >
                                Component={LabelWithChekbox}
                                data={filteredTeams || []}
                                hasNext={false}
                                fetchNext={() => {
                                    return;
                                }}
                                isLoading={false}
                                isFetching={false}
                                sx={{
                                    p: 0,
                                }}
                                onClick={handleChange}
                                selected={selectedTeams}
                            />
                        </Box>

                        <Stack
                            direction="row-reverse"
                            sx={{
                                p: 1.5,
                            }}
                        >
                            <Button
                                variant="global"
                                sx={{ px: 1 }}
                                className="capitalize"
                                onClick={() => {
                                    updateState({
                                        [selectedTeamsKey]: selectedTeams,
                                        [selectedRepsKey]: [],
                                    });
                                    handleTemplateSelection(
                                        selectedTeams,
                                        state.meetingType!
                                    );
                                    handleClose();
                                }}
                            >
                                Apply
                            </Button>
                            <Button
                                variant="text"
                                className="capitalize"
                                onClick={() => {
                                    setSelectedTeams([]);
                                }}
                            >
                                Clear all
                            </Button>
                        </Stack>
                    </>
                )}
            </CustomPopover>
        </>
    );
}
