import { Box, Stack, Typography } from "@mui/material";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormProvider,
    RHFSwitch,
    RHFTextField,
} from "@convin/components/hook-form";
import { useReactFlowStateContextProvider } from "../hooks/useReactFlowStateContextProvider";
import type {
    ConditionBlockSingleResponseNode,
    MomentReferenceType,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../types/bert";
import BlockHeader from "./Blocks/BlockHeader";
import useNodeContext from "../hooks/useNodeContext";
import {
    generateCleanNanoID,
    isDefined,
} from "@convin/utils/helper/common.helper";
import { CloseSvg } from "@convin/components/svg";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import {
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../context/NodeStateContext";
import { showToast } from "@convin/utils/toast";
import { Node } from "reactflow";

export default function CreateEditConditionalNode({
    type,
}: {
    type: Exclude<MultiResponseNodeType, "response">;
}) {
    const { data: momentsObj } = useGetMomentsQuery();
    const {
        createMultipleResponseNode,
        updateMultipleResponseNode,
        setNodes,
        setEdges,
    } = useReactFlowStateContextProvider();
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeDataToUpdate,
        nodeIdToUpdate,
        blockId,
    } = useNodeContext();
    const schema = yup.object().shape({
        name: yup.string().required("Name is Required"),
        prompt:
            type === "condition_gpt"
                ? yup.string().required("Prompt is Required")
                : yup.string().nullable(),
        has_else_block: yup.boolean(),
        blocks: yup.array().of(
            yup.object().shape({
                isNew: yup.boolean(),
                data: yup.object().shape({
                    type: yup.string().nullable(),
                    metadata: yup.object().shape({
                        name: yup.string().required("Mandatory Field"),
                    }),
                }),
            })
        ),
    });

    const methods = useForm<MultiResponseBlockData<typeof type>>({
        resolver: yupResolver(schema),
        ...(isDefined(nodeDataToUpdate)
            ? {
                  values: {
                      ...(nodeDataToUpdate as MultiResponseBlockData<
                          typeof type
                      >),
                      blocks: (
                          nodeDataToUpdate as MultiResponseBlockData<
                              typeof type
                          >
                      ).blocks.map((e) => {
                          const { isNew: _, ...rest } = e;
                          const data =
                              e.data as ConditionBlockSingleResponseNode<SingleResponseBlockType>;
                          //You have to remove the name for moment type as moment is referenced (i.e it is created in backend)
                          return data.type === "moment"
                              ? {
                                    ...rest,
                                    data: {
                                        ...data,
                                        type: "moment",
                                        metadata: {
                                            ...data.metadata,
                                            name: momentsObj?.moments.find(
                                                (m) =>
                                                    m.id ===
                                                    (
                                                        data.metadata as MomentReferenceType
                                                    ).id
                                            )?.name,
                                        },
                                    },
                                }
                              : rest;
                      }),
                  },
              }
            : {
                  defaultValues: {
                      name: "",
                      has_else_block: false,
                      ...(type === "condition_gpt" && { prompt: "" }),
                      blocks: [
                          {
                              id: generateCleanNanoID(),
                              isNew: true,
                              data: {
                                  type: null,

                                  metadata: {
                                      name: "Block 1",
                                  },
                              },
                          },
                          {
                              id: generateCleanNanoID(),
                              isNew: true,
                              data: {
                                  type: null,

                                  metadata: {
                                      name: "Block 2",
                                  },
                              },
                          },
                      ],
                  },
              }),
    });

    const { control, handleSubmit, watch } = methods;

    const { fields, append, remove } = useFieldArray<
        MultiResponseBlockData<typeof type>,
        "blocks",
        "id"
    >({
        control,
        name: "blocks",
    });

    const onSubmit = async (payload: MultiResponseBlockData<typeof type>) => {
        if (isDefined(nodeIdToUpdate)) {
            const elseBlock = payload.blocks.find(
                (block) => block.data.type === "else"
            );
            // First filter out the else block if it exists
            const nonElseBlocks = payload.blocks.filter(
                (block) => block.data.type !== "else"
            );
            let elseBlockData:
                | MultiResponseBlockData<typeof type>["blocks"][number]
                | null = null;

            // Check if we need to handle an existing else block
            if (isDefined(elseBlock) && !payload.has_else_block) {
                // We're removing the else block
                if (nonElseBlocks.length === 1) {
                    return showToast({
                        message: "Please add another block to the node",
                        type: "error",
                    });
                }
                setNodes((nodes) =>
                    nodes.map(
                        (
                            node: Node<
                                MultipleResponseNode<MultiResponseNodeType>
                            >
                        ) =>
                            node.id === payload.id
                                ? {
                                      ...node,
                                      data: {
                                          ...node.data,
                                          metadata: {
                                              ...node.data.metadata,
                                              blocks: node.data.metadata.blocks.map(
                                                  (b) =>
                                                      b.id !== elseBlock?.id
                                                          ? b
                                                          : {
                                                                ...b,
                                                                data: {
                                                                    type: null,
                                                                    is_not: false,
                                                                    metadata: {
                                                                        name:
                                                                            b
                                                                                .data
                                                                                .metadata
                                                                                .name ??
                                                                            "Untitled",
                                                                    },
                                                                },
                                                            }
                                              ),
                                          },
                                      },
                                  }
                                : node
                    )
                );
                setEdges((edges) =>
                    edges.filter(
                        (e) =>
                            e.sourceHandle !==
                            `${elseBlock?.id}_${elseBlock?.sourcePosition}`
                    )
                );
            } else if (isDefined(elseBlock) && payload.has_else_block) {
                // Keep the existing else block but will reposition it at the end
                elseBlockData = elseBlock;
            } else if (!isDefined(elseBlock) && payload.has_else_block) {
                // Create a new else block
                elseBlockData = {
                    id: generateCleanNanoID(),
                    parent: null,
                    sourcePosition: null,
                    isNew: true,
                    data: {
                        type: "else",
                        is_not: false,
                        metadata: {
                            name: "Else Path",
                        },
                    },
                };
            }

            // Prepare the final blocks array with else at the end if needed
            let finalBlocks = nonElseBlocks;
            if (isDefined(elseBlockData) && payload.has_else_block) {
                finalBlocks = [...nonElseBlocks, elseBlockData];
            }

            updateMultipleResponseNode({
                sourceId: nodeIdToUpdate,
                data: {
                    ...payload,
                    blocks: finalBlocks.map(({ isNew: _, ...e }) => {
                        const data =
                            e.data as ConditionBlockSingleResponseNode<SingleResponseBlockType>;

                        return data.type === "moment"
                            ? {
                                  ...e,
                                  data: {
                                      ...data,
                                      type: "moment",
                                      //You have to remove the name for moment type as moment is referenced (i.e it is created in backend)
                                      metadata: {
                                          ...data.metadata,
                                          name: "",
                                      },
                                  },
                              }
                            : e;
                    }),
                },
            });
        } else {
            // For new node creation
            // First ensure non-else blocks come first
            const nonElseBlocks = payload.blocks.filter(
                (block) => block.data.type !== "else"
            );
            let finalBlocks = nonElseBlocks;

            // Add else block at the end if needed
            if (payload.has_else_block) {
                finalBlocks = [
                    ...nonElseBlocks,
                    {
                        id: generateCleanNanoID(),
                        parent: null,
                        sourcePosition: null,
                        isNew: true,
                        data: {
                            type: "else",
                            is_not: false,
                            metadata: {
                                name: "Else Path",
                            },
                        },
                    },
                ];
            }

            createMultipleResponseNode<
                MultipleResponseNode<Exclude<MultiResponseNodeType, "response">>
            >({
                data: {
                    type: type,
                    metadata: {
                        ...payload,
                        blocks: finalBlocks,
                    },
                },
                sourceId,
                direction,
                blockId,
            });
        }

        onNodeSaveCallBack();
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box className="w-full max-h-[350px] flex flex-col">
                <BlockHeader
                    title={
                        type === "condition"
                            ? "Conditional Block"
                            : "GPT CONDITION"
                    }
                    showCreateButton
                />
                <Stack
                    className="w-full flex-1 overflow-y-auto nowheel"
                    py={2.5}
                    gap={2}
                >
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Block Name"
                    />
                    {type === "condition_gpt" && (
                        <RHFTextField
                            name="prompt"
                            className="w-full"
                            variant="outlined"
                            size="small"
                            placeholder="Prompt"
                            label="Prompt"
                            multiline
                            rows={4}
                        />
                    )}
                    {fields.map((e, index) => {
                        return e.data.type !== "else" ? (
                            <Box key={e.id} className="flex items-start w-full">
                                <RHFTextField
                                    name={`blocks.${index}.data.metadata.name`}
                                    className="flex-1"
                                    variant="outlined"
                                    size="small"
                                    placeholder={`Block Name ${index + 1}`}
                                    disabled={!e.isNew}
                                />
                                {!isDefined(e?.data?.type) &&
                                    /* Create a local variable to avoid deep type instantiation */
                                    (() => {
                                        const hasElseBlock =
                                            watch("has_else_block");
                                        const minBlocks = hasElseBlock ? 1 : 2;
                                        return fields.length > minBlocks ? (
                                            <button
                                                className="flex-shrink-0 ml-2 mt-2"
                                                onClick={() => {
                                                    remove(index);
                                                }}
                                            >
                                                <CloseSvg />
                                            </button>
                                        ) : null;
                                    })()}
                            </Box>
                        ) : null;
                    })}
                </Stack>
                <div className="flex items-center justify-between">
                    <Typography variant="medium" className="font-semibold">
                        Else Path
                    </Typography>
                    <RHFSwitch name="has_else_block" />
                </div>
                <div>
                    <Typography
                        className="flex-shrink-0 font-medium"
                        variant="small"
                        color="primary"
                        component="button"
                        mt={1}
                        onClick={() => {
                            append({
                                id: generateCleanNanoID(),
                                parent: null,
                                sourcePosition: null,
                                isNew: true,
                                data: {
                                    type: null,
                                    is_not: false,
                                    metadata: {
                                        name: "",
                                    },
                                },
                            });
                        }}
                    >
                        add more condition
                    </Typography>
                </div>
            </Box>
        </FormProvider>
    );
}
