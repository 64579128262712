import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { PropsWithChildren, useEffect } from "react";
import useGlobalSearchContext from "../hooks/useGlobalSearchContext";
import { datekeys, durationkeys } from "@convin/config/default.config";
import { initialConversationListFilterState } from "@convin/config/conversation.config";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export default function GlobalSearchHandleWrapper({
    children,
}: PropsWithChildren) {
    const { handleAppliedButtonClick, updateState } =
        useGlobalFiltersStateContext();
    const { searchKeyword, searchIsDirty } = useGlobalSearchContext();
    const location = useLocation();
    const navigate = useNavigate();
    const route = matchPath<"dashboard_type", string>(
        "/:dashboard_type",
        location.pathname
    );

    const dashboard = Object.values(DashboardRoutesConfig).find(
        (e) => e.path === `/${route?.params?.dashboard_type}`
    );
    const isPathConversationList =
        dashboard?.path === DashboardRoutesConfig.Conversations.path;
    const isPathSettings =
        dashboard?.path === DashboardRoutesConfig.Settings.path;

    useEffect(() => {
        if (searchIsDirty.current) {
            if (!isPathConversationList && !isPathSettings) {
                navigate(DashboardRoutesConfig["Conversations"].to);
            }
            updateState({
                ...initialConversationListFilterState,
                template: null,
                dateKey: datekeys.all,
                durationKey: durationkeys.any,
                reps: [],
                teams: [],
                searchKeywords: {
                    saidByClient: false,
                    saidByOwner: false,
                    isInCall: true,
                    keywords: searchKeyword.length ? [searchKeyword] : [],
                },
                processingStatus: null,
            });
            handleAppliedButtonClick();
            searchIsDirty.current = false;
        }
    }, [searchKeyword]);

    return children;
}
