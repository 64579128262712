import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    TopicCategoryType,
    TopicFormType,
    TopicType,
} from "@convin/type/Topic";

export const topicManagerApiSlice = createApi({
    reducerPath: "topicManagerServiceSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["TopicManager", "TopicManagerCategories"],
    endpoints: (builder) => ({
        getTopics: builder.query<TopicType[], void>({
            query: () => "/topic/topic/list_all/",
            providesTags: ["TopicManager"],
        }),
        getTopicCategories: builder.query<TopicCategoryType[], void>({
            query: () => "/topic/topic/category/list_all/",
            providesTags: ["TopicManagerCategories"],
        }),
        createTopic: builder.mutation<TopicType, Partial<TopicFormType>>({
            query: (payload) => ({
                url: "/topic/topic/create/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["TopicManager", "TopicManagerCategories"],
        }),
        editTopicById: builder.mutation<
            TopicType,
            Partial<TopicFormType> & { id: number }
        >({
            query: ({ id, ...payload }) => ({
                url: `/topic/topic/update/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        topicManagerApiSlice.util.updateQueryData(
                            "getTopics",
                            undefined,
                            (draft) =>
                                draft.map((e) => (e.id === id ? data : e))
                        )
                    );
                    dispatch(
                        topicManagerApiSlice.util.updateQueryData(
                            "getTopicCategories",
                            undefined,
                            (draft) => {
                                if (
                                    draft.find(
                                        (e) => e.id === data.category?.id
                                    )
                                )
                                    return draft;
                                return [...draft, data.category!];
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        deleteTopicById: builder.mutation<void, number>({
            query: (id) => ({
                url: `/topic/topic/delete/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const deleteResult = dispatch(
                    topicManagerApiSlice.util.updateQueryData(
                        "getTopics",
                        undefined,
                        (draft) => draft.filter((e) => e.id !== id)
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetTopicCategoriesQuery,
    useGetTopicsQuery,
    useDeleteTopicByIdMutation,
    useCreateTopicMutation,
    useEditTopicByIdMutation,
} = topicManagerApiSlice;
