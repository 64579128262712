import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetConferenceToolsQuery } from "@convin/redux/services/meeting/filters.service";

const RecordingMediumFilter = () => {
    const {
        data: recordingMediumList,
        isLoading: isRecordingMediumListLoading,
    } = useGetConferenceToolsQuery();

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    return (
        <GenericSingleSelect
            label="Conversation Recording Medium"
            autocompleteProps={{
                options: [
                    ...new Set(
                        recordingMediumList?.map((medium) => ({
                            id: medium,
                            label: medium,
                        })) || []
                    ),
                ],
                disableClearable: false,
            }}
            value={filtersState.recordingMedium}
            setValue={(e) => {
                updateFiltersState({
                    recordingMedium: e,
                });
            }}
            className="flex-1"
            loading={isRecordingMediumListLoading}
        />
    );
};

export default RecordingMediumFilter;
