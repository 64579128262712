import { SvgIcon, SxProps, Theme, useTheme } from "@mui/material";
import { ReactElement } from "react";

export default function NoCommentsSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    const {
        palette: {
            primary: { main },
        },
    } = useTheme();

    return (
        <SvgIcon
            viewBox="0 0 122 88"
            sx={{ width: 122, height: 88, fill: "none", ...sx }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 0C2.23858 0 0 2.23858 0 5V31.9108C0 34.6722 2.23858 36.9108 5 36.9108H8.54837L11.4055 41.8594C11.7904 42.5261 12.7526 42.5261 13.1375 41.8594L15.9946 36.9108H93.8931C96.6545 36.9108 98.8931 34.6722 98.8931 31.9108V5C98.8931 2.23858 96.6545 0 93.8931 0H5Z"
                fill="#F5F4F4"
            />
            <rect
                x="35.1519"
                y="7.66016"
                width="55.018"
                height="4.17858"
                rx="2.08929"
                fill="#BDC0C7"
            />
            <rect
                x="35.1519"
                y="14.625"
                width="39.6965"
                height="4.17858"
                rx="2.08929"
                fill="#BEC3C9"
            />
            <rect
                x="35.1519"
                y="25.0713"
                width="15.3215"
                height="4.17858"
                rx="2.08929"
                fill="#DCDDE1"
            />
            <circle
                cx="18.4379"
                cy="16.7137"
                r="9.05359"
                fill="url(#paint0_linear_2474:5945)"
            />
            <path
                d="M16.15 16.299C15.8642 16.0311 15.4154 16.0455 15.1474 16.3313C14.8795 16.6171 14.894 17.066 15.1798 17.334L17.4496 19.462C17.7542 19.7475 18.2383 19.7095 18.4947 19.38L22.4669 14.2728C22.7074 13.9636 22.6517 13.5179 22.3425 13.2774C22.0333 13.0369 21.5876 13.0926 21.3471 13.4018L17.8524 17.895L16.15 16.299Z"
                fill="#F5F4F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M116.271 45.5244C119.032 45.5244 121.271 47.763 121.271 50.5244V77.4205C121.271 80.1819 119.032 82.4205 116.271 82.4205H112.722L109.865 87.3675C109.48 88.034 108.518 88.034 108.133 87.3675L105.276 82.4205H27.3774C24.616 82.4205 22.3774 80.1819 22.3774 77.4205V50.5244C22.3774 47.763 24.616 45.5244 27.3774 45.5244H116.271Z"
                fill="#F5F4F4"
            />
            <rect
                x="58.1904"
                y="54.9092"
                width="51.8053"
                height="3.93458"
                rx="1.96729"
                fill="#BDC0C7"
            />
            <rect
                x="58.1904"
                y="61.4668"
                width="37.3785"
                height="3.93458"
                rx="1.96729"
                fill="#BEC3C9"
            />
            <rect
                x="58.1904"
                y="71.3027"
                width="14.4268"
                height="3.93458"
                rx="1.96729"
                fill="#DCDDE1"
            />
            <circle cx="42.4517" cy="63.4341" r="8.52492" fill="#AEAEAE" />
            <path
                d="M40.1042 63.6695C39.8351 63.4172 39.4124 63.4308 39.1601 63.6999C38.9078 63.9691 38.9215 64.3917 39.1906 64.644L41.3279 66.6478C41.6147 66.9166 42.0706 66.8809 42.3119 66.5706L46.0522 61.7616C46.2787 61.4704 46.2263 61.0508 45.9351 60.8243C45.6439 60.5978 45.2243 60.6503 44.9978 60.9415L41.7072 65.1723L40.1042 63.6695Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2474:5945"
                    x1="27.4915"
                    y1="19.8477"
                    x2="9.38428"
                    y2="13.928"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={main} />
                    <stop offset="1" stopColor="#9BBCFF" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
