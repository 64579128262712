import MailSentSvg from "@convin/components/svg/MailSentSvg";
import { DashboardRoutesConfig } from "@convin/config/routes.config";
import useSearchQuery from "@convin/hooks/useSearchQuery";
import { pxToRem } from "@convin/utils/getFontValue";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function SuccessMail() {
    const query = useSearchQuery();
    const isSignin = query.get("signin") === "true";
    return (
        <div className="flex flex-col items-center justify-center gap-2">
            <MailSentSvg sx={{ color: "primary.main", scale: 0.8 }} />
            <Typography
                sx={{
                    fontSize: pxToRem(28),
                    fontWeight: 600,
                }}
            >
                Mail sent successfully
            </Typography>
            <Typography
                sx={{
                    fontSize: pxToRem(16),
                    fontWeight: 400,
                }}
            >
                Please open mail sent to you and verify your mail id.
            </Typography>
            {isSignin && (
                <Box className="flex items-center justify-center" mt={2}>
                    <Link to={DashboardRoutesConfig["Signin"].to}>
                        <Typography
                            variant="medium"
                            color="primary.main"
                            className="font-semibold"
                        >
                            Back to Sign in
                        </Typography>
                    </Link>
                </Box>
            )}
        </div>
    );
}
