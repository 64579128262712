import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export default function CloneSvg({ sx, ...rest }: SvgIconProps): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 16, height: 16, fill: "none", ...sx }}
            {...rest}
        >
            <path
                d="M4.8 21C4.305 21 3.88125 20.8237 3.52875 20.4712C3.17625 20.1187 3 19.695 3 19.2H4.8V21ZM3 17.4V15.6H4.8V17.4H3ZM3 13.8V12H4.8V13.8H3ZM3 10.2V8.4H4.8V10.2H3ZM3 6.6C3 6.105 3.17625 5.68125 3.52875 5.32875C3.88125 4.97625 4.305 4.8 4.8 4.8V6.6H3ZM6.6 21V19.2H8.4V21H6.6ZM10.2 21V19.2H12V21H10.2ZM13.8 21V19.2H15.6V21H13.8ZM17.4 21V19.2H19.2C19.2 19.695 19.0237 20.1187 18.6712 20.4712C18.3187 20.8237 17.895 21 17.4 21ZM8.4 17.4C7.905 17.4 7.48125 17.2237 7.12875 16.8712C6.77625 16.5187 6.6 16.095 6.6 15.6V4.8C6.6 4.305 6.77625 3.88125 7.12875 3.52875C7.48125 3.17625 7.905 3 8.4 3H19.2C19.695 3 20.1187 3.17625 20.4712 3.52875C20.8237 3.88125 21 4.305 21 4.8V15.6C21 16.095 20.8237 16.5187 20.4712 16.8712C20.1187 17.2237 19.695 17.4 19.2 17.4H8.4ZM8.4 15.6H19.2V8.4H12.9V4.8H8.4V15.6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
