import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function MeetingSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            sx={{
                width: 16,
                height: 16,
                fill: "none",
                ...sx,
            }}
        >
            <mask
                id="mask0_32489_1771"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <rect width="16" height="16" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_32489_1771)">
                <path
                    d="M11.8512 13.0492C11.6946 13.2058 11.4405 13.2053 11.2845 13.0482L9.49817 11.2492C9.3429 11.0928 9.34334 10.8403 9.49916 10.6845L9.86781 10.3159C10.024 10.1596 10.2773 10.1596 10.4335 10.3159L11.2845 11.1668C11.4407 11.323 11.694 11.323 11.8502 11.1668L14.1153 8.9017C14.2725 8.74451 14.5277 8.74564 14.6835 8.90421L15.0561 9.28352C15.21 9.44012 15.2089 9.69146 15.0536 9.84669L11.8512 13.0492ZM2.01683 13.9835C1.76484 14.2355 1.33398 14.057 1.33398 13.7007V2.66634C1.33398 2.29967 1.46454 1.98579 1.72565 1.72467C1.98676 1.46356 2.30065 1.33301 2.66732 1.33301H13.334C13.7007 1.33301 14.0145 1.46356 14.2757 1.72467C14.5368 1.98579 14.6673 2.29967 14.6673 2.66634V6.93301C14.6673 7.15392 14.4882 7.33301 14.2673 7.33301H8.40065C8.17974 7.33301 8.00065 7.51209 8.00065 7.73301V11.5997C8.00065 11.8206 7.82156 11.9997 7.60065 11.9997H4.16634C4.06025 11.9997 3.95851 12.0418 3.88349 12.1168L2.01683 13.9835Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
