import { toOptions } from "@convin/components/custom_components/TreeSelect/MuiTeamSelector";
import GenericMultipleSelect from "@convin/components/select/GenericMultipleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import { useGetTeamsQuery } from "@convin/redux/services/settings/teamManager.service";
import { TeamTreeSelectOption } from "@convin/type/Common";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Checkbox, ListItemText } from "@mui/material";
import { useMemo } from "react";

interface OptionWithLabel extends TeamTreeSelectOption {
    label: string;
}

const AccountTeamsFilter = () => {
    const { data: teams } = useGetTeamsQuery();
    const { state, updateState } = useGlobalFiltersStateContext();

    const flattenedTeams: OptionWithLabel[] = useMemo(() => {
        const options = teams?.flatMap((category) => toOptions(category)) || [];
        return options.map((team) => ({ ...team, label: team.name }));
    }, [teams]);

    const teamsList = teams?.flatMap((category) => toOptions(category));

    const handleSelectAll = () => {
        const newSelectedTeams =
            state.teams.length === flattenedTeams.length
                ? []
                : flattenedTeams.map((team) => team.id);

        updateState({
            teams: newSelectedTeams,
            reps: [],
        });
    };

    const handleChange = (teamId: number, action: "add" | "remove"): void => {
        let selectedIds = [...state.teams];
        const option = teamsList?.find((team) => team.id === teamId);
        if (!isDefined(option)) return;
        if (action === "remove") {
            if (option.hasSubteams) {
                const subTeams = new Set(option.subteamsId);
                selectedIds = selectedIds.filter(
                    (id) => !subTeams.has(id) && teamId !== id
                );
            } else {
                selectedIds = selectedIds.filter((id) => id !== teamId);
            }
        } else {
            if (option.hasSubteams) {
                selectedIds = selectedIds.concat(option.subteamsId);
            } else {
                selectedIds.push(teamId);
            }
        }
        updateState({
            teams: Array.from(new Set(selectedIds)),
            reps: [],
        });
    };

    const mappedTeams: OptionWithLabel[] = flattenedTeams.map((team) => ({
        ...team,
        label: team.name,
    }));

    return (
        <GenericMultipleSelect<OptionWithLabel>
            label="Select Teams"
            values={state.teams}
            setValues={(newSelectedTeams) =>
                updateState({
                    teams: newSelectedTeams,
                    reps: [],
                })
            }
            autocompleteProps={{
                options: mappedTeams,
                renderOption: (props, option) => {
                    const selectedSet = new Set(state.teams);
                    const allSubteamsSelected = option?.hasSubteams
                        ? option?.subteamsId.every((id) => selectedSet.has(id))
                        : false;
                    const checked =
                        state.teams.includes(option.id) || allSubteamsSelected;
                    const handleOptionClick = () => {
                        if (option.id === -1) {
                            handleSelectAll();
                        } else {
                            handleChange(option.id, checked ? "remove" : "add");
                        }
                    };
                    return (
                        <li
                            {...props}
                            style={{
                                paddingLeft: `${(option.depth + 1) * 16}px`,
                                margin: "4px",
                            }}
                            onClick={handleOptionClick}
                        >
                            <Checkbox
                                checked={
                                    option.id === -1
                                        ? state.teams.length ===
                                          flattenedTeams.length
                                        : checked
                                }
                                style={{ padding: 0, marginRight: 8 }}
                            />
                            <ListItemText primary={option.label} />
                        </li>
                    );
                },
            }}
        />
    );
};

export default AccountTeamsFilter;
