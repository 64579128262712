import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function DurationSelectorSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 19 18"
            sx={{
                width: 19,
                height: 18,
                fill: "none",
                color: "#666666",
                ...sx,
            }}
        >
            <mask
                id="mask0_14847_35898"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="18"
            >
                <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_14847_35898)">
                <path
                    d="M7.30625 14.85C5.78125 14.525 4.51562 13.75 3.50938 12.525C2.50313 11.3 2 9.875 2 8.25C2 6.375 2.65625 4.78125 3.96875 3.46875C5.28125 2.15625 6.875 1.5 8.75 1.5C10.625 1.5 12.2188 2.15625 13.5312 3.46875C14.8438 4.78125 15.5 6.375 15.5 8.25C15.5 8.7875 15.4375 9.30938 15.3125 9.81563C15.1875 10.3219 15.0125 10.8 14.7875 11.25H10.2125C9.3875 11.25 8.6875 11.5437 8.1125 12.1313C7.5375 12.7188 7.25 13.425 7.25 14.25C7.25 14.35 7.25313 14.45 7.25938 14.55C7.26562 14.65 7.28125 14.75 7.30625 14.85ZM10.25 10.8L11.3 9.75L9.5 7.95V4.5H8V8.55L10.25 10.8ZM10.25 15.75H14.75C15.1625 15.75 15.5156 15.6031 15.8094 15.3094C16.1031 15.0156 16.25 14.6625 16.25 14.25C16.25 13.8375 16.1031 13.4844 15.8094 13.1906C15.5156 12.8969 15.1625 12.75 14.75 12.75H10.25C9.8375 12.75 9.48438 12.8969 9.19063 13.1906C8.89688 13.4844 8.75 13.8375 8.75 14.25C8.75 14.6625 8.89688 15.0156 9.19063 15.3094C9.48438 15.6031 9.8375 15.75 10.25 15.75ZM10.25 15C10.0375 15 9.85938 14.9281 9.71563 14.7844C9.57188 14.6406 9.5 14.4625 9.5 14.25C9.5 14.0375 9.57188 13.8594 9.71563 13.7156C9.85938 13.5719 10.0375 13.5 10.25 13.5C10.4625 13.5 10.6406 13.5719 10.7844 13.7156C10.9281 13.8594 11 14.0375 11 14.25C11 14.4625 10.9281 14.6406 10.7844 14.7844C10.6406 14.9281 10.4625 15 10.25 15ZM12.5 15C12.2875 15 12.1094 14.9281 11.9656 14.7844C11.8219 14.6406 11.75 14.4625 11.75 14.25C11.75 14.0375 11.8219 13.8594 11.9656 13.7156C12.1094 13.5719 12.2875 13.5 12.5 13.5C12.7125 13.5 12.8906 13.5719 13.0344 13.7156C13.1781 13.8594 13.25 14.0375 13.25 14.25C13.25 14.4625 13.1781 14.6406 13.0344 14.7844C12.8906 14.9281 12.7125 15 12.5 15ZM14.75 15C14.5375 15 14.3594 14.9281 14.2156 14.7844C14.0719 14.6406 14 14.4625 14 14.25C14 14.0375 14.0719 13.8594 14.2156 13.7156C14.3594 13.5719 14.5375 13.5 14.75 13.5C14.9625 13.5 15.1406 13.5719 15.2844 13.7156C15.4281 13.8594 15.5 14.0375 15.5 14.25C15.5 14.4625 15.4281 14.6406 15.2844 14.7844C15.1406 14.9281 14.9625 15 14.75 15Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
