import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function SavingSvg({
    sx,
    disabledAnimation = false,
}: {
    sx?: SxProps<Theme>;
    disabledAnimation?: boolean;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 14"
            sx={{ width: 20, height: 14, fill: "none", ...sx }}
            className={disabledAnimation ? "" : "animate-spin"}
        >
            <path
                d="M10.0413 13.6666C8.18023 13.6666 6.5969 13.0208 5.29134 11.7291C3.98579 10.4375 3.33301 8.86109 3.33301 6.99998V6.85415L1.99967 8.18748L0.833008 7.02081L4.16634 3.68748L7.49967 7.02081L6.33301 8.18748L4.99967 6.85415V6.99998C4.99967 8.38887 5.48926 9.56942 6.46842 10.5416C7.44759 11.5139 8.63856 12 10.0413 12C10.4025 12 10.7566 11.9583 11.1038 11.875C11.4511 11.7916 11.7913 11.6666 12.1247 11.5L13.3747 12.75C12.8469 13.0555 12.3052 13.2847 11.7497 13.4375C11.1941 13.5903 10.6247 13.6666 10.0413 13.6666ZM15.833 10.3125L12.4997 6.97915L13.6663 5.81248L14.9997 7.14581V6.99998C14.9997 5.61109 14.5101 4.43054 13.5309 3.45831C12.5518 2.48609 11.3608 1.99998 9.95801 1.99998C9.5969 1.99998 9.24273 2.04165 8.89551 2.12498C8.54829 2.20831 8.20801 2.33331 7.87467 2.49998L6.62467 1.24998C7.15245 0.944424 7.69412 0.715257 8.24968 0.56248C8.80523 0.409702 9.37467 0.333313 9.95801 0.333313C11.8191 0.333313 13.4025 0.979146 14.708 2.27081C16.0136 3.56248 16.6663 5.13887 16.6663 6.99998V7.14581L17.9997 5.81248L19.1663 6.97915L15.833 10.3125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
