import { useEffect } from "react";
import useSearchQuery from "./useSearchQuery";
import useAuth from "./useAuth";

export default function useSetAuthTokens() {
    const query = useSearchQuery();
    const { initialize } = useAuth();

    useEffect(() => {
        const access = query.get("token");
        const refresh = query.get("refreshToken");

        if (access && refresh) {
            localStorage.setItem(
                "authTokens",
                JSON.stringify({
                    access,
                    refresh,
                })
            );
        }
        initialize();
    }, [query]);
}
