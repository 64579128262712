import { StyledGridRow } from "@convin/components/custom_components/DataTable/styles";
import FiltersPopover from "@convin/components/custom_components/Filters/FiltersPopover";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import EmptyState from "@convin/components/custom_components/reuseable/EmptyState";
import { Label } from "@convin/components/custom_components/Typography/Label";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import EmptyDataSvg from "@convin/components/svg/EmptyDataSvg";
import { FilterSvg } from "@convin/components/svg/FilterSvg";
import SearchSvg from "@convin/components/svg/SearchSvg";
import SortingSvg from "@convin/components/svg/SortingSvg";
import useHandleFiltersPopoverState from "@convin/hooks/useHandleFiltersPopoverState";
import { useGetAllResourcesQuery } from "@convin/redux/services/lms/lmsKnowledgeBase.service";
import { LMSMedia } from "@convin/type/lms";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    alpha,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import FileCard from "./FileCard";
import { CloseSvg } from "@convin/components/svg";
import { useAttachLmsDocuments } from "./context/AttachLmsDocumentsContext";
import { pxToRem } from "@convin/utils/getFontValue";
import AttachLmsDocumentsProvider from "./context/AttachLmsDocumentsProvider";
import CustomSkeletonLoader from "@convin/components/custom_components/Skeleton";

const UI: React.FC<{
    handleUpload: (value: number[]) => void;
    onClose: () => void;
}> = ({ handleUpload, onClose }) => {
    const { value } = useAttachLmsDocuments();
    const [sortingIndex, setSortingIndex] = useState(0);
    const filterComponentProps = useHandleFiltersPopoverState<string>({
        columnKeys: ["file_type", "owners"],
    });
    const [searchTerm, setSearchTerm] = useState("");

    const { data: documents, isLoading: isLmsDocsLoading } =
        useGetAllResourcesQuery({
            file_ext_type: [".txt", ".pdf", ".doc", ".csv"],
            allow_pagination: false,
            filename: searchTerm,
        });

    const fileTypeFilterData = useMemo(() => {
        if (documents) {
            return Object.fromEntries(
                documents.map(({ id, file_type }) => [file_type, id])
            );
        }
        return {};
    }, [documents]);

    const { setAnchorPosition, handleFilterToShow, activeFilters } =
        filterComponentProps;

    const sortingTypes: Array<"asc" | "desc" | "none"> = [
        "none",
        "asc",
        "desc",
    ];

    const formattedData = useMemo(() => {
        if (!isDefined(documents)) return [];
        const d = documents.filter((curr) => {
            return (
                (activeFilters["owners"].length
                    ? activeFilters["owners"].includes(curr.owner.id)
                    : true) &&
                (activeFilters["file_type"].length
                    ? activeFilters["file_type"].includes(curr.file_type)
                    : true) &&
                (searchTerm
                    ? curr.file_name
                          .toLowerCase()
                          ?.includes(searchTerm.toLowerCase())
                    : true)
            );
        });

        if (sortingIndex) {
            d.sort((a, b) => {
                const min = sortingTypes[sortingIndex] === "asc" ? a : b;
                const max = sortingTypes[sortingIndex] === "asc" ? b : a;
                return (
                    new Date(min.created).getMilliseconds() -
                    new Date(max.created).getMilliseconds()
                );
            });
        }

        return d;
    }, [documents, activeFilters, searchTerm, sortingIndex]);

    const createFilterData = (columnKey: string) => {
        let filterDataToBePassed: string[] = [];
        if (isDefined(formattedData)) {
            switch (columnKey) {
                case "file_type":
                    filterDataToBePassed = Object.keys(fileTypeFilterData);
                    break;
                default:
                    break;
            }
        }
        return filterDataToBePassed;
    };

    return (
        <>
            <DialogContent className="flex-1">
                <Box sx={{ mt: 1 }} className="flex flex-col flex-1 w-full">
                    <StyledGridRow
                        container
                        className="flex-shrink-0 rounded-t-md w-full"
                    >
                        <Grid2
                            className="flex items-center gap-1"
                            size={{ xs: 5 }}
                        >
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Search File Name"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                slotProps={{
                                    input: {
                                        sx: { fontSize: 12 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchSvg
                                                    sx={{ color: "grey.999" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                                sx={{
                                    width: "264px",
                                    backgroundColor: (theme) =>
                                        alpha(
                                            theme.palette.textColors[999],
                                            0.1
                                        ),
                                    borderRadius: 0.75,
                                    "& .MuiInputBase-input": {
                                        padding: "8px 8px 8px 0px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: 0,
                                    },
                                }}
                            />
                        </Grid2>
                        <Grid2
                            className="flex items-center"
                            size={{ xs: 2 }}
                            textAlign="left"
                        >
                            <Label colorType="333" variant="textXs" mr={1}>
                                File Type
                            </Label>
                            <button
                                onClick={(e) => {
                                    setAnchorPosition(e);
                                    handleFilterToShow(
                                        "file_type",
                                        createFilterData("file_type")
                                    );
                                }}
                            >
                                <FilterSvg
                                    sx={{
                                        color:
                                            activeFilters["file_type"]?.length >
                                            0
                                                ? "primary.main"
                                                : "grey.999",
                                    }}
                                />
                            </button>
                        </Grid2>
                        <Grid2
                            className="flex items-center"
                            size={{ xs: 3 }}
                            textAlign="left"
                        >
                            <Label colorType="333" variant="textXs">
                                Created Date
                            </Label>
                            <Box
                                className="cursor-pointer"
                                sx={{
                                    ml: 1,
                                }}
                                onClick={() => {
                                    setSortingIndex((prev) => ++prev % 3);
                                }}
                            >
                                <SortingSvg
                                    type={sortingTypes[sortingIndex]}
                                    colorType="primary"
                                />
                            </Box>
                        </Grid2>
                        <Grid2
                            className="flex items-center"
                            size={{ xs: 2 }}
                            textAlign="left"
                        >
                            <Label colorType="333" variant="textXs" mr={1}>
                                Added By
                            </Label>
                            <button
                                onClick={(e) => {
                                    setAnchorPosition(e);
                                    handleFilterToShow("owners");
                                }}
                            >
                                <FilterSvg
                                    sx={{
                                        color:
                                            activeFilters["owners"]?.length > 0
                                                ? "primary.main"
                                                : "grey.999",
                                    }}
                                />
                            </button>
                        </Grid2>
                    </StyledGridRow>
                    {isLmsDocsLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <div className="flex-1 overflow-y-auto">
                            {formattedData?.length ? (
                                <VirtualList<LMSMedia>
                                    Component={FileCard}
                                    data={formattedData}
                                    hasNext={false}
                                    fetchNext={() => {}}
                                    isLoading={false}
                                    isFetching={false}
                                    sx={{
                                        p: 0,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        height: "20rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <EmptyState
                                        text={"No Files Found!"}
                                        Component={<EmptyDataSvg />}
                                    />
                                </Box>
                            )}
                        </div>
                    )}
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    pb: 2,
                }}
                className="flex-shrink-0"
            >
                <Button
                    variant="globalOutlined"
                    className="w-[112px]"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    className="normal-case w-[112px]"
                    variant="global"
                    type="button"
                    onClick={() => {
                        handleUpload(value);
                        onClose();
                    }}
                >
                    Upload
                </Button>
            </DialogActions>
            {<FiltersPopover {...filterComponentProps} />}
        </>
    );
};
export default function AttachLmsDocuments({
    handleUpload,
    initialValue,
}: {
    handleUpload: (value: number[]) => void;
    initialValue: number[];
}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const onClose = () => setOpen(false);
    const { data: documents, isLoading: isLmsDocsLoading } =
        useGetAllResourcesQuery({
            file_ext_type: [".txt", ".pdf", ".doc", ".csv"],
            allow_pagination: false,
        });

    return (
        <>
            <Box
                onClick={() => setOpen(true)}
                component="button"
                className="block text-left"
                sx={{
                    fontSize: pxToRem(14),
                    my: 2,
                }}
                color="primary.main"
                type="button"
            >
                <AttachFileIcon
                    sx={{ fontSize: pxToRem(20), transform: "rotate(45deg)" }}
                />
                Attach files from LMS
            </Box>
            {isLmsDocsLoading ? (
                <CustomSkeletonLoader rows={1} />
            ) : (
                <Grid2
                    container
                    spacing={1}
                    sx={{ mb: initialValue.length > 0 ? 2 : 0 }}
                >
                    {documents
                        ?.filter((e) => initialValue.includes(e.id))
                        .map((e) => (
                            <Grid2
                                size={{ xs: 4 }}
                                component={Chip}
                                sx={{
                                    "&.MuiButtonBase-root": {
                                        backgroundColor: alpha(
                                            theme.palette.primary.main,
                                            0.1
                                        ),
                                        color: theme.palette.primary.main,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                                color="primary"
                                label={
                                    <Label
                                        hasTooltip
                                        isEllipses
                                        colorType="primary"
                                        variant="small"
                                    >
                                        {`${e.file_name}.${e.file_type}`}
                                    </Label>
                                }
                                key={e.id}
                                onDelete={() => {
                                    handleUpload(
                                        initialValue.filter((id) => id !== e.id)
                                    );
                                }}
                            />
                        ))}
                </Grid2>
            )}
            {open && (
                <AttachLmsDocumentsProvider initialValue={initialValue}>
                    <Dialog
                        open={open}
                        onClose={onClose}
                        sx={{
                            "& .MuiDialog-paper": {
                                width: "1134px",
                                maxWidth: "1134px",
                                borderRadius: "12px",
                            },
                        }}
                        className="flex flex-col"
                    >
                        <DialogTitle
                            className="flex items-center justify-between flex-shrink-0"
                            sx={{ px: 3, py: 2.5 }}
                        >
                            <Typography variant="h4">Select Files</Typography>
                            <button onClick={onClose} type="button">
                                <CloseSvg />
                            </button>
                        </DialogTitle>
                        <Divider />

                        <UI handleUpload={handleUpload} onClose={onClose} />
                    </Dialog>
                </AttachLmsDocumentsProvider>
            )}
        </>
    );
}
