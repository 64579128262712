import { Box, Popover, Typography } from "@mui/material";
import AddButton from "../AddButton";
import { PropsWithChildren } from "react";
import BertCardWrapper from "../BertCardWrapper";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import useAnchorEl from "@convin/hooks/useAnchorEl";

export default function CreatorNode({ children }: PropsWithChildren) {
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const { anchorEl, handleClick, handleClose, open } =
        useAnchorEl<HTMLDivElement>();
    return (
        <>
            <Box className={isGroupBlockTree ? "nodrag nopan" : ""}>
                <Box className="flex justify-center " gap={1}>
                    <Box>
                        <AddButton onClick={handleClick} />
                        {!isGroupBlockTree && open && (
                            <Box className="h-[50px] w-[100%] flex items-center justify-center">
                                <Box
                                    sx={{ borderLeft: "2px dashed #333" }}
                                    className="h-full"
                                />
                            </Box>
                        )}
                    </Box>

                    <Box className="flex flex-col">
                        <Typography variant="medium">Build</Typography>
                        <Typography variant="small" color="textColors.999">
                            Click here to start building your rule
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {isGroupBlockTree ? (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    classes={{
                        paper: "overflow-visible bg-transparent",
                    }}
                    sx={{
                        ".MuiPaper-root": {
                            minHeight: "0px !important",
                        },
                    }}
                >
                    <Box
                        className="relative"
                        sx={{
                            width: "420px",
                        }}
                    >
                        <BertCardWrapper
                            sx={{
                                bottom: "16px",
                                left: "0",
                                transform: "translate(0%,0%)",
                                position: "absolute",
                            }}
                            onClick={(e) => {
                                const target = e.target as HTMLElement;
                                if (
                                    target &&
                                    target.classList.contains(
                                        "block_close_button"
                                    )
                                ) {
                                    handleClose();
                                }
                            }}
                        >
                            {children}
                        </BertCardWrapper>
                    </Box>
                </Popover>
            ) : open ? (
                <Box
                    className="absolute flex items-center justify-center"
                    sx={{
                        width: "20px",
                        transform: "translateX(50%)",
                    }}
                >
                    <BertCardWrapper
                        className="flex flex-col relative"
                        gap={2}
                        onClick={(e) => {
                            const target = e.target as HTMLElement;
                            if (
                                target &&
                                target.classList.contains("block_close_button")
                            ) {
                                handleClose();
                            }
                        }}
                    >
                        <Box
                            sx={(theme) => ({
                                width: 0,
                                height: 0,
                                borderLeft: "14px solid transparent",
                                borderRight: "14px solid transparent",
                                borderBottom: "14px solid",
                                borderBottomColor:
                                    theme.palette.background.paper,
                                top: "-15px",
                                left: "50%",
                                transform: "translate(-50%,0%)",
                            })}
                            className="absolute"
                        ></Box>
                        {children}
                    </BertCardWrapper>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
}
