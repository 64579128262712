import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useLottie } from "lottie-react";
import JSON from "@convin/assets/lottieJson/emptyDownloads.json";

export default function NoDownloads({ copy }: { copy?: string }): ReactElement {
    const { View } = useLottie({
        animationData: JSON,
        loop: true,
    });

    return (
        <Box>
            <Box
                sx={{
                    transform: "scale(0.4)",
                }}
            >
                {View}
            </Box>
            <Box className="text-center">
                <Typography
                    component="p"
                    variant="medium"
                    className="font-medium"
                    sx={{
                        color: "textColors.666",
                        mt: "-120px",
                    }}
                >
                    No {copy || "Downloads"}
                </Typography>
            </Box>
        </Box>
    );
}
