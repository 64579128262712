import { useState } from "react";

import InfiniteScrollSelect from "@convin/components/select/InfiniteScrollSelect";
import useDebounce from "@convin/hooks/useDebounce";
import useGetClientsPaginateQuery from "@convin/modules/components/globalDashboardFilters/hooks/useGetClientsPaginateQuery";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";

const ClientFilter = () => {
    const [query, setQuery] = useState("");

    const debouncedQuery = useDebounce(query, 1000);

    const {
        data: clientList,
        fetchNext,
        hasNext,
        isLoading,
        isFetching,
    } = useGetClientsPaginateQuery({
        query: debouncedQuery,
    });

    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();

    return (
        <InfiniteScrollSelect
            label="Client Name"
            options={
                clientList?.results.map((client) => ({
                    id: client.id,
                    label: client.name,
                })) || []
            }
            onQueryChange={(value, reason) => {
                if (reason !== "select") setQuery(value ?? "");
            }}
            value={filtersState.client}
            setValue={(e) => {
                updateFiltersState({
                    client: e,
                });
            }}
            hasClear
            className="flex-1"
            virtualizeProps={{
                fetchNext,
                hasNext,
                isFetching,
                isLoading,
            }}
        />
    );
};

export default ClientFilter;
