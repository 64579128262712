const enum searchDataFields {
    call_types__id = "call_types__id",
    owner__id = "owner__id", //Reps
    sales_task__account_score__owner__id = "sales_task__account_score__owner__id",
    owner__team__id = "owner__team__id", //Teams
    sales_task__account_score__owner__team__id = "sales_task__account_score__owner__team__id",
    start_time = "start_time",
    end_time = "end_time",
    start_join_time = "start_join_time", //Rep joining date - start
    end_join_time = "end_join_time", //Rep joining date - end
    client__id = "client__id", //Client list - Accounts Page
    sales_task__id = "sales_task__id", //Client - Call List Page
    stats__duration = "stats__duration",
    tags__id = "tags__id", //Call Tags
    stats__owner_question_count = "stats__owner_question_count",
    stats__client_question_count = "stats__client_question_count",
    topics__id = "topics__id",
    stats__owner_talk_ratio = "stats__owner_talk_ratio",
    stats__client_talk_ratio = "stats__client_talk_ratio",
    stats__longest_monologue_owner = "stats__longest_monologue_owner",
    stats__longest_monologue_client = "stats__longest_monologue_client",
    stats__interactivity = "stats__interactivity",
    stats__patience = "stats__patience",
    sentence_category_transcript_ts_vector = "sentence_category_transcript_ts_vector",
    topic_class = "topic_class",
    speaker_type = "speaker_type", //Rep or Others speaking
    combined_ts_vector = "combined_ts_vector", //Keyword list if both rep and others speaking
    speaker_transcript_ts_vector = "speaker_transcript_ts_vector", //Keyword list if either rep or others speaking
    category = "category", //Unknown
    stats__criterias_count = "stats__criterias_count", //Not Used
    stats__criterias__id = "stats__criterias__id", //Not Used
    conference_tool = "conference_tool",
    processing_status = "processing_status",
    score__question__id = "score__question__id", //Template question ID - Call List Page
    scores__question__id = "scores__question__id", //Template question ID - Accounts Page
    score__score_given = "score__score_given", //Template question score given - Call List Page
    scores__score_given = "scores__score_given", //Template question score given - Accounts Page
    meeting_score__percent = "meeting_score__percent",
    category_score__category__id = "category_score__category__id", //Template category ID - Call List Page
    category_score__category_percent = "category_score__category_percent", //Template category score given - Call List Page
    category_score__auditor__id = "category_score__auditor__id", //Unknown
    meeting_score__auditor__id = "meeting_score__auditor__id", //Manual Audit - Auditor List
    score__is_ai_rated = "score__is_ai_rated", //Audit Type - Call List page
    scores__is_ai_rated = "scores__is_ai_rated", //Audit Type - Accounts Page
    meeting_score__scores__question__category__template__id = "meeting_score__scores__question__category__template__id", //Template ID
    reps_id = "reps__id", //Unknown - Accounts Page
    sales_stage__id = "sales_stage__stage__id",
    result__type_id = "result__type_id",
    result__type = "result__type",
    result__result_repr = "result__result_repr",
    sales_task__created = "sales_task__created",
    close_date = "close_date",
    sales_task__owner__team__id = "sales_task__owner__team__id",
    sales_task__owner__id = "sales_task__owner__id",
    DateField = "DateField",
    stage__id = "stage__id",
    size = "size",
    last_connected_date = "last_connected_date",
    name = "name",
    meeting__id = "meeting__id",
    account_size = "account_size",
    duration = "duration",
    sales_task__meeting__stats__duration = "sales_task__meeting__stats__duration",
    sales_task__name = "sales_task__name",
    id = "id",
    ai_score = "ai_score",
    manual_score = "manual_score",
    template__id = "template__id",
    result__question__id = "result__question__id",
    lead_score_objs__template__category__question__id = "lead_score_objs__template__category__question__id",
    lead_score_objs__result_repr = "lead_score_objs__result_repr",
    lead_score = "lead_score",
    lead_classification = "lead_classification",
    meeting_score__auditor = "meeting_score__auditor",
    meeting_type = "meeting_type",
    sales_task__tags__id = "sales_task__tags__id",
    stats__talk_time = "stats__talk_time",
    stats__owner_overlap_rate = "stats__owner_overlap_rate",
    tag__id = "tag__id",
    sales_task__account_stat__lead_classification = "sales_task__account_stat__lead_classification",
    owner__org_date_joined = "owner__org_date_joined",
    meeting_score__status = "meeting_score__status",
    meeting_score__template__id = "meeting_score__template__id",
    meeting_score__last_audit_time = "meeting_score__last_audit_time",
    gptanalysisresult__score_type = "gptanalysisresult__score_type",
    gptanalysisresult__classification = "gptanalysisresult__classification",
    score__is_in_draft = "score__is_in_draft",
    sales_task__account_score__percent = "sales_task__account_score__percent",
    sales_task__account_score__acc_scores__score_given = "sales_task__account_score__acc_scores__score_given",
    sales_task__account_score__acc_scores__question__id = "sales_task__account_score__acc_scores__question__id",
    sales_task__account_score__auditor__id = "sales_task__account_score__auditor__id",
    sales_task__account_score__last_audit_time = "sales_task__account_score__last_audit_time",
    sales_task__account_score__template__id = "sales_task__account_score__template__id",
    sales_task__acc_category_score__category__id = "sales_task__acc_category_score__category__id",
    sales_task__acc_category_score__category_percent = "sales_task__acc_category_score__category_percent",
    sales_task__account_score__id = "sales_task__account_score__id",
    metadata__key__metadata_type__id = "metadata__key__metadata_type__id",
    metadata__key__id = "metadata__key__id",
    metadata__value = "metadata__value",
    interaction_count = "interaction_count",
    ai_disposition_results__disposition__id = "ai_disposition_results__disposition__id",
    ai_disposition_results__result = "ai_disposition_results__result",
    ai_disposition_results__disposition__ai_disposition_template__id = "ai_disposition_results__disposition__ai_disposition_template__id",
    account_ai_disposition_results__disposition__id = "account_ai_disposition_results__disposition__id",
    account_ai_disposition_results__result = "account_ai_disposition_results__result",
    account_ai_disposition_results__disposition__ai_disposition_template__id = "account_ai_disposition_results__disposition__ai_disposition_template__id",
    owner__date_joined = "owner__date_joined",
    sales_task__meeting__insight_score__insight__id = "sales_task__meeting__insight_score__insight__id",
    sales_task__meeting__insight_score__insight__parent_insight__id = "sales_task__meeting__insight_score__insight__parent_insight__id",
    sales_task__meeting__processing_status = "sales_task__meeting__processing_status",
    insight_score__insight__id = "insight_score__insight__id",
    questionresult__tags__id = "questionresult__tags__id",
    search_snippet__search_phrase__id = "search_snippet__search_phrase__id",
    sales_task__meeting__questionresult__tags__id = "sales_task__meeting__questionresult__tags__id",
    insight_score__insight__type = "insight_score__insight__type",
    insight_score__insight__parent_insight__id = "insight_score__insight__parent_insight__id",
    insight_score__is_primary = "insight_score__is_primary",
    questionresult__question__id = "questionresult__question__id",
    search_snippet__id = "search_snippet__id",
    search_snippet__search_phrase__tab__id = "search_snippet__search_phrase__tab__id",
    sales_task__meeting__search_snippet__search_phrase__tab__id = "sales_task__meeting__search_snippet__search_phrase__tab__id",
    sales_task__meeting__insight_score__insight__type = "sales_task__meeting__insight_score__insight__type",
    sales_task__meeting__insight_score__is_primary = "sales_task__meeting__insight_score__is_primary",
    sales_task__meeting__questionresult__question__id = "sales_task__meeting__questionresult__question__id",
    sales_task__meeting__search_snippet_id = "sales_task__meeting__search_snippet_id",
    sales_task__meeting__search_snippet__search_phrase__id = "sales_task__meeting__search_snippet__search_phrase__id",
    owner__is_active = "owner__is_active",
    sales_task__meeting__owner__is_active = "sales_task__meeting__owner__is_active",
}

const enum searchDataModels {
    meeting = "meeting.models.Meeting",
    transcript = "search.models.MeetingTranscriptTsVector",
    speakerTranscript = "search.models.MeetingSpeakerTranscriptTsVector",
    sentenceCategory = "search.models.SentenceCategoryTranscriptTsVector",
    opportunity = "account.models.AccountStats",
}

const enum searchDataTypes {
    ArrayField = "ArrayField",
    AutoField = "AutoField",
    BigIntegerField = "BigIntegerField",
    BooleanField = "BooleanField",
    CharField = "CharField",
    DateField = "DateField",
    FloatField = "FloatField",
    IntegerField = "IntegerField",
    PositiveIntegerField = "PositiveIntegerField",
    PositiveSmallIntegerField = "PositiveSmallIntegerField",
    SearchVectorField = "SearchVectorField",
    TextField = "TextField",
    DateTimeField = "DateTimeField",
    PositiveBigIntegerField = "PositiveBigIntegerField",
    BigAutoField = "BigAutoField",
    JSONField = "JSONField",
}

export { searchDataFields, searchDataModels, searchDataTypes };

export const fields = {
    call_types__id: "call_types__id",
    owner__id: "owner__id", //Reps
    owner__team__id: "owner__team__id", //Teams
    start_time: "start_time",
    end_time: "end_time",
    start_join_time: "start_join_time", //Rep joining date - start
    end_join_time: "end_join_time", //Rep joining date - end
    client__id: "client__id", //Client list - Accounts Page
    sales_task__id: "sales_task__id", //Client - Call List Page
    stats__duration: "stats__duration",
    tags__id: "tags__id", //Call Tags
    stats__owner_question_count: "stats__owner_question_count",
    stats__client_question_count: "stats__client_question_count",
    topics__id: "topics__id",
    stats__owner_talk_ratio: "stats__owner_talk_ratio",
    stats__client_talk_ratio: "stats__client_talk_ratio",
    stats__longest_monologue_owner: "stats__longest_monologue_owner",
    stats__longest_monologue_client: "stats__longest_monologue_client",
    stats__interactivity: "stats__interactivity",
    stats__patience: "stats__patience",
    sentence_category_transcript_ts_vector:
        "sentence_category_transcript_ts_vector",
    topic_class: "topic_class",
    speaker_type: "speaker_type", //Rep or Others speaking
    combined_ts_vector: "combined_ts_vector", //Keyword list if both rep and others speaking
    speaker_transcript_ts_vector: "speaker_transcript_ts_vector", //Keyword list if either rep or others speaking
    category: "category", //Unknown
    stats__criterias_count: "stats__criterias_count", //Not Used
    stats__criterias__id: "stats__criterias__id", //Not Used
    conference_tool: "conference_tool",
    processing_status: "processing_status",
    agent_tenure_buckets: "agent_tenure_buckets",
    score__question__id: "score__question__id", //Template question ID - Call List Page
    scores__question__id: "scores__question__id", //Template question ID - Accounts Page
    score__score_given: "score__score_given", //Template question score given - Call List Page
    scores__score_given: "scores__score_given", //Template question score given - Accounts Page
    meeting_score__percent: "meeting_score__percent",
    category_score__category__id: "category_score__category__id", //Template category ID - Call List Page
    category_score__category_percent: "category_score__category_percent", //Template category score given - Call List Page
    category_score__auditor__id: "category_score__auditor__id", //Unknown
    meeting_score__auditor__id: "meeting_score__auditor__id", //Manual Audit - Auditor List
    score__is_ai_rated: "score__is_ai_rated", //Audit Type - Call List page
    scores__is_ai_rated: "scores__is_ai_rated", //Audit Type - Accounts Page
    meeting_score__scores__question__category__template__id:
        "meeting_score__scores__question__category__template__id", //Template ID
    reps_id: "reps__id", //Unknown - Accounts Page
    sales_stage__id: "sales_stage__stage__id",
    result__type_id: "result__type_id",
    result__type: "result__type",
    result__result_repr: "result__result_repr",
    sales_task__created: "sales_task__created",
    close_date: "close_date",
    sales_task__owner__team__id: "sales_task__owner__team__id",
    sales_task__owner__id: "sales_task__owner__id",
    DateField: "DateField",
    stage__id: "stage__id",
    size: "size",
    last_connected_date: "last_connected_date",
    name: "name",
    meeting__id: "meeting__id",
    account_size: "account_size",
    duration: "duration",
    sales_task__name: "sales_task__name",
    id: "id",
    ai_score: "ai_score",
    manual_score: "manual_score",
    template__id: "template__id",
    result__question__id: "result__question__id",
    lead_score_objs__template__category__question__id:
        "lead_score_objs__template__category__question__id",
    lead_score_objs__result_repr: "lead_score_objs__result_repr",
    lead_score: "lead_score",
    meeting_score__auditor: "meeting_score__auditor",
    meeting_type: "meeting_type",
    account_tags: "sales_task__tags__id",
    stats__talk_time: "stats__talk_time",
    stats__owner_overlap_rate: "stats__owner_overlap_rate",
    tag__id: "tag__id",
    sales_task__account_stat__lead_classification:
        "sales_task__account_stat__lead_classification",
    owner__org_date_joined: "owner__org_date_joined",
    meeting_score__status: "meeting_score__status",
    gptanalysisresult__classification: "gptanalysisresult__classification",
    gptanalysisresult__score_type: "gptanalysisresult__score_type",
} as const;
