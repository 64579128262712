import {
    alpha,
    Button,
    Divider,
    Grid2,
    Stack,
    styled,
    Typography,
} from "@mui/material";

import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { baseUrl } from "@apis/axiosInstance";
import GoogleSvg from "@convin/components/svg/GoogleSvg";
import OutlookSvg from "@convin/components/svg/OutlookSvg";
import JumpCloudSvg from "@convin/components/svg/JumpCloudSvg";
import FlipkartSvg from "@convin/components/svg/FlipkartSvg";
import { CyberArkSvg } from "@convin/components/svg/CyberArkSvg";

const SsoButton = styled(Button)(({ theme }) => ({
    borderRadius: "100%",
    border: `1px solid ${alpha(theme.palette.textColors["999"], 0.2)}`,
    height: "48px",
    minWidth: "48px",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #FFF",
    boxShadow: "2px 2px 12px 0px rgba(51, 51, 51, 0.10)",
    color: theme.palette.textColors["333"],
    "& .MuiButton-icon": {
        margin: 0,
    },
    padding: 0,
}));

export const SsoOptions = () => {
    const { data: domainConfig } = useGetDomainConfigQuery();

    const getUri = (provider: string) => {
        return `${baseUrl}/app/tpsignup/${provider}/`;
    };

    return (
        isDefined(domainConfig?.sso_providers) &&
        domainConfig?.sso_providers?.length > 0 && (
            <>
                <Divider>
                    <Typography variant="small" className="font-semibold">
                        OR
                    </Typography>
                </Divider>
                <Stack direction="row" spacing={2} justifyContent="center">
                    {domainConfig?.sso_providers?.map((provider) => {
                        return (
                            <SsoButton
                                href={getUri(provider)}
                                className="flex items-center justify-center"
                                key={provider}
                            >
                                {provider === "google" ? (
                                    <GoogleSvg />
                                ) : provider === "outlook" ? (
                                    <OutlookSvg />
                                ) : provider === "jumpcloud" ? (
                                    <JumpCloudSvg />
                                ) : provider === "flipkart" ? (
                                    <FlipkartSvg />
                                ) : provider === "cyberark" ? (
                                    <CyberArkSvg />
                                ) : null}
                            </SsoButton>
                        );
                    })}
                </Stack>
            </>
        )
    );
};
