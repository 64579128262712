import ConvinTextLogo from "@convin/components/svg/ConvinTextLogo";
import { pxToRem } from "@convin/utils/getFontValue";
import { getDomain } from "@convin/utils/helper/common.helper";
import { Box, Divider, Paper, SxProps, Theme, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import "./index.scss";
import { Banner } from "@convin/components/custom_components/Banner";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
export default function PreAuthLayout({
    children,
    Footer,
    title = "",
    subtitle = "",
    showTitle = true,
    sx,
}: PropsWithChildren<{
    Footer?: React.ReactNode;
    title?: string;
    subtitle?: string;
    showTitle?: boolean;
    sx?: SxProps<Theme>;
}>) {
    const { data: domainConfig } = useGetDomainConfigQuery();
    return (
        <>
            <Box
                className="gradient-background__wrapper"
                component={Paper}
                bgcolor="background.paper"
            >
                <div className="gradient-background gradient-background__dark">
                    <div className="gradient-background__shape gradient-background__shape--1"></div>
                    <div className="gradient-background__shape gradient-background__shape--2"></div>
                </div>
            </Box>
            <Banner />
            <Paper
                className="h-full flex items-center justify-center flex-col"
                sx={{
                    ...sx,
                    "& button": {
                        height: "40px",
                    },
                }}
            >
                <Box className="flex-1 flex flex-col items-center justify-center relative z-10">
                    {showTitle && title && (
                        <Box
                            className="flex items-center justify-center flex-col"
                            gap={1}
                            mb={5}
                        >
                            <Typography
                                className="font-semibold"
                                sx={{
                                    fontSize: pxToRem(34),
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: pxToRem(20),
                                }}
                            >
                                {subtitle}
                            </Typography>
                        </Box>
                    )}

                    <Box
                        className="flex flex-col w-[500px]"
                        sx={{
                            px: 4.5,
                            py: 4,
                            borderRadius: "12px",
                            position: "relative",
                            zIndex: 10,
                            filter: "drop-shadow(0px 12px 40px 0px rgba(51, 51, 51, 0.12))",
                            border: "1px solid",
                            borderColor: "divider",
                        }}
                        component={Paper}
                    >
                        <Box
                            className="domain__name inline-flex items-center justify-start flex-shrink-0"
                            gap={0.5}
                            mb={2.5}
                        >
                            {domainConfig?.logo ? (
                                <img
                                    src={domainConfig?.logo}
                                    alt="logo"
                                    className="w-10 h-10"
                                />
                            ) : (
                                <ConvinTextLogo />
                            )}
                            <Typography
                                color="textColors.333"
                                className="uppercase font-semibold"
                                sx={{
                                    fontSize: pxToRem(14),
                                }}
                            >
                                {getDomain()}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            className="w-full"
                            sx={{
                                "& .MuiFormLabel-root:not(.MuiInputLabel-shrink)":
                                    {
                                        top: "-5px",
                                    },
                                "& .MuiInputBase-root": {
                                    height: "48px",
                                    "& input": {
                                        height: "100%",
                                        p: 0,
                                        pl: 2,
                                    },
                                },
                                "& .MuiLoadingButton-root": {
                                    borderRadius: "8px",
                                },
                                "& .domain-input": {
                                    "& input": {
                                        pl: 0,
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                py: 2.5,
                            }}
                        >
                            {children}
                            {Footer && (
                                <Box mt={2.5} className="text-center">
                                    {Footer}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>
            {domainConfig?.logo || (
                <Typography
                    mb={2}
                    variant="small"
                    className="absolute bottom-0 left-0 w-full text-center"
                >
                    {new Date().getFullYear() +
                        " Convin.ai | All rights reserved"}
                </Typography>
            )}
        </>
    );
}
