import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function GoogleSvg({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <path
                d="M20.02 10.4636C20.02 9.79731 19.9602 9.15669 19.8492 8.54169H11V12.1762H16.0567C15.8389 13.3506 15.1769 14.3458 14.1818 15.012V17.3695H17.2183C18.995 15.7338 20.02 13.325 20.02 10.4636Z"
                fill="#4285F4"
            />
            <path
                d="M11 19.6458C13.5368 19.6458 15.6637 18.8045 17.2183 17.3695L14.1817 15.012C13.3404 15.5757 12.2641 15.9089 11 15.9089C8.55279 15.9089 6.48143 14.2561 5.74258 12.0352H2.60352V14.4696C4.14956 17.5403 7.32706 19.6458 11 19.6458Z"
                fill="#34A853"
            />
            <path
                d="M5.74256 12.0352C5.55465 11.4714 5.44788 10.8692 5.44788 10.25C5.44788 9.63071 5.55465 9.02852 5.74256 8.46477V6.0304H2.6035C1.94579 7.33971 1.60355 8.78475 1.60413 10.25C1.60413 11.7661 1.96715 13.2011 2.6035 14.4696L5.74256 12.0352Z"
                fill="#FBBC05"
            />
            <path
                d="M11 4.59117C12.3795 4.59117 13.618 5.06523 14.5917 5.99627L17.2866 3.30137C15.6595 1.78523 13.5326 0.854187 11 0.854187C7.32706 0.854187 4.14956 2.95971 2.60352 6.03044L5.74258 8.46481C6.48143 6.24398 8.55279 4.59117 11 4.59117Z"
                fill="#EA4335"
            />
        </SvgIcon>
    );
}
