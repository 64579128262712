import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    FiltersVisibilityContext,
    IFilterVisibility,
} from "../contexts/FiltersVisibilityContextProvider";

const useFiltersVisibilityContext = (): IFilterVisibility => {
    const context = useContext(FiltersVisibilityContext);
    if (!isDefined(context))
        throw new Error(
            "FiltersVisibilityContext must be use inside FiltersVisibilityProvider"
        );
    return context;
};

export default useFiltersVisibilityContext;
