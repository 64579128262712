import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const DownloadProgressSvg = ({ sx, ...rest }: SvgIconProps) => {
    const {
        palette: {
            primary: { main },
        },
    } = useTheme();
    return (
        <SvgIcon
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: 14, height: 14, fill: "none", ...sx }}
            {...rest}
        >
            <path
                d="M6.99998 10.3333L2.83331 6.16666L3.99998 4.95833L6.16665 7.125V0.333328H7.83331V7.125L9.99998 4.95833L11.1666 6.16666L6.99998 10.3333ZM1.99998 13.6667C1.54165 13.6667 1.14929 13.5035 0.822896 13.1771C0.496507 12.8507 0.333313 12.4583 0.333313 12V9.49999H1.99998V12H12V9.49999H13.6666V12C13.6666 12.4583 13.5035 12.8507 13.1771 13.1771C12.8507 13.5035 12.4583 13.6667 12 13.6667H1.99998Z"
                fill={main}
            />
        </SvgIcon>
    );
};

export default DownloadProgressSvg;
