import { Close, PlayArrow } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";

type VideoPrivewProps = {
    url?: string;
} & (
    | {
          onClose: () => void;
          previewType: "preview_media" | "audit_media";
      }
    | {
          previewType: "comment_media";
          onClose?: () => void;
      }
);

const VideoPreview: React.FC<VideoPrivewProps> = ({
    onClose,
    url,
    previewType,
}) => {
    const [videoPreviewAnchorEl, setVideoPreviewAnchorEl] =
        useState<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const setVideoRef = useCallback((node: HTMLVideoElement | null) => {
        if (node) {
            videoRef.current = node;
            node.addEventListener("timeupdate", handleMetadata);
        } else if (videoRef.current) {
            videoRef.current.removeEventListener("timeupdate", handleMetadata);
            videoRef.current = null;
        }
    }, []);

    const handleMetadata = () => {
        if (videoRef.current) {
            const duration = videoRef.current.duration;
            if (duration === Infinity) {
                videoRef.current.classList.add("hide-timeline");
            } else {
                videoRef.current.classList.remove("hide-timeline");
            }
        }
    };

    useEffect(() => {
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener(
                    "timeupdate",
                    handleMetadata
                );
            }
        };
    }, [videoPreviewAnchorEl]);

    return url ? (
        <>
            <Box
                onClick={(e) => {
                    setVideoPreviewAnchorEl(e.currentTarget);
                }}
                sx={{
                    cursor: "pointer",
                    mt: 1,
                    color: "white",
                    maxWidth: "200px",
                }}
                className="relative inline-flex"
            >
                {["comment_media", "audit_media"].includes(previewType) ? (
                    <video src={url} className="w-[200px] h-auto rounded-md" />
                ) : (
                    <video
                        src={url}
                        style={{
                            width: "auto",
                            maxHeight: "50px",
                        }}
                        className="rounded-md"
                    />
                )}
                {["comment_media", "audit_media"].includes(previewType) && (
                    <PlayArrow className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                )}
                {(previewType === "audit_media" ||
                    previewType === "preview_media") && (
                    <Box
                        sx={{
                            height: "20px",
                            width: "20px",
                            bgcolor: "primary.main",
                            borderRadius: "100%",
                            color: "white",
                        }}
                        component="button"
                        className="absolute top-[-5px] right-[-5px] flex items-center justify-center"
                        onClick={() => onClose()}
                    >
                        <Close
                            sx={{
                                transform: "scale(0.6)",
                            }}
                        />
                    </Box>
                )}
            </Box>

            <Popper
                open={Boolean(videoPreviewAnchorEl)}
                anchorEl={videoPreviewAnchorEl}
                placement="left-end"
                sx={{ zIndex: 1400 }}
                className="relative"
            >
                <>
                    <video
                        ref={setVideoRef}
                        src={url}
                        controls={true}
                        style={{ height: "300px" }}
                        className="rounded-md hide-timeline"
                    />
                    <IconButton
                        className="absolute z-10 cursor-pointer top-3 right-3"
                        sx={{
                            color: (theme) => theme.palette.common.white,
                        }}
                        onClick={() => {
                            setVideoPreviewAnchorEl(null);
                        }}
                    >
                        <Close />
                    </IconButton>
                </>
            </Popper>
        </>
    ) : (
        <></>
    );
};

export default VideoPreview;
